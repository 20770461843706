<template>
  <div class="container-fluid mt-4">
    <div class="row align-items-center">
      <div class="col-lg-4 col-sm-8">
        <KakaoTemplateNavPill @selectForm="selectedForm = $event" />
      </div>
    </div>
    <div class="row mb-5">
      <SearchKakaoTemplate v-if="selectedForm === 'search'" />
      <RegisterKakaoTemplate v-if="selectedForm === 'register'" />
    </div>
  </div>
</template>

<script>
import KakaoTemplateNavPill from "@/views/manage/components/KakaoTemplateNavPill.vue";
import SearchKakaoTemplate from "@/views/manage/components/SearchKakaoTemplate.vue";
import RegisterKakaoTemplate from "@/views/manage/components/RegisterKakaoTemplate.vue";


export default {
  name: "KakaoTemplate",
  components: {
    SearchKakaoTemplate,
    RegisterKakaoTemplate,
    KakaoTemplateNavPill,
  },
  data() {
    return {
      selectedForm: "register", // default selected form
    };
  },
};
</script>
