<template>
  <div id="send-history" class="card mt-4 me-2">
    <div class="card-header">
      <h6 class="mb-0">조회 조건</h6>
    </div>
    <div class="card-body pt-0">
      <div class="mt-0 row">
        <span style="font-weight: bold; font-size: 0.8rem;">승인 상태:</span>
      </div>
      <div class="col-sm-12 d-flex align-items-center">
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">저장
        </label>
        <material-checkbox id="checkboxSave" v-model="isSaveChecked" name="checkboxSave" :checked="isSaveChecked">
        </material-checkbox>
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">요청
        </label>
        <material-checkbox id="checkboxRequest" v-model="isRequestChecked" name="checkboxRequest"
          :checked="isRequestChecked">
        </material-checkbox>
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">승인
        </label>
        <material-checkbox id="checkboxApprove" v-model="isApproveChecked" name="checkboxApprove"
          :checked="isApproveChecked">
        </material-checkbox>
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">완료
        </label>
        <material-checkbox id="checkboxComplete" v-model="isCompleteChecked" name="checkboxComplete"
          :checked="isCompleteChecked">
        </material-checkbox>
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px; ">반려
        </label>
        <material-checkbox id="checkboxReject" v-model="isRejectChecked" name="checkboxReject"
          :checked="isRejectChecked">
        </material-checkbox>
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">만료
        </label>
        <material-checkbox id="checkboxExpire" v-model="isExpireChecked" name="checkboxExpire"
          :checked="isExpireChecked">
        </material-checkbox>
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">취소
        </label>
        <material-checkbox id="checkboxCancle" v-model="isCancleChecked" name="checkboxCancle"
          :checked="isCancleChecked">
        </material-checkbox>
      </div>
      <div class="mt-0 row">
        <div class="col-6">
          <span style="font-weight: bold; font-size: 0.8rem;">캠페인전송일시:</span>
          <div class="col-sm-12 d-flex align-items-center mt-2">
            <div class="col-6" style="margin-right: 5px; max-width: 180px;">
              <material-input id="fromDate" v-model="fromDate" size="sm" type="datetime-local" :max="maxDatetime">
              </material-input>
            </div>
            ~
            <div class="col-6" style="margin-left: 5px; max-width: 180px;">
              <material-input id="toDate" v-model="toDate" size="sm" type="datetime-local" :max="maxDatetime">
              </material-input>
            </div>
          </div>

        </div>
        <div class="col-6">
          <span style="font-weight: bold; font-size: 0.8rem;">조회상세:</span>

          <div class="row align-items-center mt-2">
            <div class="col-4" style="max-width: 180px;">
              <material-input id="campaign_name" v-model="campaign_name" type="text" size="sm" label="캠페인명"
                label-size="sm" variant="outline">
              </material-input>
            </div>
            <!-- <div class="col-4" style="max-width: 180px;">
              <material-input id="campaign_company_name" type="text" size="sm" label="캠페인회사" labelSize="sm"
                v-model="campaign_company_name" variant="outline">
              </material-input>
            </div> -->
          </div>
        </div>
      </div>
      <div v-if="hasSubTenant && currentTenantId != '0'" class="mt-2 row">
        <span style="font-weight: bold; font-size: 0.8rem;">부서 선택</span>
        <select id="tenant-name" v-model="selectedTenant" class="form-control" name="tenant-name">
          <option v-for="tenant in subTenantListForSearch" :key="tenant.tenant_id" :value="tenant.tenant_id">
            {{ tenant.tenant_name }}
          </option>
        </select>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-end">
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;"
            @click="changeData">
            조회
          </material-button>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-4 card mt-2 me-2">
    <div class="pb-0 card-header">
      <div class="row mb-2">
        <div class="col-6">
          <div v-if="result_count < 0">
            <h6>조회 결과</h6>
          </div>
          <div v-if="result_count >= 0">
            <h6>조회 결과 {{ result_count }}건</h6>
          </div>
        </div>
        <div class="col-6 text-end">
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;"
            @click="exportCSV">
            내보내기
          </material-button>
        </div>
      </div>
    </div>
    <div>
      <EasyDataTable table-class-name="customize-table" empty-message="조회된 내역이 없습니다."
        rows-per-page-message="페이지당 표시 행 수" rows-of-page-separator-message="중" :headers="tableColumns"
        :items="sendHistoryList" :loading="isLoading" @click-row="showCampaignSendHistoryDetail">
        <template #loading>
          <img src="@/assets/img/Spinner-1s-200px.gif" style="width: 100px; height: 100px;" />
        </template>
        <template #item-primary="{ primary, image, image_color, secondary, secondary_color }">
          <div class="row">
            <div class="col-auto flex-wrap">
              <material-button v-if="image" :color="image_color" variant="outline"
                class="btn-icon-only btn-rounded btn-sm d-flex align-items-center justify-content-center">
                <i :class="image" aria-hidden="true"></i>
              </material-button>
            </div>
            <div class="col-auto flex-wrap">
              <div class="d-flex flex-column justify-content-center">
                <span style="display: inline-block; max-width: 100%;" :title="primary">{{ primary }}</span>
                <span style="display: inline-block; max-width: 100%;" :title="secondary">
                  <span :class="secondary_color">{{ secondary }}</span>
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #item-sent_content="{ sent_content }">
          <!--              <p>{{ sent_content }}</p>-->
          <div class="row" style="max-width: 290px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"
            :title="sent_content">
            {{ sent_content }}
          </div>
        </template>
        <template #item-info="{ info }">
          <div class="justify-content-center">
            <material-button v-if="info" size="xsm" color="secondary" variant="outline"
              class="btn-icon-only btn-rounded btn-sm d-flex align-items-center justify-content-center"
              data-bs-toggle="tooltip" data-bs-placement="bottom" :title="info">
              <i class="fas fa-info" aria-hidden="true"></i>
            </material-button>
          </div>
        </template>
      </EasyDataTable>
    </div>

  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import ApiCall from "@/Interface/ApiCall";
import { Auth } from 'aws-amplify'
import MaterialInput from "@/components/MaterialInput.vue";
import { formatDate } from "@fullcalendar/core";
import Calendar from "../../examples/Calendar.vue";

import MaterialTable from "@/components/MaterialTable.vue";
import MaterialPagination from "@/components/MaterialPagination.vue";
import * as Choices from "choices.js";
import Papa from "papaparse";

export default {
  name: "SendHistoryAdmin",
  components: {
    MaterialButton,
    // MaterialTable,
    // MaterialPagination,
    MaterialInput,
    MaterialCheckbox,
  },
  setup() {
  },
  data() {
    return {
      // tableColumns: ['발송종류','발송일자', '수신번호', '발송내용','상태','고객식별ID','캠페인ID',''],
      tableData: [],
      fromDate: new Date(new Date().getTime() + (8 * 60 * 60 * 1000)).toISOString().slice(0, 16),
      toDate: new Date(new Date().getTime() + (10 * 60 * 60 * 1000)).toISOString().slice(0, 16),
      maxDate: '',
      primary_customer_reference_id: '',
      campaign_name: '',
      campaign_company_name: '',
      customerId: '',
      //전송상태
      currentTenantId: '',
      isSaveChecked: true,
      isRequestChecked: true,
      isApproveChecked: true,
      isCompleteChecked: true,
      isRejectChecked: true,
      isExpireChecked: true,
      isCancleChecked: true,

      // currentTimestamp: Date.now(),
      sendHistoryList: [], // Add this line
      selectedRow: null,
      selectedList: null,
      maxItems: 10000,
      itemsPerPage: 1000,
      currentPage: 1, // Add this line
      page_number: 1,
      isAdmin: false,
      tenant_id: '',
      result_count: -1,
      isLoading: false,
      selectedTenant: 'all',
      subTenantListForSearch: [],
      subTenantList: [],
      hasSubTenant: false,
      tableColumns: [
        { text: "대상회사", value: "tenant_name", sortable: true },
        { text: "승인여부", value: "campaign_status", sortable: true },
        { text: "캠페인명", value: "campaign_name", sortable: true },
        { text: "캠페인대상", value: "campaign_object", sortable: true },
        { text: "캠페인목적", value: "campaign_purpose", sortable: true },
        { text: "타겟수", value: "target_count", sortable: true },
        { text: "성공수", value: "success_count", sortable: true },
        // { text: "클릭수", value: "click_count", sortable: true },
        // { text: "클릭율", value: "click_rate", sortable: true },
        { text: "실패수", value: "fail_count", sortable: true },
        { text: "캠페인전송일시", value: "send_request_dtm", sortable: true },
        { text: "캠페인생성일시", value: "request_dtm", sortable: true },
        { text: "", value: "info", width: 10, sortable: true },
      ],
    }
  },
  computed: {
    paginatedSendHistoryList() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.sendHistoryList.slice(startIndex, endIndex);
    },
    maxDatetime() {
      // Get the current datetime in Seoul time and round up to the nearest 10 minute interval
      let now = new Date();
      now.setSeconds(0);

      // Set the time zone offset to the offset for Seoul time (UTC+9)
      let timezoneOffset = 9 * 60; // in minutes
      now.setMinutes(now.getMinutes() + timezoneOffset);

      // Round up to the nearest 10 minute interval
      let roundedMinutes = Math.ceil(now.getMinutes() / 10) * 10;
      now.setMinutes(roundedMinutes);

      // Add 7 days to the current date
      now.setDate(now.getDate() + 21);

      let datetimeString = now.toISOString().slice(0, -8);

      return datetimeString;
    }
  },
  async mounted() {
    // Perform the search using the route parameters
    // if (this.$route.params.title)
    //   this.performSearch();

    await this.triggerGetTenant();
  },
  methods: {
    exportCSV() {
      // First, decode URIs in the dictionary list
      const decodedList = this.sendHistoryList;

      // Convert the decoded list to CSV with PapaParse
      const csv = Papa.unparse(decodedList, {
        quotes: true,  // Enable quotes around values
      });

      // Create and trigger the download link with UTF-8 BOM
      const csvBlob = new Blob(["\ufeff", csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(csvBlob);
      link.setAttribute('download', 'history.csv');
      document.body.appendChild(link);
      link.click();
    },
    async triggerGetTenant() {
      await this.getTenantList();

      if (document.getElementById("tenant-name")) {
        var tenant_name = document.getElementById("tenant-name");
        new Choices(tenant_name);
      }
    },
    async getTenantList() {
      await ApiCall.call('user/auth/subtenant/list', 'POST').then(async (res) => {

        // console.log("res.data :", res.data)
        if (res.data.length > 0) {
          this.subTenantList = res.data;
          this.hasSubTenant = true;
        }
        // this.subTenantList = this.subTenantList.concat([{tenant_id: "0", tenant_name:"전체"}])
        this.subTenantListForSearch = [{ tenant_id: "all", tenant_name: "전체" }]

        // 자기 자신의 tenant
        const user = await Auth.currentAuthenticatedUser()
        const idToken = user.signInUserSession.idToken;
        const tenantId = idToken.payload['cognito:groups'][0];
        const isAdmin = idToken.payload['custom:isAdmin']
        this.currentTenantId = tenantId;
        this.tenant_id = tenantId;
        // console.log("this.tenant_id :", this.tenant_id)
        const tenantName = user.attributes['custom:tenant_name'];
        const myTenant = [{ tenant_id: tenantId, tenant_name: tenantName }]
        this.subTenantListForSearch = this.subTenantListForSearch.concat(myTenant)
        this.subTenantList = this.subTenantList.concat(myTenant)

        this.subTenantListForSearch = this.subTenantListForSearch.concat(res.data);

        this.selectedTenant = this.subTenantListForSearch[0].tenant_id
        if (isAdmin === '1') {
          this.isAdmin = true;
        }
      })
    },
    onPageChange(pageNumber) {
      this.currentPage = pageNumber;
    },
    onRowClicked({ list, index }) {
      this.selectedList = list;
      this.selectedRow = index;
    },
    getFormattedDate(daysBefore = 0) {
      const currentTimestamp = Date.now();
      const date = new Date(currentTimestamp - daysBefore * 24 * 60 * 60 * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    changeDateToString(dateString) {
      const dateObj = new Date(dateString);
      const year = dateObj.getFullYear();
      const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      const day = ("0" + dateObj.getDate()).slice(-2);
      const hour = ("0" + dateObj.getHours()).slice(-2);
      const minute = ("0" + dateObj.getMinutes()).slice(-2);
      const second = ("0" + dateObj.getSeconds()).slice(-2);
      return `${year}${month}${day}${hour}${minute}${second}`;
    },
    convertStatus(status) {
      switch (status) {
        case 'save':
          return '저장';
        case 'request':
          return '요청';
        case 'approve':
          return '승인';
        case 'complete':
          return '완료';
        case 'reject':
          return '반려';
        case 'expire':
          return '만료';
        case 'cancle':
          return '취소';
        default:
          return '알 수 없음'; // 예상치 못한 값에 대한 처리
      }
    },
    changeData() {
      if (new Date(this.fromDate) > new Date(this.toDate)) {
        alert("시작일자가 종료일자보다 클 수 없습니다.")
        return;
      }
      this.isLoading = true;

      this.page_number = 1;
      this.currentPage = 1;

      const sendStatus = [];
      this.currentPage = 1;
      if (this.isSaveChecked) sendStatus.push('save')
      if (this.isRequestChecked) sendStatus.push('request')
      if (this.isApproveChecked) sendStatus.push('approve')
      if (this.isCompleteChecked) sendStatus.push('complete')
      if (this.isRejectChecked) sendStatus.push('reject')
      if (this.isExpireChecked) sendStatus.push('expire')
      if (this.isCancleChecked) sendStatus.push('cancle')

      // console.log('sendStatus', sendStatus);



      let payload = {
        from_date: this.changeDateToString(this.fromDate),
        to_date: this.changeDateToString(this.toDate),
        send_status: sendStatus,
        campaign_name: this.campaign_name,
        campaign_company_name: this.campaign_company_name,

        // customer_id: this.customerId,
        items_per_page: this.itemsPerPage,
        page_number: this.page_number,
      }

      // console.log('payload', payload);

      if (this.selectedTenant === 'all') {
        // 전체 조회
        // payload['tenant_id_list'] = this.subTenantList.map(item => item.tenant_id);
        payload['tenant_id_list'] = [this.selectedTenant];
      } else {
        payload['tenant_id_list'] = [this.selectedTenant];
      }


      ApiCall.call('marketing/campaign/history', 'POST', payload).then(res => {
        // if(res.data.length >= this.maxItems){
        //   alert('조회결과가 5000건이 넘습니다. 조회조건을 조정해 주세요.')
        // }
        // console.log('res.data :', res.data);

        this.result_count = res.data.data.length
        const sendHistoryList = res.data.data.map(item => {
          let tenant_name = '';
          let formattedSendRequestDtm = item.send_request_dtm ? this.formatDate(item.send_request_dtm) : item.send_request_dtm;
          let formattedRequestDtm = item.request_dtm ? this.formatDate(item.request_dtm) : item.request_dtm;
          let formatted_campaign_status = item.campaign_status ? this.convertStatus(item.campaign_status) : item.campaign_status;
          // console.log('item :', item);
         
          if (item.parent_tenant_name != '') {
            tenant_name = '(' + item.parent_tenant_name + ') ' + item.tenant_name;
          } else {
            tenant_name = item.tenant_name;
          }

          return {
            tenant_name: item.parent_tenant_name ? `(${item.parent_tenant_name}) ${item.tenant_name}` : item.tenant_name ?? '',
            // tenant_name : item.approve_tenant_name,
            campaign_name: item.campaign_name ?? 'N/A', // 'N/A'는 예시로, 적절한 기본값 사용
            campaign_company_name: item.campaign_company_name ?? 'N/A',
            campaign_status: formatted_campaign_status ?? 'N/A',
            campaign_object: item.campaign_object ?? 'N/A',
            campaign_purpose: item.campaign_purpose ?? 'N/A',
            target_count: item.target_count ?? 0,
            success_count: item.success_count ?? 0,
            fail_count: item.fail_count ?? 0,
            click_count: item.click_count ?? 0,
            click_rate: item.click_rate ?? '0%',
            send_request_dtm: formattedSendRequestDtm,
            request_dtm: formattedRequestDtm,
            send_request_dtm_origin: item.send_request_dtm ?? '',
            primary_customer_reference_id: item.primary_customer_reference_id ?? '',

          };


        });

        this.sendHistoryList = sendHistoryList;
        // console.log("sendHistoryList(changedata) :", sendHistoryList)
        this.isLoading = false;


        if (res.data.data.length == this.itemsPerPage) {
          //  console.log('getting next page');
          this.appendData();
        }
      })
        .catch(error => {
          // Handle error response
          console.error('Error:', error);
          // You can display an error message to the user or perform any other necessary actions
          alert('Timeout이 발생했습니다. 조회조건을 수정하고 다시 조회하세요.');

          // Reset any relevant state variables if needed

          this.isLoading = false;
        });
    },
    formatDate(dateString) {
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 8);
      const hour = dateString.substring(8, 10);
      const minute = dateString.substring(10, 12);
      const second = dateString.substring(12, 14);

      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    },
    showCampaignSendHistoryDetail(item) {
      // console.log("row clicked : send_request_dtm" + item.send_request_dtm_origin);
      // console.log("row clicked : primary_customer_reference_id :" + item.primary_customer_reference_id);
      // console.log("row clicked : " + item);
      this.campaignSendHistoryDetail = item;

      let status = '';
      status = 'schedule-request';

      const routeParams = {
        title: status,
        start: item.send_request_dtm_origin,
        request_tenant_id: this.tenant_id,
        primary_customer_reference_id: item.primary_customer_reference_id,

      };

      if (this.isAdmin)
        this.$router.push({ name: 'SendHistoryAdminWithParamsCampaign', params: routeParams });
      else
        this.$router.push({ name: 'SendHistoryWithParams', params: routeParams });
    },
    appendData() {
      this.isLoading = true;
      this.page_number += 1;
      this.currentPage = 1;

      const sendStatus = [];
      this.currentPage = 1;
      if (this.isSaveChecked) sendStatus.push('save')
      if (this.isRequestChecked) sendStatus.push('request')
      if (this.isApproveChecked) sendStatus.push('approve')
      if (this.isCompleteChecked) sendStatus.push('complete')
      if (this.isRejectChecked) sendStatus.push('reject')
      if (this.isExpireChecked) sendStatus.push('expire')
      if (this.isCancleChecked) sendStatus.push('cancle')

      const payload = {
        from_date: this.changeDateToString(this.fromDate),
        to_date: this.changeDateToString(this.toDate),
        send_status: sendStatus,
        campaign_name: this.campaign_name,
        campaign_company_name: this.campaign_company_name,
        items_per_page: this.itemsPerPage,
        page_number: this.page_number,
      }

      if (this.selectedTenant === 'all') {
        // 전체 조회
        // payload['tenant_id_list'] = this.subTenantList.map(item => item.tenant_id);
        payload['tenant_id_list'] = [this.selectedTenant];
      } else {
        payload['tenant_id_list'] = [this.selectedTenant];
      }
      ApiCall.call('marketing/campaign/history', 'POST', payload).then(res => {
        this.result_count += res.data.data.length

        const sendHistoryList = res.data.data.map(item => {

          let tenant_name = '';
          let formattedSendRequestDtm = item.send_request_dtm ? this.formatDate(item.send_request_dtm) : item.send_request_dtm;
          let formattedRequestDtm = item.request_dtm ? this.formatDate(item.request_dtm) : item.request_dtm;
          let formatted_campaign_status = item.campaign_status ? this.convertStatus(item.campaign_status) : item.campaign_status;

          if (item.parent_tenant_name != '') {
            tenant_name = '(' + item.parent_tenant_name + ') ' + item.tenant_name;
          } else {
            tenant_name = item.tenant_name;
          }
          return {
            tenant_name: item.parent_tenant_name ? `(${item.parent_tenant_name}) ${item.tenant_name}` : item.tenant_name ?? '',
            campaign_name: item.campaign_name ?? 'N/A', // 'N/A'는 예시로, 적절한 기본값 사용
            campaign_company_name: item.campaign_company_name ?? 'N/A',
            campaign_status: formatted_campaign_status ?? 'N/A',
            campaign_object: item.campaign_object ?? 'N/A',
            campaign_purpose: item.campaign_purpose ?? 'N/A',
            target_count: item.target_count ?? 0,
            success_count: item.success_count ?? 0,
            click_count: item.click_count ?? 0,
            click_rate: item.click_rate ?? '0%',
            send_request_dtm: formattedSendRequestDtm,
            request_dtm: formattedRequestDtm,
            send_request_dtm_origin: item.send_request_dtm ?? '',
            primary_customer_reference_id: item.primary_customer_reference_id ?? '',
          };


        });
        this.sendHistoryList = this.sendHistoryList.concat(sendHistoryList);



        this.isLoading = false;
        if (this.result_count >= this.maxItems) {
          alert('조회결과가 ' + this.maxItems + '건이 넘습니다. 조회조건을 조정해 주세요.');
          return;
        }
        if (res.data.data.length == this.itemsPerPage) {
          //  console.log('getting more page');
          this.appendData();
        }
      })
        .catch(error => {
          // Handle error response
          console.error('Error:', error);
          // You can display an error message to the user or perform any other necessary actions
          alert('Timeout이 발생했습니다. 조회조건을 수정하고 다시 조회하세요.');

          // Reset any relevant state variables if needed

          this.isLoading = false;
        });
    }
  },
};
</script>
<style>
.spinner-icon {
  font-size: 40px;
}

.customize-table {
  --easy-table-border: 0px;
}
</style>
