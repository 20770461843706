<template>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <title>SK(주) C&C 대량 단체문자 발송 - Message To-Go | SMS, LMS, 알림톡 API 기업 문자 서비스</title>
    <link rel="icon" href="https://to-go.io/sk_logo.jpg" type="image/jpg">
<!--    <script src="https://kit.fontawesome.com/42d5adcbca.js" crossorigin="anonymous"></script>-->
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-icons.css">
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-svg.css">
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/soft-ui-dashboard.min.css?v=1.0.2">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700">
    <link rel="stylesheet" href="../assets/css/theme.css">
    <link rel="stylesheet" href="../assets/css/loopple/loopple.css">
  </head>

  <body class="g-sidenav-show">
    <div class="container position-sticky z-index-sticky top-0">
            <div class="row">
              <div class="col-12">
                  <nav class="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
                      <div class="container-fluid">
                          <!-- <a class="navbar-brand font-weight-bolder ms-sm-3" href="javascript:;" rel="tooltip" title="Designed and Coded by Creative Tim" data-placement="bottom">Message To-Go</a> -->
                          <a class="navbar-brand ms-sm-3" href="/home">
                            <!-- <img src="../assets/img/m2g-logo-only.png" alt="Logo" height="30"> -->
                            <img src="../assets/img/SK_logo.svg" alt="Your SVG Image" style="position: relative; width: 30%; height: 20%; margin-top: -10px; margin-bottom: -10px; margin-right: 10px; margin-left: -10px;">
                          <!-- <span class="font-weight-bolder"> Message To-Go</span>                           -->
                          </a>
                          <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                              <span class="navbar-toggler-icon mt-2">
                                  <span class="navbar-toggler-bar bar1"></span>
                                  <span class="navbar-toggler-bar bar2"></span>
                                  <span class="navbar-toggler-bar bar3"></span>
                              </span>
                          </button>
                          <div id="navigation" class="collapse navbar-collapse pt-3 pb-2 py-lg-0">
                              <ul class="navbar-nav navbar-nav-hover ms-lg-12 ps-lg-5 w-100">
                                  <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="/home#channels" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">채널소개</span>
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="/home#features" aria-expanded="false">
                                        <span class="font-weight-bolder" style="font-size: larger;">기능</span>
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="/home#product" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">요금제</span>
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/contactus" aria-expanded="false">
                                        <span class="font-weight-bolder" style="font-size: larger;">문의하기</span>
                                      </router-link>
                                  </li>
                                  <li class="nav-item ms-lg-auto my-auto ms-3 ms-lg-0">
                                    <router-link class="btn btn-sm  bg-gradient-primary  btn-round mb-0 me-1 mt-2 mt-md-0" :to="`/authentication/signin/basic`" style="font-size: medium;">
                                      회원가입/로그인
                                    </router-link>
                                  </li>
                                </ul>
                          </div>
                      </div>
                  </nav>
              </div>
          </div>
      </div>
      <section class="py-5 position-relative">
          <div class="container">
              <div class="row mt-5">
                  <div class="col-lg-10 mx-auto">
                      <div class="card">
                          <div class="row">
                              <div class="col-lg-5 d-flex">                                
                                  <div class="bg-gradient-dark my-lg-3 ms-lg-3 border-radius-md">
                                      <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/shapes/waves-white.svg" alt="pattern-lines" class="position-absolute start-0 top-0 h-100 opacity-6">
                                      <div class="card-body p-5 position-relative">
                                          <img src="../assets/img/logo-m2g.png" class="img-fluid mb-4">
                                          <h3 class="text-white text-center">사전예약</h3>
                                          <p class="text-white opacity-8 mb-4 text-center">오픈 후 다양한 해택 제공예정</p>
                                          <!-- <div class="d-flex p-2 text-white">
                                              <div>
                                                  <i class="fas fa-phone text-sm" aria-hidden="true"></i>
                                              </div>
                                              <div class="ps-3">
                                                  <span class="text-sm opacity-8">(010) 772 100 200</span>
                                              </div>
                                          </div> -->
                                          <div class="d-flex p-2">
                                              <div>
                                                  <i class="fas fa-envelope text-white" aria-hidden="true"></i>
                                              </div>
                                              <div class="ps-3">
                                                  <a href="mailto:message.to-go@sk.com" class="text-white"><span class="text-sm opacity-8">message.to-go@sk.com</span></a>
                                              </div>
                                          </div>
                                          <!-- <div class="d-flex p-2 text-white">
                                              <div>
                                                  <i class="fas fa-map-marker-alt text-sm" aria-hidden="true"></i>
                                              </div>
                                              <div class="ps-3">
                                                  <span class="text-sm opacity-8">730 Dyonisie Wolf <br> Bucharest, RO 010458</span>
                                              </div>
                                          </div> -->
                                          <div class="mt-4">
                                              <button id="copy-link-btn" type="button" class="btn btn-icon-only btn-link text-white btn-lg mb-0" data-toggle="tooltip" data-placement="bottom" title="Copy link">
                                                  <i class="fas fa-share-alt"></i>
                                              </button>
                                              <button id="facebook" type="button" class="btn btn-icon-only btn-link text-white btn-lg mb-0" data-toggle="tooltip" data-placement="bottom" data-original-title="Log in with Facebook">
                                                  <i class="fab fa-facebook" aria-hidden="true"></i>
                                              </button>
                                              <button id="twitter" type="button" class="btn btn-icon-only btn-link text-white btn-lg mb-0" data-toggle="tooltip" data-placement="bottom" data-original-title="Log in with Twitter">
                                                  <i class="fab fa-twitter" aria-hidden="true"></i>
                                              </button>
                                              <button id="kakao" type="button" class="btn btn-icon-only btn-link text-white btn-lg mb-0" data-toggle="tooltip" data-placement="bottom" data-original-title="Log in with Instagram">
                                                    <svg width="43px" height="43px" viewBox="0 2 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                        <title>kakao</title>
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                                <g transform="translate(1716.000000, 291.000000)">
                                                                    <g transform="translate(4.000000, 301.000000)">
                                                                        <path fill="none" d="M0 0h24v24H0z"/> <path d="M12 3c5.799 0 10.5 3.664 10.5 8.185 0 4.52-4.701 8.184-10.5 8.184a13.5 13.5 0 0 1-1.727-.11l-4.408 2.883c-.501.265-.678.236-.472-.413l.892-3.678c-2.88-1.46-4.785-3.99-4.785-6.866C1.5 6.665 6.201 3 12 3zm5.907 8.06l1.47-1.424a.472.472 0 0 0-.656-.678l-1.928 1.866V9.282a.472.472 0 0 0-.944 0v2.557a.471.471 0 0 0 0 .222V13.5a.472.472 0 0 0 .944 0v-1.363l.427-.413 1.428 2.033a.472.472 0 1 0 .773-.543l-1.514-2.155zm-2.958 1.924h-1.46V9.297a.472.472 0 0 0-.943 0v4.159c0 .26.21.472.471.472h1.932a.472.472 0 1 0 0-.944zm-5.857-1.092l.696-1.707.638 1.707H9.092zm2.523.488l.002-.016a.469.469 0 0 0-.127-.32l-1.046-2.8a.69.69 0 0 0-.627-.474.696.696 0 0 0-.653.447l-1.661 4.075a.472.472 0 0 0 .874.357l.33-.813h2.07l.299.8a.472.472 0 1 0 .884-.33l-.345-.926zM8.293 9.302a.472.472 0 0 0-.471-.472H4.577a.472.472 0 1 0 0 .944h1.16v3.736a.472.472 0 0 0 .944 0V9.774h1.14c.261 0 .472-.212.472-.472z"/>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-7">
                                  <form id="contact-form" method="post" autocomplete="off">
                                      <div class="card-body position-relative p-3 ps-0">
                                          <div class="row mt-4">
                                              <div class="col-md-6">
                                                  <label>이름</label>
                                                  <div class="input-group mb-4">
                                                      <input class="form-control" placeholder="홍길동" aria-label="Name..." type="text" name="name">
                                                  </div>
                                              </div>
                                              <div class="col-md-6 ps-2">
                                                  <label>이메일</label>
                                                  <div class="input-group mb-4">
                                                      <input type="email" class="form-control" placeholder="eg. message.to-go@sk.com" name="email">
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="mb-4">
                                              <label>전화번호</label>
                                              <div class="input-group mb-3">
                                                  <!-- <select class="input-group-text" id="inputGroupSelect01" name="selection">
                                                      <option selected="">RU</option>
                                                      <option value="1">EN</option>
                                                      <option value="2">US</option>
                                                      <option value="3">AR</option>
                                                  </select> -->
                                                  <input class="form-control" placeholder="010-1234-5678" aria-label="Phone Number..." type="text" name="phone_number" pattern="[0-9\s.-]*" oninput="checkInputValidity(this)">
                                                  <!-- <input type="number" class="form-control ps-3" aria-label="Phone Number" placeholder="(010) 1234 5678" name="phone_number" step="1" inputmode="numeric"> -->
                                              </div>
                                          </div>                           
                                          <label>어떻게 메시지투고에 대해 알게 되었나요?</label>
                                          <div class="d-flex">
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="survey_path1" class="form-check-input" type="radio" name="survey_path" value="person" required>
                                                      <label class="form-check-label" for="survey_path1">
                                                          이메일 마케팅
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="survey_path2" class="form-check-input" type="radio" name="survey_path" value="advertisement">
                                                      <label class="form-check-label" for="survey_path2">
                                                          포탈 검색
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="survey_path3" class="form-check-input" type="radio" name="survey_path" value="blog">
                                                      <label class="form-check-label" for="survey_path3">
                                                          게시판/블로그
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="survey_path4" class="form-check-input" type="radio" name="survey_path" value="semenar">
                                                      <label class="form-check-label" for="survey_path4">
                                                          세미나
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check">
                                                      <input id="survey_path5" class="form-check-input" type="radio" name="survey_path" value="other">
                                                      <label class="form-check-label" for="survey_path5">
                                                          기타
                                                      </label>
                                                  </div>
                                              </div>
                                          </div>
                                          <label>현재 메시지 솔루션을 사용하고 계십니까?</label>
                                          <div class="d-flex">
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="solution_path1" class="form-check-input" type="radio" name="message_solution_use_yn" value="yes" required>
                                                      <label class="form-check-label" for="solution_path1">
                                                          네
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="solution_path2" class="form-check-input" type="radio" name="message_solution_use_yn" value="no">
                                                      <label class="form-check-label" for="solution_path2">
                                                          아니오
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check">
                                                      <input id="solution_path_name" class="form-control" type="text" name="message_solution_name" placeholder="사용중인 솔루션 이름" hidden>
                                                      <label id="solution_path_label" name="message_solution_label" hidden>솔루션 명</label>
                                                  </div>
                                              </div>
                                          </div>
                                          <label>메시지 솔루션 도입/변경을 검토 중 이십니까?</label>
                                          <div class="d-flex">
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="consideration_path1" class="form-check-input" type="radio" name="solution_consideration" value="new" required>
                                                      <label class="form-check-label" for="consideration_path1">
                                                          도입 검토 중
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="consideration_path2" class="form-check-input" type="radio" name="solution_consideration" value="change">
                                                      <label class="form-check-label" for="consideration_path2">
                                                          변경 검토 중
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="consideration_path3" class="form-check-input" type="radio" name="solution_consideration" value="no_plan">
                                                      <label class="form-check-label" for="consideration_path3">
                                                          현재 계획없음
                                                      </label>
                                                  </div>
                                              </div>
                                          </div>
                                          <label id="solution_change_label" hidden>도입/변경 예정일은 어떻게 됩니까?</label>
                                          <div class="d-flex">
                                              <div id="solution_change_input1" hidden>
                                                  <div class="form-check me-3">
                                                      <input id="solution_change_path1" class="form-check-input" type="radio" name="solution_change_date" value="less_than_6months">
                                                      <label class="form-check-label" for="solution_change_path1">
                                                          6개월 이내
                                                      </label>
                                                  </div>
                                              </div>
                                              <div id="solution_change_input2" hidden>
                                                  <div class="form-check me-3">
                                                      <input id="solution_change_path2" class="form-check-input" type="radio" name="solution_change_date" value="more_than_6months">
                                                      <label class="form-check-label" for="solution_change_path2">
                                                          6개월~12개월
                                                      </label>
                                                  </div>
                                              </div>
                                              <div id="solution_change_input3" hidden>
                                                  <div class="form-check me-3">
                                                      <input id="solution_change_path3" class="form-check-input" type="radio" name="solution_change_date" value="more_than_1year">
                                                      <label class="form-check-label" for="solution_change_path3">
                                                          1년 이상
                                                      </label>
                                                  </div>
                                              </div>
                                              <div id="solution_change_input4" hidden>
                                                  <div class="form-check me-3">
                                                      <input id="solution_change_path4" class="form-check-input" type="radio" name="solution_change_date" value="dont_know">
                                                      <label class="form-check-label" for="solution_change_path4">
                                                          모름
                                                      </label>
                                                  </div>
                                              </div>
                                          </div>
                                          <label id="solution_price_label" hidden>도입/변경 예산을 얼마입니까?(월평균)</label>
                                          <div class="d-flex">
                                              <div id="solution_price_input1" hidden>
                                                  <div class="form-check me-3">
                                                      <input id="solution_price_path1" class="form-check-input" type="radio" name="solution_price" value="50000">
                                                      <label class="form-check-label" for="solution_price_path1">
                                                          10만원
                                                      </label>
                                                  </div>
                                              </div>
                                              <div id="solution_price_input2" hidden>
                                                  <div class="form-check me-3">
                                                      <input id="solution_price_path2" class="form-check-input" type="radio" name="solution_price" value="100000">
                                                      <label class="form-check-label" for="solution_price_path2">
                                                          100만원
                                                      </label>
                                                  </div>
                                              </div>
                                              <div id="solution_price_input3" hidden>
                                                  <div class="form-check me-3">
                                                      <input id="solution_price_path3" class="form-check-input" type="radio" name="solution_price" value="500000">
                                                      <label class="form-check-label" for="solution_price_path3">
                                                          1000만원
                                                      </label>
                                                  </div>
                                              </div>
                                              <div id="solution_price_input4" hidden>
                                                  <div class="form-check me-3">
                                                      <input id="solution_price_path4" class="form-check-input" type="radio" name="solution_price"  value="1000000">
                                                      <label class="form-check-label" for="solution_price_path4">
                                                          1억원
                                                      </label>
                                                  </div>
                                              </div>
                                          </div>
                                          <label>메시지 솔루션에 가장 중요한 요소는 무엇이라고 생각하십니까?</label>
                                          <div class="d-flex">
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="choice_reason_path1" class="form-check-input" type="radio" name="solution_choice_reason1" value="price" required>
                                                      <label class="form-check-label" for="choice_reason_path1">
                                                        가격
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="choice_reason_path2" class="form-check-input" type="radio" name="solution_choice_reason1" value="reliability">
                                                      <label class="form-check-label" for="choice_reason_path2">
                                                        장애시간
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="choice_reason_path3" class="form-check-input" type="radio" name="solution_choice_reason1" value="performance">
                                                      <label class="form-check-label" for="choice_reason_path3">
                                                        대량처리속도
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="choice_reason_path4" class="form-check-input" type="radio" name="solution_choice_reason1" value="tech_support">
                                                      <label class="form-check-label" for="choice_reason_path4">
                                                        무료전환지원<br>(현재구조 유지)
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="choice_reason_path5" class="form-check-input" type="radio" name="solution_choice_reason1" value="tech_support">
                                                      <label class="form-check-label" for="choice_reason_path5">
                                                        사용편리성
                                                      </label>
                                                  </div>
                                              </div>
                                          </div>
                                          <label>메시지 솔루션에 두번째로 중요한 요소는 무엇이라고 생각하십니까?</label>
                                          <div class="d-flex">
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="choice_reason2_path1" class="form-check-input" type="radio" name="solution_choice_reason2" value="price" required>
                                                      <label class="form-check-label" for="choice_reason2_path1">
                                                          가격
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="choice_reason2_path2" class="form-check-input" type="radio" name="solution_choice_reason2" value="reliability">
                                                      <label class="form-check-label" for="choice_reason2_path2">
                                                          장애시간
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="choice_reason2_path3" class="form-check-input" type="radio" name="solution_choice_reason2" value="performance">
                                                      <label class="form-check-label" for="choice_reason2_path3">
                                                          대량처리속도
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="choice_reason2_path4" class="form-check-input" type="radio" name="solution_choice_reason2" value="tech_support">
                                                      <label class="form-check-label" for="choice_reason2_path4">
                                                        무료전환지원<br>(현재구조 유지)
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="choice_reason2_path5" class="form-check-input" type="radio" name="solution_choice_reason2" value="usability">
                                                      <label class="form-check-label" for="choice_reason2_path5">
                                                          사용편리성
                                                      </label>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="mb-4">
                                              <label>추가적인 요소가 있다면 어떤 것이 필요하신가요?</label>
                                              <div class="input-group mb-3">
                                                  <!-- <select class="input-group-text" id="inputGroupSelect01" name="selection">
                                                      <option selected="">RU</option>
                                                      <option value="1">EN</option>
                                                      <option value="2">US</option>
                                                      <option value="3">AR</option>
                                                  </select> -->
                                                  <input id="solution_choice_reason_text" class="form-control" placeholder="가격이 큰차이 없다면 기술지원과 안정성이 제공된다면.." type="text" name="solution_choice_reason_text" required>
                                                  <!-- <input type="number" class="form-control ps-3" aria-label="Phone Number" placeholder="(010) 1234 5678" name="phone_number" step="1" inputmode="numeric"> -->
                                              </div>
                                          </div>
                                          <label>어떠한 방식의 메시지 전송을 선호 하십니까?</label>
                                          <div class="d-flex">
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="solution_use_path1" class="form-check-input" type="radio" name="solution_use_type" value="web" required>
                                                      <label class="form-check-label" for="solution_use_path1">
                                                          웹 페이지
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="solution_use_path2" class="form-check-input" type="radio" name="solution_use_type" value="api">
                                                      <label class="form-check-label" for="solution_use_path2">
                                                          API 호출
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="solution_use_path3" class="form-check-input" type="radio" name="solution_use_type" value="db">
                                                      <label class="form-check-label" for="solution_use_path3">
                                                          DB 연동
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="solution_use_path4" class="form-check-input" type="radio" name="solution_use_type" value="pc_program">
                                                      <label class="form-check-label" for="solution_use_path4">
                                                          PC 프로그램
                                                      </label>
                                                  </div>
                                              </div>
                                          </div>
                                          <label>최우선으로 선호하는 메시지솔루션의 전송채널은 무엇입니까?</label>
                                          <div class="d-flex">
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="message_type_path1" class="form-check-input" type="radio" name="message_type" value="sms" required>
                                                      <label class="form-check-label" for="message_type_path1">
                                                          SMS
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="message_type_path2" class="form-check-input" type="radio" name="message_type" value="lms">
                                                      <label class="form-check-label" for="message_type_path2">
                                                          LMS
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="message_type_path3" class="form-check-input" type="radio" name="message_type" value="mms">
                                                      <label class="form-check-label" for="message_type_path3">
                                                          MMS
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="message_type_path4" class="form-check-input" type="radio" name="message_type" value="kakao">
                                                      <label class="form-check-label" for="message_type_path4">
                                                          카카오
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="message_type_path5" class="form-check-input" type="radio" name="message_type" value="apppush">
                                                      <label class="form-check-label" for="message_type_path5">
                                                          앱푸시
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="message_type_path6" class="form-check-input" type="radio" name="message_type" value="rcs">
                                                      <label class="form-check-label" for="message_type_path6">
                                                          RCS
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="message_type_path7" class="form-check-input" type="radio" name="message_type" value="other">
                                                      <label class="form-check-label" for="message_type_path7">
                                                          기타
                                                      </label>
                                                  </div>
                                              </div>
                                          </div>
                                          <label>가격 및 기능이 모두 동일하다면 어떤 기준으로 메시지 솔루션을 선택하시겠습니까?</label>
                                          <div class="d-flex">
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="product_type_path1" class="form-check-input" type="radio" name="product_type" value="brand" required>
                                                      <label class="form-check-label" for="product_type_path1">
                                                          브랜드/인지도
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="product_type_path2" class="form-check-input" type="radio" name="product_type" value="design">
                                                      <label class="form-check-label" for="product_type_path2">
                                                          UI 디자인
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="product_type_path3" class="form-check-input" type="radio" name="product_type" value="channel">
                                                      <label class="form-check-label" for="product_type_path3">
                                                          채널 다양성<br>(우편, 이메일, 팩스, 네이버, Slack 등)
                                                      </label>
                                                  </div>
                                              </div>
                                              <!-- <div>
                                                  <div class="form-check me-3">
                                                      <a href="/home#product" target="_blank" class="text-dark"><u><label style="color: black; text-decoration: underline;" onmouseover="this.style.cursor='pointer';">상품확인</label></u></a>
                                                  </div>
                                              </div> -->

                                              
                                          </div>
                                          <label>아래 특수 기능 중 어떤 기능이 가장 좋을 것 같습니까?</label>
                                          <div class="d-flex">
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="product_feature1" class="form-check-input" type="radio" name="feature" value="template" required>
                                                      <label class="form-check-label" for="product_feature1">
                                                          다양한 메시지 템플릿 제공
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="product_feature2" class="form-check-input" type="radio" name="feature" value="attachment">
                                                      <label class="form-check-label" for="product_feature2">
                                                          메시지 내 문서/파일 첨부기능
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="product_feature3" class="form-check-input" type="radio" name="feature" value="alarm">
                                                      <label class="form-check-label" for="product_feature3">
                                                          예약메시지 전송결과(성공/실패 건수) 자동알림<br>
                                                      </label>
                                                  </div>
                                              </div>
                                              <!-- <div>
                                                  <div class="form-check me-3">
                                                      <a href="/home#product" target="_blank" class="text-dark"><u><label style="color: black; text-decoration: underline;" onmouseover="this.style.cursor='pointer';">상품확인</label></u></a>
                                                  </div>
                                              </div> -->

                                              
                                          </div>
                                          <label>사전예약을 선택하시면 서비스 출시시 특별 할인가로 서비스 제공 예정입니다. 사전예약 하시겠습니까?</label>
                                          <div class="d-flex">
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="pre_register_yn_path1" class="form-check-input" type="radio" name="pre_register_yn" value="yes" required>
                                                      <label class="form-check-label" for="pre_register_yn_path1">
                                                          예
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="pre_register_yn_path2" class="form-check-input" type="radio" name="pre_register_yn" value="no">
                                                      <label class="form-check-label" for="pre_register_yn_path2">
                                                          아니오
                                                      </label>
                                                  </div>
                                              </div>                                            
                                          </div>
                                          <div class="form-group mt-4">
                                              <label>메시지투고에 하시고 싶은 말씀 자유롭게 적어주세요.(원하는 기능, 방식, 가격/구독 정책 등)</label>
                                              <textarea id="solution_suggestions" class="form-control" name="solution_suggestions_text1" rows="3"></textarea>
                                          </div>
                                          <div class="col-md-6">
                                              <div class="form-check form-switch mb-4">
                                                  <input id="flexSwitchCheckDefault" class="form-check-input" type="checkbox" name="agree" required>
                                                  <label class="form-check-label" for="flexSwitchCheckDefault"> 동의 <a href="/home/informationuse" class="text-dark"><u>개인정보 수집 및 이용</u></a></label>
                                              </div>
                                          </div>
                                          <div class="text-end">
                                              <button type="submit" class="btn bg-gradient-dark mb-0">Send message</button>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  <!--  <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/bootstrap.min.js"></script>-->
  <!--  <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/soft-ui-dashboard.min.js?v=1.0.2"></script>-->
    <!-- <script src="../assets/js/loopple/loopple.js"></script> -->
  </body>
<!--  <script>-->
<!--    document.addEventListener('DOMContentLoaded', function() {-->
<!--      // Remove the "active" class from the afterpay tab-->
<!--      var afterpayTab = document.getElementById('tabs-iconpricing-tab-2');-->
<!--      afterpayTab.classList.remove('active');-->
<!--      // Add the "active" class to the prepaid tab-->
<!--      var prepaidTab = document.getElementById('tabs-iconpricing-tab-1');-->
<!--      prepaidTab.classList.add('active');-->
<!--    });-->
<!--  </script>-->
</template>

<script>
import ApiCall from "@/Interface/ApiCall";
import axios from 'axios';
import { useStore } from 'vuex';
export default {
  name: 'Survey',
  beforeMount() {
    const store = useStore();
    store.commit('toggleEveryDisplay', false);
  },
  beforeUnmount() {
    const store = useStore();
    store.commit('toggleEveryDisplay', true);
  },
  mounted() {
    document.addEventListener('DOMContentLoaded', () => {
        const copyLinkBtn = document.getElementById('copy-link-btn');
        window.onload = () => {
            const url = "window.location.href";

            copyLinkBtn.addEventListener('click', () => {
                navigator.clipboard.writeText(url)
                    .then(() => {
                        alert('메시지투고 설문이벤트 링크를 복사하였습니다.');
                    })
                    .catch(() => {
                        alert('링크복사를 실패하였습니다.');
                    });
            });
        };

        // Get the form element
        const form = document.querySelector('#contact-form');

        function checkInputValidity(input) {
            if (input.value.replace(/\D/g, '').length !== 11) {
                input.setCustomValidity("11자리 휴대폰 번호만 입력 가능합니다.");
            // } else if (!/^\(\d{3}\)\s\d{4}\s\d{4}$/.test(input.value)) {
            //     input.setCustomValidity("Please enter a valid phone number.");
            } else {
                input.setCustomValidity("");
            }
        };

        // 솔루션도입예정일 경우 예정일 확인
        const newSolutionRadioButton = document.querySelector('#consideration_path1');
        const changeSolutionRadioButton = document.querySelector('#consideration_path2');
        const noSolutionRadioButton = document.querySelector('#consideration_path3');
        const changeDateLabel = document.querySelector('#solution_change_label');
        const changeDateInput1 = document.querySelector('#solution_change_input1');
        const changeDateInput2 = document.querySelector('#solution_change_input2');
        const changeDateInput3 = document.querySelector('#solution_change_input3');
        const changeDateInput4 = document.querySelector('#solution_change_input4');
        const changeDateTextbox1 = document.getElementById('solution_change_path1');
        const changeDateTextbox2 = document.getElementById('solution_change_path2');
        const changeDateTextbox3 = document.getElementById('solution_change_path3');
        const changeDateTextbox4 = document.getElementById('solution_change_path4');
        const changePriceLabel = document.querySelector('#solution_price_label');
        const changePriceInput1 = document.querySelector('#solution_price_input1');
        const changePriceInput2 = document.querySelector('#solution_price_input2');
        const changePriceInput3 = document.querySelector('#solution_price_input3');
        const changePriceInput4 = document.querySelector('#solution_price_input4');
        const changePriceTextbox1 = document.getElementById('solution_price_path1');
        const changePriceTextbox2 = document.getElementById('solution_price_path2');
        const changePriceTextbox3 = document.getElementById('solution_price_path3');
        const changePriceTextbox4 = document.getElementById('solution_price_path4');
        
        newSolutionRadioButton.addEventListener('change', () => {
            if (newSolutionRadioButton.checked) {
                changeDateLabel.hidden = false;
                changeDateInput1.hidden = false;
                changeDateInput2.hidden = false;
                changeDateInput3.hidden = false;
                changeDateInput4.hidden = false;
                changeDateTextbox1.setAttribute('required', true);
                changePriceLabel.hidden = false;
                changePriceInput1.hidden = false;
                changePriceInput2.hidden = false;
                changePriceInput3.hidden = false;
                changePriceInput4.hidden = false;
                changePriceTextbox1.setAttribute('required', true);                
            } else {
                changeDateLabel.hidden = true;
                changeDateInput1.hidden = true;
                changeDateInput2.hidden = true;
                changeDateInput3.hidden = true;
                changeDateInput4.hidden = true;
                changeDateTextbox1.removeAttribute('required'); 
                changeDateTextbox1.checked = false;
                changeDateTextbox2.checked = false;
                changeDateTextbox3.checked = false;
                changeDateTextbox4.checked = false;
                changeDateTextbox1.value = '';
                changeDateTextbox2.value = '';
                changeDateTextbox3.value = '';
                changeDateTextbox4.value = '';
                changePriceLabel.hidden = true;
                changePriceInput1.hidden = true;
                changePriceInput2.hidden = true;
                changePriceInput3.hidden = true;
                changePriceInput4.hidden = true;
                changePriceTextbox1.removeAttribute('required'); 
                changePriceTextbox1.checked = false;
                changePriceTextbox2.checked = false;
                changePriceTextbox3.checked = false;
                changePriceTextbox4.checked = false;
                changePriceTextbox1.value = '';
                changePriceTextbox2.value = '';
                changePriceTextbox3.value = '';
                changePriceTextbox4.value = '';
            }
        });
        changeSolutionRadioButton.addEventListener('change', () => {
            if (changeSolutionRadioButton.checked) {
                changeDateLabel.hidden = false;
                changeDateInput1.hidden = false;
                changeDateInput2.hidden = false;
                changeDateInput3.hidden = false;
                changeDateInput4.hidden = false;
                changeDateTextbox1.setAttribute('required', true);
                changePriceLabel.hidden = false;
                changePriceInput1.hidden = false;
                changePriceInput2.hidden = false;
                changePriceInput3.hidden = false;
                changePriceInput4.hidden = false;
                changePriceTextbox1.setAttribute('required', true); 
            } else {
                changeDateLabel.hidden = true;
                changeDateInput1.hidden = true;
                changeDateInput2.hidden = true;
                changeDateInput3.hidden = true;
                changeDateInput4.hidden = true;
                changeDateTextbox1.removeAttribute('required'); 
                changeDateTextbox1.checked = false;
                changeDateTextbox2.checked = false;
                changeDateTextbox3.checked = false;
                changeDateTextbox4.checked = false;
                changeDateTextbox1.value = '';
                changeDateTextbox2.value = '';
                changeDateTextbox3.value = '';
                changeDateTextbox4.value = '';
                changePriceLabel.hidden = true;
                changePriceInput1.hidden = true;
                changePriceInput2.hidden = true;
                changePriceInput3.hidden = true;
                changePriceInput4.hidden = true;
                changePriceTextbox1.removeAttribute('required'); 
                changePriceTextbox1.checked = false;
                changePriceTextbox2.checked = false;
                changePriceTextbox3.checked = false;
                changePriceTextbox4.checked = false;
                changePriceTextbox1.value = '';
                changePriceTextbox2.value = '';
                changePriceTextbox3.value = '';
                changePriceTextbox4.value = '';
            }
        });
        noSolutionRadioButton.addEventListener('change', () => {
            if (noSolutionRadioButton.checked) {
                changeDateLabel.hidden = true;
                changeDateInput1.hidden = true;
                changeDateInput2.hidden = true;
                changeDateInput3.hidden = true;
                changeDateInput4.hidden = true;
                changeDateTextbox1.removeAttribute('required'); 
                changeDateTextbox1.checked = false;
                changeDateTextbox2.checked = false;
                changeDateTextbox3.checked = false;
                changeDateTextbox4.checked = false;
                changeDateTextbox1.value = '';
                changeDateTextbox2.value = '';
                changeDateTextbox3.value = '';
                changeDateTextbox4.value = '';
                changePriceLabel.hidden = true;
                changePriceInput1.hidden = true;
                changePriceInput2.hidden = true;
                changePriceInput3.hidden = true;
                changePriceInput4.hidden = true;
                changePriceTextbox1.removeAttribute('required'); 
                changePriceTextbox1.checked = false;
                changePriceTextbox2.checked = false;
                changePriceTextbox3.checked = false;
                changePriceTextbox4.checked = false;
                changePriceTextbox1.value = '';
                changePriceTextbox2.value = '';
                changePriceTextbox3.value = '';
                changePriceTextbox4.value = '';
            } else {
                changeDateLabel.hidden = false;
                changeDateInput1.hidden = false;
                changeDateInput2.hidden = false;
                changeDateInput3.hidden = false;
                changeDateInput4.hidden = false;
                changeDateTextbox1.setAttribute('required', true); 
                changePriceLabel.hidden = false;
                changePriceInput1.hidden = false;
                changePriceInput2.hidden = false;
                changePriceInput3.hidden = false;
                changePriceInput4.hidden = false;
                changePriceTextbox1.setAttribute('required', true);
            }
        });


        // Get the "예" radio button
        const yesRadioButton = document.querySelector('#solution_path1');

        // Get the input text box
        const solutionNameInput = document.querySelector('#solution_path_name');

        const solutionNameLabel = document.querySelector('#solution_path_label');
        const solutionNameTextbox = document.getElementById('solution_path_name');
        // Add event listener to the radio buttons to show or hide the input text box
        yesRadioButton.addEventListener('change', () => {
            if (yesRadioButton.checked) {
                solutionNameInput.hidden = false;
                solutionNameLabel.hidden = false;
            } else {
                solutionNameInput.hidden = true;
                solutionNameLabel.hidden = true;
                solutionNameTextbox.value = '';
            }
        });

        // Get the "아니오" radio button
        const noRadioButton = document.querySelector('#solution_path2');

        // Add event listener to the radio buttons to show or hide the input text box
        noRadioButton.addEventListener('change', () => {
            if (noRadioButton.checked) {
                solutionNameInput.hidden = true;
                solutionNameLabel.hidden = true;
                solutionNameTextbox.value = '';
            } else {
                solutionNameInput.hidden = false;
                solutionNameLabel.hidden = false;
            }
        });

        form.addEventListener('submit', async (event) => {
            event.preventDefault(); // prevent default form submission behavior

            // Get the form data
            const formData = new FormData(form);

            // Convert the form data to an object
            const formObject = {};
            formData.forEach((value, key) => {
                formObject[key] = value;
            });

            formObject["pk"] = 'survey';
            formObject["sk"] = formObject['email'];

            // Log the form data to the console
            // console.log(formObject);

            try {
                // Make an HTTP POST request to your Lambda function via API Gateway

                await axios.post('https://453mfjezwk.execute-api.ap-northeast-2.amazonaws.com/prod/survery', formObject);
                // console.log(JSON.stringify(response.data));
                // Redirect to another URL with a success alert message
                alert('사전예약이 신청 되었습니다. 감사합니다!');
                const redirectURL = 'https://message.to-go.io';
                window.location.href = redirectURL;
            } catch (error) {
                console.error(error);
                alert('사전예약 등록이 실패했습니다.');
            }
        });

        // Get the phone number input field
        const phoneNumberInput = document.querySelector('input[name="phone_number"]');

        // Add event listener to the phone number input field
        phoneNumberInput.addEventListener('input', () => {
            checkInputValidity(phoneNumberInput);
        });


        // Get all the social buttons
        const socialButtons = document.querySelectorAll('.btn-link');

        // Loop through each button and add an event listener
        socialButtons.forEach(button => {
        button.addEventListener('click', () => {
            const id = button.getAttribute('id');
            let link = ''
            if (id == "facebook"){
                link = "https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=https%3A%2F%2Fmessage.to-go.io%2Fhome%2Fsurvey&display=popup&ref=plugin&src=share_button";
            } else if (id == "twitter"){
                link = "http://twitter.com/share?original_referer=https://message.to-go.io/home/survey";
            } else if (id == "kakao"){
                link = "http://pf.kakao.com/_dkaHxj";
            }
            // Open the link in a new window
            window.open(link, '_blank');
          });
        });
    });
  },
  methods: {
    getQueryString() {
      return window.location.search;
    },
  },
};

</script>

<style scoped></style>
