<template>
  <div
    class="input-group"
    :class="`input-group-${variant} ${getStatus(error, success)}`"
  >
    <label :class="[variant === 'static' ? '' : 'form-label', getLabelSizeClass(labelSize)]">{{ label }}</label>
    <input
      v-bind="$attrs"
      :id="id"
      :type="type"
      class="form-control"
      :class="getInputSizeClass(size)"
      :name="name"
      :placeholder="placeholder"
      :required="isRequired"
      :disabled="disabled"
      :value="modelValue"
      v-on="inputListeners"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<style scoped>
.form-label-sm {
  font-size: 0.675rem; /* Adjust the font size as needed */
}

.form-label-lg {
  font-size: 0.875rem; /* Adjust the font size as needed */
}
</style>

<script>
import setMaterialInput from "@/assets/js/material-input.js";

export default {
  name: "MaterialInput",
  props: {
    variant: {
      type: String,
      default: "outline",
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
      validator: (value) => ["default", "sm", "lg"].includes(value),
    },
    labelSize: {
      type: String,
      default: "default",
      validator: (value) => ["default", "sm", "lg"].includes(value),
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: "",
    },
    autoComplete: {
      type: String,
      default: 'off',
    }
  },
  emits: ["update:modelValue", 'input'],
  computed: {
    inputListeners() {
      return {
        ...this.$attrs,
        input: (event) => this.$emit("update:modelValue", event.target.value),
      };
    },
  },
  mounted() {
    setMaterialInput();
  },
  methods: {
    getLabelSizeClass(size) {
      let sizeValue;

      switch (size) {
        case "sm":
          sizeValue = "form-label-sm";
          break;
        case "lg":
          sizeValue = "form-label-lg";
          break;
        default:
          sizeValue = "";
      }

      return sizeValue;
    },
    getInputSizeClass(size) {
      let sizeValue;

      switch (size) {
        case "sm":
          sizeValue = "form-control-sm";
          break;
        case "lg":
          sizeValue = "form-control-lg";
          break;
        default:
          sizeValue = "";
      }

      return sizeValue;
    },
    getStatus: (error, success) => {
      let isValidValue;

      if (success) {
        isValidValue = "is-valid";
      } else if (error) {
        isValidValue = "is-invalid";
      } else {
        isValidValue = null;
      }

      return isValidValue;
    },
  },
};
</script>
