<template>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <title>SK(주) C&C 대량 단체문자 발송 - Message To-Go | SMS, LMS, 알림톡 API 기업 문자 서비스</title>
    <link rel="icon" href="https://to-go.io/sk_logo.jpg" type="image/jpg">
<!--    <script src="https://kit.fontawesome.com/42d5adcbca.js" crossorigin="anonymous"></script>-->
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-icons.css">
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-svg.css">
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/soft-ui-dashboard.min.css?v=1.0.2">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700">
    <link rel="stylesheet" href="../assets/css/theme.css">
    <link rel="stylesheet" href="../assets/css/loopple/loopple.css">
  </head>

  <body class="g-sidenav-show">
    <div class="container position-sticky z-index-sticky top-0">
        <div class="row">
            <div class="col-12">
                <nav class="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
                    <div class="container-fluid">
                        <!-- <a class="navbar-brand font-weight-bolder ms-sm-3" href="javascript:;" rel="tooltip" title="Designed and Coded by Creative Tim" data-placement="bottom">Message To-Go</a> -->
                        <a class="navbar-brand ms-sm-3" href="/home#top">                          
                          <!-- <img src="../assets/img/m2g-logo-only.png" alt="Logo" height="30"> -->
                          <img src="../assets/img/SK_logo.svg" alt="Your SVG Image" style="position: relative; width: 30%; height: 20%; margin-top: -10px; margin-bottom: -10px; margin-right: 10px; margin-left: -10px;">
                          <!-- <span class="font-weight-bolder"> Message To-Go</span>                           -->
                        </a>
                        <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon mt-2">
                                <span class="navbar-toggler-bar bar1"></span>
                                <span class="navbar-toggler-bar bar2"></span>
                                <span class="navbar-toggler-bar bar3"></span>
                            </span>
                        </button>
                        <div id="navigation" class="collapse navbar-collapse pt-3 pb-2 py-lg-0">
                            <ul class="navbar-nav navbar-nav-hover ms-lg-12 ps-lg-5 w-100">
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/product" aria-expanded="false">
                                    <span class="font-weight-bolder" style="font-size: larger;">채널소개</span>
                                  </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/product" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">기능</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/product" aria-expanded="false">
                                    <span class="font-weight-bolder" style="font-size: larger;">요금제</span>
                                  </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/contactus" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">문의하기</span>
                                    </router-link>
                                </li>
                                <li class="nav-item ms-lg-auto my-auto ms-3 ms-lg-0">
                                  <router-link class="btn btn-sm  bg-gradient-primary  btn-round mb-0 me-1 mt-2 mt-md-0" :to="`/authentication/signin/basic`"  style="font-size: medium;">
                                    회원가입/로그인
                                  </router-link>
                                </li>
                              </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
  <section class="py-3">
    <div class="bg-gradient-dark position-relative m-3 border-radius-xl">
      <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/shapes/waves-white.svg" alt="pattern-lines" class="position-absolute opacity-6 start-0 top-0 w-100">
      <div class="container pb-lg-9 pb-10 pt-7 postion-relative z-index-2">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h3 class="text-white">요금제 안내</h3>
            <p class="text-white">메시지 종류별 최저가에 사용하세요.<br>(VAT 별도)</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-7 mx-auto text-center">
            <div class="nav-wrapper mt-5 position-relative z-index-2">
              <ul id="tabs-pricing" class="nav nav-pills nav-fill flex-row p-1" role="tablist">
                <li class="nav-item">
                  <a id="tabs-iconpricing-tab-1" class="nav-link mb-0" data-bs-toggle="tab" href="#monthly" role="tab" aria-controls="monthly" aria-selected="true">
                    선불
                  </a>
                </li>
                <li class="nav-item">
                  <a id="tabs-iconpricing-tab-2" class="nav-link mb-0 active" data-bs-toggle="tab" href="#annual" role="tab" aria-controls="annual" aria-selected="false">
                    후불
                  </a>
                </li>
                <div class="moving-tab position-absolute nav-link" style="padding: 0px; width: 170px; transform: translate3d(0px, 0px, 0px); transition: all 0.5s ease 0s;"><a id="tabs-iconpricing-tab-1" class="nav-link mb-0 active" data-bs-toggle="tab" href="#monthly" role="tab" aria-controls="monthly" aria-selected="true">-</a></div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-n8">
      <div class="container">
        <div class="tab-content tab-space">
          <div id="monthly" class="tab-pane active" role="tabpanel" aria-labelledby="#tabs-iconpricing-tab-1">
            <div class="row">
              <div class="col-lg-4 mb-lg-0 mb-4">
                <div class="card">
                  <div class="card-header text-center pt-4 pb-3">
                    <span class="badge rounded-pill bg-light text-dark">일반</span>
                    <h1 class="font-weight-bold mt-2">
                      <small>월 </small> 0<small>원</small>
                    </h1>
                  </div>
                  <div class="card-body text-lg-start text-center pt-0">
                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 채널별 최저가 제공</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 전송 웹</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 전송 API</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-secondary shadow text-center">
                        <i class="fas fa-minus" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">앱 푸시 무제한</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-secondary shadow text-center">
                        <i class="fas fa-minus" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">이메일 무제한</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-secondary shadow text-center">
                        <i class="fas fa-minus" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">채널별 추가할인</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-secondary shadow text-center">
                        <i class="fas fa-minus" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">분석 서비스</span>
                      </div>
                    </div>

                    <a href="javascript:;" class="btn btn-icon bg-gradient-primary d-lg-block mt-3 mb-0">
                      일반 가입
                      <i class="fas fa-arrow-right ms-1" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mb-lg-0 mb-4">
                <div class="card">
                  <div class="card-header text-center pt-4 pb-3">
                    <span class="badge rounded-pill bg-light text-dark">스타터</span>
                    <h1 class="font-weight-bold mt-2">
                      <small>월 </small> 5<small>만원</small>
                    </h1>
                  </div>
                  <div class="card-body text-lg-start text-center pt-0">
                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 채널별 최저가 제공</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 전송 웹</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 전송 API</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">앱 푸시 무제한</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">이메일 무제한</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-secondary shadow text-center">
                        <i class="fas fa-minus" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">채널별 추가할인</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-secondary shadow text-center">
                        <i class="fas fa-minus" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">분석 서비스</span>
                      </div>
                    </div>

                    <a href="javascript:;" class="btn btn-icon bg-gradient-primary d-lg-block mt-3 mb-0">
                      구독 가입
                      <i class="fas fa-arrow-right ms-1" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mb-lg-0 mb-4">
                <div class="card">
                  <div class="card-header text-center pt-4 pb-3">
                    <span class="badge rounded-pill bg-light text-dark">엔터프라이즈</span>
                    <h1 class="font-weight-bold mt-2">
                      <small>월 </small> 20<small>만원</small>
                    </h1>
                  </div>
                  <div class="card-body text-lg-start text-center pt-0">
                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 채널별 최저가 제공</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 전송 웹</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 전송 API</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">앱 푸시 무제한</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">이메일 무제한</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">채널별 추가할인</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">분석 서비스</span>
                      </div>
                    </div>

                    <a href="javascript:;" class="btn btn-icon bg-gradient-primary d-lg-block mt-3 mb-0">
                      구독 가입
                      <i class="fas fa-arrow-right ms-1" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="annual" class="tab-pane" role="tabpanel" aria-labelledby="#tabs-iconpricing-tab-2">
            <div class="row">
              <div class="col-lg-4 mb-lg-0 mb-4">
                <div class="card">
                  <div class="card-header text-center pt-4 pb-3">
                    <span class="badge rounded-pill bg-light text-dark">일반</span>
                    <h1 class="font-weight-bold mt-2">
                      <small>월 </small> 00<small>원</small>
                    </h1>
                  </div>
                  <div class="card-body text-lg-start text-center pt-0">
                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 채널별 최저가 제공</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 전송 웹</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 전송 API</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-secondary shadow text-center">
                        <i class="fas fa-minus" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">앱 푸시 무제한</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-secondary shadow text-center">
                        <i class="fas fa-minus" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">이메일 무제한</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-secondary shadow text-center">
                        <i class="fas fa-minus" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">채널별 추가할인</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-secondary shadow text-center">
                        <i class="fas fa-minus" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">분석 서비스</span>
                      </div>
                    </div>

                    <a href="javascript:;" class="btn btn-icon bg-gradient-primary d-lg-block mt-3 mb-0">
                      일반 가입
                      <i class="fas fa-arrow-right ms-1" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mb-lg-0 mb-4">
                <div class="card">
                  <div class="card-header text-center pt-4 pb-3">
                    <span class="badge rounded-pill bg-light text-dark">스타터</span>
                    <h1 class="font-weight-bold mt-2">
                      <small>월 </small> 5<small>만원</small>
                    </h1>
                  </div>
                  <div class="card-body text-lg-start text-center pt-0">
                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 채널별 최저가 제공</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 전송 웹</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 전송 API</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">앱 푸시 무제한</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">이메일 무제한</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-secondary shadow text-center">
                        <i class="fas fa-minus" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">채널별 추가할인</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-secondary shadow text-center">
                        <i class="fas fa-minus" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">분석 서비스</span>
                      </div>
                    </div>

                    <a href="javascript:;" class="btn btn-icon bg-gradient-primary d-lg-block mt-3 mb-0">
                      구독 가입
                      <i class="fas fa-arrow-right ms-1" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mb-lg-0 mb-4">
                <div class="card">
                  <div class="card-header text-center pt-4 pb-3">
                    <span class="badge rounded-pill bg-light text-dark">엔터프라이즈</span>
                    <h1 class="font-weight-bold mt-2">
                      <small>월 </small> 20<small>만원</small>
                    </h1>
                  </div>
                  <div class="card-body text-lg-start text-center pt-0">
                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 채널별 최저가 제공</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 전송 웹</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">메시지 전송 API</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">앱 푸시 무제한</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">이메일 무제한</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">채널별 추가할인</span>
                      </div>
                    </div>

                    <div class="d-flex justify-content-lg-start justify-content-center p-2">
                      <div class="icon icon-shape icon-xs rounded-circle bg-gradient-success shadow text-center">
                        <i class="fas fa-check opacity-10" aria-hidden="true"></i>
                      </div>
                      <div>
                        <span class="ps-3">분석 서비스</span>
                      </div>
                    </div>

                    <a href="javascript:;" class="btn btn-icon bg-gradient-primary d-lg-block mt-3 mb-0">
                      구독 가입
                      <i class="fas fa-arrow-right ms-1" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
  <section class="py-5">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-md-3">
          <span class="badge bg-gradient-danger mb-0 ms-lg-3 ms-sm-2 mb-sm-0 mb-2 me-auto h-100 w-100 d-flex align-items-center justify-content-center" style="font-size: 24px;">
            <!-- <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/shapes/waves-white.svg" alt="pattern-lines" class="position-absolute opacity-6 start-0 top-0 w-100"> -->
            문자
          </span>
        </div>
        <div class="col-md-3">
          <h1 class="text-gradient text-danger">8.4원</h1>
          <h5 class="mt-3">SMS</h5>
        </div>
        <div class="col-md-3">
          <h1 class="text-gradient text-danger">27원</h1>
          <h5 class="mt-3">LMS</h5>
        </div>
        <div class="col-md-3">
          <h1 class="text-gradient text-danger">60원</h1>
          <h5 class="mt-3">MMS</h5>
        </div>
      </div>
    </div>
  </section>
  <section class="py-5">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-md-3">
          <span class="badge bg-gradient-info mb-0 ms-lg-3 ms-sm-2 mb-sm-0 mb-2 me-auto h-100 w-100 d-flex align-items-center justify-content-center" style="font-size: 24px;">RCS</span>
        </div>
        <div class="col-md-3">
          <h1 class="text-gradient text-info">16원</h1>
          <h5 class="mt-3">SMS</h5>
        </div>
        <div class="col-md-3">
          <h1 class="text-gradient text-info">27원</h1>
          <h5 class="mt-3">LMS</h5>
        </div>
        <div class="col-md-3">
          <h1 class="text-gradient text-info">60원</h1>
          <h5 class="mt-3">MMS</h5>
        </div>
      </div>
    </div>
  </section>
  <!-- primary, secondary, success, info, warning, danger, light, dark, white, faded-primary... -->
  <section class="py-5">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-md-3">
          <span class="badge bg-gradient-warning mb-0 ms-lg-3 ms-sm-2 mb-sm-0 mb-2 me-auto h-100 w-100 d-flex align-items-center justify-content-center" style="font-size: 24px;">카카오</span>
        </div>
        <div class="col-md-3">
          <h1 class="text-gradient text-warning">6.5원</h1>
          <h5 class="mt-3">알림톡(TEXT)</h5>
        </div>
        <div class="col-md-3">
          <h1 class="text-gradient text-warning">8원</h1>
          <h5 class="mt-3">알림톡(이미지)</h5>
        </div>
        <div class="col-md-3">
          <h1 class="text-gradient text-warning">12원</h1>
          <h5 class="mt-3">친구톡(TEXT)</h5>
        </div>
      </div>
      
    </div>
  </section>  
  <section class="py-5 position-relative">
        <div class="page-header min-vh-50 m-3 border-radius-xl" style="background-image: url('https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/curved-images/curved8.jpg');">
            <span class="mask bg-gradient-dark opacity-4"></span>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6 text-center mx-auto">
                        <h1 class="text-white mt-4 mb-1">질문이 있으신가요?</h1>
                        <p class="lead text-white mb-6">저희 솔루션에 대해 더욱 자세히 설명드리겠습니다.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row mt-n7 blur border-radius-lg shadow-blur">
                <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
                    <div class="p-3 text-center">
                        <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
                            <svg class="mt-3" width="15px" height="15px" viewBox="0 0 45 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>map-big</title>
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g transform="translate(-2321.000000, -593.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                        <g transform="translate(1716.000000, 291.000000)">
                                            <g id="map-big" transform="translate(605.000000, 302.000000)">
                                                <polygon points="31.875 0.71625 24.375 4.46625 24.375 40.53375 31.875 36.78375"></polygon>
                                                <polygon points="20.625 4.46625 13.125 0.71625 13.125 36.78375 20.625 40.53375"></polygon>
                                                <path d="M9.375,0.81375 L0.909375,5.893125 C0.346875,6.230625 0,6.84 0,7.5 L0,43.125 L9.375,37.06125 L9.375,0.81375 Z" opacity="0.70186942"></path>
                                                <path d="M44.090625,5.893125 L35.625,0.81375 L35.625,37.06125 L45,43.125 L45,7.5 C45,6.84 44.653125,6.230625 44.090625,5.893125 Z" opacity="0.70186942"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <h5 class="mt-3 mb-1">주소</h5>
                        <p class="mb-0">경기도 성남시 분당구 <br> 성남대로343번길 9 (SK u타워)</p>
                        <hr class="vertical dark">
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
                    <div class="p-3 text-center">
                        <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
                            <svg class="mt-3" width="15px" height="15px" viewBox="0 0 40 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>document</title>
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                        <g transform="translate(1716.000000, 291.000000)">
                                            <g transform="translate(154.000000, 300.000000)">
                                                <path d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z" opacity="0.603585379"></path>
                                                <path d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <h5 class="mt-3 mb-1">이메일</h5>
                        <p class="mb-0"><br>message.to-go@sk.com</p>
                    </div>
                    <hr class="vertical dark">
                </div>
                <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
                    <div class="p-3 text-center">
                        <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
                            <svg class="mt-3" width="15px" height="15px" viewBox="0 0 44 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>megaphone</title>
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g transform="translate(-2168.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                        <g transform="translate(1716.000000, 291.000000)">
                                            <g id="megaphone" transform="translate(452.000000, 300.000000)">
                                                <path d="M35.7958333,0.273166667 C35.2558424,-0.0603712374 34.5817509,-0.0908856664 34.0138333,0.1925 L19.734,7.33333333 L9.16666667,7.33333333 C4.10405646,7.33333333 0,11.4373898 0,16.5 C0,21.5626102 4.10405646,25.6666667 9.16666667,25.6666667 L19.734,25.6666667 L34.0138333,32.8166667 C34.5837412,33.1014624 35.2606401,33.0699651 35.8016385,32.7334768 C36.3426368,32.3969885 36.6701539,31.8037627 36.6666942,31.1666667 L36.6666942,1.83333333 C36.6666942,1.19744715 36.3370375,0.607006911 35.7958333,0.273166667 Z"></path>
                                                <path d="M38.5,11 L38.5,22 C41.5375661,22 44,19.5375661 44,16.5 C44,13.4624339 41.5375661,11 38.5,11 Z" opacity="0.601050967"></path>
                                                <path d="M18.5936667,29.3333333 L10.6571667,29.3333333 L14.9361667,39.864 C15.7423448,41.6604248 17.8234451,42.4993948 19.6501416,41.764381 C21.4768381,41.0293672 22.3968823,38.982817 21.7341667,37.1286667 L18.5936667,29.3333333 Z" opacity="0.601050967"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <h5 class="mt-3 mb-1">전화번호</h5>
                        <p class="mb-0"><br>(02)6400-2613</p>
                    </div>
                    <hr class="vertical dark">
                </div>
                <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
                    <div class="p-3 text-center">
                        <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
                            <svg class="mt-3" width="15px" height="15px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>ungroup</title>
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g transform="translate(-2170.000000, -442.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                        <g transform="translate(1716.000000, 291.000000)">
                                            <g id="ungroup" transform="translate(454.000000, 151.000000)">
                                                <path d="M38.1818182,10.9090909 L32.7272727,10.9090909 L32.7272727,30.9090909 C32.7272727,31.9127273 31.9127273,32.7272727 30.9090909,32.7272727 L10.9090909,32.7272727 L10.9090909,38.1818182 C10.9090909,39.1854545 11.7236364,40 12.7272727,40 L38.1818182,40 C39.1854545,40 40,39.1854545 40,38.1818182 L40,12.7272727 C40,11.7236364 39.1854545,10.9090909 38.1818182,10.9090909 Z" opacity="0.6"></path>
                                                <path d="M27.2727273,29.0909091 L1.81818182,29.0909091 C0.812727273,29.0909091 0,28.2781818 0,27.2727273 L0,1.81818182 C0,0.814545455 0.812727273,0 1.81818182,0 L27.2727273,0 C28.2781818,0 29.0909091,0.814545455 29.0909091,1.81818182 L29.0909091,27.2727273 C29.0909091,28.2781818 28.2781818,29.0909091 27.2727273,29.0909091 Z"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <h5 class="mt-3 mb-1">영업 담당자</h5>
                        <p class="mb-0"><br>오상준 매니저</p>
                    </div>
                </div>
            </div>
            <div class="container">
              <br>
              <div class="container-fluid">
                <div class="row align-items-center justify-content-lg-between">
                  <div class="mb-4 col-lg-9 mb-lg-0">
                    <div
                      class="text-sm text-center copyright text-muted"
                      :class="$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
                    >
                    ©
          {{ new Date().getFullYear() }}, made
          <!-- <i class="fa fa-heart"></i>  -->
          by
          <a
            href="https://www.sk.com"
            class="font-weight-bold"
            target="_blank"
            >SK 주식회사 C&C</a
          >               
                    </div>
                    <span style="font-size: 0.8rem; margin-right: 0.5rem;">
          SK(주) | 대표이사: 장용호, 최태원 | 경기도 성남시 분당구 성남대로 343번길 9 | 사업자등록번호 783-85-00169 | 통신판매업신고 2015-경기성남-1388 <br> COPYRIGHT SK HOLDINGS CO., LTD. ALL RIGHTS RESERVED.
          </span>
                  </div>
                  <div class="col-lg-2">
                    <ul
                      class="nav nav-footer"
                    >
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 이용 약관_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >이용약관</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 수준 약정서(SLA)_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >서비스수준(SLA)</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/home/privacy"
                          class="nav-link pe-0 text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 1rem;"
                          >개인정보처리방침</a
                        >
                      </li>
                    </ul>
                  </div>
          </div>
        </div>
      </div>
        </div>
    </section>
<!--  <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/bootstrap.min.js"></script>-->
<!--  <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/soft-ui-dashboard.min.js?v=1.0.2"></script>-->
  <!-- <script src="../assets/js/loopple/loopple.js"></script> -->
  </body>
<!--  <script>-->
<!--    document.addEventListener('DOMContentLoaded', function() {-->
<!--      // Remove the "active" class from the afterpay tab-->
<!--      var afterpayTab = document.getElementById('tabs-iconpricing-tab-2');-->
<!--      afterpayTab.classList.remove('active');-->
<!--      // Add the "active" class to the prepaid tab-->
<!--      var prepaidTab = document.getElementById('tabs-iconpricing-tab-1');-->
<!--      prepaidTab.classList.add('active');-->
<!--    });-->
<!--  </script>-->
</template>

<script>
import store from '@/store';

export default {
  name: 'Product',
  beforeMount() {
    store.commit('toggleEveryDisplay', false);
  },
  beforeUnmount() {
    store.commit('toggleEveryDisplay', true);
  },
  methods: {
    getQueryString() {
      return window.location.search;
    }
  },
};
</script>

<style scoped></style>
