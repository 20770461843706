<template>
  <div id="send-history" class="card mt-4 me-2">
    <div class="card-header">
      <h6 class="mb-0">조회 조건</h6>
    </div>
    <div class="card-body pt-0">
      <div class="mt-0 row">
        <span style="font-weight: bold; font-size: 0.8rem;">승인 상태:</span>
      </div>
      <div class="col-sm-12 d-flex align-items-center">
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">저장
        </label>
        <material-checkbox id="checkboxSave" v-model="isSaveChecked" name="checkboxSave" :checked="isSaveChecked">
        </material-checkbox>
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">요청
        </label>
        <material-checkbox id="checkboxRequest" v-model="isRequestChecked" name="checkboxRequest"
          :checked="isRequestChecked">
        </material-checkbox>
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">승인
        </label>
        <material-checkbox id="checkboxApprove" v-model="isApproveChecked" name="checkboxApprove"
          :checked="isApproveChecked">
        </material-checkbox>
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">완료
        </label>
        <material-checkbox id="checkboxComplete" v-model="isCompleteChecked" name="checkboxComplete"
          :checked="isCompleteChecked">
        </material-checkbox>
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px; ">반려
        </label>
        <material-checkbox id="checkboxReject" v-model="isRejectChecked" name="checkboxReject"
          :checked="isRejectChecked">
        </material-checkbox>
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">만료
        </label>
        <material-checkbox id="checkboxExpire" v-model="isExpireChecked" name="checkboxExpire"
          :checked="isExpireChecked">
        </material-checkbox>
        <label for="send_status"
          style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">취소
        </label>
        <material-checkbox id="checkboxCancle" v-model="isCancleChecked" name="checkboxCancle"
          :checked="isCancleChecked">
        </material-checkbox>
      </div>
      <div class="mt-0 row">
        <span style="font-weight: bold; font-size: 0.8rem;">조회상세:</span>

        <div class="row align-items-center mt-2">
          <div class="col-4" style="max-width: 180px;">
            <material-input id="campaign_company_name" v-model="campaign_company_name" type="text" size="sm"
              label="요청회사" label-size="sm" variant="outline">
            </material-input>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-end">
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;"
            @click="changeData">
            조회
          </material-button>
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;"
            @click="showCustomerDBDetail">
            고객DB등록
          </material-button>
        </div>

      </div>
    </div>
  </div>
  <div class="mb-4 card mt-2 me-2">
    <div class="pb-0 card-header">
      <div class="row mb-2">
        <div class="col-6">
          <div v-if="result_count < 0">
            <h6>조회 결과</h6>
          </div>
          <div v-if="result_count >= 0">
            <h6>조회 결과 {{ result_count }}건</h6>
          </div>
        </div>
        <div class="col-6 text-end">
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;"
            @click="exportCSV">
            내보내기
          </material-button>
        </div>
      </div>
    </div>
    <div>
      <EasyDataTable table-class-name="customize-table" empty-message="조회된 내역이 없습니다."
        rows-per-page-message="페이지당 표시 행 수" rows-of-page-separator-message="중" :headers="tableColumns"
        :items="approveCampaignList" :loading="isLoading" @click-row="showCampaignDetail">
        <template #loading>
          <img src="@/assets/img/Spinner-1s-200px.gif" style="width: 100px; height: 100px;" />
        </template>
        <template #item-primary="{ primary, image, image_color, secondary, secondary_color }">
          <div class="row">
            <div class="col-auto flex-wrap">
              <material-button v-if="image" :color="image_color" variant="outline"
                class="btn-icon-only btn-rounded btn-sm d-flex align-items-center justify-content-center">
                <i :class="image" aria-hidden="true"></i>
              </material-button>
            </div>
            <div class="col-auto flex-wrap">
              <div class="d-flex flex-column justify-content-center">
                <span style="display: inline-block; max-width: 100%;" :title="primary">{{ primary }}</span>
                <span style="display: inline-block; max-width: 100%;" :title="secondary">
                  <span :class="secondary_color">{{ secondary }}</span>
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #item-sent_content="{ sent_content }">
          <div class="row" style="max-width: 290px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"
            :title="sent_content">
            {{ sent_content }}
          </div>
        </template>
        <template #item-info="{ info }">
          <div class="justify-content-center">
            <material-button v-if="info" size="xsm" color="secondary" variant="outline"
              class="btn-icon-only btn-rounded btn-sm d-flex align-items-center justify-content-center"
              data-bs-toggle="tooltip" data-bs-placement="bottom" :title="info">
              <i class="fas fa-info" aria-hidden="true"></i>
            </material-button>
          </div>
        </template>
      </EasyDataTable>
    </div>
  </div>
  <div id="campaignDetailModal" class="modal fade" tabindex="-1" aria-labelledby="campaignDetailModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="createGroupModalLabel" class="modal-title">승인필요 캠페인</h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <campaign-form v-if="Object.prototype.hasOwnProperty.call(campaignInfo, 'subject')" id="campaign-form"
              :campaign-info="campaignInfo">
            </campaign-form>
          </div>
        </div>
        <div class="modal-footer">
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;"
            :is-loading="isCreateLoading" @click="approveRequest">
            승인
          </material-button>
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;"
            @click="rejectRequest">
            반려
          </material-button>
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;"
            class="btn btn-secondary" data-bs-dismiss="modal">닫기</material-button>
        </div>
      </div>
    </div>
  </div>
  <div id="customerDBModal" class="modal fade" tabindex="-1" aria-labelledby="customerDBModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="createGroupModalLabel" class="modal-title">고객DB등록</h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row align-items-center">
                <div class="col-sm-3 pr-0">
                  <span style="font-weight: bold; font-size: 0.8rem;">발신번호: </span>
                </div>
                <div class="col-sm-6">
                  <select id="from-phone-number" v-model="formData.from_phone_num" class="form-control">
                  </select>
                </div>
              </div>
              <div id="address_name" class="col-sm-9" style="height: 400px; overflow-y: auto;">
                <span style="font-weight: bold; font-size: 0.8rem;">주소록명</span><br>
                <td style="padding: 5px;">
                  <div class="d-flex align-items-start">
                    <material-input id="address_name" v-model="formData.customer_group_alias" size="sm"
                      style="margin-left: -3px;" :disabled="!fileSelected"></material-input>
                  </div>
                </td>
                <div class="table-responsive">
                  <table id="excel_table" class="table table-flush">
                    <div class="col-sm-5">
                      <span style="font-weight: bold; font-size: 0.8rem;">전화번호</span><br>
                      <material-button id="toPhoneNumberIndex" color="success" size="sm" variant="outline"
                        class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                        style="width: 180px" :disabled="!fileSelected">
                        {{ selectedPhoneNumberField || "전화번호헤더 선택" }}
                      </material-button>
                      <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                        <li v-for="field in headerFields" :key="field">
                          <a class="dropdown-item border-radius-md" href="javascript:;"
                            @click="handlePhoneSelection(field)">{{ field }}</a>
                        </li>
                      </ul>
                      <br>

                      <span style="font-weight: bold; font-size: 0.8rem;">마케팅동의일자</span><br>
                      <material-button id="agreeMarketingIndex" color="success" size="sm" variant="outline"
                        class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                        style="width: 180px" :disabled="!fileSelected">
                        {{ agreeMarketingField || "마케팅동의일자헤더 선택" }}
                      </material-button>
                      <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                        <li v-for="field in headerFields" :key="field">
                          <a class="dropdown-item border-radius-md" href="javascript:;"
                            @click="handleagreeMarketingSelection(field)">{{ field }}</a>
                        </li>
                      </ul>
                      <br>

                      <span style="font-weight: bold; font-size: 0.8rem;">변수</span>
                      <div class="d-flex" style="width: 270px;">
                        <material-button id="paramIndex1" color="success" size="sm" variant="outline"
                          class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                          style="width: 90px" :disabled="!phoneSelected">
                          {{ selectedParamField1 || "변수1" }}
                        </material-button>
                        <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                          <li v-for="field in headerFields" :key="field">
                            <a class="dropdown-item border-radius-md" href="javascript:;"
                              @click="handleParmSelection1(field)">{{ field }}</a>
                          </li>
                        </ul>
                        <material-button id="paramIndex2" color="success" size="sm" variant="outline"
                          class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                          style="width: 90px" :disabled="!param1Selected">
                          {{ selectedParamField2 || "변수2" }}
                        </material-button>
                        <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                          <li v-for="field in headerFields" :key="field">
                            <a class="dropdown-item border-radius-md" href="javascript:;"
                              @click="handleParmSelection2(field)">{{ field }}</a>
                          </li>
                        </ul>
                        <material-button id="paramIndex3" color="success" size="sm" variant="outline"
                          class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                          style="width: 90px" :disabled="!param2Selected">
                          {{ selectedParamField3 || "변수3" }}
                        </material-button>
                        <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                          <li v-for="field in headerFields" :key="field">
                            <a class="dropdown-item border-radius-md" href="javascript:;"
                              @click="handleParmSelection3(field)">{{ field }}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="d-flex" style="width: 270px;">
                        <material-button id="paramIndex4" color="success" size="sm" variant="outline"
                          class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                          style="width: 90px" :disabled="!param3Selected">
                          {{ selectedParamField4 || "변수4" }}
                        </material-button>
                        <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                          <li v-for="field in headerFields" :key="field">
                            <a class="dropdown-item border-radius-md" href="javascript:;"
                              @click="handleParmSelection4(field)">{{ field }}</a>
                          </li>
                        </ul>
                        <material-button id="paramIndex5" color="success" size="sm" variant="outline"
                          class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                          style="width: 90px" :disabled="!param4Selected">
                          {{ selectedParamField5 || "변수5" }}
                        </material-button>
                        <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                          <li v-for="field in headerFields" :key="field">
                            <a class="dropdown-item border-radius-md" href="javascript:;"
                              @click="handleParmSelection5(field)">{{ field }}</a>
                          </li>
                        </ul>
                        <material-button id="paramIndex6" color="success" size="sm" variant="outline"
                          class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                          style="width: 90px" :disabled="!param5Selected">
                          {{ selectedParamField6 || "변수6" }}
                        </material-button>
                        <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                          <li v-for="field in headerFields" :key="field">
                            <a class="dropdown-item border-radius-md" href="javascript:;"
                              @click="handleParmSelection6(field)">{{ field }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </table>
                </div>
                <span style="font-weight: bold; font-size: 0.8rem;">엑셀선택:</span><br>
                <label class="btn-sm" style="display: inline-block; position: relative; cursor: pointer;">
                  <i class="material-icons icon-hover icon-large"
                    style="cursor: pointer; margin-top: 0px; margin-left: -15px;" @click="openFileDialog">upload</i>
                  <span
                    style="cursor: pointer; display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 20px;"
                    @click="openFileDialog">UPLOAD</span>
                  <input ref="fileInput" type="file" style="display: none" @change="handleFileUpload" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <material-button-progress color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;"
            :is-loading="isCreateLoading" @click="registCustomerDB">
            등록
          </material-button-progress>
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;"
            class="btn btn-secondary" data-bs-dismiss="modal" @click="closeRequest">닫기</material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import ApiCall from "@/Interface/ApiCall";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialButtonProgress from "@/components/MaterialButtonProgress.vue";
import MaterialTable from "@/components/MaterialTable.vue";
import MaterialPagination from "@/components/MaterialPagination.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import * as Choices from "choices.js";
import Papa from "papaparse";
import { Auth } from 'aws-amplify';
// import MaterialInputVmodel from "@/components/MaterialInputVmodel.vue";
import * as XLSX from 'xlsx';
import CampaignForm from "@/components/CampaignForm.vue";

export default {
  name: "DataTables",
  components: {
    MaterialButton,
    // MaterialTable,
    // MaterialPagination,
    MaterialInput,
    // MaterialTextarea,
    MaterialCheckbox,
    // MaterialInputVmodel,
    MaterialButtonProgress,
    CampaignForm,
  },
  setup() {
  },
  data() {
    return {
      formData: {
        from_phone_num: '',
        send_request_dtm: '',
        to_phone_number: [''],
        customer_group_id: '',
        content: '',
        customer_group_alias: '',
        excel_file_name: '',
        excelfile: '',
        primary_customer_reference_id: '',
        secondary_customer_reference_id: '',
        send_status: '',
        alert_phone_number: '',
      },

      tableColumns: [
        { text: "캠페인요청회사", value: "request_tenant_name", sortable: true },
        { text: "승인여부", value: "campaign_status", sortable: true },
        { text: "캠페인명", value: "campaign_name", sortable: true },
        { text: "캠페인대상", value: "campaign_object", sortable: true },
        { text: "캠페인목적", value: "campaign_purpose", sortable: true },
        { text: "타겟수", value: "target_count", sortable: true },
        { text: "요청자 전화번호", value: "request_user_phone_number", sortable: true },
        { text: "캠페인전송일시", value: "send_request_dtm", sortable: true },
        { text: "캠페인생성일시", value: "request_dtm", sortable: true },
        { text: "", value: "info", width: 10, sortable: true },
      ],
      //전송상태
      isSaveChecked: true,
      isRequestChecked: true,
      isApproveChecked: true,
      isCompleteChecked: true,
      isRejectChecked: true,
      isExpireChecked: true,
      isCancleChecked: true,

      campaignDetailModal: null,
      customerDBModal: null,
      tableData: [],
      customerDB: {},
      // currentTimestamp: Date.now(),
      approveCampaignList: [], // Add this line
      campaign_company_name: '',
      selectedRow: null,
      selectedList: null,
      itemsPerPage: 20,
      currentPage: 1, // Add this line

      isLoading: false,
      result_count: -1,
      request_pk: '',
      request_sk: '',
      from_phone_number_type: '',
      campaign_status: 'request',
      request_from_phone_number: '',
      request_file_list: [],
      comment_text: '',
      authorize_type: '',
      tenant_id: '',
      create_id: '',
      tenantOptions: [],
      tenantList: [],
      tenantListOrigin: [],
      optionFromPhoneNumbers: [],

      //uploadFile
      headerFields: [],
      params: [],
      selectedPhoneNumberField: '전화번호 선택',
      agreeMarketingField: '마케팅동의일자 선택',
      selectedParamField1: '변수1',
      selectedParamField2: '변수2',
      selectedParamField3: '변수3',
      selectedParamField4: '변수4',
      selectedParamField5: '변수5',
      selectedParamField6: '변수6',

      fileSelected: false,
      phoneSelected: false,
      param1Selected: false,
      param2Selected: false,
      param3Selected: false,
      param4Selected: false,
      param5Selected: false,
      param6Selected: false,
      selectInstance: null,
      typed_content: '',

      excel_file: '',

      campaignInfo: {},
      getCampaignList: {},
      // imagesArray :[],

      isCreateLoading: false,
      isAgreeLoading: false,
    }
  },
  computed: {
    paginatedCampaignList() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      // console.log('this.approveCampaignList', this.approveCampaignList.slice(startIndex, endIndex));
      return this.approveCampaignList.slice(startIndex, endIndex);
    },
  },
  watch: {
    campaign_status(newValue) {
      // console.log('campaign_status changed to:', newValue);
    },
    'formData.from_phone_num'(newValue) {
      console.log('this.formData.from_phone_num', this.formData.from_phone_num);

      console.log('from_phone_num changed:', newValue);
    }

  },
  mounted() {
    // this.initChoicesList();
    this.getAllTenantList();
    // if (this.$route.params.title)
    //   this.performSearch();
    // await this.triggerGetTenant();
  },
  methods: {
    initChoicesList() {
      if (this.selectInstance) {
        this.selectInstance.destroy();
      }
      console.log("abcdev");
      this.selectInstance = new Choices('#from-phone-number', {
        shouldSort: false,
        choices: this.optionFromPhoneNumbers,
      });
    },
    async getAllTenantList() {
      await ApiCall.call('user/auth/tenant/all', 'POST').then(async (res) => {
        this.tenantListOrigin = res.data.data
        this.tenantOptions = []
        this.tenantList = []

        const optionData = res.data.data.map(item => ({
          label: item['tenant_id'] + ' | ' + item['tenant_name'],
          value: item['tenant_id']
        }));
        this.tenantList = res.data.data.map(item => (
          item['tenant_id']
        ));

        // 전체 선택
        const all = [{ value: "all", label: "전체" }]
        this.tenantOptions = this.tenantOptions.concat(all)
        this.tenantOptions = this.tenantOptions.concat(optionData);
        // console.log('tenantOptions :', this.tenantOptions)


      })
    },
    async triggerGetTenant() {
      await this.getTenantList();

      if (document.getElementById("tenant-name")) {
        var tenant_name = document.getElementById("tenant-name");
        new Choices(tenant_name);
      }
    },
    exportCSV() {
      // First, decode URIs in the dictionary list
      const decodedList = this.approveCampaignList;

      // Convert the decoded list to CSV with PapaParse
      const csv = Papa.unparse(decodedList, {
        quotes: true,  // Enable quotes around values
      });

      // Create and trigger the download link with UTF-8 BOM
      const csvBlob = new Blob(["\ufeff", csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(csvBlob);
      link.setAttribute('download', 'history.csv');
      document.body.appendChild(link);
      link.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      // console.log('file:', file)
      if (file) {
        const reader = new FileReader()
        // console.log('reader:', reader)
        reader.onload = () => {
          const fileData = reader.result
          const workbook = XLSX.read(fileData, { type: 'binary' })
          const sheetName = workbook.SheetNames[0]
          const sheet = workbook.Sheets[sheetName]
          const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 })
          this.headerFields = rows[0]
          this.formData.excelfile = file.name
          this.formData.customer_group_alias = file.name.split('.').slice(0, -1).join('.');
          this.excel_file = file;
        };
        reader.readAsBinaryString(file)

        this.selectedPhoneNumberField = ''
        this.agreeMarketingField = ''
        this.to_phone_num_index = ''
        this.agree_marketing_index = ''
        this.selectedParamField1 = ''
        this.selectedParamField2 = ''
        this.selectedParamField3 = ''
        this.params.length = 0;
        this.fileSelected = true;
        this.phoneSelected = false;
        this.param1Selected = false;
        this.param2Selected = false;
        this.param3Selected = false;
        this.param4Selected = false;
        this.param5Selected = false;
        this.param6Selected = false;
      }
    },
    handlePhoneSelection(option) {
      this.selectedPhoneNumberField = option
      this.to_phone_num_index = this.headerFields.indexOf(option)
      this.phoneSelected = true;
    },
    handleagreeMarketingSelection(option) {
      this.agreeMarketingField = option
      this.agree_marketing_index = this.headerFields.indexOf(option)
      this.phoneSelected = true;
    },
    handleParmSelection1(option) {
      this.typed_content = this.typed_content.replace("{" + this.selectedParamField1 + "}", "");
      this.selectedParamField1 = option
      this.params[0] = this.headerFields.indexOf(option)
      this.typed_content += "{" + this.selectedParamField1 + "}"
      this.param1Selected = true;
    },
    handleParmSelection2(option) {
      this.typed_content = this.typed_content.replace("{" + this.selectedParamField2 + "}", "");
      this.selectedParamField2 = option
      this.params[1] = this.headerFields.indexOf(option)
      this.typed_content += "{" + this.selectedParamField2 + "}"
      this.param2Selected = true;

    },
    handleParmSelection3(option) {
      this.typed_content = this.typed_content.replace("{" + this.selectedParamField3 + "}", "");
      this.selectedParamField3 = option
      this.params[2] = this.headerFields.indexOf(option)
      this.typed_content += "{" + this.selectedParamField3 + "}"
      this.param3Selected = true;

    },
    handleParmSelection4(option) {
      this.typed_content = this.typed_content.replace("{" + this.selectedParamField4 + "}", "");
      this.selectedParamField4 = option
      this.params[3] = this.headerFields.indexOf(option)
      this.typed_content += "{" + this.selectedParamField4 + "}"
      this.param4Selected = true;
    },
    handleParmSelection5(option) {
      this.typed_content = this.typed_content.replace("{" + this.selectedParamField5 + "}", "");
      this.selectedParamField5 = option
      this.params[4] = this.headerFields.indexOf(option)
      this.typed_content += "{" + this.selectedParamField5 + "}"
      this.param5Selected = true;

    },
    handleParmSelection6(option) {
      this.typed_content = this.typed_content.replace("{" + this.selectedParamField6 + "}", "");
      this.selectedParamField6 = option
      this.params[5] = this.headerFields.indexOf(option)
      this.typed_content += "{" + this.selectedParamField6 + "}"
      this.param6Selected = true;

    },

    async approveRequest() {
      this.isAgreeLoading = true;

      const payload = {
        request_tenant_id: this.campaignInfo.request_tenant_id,
        request_campaign_id: this.campaignInfo.sk,
        campaign_status: 'approve',
      };

      await ApiCall.call('marketing/campaign/approve', 'POST', payload).then(res => {
        // console.log("res : ",res);
        alert(res.data.resultMessage);
        this.changeData();
      })
        .catch(error => {
          // Handle error response
          console.error('Error:', error);
          // You can display an error message to the user or perform any other necessary actions
          alert('캠페인 실패');

          // Reset any relevant state variables if needed

          this.isLoading = false;
        });

      this.isAgreeLoading = false;

    },
    async rejectRequest() {

      const payload = {
        request_tenant_id: this.campaignInfo.request_tenant_id,
        request_campaign_id: this.campaignInfo.sk,
        campaign_status: 'reject',
      };

      ApiCall.call('marketing/campaign/approve', 'POST', payload).then(res => {
        // console.log("res : ",res);
        alert(res.data.resultMessage);
        this.changeData();
      })
        .catch(error => {
          // Handle error response
          console.error('Error:', error);
          // You can display an error message to the user or perform any other necessary actions
          alert('캠페인 실패');

          // Reset any relevant state variables if needed

          this.isLoading = false;
        });
    },
    async closeRequest() {
      this.resetData();
    },
    resetData() {
      this.isLoading = false;
      this.formData.from_phone_num = '';
      this.formData.send_request_dtm = '';
      this.formData.to_phone_number = [''];
      this.formData.customer_group_id = '';
      this.formData.content = '';
      this.formData.customer_group_alias = '';
      this.excel_file_name = '',
        this.formData.excelfile = '';
      this.formData.primary_customer_reference_id = '';
      this.formData.secondary_customer_reference_id = '';
      this.formData.send_status = '';
      this.formData.alert_phone_number = '';
      this.typed_content = '';
      this.to_phone_num_index = '';
      this.agree_marketing_index = '';

      this.headerFields = [''];
      this.params = [''];
      this.selectedPhoneNumberField = '전화번호 선택';
      this.agreeMarketingField = '마케팅동의일자 선택';
      this.selectedParamField1 = '변수1';
      this.selectedParamField2 = '변수2';
      this.selectedParamField3 = '변수3';
      this.selectedParamField4 = '변수4';
      this.selectedParamField5 = '변수5';
      this.selectedParamField6 = '변수6';
      this.selectedSendMethod = 'manual';
      this.is080Selected = false;
      this.selectedAlertMethod = 'alert_n';
      this.contact_list = [],
        this.tableData = [],
        this.checkedPhones = [{ checked: false, phoneNumber: '' }];
      this.checkedPhones[0].checked = true;
      this.checkedPhones.push({ checked: false, phoneNumber: '' });
      this.contact_list_count = 0;
      this.isAlert = false;
      this.addressSelected = false;
      this.fileSelected = false;
      this.phoneSelected = false;
      this.param1Selected = false;
      this.param2Selected = false;
      this.param3Selected = false;
      this.param4Selected = false;
      this.param5Selected = false;
      this.param6Selected = false;

      this.initChoicesList();

      this.isContentEditable = true;
    },
    async registCustomerDB() {

      this.isCreateLoading = true;
      
      if (this.formData.from_phone_num.length <= 0) {
        alert("발신번호를 선택해주세요.");
        this.isCreateLoading = false;
        return;
      }
      if (this.to_phone_num_index.length <= 0) {
        alert("전화번호가 있는 컬럼을 선택해주세요.");
        this.isCreateLoading = false;
        return;
      }
      if (this.agree_marketing_index.length <= 0) {
        alert("마케팅 동의일자가 있는 컬럼을 선택해주세요.");
        this.isCreateLoading = false;
        return;
      }

      const payload = {
        from_phone_number: this.formData.from_phone_num,
        phone_number_index: this.to_phone_num_index,
        marketing_agree_index: this.agree_marketing_index,
        params: this.params,

      };

      console.log("payload : ", payload);

      await ApiCall.file('marketing/target/upload', 'POST', payload, null, this.excel_file, null, false).then(res => {
        // console.log("res : ",res);
        console.log("res : ", res);
        alert(`${res.data.data.total_count}건의 데이터가 등록되었습니다.`);



      })
        .catch(error => {
          // Handle error response
          console.error('Error :', error);
          // console.error('res.data : ',res.data);
          // You can display an error message to the user or perform any other necessary actions
          const parsedMessage = JSON.parse(error.response.data);
          alert(parsedMessage.resultMessage);


          // Reset any relevant state variables if needed

          this.isLoading = false;
        });
      this.isCreateLoading = false;
      this.resetData();

    },
    downloadFile(object) {
      // console.log('download file object: ', object);

      // file object key, reference key 통일 필요. 현재는 object key 로 apiCall 에서 사용.
      const pattern = /s3:\/\/[^/]+\/([^/]+\/[^/]+\/[^/]+)/;
      const match = object.match(pattern);
      let objectKey = ''
      objectKey = match[1];

      // console.log('download file objectKey: ', objectKey);

      ApiCall.file('download', 'POST', null, null, null, null, objectKey).then(response => {
        // console.log(response)
        const data = response.data;
        const fileName = response.filename;

        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link);
        // console.log(`File saved: ${fileName}`);
      }).catch(error => {
        console.error('Download failed:', error)
      })
    },
    reviewDocument() {
      const bootstrap = this.$store.state.bootstrap;

      // 모달 열기
      const modal = new bootstrap.Modal(
        document.getElementById("reviewDocument")
      );
      modal.show();
    },
    onPageChange(pageNumber) {
      this.currentPage = pageNumber;
    },
    onRowClicked({ list, index }) {
      this.selectedList = list;
      this.selectedRow = index;
      this.request_pk = this.selectedList.pk;
      this.request_sk = this.selectedList.sk;
      this.from_phone_number_type = this.selectedList.from_phone_number_type;
      this.request_from_phone_number = this.selectedList.from_phone_number;
      this.from_phone_number_alias = this.selectedList.from_phone_number_alias;
      this.request_file_list = this.selectedList.file_list;
      this.from_phone_number_status = this.selectedList.from_phone_number_status;
      this.authorize_type = this.selectedList.authorize_type;
      this.tenant_id = this.selectedList.tenant_id;
      this.create_id = this.selectedList.create_id;
      this.reviewDocument();
    },
    formatDate(dateString) {
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 8);
      const hour = dateString.substring(8, 10);
      const minute = dateString.substring(10, 12);
      const second = dateString.substring(12, 14);

      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    },
    getFormattedDate(daysBefore = 0) {
      const currentTimestamp = Date.now();
      const date = new Date(currentTimestamp - daysBefore * 24 * 60 * 60 * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    changeDateToString(dateString) {
      const dateObj = new Date(dateString);
      const year = dateObj.getFullYear();
      const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      const day = ("0" + dateObj.getDate()).slice(-2);
      const hour = ("0" + dateObj.getHours()).slice(-2);
      const minute = ("0" + dateObj.getMinutes()).slice(-2);
      const second = ("0" + dateObj.getSeconds()).slice(-2);
      return `${year}${month}${day}${hour}${minute}${second}`;
    },
    showCampaignDetail(item) {
      console.log("TEST", JSON.stringify(item));
      console.log("item,", item);


      this.campaignInfo = item;
      this.campaignInfo.send_request_dtm = item.send_request_dtm_origin;
      console.log("this.campaignInfo =", this.campaignInfo);
      // const uri='s3://m2g-dev/0/Image/1709885234_BQ5060198523400009299.jpg#sample.jpg';
      //Image Object 호출 및 URL로 변환
      if (this.campaignInfo.s3_uri) {

        const payload = {
          s3_uri: this.campaignInfo.s3_uri,
        };

        ApiCall.call('download/image', 'POST', payload).then(res => {

          console.log("res.data=", res.data);
          const base64Data = res.data.image; // 단일 이미지 데이터 접근
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'image/jpg' });

          const fileName = 'image.jpg';
          const fileObject = new File([blob], fileName, { type: 'image/jpg' });
          const fileUrl = URL.createObjectURL(fileObject);

          // 배열이 아닌 단일 객체로 처리하므로, 배열에 push하는 대신 직접 할당
          let imageInfo = { file: fileObject, url: fileUrl };

          console.log("fileObject=", fileObject);
          console.log("fileUrl=", fileUrl);

          this.campaignInfo.final_image_url = fileUrl;

        });
      }
      else {
        this.campaignInfo.final_image_url = null;
      }
      console.log("campaigninfo.send_request_dtm=", this.campaignInfo.send_request_dtm);
      const bootstrap = this.$store.state.bootstrap;
      // 모달 열기
      this.campaignDetailModal = new bootstrap.Modal(
        document.getElementById("campaignDetailModal")
      );

      this.campaignDetailModal.show();
    },
    showCustomerDBDetail() {
      // console.log("row clicked : " + item)
      // this.campaignDetail = item
      const bootstrap = this.$store.state.bootstrap;
      // 모달 열기
      this.customerDBModal = new bootstrap.Modal(
        document.getElementById("customerDBModal")
      );
      this.fromPhoneNumber();
      this.customerDBModal.show();
    },
    fromPhoneNumber() {
      (async () => {
        const user = await Auth.currentAuthenticatedUser();
        const idToken = user.signInUserSession.idToken;
        const tenantId = idToken.payload['cognito:groups'][0];
        const isBusinessVerified = user.attributes['custom:isBusinessVerified']
        this.tenant_id = tenantId;
        this.isBusinessVerified = isBusinessVerified;

        const payload = {
          status: "active",
        };
        console.log("abc");

        ApiCall.call('phone/from/numbers', 'POST', payload)
          .then(response => {
            if (Array.isArray(response.data) && response.data.length > 0) {
              let defaultOptionData = [{ value: "", label: "발신번호를 선택해주세요." }];
              const optionData = defaultOptionData.concat(response.data.map(item => {
                // Check if from_phone_number_alias is not null
                const label = item['from_phone_number_alias']
                  ? '(' + item['from_phone_number_alias'] + ') ' + item['from_phone_number']
                  : item['from_phone_number'];

                return {
                  label: label,
                  value: item['from_phone_number']
                };
              }));
              this.optionFromPhoneNumbers = optionData;
              // console.log("optionsFromPhoneNumbers :", this.optionFromPhoneNumbers);
              this.initChoicesList();
            } else {
              console.error('Error: Invalid response data format');
            }
          })
          .catch(error => {
            console.error('Error: Unable to fetch data from API', error);
          });
        const getGroupPayload = {
          status: "active",
        };
      })();
      this.isLoading = false;
    },
    // campaign_status 값을 기반으로 한글로 된 상태로 변환하는 함수
    convertStatus(status) {
      switch (status) {
        case 'save':
          return '저장';
        case 'request':
          return '요청';
        case 'approve':
          return '승인';
        case 'complete':
          return '완료';
        case 'reject':
          return '반려';
        case 'expire':
          return '만료';
        case 'cancle':
          return '취소';
        default:
          return '알 수 없음'; // 예상치 못한 값에 대한 처리
      }
    },
    changeData() {
      this.isLoading = true;

      // this.approveCampaignList = []
      this.currentPage = 1;
      const campaign_status = [];
      if (this.isSaveChecked) campaign_status.push('save')
      if (this.isRequestChecked) campaign_status.push('request')
      if (this.isApproveChecked) campaign_status.push('approve')
      if (this.isCompleteChecked) campaign_status.push('complete')
      if (this.isRejectChecked) campaign_status.push('reject')
      if (this.isExpireChecked) campaign_status.push('expire')
      if (this.isCancleChecked) campaign_status.push('cancle')


      const payload = {
        // tenant_id : '41',
        campaign_company_name: this.campaign_company_name,
        campaign_status: campaign_status,
        operator_yn: 'Y',
      }
      // console.log('payload :', payload)

      ApiCall.call('marketing/campaign', 'POST', payload).then(res => {
        // console.log('res.data :', res.data);
        // console.log('res.data.data.length :', res.data.data.length);
        this.result_count = res.data.data.length;
        // this.getCampaignList = res;

        const responseData = res.data.data.map(item => {

          // console.log('apicall item :', item);
          let formattedSendRequestDtm = item.send_request_dtm ? this.formatDate(item.send_request_dtm) : item.send_request_dtm;
          // console.log('formattedSendRequestDtm :', formattedSendRequestDtm);
          let formattedRequestDtm = item.request_dtm ? this.formatDate(item.request_dtm) : item.request_dtm;
          let formatted_campaign_status = item.campaign_status ? this.convertStatus(item.campaign_status) : item.campaign_status;



          return {
            request_tenant_id: item.request_tenant_id ?? 'N/A', // 기본값으로 'N/A' 설정
            sk: item.sk ?? 'N/A',
            request_tenant_name: item.request_tenant_name ?? 'N/A',
            campaign_status: formatted_campaign_status,
            campaign_name: item.campaign_name ?? 'N/A', // 캠페인명이 null일 경우 'N/A'로 표시
            campaign_object: item.campaign_object ?? 'N/A',
            campaign_purpose: item.campaign_purpose ?? 'N/A',
            request_user_phone_number: item.request_user_phone_number ?? 'N/A',
            target_count: item.target_count ?? 0, // 숫자형 필드는 0으로 설정할 수 있음
            send_request_dtm: formattedSendRequestDtm,
            send_request_dtm_origin: item.send_request_dtm,
            request_dtm: formattedRequestDtm,

            approve_tenant_id: item.approve_tenant_id,
            contact_address: item.contact_address,
            contact_phone_number: item.contact_phone_number,
            final_image_url: item.image_url1,
            landing_url: item.landing_url,
            secondary_customer_reference_id: item.secondary_customer_reference_id,
            send_type: item.send_type,
            sent_content: item.sent_content,
            from_phone_number: item.from_phone_number,
            target_segment: item.target_segment,
            keywords: item.keywords,
            subject: item.subject,
            service_type: item.service_type,
            sent_keyword: item.sent_emphasize_keyword,
            approve_tenant_name: item.approve_tenant_name,
            s3_uri: item.s3_uri,


          };
        });
        // console.log('responseData :', responseData);
        // console.log('getCampaignList : ', this.getCampaignList);

        this.approveCampaignList = responseData;

        // Update current page if it exceeds the number of pages available
        const maxPage = Math.ceil(this.approveCampaignList.length / this.itemsPerPage);
        if (this.currentPage > maxPage) {
          this.currentPage = maxPage;
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
<style>
.spinner-icon {
  font-size: 40px;
}
</style>
