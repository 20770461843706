<template>
  <div class="container-fluid mt-4">
    <div class="row mb-5">
      <FaxForm v-if="selectedForm === 'fax'" />
      
    </div>
  </div>
</template>

<script>
import FaxForm from "./components/FaxForm.vue";


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Settings",
  components: {
    FaxForm,
    
  },
  data() {
    return {
      selectedForm: "fax", // default selected form
    };
  },
};
</script>