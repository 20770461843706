import axios from 'axios';
import { Auth } from 'aws-amplify';
import mime from 'mime-types';

class AlbCall {
  constructor() {
    if(this.vdiDomainCheck()) {
      this.apiGatewayEndpoint = process.env.VUE_APP_ALB_URL_PROXY;
      Auth.configure({
        endpoint: 'https://changedendpoint.to-go.io'
      });
      // console.log('using proxy api url')
    } else {
      this.apiGatewayEndpoint = process.env.VUE_APP_ALB_URL;
      // console.log('using normal api url')
    }
  }



  async call(resourceName, method, data = null, param = null, file = null, header = null, blob = false) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const idToken = user.signInUserSession.idToken;
      const tenantId = idToken.payload['cognito:groups'][0];
      const tenantName = user.attributes['custom:tenant_name'];
      const userId = user.username;

      const defaultBody = {
        'tenant_id': tenantId,
        'tenant_name': tenantName,
        'user_id': userId,
      };
      const mergedBody = { ...defaultBody, ...data }

      let url = `${this.apiGatewayEndpoint}/${resourceName}`;

      const defaultParam = {
        'tenant_id': tenantId,
        'tenant_name': tenantName,
        'user_id': userId,
      }
      const mergedParam = { ...defaultParam, ...param }
      const queryString = objectToQueryString(mergedParam);
      url += `${queryString ? '?' + queryString : ''}`;

      let requestData = mergedBody;
      let defaultHeaders = {
        'Authorization': `Bearer ${idToken.getJwtToken()}`,
      };
      const mergedHeaders = { ...defaultHeaders, ...header }
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        for (const [key, value] of Object.entries(mergedBody)) {
            formData.append(key, value);
        }
        requestData = formData;
      }

      if (blob) {
        const response = await axios({
          method: method.toLowerCase(),
          url: url,
          data: requestData,
          responseType: 'blob',
          headers: mergedHeaders,
        });

        return response;
      } else {
        const response = await axios({
          method: method.toLowerCase(),
          url: url,
          data: requestData,
          headers: mergedHeaders,
        });

        return response;
      }

    } catch (error) {
      console.error('Error in API call:', error);
      // throw error;
      return error.response;
    }
  }

  async callNoAuth(resourceName, method, data = null, param = null, header = null) {
    try {
      let url = `${this.apiGatewayEndpoint}/${resourceName}`;

      if (param) {
        const queryString = objectToQueryString(param);
        url += `${queryString ? '?' + queryString : ''}`;
      }

      const response = await axios({
        method: method.toLowerCase(),
        url: url,
        data: data,
        headers: header,
      });

      return response.data;

    } catch (error) {
      console.error('Error in API call:', error);
      throw error;
    }
  }

  async file(resourceName, method, data = null, param = null, file = null, header = null, objectKey = null) {
    const allowMimeType = [
      'multipart/form-data',
      'application/ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/pdf',
      'application/vnd.ms-powerpoint',
      'application/msword',
      'image/jpeg',
      'image/png',
      'application/vnd.hancom.hwp',
      'application/haansofthwp',
      'application/x-hwp',
      'application/vnd.hancom.hwpx',
      'application/haansofthwpx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/vnd.ms-word.template.macroEnabled.12',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.spreadsheet',
      'text/rtf',
      'text/csv'
    ];

    try {
      const user = await Auth.currentAuthenticatedUser();
      const idToken = user.signInUserSession.idToken;
      const tenantId = idToken.payload['cognito:groups'][0];
      const tenantName = user.attributes['custom:tenant_name'];
      const userId = user.username;

      let url = `${this.apiGatewayEndpoint}/${resourceName}`;

      const defaultParam = {
        'tenant_id': tenantId,
        'tenant_name': tenantName,
        'user_id': userId,
      }
      const mergedParam = { ...defaultParam, ...param }
      const queryString = objectToQueryString(mergedParam);
      const apiUrl = url + `${queryString ? '?' + queryString : ''}`;

      let requestData = data;

      let defaultHeaders = {
        'Authorization': `Bearer ${idToken.getJwtToken()}`,
      };
      const mergedHeaders = { ...defaultHeaders, ...header }

      if (file) {
        // Determine the MIME type of the file
        let mimeType = file.type;

        // hwp 한글파일 처리,mime type 확인완될때.
        if (mimeType === '') {
          const fileExtention = file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2);
          console.log('file extention : ', fileExtention)
          switch (fileExtention) {
            case 'hwp':
              mimeType = 'application/vnd.hancom.hwp'
              break;
            case 'hwt':
              mimeType = 'application/vnd.hancom.hwt'
              break;
            case 'hml':
              mimeType = 'application/vnd.hancom.hml'
              break;
            case 'hwpx':
              mimeType = 'application/vnd.hancom.hwpx'
              break;
          }
        }

        // 업로드 파일 확장자 제한.
        // 백엔드에서 처리로 주석
        // if (!allowMimeType.includes(mimeType)) {
        //   console.log("statement :" + !(mimeType in allowMimeType))
        //   console.log("mimeType :" + mimeType)
        //   console.log("allowMimeType :" + allowMimeType)
        //   throw new Error("허용된 파일 확장자가 아닙니다.")
        // }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('mime_type', mimeType)
        console.log('mimeType :', mimeType)
        for (const [key, value] of Object.entries(data)) {
          formData.append(key, value);
        }
        requestData = formData;
      }

      if (objectKey) {
        // objectKey 가 존재할 시 Download 로 인식, Header 에 contentType 설정, param 에 objectkeywithouttenatid 설정
        let tenantId = objectKey.slice(0, objectKey.indexOf('/'));
        let objectKeyWithoutTenantId = objectKey.slice(objectKey.indexOf('/') + 1);
        let fileName = objectKeyWithoutTenantId.slice(objectKeyWithoutTenantId.indexOf('/') + 1);
        console.log('fileName : ', fileName); // Output: customer_group/명비즈팜4#명비즈팜4.xlsx

        console.log('tenantId : ', tenantId); // Output: 0
        console.log('objectKeyWithoutTenantId : ', objectKeyWithoutTenantId); // Output: customer_group/명비즈팜4#명비즈팜4.xlsx

        const mimeType = mime.lookup(fileName)

        console.log('file mimeType : ', mimeType)
        const downloadHeader = {
          'accept': '*/*',
          'Content-Type': mimeType,
        }
        const mergedDownloadHeader = {...mergedHeaders, ...downloadHeader}

        const downloadParam = {
          object_key: objectKey,
        }
        const mergedDownloadParam = { ...mergedParam, ...downloadParam }
        const downloadQueryString = objectToQueryString(mergedDownloadParam);
        const downloadUrl = url + `${downloadQueryString ? '?' + downloadQueryString : ''}`;

        console.log('final download header : ', mergedDownloadHeader)

        const response = await axios({
          method: method.toLowerCase(),
          url: downloadUrl,
          data: requestData,
          responseType: 'blob',
          headers: mergedDownloadHeader,
        });

        response.filename = fileName

        return response;
      } else {
        const response = await axios({
          method: method.toLowerCase(),
          url: apiUrl,
          data: requestData,
          headers: mergedHeaders,
        });

        return response;
      }

    } catch (error) {
      console.error('Error in API call(File):', error);
      throw error;
    }
  }

  hasVdiKeywordInThirdDomain(url) {
    const parsedUrl = new URL(url);
    const hostnameParts = parsedUrl.hostname.split('.');

    console.log("parts :", hostnameParts)

    if (hostnameParts.length >= 3) {
      return hostnameParts[0].includes('vdi');
    }

    return false;
  }

  vdiDomainCheck() {
    const currentUrl = window.location.href;

    console.log('href :', currentUrl)

    return this.hasVdiKeywordInThirdDomain(currentUrl);
  }
}

export default new AlbCall();

function objectToQueryString(obj) {
  if (!obj) {
    return '';
  }

  const queryString = Object.keys(obj)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
    .join('&');

  return queryString;
}

