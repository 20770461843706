<template>
  <div class="col-12">
    <div class="card">
      <!-- Card header -->
      <div class="card-header">
        <h5 class="mb-0">{{ title }}</h5>
        <p class="mb-0 text-sm">
          {{ subTitle }}
        </p>
      </div>
      <div class="card-body pt-0">
        <DataTable
            :data="tableData"
            :columns="tableColumns"
            :options="{select: true, buttons: ['copy','execl', 'pdf'], scrollY: true }"
            class="custom-table display table table-flush datatable-table"
        >
          <thead class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          </thead>
          <tbody
              class="text-sm font-weight-normal"
          >
          </tbody>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
// import 'datatables.net-buttons/js/buttons.html5';
// import 'datatables.net-responsive';
// import 'datatables.net-select';
import Button from 'datatables.net-buttons';
import Select from 'datatables.net-select';

export default {
  components: {
    DataTable,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    tableColumns: {
      type: Array,
      default() {
        return [];
      },
    },
    tableData: {
      type: Array,
      default() {
        return [{}];
      },
    },
  },
  setup() {
    DataTable.use(DataTablesCore);
    DataTable.use(Select);
    DataTable.use(Button);
  },
};
</script>

<style>
/* styles for just the table element in this component */
@import "~datatables.net-dt/css/jquery.dataTables.css";
@import '~datatables.net-buttons-dt/css/buttons.dataTables.css';
</style>
