import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueSweetalert2 from "vue-sweetalert2";
import MaterialDashboard from "./material-dashboard";
// import 'bootstrap-icons/font/bootstrap-icons.css'
// import pricing from './pages/pricing.vue'
// Import the SendHistory component
import SendHistory from './views/applications/SendHistory.vue';

import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
Amplify.configure(awsExports)

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

// marketing tag
import { createGtm } from '@gtm-support/vue-gtm';
import VueGtag from "vue-gtag";
// import "./assets/js/gtm.js";

//tutorial
import VueJsTour from '@globalhive/vuejs-tour';
import '@globalhive/vuejs-tour/dist/style.css';
// tour custom css
import './assets/css/vjt-custom.css'


//syncfusion
// import { registerLicense } from '@syncfusion/ej2-base';
// registerLicense('ORg4AjUWIQA/Gnt2UVhiQldPdUBDW3xLflFyVWJTf1l6dFJWESFaRnZdRl1hSHZTdUBnWXpWc3JR');

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
// appInstance.use(createGtm({
//   id: 'GTM-W5CCBKQN',
//   enabled: true,
//   vueRouter: router,
// }));
appInstance.use(VueGtag, {
  // config: { id: "G-XQCCJE5WY2" },
  includes: [{ id: "G-WGX7MYVJZH" }]
});

appInstance.use(VueSweetalert2);
appInstance.use(MaterialDashboard);

appInstance.use(VueJsTour);

// Register the SendHistory component globally
appInstance.component('SendHistory', SendHistory);
appInstance.component('EasyDataTable', Vue3EasyDataTable);

appInstance.mount("#app");
