<template>
  <div id="send-history" class="card mt-4 me-2">
    <div class="card-header">
      <h6 class="mb-0">조회 조건</h6>
    </div>
    <div class="card-body pt-0">
      <div v-if="hasSubTenant" class="row align-items-end">
        <div class="col-5">
          <label class="form-label mt-0 ms-0">부서 선택</label>
          <select
            id="sub-tenant-select"
            v-model="selectedTenant"
            class="form-control"
          >
          </select>
        </div>
        <div  class="col-5">
          <label class="form-label mt-0 ms-0">사용월 선택</label>
          <select
            id="invoice-select"
            v-model="selectedInvoiceMonth"
            class="form-control"
          >
          </select>
        </div>
        <div class="col-2 text-end mb-2">
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;" @click="getInvoice">
            조회
          </material-button>
        </div>
      </div>
      <div v-if="!hasSubTenant" class="row align-items-end">
        <div  class="col-10">
          <label class="form-label mt-0 ms-0">사용월 선택</label>
          <select
            id="invoice-select"
            v-model="selectedInvoiceMonth"
            class="form-control"
          >
          </select>
        </div>
        <div class="col-2 text-end mb-2">
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;" @click="getInvoice">
            조회
          </material-button>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-4 card mt-2 me-2">
    <div class="pb-0 card-header">
      <div class="col-12 text-end">
        <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;" @click="exportCSV">
          내보내기
        </material-button>
      </div>
    </div>
    <div class="card-body">
      <EasyDataTable
        v-if="allTable"
        table-class-name="customize-table"
        empty-message="조회된 내역이 없습니다."
        rows-per-page-message="페이지당 표시 행 수"
        rows-of-page-separator-message="중"
        :headers="tableColumns"
        :items="invoiceListForTable"
        :loading="isLoading"
        header-text-direction="right"
        body-text-direction="right"
        alternating
      >
        <template #loading>
          <img
            src="@/assets/img/Spinner-1s-200px.gif"
            style="width: 100px; height: 100px;"
          />
        </template>
        <template #item-invoice_month="item">
          {{ item.invoice_month.substring(0, 4) }}년{{ item.invoice_month.substring(4)}}월
        </template>
        <template #item-sms_count="item">
          <div v-if="item.sms_count > 0">
            {{ item.sms_count.toLocaleString() }}건
          </div>
        </template>
        <template #item-sms_unit_price="item">
          <div v-if="item.sms_count > 0">
            {{ item.sms_unit_price.toLocaleString() }}원
          </div>
        </template>
        <template #item-lms_count="item">
          <div v-if="item.lms_count > 0">
            {{ item.lms_count.toLocaleString() }}건
          </div>
        </template>
        <template #item-lms_unit_price="item">
          <div v-if="item.lms_count > 0">
            {{ item.lms_unit_price.toLocaleString() }}원
          </div>
        </template>
        <template #item-mms_count="item">
          <div v-if="item.mms_count > 0">
            {{ item.mms_count.toLocaleString() }}건
          </div>
        </template>
        <template #item-mms_unit_price="item">
          <div v-if="item.mms_count > 0">
            {{ item.mms_unit_price.toLocaleString() }}원
          </div>
        </template>
        <template #item-at_count="item">
          <div v-if="item.at_count > 0">
            {{ item.at_count.toLocaleString() }}건
          </div>
        </template>
        <template #item-at_unit_price="item">
          <div v-if="item.at_count > 0">
            {{ item.at_unit_price.toLocaleString() }}원
          </div>
        </template>
        <template #item-ft_count="item">
          <div v-if="item.ft_count > 0">
            {{ item.ft_count.toLocaleString() }}건
          </div>
        </template>
        <template #item-ft_unit_price="item">
          <div v-if="item.ft_count > 0">
            {{ item.ft_unit_price.toLocaleString() }}원
          </div>
        </template>
        <template #item-fi_count="item">
          <div v-if="item.fi_count > 0">
            {{ item.fi_count.toLocaleString() }}건
          </div>
        </template>
        <template #item-fi_unit_price="item">
          <div v-if="item.fi_count > 0">
            {{ item.fi_unit_price.toLocaleString() }}원
          </div>
        </template>
        <template #item-fl_count="item">
          <div v-if="item.fl_count > 0">
            {{ item.fl_count.toLocaleString() }}건
          </div>
        </template>
        <template #item-fl_unit_price="item">
          <div v-if="item.fl_count > 0">
            {{ item.fl_unit_price.toLocaleString() }}원
          </div>
        </template>
        <template #item-fc_count="item">
          <div v-if="item.fc_count > 0">
            {{ item.fc_count.toLocaleString() }}건
          </div>
        </template>
        <template #item-fc_unit_price="item">
          <div v-if="item.fc_count > 0">
            {{ item.fc_unit_price.toLocaleString() }}원
          </div>
        </template>
        <template #item-fw_count="item">
          <div v-if="item.fw_count > 0">
            {{ item.fw_count.toLocaleString() }}건
          </div>
        </template>
        <template #item-fw_unit_price="item">
          <div v-if="item.fw_count > 0">
            {{ item.fw_unit_price.toLocaleString() }}원
          </div>
        </template>
        <template #item-email_count="item">
          <div v-if="item.email_count > 0">
            {{ item.email_count.toLocaleString() }}건
          </div>
        </template>
        <template #item-email_unit_price="item">
          <div v-if="item.email_count > 0">
            {{ item.email_unit_price.toLocaleString() }}원
          </div>
        </template>
        <template #item-tax_amount="item">
          {{ item.tax_amount.toLocaleString() }}원
        </template>
        <template #item-invoice_amount="item">
          {{ item.invoice_amount.toLocaleString() }}원
        </template>
        <template #expand="item">
            <EasyDataTable
              :headers="detailTableColumns"
              :items="invoiceListDetail[item.invoice_month]"
              hide-footer
              border-cell
              header-text-direction="right"
              body-text-direction="right"
            >
            <template #item-sms_count="item">
              <div v-if="item.sms_count > 0">
                {{ item.sms_count.toLocaleString() }}건
              </div>
            </template>
            <template #item-sms_unit_price="item">
              <div v-if="item.sms_count > 0">
                {{ item.sms_unit_price.toLocaleString() }}원
              </div>
            </template>
            <template #item-lms_count="item">
              <div v-if="item.lms_count > 0">
                {{ item.lms_count.toLocaleString() }}건
              </div>
            </template>
            <template #item-lms_unit_price="item">
              <div v-if="item.lms_count > 0">
                {{ item.lms_unit_price.toLocaleString() }}원
              </div>
            </template>
            <template #item-mms_count="item">
              <div v-if="item.mms_count > 0">
                {{ item.mms_count.toLocaleString() }}건
              </div>
            </template>
            <template #item-mms_unit_price="item">
              <div v-if="item.mms_count > 0">
                {{ item.mms_unit_price.toLocaleString() }}원
              </div>
            </template>
            <template #item-at_count="item">
              <div v-if="item.at_count > 0">
                {{ item.at_count.toLocaleString() }}건
              </div>
            </template>
            <template #item-at_unit_price="item">
              <div v-if="item.at_count > 0">
                {{ item.at_unit_price.toLocaleString() }}원
              </div>
            </template>
            <template #item-ft_count="item">
              <div v-if="item.ft_count > 0">
                {{ item.ft_count.toLocaleString() }}건
              </div>
            </template>
            <template #item-ft_unit_price="item">
              <div v-if="item.ft_count > 0">
                {{ item.ft_unit_price.toLocaleString() }}원
              </div>
            </template>
            <template #item-fi_count="item">
              <div v-if="item.fi_count > 0">
                {{ item.fi_count.toLocaleString() }}건
              </div>
            </template>
            <template #item-fi_unit_price="item">
              <div v-if="item.fi_count > 0">
                {{ item.fi_unit_price.toLocaleString() }}원
              </div>
            </template>
            <template #item-fl_count="item">
              <div v-if="item.fl_count > 0">
                {{ item.fl_count.toLocaleString() }}건
              </div>
            </template>
            <template #item-fl_unit_price="item">
              <div v-if="item.fl_count > 0">
                {{ item.fl_unit_price.toLocaleString() }}원
              </div>
            </template>
            <template #item-fc_count="item">
              <div v-if="item.fc_count > 0">
                {{ item.fc_count.toLocaleString() }}건
              </div>
            </template>
            <template #item-fc_unit_price="item">
              <div v-if="item.fc_count > 0">
                {{ item.fc_unit_price.toLocaleString() }}원
              </div>
            </template>
            <template #item-fw_count="item">
              <div v-if="item.fw_count > 0">
                {{ item.fw_count.toLocaleString() }}건
              </div>
            </template>
            <template #item-fw_unit_price="item">
              <div v-if="item.fw_count > 0">
                {{ item.fw_unit_price.toLocaleString() }}원
              </div>
            </template>
            <template #item-email_count="item">
              <div v-if="item.email_count > 0">
                {{ item.email_count.toLocaleString() }}건
              </div>
            </template>
            <template #item-email_unit_price="item">
              <div v-if="item.email_count > 0">
                {{ item.email_unit_price.toLocaleString() }}원
              </div>
            </template>
            <template #item-tax_amount="item">
              {{ item.tax_amount.toLocaleString() }}원
            </template>
            <template #item-invoice_amount="item">
              {{ item.invoice_amount.toLocaleString() }}원
            </template>
          </EasyDataTable>
        </template>
      </EasyDataTable>
      <EasyDataTable
        v-if="!allTable"
        table-class-name="customize-table"
        empty-message="조회된 내역이 없습니다."
        rows-per-page-message="페이지당 표시 행 수"
        rows-of-page-separator-message="중"
        :headers="tableColumns"
        :items="invoiceListForTable"
        :loading="isLoading"
        header-text-direction="right"
        body-text-direction="right"
        alternating
      >
        <template #loading>
          <img
            src="@/assets/img/Spinner-1s-200px.gif"
            style="width: 100px; height: 100px;"
          />
        </template>
        <template #item-invoice_month="item">
          {{ item.invoice_month.substring(0, 4) }}년{{ item.invoice_month.substring(4)}}월
        </template>
        <template #item-sms_count="item">
          {{ item.sms_count.toLocaleString() }}건
        </template>
        <template #item-sms_unit_price="item">
          {{ item.sms_unit_price.toLocaleString() }}원
        </template>
        <template #item-lms_count="item">
          {{ item.lms_count.toLocaleString() }}건
        </template>
        <template #item-lms_unit_price="item">
          {{ item.lms_unit_price.toLocaleString() }}원
        </template>
        <template #item-mms_count="item">
          {{ item.mms_count.toLocaleString() }}건
        </template>
        <template #item-mms_unit_price="item">
          {{ item.mms_unit_price.toLocaleString() }}원
        </template>
        <template #item-at_count="item">
          {{ item.at_count.toLocaleString() }}건
        </template>
        <template #item-at_unit_price="item">
          {{ item.at_unit_price.toLocaleString() }}원
        </template>
        <template #item-tax_amount="item">
          {{ item.tax_amount.toLocaleString() }}원
        </template>
        <template #item-invoice_total_amount="item">
          {{ item.invoice_total_amount.toLocaleString() }}원
        </template>
      </EasyDataTable>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import ApiCall from "@/Interface/ApiCall";
import * as Choices from "choices.js";
import { Auth } from "aws-amplify";
import Papa from 'papaparse';


import { Header, Item } from "vue3-easy-data-table";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Invoice",
  components: {
    MaterialButton,
  },
  data() {
    return {
      isLoading: false,
      isOperator: false,
      isInvoiceLoaded: false,
      allTable: true,
      invoiceList: [],
      invoiceListForTable: [],
      invoiceListDetail: {},
      invoiceOptions: [],
      selectedInvoiceMonth: '',
      subTenantOptions: [],
      hasSubTenant: false,
      selectedTenant: '',
      selectInstanceList: null,
      selectInstanceInvoice: null,
      tableColumns : [],
      detailTableColumns : [],
    }
  },
  watch: {
    subTenantOptions(newList) {
      if (newList.length > 0) {
        this.selectedTenant = newList[0].value;
      }
    },
    invoiceOptions(newList) {
      if (newList.length > 0) {
        this.selectedInvoiceMonth = newList[0].value;
      }
    }
  },
  async mounted() {
    await this.triggerGetTenant();
   

    this.generateDateList();

    this.isLoading = false;
  },
  methods: {
    generateDateList() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1); // Start from the previous month
      const oneYearAgo = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);

      const dateList = [{label:'전체', value:'all'}];

      while (currentDate >= oneYearAgo) {
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Adjust for 0-based month index
        const date = `${year}${month}`;

        dateList.push({ label: date, value: date }); // Add to the end

        currentDate.setMonth(currentDate.getMonth() - 1);
      }

      this.invoiceOptions = dateList; // No need to reverse
      console.log('month : ', this.invoiceOptions);
      this.initChoicesInvoice();
    },
    exportCSV() {
      // First, decode URIs in the dictionary list
      const decodedList = this.invoiceList;

      // Convert the decoded list to CSV with PapaParse
      const csv = Papa.unparse(decodedList, {
        quotes: true,  // Enable quotes around values
      });

      // Create and trigger the download link with UTF-8 BOM
      const csvBlob = new Blob(["\ufeff", csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(csvBlob);
      link.setAttribute('download', 'invoice.csv');
      document.body.appendChild(link);
      link.click();
    },
    filteringMonth() {
      if (this.selectedInvoiceMonth == 'all') {
        this.invoiceListForTable = this.invoiceList;
      } else {
        this.invoiceListForTable = this.invoiceList.filter(dict => dict['invoice_month'] === this.selectedInvoiceMonth);
      }
    },
    async triggerGetTenant() {
      await this.getTenantList();
      this.initChoicesSubTenant();
    },
    initChoicesSubTenant() {
      if (this.hasSubTenant === true) {
        if (this.selectInstanceList) {
          this.selectInstanceList.destroy();
        }
        this.selectInstanceList = new Choices('#sub-tenant-select', {
          shouldSort: false,
          choices: this.subTenantOptions,
        });
      }
    },
    initChoicesInvoice() {
      if (this.selectInstanceInvoice) {
        this.selectInstanceInvoice.destroy();
      }
      setTimeout(() => {
        this.selectInstanceInvoice = new Choices('#invoice-select', {
          shouldSort: false,
          choices: this.invoiceOptions,
        });
      }, 0);
    },
    async getTenantList() {
      await ApiCall.call('user/auth/subtenant/list', 'POST').then(async (res) => {
        if (res.data.length > 0) {
          this.hasSubTenant = true;
        } else {
          // alert('no subtenant')
          return
          // subTenant 없을때 바로 청구월 노출
        }

        this.subTenantOptions = []
        this.subTenantList = []

        const optionData = res.data.map(item => ({
          label: item['tenant_name'],
          value: item['tenant_id']
        }));
        this.subTenantList = res.data.map(item => (
          item['tenant_id']
        ));
        // console.log('subTenantList :', this.subTenantList)

        // 전체 선택
        const all = [{ value: "all", label: "전체" }]
        // 자기 자신의 tenant
        const user = await Auth.currentAuthenticatedUser()
        const idToken = user.signInUserSession.idToken;
        const tenantId = idToken.payload['cognito:groups'][0];
        const tenantName = user.attributes['custom:tenant_name'];
        const myTenant = [{value:tenantId, label:tenantName}]
        this.subTenantOptions = this.subTenantOptions.concat(all)
        this.subTenantOptions = this.subTenantOptions.concat(myTenant)
        this.subTenantOptions = this.subTenantOptions.concat(optionData);
        console.log('subTenantOptions :', this.subTenantOptions)

        // console.log('tenant list options:' + this.subTenantOptions);
      })
    },
    async getInvoice() {
      this.invoiceList = [];
      this.invoiceListForTable = [];
      this.invoiceListDetail = [];

      let payload = {
        limit: '12',
      };

      // console.log("selected tenant : ", this.selectedTenant);
      if (this.selectedTenant === "all") {
        payload['request_tenant_id'] = this.subTenantOptions[1].value
        payload['sub_tenant_list'] = this.subTenantList
      } else {
        payload['request_tenant_id'] = this.selectedTenant
      }

      if (this.selectedInvoiceMonth !== "all") {
        payload['invoice_yyyymm'] = this.selectedInvoiceMonth
      }

      // console.log("payload for invoice :", payload)
      ApiCall.call('invoice/list', 'POST', payload).then(async response => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.invoiceList = response.data.map(item => {
            const tenantId = item.pk.split('#')[0];
            const matchingTenant = this.subTenantOptions.find(option => option.value === tenantId);
            const tenant_name = matchingTenant ? matchingTenant.label : '';
            const invoice_item_list = item['invoice_item_list']
            const sms_invoice = invoice_item_list.find(invoice_item => invoice_item.item_name === 'sms')
            const lms_invoice = invoice_item_list.find(invoice_item => invoice_item.item_name === 'lms')
            const mms_invoice = invoice_item_list.find(invoice_item => invoice_item.item_name === 'mms')
            const at_invoice = invoice_item_list.find(invoice_item => invoice_item.item_name === 'at')
            const ft_invoice = invoice_item_list.find(invoice_item => invoice_item.item_name === 'ft')
            const fi_invoice = invoice_item_list.find(invoice_item => invoice_item.item_name === 'fi')
            const fl_invoice = invoice_item_list.find(invoice_item => invoice_item.item_name === 'fl')
            const fc_invoice = invoice_item_list.find(invoice_item => invoice_item.item_name === 'fc')
            const fw_invoice = invoice_item_list.find(invoice_item => invoice_item.item_name === 'fw')
            const email_invoice = invoice_item_list.find(invoice_item => invoice_item.item_name === 'email')

            // console.log('tenantId :', tenantId)
            // console.log('subTenantOptions :', this.subTenantOptions)
            // console.log('matchingTenant :', matchingTenant)
            return {
              tenant_name: tenant_name,
              invoice_month: item.gsi_1_sk,
              sms_count: sms_invoice ? sms_invoice.item_count : 0,
              sms_unit_price: sms_invoice ? sms_invoice.unit_price : '- ',
              lms_count: lms_invoice ? lms_invoice.item_count : 0,
              lms_unit_price: lms_invoice ? lms_invoice.unit_price : '- ',
              mms_count: mms_invoice ? mms_invoice.item_count : 0,
              mms_unit_price: mms_invoice ? mms_invoice.unit_price : '- ',
              at_count: at_invoice ? at_invoice.item_count : 0,
              at_unit_price: at_invoice ? at_invoice.unit_price : '- ',
              ft_count: ft_invoice ? ft_invoice.item_count : 0,
              ft_unit_price: ft_invoice ? ft_invoice.unit_price : '- ',
              fi_count: fi_invoice ? fi_invoice.item_count : 0,
              fi_unit_price: fi_invoice ? fi_invoice.unit_price : '- ',
              fl_count: fl_invoice ? fl_invoice.item_count : 0,
              fl_unit_price: fl_invoice ? fl_invoice.unit_price : '- ',
              fc_count: fc_invoice ? fc_invoice.item_count : 0,
              fc_unit_price: fc_invoice ? fc_invoice.unit_price : '- ',
              fw_count: fw_invoice ? fw_invoice.item_count : 0,
              fw_unit_price: fw_invoice ? fw_invoice.unit_price : '- ',
              email_count: email_invoice ? email_invoice.item_count : 0,
              email_unit_price: email_invoice ? email_invoice.unit_price : '- ',
              // tax_amount: item.tax_amount,
              invoice_amount: item.invoice_amount,
            }
          });

          if (this.selectedTenant === 'all') {
            // 합계 데이터 만들기.
            // Step 1: Group and summarize the data
            const groupedAndSummarizedData = this.invoiceList.reduce((result, item) => {
              const invoiceMonth = item.invoice_month;

              if (!result[invoiceMonth]) {
                result[invoiceMonth] = {
                  invoice_month: invoiceMonth,
                  tenant_name: "전체",
                  sms_count: 0,
                  sms_unit_price: 0,
                  lms_count: 0,
                  lms_unit_price: 0,
                  mms_count: 0,
                  mms_unit_price: 0,
                  at_count: 0,
                  at_unit_price: 0,
                  ft_count: 0,
                  ft_unit_price: 0,
                  fi_count: 0,
                  fi_unit_price: 0,
                  fl_count: 0,
                  fl_unit_price: 0,
                  fc_count: 0,
                  fc_unit_price: 0,
                  fw_count: 0,
                  fw_unit_price: 0,
                  email_count: 0,
                  email_unit_price: 0,
                  // tax_amount: 0,
                  invoice_amount: 0,
                };
              }

              result[invoiceMonth].sms_unit_price = Math.max(
                result[invoiceMonth].sms_unit_price,
                item.sms_unit_price !== '- ' ? item.sms_unit_price : 0
              );

              result[invoiceMonth].lms_unit_price = Math.max(
                result[invoiceMonth].lms_unit_price,
                item.lms_unit_price !== '- ' ? item.lms_unit_price : 0
              );

              result[invoiceMonth].mms_unit_price = Math.max(
                result[invoiceMonth].mms_unit_price,
                item.mms_unit_price !== '- ' ? item.mms_unit_price : 0
              );

              result[invoiceMonth].at_unit_price = Math.max(
                result[invoiceMonth].at_unit_price,
                item.at_unit_price !== '- ' ? item.at_unit_price : 0
              );

              result[invoiceMonth].ft_unit_price = Math.max(
                result[invoiceMonth].ft_unit_price,
                item.ft_unit_price !== '- ' ? item.ft_unit_price : 0
              );

              result[invoiceMonth].fi_unit_price = Math.max(
                result[invoiceMonth].fi_unit_price,
                item.fi_unit_price !== '- ' ? item.fi_unit_price : 0
              );

              result[invoiceMonth].fl_unit_price = Math.max(
                result[invoiceMonth].fl_unit_price,
                item.fl_unit_price !== '- ' ? item.fl_unit_price : 0
              );

              result[invoiceMonth].fc_unit_price = Math.max(
                result[invoiceMonth].fc_unit_price,
                item.fc_unit_price !== '- ' ? item.fc_unit_price : 0
              );

              result[invoiceMonth].fw_unit_price = Math.max(
                result[invoiceMonth].fw_unit_price,
                item.fw_unit_price !== '- ' ? item.fw_unit_price : 0
              );

              result[invoiceMonth].email_unit_price = Math.max(
                result[invoiceMonth].email_unit_price,
                item.email_unit_price !== '- ' ? item.email_unit_price : 0
              );

              result[invoiceMonth].sms_count += item.sms_count;
              result[invoiceMonth].lms_count += item.lms_count;
              result[invoiceMonth].mms_count += item.mms_count;
              result[invoiceMonth].at_count += item.at_count;
              result[invoiceMonth].ft_count += item.ft_count;
              result[invoiceMonth].fi_count += item.fi_count;
              result[invoiceMonth].fl_count += item.fl_count;
              result[invoiceMonth].fc_count += item.fc_count;
              result[invoiceMonth].fw_count += item.fw_count;
              result[invoiceMonth].email_count += item.email_count;
              // result[invoiceMonth].tax_amount += item.tax_amount;
              result[invoiceMonth].invoice_amount += item.invoice_amount;

              return result;
            }, {});

            // Step 2: Convert the grouped data into an array and order by invoice_month
            const summarizedList = Object.values(groupedAndSummarizedData).sort((a, b) => {
              // You might need to parse invoice_month as date here for correct sorting
              return a.invoice_month.localeCompare(b.invoice_month);
            });

            // 세부항목 데이터 만들기
            // Step 3: Create a dictionary with invoice_month as keys and lists as values
            const invoiceMonthDictionary = this.invoiceList.reduce((result, item) => {
              const invoiceMonth = item.invoice_month;

              if (!result[invoiceMonth]) {
                result[invoiceMonth] = [];
              }

              result[invoiceMonth].push(item);

              return result;
            }, {});
            // console.log('detail invoice :', invoiceMonthDictionary)


            this.allTable = true;
            this.invoiceListForTable = summarizedList;
            this.invoiceListDetail = invoiceMonthDictionary;
          } else {
            this.allTable = false;
            this.invoiceListForTable = this.invoiceList;
          }

          this.generateTableColumns(this.invoiceListForTable);
          this.generateDetailTableColumns(this.invoiceListDetail);

          this.isInvoiceLoaded = true;
        } else {
          // console.error('Error: Invalid response data format');
          alert("조회내역이 없습니다.")
        }
      }).catch(error => {
        console.error('Error: Unable to fetch data from API', error);
      });
    },
    generateTableColumns(data) {
      const hasSMS = data.some(item => item.sms_count > 0);
      const hasLMS = data.some(item => item.lms_count > 0);
      const hasMMS = data.some(item => item.mms_count > 0);
      const hasAT = data.some(item => item.at_count > 0);
      const hasFT = data.some(item => item.ft_count > 0);
      const hasFI = data.some(item => item.fi_count > 0);
      const hasFL = data.some(item => item.fl_count > 0);
      const hasFC = data.some(item => item.fc_count > 0);
      const hasFW = data.some(item => item.fw_count > 0);
      const hasEMAIL = data.some(item => item.email_count > 0);

      // 추가 메시지 타입에 대해 동일하게 적용

      this.tableColumns = [
        { text: "사용년월", value: "invoice_month", sortable: true },
        { text: "부서", value: "tenant_name", sortable: true},
        ...hasSMS ? [{ text: "SMS건수", value: "sms_count", sortable: true }, { text: "SMS요율", value: "sms_unit_price", sortable: true }] : [],
        ...hasLMS ? [{ text: "LMS건수", value: "lms_count", sortable: true }, { text: "LMS요율", value: "lms_unit_price", sortable: true }] : [],
        ...hasMMS ? [{ text: "MMS건수", value: "mms_count", sortable: true }, { text: "MMS요율", value: "mms_unit_price", sortable: true }] : [],
        ...hasAT ? [{ text: "알림톡건수", value: "at_count", sortable: true }, { text: "알림톡요율", value: "at_unit_price", sortable: true }] : [],
        ...hasFT ? [{ text: "친구톡건수", value: "ft_count", sortable: true }, { text: "친구톡요율", value: "ft_unit_price", sortable: true }] : [],
        ...hasFI ? [{ text: "친구톡(i)건수", value: "fi_count", sortable: true }, { text: "친구톡(i)요율", value: "fi_unit_price", sortable: true }] : [],
        ...hasFL ? [{ text: "친구톡(l)건수", value: "fl_count", sortable: true }, { text: "친구톡(l)요율", value: "fl_unit_price", sortable: true }] : [],
        ...hasFC ? [{ text: "친구톡(c)건수", value: "fc_count", sortable: true }, { text: "친구톡(c)요율", value: "fc_unit_price", sortable: true }] : [],
        ...hasFW ? [{ text: "친구톡(w)건수", value: "fw_count", sortable: true }, { text: "친구톡(w)요율", value: "fw_unit_price", sortable: true }] : [],
        ...hasEMAIL ? [{ text: "이메일건수", value: "email_count", sortable: true }, { text: "이메일요율", value: "email_unit_price", sortable: true }] : [],

        { text: "합계(부가세별도)", value: "invoice_amount", sortable: true },
      ];

    },
    generateDetailTableColumns(data) {
      const allValues = Object.values(data).flatMap(arr => arr);

      const hasSMS = allValues.some(item => item.sms_count > 0);
      const hasLMS = allValues.some(item => item.lms_count > 0);
      const hasMMS = allValues.some(item => item.mms_count > 0);
      const hasAT = allValues.some(item => item.at_count > 0);
      const hasFT = allValues.some(item => item.ft_count > 0);
      const hasFI = allValues.some(item => item.fi_count > 0);
      const hasFL = allValues.some(item => item.fl_count > 0);
      const hasFC = allValues.some(item => item.fc_count > 0);
      const hasFW = allValues.some(item => item.fw_count > 0);
      const hasEMAIL = allValues.some(item => item.email_count > 0);

      // 추가 메시지 타입에 대해 동일하게 적용

      this.detailTableColumns = [
        { text: "부서", value: "tenant_name", sortable: true},
        ...hasSMS ? [{ text: "SMS건수", value: "sms_count", sortable: true }, { text: "SMS요율", value: "sms_unit_price", sortable: true }] : [],
        ...hasLMS ? [{ text: "LMS건수", value: "lms_count", sortable: true }, { text: "LMS요율", value: "lms_unit_price", sortable: true }] : [],
        ...hasMMS ? [{ text: "MMS건수", value: "mms_count", sortable: true }, { text: "MMS요율", value: "mms_unit_price", sortable: true }] : [],
        ...hasAT ? [{ text: "알림톡건수", value: "at_count", sortable: true }, { text: "알림톡요율", value: "at_unit_price", sortable: true }] : [],
        ...hasFT ? [{ text: "친구톡건수", value: "ft_count", sortable: true }, { text: "친구톡요율", value: "ft_unit_price", sortable: true }] : [],
        ...hasFI ? [{ text: "친구톡(i)건수", value: "fi_count", sortable: true }, { text: "친구톡(i)요율", value: "fi_unit_price", sortable: true }] : [],
        ...hasFL ? [{ text: "친구톡(l)건수", value: "fl_count", sortable: true }, { text: "친구톡(l)요율", value: "fl_unit_price", sortable: true }] : [],
        ...hasFC ? [{ text: "친구톡(c)건수", value: "fc_count", sortable: true }, { text: "친구톡(c)요율", value: "fc_unit_price", sortable: true }] : [],
        ...hasFW ? [{ text: "친구톡(w)건수", value: "fw_count", sortable: true }, { text: "친구톡(w)요율", value: "fw_unit_price", sortable: true }] : [],
        ...hasEMAIL ? [{ text: "이메일건수", value: "email_count", sortable: true }, { text: "이메일요율", value: "email_unit_price", sortable: true }] : [],

        { text: "합계(부가세별도)", value: "invoice_amount", sortable: true },
      ];

    },
  },
};
</script>
