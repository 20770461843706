<template>
  <div v-if="isLoading" class="position-absolute top-0 bottom-0 d-flex justify-content-center align-items-center">
    <div class="spinner-container">
      <i class="fas fa-spinner fa-spin spinner-icon text-info"></i>
    </div>
  </div>
  <div class="py-3 container-fluid">
    <div class="row">
      <div class="card h-100">
        <div class="p-3 pb-0 card-header">
          <div class="d-flex justify-content-between">
            <h6 class="mb-0">SMS 전송 Test (사용자 수신번호)</h6>
          </div>
        </div>
        <div class="p-3 card-body">
          <div class="row align-items-start mt-2">
            <div class="col-6">
              <form ref="form" @submit.prevent="submitForm">
                <div class="row align-items-start mt-2">
                  <div class="row align-items-center">
                    <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">발신번호: </span>
                    </div>
                    <div class="col-sm-9">
                      <material-button
                        id="fromPhoneNumbers"
                        color="success"
                        size="sm"
                        variant="outline"
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style="width: 180px"
                        :disabled="isLoading"
                      >
                        {{ formData.from_phone_num || "테스트 발신번호" }}
                      </material-button>
                      <ul class="dropdown-menu" aria-labelledby="fromPhoneNumbers">
                        <li v-for="optionFromPhoneNumber in optionFromPhoneNumbers" :key="optionFromPhoneNumber">
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            @click="selectFromPhone(optionFromPhoneNumber)"
                          >
                            {{ optionFromPhoneNumber }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row align-items-center mt-2">
                    <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">예약발송:</span>
                    </div>
                    <div class="col-sm-9">
                      <material-input
                        id="senddtm_datetime"
                        v-model="formData.send_request_dtm"
                        size="sm"
                        type="datetime-local"
                        style="width: 180px"
                        step="600"
                        :min="minDatetime"
                      >
                      </material-input>
                    </div>
                  </div>
                  <div class="row align-items-center mt-2">
                    <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">전송방식:</span>
                    </div>
                    <div v-if="isBusinessVerified == '2'" class="col-sm-9 d-flex align-items-center">
                      <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">수동입력</label>
                      <material-radio
                        id="manual"
                        v-model.sync="selectedSendMethod"
                        name="send_method"
                        style="margin-left: -20px"
                        :value="'manual'"
                        :checked="selectedSendMethod === 'manual'"
                        @change="onChangeSendMethod('manual')"
                      >
                      </material-radio>
                      <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">엑셀업로드</label>
                      <material-radio
                        id="excel"
                        v-model.sync="selectedSendMethod"
                        name="send_method"
                        style="margin-left: -20px"
                        :value="'excel'"
                        :checked="selectedSendMethod === 'excel'"
                        :disabled="true"
                        @change="onChangeSendMethod('excel')"
                      >
                      </material-radio>
                      <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">주소록전송</label>
                      <material-radio
                        id="address"
                        v-model.sync="selectedSendMethod"
                        name="send_method"
                        style="margin-left: -20px"
                        :value="'address'"
                        :checked="selectedSendMethod === 'address'"
                        :disabled="true"
                        @change="onChangeSendMethod('address')"
                      >
                      </material-radio>
                    </div>
                  </div>
                  <div v-if="selectedSendMethod == 'manual'" id="send_from_manual" class="row align-items-center mt-2">
                    <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">수동입력:</span>
                    </div>
                    <div class="col-sm-9">
                      <span style="font-weight: bold; font-size: 0.8rem;">전화번호</span>
                      <div id="to_phone_table" style="height: 250px; overflow-y: auto;">
                        <div class="table-responsive">
                          <table id="to-phone-list" class="table table-flush">
                            <tbody>
                            <tr>
                              <td> <!-- Use a <td> to contain your content -->
                                <div class="d-flex align-items-start" style="padding: 0;">
                                  <material-checkbox
                                    id="to_phone_number_checkbox"
                                    :checked="true"
                                    :disabled="true"
                                  />
                                  <material-input
                                    id="to_phone_number"
                                    v-model="defaultPhoneNumber"
                                    size="sm"
                                    placeholder="수신번호"
                                    style="width: 150px;"
                                    :value="defaultPhoneNumber"
                                    :disabled="true"
                                  ></material-input>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="selectedSendMethod == 'excel'" id="send_from_excel" class="row align-items-center mt-2">
                    <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">엑셀선택:</span><br>
                      <label class="btn-sm" style="display: inline-block; position: relative; cursor: pointer;">
                        <i class="material-icons icon-hover icon-large" style="cursor: pointer; margin-top: 0px; margin-left: -15px;"
                           @click="openFileDialog">upload</i>
                        <span style="cursor: pointer; display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 20px;" @click="openFileDialog">UPLOAD</span>
                        <input ref="fileInput" type="file" style="display: none" @change="handleFileUpload" />
                      </label><br><br>
                      <span style="font-weight: bold; font-size: 0.8rem;">샘플파일:</span><br>
                      <label class="btn-sm" style="display: inline-block; position: relative; cursor: pointer;">
                        <i class="material-icons icon-hover icon-large" style="cursor: pointer; margin-top: 0px; margin-left: -15px;"
                           @click="downloadExcelSample">download</i>
                        <span style="cursor: pointer; display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 20px;" @click="downloadExcelSample">DOWNLOAD</span>
                      </label>
                    </div>
                    <div id="address_name" class="col-sm-9" style="height: 300px; overflow-y: auto;">
                      <span style="font-weight: bold; font-size: 0.8rem;">주소록명</span><br>
                      <td style="padding: 5px;">
                        <div class="d-flex align-items-start">
                          <material-input id="address_name" v-model="formData.customer_group_alias" size="sm" style="margin-left: -3px;" :disabled="!fileSelected"></material-input>
                        </div>
                      </td>
                      <div class="table-responsive">
                        <table id="excel_table" class="table table-flush">
                          <div class="col-sm-5">

                            <span style="font-weight: bold; font-size: 0.8rem;">전화번호</span><br>
                            <material-button
                              id="toPhoneNumberIndex"
                              color="success"
                              size="sm"
                              variant="outline"
                              class="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style="width: 180px"
                              :disabled = "!fileSelected"
                            >
                              {{ selectedPhoneNumberField || "전화번호헤더 선택" }}
                            </material-button>
                            <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                              <li v-for="field in headerFields" :key="field">
                                <a class="dropdown-item border-radius-md" href="javascript:;"
                                   @click="handlePhoneSelection(field)">{{ field }}</a>
                              </li>
                            </ul>
                            <br>
                            <span style="font-weight: bold; font-size: 0.8rem;">변수</span>
                            <div class="d-flex" style="width: 270px;">
                              <material-button
                                id="paramIndex1"
                                color="success"
                                size="sm"
                                variant="outline"
                                class="dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style="width: 90px"
                                :disabled = "!phoneSelected"
                              >
                                {{ selectedParamField1 || "변수1" }}
                              </material-button>
                              <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                <li v-for="field in headerFields" :key="field">
                                  <a class="dropdown-item border-radius-md" href="javascript:;"
                                     @click="handleParmSelection1(field)">{{ field }}</a>
                                </li>
                              </ul>
                              <material-button
                                id="paramIndex2"
                                color="success"
                                size="sm"
                                variant="outline"
                                class="dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style="width: 90px"
                                :disabled = "!param1Selected"
                              >
                                {{ selectedParamField2 || "변수2" }}
                              </material-button>
                              <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                <li v-for="field in headerFields" :key="field">
                                  <a class="dropdown-item border-radius-md" href="javascript:;"
                                     @click="handleParmSelection2(field)">{{ field }}</a>
                                </li>
                              </ul>
                              <material-button
                                id="paramIndex3"
                                color="success"
                                size="sm"
                                variant="outline"
                                class="dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style="width: 90px"
                                :disabled = "!param2Selected"
                              >
                                {{ selectedParamField3 || "변수3" }}
                              </material-button>
                              <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                <li v-for="field in headerFields" :key="field">
                                  <a class="dropdown-item border-radius-md" href="javascript:;"
                                     @click="handleParmSelection3(field)">{{ field }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div v-if="selectedSendMethod == 'address'" id="send_from_customer_group" class="row align-items-center mt-2">
                    <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">주소록선택:</span><br>
                      <div style="display: flex;">
                        <material-button
                          id="toAddress"
                          color="success"
                          size="sm"
                          variant="outline"
                          class="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style="flex-grow: 1;"
                        >
                          {{ formData.customer_group_id || "주소록" }}
                        </material-button>
                        <ul class="dropdown-menu" aria-labelledby="customerGroupIds">
                          <li v-for="optionCustomerGroupId in optionCustomerGroupIds" :key="optionCustomerGroupId">
                            <a
                              class="dropdown-item"
                              href="javascript:;"
                              @click="selectCustomerGroupId(optionCustomerGroupId)"
                            >
                              {{ optionCustomerGroupId }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-9">
                      <span style="font-weight: bold; font-size: 0.8rem; margin-right: 0.5rem;">주소록명</span><span style="font-weight: bold; font-size: 0.7rem;">(최대 100건까지 조회됩니다.)</span><br>
                      <td style="padding: 5px;">
                        <div>
                          <material-input id="address_name" v-model="formData.customer_group_id" size="sm" style="margin-left: -3px;" readonly></material-input>
                          <span style="font-weight: bold; font-size: 0.7rem;">총 주소록 건수: {{contact_list_count}}</span>
                        </div>
                      </td>
                      <!-- <span style="font-weight: bold; font-size: 0.8rem;">전화번호</span> -->
                      <div id="address_table" style="height: 200px; overflow-y: auto;">
                        <div class="table-responsive">
                          <material-table
                            title="연락처"
                            :headers=tableColumns
                            :lists=contact_list
                            :font-size=fontSize
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center mt-2">
                    <div class="col-3">
                      <span style="font-weight: bold; font-size: 0.8rem;">단축URL:</span>
                    </div>
                    <div class="col-9">
                      <URLShortnerInsert
                        id="urlShortner"
                        :url="linkUrl"
                        :link-type="linkType"
                        @urlCreated="handleUrlCreated"
                      />
                    </div>
                  </div>
                  <div class="row align-items-center mt-2">
                    <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">식별정보:</span>
                    </div>
                    <div class="col-sm-9" style="max-width: 180px;">
                      <div class="mt-2">
                        <material-input
                          id="primary_customer_reference_id"
                          v-model="formData.primary_customer_reference_id"
                          type="text"
                          size="sm"
                          label="고객식별ID"
                          label-size="sm"
                          variant="outline"
                          maxlength="36"
                        >
                        </material-input>
                      </div>
                      <div class="mt-2">
                        <material-input
                          id="secondary_customer_reference_id"
                          v-model="formData.secondary_customer_reference_id"
                          type="text"
                          size="sm"
                          label="캠페인ID"
                          label-size="sm"
                          variant="outline"
                          maxlength="36"
                        >
                        </material-input>
                      </div>

                    </div>
                  </div>
                  <div class="row align-items-center mt-2">
                    <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">결과알림:</span>
                    </div>
                    <div class="col-sm-9 d-flex">
                      <label for="send_alert" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">미수신</label>
                      <material-radio
                        id="alert_n"
                        v-model.sync="selectedAlertMethod"
                        name="send_alert"
                        style="margin-left: -20px"
                        :value="'alert_n'"
                        :checked="selectedAlertMethod === 'alert_n'"
                        :disabled="formData.send_request_dtm == ''"
                        @change="onChangeAlertMethod('alert_n')"
                      >
                      </material-radio>
                      <label for="send_alert" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">수신</label>
                      <material-radio
                        id="alert_y"
                        v-model.sync="selectedAlertMethod"
                        name="send_alert"
                        style="margin-left: -20px"
                        :value="'alert_y'"
                        :checked="selectedAlertMethod === 'alert_y'"
                        :disabled="formData.send_request_dtm == ''"
                        @change="onChangeAlertMethod('alert_y')"
                      >
                      </material-radio>
                      <div class="mt-0" style="margin-left: 10px;">
                        <material-input
                          id="alert_phone_number"
                          v-model="formData.alert_phone_number"
                          type="number"
                          size="sm"
                          label="알림수신자번호"
                          label-size="sm"
                          variant="outline"
                          maxlength="36"
                          :disabled=!isAlert
                        >
                        </material-input>
                      </div>
                    </div>
                  </div>
            <!--              <div class="row align-items-center mt-2">-->
            <!--                  <div class="col-sm-3 pr-0">-->
            <!--                      <span style="font-weight: bold; font-size: 0.8rem;">수신거부:</span>-->
            <!--                  </div>-->
            <!--                  <div class="col-sm-9 d-flex align-items-center">-->
            <!--                      <label for="blacklist" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">없음</label>-->
            <!--                      <material-radio -->
            <!--                          name="blacklist"-->
            <!--                          id="none"-->
            <!--                          style="margin-left: -20px"-->
            <!--                          :value="'none'"-->
            <!--                          v-model.sync="selectedBlacklistMethod"-->
            <!--                          @change="onChangeBlacklistMethod('none')"-->
            <!--                          :checked="selectedBlacklistMethod === 'none'"-->
            <!--                      >-->
            <!--                      </material-radio>-->
            <!--                      <label for="blacklist" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">080수신거부</label>-->
            <!--                      <material-radio -->
            <!--                          name="blacklist"-->
            <!--                          id="080"-->
            <!--                          style="margin-left: -20px"-->
            <!--                          :value="'080'"-->
            <!--                          v-model.sync="selectedBlacklistMethod"-->
            <!--                          @change="onChangeBlacklistMethod('080')"-->
            <!--                          :checked="selectedBlacklistMethod === '080'"-->
            <!--                      >-->
            <!--                      </material-radio>-->
            <!--                  </div>-->
            <!--              </div>-->
                </div>
              </form>
            </div>
            <div class="col-6">
              <div class="row d-flex justify-content-center">
                <div class="image-container max-width-300" style="position: relative; display: inline-block;">
                  <img src="@/assets/img/smartphone2.png" class="img-fluid border-radius-lg max-width-300">
                  <div class="text-start" style="position: absolute; top: 18%; left: 55%; transform: translateX(-50%);">
                    <message-textarea
                      id="content"
                      v-model="typed_content"
                      placeholder="내용..."
                      :disabled="!isContentEditable"
                      :max-length=90
                    >
                    </message-textarea>
                    <p style="font-weight: bold; font-size: 0.8rem; text-align: left;">{{ calculateByteLength(typed_content) }}/90 bytes</p>
                  </div>
                </div>
                <div v-if="typed_content && calculateByteLength(typed_content) >= 90">
                  <p class="text-danger" style="margin-top: -125px; font-weight: bold; font-size: 0.8rem; text-align: center;">입력할 수 있는 바이트 수가 초과되어<br>내용이 잘릴 수 있습니다.<br> LMS 발송을 권장합니다.</p>
                </div>
              </div>
            </div>
            <div class="col-sm-9 d-flex">
              <label for="validateMessageContent" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 6px; margin-right: 5px; margin-left: 15px;">문법/문맥 검증 (추가시간 소요)</label>
              <material-checkbox
                    id="checkboxValidateMessageContent"
                    v-model="isValidateMessageContent"
                    name="checkboxValidateMessageContent"
                    :checked="isValidateMessageContent"
                >
              </material-checkbox>
            </div>
            <div class="row position-absolute bottom-1 end-2">
              <div class="col-12 text-end">
                <material-button type="submit" color="primary" size="sm" variant="default" style="width: 120px" @click="submitForm">
                  전송
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import ApiCall from "@/Interface/ApiCall";
import { Auth } from 'aws-amplify'
import * as XLSX from 'xlsx'
import MaterialTable from "@/components/MaterialTable.vue";
import URLShortnerInsert from "@/views/message/components/URLShortnerInsert.vue";
import MessageTextarea from "@/components/MessageTextarea.vue";
import * as Choices from "choices.js";

export default {
  components: {
    MaterialButton,
    MaterialInput,
    MaterialCheckbox,
    MaterialRadio,
    MaterialTable,
    URLShortnerInsert,
    MessageTextarea,
  },
  data() {
    return {
      formData: {
        from_phone_num: '',
        send_request_dtm: '',
        to_phone_number: [''],
        customer_group_id: '',
        content: '',
        customer_group_alias: '',
        excel_file_name: '',
        excelfile: '',
        primary_customer_reference_id: '',
        secondary_customer_reference_id: '',
        send_status: '',
        alert_phone_number: '',
      },
      typed_content: '',
      to_phone_num_index: '',
      headerFields: [],
      params: [],
      selectedPhoneNumberField: '전화번호 선택',
      selectedParamField1: '변수1',
      selectedParamField2: '변수2',
      selectedParamField3: '변수3',
      selectedSendMethod: 'manual',
      selectedBlacklistMethod: 'none',
      selectedAlertMethod: 'alert_n',
      checkedPhones: [{ checked: false, phoneNumber: '' }],
      optionFromPhoneNumbers: [],
      optionCustomerGroupIds: [],
      sendNumberGroupData: [],

      //이미지 업로드
      uploadImages: [
        { files: [], data: null, imageID: null },
        { files: [], data: null, imageID: null },
        { files: [], data: null, imageID: null }
      ],
      imageReturnID: [],

      contact_list: [], 
      fontSize: 'xs',
      tableData: [],
      tableColumns: ['전화번호', '변수',''],

      contact_list_count: 0,

      isLoading: true,
      isAlert: false,
      addressSelected: false,
      fileSelected: false,
      phoneSelected: false,
      param1Selected: false,
      param2Selected: false,
      param3Selected: false,
      param4Selected: false,
      param5Selected: false,
      param6Selected: false,
      selectInstance: null,
      isValidateMessageContent: false,

      // 단축link
      linkUrl: '',
      linkType: '',
      linkFileName: '',

      defaultPhoneNumber: '',

      // 080수신거부
      tenantId: '',
      string_080: '',
      isContentEditable: true,

      // 불법스팸 막기
      isBusinessVerified: '',
    };
  },
  computed: {
    minDatetime() {
      // Get the current datetime in Seoul time and round up to the nearest 10 minute interval
      let now = new Date();
      now.setSeconds(0);

      // Set the time zone offset to the offset for Seoul time (UTC+9)
      let timezoneOffset = 9 * 60; // in minutes
      now.setMinutes(now.getMinutes() + timezoneOffset);

      // Round up to the nearest 10 minute interval
      let roundedMinutes = Math.ceil(now.getMinutes() / 10) * 10;
      now.setMinutes(roundedMinutes);

      let datetimeString = now.toISOString().slice(0, -8);

      return datetimeString;
    }
  },
  mounted() {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      const idToken = user.signInUserSession.idToken;
      const tenantId = idToken.payload['cognito:groups'][0];
      const isBusinessVerified = user.attributes['custom:isBusinessVerified']
      this.tenantId = tenantId;
      this.isBusinessVerified = isBusinessVerified;
      const phoneNumber = idToken.payload['phone_number'];
      const newPhoneNumber = '0' + phoneNumber.substring(3);
      this.defaultPhoneNumber = newPhoneNumber;
      this.optionFromPhoneNumbers = ['0264002613'];
      this.formData.from_phone_num = '';
      // const payload = {
      //   status: "active",
      // };

      // ApiCall.call('phone/from/numbers', 'POST', payload)
      //   .then(response => {
      //     if (Array.isArray(response.data) && response.data.length > 0) {
      //       const phoneNums = response.data.map(item => item.from_phone_number);
      //       this.optionFromPhoneNumbers = phoneNums;
      //       console.log(this.optionFromPhoneNumbers);
      //     } else {
      //       console.error('Error: Invalid response data format');
      //     }
      //   })
      //   .catch(error => {
      //     console.error('Error: Unable to fetch data from API', error);
      //   });


      const getGroupPayload = {
        status: "active",
      };

      ApiCall.call('group/list', 'POST', getGroupPayload)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.optionCustomerGroupIds = response.data;
            // console.log(this.optionCustomerGroupIds);
          } else {
            console.error('Error: Invalid response data format');
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });  

      })();

      this.checkedPhones[0].checked = true;
      this.checkedPhones.push({ checked: false, phoneNumber: "" });
      this.isLoading = false;
  },
  methods: {
    calculateByteLength(content) {
      const iconv = require('iconv-lite');
      const bytes = iconv.encode(content, 'euc-kr');
      return bytes.length;
    },
    resetData(){
      this.isLoading = false;
      // this.formData.from_phone_num= '';
      this.formData.send_request_dtm= '';
      // this.formData.to_phone_number= [''];
      // this.formData.customer_group_id= '';
      this.formData.content= '';
      // this.formData.customer_group_alias= '';
      // this.excel_file_name = '',
      // this.formData.excelfile= '';
      // this.formData.primary_customer_reference_id= '';
      // this.formData.secondary_customer_reference_id= '';
      // this.formData.send_status= '';
      // this.formData.alert_phone_number= '';
      this.typed_content= '';
      // this.to_phone_num_index= '';
      // this.headerFields= [''];
      // this.params= [''];
      // this.selectedPhoneNumberField= '전화번호 선택';
      // this.selectedParamField1= '변수1';
      // this.selectedParamField2= '변수2';
      // this.selectedParamField3= '변수3';
      // this.selectedSendMethod= 'manual';
      // this.selectedBlacklistMethod= 'none';
      // this.selectedAlertMethod= 'alert_n';
      // this.contact_list = [], 
      // this.tableData = [],
      // this.checkedPhones = [{ checked: false, phoneNumber: '' }];
      // this.checkedPhones[0].checked = true;
      // this.checkedPhones.push({ checked: false, phoneNumber: '' });
      // this.contact_list_count = 0;
      // this.isAlert = false;
      // this.addressSelected = false;
      // this.fileSelected = false;
      // this.phoneSelected = false;
      // this.param1Selected = false;
      // this.param2Selected = false;
      // this.param3Selected = false;
      // this.param4Selected = false;
      // this.param5Selected = false;
      // this.param6Selected = false;
    },
    resetDataPartial(){
      this.formData.to_phone_number= [''];
      this.formData.customer_group_id= '';
      this.formData.content= '';
      this.formData.customer_group_alias= '';
      this.excel_file_name = '',
      this.formData.excelfile= '';
      this.formData.send_status= '';
      this.typed_content= '';
      this.to_phone_num_index= '';
      this.headerFields= [''];
      this.params= [''];
      this.selectedPhoneNumberField= '전화번호 선택';
      this.selectedParamField1= '변수1';
      this.selectedParamField2= '변수2';
      this.selectedParamField3= '변수3';
      this.selectedSendMethod= 'manual';
      this.contact_list = [], 
      this.tableData = [],
      this.checkedPhones = [{ checked: false, phoneNumber: '' }];
      this.checkedPhones[0].checked = true;
      this.checkedPhones.push({ checked: false, phoneNumber: '' });
      this.contact_list_count = 0;
      this.addressSelected = false;
      this.fileSelected = false;
      this.phoneSelected = false;
      this.param1Selected = false;
      this.param2Selected = false;
      this.param3Selected = false;
      this.param4Selected = false;
      this.param5Selected = false;
      this.param6Selected = false;
    },
    getCurrentDateTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = '00';


      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    },
    selectFromPhone(option) {
      this.formData.from_phone_num = option;
      alert("테스트 발신번호를 선택하셨습니다. 현재 가입하신 전화번호로만 전송이 가능합니다. 테스트 완료 후 메시지관리>발신번호관리 화면에서 발신번호를 등록하고 사용해 보세요.")
    },
    selectCustomerGroupId(option) {
      this.isLoading = true;
      this.formData.customer_group_id = option;

      const getcontactlistPayload = {
        customer_group_id: this.formData.customer_group_id,
        limit: "100",
      };

      ApiCall.call('group/contact/list', 'POST', getcontactlistPayload).then(res => {
        this.contact_list_count = res.data.record_count;
        const contact_list = res.data.data.map(item => {
          return {
            primary: item.phone_number.substring(0, item.phone_number.length - 4) + '****',
            values: [ 
              item.param_list,            
            ],
            secondary: '',
            secondary_color: '',
            info: '',
            image: '',
            image_color: '',
            icon: '',
          };
        });
        this.tableData = res.data.data;
        // this.contact_list = contact_list;
        this.contact_list = contact_list.map(item => ({
          ...item,
          values: item.values.map(value => String(value).replace(/"/g, '')),
        }));
        this.isLoading = false;
      });
    },
    downloadExcelSample(){
      const filePath = '/엑셀샘플.xlsx';
      window.location.href = filePath;
    },
    handleFileUpload(event) {
          const file = event.target.files[0]
          console.log('file:', file)


          if (file) {
              const reader = new FileReader()
              console.log('reader:', reader)
              reader.onload = () => {
                  const fileData = reader.result
                  const workbook = XLSX.read(fileData, { type: 'binary' })
                  const sheetName = workbook.SheetNames[0]
                  const sheet = workbook.Sheets[sheetName]
                  const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 })
                  this.headerFields = rows[0]
                  this.formData.excelfile = file.name
                  this.formData.customer_group_alias = file.name.split('.').slice(0, -1).join('.');
                  this.excel_file = file;
              };
              reader.readAsBinaryString(file)
              
              this.selectedPhoneNumberField = ''
              this.to_phone_num_index = ''
              this.selectedParamField1 = ''
              this.selectedParamField2 = ''
              this.selectedParamField3 = ''
              this.params.length = 0;
              this.fileSelected = true;
              this.phoneSelected = false;
              this.param1Selected = false;
              this.param2Selected = false;
              this.param3Selected = false;
              this.param4Selected = false;
              this.param5Selected = false;
              this.param6Selected = false;
          }
      },
      handlePhoneSelection(option) {
          this.selectedPhoneNumberField = option
          this.to_phone_num_index = this.headerFields.indexOf(option)
          this.phoneSelected = true;
      },
      handleParmSelection1(option) {
          this.typed_content = this.typed_content.replace("{"+this.selectedParamField1+"}", "");
          this.selectedParamField1 = option
          this.params[0] = this.headerFields.indexOf(option)
          this.typed_content += "{"+this.selectedParamField1+"}"
          this.param1Selected = true;
      },
      handleParmSelection2(option) {
          this.typed_content = this.typed_content.replace("{"+this.selectedParamField2+"}", "");
          this.selectedParamField2 = option
          this.params[1] = this.headerFields.indexOf(option)
          this.typed_content += "{"+this.selectedParamField2+"}"
          this.param2Selected = true;
          
      },
      handleParmSelection3(option) {
        this.typed_content = this.typed_content.replace("{"+this.selectedParamField3+"}", "");
          this.selectedParamField3 = option
          this.params[2] = this.headerFields.indexOf(option)
          this.typed_content += "{"+this.selectedParamField3+"}"
          this.param3Selected = true;
          
      },
    onChangeSendMethod(option) {      
      this.resetDataPartial();
      this.selectedSendMethod = option;
    },
    onChangeAlertMethod(option) {      
      this.selectedAlertMethod = option;  
      if(option == 'alert_n'){
        this.formData.alert_phone_number = '';
        this.isAlert = false;
      }else{
        this.isAlert = true;
      }    
    },
    onChangeBlacklistMethod(option) {      
      this.selectedBlacklistMethod = option;
      if(option == 'none'){
        this.typed_content = this.typed_content.replace("\n\n수신거부: 080012345678\n인증번호: 12345", "");
        this.typed_content = this.typed_content.replace("\n\n수신거부: to-go.io/cCNdHKp309f", "");
      }else if (option == '080'){
        this.typed_content = this.typed_content.replace("\n\n수신거부: 080012345678\n인증번호: 12345", "");
        this.typed_content = this.typed_content.replace("\n\n수신거부: to-go.io/cCNdHKp309f", "");
        this.typed_content += "\n\n수신거부: 080012345678\n인증번호: 12345";
      }else if (option == 'url'){
        this.typed_content = this.typed_content.replace("\n\n수신거부: 080012345678\n인증번호: 12345", "");
        this.typed_content = this.typed_content.replace("\n\n수신거부: to-go.io/cCNdHKp309f", "");
        this.typed_content += "\n\n수신거부: to-go.io/cCNdHKp309f";
      }
      
    },
    toggleCheckbox(event, idx) {
      if (this.checkedPhones[idx].checked){
        this.checkedPhones[idx].checked = false;
        if(this.checkedPhones[idx].phoneNumber == '')
          this.checkedPhones.splice(idx, 1);
      } else {
        this.checkedPhones[idx].checked = true;
        if (idx == this.checkedPhones.length-1)
          this.checkedPhones.push({ checked: false, phoneNumber: "" });
      }
      this.$nextTick(() => {
          const table = document.getElementById("to_phone_table");
          table.scrollTo(0, table.scrollHeight);
          });
    },
    async submitForm(){
      if(this.formData.from_phone_num.length <= 0){
        alert("발신번호를 선택해주세요.");
        return;
      }
      if(this.typed_content.length <= 0){
        alert("전송 메시지를 입력해주세요.");
        return;
      }
      if(this.selectedSendMethod == 'manual'){
        // if (!this.checkedPhones.every(phone => {
        //   return !phone.checked || (phone.phoneNumber !== '' && phone.phoneNumber !== null);
        // })) {
        //   alert('체크한 수신번호를 모두 입력해주세요.');
        //   return;
        // }
        // if (!this.checkedPhones.every(phone => {
        //   if (phone.checked && phone.phoneNumber !== '') {
        //     if (!phone.phoneNumber.startsWith('01')){
        //       return false;
        //     }
        //     if (phone.phoneNumber.length != 10 && phone.phoneNumber.length != 11){
        //       return false;
        //     }

        //     return !isNaN(parseInt(phone.phoneNumber));
        //   }
        //   return true;
        // })) {
        //   alert('유효한 전화번호를 입력해주세요.');
        //   return;
        // }
      }
      if(this.selectedSendMethod == 'excel'){
        if (!this.excel_file || this.excel_file.length <= 0) {
          alert("전화번호가 있는 엑셀을 선택해주세요.");
          return;
        }
        if(this.to_phone_num_index.length <= 0){
          alert("전화번호가 있는 컬럼을 선택해주세요.");
          return;
        }        
      }
      if(this.selectedSendMethod == 'address'){
        if (this.formData.customer_group_id.length <= 0) {
          alert("주소록을 선택해주세요.");
          return;
        }      
      }
      if(this.isAlert){
        if (this.formData.alert_phone_number.length <= 0) {
          alert("알림수신번호를 입력해주세요.");
          return;
        }   
        if (!this.formData.alert_phone_number.startsWith('01')){
          alert('유효한 알림수신번호를 입력해주세요.');
          return false;
        }
        if (this.formData.alert_phone_number.length != 10 && this.formData.alert_phone_number.length != 11){
          alert('유효한 알림수신번호를 입력해주세요.');
          return false;
        }
      }
      // chatgpt 오발송
      if(this.isValidateMessageContent){
        const isValid = await this.validateMessage();
        if (!isValid) {
          return false;
        }
      }


      if(this.formData.send_request_dtm){
        const currentTime = new Date();
        const requestTime = new Date(this.formData.send_request_dtm);
        const thirtyMinutesFromNow = new Date(currentTime.getTime() + (21 * 60000));
        const minutes = requestTime.getMinutes();
        if (minutes % 1 !== 0) {
          alert("예약전송은 1분 단위로 전송 가능합니다.");
          return;
        }
        if (requestTime < thirtyMinutesFromNow) {
          alert("예약전송은 최소 30분 이후부터 가능합니다.");
          return;
        }        
        this.submitFormData();
      } else {
        this.showConfirmation();
      }
    },
    async validateMessage(){
      this.isLoading = true;
      const payload = {
          message: this.typed_content
      }
      console.log(payload);

      try{
        const response = await ApiCall.call('message/validate', 'POST', payload);
        
        const responseData = JSON.parse(response.data);
        console.log(responseData);
        let confirmationMessage = "";
        this.isLoading = false;
        if(responseData.grammer_error > 5){
          confirmationMessage = "문법 문제 가능성이 감지되었습니다. 계속 전송 하시겠습니까?\n" + responseData.grammer_error_reason;
        } else if (responseData.content_error > 0){
          confirmationMessage = "내용 문제 가능성이 감지되었습니다. 계속 전송 하시겠습니까?\n" + responseData.content_error_reason;
        } else if (responseData.timeline_error > 0){
          confirmationMessage = "날짜 문제 가능성이 감지되었습니다. 계속 전송 하시겠습니까?\n" +  responseData.timeline_error_reason;
        } else if (responseData.test_error > 0){
          confirmationMessage = "테스트 문자 가능성이 감지되었습니다. 계속 전송 하시겠습니까?\n" +  responseData.test_error_reason;
        } else if (responseData.grammer_error > 0){
          confirmationMessage = "문법 문제 가능성이 감지되었습니다. 계속 전송 하시겠습니까?\n" + responseData.grammer_error_reason;
        }
        if(confirmationMessage == "")
          return true;


        if (window.confirm(confirmationMessage)) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        console.log("bypass");
        // Handle any error that occurred during the API call
        return true; // or throw an error if you want to handle it differently
      }
    },
    showConfirmation() {
      this.submitFormData();
    },
    async submitFormData() {
      this.isLoading = true;

      this.formData.to_phone_number = [this.defaultPhoneNumber];
      this.formData.content = this.typed_content;
      this.formData.content = this.formData.content.replace(new RegExp("{"+this.selectedParamField1+"}", "g"), "{0}");
      this.formData.content = this.formData.content.replace(new RegExp("{"+this.selectedParamField2+"}", "g"), "{1}");
      this.formData.content = this.formData.content.replace(new RegExp("{"+this.selectedParamField3+"}", "g"), "{2}");
      this.formData.content = this.formData.content.replace("\n\n수신거부: 080012345678\n인증번호: 12345", "");
      this.formData.content = this.formData.content.replace("\n\n수신거부: to-go.io/cCNdHKp309f", "");
      this.formData.content = this.formData.content.replace("to-go.io/ABc1", "{link}");

      
      // alert(JSON.stringify(this.formData));
      // alert(JSON.stringify(this));
      const sendRequestDtm = '';
      //send SMS
      if (!this.formData.send_request_dtm) {
        this.sendRequestDtm = this.getCurrentDateTime();
        this.formData.send_status = "request"
      } else {
        this.formData.send_status = "schedule-request"
        const date = new Date(this.formData.send_request_dtm);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = '00';

        this.sendRequestDtm = `${year}${month}${day}${hours}${minutes}${seconds}`;
      }

      const toPhoneNumbersWithParams = this.formData.to_phone_number.map((phone) => {
        const result = { phone: phone };

        if (this.params !== null && this.params.length !== 0) {
          result.params = this.params;
        }

        return result;
      });
      const payload = {
          contents: this.formData.content,
          send_request_dtm: this.sendRequestDtm,
          from_phone_number: this.formData.from_phone_num,
          from_country_code: "82",
          // to_phone_numbers: undefined, // initialize with undefined 
          // customer_group_id: undefined, // initialize with undefined          
          to_country_code: "82",
          next_method_on_fail: "",
          secondary_customer_reference_id: this.formData.secondary_customer_reference_id,
          primary_customer_reference_id: this.formData.primary_customer_reference_id,
          send_status: this.formData.send_status,
          blacklist_type: this.selectedBlacklistMethod,
          result_alarm_phone_number: this.formData.alert_phone_number,
          link_url: this.linkUrl,
          link_type: this.linkType,
          link_file_name: this.linkFileName,
          send_type: "sms",
          inbound_type: "web"
      };
      if (this.selectedSendMethod == 'manual'){
        try {
          payload.to_phone_numbers = toPhoneNumbersWithParams;
          console.log(payload);
          const response = await ApiCall.call('message', 'POST', payload);
          // const response = await ApiCall.call('안보내기', 'POST', payload);
          console.log(response.data);
          alert("SMS 전송 성공");
        } catch (error) {
          console.error(error);
          alert("SMS 전송 실패");
        }
        this.resetData();
      } else if (this.selectedSendMethod == 'excel') {
        const payloadFile = {
          customer_group_id: this.formData.customer_group_alias,
          phone_number_index: this.to_phone_num_index,
          params: this.params,
          link_url: this.linkUrl,
          link_type: this.linkType,
          link_file_name: this.linkFileName,
        };
        console.log(payloadFile);
        try {
          const fileResponse = await ApiCall.file('upload/excel', 'POST', payloadFile, null, this.excel_file, null, false);
          // const fileResponse = await ApiCall.call('안보내기', 'POST', payloadFile, null, this.excel_file);
          if (fileResponse.data.customer_group_id) {
            payload.customer_group_id = fileResponse.data.customer_group_id;
            console.log(payload);
            const response = await ApiCall.call('message/group/sms', 'POST', payload);
            // const response = await ApiCall.call('안보내기', 'POST', payload);
            console.log(response.data);
            alert("SMS 전송 성공");
          }
        } catch (error) {
          console.error(error);
          alert("SMS 전송 실패");
        }
        this.resetData();
      } else if (this.selectedSendMethod == 'address') {
        payload.customer_group_id = this.formData.customer_group_id;
        console.log(payload);
        try {
          const response = await ApiCall.call('message/group/sms', 'POST', payload);
          // const response = await ApiCall.call('안보내기', 'POST', payload);
          console.log(response.data);
          alert("SMS 전송 성공");
        } catch (error) {
          console.error(error);
          alert("SMS 전송 실패");
        }
        this.resetData();
      }
      this.isLoading = false;  
    },
    handleUrlCreated(shourUrl, longUrl, type, fileName) {
      this.typed_content += shourUrl;
      this.linkUrl = longUrl;
      this.linkType = type;
      this.linkFileName = fileName;
    },
  },
};

</script>

<style>
#to_phone_table {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
#excel_table {
  border: 0px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
#address_table {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
.spinner-container {
  z-index: 9999; /* Set a high z-index value */
}
</style>
