<template>
  <div id="profile" class="card card-body mt-4">
    <div class="row align-items-center">
      <div class="col-sm-auto col-4">
        <div class="avatar-container">
          <material-avatar
              :avatar="avatar"
              size="xl"
              shadow="sm"
              circular
          />
          <button class="edit-button" @click="editAvatar">
            <i class="fas fa-pencil-alt"></i>
          </button>
<!--          아바타 변경 Modal-->
          <div id="editAvatarModal" class="modal fade"  tabindex="-1" aria-labelledby="createGroupModalLabel"
               aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 id="createGroupModalLabel" class="modal-title" >아바타 변경</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div v-for="rowIndex in 3" :key="rowIndex" class="row">
                    <div v-for="avatarIndex in 5" :key="avatarIndex" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                      <div class="avatar-item">
                        <material-avatar
                            :avatar="avatarIndex - 1 + ((rowIndex - 1) * 5) + ''"
                            size="xl"
                            shadow="sm"
                            circular
                            @click="handleAvatarClick(avatarIndex - 1 + ((rowIndex - 1) * 5))"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                  >닫기</button>
                </div>
              </div>
            </div>
          </div>
<!--          아바타 변경 Modal-->
        </div>
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <template v-if="userInfo">
            <h5 class="mb-1 font-weight-bolder">{{ userInfo.user_id }}</h5>
            <p class="mb-0 font-weight-bold text-sm">{{ userInfo.tenant_name }}</p>
          </template>
        </div>
      </div>
    </div>
  </div>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>회원 정보</h5>
    </div>
    <template v-if="userInfo">
      <div class="card-body pt-0">
        <div class="row">
          <div class="col-6">
            <material-input-vmodel
                id="user-id"
                variant="static"
                label="아이디"
                :placeholder="userInfo.user_id"
                :disabled=true
            />
          </div>
          <div class="col-6">
            <material-input-vmodel
                id="user-name"
                variant="static"
                label="이름"
                :placeholder="userInfo.user_name"
                :disabled=true
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <material-input-vmodel
                id="email"
                type="email"
                variant="static"
                label="Email"
                :placeholder="userInfo.email"
                :disabled=true
            />
          </div>
          <div class="col-6">
            <material-input-vmodel
                id="phoneNumber"
                type="phone"
                variant="static"
                label="전화번호"
                :placeholder="userInfo.phone_number"
                :disabled=true
            />
          </div>
        </div>
        <div v-if="userInfo.is_admin === '1' && (userInfo.is_business_verified === '1' || userInfo.is_business_verified === '2')" class="row mt-4 ms-2">
          세금계산서는 위 회원정보로 발행됩니다. 변경이 필요하시면 아래 메일로 연락주세요 <br>
          message.to-go@sk.com
        </div>
        <material-button
        class="float-end mt-2 mb-0"
        size="sm"
        @click="deleteUser"
      >
        회원탈퇴
      </material-button>
      </div>
    </template>
  </div>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>카카오톡 프로필 정보</h5>
    </div>
    <div class="card-body pt-0">
      <div v-if="ownKakaoProfiles.length > 0">
        <div v-for="profile in ownKakaoProfiles" :key="profile.sk" :value="profile.sk">
          <div class="row">
            <div class="col-4">
              <material-input-vmodel
                id="kakao_channel_id"
                v-model="profile.sk"
                variant="static"
                label="발신프로필(@ID)"
                :disabled="true"
              >
              </material-input-vmodel>
            </div>
            <div class="col-4">
              <material-input-vmodel
                id="category"
                v-model="profile.category_name"
                variant="static"
                label="카테고리"
                :disabled="true"
              >
              </material-input-vmodel>
            </div>
            <div class="col-4">
              <material-input-vmodel
                id="contact"
                v-model="profile.phone_number"
                variant="static"
                label="프로필 연락처"
                :disabled="true"
              >
              </material-input-vmodel>
            </div>
          </div>
          <div class="row">
            <material-input-vmodel
              id="senderKey"
              v-model="profile.sender_key"
              variant="static"
              label="from_kakao_channel"
              :disabled="true"
            >
            </material-input-vmodel>
          </div>
        </div>
      </div>
      <material-button
        class="float-end mt-2 mb-0"
        size="sm"
        @click="openRegisterKakaoProfile"
      >
        발신프로필 등록(@ID)
      </material-button>
      <div id="registerKakaoProfileModal" class="modal fade"  tabindex="-1" aria-labelledby="createGroupModalLabel"
           aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="createGroupModalLabel" class="modal-title" >발신 프로필 등록</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-4">
                  <select
                    id="profileCategorySelect1"
                    v-model="selectedCategory1"
                    class="form-control"
                  >
                  </select>
                </div>
                <div class="col-4">
                  <select
                    id="profileCategorySelect2"
                    v-model="selectedCategory2"
                    class="form-control"
                  >
                  </select>
                </div>
                <div class="col-4">
                  <select
                    id="profileCategorySelect3"
                    v-model="selectedCategory3"
                    class="form-control"
                  >
                  </select>
                </div>
              </div>
              <div class="row">
                <material-input-vmodel
                  id="kakaoProfile"
                  v-model="kakaoProfileForRegister"
                  label="발신프로필(@ID)"
                />
              </div>
              <div class="row mt-2">
                <div class="col-8">
                  <material-input-vmodel
                    id="kakaoProfilePhoneNumber"
                    v-model="kakaoProfilePhoneNumber"
                    label="프로필연락처"
                  />
                </div>
                <div class="col-4">
                  <material-button
                    :full-width="true"
                    @click="requestRegisterProfileToken"
                  >
                    인증키 요청
                  </material-button>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-8">
                  <material-input-vmodel
                    id="token"
                    v-model="tokenFromBizppurio"
                    label="인증번호"
                    :disabled="!isRequestedRegisterKakaoProfileToken"
                  />
                </div>
                <div class="col-4">
                  <material-button
                    :full-width="true"
                    :disabled="!isRequestedRegisterKakaoProfileToken"
                    @click="registerKakaoProfile"
                  >
                    인증 및 등록
                  </material-button>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
              >닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>API Key 정보</h5>
    </div>
    <div class="card-body pt-0">
      <div v-if="userInfo" class="row">
        <material-input-vmodel
          id="tenant-id"
          v-model="userInfo.tenant_id"
          variant="static"
          label="Tenant ID"
          :disabled=true
        />
      </div>
      <div class="row">
        <div v-if="apiKeyList.length > 0">
          <div v-for="apiKey in apiKeyList" :key="apiKey.sk" :value="apiKey.sk">
            <div class="row">
              <div class="col-12">
                <material-input-vmodel
                  :id="'appKey_' + apiKey.sk"
                  v-model="apiKey.sk"
                  variant="static"
                  label="API Key"
                  :disabled=true
                  :button=true
                  button-icon="copy"
                  :button-color="callBackStatusApiKey"
                />
              </div>
<!--              <div class="col-3">-->
<!--                <material-input-vmodel-->
<!--                  id="expire_date"-->
<!--                  variant="static"-->
<!--                  label="만료일자"-->
<!--                  v-model="apiKey.expired_date"-->
<!--                />-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <material-button
        class="float-end mt-1 mb-0"
        size="sm"
        @click="createApiKey"
      >
        API Key 생성
      </material-button>
    </div>
  </div>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>앱 푸쉬 정보</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <material-input-vmodel
          id="appKey"
          v-model="appKey"
          variant="static"
          label="M2G 앱 키"
          :disabled=true
          :button=true
          button-icon="copy"
          :button-color="callBackStatus"
        />
      </div>
      <div class="row mt-4">
        <material-input-vmodel
          id="project-id"
          v-model="projectId"
          variant="static"
          label="Firebase Project ID"
          :placeholder="getPlaceholderText"
        />
      </div>
      <div class="row mt-4">
        <material-input-vmodel
          id="sender-id"
          v-model="fcmKeyFile"
          :disabled=true
          variant="static"
          label="FCM JSON Key file"
          :placeholder="getKeyFilePlaceholderText"
        />
      </div>
      <material-button
        class="float-end mt-1 mb-0"
        size="sm"
        @click="registerProjectId"
      >
        Project ID 등록
      </material-button>
      <input
        ref="fileInput"
        type="file"
        style="display: none"
        @change="handleFileInputChange($event, index)"
      />
      <material-button
        class="float-end mt-1 mb-0 mx-2"
        size="sm"
        @click="registerJSONKeyFile"
      >
        Key 파일등록(JSON)
      </material-button>
    </div>
  </div>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>비밀번호 변경</h5>
    </div>
    <div class="card-body pt-0">
      <div class="mb-3">
        <material-input-vmodel
          id="currnetPass"
          type="password"
          label="현재 비밀번호"
          @input="updateCurrentPassword($event.target.value)"
        />
      </div>
      <div class="mb-3">
        <material-input-vmodel
            id="newPassword"
            type="password"
            label="변경할 비밀번호"
            @input="updatePassword($event.target.value)"
        />
      </div>
      <div class="mb-3">
        <material-input-vmodel
          id="confirmPass"
          type="password"
          label="비밀번호 확인"
          @input="updateConfirmPassword($event.target.value)"
        />
      </div>
      <h5 class="mt-5">비밀번호 생성 규칙</h5>
      <p class="text-muted mb-2">
        보안을 위하여 아래 비밀번호 규칙에 따라 생성해주세요.
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">8자리 이상 길이</span>
        </li>
        <li>
          <span class="text-sm">최소 1개의 숫자 포함</span>
        </li>
        <li>
          <span class="text-sm">최소 1개의 영문 소문자 포함</span>
        </li>
      </ul>
      <material-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        :disabled="!(isPasswordValid&&isPasswordConfirmValid)"
        @click="changePassword"
        >비밀번호 변경</material-button
      >
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialBadge from "@/components/MaterialBadge.vue";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialInputVmodel from "@/components/MaterialInputVmodel.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import {Auth} from "aws-amplify";
import ApiCall from "@/Interface/ApiCall";
import { useRouter } from 'vue-router';

export default {
  name: "ProfileItem",
  components: {
    MaterialButton,
    MaterialAvatar,
    MaterialInputVmodel,
    // MaterialInput,
  },
  setup() {
    const router = useRouter()
    // async function deleteUser() {
    //   if (window.confirm("회원탈퇴 하시겠습니까?")) {
    //     // if (ApiCall.vdiDomainCheck()) {
    //     //   Auth.configure({
    //     //     endpoint: process.env.VUE_APP_COGNITO_URL_PROXY
    //     //   });
    //     // }
    //     // await Auth.deleteUser()
    //     await Auth.signOut()
    //     console.log('User deleted successfully')
    //     await router.push('/authentication/deleteuser/done')
    //   }
    // }

    // return {
    //   deleteUser,
    // }
  },
  data() {
    return {
      avatar: '',
      userInfo: null,
      currentPassword: '',
      password: '',
      confirmPassword: '',
      isPasswordValid: false,
      isPasswordConfirmValid: false,
      editAvatarModal: null,
      ownKakaoProfiles: [],
      registerKakaoProfileModal: null,
      isRequestedRegisterKakaoProfileToken: false,
      kakaoProfileForRegister: '',
      tokenFromBizppurio: '',
      kakaoProfileCategorys:[],
      kakaoProfilePhoneNumber: '',
      categorySelectInstance1: null,
      categorySelectInstance2: null,
      categorySelectInstance3: null,
      selectedCategory1: '',
      selectedCategory2: '',
      selectedCategory3: '',
      selectedCategoryCode: '',
      appKey: '',
      apiKeyList: [],
      senderId: '',
      projectId: '',
      fcmKeyFile: '',
      callBackStatus: 'grey',
      callBackStatusApiKey: 'grey',
      JSONFile: null,
      isUploadLoading: false,
    };
  },
  computed: {
    getPlaceholderText() {
      // console.log('서버 키 :' + this.serverKey)
      if (this.projectId !== "") {
        return this.projectId;
      } else {
        return 'Firebase의 Project ID를 등록해 주세요.';
      }
    },
    getKeyFilePlaceholderText() {
      // console.log('서버 키 :' + this.serverKey)
      if (this.fcmKeyFile !== "") {
        return this.fcmKeyFile;
      } else {
        return 'Cloud Messaging 서버 키파일을 등록해 주세요.';
      }
    },
    firstDepthCategory() {
      const initialData = { 'label': '선택', 'value': '0' };
      const categoryArray = Object.keys(this.kakaoProfileCategorys).map(key => ({
        'label': key,
        'value': key
      }));
      return [initialData, ...categoryArray];
    },
    secondDepthCategory() {
      const initialData = { 'label': '선택', 'value': '0' };
      const categoryArray = Object.keys(this.kakaoProfileCategorys[this.selectedCategory1]).map(key => ({
        'label': key,
        'value': key
      }));
      return [initialData, ...categoryArray];
    },
    thirdDepthCategory() {
      const initialData = { 'label': '선택', 'value': '0' };
      const categoryArray = Object.keys(this.kakaoProfileCategorys[this.selectedCategory1][this.selectedCategory2]).map(key => ({
        'label': key,
        'value': this.kakaoProfileCategorys[this.selectedCategory1][this.selectedCategory2][key]['code'],
        // 'value': key,
      }));

      return [initialData, ...categoryArray];
    },
  },
  watch: {
    selectedCategory1(newList) {
      if (newList !== '0') {
        this.initChoicesList2();
      }
    },
    selectedCategory2(newList) {
      if (newList !== '0') {
        this.initChoicesList3();
      }
    },
    selectedCategory3(newList) {
      console.log("newList :" + newList)
      if (newList !== '0') {
        console.log("selectedCategory1 :" + this.selectedCategory1)
        console.log("selectedCategory2 :" + this.selectedCategory2)
        console.log("selectedCategory3 :" + this.selectedCategory3)

        for (const key in this.kakaoProfileCategorys[this.selectedCategory1][this.selectedCategory2]) {
          console.log(key + ":" + this.kakaoProfileCategorys[this.selectedCategory1][this.selectedCategory2][key]['code'])
        }
        console.log("category name: "+ this.selectedCategory1 + this.selectedCategory2 + this.findKeyByValue(this.kakaoProfileCategorys[this.selectedCategory1][this.selectedCategory2], this.selectedCategory3))
        this.selectedCategoryCode = this.selectedCategory3
      }
    },
  },
  async mounted() {
    await this.getUserInfo();
    // console.log('userInfo(ProfileItem) :', this.userInfo)
    this.avatar = this.userInfo.avatar;

    this.getKakaoProfiles();
    this.getAppPushInfo();
    this.getApiKeyInfo();
  },
  created() {
    if (ApiCall.vdiDomainCheck()) {
      Auth.configure({
        endpoint: process.env.VUE_APP_COGNITO_URL_PROXY
      });
    }
  },
  methods: {
    async getUserInfo() {
      const user = await Auth.currentAuthenticatedUser()

      const idToken = user.signInUserSession.idToken
      const tenantId = idToken.payload['cognito:groups'][0]
      // console.log(tenantId)
      this.userName = user.attributes.name // Set the user ID as the dynamicMessage

      const user_id = user.username;
      const user_name = user.attributes.name;
      const email = user.attributes.email;
      const phone_number = user.attributes.phone_number;
      const tenant_name = user.attributes['custom:tenant_name'];
      const is_admin = user.attributes['custom:isAdmin'];
      const is_business_verified = user.attributes['custom:isBusinessVerified']
      // additional user information loading from dynamoDB
      const payload = {
        user_id: user_id,
      };
      let dynamoUserInfo = null;
      await ApiCall.call('user', 'POST', payload).then((res) => {
        dynamoUserInfo = res.data[0];
      })
      const avatar = dynamoUserInfo['avatar'];

      // console.log('dynamoUserInfo : ', dynamoUserInfo)

      this.userInfo =  {
          user_id: user_id,
          user_name: user_name,
          email: email,
          phone_number: phone_number,
          tenant_id: tenantId,
          tenant_name: tenant_name,
          is_admin: is_admin,
          avatar: avatar,
          is_business_verified: is_business_verified,
        }

    },
    updateCurrentPassword(value) {
      this.currentPassword = value;
      this.validatePassword();
    },
    updatePassword(value) {
      this.password = value;
      this.validatePassword();
    },
    updateConfirmPassword(value) {
      this.confirmPassword = value;
      this.validatePassword();
    },
    async deleteUser() {
      if (window.confirm("회원탈퇴 하시겠습니까?")) {
        if (ApiCall.vdiDomainCheck()) {
          Auth.configure({
            endpoint: process.env.VUE_APP_COGNITO_URL_PROXY
          });
        }
        const success = await this.deleteUserLambda();
        // console.log("success=",success);
        // if (!success) {
        //     return;  // deleteUserLambda에서 false 반환시 더 이상 진행하지 않음
        // }
        // await Auth.deleteUser()
        await Auth.signOut();
        await this.$router.push('/authentication/deleteuser/done');
      }
    },
    async deleteUserLambda(){

      const data = {
      };

      ApiCall.call('user/deleteuser', 'POST', data).then((res) =>{
        console.log("res=",res)
        if (res.status === 200) {
            // 요청이 성공적으로 처리됐을 때
            alert("사용자 삭제가 성공적으로 완료되었습니다.");
            Auth.deleteUser();
            // Auth.signOut();
        } else {
            // 서버가 200 이외의 상태 코드를 반환했을 때 (예: 400, 500 등)
            alert("사용자 삭제 실패: " + res.data.message);
            // Auth.signOut();
        }
      });
    },
    validatePassword() {
      const minLength = 8;
      const containsNumber = /\d/;
      // const containsSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

      const isValidLength = this.password.length >= minLength;
      const hasNumber = containsNumber.test(this.password);
      // const hasSpecialChar = containsSpecialChar.test(this.password);
      this.isPasswordConfirmValid = this.password === this.confirmPassword;
      this.isPasswordValid = isValidLength && hasNumber;
    },
    changePassword() {
      Auth.currentAuthenticatedUser().then((user) => {
        return Auth.changePassword(user, this.currentPassword, this.password);
      })
          .then((data) => {
            // console.log(data)
            this.password = '';
            this.currentPassword = '';
            this.confirmPassword = '';
            alert("비밀번호 변경 완료");
          })
          // .catch((err) => console.log(err));
          .catch((err) => {
            alert("비밀번호 변경 실패")
            console.log(err)
          });
    },
    editAvatar() {
      // console.log('click edit avatar')
      const bootstrap = this.$store.state.bootstrap;
      // 모달 열기
      this.editAvatarModal = new bootstrap.Modal(
          document.getElementById("editAvatarModal")
      );
      this.editAvatarModal.show();
    },
    handleAvatarClick(avatarIndex) {
      // console.log('Clicked Avatar Index:', avatarIndex);
      this.userInfo.avatar = avatarIndex + '';
      this.avatar = this.userInfo.avatar;

      const data = {
        user_id: this.userInfo.user_id,
        avatar: this.userInfo.avatar,
      }
      ApiCall.call('user/avatar/update', 'POST', data).then((res) =>{
        // console.log(res)
        this.editAvatarModal.hide();
      });
    },
    getAppPushInfo() {
      ApiCall.call('user/apppush', 'POST').then((res) => {
        // console.log(res)

        if (res.data.length > 0) {
          this.appKey = res.data[0]['sk']
          this.senderId = res.data[0]['sender_id']
          this.projectId = res.data[0]['project_id']
          this.fcmKeyFile = res.data[0]['key_file']
        }
      })
    },
    getApiKeyInfo() {
      ApiCall.call('user/api/key', 'GET').then((res) => {
        this.apiKeyList = []
        this.apiKeyList = res.data['list']
      })
    },
    registerServerKey() {
      const data = {
        app_key: this.appKey,
        sender_id: this.senderId,
      }

      console.log('data :', data)

      ApiCall.call('user/apppush/senderid', 'POST', data).then((res) => {
        console.log(res.data)
        console.log('Register Server key response :', res.data.message)
        if (res.data.status === 200) {
          alert(res.data.message)
        } else {
          alert(res.data.message)
        }
      });
    },
    registerProjectId() {
      const data = {
        app_key: this.appKey,
        project_id: this.projectId,
      }

      console.log('data :', data)

      ApiCall.call('user/apppush/projectid', 'POST', data).then((res) => {
        console.log(res.data)
        console.log('Register Project ID response :', res.data.message)
        if (res.data.status === 200) {
          alert(res.data.message)
        } else {
          alert(res.data.message)
        }
      });
    },
    registerJSONKeyFile() {
      this.$refs.fileInput.click();
    },
    async handleFileInputChange(event, index) {
      this.isUploadLoading = true;
      console.log("file input changed");
      if (event.target.files.length > 0) {
        try {
          this.JSONFile = event.target.files[0]; // Use 'this' to access the data property

          const payload = {
            app_key: this.appKey,
          }

          const keyFileResponse = await ApiCall.call('user/apppush/keyfile', 'POST', payload, null, this.JSONFile);
          if (keyFileResponse.status === 200) {
            console.log('전송성공');
          } else {
            console.log('전송실패');
          }

          this.isUploadLoading = false;
        } catch (error) {
          console.log('전송실패 :' + error);
          alert("업로드 실패: " + error);
          this.isUploadLoading = false;
          return;
        }
      }
    },
    createApiKey() {
      ApiCall.call('user/api/key', 'POST').then((res) => {
        console.log(res.data)
        console.log('Register Server key response :', res.data.message)
        if (res.data.status === 200) {
          alert(res.data.message)
          this.apiKey = res.data.api_key;
        } else {
          alert(res.data.message)
        }
      });
    },
    getKakaoProfiles() {
      ApiCall.call('user/kakaoprofile/profileinfo', 'GET').then(response =>{
        this.ownKakaoProfiles = response.data.list;
      });
    },
    openRegisterKakaoProfile() {
      // console.log('click edit avatar')
      const bootstrap = this.$store.state.bootstrap;
      // 모달 열기
      this.registerKakaoProfileModal = new bootstrap.Modal(
        document.getElementById("registerKakaoProfileModal")
      );
      // 카테고리 목록 조회 API 콜 후에 initChoices
      ApiCall.call('user/kakaoprofile/category', 'POST').then(response =>{
        console.log(response.data);

        const structuredData = {};

        response.data.forEach(item => {
          const categories = item.name.split(",");
          const code = item.code;
          let currentLevel = structuredData;

          categories.forEach((category, index) => {
            if (!currentLevel[category]) {
              currentLevel[category] = {};
            }
            if (index === categories.length - 1) {
              currentLevel[category]['code'] = code;
            }
            currentLevel = currentLevel[category];
          });
        });

        console.log(structuredData);
        this.kakaoProfileCategorys = structuredData;

        this.initChoicesList1();
        this.registerKakaoProfileModal.show();
      }).catch(error => {
        alert(error)
      })
    },
    requestRegisterProfileToken() {
      if (this.validateRequestToken()) {
        const payload = {
          phoneNumber: this.kakaoProfilePhoneNumber,
          yellowId: this.kakaoProfileForRegister,
        }

        ApiCall.call('user/kakaoprofile/token', 'POST', payload).then((res) =>{
          if (res.status === 200) {
            alert("입력하신 연락처로 인증번호가 발송되었습니다. 인증번호 입력 후 등록을 진행해주세요.")
            this.isRequestedRegisterKakaoProfileToken = true;
          } else {
            alert("프로필 등록 토큰 요청 오류("+res.data.message+")")
          }
        }).catch(error=>{
          alert("프로필 등록 토큰 요청 오류("+error+")")
        });
      }
    },
    registerKakaoProfile() {
      if (this.validateRegist()) {
        const payload = {
          token: this.tokenFromBizppurio,
          phoneNumber: this.kakaoProfilePhoneNumber,
          yellowId: this.kakaoProfileForRegister,
          categoryCode: this.selectedCategoryCode,
        }

        ApiCall.call('user/kakaoprofile/create', 'POST', payload).then(async (res) => {
          if (res.status === 200) {
            const categoryName = this.selectedCategory1 + "," + this.selectedCategory2 + "," + this.findKeyByValue(this.kakaoProfileCategorys[this.selectedCategory1][this.selectedCategory2], this.selectedCategory3)

            // dynamo에 발신프로핑 등록
            const insertInfoPayload = {
              yellowId: this.kakaoProfileForRegister,
              senderKey: res.data.senderKey,
              phoneNumber: this.kakaoProfilePhoneNumber,
              category: categoryName,
              categoryCode: this.selectedCategoryCode,
            }

            await ApiCall.call('user/kakaoprofile/profileinfo', 'POST', insertInfoPayload).then((dynamoRes) => {
              alert(dynamoRes.data.message)
              this.registerKakaoProfileModal.hide();

              //input 리셋
              this.resetKakaoProfileInput();

              //프로필 refresh
              this.getKakaoProfiles();
            }).catch(dynamoError => {
              alert("프로필 등록 오류 (저장 실패:" + dynamoError + ")");
            });
          } else {
            alert("프로필 등록 실패 :" + res.data);
          }
        }).catch(error =>{
          alert("프로필 등록 오류("+error+")")
        });
      }
    },
    initChoicesList1() {
      if (this.categorySelectInstance1) {
        this.categorySelectInstance1.destroy();
      }
      this.categorySelectInstance1 = new Choices('#profileCategorySelect1', {
        shouldSort: false,
        choices: this.firstDepthCategory,
      });
    },
    initChoicesList2() {
      if (this.categorySelectInstance2) {
        this.categorySelectInstance2.destroy();
      }
      this.categorySelectInstance2 = new Choices('#profileCategorySelect2', {
        shouldSort: false,
        choices: this.secondDepthCategory,
      });
    },
    initChoicesList3() {
      if (this.categorySelectInstance3) {
        this.categorySelectInstance3.destroy();
      }
      this.categorySelectInstance3 = new Choices('#profileCategorySelect3', {
        shouldSort: false,
        choices: this.thirdDepthCategory,
      });
    },
    validateRequestToken() {
      if(this.kakaoProfilePhoneNumber.length <= 0){
        alert("카카오 프로필에 등록된 번호를 입력해주세요.");
        return false;
      } else if (this.kakaoProfileForRegister.length <=0){
        alert("발신프로필(@ID)를 입력해주세요.");
        return false;
      } else {
        return true;
      }
    },
    validateRegist() {
      if(this.kakaoProfilePhoneNumber.length <= 0){
        alert("카카오 프로필에 등록된 번호를 입력해주세요.");
        return false;
      } else if (this.kakaoProfileForRegister.length <=0){
        alert("발신프로필(@ID)를 입력해주세요.");
        return false;
      } else if (this.selectedCategoryCode.length <= 0){
        alert("발신프로필의 카테고리를 선택해주세요.");
        return false;
      } else {
          return true;
      }
    },
    resetKakaoProfileInput() {
      this.kakaoProfileCategorys = [];
      this.kakaoProfilePhoneNumber = '';
      this.selectedCategory1 = '';
      this.selectedCategory2 ='';
      this.selectedCategory3 ='';
      this.selectedCategoryCode = '';
      this.isRequestedRegisterKakaoProfileToken = false;
      this.tokenFromBizppurio = '';
    },
    findKeyByValue(object, value) {
      for (const key in object) { if (Object.prototype.hasOwnProperty.call(object, key) && object[key]['code'] === value) {
          return key;
        }
      }
      // If the value is not found, you can return null or any other suitable value.
      return null;
    },
  }
};
</script>

<style>
.avatar-container {
  position: relative;
}

.edit-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
}

.edit-button i {
  color: grey;
  font-size: 14px;
}
</style>
