<template>
  <div v-if="isPropertyLoading" class="card" data-animation="true">
    <a :href="url">
      <div v-if="metaProperties['og:image']" class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
        <a class="d-block blur-shadow-image">
          <img
            :src="metaProperties['og:image']"
            alt="img-blur-shadow"
            class="img-fluid shadow border-radius-lg"
          />
        </a>
        <div
          class="colored-shadow"
          :style="`background-image: url(${img})`"
        ></div>
      </div>
      <div class="card-body text-center">
        <h5 v-if="metaProperties['og:title']" class="font-weight-normal mt-3">
          {{ metaProperties['og:title'] }}
        </h5>
        <p v-if="metaProperties['og:description']" class="mb-0">
          {{ metaProperties['og:description'] }}
        </p>
      </div>
    </a>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import ApiCall from "@/Interface/ApiCall";
import he from 'he';

export default {
  name: "BookingCard",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      metaProperties: null,
      isPropertyLoading: false,
    }
  },
  beforeMount() {
    this.getPropertyFromUrl()
  },
  methods: {
    async getPropertyFromUrl() {
      console.log("get document properties")
      const payload = {
        url: this.url,
      };

    ApiCall.callNoAuth('blog/document/properties', 'POST', payload).then(response=>{
      // Handle successful response
      if (response) {
        const decodedProperties = {};

        for (const key in response) {
          console.log(key + ":" + response[key])
          if (key === 'og:image') {
            decodedProperties[key] = decodeURIComponent(response[key]);
          } else {
            decodedProperties[key] = response[key];
          }
        };

        this.metaProperties = decodedProperties;

        this.isPropertyLoading = true;
      } else {
        console.error('No data found in the response.');
      }
    }).catch(error =>{
      console.error('Error fetching meta properties:', error);
    })
    },
  }
};
</script>
