<template>
  <div class="container-fluid mt-4">
    <div class="row align-items-center">
      <div class="col-lg-4 col-sm-8">
        <KakaoNavPill @selectForm="selectedForm = $event" />
      </div>
    </div>
    <div class="row mb-5">
      <KakaoNoticeForm v-if="selectedForm === 'kakao_notice'" />
      <KakaoFriendForm v-if="selectedForm === 'kakao_friend'" />
    </div>
  </div>
</template>

<script>
import KakaoNavPill from "./components/KakaoNavPill.vue";
import KakaoNoticeForm from "./components/KakaoNoticeForm.vue";
import KakaoFriendForm from "@/views/message/components/KakaoFriendForm.vue";

export default {
  name: "Settings",
  components: {
    KakaoNavPill,
    KakaoNoticeForm,
    KakaoFriendForm,
  },
  data() {
    return {
      selectedForm: "kakao_notice", // default selected form
    };
  },
};
</script>
