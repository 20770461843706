<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <custom-table
          title="Table test"
          sub-title="Table test sub title"
          :table-data="tableData"
          :table-columns="tableColumns"
      ></custom-table>
    </div>
    <div class="mt-4 row">
      <material-button @click="changeData">
        change
      </material-button>
    </div>
  </div>
</template>

<script>
import CustomTable from "@/components/CustomTable.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import ApiCall from "@/Interface/ApiCall";
import { Auth } from 'aws-amplify'

export default {
  name: "DataTables",
  components: {
    MaterialButton,
    CustomTable,
  },
  setup() {
  },
  data() {
    return {
      tableColumns: [
        { data: 'name', title: 'Name' },
        { data: 'position', title: 'Position' },
        { data: 'office', title: 'Office' },
        { data: 'age', title: 'Age' },
        { data: 'startDate', title: 'StartDate' },
        { data: 'salary', title: 'Salary' },
      ],
      tableData: [
        {
          name: "Tiger Nixon",
          position: "System Architect",
          office: "Edinburgh",
          age: 61,
          startDate: "2011/04/25",
          salary: "$320,800",
        },
        {
          name: "Garrett Winters",
          position: "Accountant",
          office: "Tokyo",
          age: 63,
          startDate: "2011/07/25",
          salary: "$170,750",
        },
      ],
    }
  },
  methods: {
    changeData() {
      // this.tableData = [
      //   ["Tiger ", "System ", "Edinburgh", 61, "2011/04/25", "$320,800"],
      //   ["Garrett ", "Accoun", "Tokyo", 63, "2011/07/25", "$170,750",],
      // ]

      const payload = {
        "customer_id" : "1234",
      }
      const queryParam = {
        "customer_id" : '01059674864',
      }

      const response = ApiCall.call('message/history', 'GET', null, queryParam).then(res => {
        console.log(res)
      })


    },
  },
};
</script>
