<template>
  <div class="radio-group">
    <button
      v-for="(option, index) in options"
      :key="index"
      class="custom-button"
      :class="{'selected': selectedOption === option }"
      @click="selectOption(option)"
    >
      {{ option }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'CustomSelectKeyword',
  props: {
    options: {
      type: Array,
      required: true
    }
  },
emits: ['selected'],
  data() {
    return {
      selectedOption: this.options.length > 0 ? this.options[0] : null
    };
  },
  methods: {
    selectOption(option) {
      console.log('contents select changed : ' + JSON.stringify(option));
      this.selectedOption = option;
      this.$emit('selected', option);
    }
  }
};
</script>

<style>
.radio-group {
  display: flex;
  flex-direction: row;
}

button.custom-button {
  margin-right: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 5px 20px;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: none;
  font-size: .75rem;
}

button.custom-button.selected {
  background-color: dimgray;
  color: #fff;
}
</style>
