<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="row mt-3">
        <material-input
          id="example"
          v-model="example"
          label="test"
        />
        </div>
        <div class="row mt-3">
        <material-input
            id="example2"
            v-model="example2"
            label="test"
        />
        </div>
        <div class="row mt-3">
          <div class="button-row d-flex mt-4">
            <material-button
                @click="call"
            >Call</material-button>
          </div>
        </div>
        <div class="row mt-3">
          <material-input-custom
              id="example3"
              v-model="example3"
              label="test"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialInputCustom from "@/components/MaterialInputCustom.vue";
import MaterialButton from "@/components/MaterialButton.vue";
export default {
  name: "Empty",
  components: {
    MaterialButton,
    MaterialInput,
    MaterialInputCustom,
  },
  data() {
    return {
      example: '',
      example2: '',
      example3: '',
    };
  },
  methods: {
    call() {
      console.log('called')
      console.log('example : ', this.example)
      console.log('example2 : ', this.example2)
      console.log('example3 : ', this.example3)
    },
  }
};
</script>
