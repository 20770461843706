<template>
  <div class="row">
    <div v-if="hasSubTenant" class="col-lg-2 col-sm-6">
      <div class="col-6">
        <material-button id="tenant_list" color="success" size="sm" variant="outline" class="dropdown-toggle"
          type="button" data-bs-toggle="dropdown" aria-expanded="false" style="width: 180px; margin-left: 20px"
          :disabled="!calendar_data_loaded">
          {{ selectedTenant.tenant_name || "전체" }}
        </material-button>
        <ul class="dropdown-menu" aria-labelledby="tenant_list">
          <li v-for="(item, index) in subTenantListForSearch" :key="index">
            <a class="dropdown-item" href="javascript:;" @click="selectTenant(item, index)">
              {{ item.tenant_name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="hasZeroTenant" class="col-lg-2 col-sm-6">
      <div class="col-6">
        <material-button id="tenant_list_2" color="success" size="sm" variant="outline" class="dropdown-toggle"
          type="button" data-bs-toggle="dropdown" aria-expanded="false" style="width: 180px; margin-left: 5px"
          :disabled="!calendar_data_loaded">
          {{ selectedTenantReference.reference_id || "발송유형" }}
        </material-button>
        <ul class="dropdown-menu" aria-labelledby="tenant_list_2">
          <li v-for="(item, index) in primary_customer_reference_id_list" :key="index">
            <!-- <a class="dropdown-item" href="javascript:;" @click="selectTenant(item, index)"> -->
            <a class="dropdown-item" href="javascript:;" @click="selectReferenceID(item, index)">
              {{ item.reference_id }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div v-if="!calendar_data_loaded"
    class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
    <div class="spinner-container">
      <!--      <i class="fas fa-spinner fa-spin spinner-icon text-info"></i>-->
      <img src="@/assets/img/Spinner-1s-200px.gif" style="width: 100px; height: 100px;" />
    </div>

  </div>
  <div v-if="tenant_data_loaded" class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-4 col-sm-6 mt-3 mb-4">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">공지사항</h6>
            </div>
          </div>
          <material-table title="조회 결과" :headers=noticeColumns :lists=noticeList :font-size="'sm'"
            @row-clicked=onRowClicked />
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt-3 mb-4">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">채널 별 발송 현황</h6>
            </div>
          </div>
          <div class="p-3 pb-0 mt-4 card-body">
            <div class="row">
              <div class="col-7 text-start">
                <pie-chart :chart="{
      labels: ['SMS', 'LMS', 'MMS', 'Kakao'],
      datasets: {
        label: 'Projects',
        data: [sms_success_count, lms_success_count, mms_success_count, kakao_success_count],
      },
    }" />
              </div>
              <div class="my-auto col-5">
                <span class="badge badge-md badge-dot me-4 d-block text-start">
                  <i class="bg-secondary"></i>
                  <span class="text-xs text-dark">Kakao</span>
                </span>
                <span class="badge badge-md badge-dot me-4 d-block text-start">
                  <i class="bg-info"></i>
                  <span class="text-xs text-dark">SMS</span>
                </span>
                <span class="badge badge-md badge-dot me-4 d-block text-start">
                  <i class="bg-primary"></i>
                  <span class="text-xs text-dark">LMS</span>
                </span>
                <span class="badge badge-md badge-dot me-4 d-block text-start">
                  <i class="bg-dark"></i>
                  <span class="text-xs text-dark">MMS</span>
                </span>

              </div>
            </div>
          </div>
          <div class="p-4 pt-0 pb-1 card-footer d-flex align-items-center">
            <div v-if="dataLoaded" class="w-100">
              <p class="text-sm">
                이번 달 전체 채널 발송 건 : <b>{{ total_success_count.toLocaleString() }}</b><br>
                지난 달 전체 채널 발송 건 : <b>{{ prev_month_success_count.toLocaleString() }}</b>
              </p>
            </div>
            <!-- <div class="w-40 text-end">
              <a class="mb-0 btn bg-light text-end" href="javascript:;">더 보기</a>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt-3 mb-4">
        <div class="card">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">실시간 요금</h6>

            </div>

          </div>
          <div class="p-4 card-body">
            <div class="card-header p-2 position-relative mt-n4 mx-4 z-index-2">
              <div class="bg-gradient-success shadow-success border-radius-lg py-2 pe-1 text-center">
                <p class="mb-1 text-white text-sm font-weight-bold">
                  이번달 사용하신 총 예상금액
                </p>
                <h4 class="font-weight-bolder text-white mt-1">
                  {{ (total_cost)
      .toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}원
                </h4>
              </div>
            </div>
            <ul class="list-group list-group-flush list my--3">
              <li class="px-0 border-0 list-group-item">
                <div class="row align-items-center mt-4">
                  <div class="col">
                    <p class="mb-0 text-xs font-weight-bold">채널:</p>
                    <h6 class="mb-0 text-sm">SMS</h6>
                  </div>
                  <div class="text-center col">
                    <p class="mb-0 text-xs font-weight-bold">사용 금액:</p>
                    <h6 class="mb-0 text-sm">{{ (sms_total_cost).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}원
                    </h6>
                  </div>
                </div>
                <div class="row align-items-center mt-3">
                  <div class="col">
                    <p class="mb-0 text-xs font-weight-bold">채널:</p>
                    <h6 class="mb-0 text-sm">LMS</h6>
                  </div>
                  <div class="text-center col">
                    <p class="mb-0 text-xs font-weight-bold">사용 금액:</p>
                    <h6 class="mb-0 text-sm">{{ (lms_total_cost).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}원
                    </h6>
                  </div>
                </div>
                <div class="row align-items-center mt-3">
                  <div class="col">
                    <p class="mb-0 text-xs font-weight-bold">채널:</p>
                    <h6 class="mb-0 text-sm">MMS</h6>
                  </div>
                  <div class="text-center col">
                    <p class="mb-0 text-xs font-weight-bold">사용 금액:</p>
                    <h6 class="mb-0 text-sm">{{ (mms_total_cost).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}원
                    </h6>
                  </div>
                </div>
                <div class="row align-items-center mt-3">
                  <div class="col">
                    <p class="mb-0 text-xs font-weight-bold">채널:</p>
                    <h6 class="mb-0 text-sm">Kakao</h6>
                  </div>
                  <div class="text-center col">
                    <p class="mb-0 text-xs font-weight-bold">사용 금액:</p>
                    <h6 class="mb-0 text-sm">{{ (kakao_total_cost).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}원
                    </h6>
                  </div>
                </div>
                <div class="row align-items-center mt-3">
                  <div class="col">
                    <p class="mb-0 text-xs font-weight-bold">채널:</p>
                    <h6 class="mb-0 text-sm">Email</h6>
                  </div>
                  <div class="text-center col">
                    <p class="mb-0 text-xs font-weight-bold">사용 금액:</p>
                    <h6 class="mb-0 text-sm">{{ (email_total_cost).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}원
                    </h6>
                  </div>
                </div>
                <!-- <hr class="mt-3 mb-1 horizontal dark" /> -->
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-12 position-relative z-index-2 mt-0 mb-4">
        <div class="calendar-header">
          <calendar id="calendar" title="예약 및 발송현황" :day="shiftedLabels[6] + '요일'" :year="getCurrentDate()"
            :initial-date="initialDate" :events="events" @event-clicked="handleCalendarEventClicked" />
        </div>
      </div>
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row mb-4">
          <div v-if="sms_data_loaded && calendar_data_loaded" class="col-lg-3 col-md-6 mt-4 mb-4">
            <chart-holder-card title="주간 SMS 발송 건수"
              :subtitle="new_sixDaysAgoFormatted + ' ~ ' + new_currentDate" :update="updateMessage">
              <reports-bar-chart :chart="{
      labels: shiftedLabels,
      datasets: {
        label: 'SMS',
        data: sms_success_count_week,
      },
    }" />
            </chart-holder-card>
          </div>
          <div v-if="lms_data_loaded && calendar_data_loaded" class="col-lg-3 col-md-6 mt-4 mb-4">
            <chart-holder-card title="주간 LMS 발송 건수"
              :subtitle="new_sixDaysAgoFormatted + ' ~ ' + new_currentDate" :update="updateMessage"
              color="warning">
              <reports-bar-chart id="test2" :chart="{
      labels: shiftedLabels,
      datasets: {
        label: 'LMS',
        data: lms_success_count_week,
      },
    }" />
            </chart-holder-card>
          </div>
          <div v-if="mms_data_loaded && calendar_data_loaded" class="col-lg-3 col-md-6 mt-4 mb-4">
            <chart-holder-card title="주간 MMS 발송 건수"
              :subtitle="new_sixDaysAgoFormatted + ' ~ ' + new_currentDate" :update="updateMessage"
              color="success">
              <reports-bar-chart id="test" :chart="{
      labels: shiftedLabels,
      datasets: {
        label: 'MMS',
        data: mms_success_count_week,
      },
    }" />
            </chart-holder-card>
          </div>
          <div v-if="kakao_data_loaded && calendar_data_loaded" class="col-lg-3 mt-4 mb-3">
            <chart-holder-card title="주간 Kakao 발송 건수"
              :subtitle="new_sixDaysAgoFormatted + ' ~ ' + new_currentDate" :update="updateMessage"
              color="info">
              <reports-bar-chart id="tes4" :chart="{
      labels: shiftedLabels,
      datasets: {
        label: 'KAKAO',
        data: kakao_success_count_week,
      },
    }" />
            </chart-holder-card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card :title="{ text: '총 SMS 발송 건수', value: sms_success_count.toLocaleString() + '건' }"
              :detail="'사용 금액: ' + (sms_success_count * sms_unit_cost).toLocaleString() + '원 예상'" :icon="{
      name: 'sms',
      color: 'text-white',
      background: 'primary',
    }" />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card :title="{ text: '총 LMS 발송 건수', value: lms_success_count.toLocaleString() + '건' }"
              :detail="'사용 금액: ' + (lms_success_count * lms_unit_cost).toLocaleString() + '원 예상'" :icon="{
      name: 'chat',
      color: 'text-white',
      background: 'warning',
    }" />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card :title="{ text: '총 MMS 발송 건수', value: mms_success_count.toLocaleString() + '건' }"
              :detail="'사용 금액: ' + (mms_success_count * mms_unit_cost).toLocaleString() + '원 예상'" :icon="{
      name: 'mms',
      color: 'text-white',
      background: 'success',
    }" />

          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <mini-statistics-card :title="{ text: '총 Kakao 발송 건수', value: kakao_success_count.toLocaleString() + '건' }"
              :detail="'사용 금액: ' + (kakao_success_count * kakao_unit_cost).toLocaleString() + '원 예상'" :icon="{
      name: 'wallet',
      color: 'text-white',
      background: 'info',
    }" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
    </div>
    <div id="TermsAndConditions" class="modal fade" tabindex="-1" aria-labelledby="TermsAndConditionsLabel"
      aria-hidden="true">
      <div class="modal-dialog" style="max-width: 800px; width: 90%;">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h5 class="modal-title" id="createGroupModalLabel">{{ modalTitle }}</h5> -->
            <h5 id="TermsAndConditionsLabel" class="modal-title">약관동의</h5>
          </div>
          <div class="modal-body" style="max-height: 500px; overflow-y: auto;">
            <div v-if=!previousInformationUseAgree class="row mt-2">
              <div class="row" style="margin-left: 0px;">
                <material-checkbox id="flexCheckDefault" v-model="isInformationUseAgree" class="font-weight-light">
                  <label class="text-success font-weight-bold">개인정보 수집/이용</label>
                  동의
                </material-checkbox>
              </div>
              <div v-if=!isInformationUseAgree style="border: 1px solid #ccc; padding: 10px;">
                <label class="ms-0 text-xs font-weight-bold">개인정보 제공 동의 거부 권리 및 동의 거부에 따른 불이익 내용 또는 제한사항</label>
                <label class="ms-0 text-xs">
                  1. SK주식회사(이하 "당사")는 개인정보 제공자(이하 "고객")가 동의한 내용 외의 다른 목적으로 활용하지 않습니다.<br>
                  2. 제공된 개인정보의 이용을 거부하고자 할 경우 당사 홈페이지(https://message.to-go.io) 개인정보처리방침에 게시된 방법을 통해 열람,정정,삭제를 <br>
                  &nbsp;&nbsp;&nbsp;&nbsp;요구할 수 있으며, 동의 거부에 따른 불이익은 없으나, 서비스가 제한 될 수 있습니다.<br>
                  3. 당사 개인정보 수집/이용/처리위탁 현황 및 변동사항은 홈페이지(https://message.to-go.io) 개인정보처리방침에 공개하여 고지하고 있습니다.<br>
                </label>
                <label class="ms-0 text-xs">
                  1. 수집 항목<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;1) 필수수집항목 : 본인확인정보(이름,생년월일,성별,휴대폰번호,이메일주소)로그인정보(홈페이지ID,비밀번호)<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;고객정보(법인번호, 사업자등록번호, 업체명, 대표자, 업태, 종목, 주소, 전화번호),
                  서비스담당자 및(후불 납부 시)세금계산서 담당자
                  정보<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(신청자명,부서/직위,휴대폰전화번호,유선번호,이메일주소)<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;자동이체 신청 시 출금계좌 정보(은행명, 계좌번호, 예금주명, 사업자등록번호, 생년월일(개인계좌인
                  경우),전화번호),증빙서류(통장사본)<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;2)서비스 이용 및 업무처리 과정에서 생성되어 수집되는 정보 : 접속정보(서비스 이용기록, 접속로그, 쿠키, 접속IP),결제기록<br>
                  2. 수집/이용 목적<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;1)서비스 제공에 관한 계약이행 및 요금정산, 콘텐츠 제공, 구매 및 요금결제<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;2)회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정이용방지 및 비인가 사용방지, 가입의사 확인, 불만처리 등 민원처리
                  및<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;고지사항 전달<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;3)접속 통계 등 서비스 개선을 위한 분석 자료로 활용<br>
                  3. 보유/이용기간<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;서비스 가입기간 동안 요금정산, 과오납 등 분쟁 대비를 위해 해지 후 요금 완납 3개월까지 보유 이용<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;(단. 정보통신망이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법 등 법률에 의한 특별한 규정이 있는 경우 관계 법령에 따라
                  보관)<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;※서비스 제공을 위해서 필요한 최소한의 개인정보이므로 동의를 해주셔야 서비스를 이용하실 수 있습니다.<br>
                </label>
              </div>
            </div>
            <div v-if=!previousCreditCheckAgree class="row mt-2">
              <div class="row" style="margin-left: 0px;">
                <material-checkbox id="flexCheckDefault" v-model="isCreditCheckAgree" class="font-weight-light">
                  <label class="text-success font-weight-bold">신용정보 조회</label>
                  동의
                </material-checkbox>
              </div>
              <div v-if=!isCreditCheckAgree style="border: 1px solid #ccc; padding: 10px;">
                <label class="ms-0 text-xs">
                  <table style="width: 100%; border-collapse: collapse; table-layout: fixed;">
                    <colgroup>
                      <col style="width: 33.33%;">
                      <col style="width: 33.33%;">
                      <col style="width: 33.33%;">
                    </colgroup>
                    <tr>
                      <th style="border: 1px solid #ccc; text-align: center;">신용정보제공업체</th>
                      <th style="border: 1px solid #ccc; text-align: center;">제공목적</th>
                      <th style="border: 1px solid #ccc; text-align: center;">제공항목</th>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #ccc;text-align: center;">한국기업데이터㈜-Cretop, NICE평가정보㈜</td>
                      <td style="border: 1px solid #ccc;text-align: center;">서비스 개통/정산 등 여신관리 시 참고자료로 활용</td>
                      <td style="border: 1px solid #ccc;text-align: center;">신용도</td>
                    </tr>
                  </table>
                  <br>
                  &nbsp;&nbsp;&nbsp;&nbsp;※신용정보 조회기록은 고객님의 신용등급에 영향을 주지 않습니다.
                </label>
              </div>
            </div>
            <div v-if=!previousSpamResponsibilityAgree class="row mt-2">
              <div class="row" style="margin-left: 0px;">
                <material-checkbox id="flexCheckDefault" v-model="isSpamResponsibilityAgree" class="font-weight-light">
                  <label class="text-success font-weight-bold">스팸메시지 관리 및 이용고객 책임</label>
                  동의
                </material-checkbox>
              </div>
              <div v-if=!isSpamResponsibilityAgree style="border: 1px solid #ccc; padding: 10px;">
                <label class="ms-0 text-xs">
                  SK㈜(이하 "회사")에서 제공하는 메시징 서비스 이용약관(이하 "서비스 계약")과 관련하여, 귀사(이하 "본인")는 회사가 스팸메세지에 대하여 아래와 같이 관리하는 것에 동의하며,
                  본인 또는 본인의 회원/고객이 전송한 메시지 내용에 관하여 스팸 메시지 전송 문제가 발생할 경우 이에 대한 민사, 형사, 행정상의 모든 책임을 부담하고, 본인의 책임과 비용으로 회사를
                  면책시키고 회사에 손해가 있으면 이를 배상합니다. 동의를 거부하는 경우 서비스 가입(계약 체결) 및 이용이 불가능 합니다. <br><br>
                  "용어의 정의(서비스 이용약관 제 2조)<br>
                  1) 스팸: 정보통신망법을 위반하여 사용자가 원하지 않는데도 불구하고 정보통신망을 통해 일방적으로 전송 또는 게시되는 영리목적의 광고성
                  &nbsp;&nbsp;&nbsp;&nbsp;정보<br>
                  2) 스미싱 : 메시지 내용 중 인터넷 주소를 클릭하면 악성코드가 설치되어 수신자가 모르는 사이에 금전적 피해 또는 개인금융정보 탈취 피해를
                  &nbsp;&nbsp;&nbsp;&nbsp;야기하는 메시지"
                  <br><br>
                  1. 본인은 정보통신망법 및 기타 관련 법령을 위반하지 않는 범위 내에서 광고메시지를 발송합니다.<br>
                  2. 본인은 SMS/MMS 전송에 대하여 본인의 회원 또는 고객으로부터 스팸 또는 스미싱(이하 통칭하여 불법스팸1)을 발송하지 않겠다는
                  <br>&nbsp;&nbsp;&nbsp;&nbsp;동의서를 회사에 제출 하겠습니다. 단, 모든 회원의 동의서를 직접 제출하기 힘든 경우 본 동의서로 이를 대신하며, 이에 대한 법적
                  책임도 <br>&nbsp;&nbsp;&nbsp;&nbsp;대신 부담합니다.<br>
                  3. 본인 또는 본인의 회원 또는 고객의 발송 요청에 따라 회사가 발송한 메시지 중 일반인이 해당 메시지에 대하여 불법스팸을 사유로 회사에
                  <br>&nbsp;&nbsp;&nbsp;&nbsp;손해배상 등의 청구를 하는 경우, 본인의 책임과 비용으로 회사를 면책시키고, 회사에 손해가 발생하면 그 손해를 배상합니다.<br>
                  4. 회사는 본인이 다음 중 하나에 해당하는 경우 지체 없이 서비스 이용을 정지할 수 있습니다.<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;1) 과학기술정보통신부 또는 한국인터넷진흥원이 불법스팸 전송 사실을 확인하여 이용정지를 요청하는 경우<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;2) 본인이 전송하는 광고의 수신자가 불법스팸으로 신고한 경우<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;3) 전송된 메시지가 불법스팸으로 판단되어 회사로부터 전송 중단을 요청 받았으나 지속 전송한 경우<br>
                  5. 본인이 불법스팸을 발송할 경우 본 동의서에 따라 회사는 서비스 계약을 해지할 수 있으며, 이에 대하여 본인은 일체 이의를 제기하지
                  <br>&nbsp;&nbsp;&nbsp;&nbsp;않습니다.<br>
                  6. 본인은 불법스팸이 발송되지 않도록 선량한 관리자의 주의의무를 가지고 서비스 계약상의 서비스를 이용할 것이며, 메시지 전송 현황을 항상
                  <br>&nbsp;&nbsp;&nbsp;&nbsp;철저히 확인 및 관리 감독하겠습니다.<br>
                  7. 회사는 서비스 제공목적에 맞는 서비스 이용여부를 확인하기 위하여 상시적으로 모니터링을 실시합니다.<br>
                  8. 회사는 본인이 불법스팸을 전송한 사실을 확인한 경우, 한국인터넷진흥원 불법스팸대응센터에 관련 자료를 첨부하여 신고할 수 있습니다.<br>
                  9. 본인은 SMS 또는 MMS 발송을 위해 본인이 운영하는 어플리케이션, 웹서버, DB서버, 네트워크 등에 대한 보안 및 관리 책임을 부담합니다.<br>
                  10.본인의 관리 소홀 또는 시스템 취약점 듯 회사의 귀책사유 없는 사정에 의한 해킹 또는 제3자의 불법 침해로 인해 발생되는 SMS 또는 MMS
                  <br>&nbsp;&nbsp;&nbsp;&nbsp;발송, 서비스 장애, 서비스 중단, 불법 스팸 발송, 발송요금 등의 피해에 대해서 회사가 어떠한 책임도 부담하지 않음에
                  동의합니다.<br>
                  11. 본 동의서에 규정하지 않은 사항은 정보봉신망법과 회사의 서비스 이용약관에 따라 적용하며, 불법스팸 규제를 위한 책임과 의무를 성실히
                  <br>&nbsp;&nbsp;&nbsp;&nbsp;이행합니다.<br>

                </label>
              </div>
            </div>

            <div v-if=!previousFromPhoneNumberManageAgree class="row mt-2">
              <div class="row" style="margin-left: 0px;">
                <material-checkbox id="flexCheckDefault" v-model="isFromPhoneNumberManageAgree"
                  class="font-weight-light">
                  <label class="text-success font-weight-bold">발신번호 변작 방지 및 사전등록</label>
                  동의
                </material-checkbox>
              </div>
              <div v-if=!isFromPhoneNumberManageAgree style="border: 1px solid #ccc; padding: 10px;">
                <label class="ms-0 text-xs">
                  1. 용어의 정의<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;① 번호변작'이라 함은 본인 또는 본인이 소속된 기관의 전화번호가 아닌 다른 번호를 이용하는 경우를 말한다.<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;② *발신번호'라 함은 문자메시지를 발송하는 송신인의 전화번호를 말한다.<br>
                  2. 이용자의 의무<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;① 이용자는 이용하고자 하는 "발신번호"를 이용 전 사전등록 해야 한다.<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;② 다음 각 호의 방법 중 하나를 선택하여 사전 등록 한다.<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1. 휴대폰 본인인증<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2. 통신서비스 증명원<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;③ 사전 등록한 "발신번호" 이외의 다른 번호로 문자메시지를 발송해서는 안된다.<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;② 이용자는 "번호변작"으로 전송함으로써 발생하는 모든 민형사상 책임을 부담한다.<br>
                  3. 이용 정지<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;① 이용자가 다음 중 각 호에 해당하는 경우 지체 없이 서비스 이용을 정지할 수 있다.<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1. 과학기술정보통신부 『거짓으로 표시된 전화번호로 인한 이용자 피해 예방 등에 관한 고시
                  1이하 *고시") 제12조 제1항에 따라
                  <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;과학기술정보통신부 또는 중앙전파관리소,
                  한국인터넷진흥원 (KISA)에서 *번호변작 등으로 판명되어 이용정지를 요청한 경우<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2. 전송된 메시지가 "번호변작*으로 판단되어 회사로부터 전송중지 요청을 받음에도 불구하고 지속
                  전송한 경우<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;② 이용정지의 사유가 해소되고 재발할 우려가 없다고 판단될 경우 서비스 이용을 가능하게 할 수 있다.<br>
                  4. 계약의 해지<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;이용자가 있음 중 각 호의 하나에 해당하는 경우 지체 없이 계약을 해지할 수 있으며, 그 사실을 이용자에게 통지한다.<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1. 이용정지 기간 경과 이후에도 지속적으로 "번호변작"하여 전송하여 과학기술정보통신부 또는
                  중앙전파관리소, 한국인터넷진흥원에서 <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;계약해지를
                  요청하는 경우<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2. 3회 이상 이용정지 또는 "번호변작" 전송 중단 요청을 받은 경우<br>
                  5. 회사의 의무<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;① 회사는 "고시" 제19조 제1항에 따라 이용자가 번호변적" 전송한 사실을 확인한 경우, 한국인터넷진흥원 요청에 따라 관련 자료를 제공할
                  수 <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;있다<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;② 회사는 "고시" 제19조 제1항에 따라 관련 자료 제공을 위해 "발신번호" 사전 등록한 이력 및 차단한 통신 이력을 최소 1년간 보관
                  한다.<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  본인은 회사에서 제공하는 서비스 계약과 관련하여,회사가 전기동신사업법 등 관련 법령에 따라 아래와 같이 본인의 발신번호를 사진 등록
                  <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;및 관리하는 데에 동의하며, 본인 또는 본인의 회원/고객이 진송한 메시지에 관하여 발신번호
                  변작 관련 문제가 발생할 경우 이에 대한 민사, <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;형사, 행정상의 모든 책임을 부담하고
                  본인의 책임과 비용으로 회사를 면책시키고 회사에 손해가 있으면 이를 배상합니다.<br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;동의를 거부하는 경우 서비스 가입(계약 체결) 및 이용이 불가합니다<br>
                </label>
              </div>
            </div>
            <div v-if=!previousServiceAgree class="row mt-2">
              <div class="row" style="margin-left: 0px;">
                <material-checkbox id="flexCheckDefault" v-model="isServiceAgree" class="font-weight-light">
                  <label class="text-success font-weight-bold">서비스 종류/사용료</label>
                  동의
                </material-checkbox>
              </div>
              <div v-if=!isServiceAgree style="border: 1px solid #ccc; padding: 10px;">
                <label class="ms-0 text-xs">
                  <table style="width: 100%; border-collapse: collapse; table-layout: fixed;">
                    <colgroup>
                      <col style="width: 50%;">
                      <col style="width: 50%;">
                    </colgroup>
                    <tr>
                      <th style="border: 1px solid #ccc; text-align: center;">서비스 종류(건별)</th>
                      <th style="border: 1px solid #ccc; text-align: center;">원/건</th>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #ccc;text-align: center;">SMS</td>
                      <td style="border: 1px solid #ccc;text-align: center;">{{ sms_unit_cost }}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #ccc;text-align: center;">LMS</td>
                      <td style="border: 1px solid #ccc;text-align: center;">{{ lms_unit_cost }}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #ccc;text-align: center;">MMS</td>
                      <td style="border: 1px solid #ccc;text-align: center;">{{ mms_unit_cost }}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #ccc;text-align: center;">SMS(해외)</td>
                      <td style="border: 1px solid #ccc;text-align: center;">{{ sms_intl_unit_cost }}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #ccc;text-align: center;">LMS(해외)</td>
                      <td style="border: 1px solid #ccc;text-align: center;">{{ lms_intl_unit_cost }}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #ccc;text-align: center;">알림톡</td>
                      <td style="border: 1px solid #ccc;text-align: center;">{{ kakao_unit_cost }}</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #ccc;text-align: center;">이메일</td>
                      <td style="border: 1px solid #ccc;text-align: center;">{{ email_unit_cost }}</td>
                    </tr>
                    <!-- <tr>
                                        <td style="border: 1px solid #ccc;text-align: center;">푸시(Slack, Teams 등)</td>
                                        <td style="border: 1px solid #ccc;text-align: center;">{{push_unit_cost}}</td>
                                    </tr>                                     -->
                  </table>
                  <br>
                  <table style="width: 100%; border-collapse: collapse; table-layout: fixed;">
                    <colgroup>
                      <col style="width: 50%;">
                      <col style="width: 50%;">
                    </colgroup>
                    <tr>
                      <th style="border: 1px solid #ccc; text-align: center;">서비스 종류(월별)</th>
                      <th style="border: 1px solid #ccc; text-align: center;">원/월</th>
                    </tr>
                    <tr>
                      <td style="border: 1px solid #ccc;text-align: center;">푸시(Slack, Teams 등)</td>
                      <td style="border: 1px solid #ccc;text-align: center;">50,000</td>
                    </tr>
                  </table>
                  *월별 서비스는 해당 서비스 이용시 과금, 월 백만건 이상 발생 시 추가금 발생
                </label>
              </div>
            </div>
            <div v-if=!previousTermsAgree class="row mt-2">
              <div class="row" style="margin-left: 0px;">
                <material-checkbox id="flexCheckDefault" v-model="isTermsAgree" class="font-weight-light"
                  style="margin-right: 15px;">
                  <a href="/서비스 이용 약관_SK(주)_Message To-Go.pdf" class="text-primary font-weight-bold" target="_blank"
                    style="font-size: 0.8rem;">이용약관</a>
                  동의 (링크 확인)
                </material-checkbox>
              </div>
            </div>
            <!-- <div class="row mt-2" v-if = !previousPrivacyAgree>
                            <div class="row" style="margin-left: 0px;">
                              <material-checkbox
                                  id="flexCheckDefault"
                                  class="font-weight-light"
                                  v-model="isPrivacyAgree"
                              >
                              <a
                                href="/home/privacyagree"
                                class="text-primary font-weight-bold"
                                target="_blank"
                                style="font-size: 1rem;"
                                >개인정보 수집 및 이용</a>
                                동의 (링크 확인)
                              </material-checkbox>
                            </div>
                          </div> -->
            <!-- :disabled="!((isPrivacyAgree||previousPrivacyAgree)
                                            &&(isTermsAgree||previousTermsAgree)
                                            &&(isServiceAgree||previousServiceAgree)
                                            &&(isFromPhoneNumberManageAgree||previousFromPhoneNumberManageAgree)
                                            &&(isSpamResponsibilityAgree||previousSpamResponsibilityAgree)
                                            &&(isCreditCheckAgree||previousCreditCheckAgree)
                                            &&(isThirdPartyUseAgree||previousThirdPartyUseAgree)
                                            &&(isInformationUseAgree||previousInformationUseAgree))" -->
            <!-- Modal body content goes here -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" data-bs-dismiss="modal" :disabled="!((isTermsAgree || previousTermsAgree)
      && (isServiceAgree || previousServiceAgree)
      && (isFromPhoneNumberManageAgree || previousFromPhoneNumberManageAgree)
      && (isSpamResponsibilityAgree || previousSpamResponsibilityAgree)
      && (isCreditCheckAgree || previousCreditCheckAgree)
      && (isInformationUseAgree || previousInformationUseAgree))"
              @click="agreeTermsAndConditions()">동의</button>
            <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SalesTableCard from "./components/SalesTableCard.vue";
import ChartHolderCard from "./components/ChartHolderCard.vue";
import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
import ReportsLineChart from "./components/ReportsLineChart.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
import BookingCard from "./components/BookingCard.vue";
import OrdersListCard from "@/examples/Cards/OrdersListCard.vue";
import ApiCall from "@/Interface/ApiCall";
import PieChart from "@/examples/Charts/PieChart.vue";
import MaterialTable from "@/components/MaterialTable.vue";
import MaterialButton from "@/components/MaterialButton.vue";

import Calendar from "@/examples/Calendar.vue";
import { Auth } from 'aws-amplify'
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
// import MaterialTextarea from "@/components/MaterialTextarea.vue";

export default {
  name: "DashboardDefault",
  components: {
    ChartHolderCard,
    ReportsBarChart,
    MiniStatisticsCard,
    PieChart,
    MaterialTable,
    MaterialButton,
    Calendar,
    MaterialCheckbox,
    // MaterialTextarea,
  },
  data() {
    return {
      dataLoaded: false,
      prev_dataLoaded: false,
      sms_data_loaded: false,
      lms_data_loaded: false,
      mms_data_loaded: false,
      kakao_data_loaded: false,
      calendar_data_loaded: false,

      sms_success_count: 0,
      sms_fail_count: 0,
      lms_success_count: 0,
      lms_fail_count: 0,
      mms_success_count: 0,
      mms_fail_count: 0,
      kakao_success_count: 0,
      kakao_fail_count: 0,
      email_success_count: 0,
      email_fail_count: 0,
      total_success_count: 0,

      sms_total_cost: 0,
      lms_total_cost: 0,
      mms_total_cost: 0,
      kakao_total_cost: 0,
      email_total_cost: 0,
      total_cost: 0,

      prev_month_success_count: 0,
      prev_month_fail_count: 0,

      sms_unit_cost: 8.4,
      lms_unit_cost: 27,
      mms_unit_cost: 60,
      kakao_unit_cost: 6.5,
      email_unit_cost: 0.5,
      push_unit_cost: 0.05,
      sms_intl_unit_cost: 120,
      lms_intl_unit_cost: 360,

      shiftedLabels: [],
      events: [],

      sms_success_count_week: [0, 0, 0, 0, 0, 0, 0],
      lms_success_count_week: [0, 0, 0, 0, 0, 0, 0],
      mms_success_count_week: [0, 0, 0, 0, 0, 0, 0],
      kakao_success_count_week: [0, 0, 0, 0, 0, 0, 0],

      sixDaysAgoFormatted: '',
      currentDate: '',
      new_sixDaysAgoFormatted: '',
      new_currentDate: '',

      updateMessage: '',

      noticeColumns: ['제목', ''],
      noticeList: [],
      selectedRow: null,
      selectedList: null,

      initialDate: this.getCurrentDate(),

      isAdmin: false,
      isLogin: false,
      isOperator: false,

      hasSubTenant: false,
      hasZeroTenant: false,

      subTenantList: '',
      subTenantListForSearch: [],
      primary_customer_reference_id_list: [],
      selectedTenant: '',
      selectedTenantReference: '',
      tenant_data_loaded: true,
      calendar_year_month: '',
      user_id: '',
      myTenant: [],

      // previousPrivacyAgree: false,
      previousTermsAgree: false,
      previousServiceAgree: false,
      previousFromPhoneNumberManageAgree: false,
      previousSpamResponsibilityAgree: false,
      previousCreditCheckAgree: false,
      // previousThirdPartyUseAgree: false,
      previousInformationUseAgree: false,

      // isPrivacyAgree: true,
      isTermsAgree: false,
      isServiceAgree: false,
      isFromPhoneNumberManageAgree: false,
      isSpamResponsibilityAgree: false,
      isCreditCheckAgree: false,
      // isThirdPartyUseAgree: false,
      isInformationUseAgree: false,
      isAllTermsAndConditionsAgree: false,
    };
  },
  async mounted() {

    await this.setLoginInformation();
    // console.log(this.isAdmin);
    this.getTermsAndAgreementData();

    // if (this.isAdmin) {
    //   this.getTermsAndAgreementData();
    // }

    const today = new Date().getDay();

    // define an array of labels with Sunday as the first day of the week
    const labels = ['월', '화', '수', '목', '금', '토', '일'];

    // shift the labels based on the current day of the week
    this.shiftedLabels = labels.slice(today).concat(labels.slice(0, today));
    // admin일때만 subtenant 조회
    if (this.isAdmin) {
      this.setTenantList();
    }
    this.setReferenceList(this.selectedTenant.tenant_id);

    // console.log('selectTenant :' + JSON.stringify(this.selectedTenant));


    this.setSuccessFailCount(this.selectedTenant.tenant_id);

    this.setPrevMonthSuccessCount(this.selectedTenant.tenant_id);

    this.setWeeklySuccessCount(this.selectedTenant.tenant_id);

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year_month = `${year}${month}`;
    this.calendar_year_month = year_month
    this.setCalendarData(this.selectedTenant.tenant_id, this.calendar_year_month);

    this.setNoticeData();

    const year1 = this.sixDaysAgoFormatted.substring(0, 4);
    const month1 = this.sixDaysAgoFormatted.substring(4, 6);
    const day1 = this.sixDaysAgoFormatted.substring(6, 8);

    this.new_sixDaysAgoFormatted = `${year1}/${month1}/${day1}`;

    const year2 = this.currentDate.substring(0, 4);
    const month2 = this.currentDate.substring(4, 6);
    const day2 = this.currentDate.substring(6, 8);

    this.new_currentDate = `${year2}/${month2}/${day2}`;

    const currentDate = new Date().toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hourCycle: 'h23', minute: '2-digit', second: '2-digit' }).replace(/,/g, '');

    this.updateMessage = `업데이트: ${currentDate}`;



  },
  methods: {
    agreeTermsAndConditions() {

      const agree_type_list = [];
      // if (this.isPrivacyAgree)
      //   agree_type_list.push('privacy');
      if (this.isTermsAgree)
        agree_type_list.push('terms');
      if (this.isServiceAgree)
        agree_type_list.push('service');
      if (this.isFromPhoneNumberManageAgree)
        agree_type_list.push('from_phone_number_manage');
      if (this.isSpamResponsibilityAgree)
        agree_type_list.push('spam_responsibility');
      if (this.isCreditCheckAgree)
        agree_type_list.push('credit_check');
      // if (this.isThirdPartyUseAgree)
      //   agree_type_list.push('third_party_use');
      if (this.isInformationUseAgree)
        agree_type_list.push('information_use');


      const agree_payload = {
        agree_type_list: agree_type_list,
        user_id: this.user_id,
      };

      ApiCall.call('terms/agree', 'POST', agree_payload)
        .then(response => {
          alert("동의해 주셔서 감사합니다.");
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });
    },
    openPopup() {
      const bootstrap = this.$store.state.bootstrap;
      // 모달 열기
      const modal = new bootstrap.Modal(
        document.getElementById("TermsAndConditions")
      );
      modal.show()
    },
    onRowClicked({ list, index }) {
      this.selectedList = list;
      this.selectedRow = index;
      // console.log(JSON.stringify(this.selectedList));
    },
    getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    handleCalendarEventClicked(eventData) {
      // console.log(eventData);
      if (eventData.title === 'Previous Month') {
        // Handle the previous month click logic
        // console.log('Previous Month clicked');
        // console.log(eventData.start);
        // console.log(eventData.initialDate);
        this.calendar_data_loaded = false;
        this.initialDate = eventData.initialDate;
        // console.log(eventData);
        this.calendar_year_month = eventData.start;
        this.setCalendarData(this.selectedTenant.tenant_id, this.calendar_year_month);
        // Perform any necessary actions for the previous month
      } else if (eventData.title === 'Next Month') {
        // Handle the next month click logic
        // console.log('Next Month clicked');
        // console.log(eventData.start);
        // console.log(eventData.initialDate);
        this.calendar_data_loaded = false;
        this.initialDate = eventData.initialDate;
        this.calendar_year_month = eventData.start;
        this.setCalendarData(this.selectedTenant.tenant_id, this.calendar_year_month);
        // Perform any necessary actions for the next month
      } else {
        const startDateTime = new Date(eventData.start);
        const year = startDateTime.getFullYear();
        const month = String(startDateTime.getMonth() + 1).padStart(2, '0');
        const day = String(startDateTime.getDate()).padStart(2, '0');
        const hour = String(startDateTime.getHours()).padStart(2, '0');
        const minute = String(startDateTime.getMinutes()).padStart(2, '0');
        const second = String(startDateTime.getSeconds()).padStart(2, '0');

        const yyyymmddhhmmss = `${year}${month}${day}${hour}${minute}${second}`;

        let status = '';
        if (eventData.title.startsWith('예약취소'))
          status = 'schedule-cancel';
        else if (eventData.title.startsWith('예약완료'))
          status = 'schedule-request';
        else if (eventData.title.startsWith('전송실패'))
          status = 'fail';
        else if (eventData.title.startsWith('전송성공'))
          status = 'success';

        const routeParams = {
          title: status,
          start: yyyymmddhhmmss,
          request_tenant_id: this.selectedTenant.tenant_id
        };

        if (this.isAdmin)
          this.$router.push({ name: 'SendHistoryAdminWithParams', params: routeParams });
        else
          this.$router.push({ name: 'SendHistoryWithParams', params: routeParams });
      }
    },
    selectTenant(item, index) {
      this.tenant_data_loaded = false;
      this.selectedTenant = item;

      this.setSuccessFailCount(this.selectedTenant.tenant_id);

      this.setPrevMonthSuccessCount(this.selectedTenant.tenant_id);

      this.setWeeklySuccessCount(this.selectedTenant.tenant_id);

      this.setCalendarData(this.selectedTenant.tenant_id, this.calendar_year_month);

      this.setNoticeData();

      this.setReferenceList(this.selectedTenant.tenant_id);

      //tenant 기반으로 primary 조회 하여 재설정

      // console.log('selectTenant :' + JSON.stringify(item));
      // console.log('selectTenant.name :' + JSON.stringify(item.tenant_name));

      this.tenant_data_loaded = true;
    },
    setinitializeReference(){
          this.sms_success_count=0;
          this.sms_fail_count=0;
          this.lms_success_count=0;
          this.lms_fail_count=0;
          this.mms_success_count=0;
          this.mms_fail_count=0;
          this.kakao_success_count=0;
          this.kakao_fail_count=0;
          this.email_success_count=0;
          this.email_fail_count=0;

          this.sms_total_cost=0;
          this.lms_total_cost=0;
          this.mms_total_cost=0;
          this.kakao_total_cost=0;
          this.email_total_cost=0;
          this.total_cost=0;

          this.total_success_count=0;
          this.prev_month_success_count=0;
    },
    setSuccessFailCountReference(tenant_id, reference_id) {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year_month = `${year}${month}`;
      const payload = {
        year_month: year_month,
        send_type: ["sms", "lms", "mms", "at", "ai", "email"],
        request_tenant_id: tenant_id,
        reference_id: reference_id
      };

      ApiCall.call('message/count/success-fail/reference', 'POST', payload)
        .then(response => {
          // console.log("response.data=", response.data);

          //초기화
          this.setinitializeReference();
          
          // response.data.data를 사용하여 데이터 접근
          const data = response.data.current_month; // 여기에서 data 객체를 추출
          this.sms_success_count = data.sms_success_count || 0;
          this.sms_fail_count = data.sms_fail_count || 0;
          this.lms_success_count = data.lms_success_count || 0;
          this.lms_fail_count = data.lms_fail_count || 0;
          this.mms_success_count = data.mms_success_count || 0;
          this.mms_fail_count = data.mms_fail_count || 0;
          this.kakao_success_count = (data.ai_success_count || 0) + (data.at_success_count || 0);
          this.kakao_fail_count = (data.ai_fail_count || 0) + (data.at_fail_count || 0);
          this.email_success_count = data.email_success_count || 0;
          this.email_fail_count = data.email_fail_count || 0;;

          // console.log("sms_success_count=", this.sms_success_count);
          // console.log("sms_fail_count=", this.sms_fail_count);
          // console.log("lms_success_count=", this.lms_success_count);
          // console.log("lms_fail_count=", this.lms_fail_count);
          // console.log("mms_success_count=", this.mms_success_count);
          // console.log("mms_fail_count=", this.mms_fail_count);
          // console.log("kakao_success_count=", this.kakao_success_count);
          // console.log("kakao_fail_count=", this.kakao_fail_count);
          // console.log("email_success_count=", this.email_success_count);
          // console.log("email_fail_count=", this.email_fail_count);

          // 실시간 요금화면, 채널별 발송현황 원형
          this.sms_total_cost = this.sms_success_count * this.sms_unit_cost;
          this.lms_total_cost = this.lms_success_count * this.lms_unit_cost;
          this.mms_total_cost = this.mms_success_count * this.mms_unit_cost;
          this.kakao_total_cost = this.kakao_success_count * this.kakao_unit_cost;
          this.email_total_cost = this.email_success_count * this.email_unit_cost;
          this.total_cost = this.sms_total_cost + this.lms_total_cost + this.mms_total_cost + this.kakao_total_cost + this.email_total_cost;

          // console.log("sms_unit_cost=", this.sms_unit_cost);
          // console.log("lms_unit_cost=", this.lms_unit_cost);
          // console.log("mms_unit_cost=", this.mms_unit_cost);
          // console.log("kakao_unit_cost=", this.kakao_unit_cost);
          // console.log("email_unit_cost=", this.email_unit_cost);
          // console.log("total_cost=", this.total_cost);

          //채널별 발송현황 아래화면

          const prev_data = response.data.previous_month; // 여기에서 data 객체를 추출
          this.total_success_count = this.sms_success_count + this.lms_success_count + this.mms_success_count + this.kakao_success_count + this.email_success_count;
          this.prev_month_success_count =
            (prev_data.sms_success_count || 0) +
            (prev_data.lms_success_count || 0) +
            (prev_data.mms_success_count || 0) +
            (prev_data.ai_success_count || 0) +
            (prev_data.at_success_count || 0) +
            (prev_data.email_success_count || 0);
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });


    },
    
    selectReferenceID(item, index) {

      this.tenant_data_loaded = false;
      this.selectedTenantReference = item;

      // console.log('this.selectedTenantReference=', this.selectedTenantReference);
      // console.log('this.selectedTenant=', this.selectedTenant);


      // primary 와 tenant에 따라 조회 기능 개발
      // this.total_cost = this.sms_total_cost + this.lms_total_cost + this.mms_total_cost + this.kakao_total_cost + this.email_total_cost;
      // this.tenant_data_loaded = false;
      // this.selectedTenant = item;

      this.setSuccessFailCountReference(this.selectedTenant.tenant_id, this.selectedTenantReference.reference_id);

      this.setCalendarDataReference(this.selectedTenant.tenant_id, this.calendar_year_month, this.selectedTenantReference.reference_id);

      // this.setSuccessFailCount(this.selectedTenant.tenant_id);

      // this.setPrevMonthSuccessCount(this.selectedTenant.tenant_id);

      // this.setWeeklySuccessCount(this.selectedTenant.tenant_id);

      
      // this.setNoticeData();

      // this.setReferenceList(this.selectedTenant.tenant_id);

      // console.log('selectTenant :' + JSON.stringify(item));
      // console.log('selectTenant.name :' + JSON.stringify(item.tenant_name));

      this.tenant_data_loaded = true;
    },
    setReferenceList(tenant) {

      this.primary_customer_reference_id_list = [];
      this.$forceUpdate();

      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year_month = `${year}${month}`;
      const payload = {
        year_month: year_month,
        send_type: ["sms", "lms", "mms", "at", "ai", "email"],
        request_tenant_id: tenant,
      };

      // API 호출을 통해 데이터를 받아옴
      ApiCall.call('user/auth/referenceid', 'POST', payload).then((res) => {
        // API 응답에서 'data' 배열 가져오기
        const dataArray = res.data.data;

        // primary_customer_reference_id_list에 데이터 매핑
        this.primary_customer_reference_id_list = dataArray.map((item) => ({
          reference_id: item
        }));
        // console.log("primary_customer_reference_id_list=", this.primary_customer_reference_id_list);

      }).catch((error) => {
        console.error('Error fetching reference list:', error);
      });
    },
    setTenantList() {
      ApiCall.call('user/auth/subtenant/list', 'POST').then(async (res) => {

        // console.log("res.data :", res.data)
        if (res.data.length > 0) {
          this.subTenantList = res.data;
          this.hasSubTenant = true;
        }
        // this.subTenantList = this.subTenantList.concat([{tenant_id: "0", tenant_name:"전체"}])
        this.subTenantListForSearch = [{ tenant_id: "all", tenant_name: "전체" }]

        // 자기 자신의 tenant
        const user = await Auth.currentAuthenticatedUser()
        const idToken = user.signInUserSession.idToken;
        const tenantId = idToken.payload['cognito:groups'][0];
        const tenantName = user.attributes['custom:tenant_name'];
        this.myTenant = [{ tenant_id: tenantId, tenant_name: tenantName }]
        this.subTenantListForSearch = this.subTenantListForSearch.concat(this.myTenant)
        this.subTenantList = this.subTenantList.concat(this.myTenant)
        if (tenantId == 0) {
          this.hasZeroTenant = true;
        }
        // console.log("hasZeroTenant=", this.hasZeroTenant);

        this.subTenantListForSearch = this.subTenantListForSearch.concat(res.data);

        // console.log('primary_customer_reference_id_list :' + JSON.stringify(this.primary_customer_reference_id_list));

        this.selectedTenant = this.subTenantListForSearch[1]
        // console.log('tenant list :' + JSON.stringify(this.subTenantListForSearch));
        // console.log('this.myTenant :', this.myTenant);
        // console.log('this.subTenantList :', this.subTenantList);
        // console.log('selectedTenant :' + JSON.stringify(this.selectedTenant));
      })
    },
    async setSuccessFailCount(tenant) {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year_month = `${year}${month}`;
      const payload = {
        year_month: year_month,
        send_type: ["sms", "lms", "mms", "at", "ai", "email"],
        request_tenant_id: tenant,
      };

      ApiCall.call('message/count/success-fail/month', 'POST', payload)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            // Initialize empty objects for each message type
            const smsCounts = { success: 0, fail: 0 };
            const lmsCounts = { success: 0, fail: 0 };
            const mmsCounts = { success: 0, fail: 0 };
            const kakaoCounts = { success: 0, fail: 0 };
            const emailCounts = { success: 0, fail: 0 };

            // Loop over the response array and update the count objects
            response.data.forEach(item => {
              const messageType = Object.keys(item)[0];
              const successCount = item[messageType]?.success ?? 0;
              const failCount = item[messageType]?.fail ?? 0;

              if (messageType === 'sms') {
                smsCounts.success = successCount;
                smsCounts.fail = failCount;
              } else if (messageType === 'lms') {
                lmsCounts.success = successCount;
                lmsCounts.fail = failCount;
              } else if (messageType === 'mms') {
                mmsCounts.success = successCount;
                mmsCounts.fail = failCount;
              } else if (messageType === 'at') {
                kakaoCounts.success += successCount;
                kakaoCounts.fail += failCount;
              } else if (messageType === 'ai') {
                kakaoCounts.success += successCount;
                kakaoCounts.fail += failCount;
              } else if (messageType === 'email') {
                emailCounts.success += successCount;
                emailCounts.fail += failCount;
              }
            });

            // Store the counts in the Vue component's data
            this.sms_success_count = smsCounts.success;
            this.sms_fail_count = smsCounts.fail;
            this.lms_success_count = lmsCounts.success;
            this.lms_fail_count = lmsCounts.fail;
            this.mms_success_count = mmsCounts.success;
            this.mms_fail_count = mmsCounts.fail;
            this.kakao_success_count = kakaoCounts.success;
            this.kakao_fail_count = kakaoCounts.fail;
            this.email_success_count = emailCounts.success;
            this.email_fail_cofunt = emailCounts.fail;
            this.total_success_count = this.sms_success_count + this.lms_success_count + this.mms_success_count + this.kakao_success_count + this.email_success_count;

            //채널별 금액 계산
            this.sms_total_cost = this.sms_success_count * this.sms_unit_cost;
            this.lms_total_cost = this.lms_success_count * this.lms_unit_cost;
            this.mms_total_cost = this.mms_success_count * this.mms_unit_cost;
            this.kakao_total_cost = this.kakao_success_count * this.kakao_unit_cost;
            this.email_total_cost = this.email_success_count * this.email_unit_cost;
            this.total_cost = this.sms_total_cost + this.lms_total_cost + this.mms_total_cost + this.kakao_total_cost + this.email_total_cost;


            this.dataLoaded = true;
          } else {
            this.dataLoaded = true;
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });
    },
    async setPrevMonthSuccessCount(tenant) {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      const prev_year = date.getFullYear();
      const prev_month = (date.getMonth() + 1).toString().padStart(2, '0');
      const prev_year_month = `${prev_year}${prev_month}`;
      const prev_payload = {
        year_month: prev_year_month,
        send_type: ["sms", "lms", "mms", "ai", "at", "ft", "fl", "fc", "fw", "fi"],
        request_tenant_id: tenant,
      };
      ApiCall.call('message/count/success-fail/month', 'POST', prev_payload)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.prev_month_success_count = 0;
            this.prev_month_fail_count = 0;
            // Loop over the response array and update the count objects
            response.data.forEach(item => {
              const messageType = Object.keys(item)[0];
              const successCount = item[messageType]?.success ?? 0;
              const failCount = item[messageType]?.fail ?? 0;

              this.prev_month_success_count += successCount;
              this.prev_month_fail_count += failCount;
            });
            // Set the dataLoaded flag to true
            this.prev_dataLoaded = true;
          } else {
            this.prev_dataLoaded = true;
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });
    },
    async setWeeklySuccessCount(tenant) {
      // Get the current date in yyyymmdd format
      // this.currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "");
      const nowDate = new Date();
      const nowYear = nowDate.getFullYear();
      const nowMonth = String(nowDate.getMonth() + 1).padStart(2, '0');
      const nowDay = String(nowDate.getDate()).padStart(2, '0');
      this.currentDate = `${nowYear}${nowMonth}${nowDay}`;

      // Get the date that is 6 days prior in yyyymmdd format
      const sixDaysAgo = new Date();
      sixDaysAgo.setDate(sixDaysAgo.getDate() - 6);
      // this.sixDaysAgoFormatted = sixDaysAgo.toISOString().slice(0, 10).replace(/-/g, "");
      const sixDaysAgoYear = sixDaysAgo.getFullYear();
      const sixDaysAgoMonth = String(sixDaysAgo.getMonth() + 1).padStart(2, '0');
      const sixDaysAgoDay = String(sixDaysAgo.getDate()).padStart(2, '0');
      this.sixDaysAgoFormatted = `${sixDaysAgoYear}${sixDaysAgoMonth}${sixDaysAgoDay}`;

      this.sms_success_count_week = [0, 0, 0, 0, 0, 0, 0];
      this.lms_success_count_week = [0, 0, 0, 0, 0, 0, 0];
      this.mms_success_count_week = [0, 0, 0, 0, 0, 0, 0];
      this.kakao_success_count_week = [0, 0, 0, 0, 0, 0, 0];

      const daily_payload = {
        from_date: this.sixDaysAgoFormatted,
        to_date: this.currentDate,
        request_tenant_id: tenant,
      };

      ApiCall.call('message/count/success-fail/daily', 'POST', daily_payload)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {

            // Loop through the response data and update sms_success_count_week array
            for (let i = 0; i < response.data.length; i++) {
              this.sms_success_count_week[i] = response.data[i].sms_success_count;
              this.lms_success_count_week[i] = response.data[i].lms_success_count;
              this.mms_success_count_week[i] = response.data[i].mms_success_count;
              this.kakao_success_count_week[i] = response.data[i].kakao_success_count;
            }
            // Set the dataLoaded flag to true
            this.sms_data_loaded = true;
            this.lms_data_loaded = true;
            this.mms_data_loaded = true;
            this.kakao_data_loaded = true;

          } else {
            this.sms_data_loaded = true;
            this.lms_data_loaded = true;
            this.mms_data_loaded = true;
            this.kakao_data_loaded = true;
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });
    },
    async setCalendarDataReference(tenant_id, year_month,reference_id){
      const calendar_payload = {
        year_month: year_month,
        request_tenant_id: tenant_id,
        reference_id: reference_id,
      };
      this.events = []
      ApiCall.call('calendar/event/month/reference', 'POST', calendar_payload)
        .then(response => {
          // console.log("response=",response);
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.events = response.data;
            // console.log('this.events :' + JSON.stringify(this.events));
            this.calendar_data_loaded = true;
          } else {
            this.calendar_data_loaded = true;
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });
    },
    async setCalendarData(tenant, year_month) {
      // console.log('initialDate :' + JSON.stringify(this.initialDate));
      const calendar_payload = {
        year_month: year_month,
        request_tenant_id: tenant,
      };
      this.events = []
      ApiCall.call('calendar/event/month', 'POST', calendar_payload)
        .then(response => {
          // console.log(response);
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.events = response.data;
            // console.log('this.events :' + JSON.stringify(this.events));
            this.calendar_data_loaded = true;
          } else {
            this.calendar_data_loaded = true;
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });
    },
    async getTermsAndAgreementData() {
      // console.log('initialDate :' + JSON.stringify(this.initialDate));
      const terms_payload = {};
      this.events = []

      try {
        const response = await ApiCall.call('terms/list', 'POST', terms_payload);
        const responseData = response.data;
        // console.log(responseData);
        // Retrieve the 'agree' array
        const agreeData = responseData.data.agree;

        // Retrieve the 'rate' array
        const rateData = responseData.data.rate;

        // this.previousPrivacyAgree = agreeData.some(item => item.agree_type === 'privacy');
        this.previousTermsAgree = agreeData.some(item => item.agree_type === 'terms');
        this.previousServiceAgree = agreeData.some(item => item.agree_type === 'service');
        this.previousFromPhoneNumberManageAgree = agreeData.some(item => item.agree_type === 'from_phone_number_manage');
        this.previousSpamResponsibilityAgree = agreeData.some(item => item.agree_type === 'spam_responsibility');
        this.previousCreditCheckAgree = agreeData.some(item => item.agree_type === 'credit_check');
        // this.previousThirdPartyUseAgree = agreeData.some(item => item.agree_type === 'third_party_use');
        this.previousInformationUseAgree = agreeData.some(item => item.agree_type === 'information_use');

        this.sms_unit_cost = rateData[0].sms;
        this.lms_unit_cost = rateData[0].lms;
        this.mms_unit_cost = rateData[0].mms;
        this.kakao_unit_cost = rateData[0].at;
        this.email_unit_cost = rateData[0].email;
        this.push_unit_cost = rateData[0].push;
        this.sms_intl_unit_cost = rateData[0].sms_intl;
        this.lms_intl_unit_cost = rateData[0].lms_intl;

        if (!(this.previousTermsAgree
          & this.previousServiceAgree
          & this.previousFromPhoneNumberManageAgree
          & this.previousSpamResponsibilityAgree
          & this.previousCreditCheckAgree
          & this.previousInformationUseAgree))
          this.openPopup();
      } catch (error) {
        console.error('Error: Unable to fetch data from API', error);
      }
    },
    async setLoginInformation() {
      const user = await Auth.currentAuthenticatedUser()
      this.isLogin = true;
      const idToken = user.signInUserSession.idToken
      const tenantId = idToken.payload['cognito:groups'][0]
      const isAdmin = idToken.payload['custom:isAdmin']
      const user_id = user.username;
      this.user_id = user_id;
      // console.log(tenantId)
      // console.log(user.username)
      if (tenantId === '0') {
        this.isOperator = true;
      }
      if (isAdmin === '1') {
        this.isAdmin = true;
      }
    },
    async setNoticeData() {
      const notice_payload = {};
      ApiCall.call('notice/list', 'POST', notice_payload).then(res => {
        // console.log('notice/list res :', res)
        if (res.data.data.length !== 0) {
          const noticeList = res.data.data.map(item => {
            let iconClass = '';
            let primary_image = '';
            let primary_image_color = '';
            let secondary = '';
            let secondary_color = 'secondary';
            //success(green), info(blue), warning(orange), danger(red), primary(red), secondary(grey), dark(black)

            return {
              primary: item.subject,
              values: [

              ],
              secondary: formatDate(item.sk),
              secondary_color: secondary_color,
              info: item.content.replace(/\|/g, '\n'),
              image: primary_image,
              image_color: primary_image_color,
              icon: '',
            };

            function formatDate(dateString) {
              const year = dateString.substring(0, 4);
              const month = dateString.substring(4, 6);
              const day = dateString.substring(6, 8);
              const hour = dateString.substring(8, 10);
              const minute = dateString.substring(10, 12);
              const second = dateString.substring(12, 14);

              // return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
              return `${year}-${month}-${day}`;
            }
          });

          this.noticeList = noticeList;
        }
      });
    }
  },
};
</script>

<style scoped>
.spinner-container {
  z-index: 9999;
  /* Set a high z-index value */
}
</style>
