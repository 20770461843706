<template>
  <div v-if="isLoading" class="progress-overlay">
    <div class="col-12 text-center">
      <div class="row">
        <h6 class="font-weight-bolder mb-4 text-white">🤖 AI 추천 이미지, 텍스트를 생성중입니다...</h6>
      </div>
      <div class="row">
        <div class="progress-bar">
          <material-progress :percentage="percent"></material-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialProgress from "@/components/MaterialProgress.vue";

export default {
  name: "ProgressBar",
  components: {
    MaterialProgress,
  },
  props: {
    isLoading: Boolean,
    percent: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style scoped>
.progress-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center; /* Center items vertically */
  z-index: 9999; /* Ensure it's above other content */
}

.progress-bar {
  width: 200px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
  background-color: #ffffff00; /* Progress bar color */
  border-radius: 10px;
  margin: 0 auto; /* Center horizontally */
}
</style>
