<template>
  <div id="send-history" class="card mt-4 me-2">
    <div class="card-header">
      <h6 class="mb-0">조회 조건</h6>
    </div>
    <div class="card-body pt-0">
      <div class="row align-items-end">
        <div class="col-5">
          <label class="form-label mt-0 ms-0">시작일</label>
          <material-input-vmodel
            id="fromDate"
            v-model="selectedStartDate"
            type="date"
          >
          </material-input-vmodel>
        </div>
        <div  class="col-5">
          <label class="form-label mt-0 ms-0">종료일</label>
          <material-input-vmodel
            id="fromDate"
            v-model="selectedEndDate"
            type="date"
          >
          </material-input-vmodel>
        </div>
        <div class="col-2 text-end mb-2">
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;" @click="downloadUtm">
            다운로드
          </material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import ApiCall from "@/Interface/ApiCall";
import * as Choices from "choices.js";
import { Auth } from "aws-amplify";
import Papa from 'papaparse';


import { Header, Item } from "vue3-easy-data-table";
import MaterialInputVmodel from "@/components/MaterialInputVmodel.vue";

export default {
  name: "UtmList",
  components: {
    MaterialButton,
    MaterialInputVmodel,
  },
  data() {
    return {
      isOperator: false,
      selectedStartDate: this.getCurrentDate(),
      selectedEndDate: this.getCurrentDate(),
      utmList: [],
      utmListConverted: [],
    }
  },
  computed: {
    formattedStartDate() {
      return this.selectedStartDate.replace(/-/g, '');
    },
    formattedEndDate() {
      return this.selectedEndDate.replace(/-/g, '');
    },
  },
  methods: {
    getDates(startDate, endDate) {
      const dateList = [];
      let currentDate = new Date(startDate.substring(0, 4), startDate.substring(4, 6) - 1, startDate.substring(6, 8));

      while (currentDate <= new Date(endDate.substring(0, 4), endDate.substring(4, 6) - 1, endDate.substring(6, 8))) {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        dateList.push(`${year}${month}${day}`);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dateList;
    },
    getCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    convertToDictList() {
      // Get a list of all keys present in the API response
      const allKeys = this.utmList.reduce((keys, obj) => {
        Object.keys(obj).forEach(key => {
          if (!keys.includes(key)) {
            keys.push(key);
          }
        });
        return keys;
      }, []);

      // Create a dictionary for each object in the list with all keys
      this.utmListConverted = this.utmList.map(obj => {
        const dict = {};
        allKeys.forEach(key => {
          dict[key] = obj[key] || ""; // Set to empty string if key does not exist
        });
        return dict;
      });
    },
    exportCSV() {
      // First, decode URIs in the dictionary list
      const decodedList = this.utmListConverted;

      // Convert the decoded list to CSV with PapaParse
      const csv = Papa.unparse(decodedList, {
        quotes: true,  // Enable quotes around values
      });

      // Create and trigger the download link with UTF-8 BOM
      const csvBlob = new Blob(["\ufeff", csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(csvBlob);
      link.setAttribute('download', 'list.csv');
      document.body.appendChild(link);
      link.click();
    },
    downloadUtm() {
      console.log('start date :', this.formattedStartDate)
      console.log('end date :', this.formattedEndDate)

      const dateList = this.getDates(this.formattedStartDate, this.formattedEndDate)
      console.log('list :', dateList)

      if (this.formattedEndDate < this.formattedStartDate) {
        alert('시작일을 종료일보다 과거로 지정해주세요.')
        return;
      }

      let payload = {
        date_list: dateList,
      };

      ApiCall.call('analytics/utmlist', 'POST', payload).then(response => {
        console.log('utm list : ', response.data)
        this.utmList = response.data.list;

        this.convertToDictList();
        this.exportCSV();
      }).catch(error => {
        console.error('Error: Unable to fetch data from API : ' + error);
      });
    },
  },
};
</script>
