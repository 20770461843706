<template>
  <div class="chart">
    <canvas :id="id" class="chart-canvas" :height="height"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import { debounce } from "lodash";

export default {
  name: "PieChart",
  props: {
    id: {
      type: String,
      default: "chart-pie",
    },
    height: {
      type: [String, Number],
      default: "200",
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Object,
        label: String,
        data: Array,
      },
    },
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    chart: {
      deep: true,
      handler: debounce(function () {
        this.renderChart();
      }, 1000) // Debounce for 1000 milliseconds (1 second)
    }
  },
  methods: {
    renderChart() {
      var pieChart = document.getElementById(this.id).getContext("2d");

      let chartStatus = Chart.getChart(this.id);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      new Chart(pieChart, {
        type: "pie",
        data: {
          labels: this.chart.labels,
          datasets: [
            {
              label: this.chart.datasets.label,
              weight: 9,
              cutout: 0,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: ["#03A9F4", "#e91e63", "#3A416F", "#a8b8d8"],
              data: this.chart.datasets.data,
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
      });
    }
  }
};
</script>
