<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-9 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
          ©
          {{ new Date().getFullYear() }}, made
          <!-- <i class="fa fa-heart"></i>  -->
          by
          <a
            href="https://www.sk.com"
            class="font-weight-bold"
            target="_blank"
            >SK 주식회사 C&C</a
          >
          </div>
          <span style="font-size: 0.8rem; margin-right: 0.5rem;">
          SK(주) | 대표이사: 장용호, 최태원 | 경기도 성남시 분당구 성남대로 343번길 9 | 사업자등록번호 783-85-00169 | 통신판매업신고 2015-경기성남-1388 <br> COPYRIGHT SK HOLDINGS CO., LTD. ALL RIGHTS RESERVED.
          </span>
        </div>
        <div class="col-lg-3">
          <ul
                      class="nav nav-footer"
                    >
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 이용 약관_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >이용약관</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 수준 약정서(SLA)_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >서비스수준(SLA)</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/home/privacy"
                          class="nav-link pe-0 text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 1rem;"
                          >개인정보처리방침</a
                        >
                      </li>
                    </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
<style>
  .fa-heart {
    color: red;
  }
</style>