<template>
  <div class="container-fluid mt-4">
    <div class="row align-items-center">
      <div class="col-lg-4 col-sm-8">
        <TestSmsNavPill @selectForm="selectedForm = $event" />
      </div>
    </div>
    <div class="row mb-5">
      <TestSmsForm v-if="selectedForm === 'sms'" />      
    </div>
  </div>
</template>

<script>
import TestSmsNavPill from "./components/TestSmsNavPill.vue";
import TestSmsForm from "./components/TestSmsForm.vue";

export default {
  name: "Settings",
  components: {
    TestSmsNavPill,
    TestSmsForm,
  },
  data() {
    return {
      selectedForm: "sms", // default selected form
    };
  },
};
</script>