<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h1 class="font-weight-bolder mb-0 text-center text-gradient text-warning">You're All Set!</h1>
    <h5 class="mt-4 text-center">발송 준비가 완료되었습니다.</h5>
    <div class="multisteps-form__content">
      <div class="row mt-5">
        <campaign-form
          id="campaign-form"
          :campaign-info="campaignInfo"
        >
        </campaign-form>
      </div>
      <div class="button-row d-flex mt-4">
        <div class="col-4">
          <material-button-progress
            type="button"
            color="light"
            variant="gradient"
            class="js-btn-prev"
            @click="$parent.prevStep"
          >뒤로</material-button-progress
          >
        </div>
        <div class="col-4"></div>
        <div class="col-4">
          <div class="row">
            <div class="col-6 text-end">
              <material-button-progress
                type="button"
                color="dark"
                variant="gradient"
                class="ms-auto mb-0 js-btn-next"
                :is-loading="isRegisterLoading"
                @click="registerCampaign"
              >저장</material-button-progress
              >
            </div>
            <div class="col-6 text-end">
              <material-button-progress
                type="button"
                color="dark"
                variant="gradient"
                class="ms-auto mb-0 js-btn-next"
                :disabled="!isRegistered"
                :is-loading="isRequestLoading"
                @click="requestCampaign"
              >요청</material-button-progress
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import * as Choices from "choices.js";
import ApiCall from "@/Interface/ApiCall";
import CampaignForm from "@/components/CampaignForm.vue";
import MaterialButtonProgress from "@/components/MaterialButtonProgress.vue";

export default {
  name: "Preview",
  components: {
    MaterialButtonProgress,
    CampaignForm,
  },
  props: {
    campaignInfo: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:campaignInfo'],
  data() {
    return {
      segmentOptions: [],
      dbSelectInstance: null,
      checkedNames: {},
      register_result: {},
      isRegisterLoading: false,
      isRequestLoading: false,
      isRegistered: false,
    }
  },
  computed: {
  },
  watch: {
    checkedNames(newList) {
      console.log("checkedNames : " + JSON.stringify(this.checkedNames))
    },
    segmentOptions(newList) {
      console.log("segmentOptions changed : " + JSON.stringify(this.segmentOptions));
    }
  },
  methods: {
    async registerCampaign() {
      this.isRegisterLoading = true;

      if (this.campaignInfo.send_type === 'mms') {
        await ApiCall.call('upload/image', 'POST', null, null, this.campaignInfo.final_image_file).then(response => {
          this.$emit('update:campaignInfo', {
            ...this.campaignInfo,
            image_url1: response.data.file_key.toString(),
            s3_uri: response.data.s3_uri.toString(),
          });
        }).catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });
      }

      // 캠페인등록
      console.log('register campaign');

      const payload = {
        approve_tenant_id: this.campaignInfo.approve_tenant_id,
        approve_tenant_name: this.campaignInfo.approve_tenant_name,
        campaign_name: this.campaignInfo.secondary_customer_reference_id,
        campaign_object: this.campaignInfo.campaign_object,
        campaign_purpose: this.campaignInfo.campaign_purpose,
        campaign_status: 'save',
        contact_address: this.campaignInfo.contact_address,
        contact_phone_number: this.campaignInfo.contact_phone_number,
        image_url1: this.campaignInfo.image_url1,
        landing_url: this.campaignInfo.landing_url,
        secondary_customer_reference_id: this.campaignInfo.secondary_customer_reference_id,
        send_request_dtm: this.campaignInfo.send_request_dtm,
        send_type: this.campaignInfo.send_type,
        sent_content: this.campaignInfo.sent_content,
        from_phone_number: this.campaignInfo.from_phone_number,
        target_segment: this.campaignInfo.target_segment,
        keywords: this.campaignInfo.keywords,
        subject: this.campaignInfo.subject,
        service_type: this.campaignInfo.service_type,
        sent_keyword: this.campaignInfo.sent_emphasize_keyword,
        target_count: this.campaignInfo.target_count,
        s3_uri: this.campaignInfo.s3_uri,
      }

      await ApiCall.call('marketing/campaign/register', 'POST', payload).then(response => {
        if (response.status === 200) {
          console.log("register response : " + JSON.stringify(response));

          this.isRegistered = true;

          this.register_result = { pk: response.data.data.pk, sk: response.data.data.sk };
        } else {
          console.error("등록에 실패했습니다.");
        }
      }).catch(error => {
        console.error('Error: Unable to fetch data from API', error);
      });

      this.isRegisterLoading = false;
    },
    async requestCampaign() {
      this.isRequestLoading = true;
      console.log('request campaign');

      const payload = {
        pk: this.register_result.pk,
        sk: this.register_result.sk,
      }

      await ApiCall.call('marketing/campaign/request', 'POST', payload).then(response => {
        console.log("register response : " + JSON.stringify(response));

        alert("요청이 완료 되었습니다. 담당자 승인 후 전송결과를 알림톡으로 알려드립니다.")
      }).catch(error => {
        console.error('Error: Unable to fetch data from API', error);
      });
      this.isRequestLoading = false;
    },
  },
};
</script>
