<template>
  <button
    class="btn mb-0"
    :disabled="isLoading || disabled"
    :class="getClasses(variant, color, size, fullWidth, active)"
  >
    <template v-if="isLoading">
      <!-- Render a progress circle here when isLoading is true -->
      <div class="spinner-border custom-spinner" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </template>
    <template v-else>
      <!-- Render button content when isLoading is false -->
      <slot />
    </template>
  </button>
</template>

<script>
export default {
  name: "MaterialButton",
  props: {
    color: {
      type: String,
      default: "success",
    },
    size: {
      type: String,
      default: "md",
    },
    variant: {
      type: String,
      default: "fill",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getClasses: (variant, color, size, fullWidth, active) => {
      let classes = [];

      // Setting the button variant and color
      if (variant === "gradient") {
        classes.push(`bg-gradient-${color}`);
      } else if (variant === "outline") {
        classes.push(`btn-outline-${color}`);
      } else {
        classes.push(`btn-${color}`);
      }

      if (size) {
        classes.push(`btn-${size}`);
      }

      if (fullWidth) {
        classes.push(`w-100`);
      }

      if (active) {
        classes.push(`active`);
      }

      return classes.join(" ");
    },
  },
};
</script>

<style scoped>
/* Adjust the size of the custom spinner */
.custom-spinner {
  width: 1.0rem; /* You can adjust the width and height to your desired size */
  height: 1.0rem;
  border-width: 0.2em; /* You can adjust the border width for thickness */
}
</style>
