<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-9">
          <div class="row">
            <div class="col-12 col-lg-12 m-auto">
              <div class="card">
                <div
                  class="card-header d-flex justify-content-end"
                >
                  <div class="col-4">
                    <h5 v-if="activeStep === 0">시작하기</h5>
                    <h5 v-if="activeStep === 1">생성하기</h5>
                    <h5 v-if="activeStep === 2">생성하기</h5>
                    <h5 v-if="activeStep === 3">수정하기</h5>
                    <h5 v-if="activeStep === 4">발송하기</h5>
                    <h5 v-if="activeStep === 5">발송하기</h5>
                  </div>
                  <div class="col-4"></div>
                  <div class="col-4">
                    <div class="multisteps-form__progress">
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="DefaultSetting"
                        :class="[activeStep > 0 ? activeClass : '', activeStep === 0 ? currentClass : '']"
                        data-step="1"
                        @click="handleStepClick(0)"
                      >
                        <span>시작</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="CreateInfo"
                        :class="[activeStep > 1 ? activeClass : '', activeStep === 1 ? currentClass : '']"
                        data-step="2"
                        @click="handleStepClick(1)"
                      >
                        <span>생성</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="TargetSelect"
                        :class="[activeStep > 2 ? activeClass : '', activeStep === 2 ? currentClass : '']"
                        data-step="3"
                        @click="handleStepClick(2)"
                      >
                        <span>선택</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="ModifyInfo"
                        :class="[activeStep > 3 ? activeClass : '', activeStep === 3 ? currentClass : '']"
                        data-step="4"
                        @click="handleStepClick(3)"
                      >
                        <span>수정</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Preview"
                        :class="[activeStep > 4 ? activeClass : '', activeStep === 4 ? currentClass : '']"
                        data-step="5"
                        @click="handleStepClick(4)"
                      >
                        <span>설정</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Preview"
                        :class="[activeStep > 5 ? activeClass : '', activeStep === 5 ? currentClass : '']"
                        data-step="6"
                        @click="handleStepClick(5)"
                      >
                        <span>발송</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <default-setting
                    v-model:campaign-info="campaignInfo"
                    :class="activeStep === 0 ? activeClass : ''" />
                  <create-info
                    v-model:campaign-info="campaignInfo"
                    :class="activeStep === 1 ? activeClass : ''" />
                  <marketing-select
                    v-model:campaign-info="campaignInfo"
                    :class="activeStep === 2 ? activeClass : ''" />
                  <modify-info
                    v-model:campaign-info="campaignInfo"
                    :class="activeStep === 3 ? activeClass : ''"/>
                  <target-select
                    v-model:campaign-info="campaignInfo"
                    :class="activeStep === 4 ? activeClass : ''" />
                  <preview
                    v-model:campaign-info="campaignInfo"
                    :class="activeStep === 5 ? activeClass: ''"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultSetting from "@/views/pages/marketing/components/DefaultSetting.vue";
import CreateInfo from "@/views/pages/marketing/components/CreateInfo.vue";
import ModifyInfo from "@/views/pages/marketing/components/ModifyInfo.vue";
import TargetSelect from "@/views/pages/marketing/components/TargetSelect.vue";
import Preview from "@/views/pages/marketing/components/Preview.vue";
import MarketingSelect from "@/views/pages/marketing/components/MarketingSelect.vue";

export default {
  name: "Register Campaign",
  components: {
    // TargetSelect,
    MarketingSelect,
    Preview,
    ModifyInfo,
    CreateInfo,
    DefaultSetting,
    TargetSelect,
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      currentClass: "js-current position-relative",
      activeStep: 0,
      formSteps: 5,
      campaignInfo: {
        campaign_name: '',
        send_type: 'mms',
        campaign_object: '',
        service_type: '',
        campaign_purpose: '인지도',
        campaign_description: '',
        landing_url: '',
        contact_address: '',
        contact_phone_number: '',
        graphic_style: '3D',
        graphic_effect: '기본',
        color_tone: '네이비',
        marketing_text_tone: '신뢰있고 정중하게',
        text_length: '짧게',
        marketing_content_type: '리스트형',
        final_image_url: '',
        final_image_file: null,
        sent_content: '',
        subject: '',
        keywords: [],
        sent_emphasize_keyword: '',
        titles: [],
        contents: [],
        image_files: [],
        image_file_urls: [],
        approve_tenant_id: '',
        approve_tenant_name: '',
        total_count: '',
        image_url1: '',
        image_info: [],
        target_segment: {},
        target_count: 0,
        from_phone_number: '',
        send_request_dtm: '',
        original_send_request_dtm: '',
        secondary_customer_reference_id: '',
        confirm_step: 0,
        s3_uri: '',
      },
    };
  },
  watch: {
    campaignInfo(newValue) {
      console.log(JSON.stringify(newValue));
      console.log(JSON.stringify(newValue.contents));
    },
  },
  methods: {
    nextStep() {
      if (this.activeStep < this.formSteps) {
        if (this.activeStep < this.campaignInfo.confirm_step) {
          this.activeStep += 1;
          console.log(this.activeStep);
        } else {
          alert("현재 단계를 완료 하고 다음단계로 진행해주세요.")
        }
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
        console.log(this.activeStep);
      }
    },
    handleStepClick(step) {
      console.log("move to " + step + "step")
      if (step <= this.campaignInfo.confirm_step) {
        this.activeStep = step;
      } else {
        alert("현재 단계를 완료 하고 다음단계로 진행해주세요. 현재 단계로 이동합니다.")
        this.activeStep = this.campaignInfo.confirm_step;
      }
    }
  },
};
</script>
