<template>
  <div>
    <h3>
      Nice Certifying redirecting...
    </h3>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Certify",
  beforeMount() {
    // this.toggleEveryDisplay();
    // this.toggleHideConfig();
  },
  mounted() {
    this.passParameterToParent(window.location.search);
  },
  beforeUnmount() {
    // this.toggleEveryDisplay();
    // this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    passParameterToParent(queryString) {
      // Access the parent window and pass the parameter
      window.opener.postMessage(queryString, window.location.origin);

      // Close the child window
      window.close();
    }
  },
};
</script>

<style scoped>

</style>
