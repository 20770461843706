<template>
  <div
    class="input-group"
    :class="`input-group-${variant} ${getStatus(error, success)}`"
  >
    <label :class="variant === 'static' ? '' : 'form-label'">{{ label }}</label>
    <input
      :id="id"
      v-model="inputValue"
      :type="type"
      class="form-control"
      :class="getClasses(size)"
      :name="name"
      :placeholder="placeholder"
      :isRequired="isRequired"
      :disabled="disabled"
      :autocomplete="autoComplete"
      @change="handleInputChange"
    />
    <input
      ref="fileInput"
      type="file"
      style="display: none"
      @change="createFileLink"
    />
    <material-button
      size="sm"
      type="button"
      variant="outline"
      :disabled="isUrlCreated"
      @click="createUrlLink"
    >
      URL
    </material-button>
    <material-button
      size="sm"
      type="button"
      variant="outline"
      @click="openFileInput"
    >
      File
    </material-button>
  </div>
</template>

<style>
.input-group {
  position: relative;
}

.copy-button {
  position: absolute;
  right: 0;
  top: 75%;
  transform: translateY(-50%);
}
</style>


<script>
import setMaterialInput from "@/assets/js/material-input.js";
import MaterialButton from "@/components/MaterialButton.vue";
import ApiCall from "@/Interface/ApiCall";

export default {
  name: "URLShortner",
  components: { MaterialButton },
  props: {
    variant: {
      type: String,
      default: "outline",
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "sm",
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    autoComplete: {
      type: String,
      default: 'new-password',
    },
    button: {
      type: Boolean,
      default: false,
    },
    buttonIcon: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-prop-types
    callback: {
      default: ''
    },
    buttonColor: {
      type: String,
      default: 'grey',
    },
  },
emits: ['url-created'],
  data() {
    return {
      inputValue: '',
      url: '',
      linkType: '',
      isUrlCreated: false,
    }
  },
  computed: {
  },
  mounted() {
    setMaterialInput();
  },
  methods: {
    checkUrlFormat() {
    },
    createUrlLink() {
      // console.log("url link create : ", this.urlLink)

      if(this.inputValue.length === 0) {
        alert("url을 입력해주세요.")
      } else {
        this.url = this.inputValue;
        this.linkType = 'url';
        this.$emit('url-created', "to-go.io/ABc1", this.url, this.linkType, '')
      }

    },
    openFileInput() {
      this.$refs.fileInput.click(); // Trigger click on the hidden file input
    },
    async createFileLink(event, file_type) {
      const file = event.target.files[0]
      console.log('file:', file)


      if (file) {
        const payloadFile = {
          file_type: file_type,
          upload_type: 'link',
        };
        console.log(payloadFile);
        try {
          const fileResponse = await ApiCall.file('upload/file', 'POST', payloadFile, null, file, null, false);
          console.log(fileResponse)
          if (fileResponse.status === 200) {
            console.log(fileResponse.data.item_list);
            const objectKey = fileResponse.data.item_list[0].object_key;
            const fileName = fileResponse.data.item_list[0].file_name;

            // console.log("object key : ", objectKey)
            // presigned url 발급
            const presigned_url_payload = {
              "object_key": objectKey,
            }
            ApiCall.call('link/s3', 'POST', presigned_url_payload).then((res) => {
              this.inputValue = fileName
              this.url = res.data.presigned_url;
              this.linkType = 'file'
              this.$emit('url-created', "to-go.io/ABc1", this.url, this.linkType, fileName)
            }).catch((e) => {
              console.log("presigned url 발급 오류 :", e)
            });
          } else {
            throw new Error(fileResponse.message)
          }
        } catch (error) {
          console.error(error);
          if (error.response.status === 599) {
            alert("파일 전송 실패 :" + "업로드 할 수 없는 파일입니다.");
          } else {
            alert("파일 전송 실패 :" + error.message);
          }
        }
      }
    },
    handleInputChange() {
      this.isUrlCreated = false;
    },
    getClasses: (size) => {
      let sizeValue;

      sizeValue = size ? `form-control-${size}` : null;

      return sizeValue;
    },
    getStatus: (error, success) => {
      let isValidValue;

      if (success) {
        isValidValue = "is-valid";
      } else if (error) {
        isValidValue = "is-invalid";
      } else {
        isValidValue = null;
      }

      return isValidValue;
    },
    setHandCursor() {
      this.$el.style.cursor = 'pointer';
    },
    // Method to set the default cursor
    setDefaultCursor() {
      this.$el.style.cursor = 'default';
    },
  },
};
</script>
