<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h6 class="font-weight-bolder mb-4">📋 기본설정</h6>
    <div class="multisteps-form__content">
      <div class="col-11">
        <div class="row mt-4">
          <p class="text-sm font-weight-bold">메시지타입을 선택해주세요.</p>
          <campaign-nav-pill
            id="messageTypeSelect"
            :select-items="messageTypes"
            :box-height=53
            name="messageType"
            @selectForm="updateMessageType($event)"
          >
          </campaign-nav-pill>
        </div>
        <div class="row mt-4">
          <p class="text-sm font-weight-bold">광고 대상을 입력해 주세요.</p>
          <div class="col-12">
            <material-input-vmodel
              id="campaign-name-default-setting"
              :model-value="campaignInfo.campaign_object"
              placeholder="제품, 서비스, 브랜드 등 광고 대상의 명칭 입력"
              @input="$emit('update:campaignInfo', { ...campaignInfo, campaign_object: $event.target.value })"
            />
          </div>
        </div>
        <div class="row mt-4">
          <p class="text-sm font-weight-bold">업종 유형을 입력해 주세요.</p>
          <div class="col-12">
            <material-input-vmodel
              id="campaign-name-default-setting"
              :model-value="campaignInfo.service_type"
              placeholder="광고 대상의 업종 유형 입력 (예: 음식점 또는 파스타집, 학원 또는 중등수학학원)"
              @input="$emit('update:campaignInfo', { ...campaignInfo, service_type: $event.target.value })"
            />
          </div>
        </div>
        <div class="row mt-4">
          <p class="text-sm font-weight-bold">광고 목적을 선택해주세요.</p>
          <campaign-nav-pill
            id="purposeSelect"
            :select-items="campaignPurposes"
            :box-height=53
            name="purpose"
            @selectForm="updateCampaignPurpose($event)"
          >
          </campaign-nav-pill>
        </div>
        <div class="row mt-4">
          <p class="text-sm mb-1 font-weight-bold">특장점을 입력해 주세요.</p>
          <p class="text-xs">광고할 대상의 주요 특징과 강조하고 싶은 장점 등을 자유롭게 적어주세요.</p>
          <material-text-area
            id="campaign-description-default-setting"
            :model-value="campaignInfo.campaign_description"
            placeholder="광고할 대상의 주요 특징을 자유롭게 적어주세요."
            @input="$emit('update:campaignInfo', { ...campaignInfo, campaign_description: $event.target.value })"
          />
        </div>
        <div class="row mt-4">
          <p class="text-sm font-weight-bold">랜딩페이지 주소를 입력해 주세요. (선택)</p>
          <div class="col-12">
            <material-input-vmodel
              id="landing-url-default-setting"
              :model-value="campaignInfo.landing_url"
              placeholder="홈페이지 또는 웹페이지를 입력 (예: https://message.to-go.io)"
              @input="$emit('update:campaignInfo', { ...campaignInfo, landing_url: $event.target.value })"
            />
          </div>
        </div>
        <div class="row mt-4">
          <p class="text-sm font-weight-bold">방문 주소를 입력해 주세요. (선택)</p>
          <div class="col-12">
            <material-input-vmodel
              id="contact-address-default-setting"
              :model-value="campaignInfo.contact_address"
              placeholder="오프라인 매장 방문 안내가 필요한 경우 주소를 입력 (예: 서울시 종로구 종로 26)"
              @input="$emit('update:campaignInfo', { ...campaignInfo, contact_address: $event.target.value })"
            />
          </div>
        </div>
        <div class="row mt-4">
          <p class="text-sm font-weight-bold">연락처를 입력해 주세요. (선택)</p>
          <div class="col-12">
            <material-input-vmodel
              id="contact-phone-number-default-setting"
              :model-value="campaignInfo.contact_phone_number"
              placeholder="입력한 연락처는 메시지 내 문의 전화번호로 삽입"
              @input="$emit('update:campaignInfo', { ...campaignInfo, contact_phone_number: $event.target.value })"
            />
          </div>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button-progress
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          :is-loading="isKeywordLoading"
          @click="handleDefaultSettingConfirm"
        >입력완료</material-button-progress
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInputVmodel from "@/components/MaterialInputVmodel.vue";
import MaterialButtonProgress from "@/components/MaterialButtonProgress.vue";
import CampaignNavPill from "@/views/pages/marketing/components/CampaignNavPill.vue";
import MaterialTextArea from "@/components/MaterialTextareaCustom.vue";
import ApiCall from "@/Interface/ApiCall";

export default {
  name: "DefaultSetting",
  components: {
    MaterialTextArea,
    CampaignNavPill,
    MaterialInputVmodel,
    MaterialButtonProgress,
  },
  props: {
    campaignInfo: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:campaignInfo'],
  data() {
    return {
      isKeywordLoading: false,
      messageTypes: [
        {
          type: "mms",
          name: "MMS",
          description: "이미지와 장문 텍스트",
        },
        {
          type: "lms",
          name: "LMS",
          description: "장문 텍스트",
        },
        {
          type: "sms",
          name: "SMS",
          description: "단문 텍스트",
        },
      ],
      campaignPurposes: [
        {
          type: "인지도",
          name: "인지도",
          description: "제품, 서비스 홍보",
        },
        {
          type: "판매",
          name: "판매",
          description: "방문자 유치 및 매출 증대",
        },
        {
          type: "잠재고객",
          name: "잠재고객",
          description: "잠재고객 유치",
        },
        {
          type: "트래픽",
          name: "트래픽",
          description: "랜딩 페이지 접속량 증대",
        },
        {
          type: "앱 홍보",
          name: "앱 홍보",
          description: "앱 다운로드 유도",
        },
      ],
    };
  },
  methods: {
    updateMessageType(selectedType) {
      this.$emit('update:campaignInfo', { ...this.campaignInfo, send_type: selectedType});
    },
    updateCampaignPurpose(selectedType) {
      this.$emit('update:campaignInfo', { ...this.campaignInfo, campaign_purpose: selectedType});
    },
    async handleDefaultSettingConfirm() {
      this.isKeywordLoading = true;

      if (!this.validateInput()) {
        this.isKeywordLoading = false;
      } else {
        const payload = {
          campaign_description: this.campaignInfo.campaign_description,
        }
        // 키워드 api 호출
        await ApiCall.call('marketing/campaign/keywords', 'POST', payload).then(response =>{
          if (response.status === 200) {
            console.log("response : " + JSON.stringify(response))
            console.log("keywords :" + response.data.data.keywords);

            this.$emit('update:campaignInfo', {
              ...this.campaignInfo,
              keywords: response.data.data.keywords,
              sent_emphasize_keyword: response.data.data.keywords[0],
              confirm_step: 1,
            });
            this.isKeywordLoading = false;
            this.$parent.nextStep();
          } else {
            alert("키워드 추출 실패: " + response.data);
            this.isKeywordLoading = false;
          }
        });
      }
    },
    validateInput() {
      if (this.campaignInfo.campaign_object.length === 0 ||
        this.campaignInfo.service_type.length === 0 ||
        this.campaignInfo.campaign_description.length === 0) {
        alert('정보를 모두 입력해주세요.')
        return false; // Validation failed
      } else {
        return true;
      }
    }
  }
};
</script>
