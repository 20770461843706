<template>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="keywords" content="SK(주) C&C에서 만든 대량 문자 발송 솔루션 Message To-Go에서 쉽고 빠르게 대량 문자 발송과 단체 문자 보내기를 경험하세요. SMS, LMS, 알림톡 API 통합을 통해 기업 문자 서비스를 빠르고 효과적이게 이용하세요." />
    <meta property="og:locale" content="ko_KR" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="SK(주) C&C 대량 단체문자 발송 - Message To-Go | SMS, LMS, 알림톡 API 기업 문자 서비스" />
    <meta property="og:description" content="SK(주) C&C에서 만든 대량 문자 발송 솔루션 Message To-Go에서 쉽고 빠르게 대량 문자 발송과 단체 문자 보내기를 경험하세요. SMS, LMS, 알림톡 API 통합을 통해 기업 문자 서비스를 빠르고 효과적이게 이용하세요." />
    <meta property="og:url" content="https://message.to-go.io/home" />
    <meta property="og:site_name" content="SK(주) C&C 대량 단체문자 발송 - Message To-Go | SMS, LMS, 알림톡 API 기업 문자 서비스" />
    <title>SK(주) C&C 대량 단체문자 발송 - Message To-Go | SMS, LMS, 알림톡 API 기업 문자 서비스</title>
    <link rel="icon" href="https://to-go.io/sk_logo.jpg" type="image/jpg">
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-icons.css">
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-svg.css">
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/soft-ui-dashboard.min.css?v=1.0.2">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700">
    <link rel="stylesheet" href="./assets/css/theme.css">
</head>

<body class="g-sidenav-show">
    <div class="container position-sticky z-index-sticky top-0">
        <div class="row">
            <div class="col-12">
                <nav class="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
                  <div class="container-fluid">
                        <a class="navbar-brand ms-sm-3" href="/home">
                          <img src="../assets/img/SK_logo.svg" alt="Your SVG Image" style="position: relative; width: 30%; height: 20%; margin-top: -10px; margin-bottom: -10px; margin-right: 10px; margin-left: -10px;">
                        </a>
                        <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon mt-2">
                                <span class="navbar-toggler-bar bar1"></span>
                                <span class="navbar-toggler-bar bar2"></span>
                                <span class="navbar-toggler-bar bar3"></span>
                            </span>
                        </button>
                        <div id="navigation" class="collapse navbar-collapse pt-3 pb-2 py-lg-0">
                            <ul class="navbar-nav navbar-nav-hover ms-lg-12 ps-lg-5 w-100">
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" :to="`/home#channels`" aria-expanded="false">
                                    <span class="font-weight-bolder" style="font-size: larger;">채널소개</span>
                                  </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" :to="`/home#features`" aria-expanded="false">
                                    <span class="font-weight-bolder" style="font-size: larger;">기능</span>
                                  </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" :to="`/home#product`" aria-expanded="false">
                                    <span class="font-weight-bolder" style="font-size: larger;">요금제</span>
                                  </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" :to="`/home/contactus`" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">문의하기</span>
                                    </router-link>
                                </li>
                              <li class="nav-item">
                                <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" :to="`/home/news`" aria-expanded="false">
                                  <span class="font-weight-bolder" style="font-size: larger;">NEWS/소식</span>
                                </router-link>
                              </li>
                                <li class="nav-item ms-lg-auto my-auto ms-3 ms-lg-0">
                                  <router-link class="btn btn-sm  bg-gradient-primary  btn-round mb-0 me-1 mt-2 mt-md-0" :to="`/authentication/signin/basic`">
                                    회원가입/로그인
                                  </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <header>
      <div class="container mt-8">
        <h1>News/소식</h1>
      </div>
    </header>
    <article>
      <div class="container mt-6">
        <div class="row mt-5">
          <div v-for="(documentPath, index) in documentList" :key="index" class="col-lg-4 col-md-6">
            <blog-card class="my-6" :url="documentPath" />
          </div>
        </div>
      </div>
    </article>
  </body>
  <footer>
    <section class="py-5 position-relative">
      <div class="container">
        <div class="container">
          <br>
          <div class="container-fluid">
            <div class="row align-items-center justify-content-lg-between">
              <div class="mb-4 col-lg-9 mb-lg-0">
                <div
                  class="text-sm text-center copyright text-muted"
                  :class="$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
                >
                  ©
                  {{ new Date().getFullYear() }}, made
                  by
                  <a
                    href="https://www.sk.com"
                    class="font-weight-bold"
                    target="_blank"
                  >SK 주식회사 C&C</a
                  >
                </div>
                <span style="font-size: 0.8rem; margin-right: 0.5rem;">
          SK(주) | 대표이사: 장용호, 최태원 | 경기도 성남시 분당구 성남대로 343번길 9 | 사업자등록번호 783-85-00169 | 통신판매업신고 2015-경기성남-1388 <br> COPYRIGHT SK HOLDINGS CO., LTD. ALL RIGHTS RESERVED. (ver.1.2.0)
          </span>
              </div>
              <div class="col-lg-2">
                <ul
                  class="nav nav-footer"
                >
                  <li class="nav-item" style="margin-bottom: -10px;">
                    <a
                      href="/서비스 이용 약관_SK(주)_Message To-Go.pdf"
                      class="nav-link text-muted font-weight-bold"
                      target="_blank"
                      style="font-size: 0.8rem;"
                    >이용약관</a
                    >
                  </li>
                  <li class="nav-item" style="margin-bottom: -10px;">
                    <a
                      href="/서비스 수준 약정서(SLA)_SK(주)_Message To-Go.pdf"
                      class="nav-link text-muted font-weight-bold"
                      target="_blank"
                      style="font-size: 0.8rem;"
                    >서비스수준(SLA)</a
                    >
                  </li>
                  <li class="nav-item" style="margin-bottom: -10px;">
                    <a
                      href="/home/privacy"
                      class="nav-link pe-0 text-muted font-weight-bold"
                      target="_blank"
                      style="font-size: 1rem;"
                    >개인정보처리방침</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  </footer>
</template>

<script>
import { useStore } from 'vuex';
import BlogCard from "@/views/dashboards/components/BlogCard.vue";
import ApiCall from "@/Interface/ApiCall";
export default {
  name: 'News',
  components: {
    BlogCard,
  }, 
  data() {
    return {
      isUnderlined: false,
      selectedTab: 0,
      documentList: [],
      isDocumentLoaded: false,
    };
  },
  beforeMount() {
    const store = useStore();
    store.commit('toggleEveryDisplay', false);
  },
  beforeUnmount() {
    const store = useStore();
    store.commit('toggleEveryDisplay', true);
  },
  async mounted() {
    await this.getDocumentList();
    console.log("document list updated")
    this.isDocumentLoaded = true;
  },
  methods: {
    handleClick(preview) {
      console.log(
        "click",
        preview.domain,
        preview.title,
        preview.description,
        preview.img
      );
    },
    async getDocumentList() {
      ApiCall.callNoAuth('blog/list', 'POST').then(response=>{
        this.documentList = response.data;
      }).catch(error => {
        console.error('Error: Unable to fetch data from API', error);
      });
    },
    selectTab(tabId) {
      const tab = document.getElementById(tabId);
      if (tab) {
        const tabs = document.querySelectorAll('.nav-link');
        tabs.forEach((t) => {
          t.classList.remove('selected');
        });
        tab.classList.add('selected');
      }
    },
    getQueryString() {
      return window.location.search;
    }
  }
};

</script>

<style>
.hover-underline:hover {
  text-decoration: underline !important;
}
</style>

<style>
.selected {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
</style>

<style scoped>
/* If you are using scoped CSS, you can still apply the class */
.large-button {
  font-size: 50px;
  padding: 10px 20px;
}
</style>
