<template>
  <div class="row">
    <div v-for="value in options" :key="value" class="col-4">
      <label>
        <div className="radio-option text-center">
          <input v-model="selectedOption" type="radio" :value="value">
          <div className="radio-text" style="font-weight: 700">
            {{ value.title }}
          </div>
          <div class="mt-4"></div>
          <div className="radio-text" style="font-weight: 500">
            {{ value.content }}
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
emits: ['selected'],
  data() {
    return {
      selectedOption: this.options.length > 0 ? this.options[0] : null
    };
  },
  watch: {
    selectedOption(newValue) {
      // console.log('contents select changed : ' + JSON.stringify(newValue));
      this.$emit('selected', newValue);
    }
  }
};
</script>

<style>
/* Apply custom styles to radio buttons */
input[type="radio"] {
  -webkit-appearance: none; /* Remove default appearance */
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%; /* Create a circular shape */
  border: 2px solid grey; /* Set default border color */
  outline: none; /* Remove outline */
  cursor: pointer;
}

/* Style the checked state */
input[type="radio"]:checked {
  background-color: orange;
  border: 3px solid white;
  box-shadow: 0 0 0 1.6px orange;
}

/* Style the radio option container */
.radio-option {
  display: inline-block;
  border: 2px solid lightgray; /* Set border around the container */
  border-radius: 0.5rem;
  padding: 5px; /* Add padding to create space between border and content */
  margin-right: 10px;
}

/* Style the radio text */
.radio-text {
  text-align: center; /* Center the text */
}

.radio-label {
  display: inline-block; /* Set label to inline-block */
}
</style>
