<template>
  <div class="nav-wrapper position-relative end-0">
    <ul class="nav nav-pills nav-fill p-1" role="tablist" style="background-color: #dee2e6;">
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="selectTab('sms')"
          >SMS</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="false"
          @click="selectTab('lms')"
          >LMS</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="false"
          @click="selectTab('mms')"
          >MMS</a
        >
      </li>
      <!-- <li class="nav-item">
          <a
            class="nav-link mb-0 px-0 py-1"
            data-bs-toggle="tab"
            href="javascript:;"
            role="tab"
            aria-selected="false"
            @click="selectTab('pshtest')"
            >pshtest</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link mb-0 px-0 py-1"
            data-bs-toggle="tab"
            href="javascript:;"
            role="tab"
            aria-selected="false"
            @click="selectTab('osjtest')"
            >osjtest</a
          >
        </li> -->
    </ul>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "SmsNavPill",
  data() {
    return {
      currentTab: "sms",
    };
  },
  mounted() {
    setNavPills();
  },
  methods: {
    selectTab(tab) {
      this.currentTab = tab;
      this.$emit("select-form", tab);
    },
  },  
};
</script>
