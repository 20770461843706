<template>
  <div class="fixed-plugin">    
    <div class="text-overlay-container">
        <a class="px-3 py-2 fixed-plugin-button text-dark position-fixed chat-toggle" @click="toggle">
            <i class="material-icons py-2">chat</i>
            <span class="icon-text-overlay text-black font-weight-bold">AI 챗봇</span>      
        </a>
    </div>
    <div class="shadow-lg card">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Message To-Go 고객상담 Chatbot(Beta)</h5>
          <!-- <p>Feel free to ask anything.</p> -->
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="material-icons">clear</i>
          </button>
        </div>
      </div>
      <hr class="my-1 horizontal dark" />
      <div class="pt-0 card-body pt-sm-3">
        <!-- Chat content -->
        <div ref="chatMessagesContainer" class="chat-messages">
          <div v-for="(message, index) in chatMessages" :key="index" :class="`chat-message ${message.role}`">
            <material-button
              type="submit"
              :color="message.role === 'user' ? 'warning' : 'danger'"
              size="sm"
              variant="default"
              style="width: 100%; margin-top: 10px; text-align: left; text-transform: inherit;"
            >
            <span class="selectable-text" v-html="convertUrlsToLinks(message.content)"></span>
            </material-button>
          </div>
        </div>
        <div class="chat-input">
          <material-textarea id="content" v-model="newMessage" name="content" class="text-xs" placeholder="문의사항 입력..." style="width: 100%; height: 40px; resize: none;" @keyup.enter="sendMessage"></material-textarea>
          <material-button type="submit" color="success" size="sm" variant="default" style="width: 100px; margin-top: 10px" @click="sendMessage">
                전송
          </material-button>
          <material-button type="submit" color="success" size="sm" variant="default" style="width: 100px; margin-top: 10px; margin-left: 5px" @click="clearChat">
            대화초기화
          </material-button>
        </div>
      </div>
      <div v-if="isLoading" class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
    <div class="spinner-container">
<!--      <i class="fas fa-spinner fa-spin spinner-icon text-info"></i>-->
      <img
        src="@/assets/img/Spinner-1s-200px.gif"
        style="width: 100px; height: 100px;"
      />
    </div>
  </div>
    </div>
  </div>
  
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import { ref, nextTick } from 'vue';
import ApiCall from "@/Interface/ApiCall";
import AlbCall from "@/Interface/AlbCall";

export default {
  name: 'Chat',
  components: {
    MaterialButton,
    MaterialTextarea,
  },
  props: ['toggle'],
  setup() {
    const chatMessages = ref([{ role: 'assistant', content: '안녕하세요 Message To-Go 고객상담 Chatbot 입니다. \n 무엇을 도와 드릴까요?' }]);
    const newMessage = ref('');
    const chatMessagesContainer = ref(null);
    const isLoading = ref(false);
    const sendMessage = async () => {
      if (!newMessage.value.trim()) {
        return;
      }
      isLoading.value = true;
      let user_input = newMessage.value;
      // If the conversation has already begun, send thread_id and run_id with the message
      const messageToSend = {
        role: 'user',
        content: newMessage.value        
      };

      chatMessages.value.push(messageToSend);
      nextTick(() => {
        chatMessagesContainer.value.scrollTop = chatMessagesContainer.value.scrollHeight;
      });
      newMessage.value = ''; // Clear the input field
      console.log(chatMessages.value)

      const payload = {
          message: chatMessages.value
      }
      let responseMessage = '';
      try{
        const response = await AlbCall.call('chatbot', 'POST', payload);
        
        // const responseData = JSON.parse(response);
        console.log(response);
        responseMessage = response.data.response_text;

        // Extract and store the thread_id and run_id from the response

      } catch (error) {
        console.error(error);
        console.log("bypass");
        responseMessage = '죄송합니다. 다시 한번 말씀해 주시겠어요?'
        chatMessages.value.push({ role: 'assistant', content: responseMessage });
        // Handle any error that occurred during the API call
        isLoading.value = false;
        return true; // or throw an error if you want to handle it differently
      }

      chatMessages.value.push({ role: 'assistant', content: responseMessage });

      nextTick(() => {
        chatMessagesContainer.value.scrollTop = chatMessagesContainer.value.scrollHeight;
      });

      isLoading.value = false;
    };

    const clearChat = () => {
      chatMessages.value = [{ role: 'assistant', content: '안녕하세요 Message To-Go AI 상담원 입니다. 무엇을 도와 드릴까요?' }]; // Clear the chat history
    };

    return {
      chatMessages,
      newMessage,
      sendMessage,
      chatMessagesContainer,
      clearChat,
      isLoading
    };
  },
  methods: {
    convertLineBreaks(content) {
      // Replace '\n' with '<br>' for line breaks
      return content.replace(/\n/g, '<br>');
    },
    convertUrlsToLinks(text) {
      // Regular expression to match URL until a space, linebreak, apostrophe, or parenthesis
      const urlRegex = /(\bhttps?:\/\/[^\s'()]+[\w/])/ig;
      text = text.replace(urlRegex, (url) => {
        // Convert URLs into clickable links
        return `<a href="${url}" target="_blank" style="color: inherit; text-decoration: underline;">${url}</a>`;
      });

      // Replace line breaks with <br> tags
      return text.replace(/\n/g, '<br>');
    }
  }
};
</script>
<style scoped>

.card-body {
  border-radius: 40px; /* Rounded corners for the chat card body */
  display: flex;
  flex-direction: column;
  height: 90%;
  padding: 1rem; /* Add some padding inside the chat card */
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 1rem; /* Space before the input area */
}

.chat-message {
  max-width: 80%; /* Max message width */
  padding: 0.5rem 1rem; /* Padding inside message bubbles */
  border-radius: 20px; /* Rounded corners for bubbles */
  margin-bottom: 0.5rem;
  line-height: 1.4; /* Better readability for multiple lines */
}

/* Bot messages */
.chat-message.bot {
  align-self: flex-start; /* Align to left */
  color: #333; /* Dark grey text for contrast */
  max-width: 66%; /* Max width for bot messages */
  text-align: left; /* Align text inside bubble */
  border-radius: 16px; /* Rounded corners for bubble */
  margin-right: auto; /* Pushes the message bubble to the left */
  margin-left: 10px; /* Space from the left edge */
}

/* User messages */
.chat-message.user {
  align-self: flex-end; /* Align to right */
  color: white; /* White text for contrast */
  text-align: left; /* Align text inside bubble */
  max-width: 66%; /* Max width for user messages */
  border-radius: 16px; /* Rounded corners for bubble */
  margin-left: auto; /* Pushes the message bubble to the right */
  margin-right: 10px; /* Space from the right edge */
}

.chat-input {
  text-align: right; /* Right aligns the content */
  padding-top: 50px; /* Adds space above */
}

.chat-input input {
  flex-grow: 1;
  margin-right: 0.5rem;
  width: 100% !important;
  padding: 0.5rem; /* Add padding for the input */
  border-radius: 20px; /* Rounded corners for the input */
  border: 1px solid #ddd; /* Light grey border */
}

/* Add a send button for the chat input */

/* Extra styles for scrollbars, if you want to customize instead of hiding */
.chat-messages::-webkit-scrollbar {
  width: 8px;
}
.chat-messages::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.text-overlay-container {
    position: relative;
}

.icon-text-overlay {
    position: absolute;
    top: 80%; /* Adjust these values as needed */
    left: 50%; /* Adjust these values as needed */
    transform: translate(-50%, -50%);
    color: black; /* Change color as needed */
    font-size: 10px; /* Adjust font size as needed */
    white-space: nowrap; /* Prevents text wrapping */
}

.selectable-text {
    user-select: text;
    -moz-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
}

</style>
