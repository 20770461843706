<template>
    <div class="card h-100 mt-4">
        <div class="card-header pb-0 p-3">
            <div class="row">
                <div class="col-6 d-flex align-items-center">
                    <h6 class="mb-0">발신번호 등록</h6>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col d-flex align-items-center text-sm font-weight-bold">
                        <ul>
                            <li>전기통신상버법 제 84조 'SMS 발신 번호 사전 등록제'가 2015년 10월 16일부로 시행됨에 따라 메시지를 발송하려면 발신번호를 먼저 등록하셔야 합니다.</li>
                            <li>'발신번호 사전 등록제'란, 거짓으로 표시된 전화번호로 인한 이용자 피해 예방을 위한 것으로, 문자 메시지 전송 시 사전 인증된 발신번호로만 사용할 수 있도록 등록하는 제도 입니다.</li>
                            <li>이에 따라, 전기통신사업법 제 84조 2항(전화번호의 거짓 표시 금지 및 이용자 보호)에 위반되지 않도록 자신의 발신번호를 사전에 등록하고, 등록된 번호로만 문자 메세지를 발송해야 합니다.</li>
                            <li>또한, 발신 번호는 기업,기관 혹은 본인이 소유한 번호 여야 하며, 등록된 발신 번호로 전송한 메시지가 발신 번호 변조로 의심되어 KISA(한국인터넷진흥원)로부터 소명 요청을 받을 경우, 해당 고객의 발신 번호 소유를 증명을 수 있어야 합니다.</li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4"></div>
        <div class="card-body p-3 pb-0 mb-0 row">

            <div>
                <table id="datatable-basic" class="table table-flush">
                    <thead class="thead-light">
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" rowspan="3">
                                구분
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                내용
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                버튼
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr>
                            <td class="text-sm font-weight-normal" rowspan="2">

                                <h6 class="mb-0">개인회원 전화번호 등록</h6>
                                <div class="col ">
                                    <ul>

                                    </ul>
                                </div>

                            </td>
                            <td class="text-sm font-weight-normal">
                                <h6 class="mb-0">휴대폰 인증</h6>
                                <div class="col ">
                                    <ul>
                                        <li>
                                            개인회원 안심본인인증을 통한 등록(즉시 등록)
                                        </li>

                                    </ul>
                                </div>
                            </td>
                            <td class="text-sm font-weight-normal">
                                <material-button color="primary" size="sm" variant="outline" class="me-3" style="width: 200px;" @click="issuNiceCryptoKey()" :disabled="isNiceAuthenticated">
                                    개인회원 휴대폰 인증
                                </material-button>
                                <form name="form">
                                    <input type="hidden" id="m" name="m" value="service" />
                                    <input type="hidden" id="token_version_id" name="token_version_id" value="" />
                                    <input type="hidden" id="enc_data" name="enc_data" value="" />
                                    <input type="hidden" id="integrity_value" name="integrity_value" value="" />
                                </form>
                            </td>
                                
                        </tr>
                        <tr>
                            <td class="text-sm font-weight-normal">
                                <h6 class="mb-0">서류 인증</h6>
                                <div class="col ">
                                    <ul>
                                        <li>
                                            통신사가입증명서과 신분증사본을 통한 등록(1~2일 소요)
                                        </li>

                                    </ul>
                                </div>
                            </td>
                            <td class="text-sm font-weight-normal"> <material-button color="primary" size="sm"
                                    variant="outline" class="me-3" style="width: 200px;" @click="individualNumber()">개인회원 서류 인증</material-button></td>
                        </tr> -->
                        <tr>
                            <td class="text-sm font-weight-normal" rowspan="3">

                                <h6 class="mb-0">개인 번호</h6>

                            </td>
                            <td class="text-sm font-weight-normal">
                                <h6 class="mb-0">자사 대표자번호 인증</h6>
                                <div class="col ">
                                    <ul>
                                        <li>통신사가입증명서</li>
                                        <li>사업자등록증</li>
                                    </ul>
                                </div>
                            </td>
                            <td class="text-sm font-weight-normal"> <material-button color="primary" size="sm"
                                    variant="outline" class="me-3" style="width: 200px;" @click="ownerNumber()">대표자번호 인증</material-button></td>
                            
                        </tr>
                        <tr>
                            <td class="text-sm font-weight-normal">
                                <h6 class="mb-0">자사 직원번호 인증</h6>
                                <div class="col ">
                                    <ul>
                                        <li>통신사가입증명서</li>
                                        <li>전화번호 소유자의 재직증명서</li>
                                        <li>법인인감증명서</li>
                                        <li>사업자등록증</li>
                                    </ul>
                                </div>
                            </td>
                            <td class="text-sm font-weight-normal"><material-button color="primary" size="sm"
                                    variant="outline" class="me-3" style="width: 200px;" @click="employeeNumber()">직원번호 인증</material-button></td>
                        </tr>
                        <tr></tr>

                        <tr>
                            <td class="text-sm font-weight-normal" rowspan="2">

                                <h6 class="mb-0">기업 번호</h6>

                            </td>
                            <td class="text-sm font-weight-normal">
                                <h6 class="mb-0">자사 번호 인증</h6>
                                <div class="col ">
                                    <ul>
                                        <li>통신사가입증명서</li>
                                        <li>사업자등록증</li>
                                    </ul>
                                </div>
                            </td>
                            <td class="text-sm font-weight-normal"><material-button color="primary" size="sm"
                                    variant="outline" class="me-3" style="width: 200px;" @click="companyNumber()">자사번호 인증</material-button></td>
                        </tr> 
                        <tr></tr>   
                        
                        <tr>
                            <td class="text-sm font-weight-normal" rowspan="2">

                                <h6 class="mb-0">타사 번호</h6>

                            </td>
                        </tr>
                        <tr>
                            <td class="text-sm font-weight-normal">
                                <h6 class="mb-0">*타사 직원번호 인증</h6>
                                <div class="col ">                                    
                                    <!-- <ul>
                                        <li>위임자의 통신사가입증명서</li>
                                        <li>위임자의 발신번호 위탁서</li>
                                        <li>수임자의 사업자등록증</li>
                                        <li>수임자의 대리인지정위임장</li>
                                        <li>수임자의 법인인감증명서</li>
                                        <li>수임자의 대리인의 재직증명서</li>
                                    </ul> -->
                                </div>
                            </td>
                            <td class="text-sm font-weight-normal"><material-button color="primary" size="sm"
                                    variant="outline" class="me-3" style="width: 200px;" @click="extEmployeeNumber()">타사 직원번호 인증</material-button></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td class="text-sm font-weight-normal">
                                <h6 class="mb-0">*타사 번호 인증</h6>
                                <div class="col ">
                                    <!-- <ul>
                                        <li>위임자의 통신사가입증명서</li>
                                        <li>위임자의 사업자등록증</li>
                                        <li>위임자의 발신번호 위탁서</li>
                                        <li>수임자의 사업자등록증</li>
                                        <li>수임자의 대리인지정위임장</li>
                                        <li>수임자의 법인인감증명서</li>
                                        <li>수임자의 대리인의 재직증명서</li>
                                    </ul> -->
                                </div>
                            </td>
                            <td class="text-sm font-weight-normal"><material-button color="primary" size="sm"
                                    variant="outline" class="me-3" style="width: 200px;" @click="extCompanyNumber()">타사번호 인증</material-button></td>
                        </tr>  
                        
                    </tbody>
                    
                </table>
                <span class="text-sm font-weight-bold">
                통신사가입증명서에 등록할 번호(번호대역)이 명시되어야 합니다.<br>
                재직증명서, 대리인지정위임장에 법인인감이 날인되어야 합니다. 사용인감으로 대체할 경우 사용인감계를 반드시 첨부하시기 바랍니다.<br>
                * 타사는 이용자의 자회사, 계열사 등 법인등록번호가 다른 사업자를 포함합니다.<br>
                * 위탁서는 위임자 및 수임자에 대한 정보가 정확히 명시되어야 하며, 수임자가 등록하려는 위임자의 전화번호 목록과 위임하는 목적이 포함되어야 합니다.
                </span>
            </div>
            <div id="requestDocument" class="modal fade" tabindex="-1" aria-labelledby="requestDocumentLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <!-- <h5 class="modal-title" id="createGroupModalLabel">{{ modalTitle }}</h5> -->
                            <h5 id="requestDocumentLabel" class="modal-title">발신번호 등록요청</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-7">
                                    <label class="ms-0 text-sm font-weight-bold">등록할 발신번호</label>
                                </div>
                                <div class="col-5">
                                  <material-input id="from_phone" v-model="from_phone_number" size="sm" style="margin-left: -3px;"></material-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-7">
                                    <label class="ms-0 text-sm font-weight-bold">발신번호 명</label>
                                </div>
                                <div class="col-5">
                                  <material-input id="from_phone_alias" v-model="from_phone_number_alias" size="sm" style="margin-left: -3px;"></material-input>
                                </div>
                            </div>
                            <div class="row mt-4">
                              <div v-for="(file, index) in request_file_list" :key="index">
                                <div class="row align-items-center">
                                  <div class="col-10">
                                    <p class="text-sm font-weight-bold">{{ file.file_type_name }}:{{ file.file_name }}</p>
                                  </div>
                                  <div class="col-2">
                                        <label class="btn-lg">
                                            <i v-if="!file.uploaded" class="material-icons icon-hover icon-large" style="cursor: pointer; margin-top: -7px;"
                                                @click="openFileDialog">attach_file</i>
                                            <input ref="fileInput" type="file" style="display: none"
                                                @change="handleFileUpload($event, file.file_type)" />
                                            <i v-if="file.uploaded" class="material-icons" style="color: green; margin-top: -7px;">check</i>
                                        </label>
                                          
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-4">
                              <p class="text-sm font-weight-bold">*업로드 허용 파일: 엑셀, 워드, PDF, 한글, 이미지</p>
                            </div>
                            <!-- Modal body content goes here -->
                        </div>
                        <material-textarea 
                          id="comment" 
                          v-model=reject_reason 
                          name="comment" 
                          class="text-xs" 
                          style="height: 60px; resize: none;" 
                          placeholder="Comment 입력">
                        </material-textarea>
                        <div class="modal-footer">
                            <button :disabled="false" type="button" class="btn btn-success" data-bs-dismiss="modal"
                                @click="requestApproval()">요청</button> 
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>        
            
        </div>
    </div>
</template>


<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import ApiCall from "@/Interface/ApiCall";
import CryptoJS from 'crypto-js';
// import { mapMutations } from "vuex";


export default {
    name: "RegisterSendNumber",
    components: {
        MaterialButton,
        MaterialInput,
    },


    data() {
        return {
            valid: false,
            registerSendNumber: null,
            from_phone_number_type: '',
            from_phone_number: '',
            from_phone_number_alias: '',
            request_file_list: [],
            temp_request_file_list: [],
            individual_number_file_list: [{file_type_name: "통신서비스이용증명원", file_type: "telecom_service_certificate", file_name: "", file_reference_id: ""}
                                        , {file_type_name: "신분증", file_type: "identification", file_name: "", file_reference_id: ""}],
            owner_number_file_list:  [{file_type_name: "통신서비스이용증명원", file_type: "telecom_service_certificate", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "사업자 등록증", file_type: "business_registration", file_name: "", file_reference_id: ""}],
            employee_number_file_list:  [{file_type_name: "통신서비스이용증명원", file_type: "telecom_service_certificate", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "재직 증명서", file_type: "employment_certification", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "법인 인감증명서", file_type: "corporate_seal_certificate", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "사업자 등록증", file_type: "business_registration", file_name: "", file_reference_id: ""}],
            ext_employee_number_file_list:  [{file_type_name: "통신서비스이용증명원", file_type: "telecom_service_certificate", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "발신번호 위탁서", file_type: "send_phone_number_consignment_letter", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "자사 사업자 등록증", file_type: "business_registration", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "타사 사업자 등록증", file_type: "business_registration_another", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "대리인지정위임장", file_type: "representative_assign_warrant_document", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "법인 인감증명서", file_type: "corporate_seal_certificate", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "대리인 재직 증명서", file_type: "representative_employment_certification", file_name: "", file_reference_id: ""}],
            company_number_file_list:  [{file_type_name: "통신서비스이용증명원", file_type: "telecom_service_certificate", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "사업자 등록증", file_type: "business_registration", file_name: "", file_reference_id: ""}],
            ext_company_number_file_list:  [{file_type_name: "통신서비스이용증명원", file_type: "telecom_service_certificate", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "대리인 사업자 등록증", file_type: "representative_business_registration", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "발신번호 위탁서", file_type: "send_phone_number_consignment_letter", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "자사 사업자 등록증", file_type: "business_registration", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "타사 사업자 등록증", file_type: "business_registration_another", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "대리인지정위임장", file_type: "representative_assign_warrant_document", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "법인 인감증명서", file_type: "corporate_seal_certificate", file_name: "", file_reference_id: ""}
                                    , {file_type_name: "대리인 재직 증명서", file_type: "representative_employment_certification", file_name: "", file_reference_id: ""}],

            niceReqDtim: '',
            niceReqNo: '',
            niceKey: '',
            niceIv: '',
            niceHmacKey: '',
            niceReqData: {
                'requestno': '',
                'returnurl': '',
                'sitecode': '',
                // 'authtype': '',
                // 'mobilceco': '',
                // 'businessno': '',
                'methodtype': '',
                // 'popupyn': '',
                'receivedata': '',
            },
            niceReturnData: {
                token_version_id: '',
                enc_data: '',
                integrity_value: '',
            },
            niceDecData: {},
            isNiceAuthenticated: false,
        };
    },
    computed: {

    },
    watch: {
        registerSendNumber: function () {
            this.isValid();
        },
    },

    beforeMount() {
        console.log("nice return url:", process.env.VUE_APP_NICE_RETURN_URL)
        // this.toggleEveryDisplay();
        // this.toggleHideConfig();

        // authentication 에 nice 인증정보가 들어왔을때 처리
        this.niceReturnData = this.$route.query;
        console.log("return nice return data: ", this.niceReturnData);

        if ('enc_data' in this.niceReturnData) {
        console.log("queryString exist")

        // sessionStorage에 저장된 key, iv 불러와서 사용. (이렇게 써도 되는지의문이지만..)
        this.niceKey = sessionStorage.getItem('niceKey');
        this.niceIv = sessionStorage.getItem('niceIv');
        console.log("key :", this.niceKey, " iv :", this.niceIv);

        const decrypted = CryptoJS.AES.decrypt(this.niceReturnData.enc_data, CryptoJS.enc.Utf8.parse(this.niceKey), {
            iv: CryptoJS.enc.Utf8.parse(this.niceIv),
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });

        const decryptedBase64 = CryptoJS.enc.Base64.stringify(decrypted)
        console.log("decrypt string :", decryptedBase64);


        const bytes = Uint8Array.from(atob(decryptedBase64), c => c.charCodeAt(0));
        // Decode using TextDecoder with EUC-KR encoding
        const decoder = new TextDecoder("euc-kr");
        const decodedString = decoder.decode(bytes);

        console.log("plain text :", decodedString);
        const niceReturnObject = JSON.parse(decodedString);

        console.log("object :", niceReturnObject);
        this.from_phone_number_alias = niceReturnObject.name;
        this.from_phone_number = niceReturnObject.mobileno;
        // this.nameStateAttribute = { success: '' }
        // this.phoneNumberStateAttribute = { success: '' }
        // this.isUserNameValid = true
        // this.isPhoneNumberValid = true

        // 본인인증 전 입력한 정보들 입력.
        const receiveDataString = niceReturnObject.receivedata;
        const receiveData = JSON.parse(receiveDataString);
        console.log("receiveData Object :", receiveData)
        // this.userId = receiveData.userId;
        // this.password = receiveData.password;
        // this.confirmPassword = receiveData.confirmPassword;
        // this.email = receiveData.email;
        // this.tenantName = receiveData.tenantName;

        // 입력창 상태 적용
        // this.validatePassword();
        // this.validatePhoneNumber();
        // this.validateEmail();
        // this.validateUserId();

        // to-do 입력창 label 상태 바꿔줘야함.
        // to-do 본인인증 창 disable
        this.isNiceAuthenticated = true;
        } else {
        console.log("no queryString")
        }
    },
    mounted() {
        window.addEventListener('message', this.handleChildMessage);
    },
    beforeUnmount() {
        // this.toggleEveryDisplay();
        // this.toggleHideConfig();
        window.removeEventListener('message', this.handleChildMessage);
    },
    methods: {
        handleChildMessage(event) {
            // Check if the message is coming from the child window
            if (event.origin !== window.location.origin) return;

            const queryString = event.data; // Retrieve the query string parameter
            // Do something with the query string parameter
            // console.log("queryString from child window :", queryString);
            const params = Object.fromEntries(new URLSearchParams(queryString));

            // console.log("queryString object :", params)

            if ('resultcode' in params) {
                //인증 후 진입
                if (params.resultcode === '0000') {
                    // 인증처리 정상 진행
                    this.from_phone_number_alias = params.name;
                    this.from_phone_number = params.mobileno;

                    // 기존 입력했던 값들 처리
                    const receiveData = JSON.parse(params.receivedata);
                    this.userId = receiveData.userId;
                    this.password = receiveData.password;
                    this.confirmPassword = receiveData.confirmPassword;
                    this.tenantName = receiveData.tenantName;
                    this.email = receiveData.email;
                    this.isNiceAuthenticated = true;
                } else {
                //인증오류
                }
            }
        },
        // ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        async handleFileUpload(event, file_type) {
          const file = event.target.files[0]
          const fileIndex = this.request_file_list.findIndex((f) => f.file_type === file_type);
          console.log('file:', file)


          if (file) {
            const payloadFile = {
                file_type: file_type,
                upload_type: 'file',
            };
            console.log(payloadFile);
            try {
                const fileResponse = await ApiCall.file('upload/file', 'POST', payloadFile, null, file, null, false);
                // const fileResponse = await ApiCall.call('안보내기', 'POST', payloadFile, null, this.excel_file);
                console.log(fileResponse)
                if (fileResponse.status === 200) {
                  console.log(fileResponse.data.item_list);
                  this.request_file_list[fileIndex].file_name = fileResponse.data.item_list[0].file_name;
                  this.request_file_list[fileIndex].file_reference_id = fileResponse.data.item_list[0].file_reference_id;
                  this.request_file_list[fileIndex].uploaded = true;
                  console.log(this.request_file_list);
                } else {
                  throw new Error(fileResponse.message)
                }
            } catch (error) {
                console.error(error);
              if (error.response.status === 599) {
                alert("파일 전송 실패 :" + "업로드 할 수 없는 파일입니다.");
              } else {
                alert("파일 전송 실패 :" + error.message);
              }
            }
          }
        },        
        async requestApproval(){
            if(!this.validatePhoneNumber(this.from_phone_number)){
                alert('발신번호 등록이 불가한 번호 입니다.');
                return;
            }
            const payload = {
                from_phone_number: this.from_phone_number,
                authorize_type: "document",
                from_phone_number_type: this.from_phone_number_type, 
                from_phone_number_alias: this.from_phone_number_alias,
                from_phone_number_status: 'request',      
                file_list: this.request_file_list,
                comment_text: '',
            };
            try {
                console.log(payload);
                const response = await ApiCall.call('phone/from/register', 'POST', payload);
                // const response = await ApiCall.call('안보내기', 'POST', payload);
                if (response.status === 200) {
                  console.log(response.data);
                  alert("발신번호 등록 요청 완료");
                } else {
                  alert(response.data.resultMessage)
                  console.log("발신번호 등록 요청 실패 : " + response.data.resultMessage);
                }
            } catch (error) {
                console.error(error);
                alert("발신번호 등록 요청 실패");
            }
        },
        validatePhoneNumber(intPhoneNumber){
            // check if phoneNumber is empty or undefined
            let phoneNumber = intPhoneNumber.toString();
            if (!phoneNumber) {
              alert('번호를 입력해주세요.');
              return false;
            }
            
            // remove any non-digit characters from the phoneNumber
            // phoneNumber = phoneNumber.replace(/\D/g, '');
            // fail when non-digit characters include.
            if (/\D/.test(phoneNumber)) {
              alert('숫자만 입력해주세요.')
              return false;
            }

            // 유선 전화 번호 서울
            if ((phoneNumber.length == 10 || phoneNumber.length == 9) && phoneNumber.startsWith("02")) {
                var prefix = phoneNumber.substring(2, 4); // Extract the two-digit prefix
                if (prefix === "15" || prefix === "16" || prefix === "18") {
                    alert('대표번호는 지역번호를 제외하고 등록하세요.');
                    return false;
                }
                return true;
            }

            // 유선 전화 번호 서울 외 043-267-1097
            if ((phoneNumber.length == 11 
                || phoneNumber.length == 10) 
                && (phoneNumber.startsWith('031')
                || phoneNumber.startsWith('032')
                || phoneNumber.startsWith('033')
                || phoneNumber.startsWith('041')
                || phoneNumber.startsWith('042')
                || phoneNumber.startsWith('043')
                || phoneNumber.startsWith('044')
                || phoneNumber.startsWith('051')
                || phoneNumber.startsWith('052')
                || phoneNumber.startsWith('053')
                || phoneNumber.startsWith('054')
                || phoneNumber.startsWith('055')
                || phoneNumber.startsWith('061')
                || phoneNumber.startsWith('062')
                || phoneNumber.startsWith('063')
                || phoneNumber.startsWith('064')
                )) {
                var prefix2 = phoneNumber.substring(3, 5); // Extract the two-digit prefix
                if (prefix2 === "15" || prefix2 === "16" || prefix2 === "18") {
                    alert('대표번호는 지역번호를 제외하고 등록하세요.');
                    return false;
                }
                return true;
            }
            
            // 이동통신 전화 번호
            if (phoneNumber.startsWith('01') && phoneNumber.length === 11) {
                return true;
            }
            
            // 대표 전화번호
            if (phoneNumber.length === 8 && (phoneNumber.startsWith('15') || phoneNumber.startsWith('16') || phoneNumber.startsWith('18'))) {
                return true;
            }
            
            // 공통 서비스 식별 번호
            if (phoneNumber.length <= 11 && (phoneNumber.startsWith('020') || phoneNumber.startsWith('040') || phoneNumber.startsWith('060')
                || phoneNumber.startsWith('070') || phoneNumber.startsWith('080') || phoneNumber.startsWith('090'))) {
                return true;
            }
            
            // check if phoneNumber is a special number
            if (phoneNumber === '100' || phoneNumber === '101' || phoneNumber === '106' || phoneNumber === '107' || phoneNumber === '109'
                || phoneNumber === '110' || phoneNumber === '111' || phoneNumber === '112' || phoneNumber === '113' || phoneNumber === '114'
                || phoneNumber === '115' || phoneNumber === '116' || phoneNumber === '117' || phoneNumber === '118' || phoneNumber === '119'
                || phoneNumber === '120' || phoneNumber === '233' || phoneNumber === '123' || phoneNumber === '124' || phoneNumber === '125'
                || phoneNumber === '126' || phoneNumber === '127' || phoneNumber === '128' || phoneNumber === '129' || phoneNumber === '1300'
                || phoneNumber === '1301' || phoneNumber === '131' || phoneNumber === '132' || phoneNumber === '1330' || phoneNumber === '1331'
                || phoneNumber === '1332' || phoneNumber === '1333' || phoneNumber === '1335' || phoneNumber === '1336' || phoneNumber === '1337'
                || phoneNumber === '1338' || phoneNumber === '1339' || phoneNumber === '1350' || phoneNumber === '1355' || phoneNumber === '1357'
                || phoneNumber === '1365' || phoneNumber === '1366' || phoneNumber === '1369' || phoneNumber === '1372' || phoneNumber === '1377'
                || phoneNumber === '1379' || phoneNumber === '1380' || phoneNumber === '1382' || phoneNumber === '1385' || phoneNumber === '1388'
                || phoneNumber === '1390' || phoneNumber === '1391' || phoneNumber === '1393' || phoneNumber === '1397' || phoneNumber === '1398'
                || phoneNumber === '1399' || phoneNumber === '182' || phoneNumber === '188') {
                return true;
            }
            
            // check if phoneNumber starts with '030' or '050' and has length up to 12 digits
            if ((phoneNumber.startsWith('030') || phoneNumber.startsWith('050')) && phoneNumber.length <= 12) {
                return true;
            }
            
            // check if phoneNumber length is between 8 and 12 digits
            if (phoneNumber.length < 8 || phoneNumber.length > 12) {
                alert('발신번호 자리수를 확인해주세요.');
                return false;
            }
            
            // if phoneNumber didn't match any of the above conditions, it's invalid
            // alert('발신번호 등록이 불가한 번호 입니다.');
            return false;
        },
        verifyPhone(){
            alert("휴대폰 인증 적용 예정입니다.")
        },
        openPopup(){
            const bootstrap = this.$store.state.bootstrap;
            // 모달 열기
            const modal = new bootstrap.Modal(
                document.getElementById("requestDocument")
            );
            modal.show()
        },
        individualNumber(){
            this.request_file_list=[];
            this.request_file_list = [...this.individual_number_file_list];
            this.from_phone_number_type = 'individual';
            this.openPopup();
        },
        ownerNumber(){
            this.request_file_list=[];
            this.request_file_list = [...this.owner_number_file_list];
            this.from_phone_number_type = 'company';
            this.openPopup();
        },
        employeeNumber(){
            this.request_file_list=[];
            this.request_file_list = [...this.employee_number_file_list];
            this.from_phone_number_type = 'company';
            this.openPopup();
        },
        extEmployeeNumber(){
            this.request_file_list=[];
            this.request_file_list = [...this.ext_employee_number_file_list];
            this.from_phone_number_type = 'company';
            this.openPopup();
        },
        companyNumber(){
            this.request_file_list=[];
            this.request_file_list = [...this.company_number_file_list];
            this.from_phone_number_type = 'company';
            this.openPopup();
        },
        extCompanyNumber(){
            this.request_file_list=[];
            this.request_file_list = [...this.ext_employee_number_file_list];
            this.from_phone_number_type = 'company';
            this.openPopup();
        },
        isValid() {
            if (this.registerSendNumber == '') {
                this.valid = false;
            } else {
                this.valid = true;
            }
        },
        handleExcelUpload() {  //통신서비스 가입 증명원 엑셀 업로드

        },
        async issuNiceCryptoKey() {
            const returnInfo = {
                'returnUrl': 'manage/certify'
            }

            // const res = await ApiCall.callNoAuth('user/nice/token', 'POST', signUpInfo);
            const res = await ApiCall.callNoAuth('user/nice/token', 'POST', returnInfo);
            // console.log("enc data", res);

            // document.form.submit();
            document.form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
            document.form.token_version_id.value = res.token_version_id;
            document.form.enc_data.value = res.enc_data;
            document.form.integrity_value.value = res.integrity_value;
            document.form.target='niceForm';

            const left = screen.width / 2 - 500 / 2;
            const top = screen.height / 2 - 800 / 2;
            const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;

            let niceAuthWindow = window.open(
                'about:blank',
                'niceForm',
                option
            )
            document.form.submit();

            // niceAuthWindow.addEventListener('hashchange', function(){
            //   var response =
            // });
        },
    }
};
</script>
