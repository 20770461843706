<template>
  <div class="row">
    <div v-for="(value, index) in options" :key="value" class="col-4">
      <label class="radio-label">
        <div class="radio-option-image">
          <input v-model="selectedOption" type="radio" :value="value"><br>
          <img
            class="radio-img"
            :src="value.url"
            :alt="`Option ${index}`"
          >
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  emits: ['selected'],
  data() {
    return {
      selectedOption: this.options.length > 0 ? this.options[0] : null
    };
  },
  watch: {
    selectedOption(newValue) {
      console.log('contents select changed : ' + JSON.stringify(newValue));
      console.log(newValue.file)
      this.$emit('selected', newValue);
    }
  }
};
</script>

<style>
/* Apply custom styles to radio buttons */
input[type="radio"] {
  -webkit-appearance: none; /* Remove default appearance */
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%; /* Create a circular shape */
  border: 2px solid grey; /* Set default border color */
  outline: none; /* Remove outline */
  cursor: pointer;
}

/* Style the checked state */
input[type="radio"]:checked {
  background-color: orange;
  border: 3px solid white;
  box-shadow: 0 0 0 1.6px orange;
}

/* Style the radio option container */
.radio-option-image {
  display: inline-block;
  border: 2px solid lightgray; /* Set border around the container */
  border-radius: 0.5rem;
  padding: 5px; /* Add padding to create space between border and content */
  margin-right: 10px;
  text-align: center; /* Center the text */
}

/* Style the radio text */
.radio-img {
  max-width: 100%; /* Ensure image stays within the container */
  max-height: 100%; /* Set max height for the image */
  text-align: center; /* Center the text */
}

.radio-label {
  display: inline-block; /* Set label to inline-block */
}
</style>
