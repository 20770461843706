<template>
  <div class="combined-textarea" :class="{ 'has-buttons': pages[0].insertedButtons.length > 0 }">
  <textarea
    v-bind="$attrs"
    :id="id"
    class="form-control title-textarea"
    :rows="1"
    :name="name"
    value="친구톡 도착"
    :disabled="disabled"
  ></textarea>
    <div class="kakao-badge">kakao</div>
    <div class="textarea-container">
      <div
        v-for="(page, index) in pages" :key = "page"
      >
        <div v-if="page.imageFileUrl && index === 0" class="first-image">
          <img  class="image-area" :src="page.imageFileUrl" alt="Uploaded Image" />
          <div class="text-overlay">
            <p class="overlay-text">{{ page.typed_contents }}</p>
          </div>
        </div>
        <div v-if="page.imageFileUrl && index !== 0" class="image-container">
          <div class="not-first-image">
            <img  class="image-area" :src="page.imageFileUrl" alt="Uploaded Image" />
          </div>
          <p class="side-text">{{ page.typed_contents }}</p>
        </div>
      </div>
      <div v-if="friendsTalkType === 'TEXT'" class="textarea-container">
        <textarea
          v-if="textSubTitleValue"
          v-bind="$attrs"
          :id="`${id}-textSubTitle`"
          ref="textSubTitleTextarea"
          class="form-control emphasize-sub-title-textarea"
          :rows="1"
          :disabled="disabled"
          :value="textSubTitleValue"
          v-on="textSubTitleInputListeners"
          @input="$emit('update:textSubTitleValue', $event.target.value)"
        >
        </textarea>
        <textarea
          v-if="textTitleValue"
          v-bind="$attrs"
          :id="`${id}-textTitle`"
          ref="textTitleTextarea"
          class="form-control emphasize-title-textarea"
          :rows="currentTitleRows"
          :disabled="disabled"
          :value="textTitleValue"
          v-on="textTitleInputListeners"
          @input="$emit('update:textTitleValue', $event.target.value)"
        >
        </textarea>
      </div>
      <div class="textarea-content">
        <textarea
          v-bind="$attrs"
          :id="`${id}-contents`"
          ref="contentTextarea"
          class="form-control content-textarea"
          :rows="currentContentsRows"
          :name="name"
          :isRequired="isRequired"
          :disabled="disabled"
          v-on="contentsInputListeners"
        ></textarea>
      </div>
      <div v-if="pages[0].insertedButtons.length > 0"
           class="white-box"
           :class="{ 'has-buttons': pages[0].insertedButtons.length > 0}">
        <div class="buttons-area">
          <button
            v-for="(button, index) in pages[0].insertedButtons"
            :key="index"
            class="button"
            >
            {{ button.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setMaterialInput from "@/assets/js/material-input.js";

export default {
  name: "CustomTextarea",
  props: {
    variant: {
      type: String,
      default: "outline",
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "",
    },
    contentsValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Your text here...",
    },
    isRequired: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 5,
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    friendsTalkType: {
      type: String,
      default: 'NONE',
    },
    textTitleValue: {
      type:String,
      default: '',
    },
    textSubTitleValue: {
      type:String,
      default: '',
    },
    pages: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:contentsValue", "update:textTitleValue", "update:textSubTitleValue"],
  data() {
    return {
      currentContentsRows: 1,
      currentTitleRows: 1,
    }
  },
  computed: {
    contentsInputListeners() {
      return {
        ...this.$attrs,
        input: (event) => this.$emit("update:contentsValue", event.target.value),
      };
    },
    textTitleInputListeners() {
      return {
        ...this.$attrs,
        input: (event) => this.$emit("update:textTitleValue", event.target.value),
      };
    },
    textSubTitleInputListeners() {
      return {
        ...this.$attrs,
        input: (event) => this.$emit("update:textSubTitleValue", event.target.value),
      };
    },

  },
  watch: {
    contentsValue(newValue) {
      this.adjustContentsRows();
    },
    textTitleValue(newValue) {
      this.adjustTitleRows();
    }
  },
  mounted() {
    this.adjustContentsRows(); // Initial adjustment
    this.adjustTitleRows();
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value);
      this.adjustContentsRows();
    },
    adjustContentsRows() {
      const content = this.contentsValue;
      const byteLimit = 57; // Set your desired byte limit for line increase with Korean characters
      const maxRows = 20; // Set your maximum row limit here
      const textarea = this.$refs.contentTextarea; // Get a reference to the content textarea element

      if (textarea) {
        // Calculate the byte length of the content considering UTF-8 encoding
        const contentBytes = new TextEncoder().encode(content).length;

        // Calculate the number of line breaks in the content
        const lineBreaks = (content.match(/\n/g) || []).length;

        // Calculate the number of rows based on byte length and line breaks
        const estimatedRows =
          Math.ceil(contentBytes / byteLimit) +
          lineBreaks + 1 // Add 1 to account for the initial row

        // Set a minimum number of rows to prevent the textarea from becoming too small
        const minRows = 1;

        // Enforce the maximum row limit and minimum row limit
        this.currentContentsRows = Math.min(Math.max(estimatedRows, minRows), maxRows);
      }
    },
    adjustTitleRows() {
      const content = this.textTitleValue;
      const byteLimit = 30; // Set your desired byte limit for line increase with Korean characters
      const maxRows = 2; // Set your maximum row limit here
      const textarea = this.$refs.textTitleTextarea; // Get a reference to the content textarea element

      if (textarea) {
        // Calculate the byte length of the content considering UTF-8 encoding
        const contentBytes = new TextEncoder().encode(content).length;

        // Calculate the number of line breaks in the content
        const lineBreaks = (content.match(/\n/g) || []).length;

        // Calculate the number of rows based on byte length and line breaks
        const estimatedRows =
          Math.ceil(contentBytes / byteLimit) +
          lineBreaks // Add 1 to account for the initial row

        // Set a minimum number of rows to prevent the textarea from becoming too small
        const minRows = 1;

        // Enforce the maximum row limit and minimum row limit
        this.currentTitleRows = Math.min(Math.max(estimatedRows, minRows), maxRows);
      }
    },

  },
};
</script>

<style scoped>
.title-textarea {
  border: 1px solid #ccc;
  border-radius: 10px 10px 0 0; /* Round top corners only */
  background-color: #fef01b !important;
  color: #000000 !important;
  font-weight: bold !important;
  width: 200px;
  resize: none;
  font-size: 0.8rem;
}

.textarea-container {
  display: flex;
  flex-direction: column;
}

.image-area {
  border: 1px solid #ccc;
  border-top: none;
  border-bottom: none;
  background-color: #ffffff !important;
  max-width: 100%;
  height: auto;
}

.content-textarea {
  border: 1px solid #ccc;
  border-top: none; /* Remove bottom border */
  border-radius: 0 0 10px 10px; /* Round bottom corners only */
  margin-top: 0px; /* Align closely with the title textarea */
  background-color: #ffffff !important;
  resize: none;
  font-size: 0.6rem;
}

.emphasize-title-textarea {
  border: 1px solid #ccc;
  border-top: none; /* Remove bottom border */
  border-bottom: none; /* Remove bottom border */
  border-radius: 0 0 0 0; /* Round bottom corners only */
  margin-top: -2px; /* Align closely with the title textarea */
  margin-bottom: -10px; /* Align closely with the title textarea */
  background-color: #ffffff !important;
  resize: none;
  font-size: 1.0rem;
}

.emphasize-sub-title-textarea {
  border: 1px solid #ccc;
  border-bottom: none; /* Remove bottom border */
  border-radius: 0 0 0 0; /* Round bottom corners only */
  margin-top: -5px; /* Align closely with the title textarea */
  margin-bottom: -10px;
  background-color: #ffffff !important;
  resize: none;
  font-size: 0.6rem;
}

.kakao-badge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: grey;
  color: white;
  border-radius: 20px; /* Adjust for desired elliptical shape */
  width: 30px; /* Fixed width */
  height: 30px; /* Fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem; /* Adjust as needed */
}

.white-box {
  background-color: #ffffff;
  border: none;
  padding: 0px;
  margin-top: 0px; /* Adjust the margin as needed */
}
.white-box.has-buttons {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 10px 10px;
  margin-top: 0px;
}

.buttons-area {
  margin-top: 0px; /* Adjust the margin as needed */
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center buttons horizontally */
}

.button {
  background-color: #eeeeee; /* Example button background color */
  color: black; /* Example button text color */
  font-weight: bold;
  padding: 5px 0; /* Adjust vertical padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8rem;
  margin-bottom: 10px; /* Add margin between buttons */
  width: 90%; /* Set button width to 80% of parent */
  height: 35px;
  text-align: center; /* Center button text horizontally */
}

.combined-textarea.has-buttons .content-textarea {
  border-radius: 0px 0px 0px 0px; /* Rounded corners on all sides when buttons are shown */
  border-bottom: none; /* Remove bottom border when buttons are shown */
}

.first-image {
  position: relative;
  display: inline-block;
}

.text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0); /* Adjust the background color and opacity */
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  transition: opacity 0.3s ease;
}

.overlay-text {
  margin: 0;
}

.image-container {
  display: flex;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none; /* Remove bottom border */
  border-bottom: none;
}

.not-first-image {
  flex: 0 0 auto; /* This prevents the image from growing to fill available space */
  width: 50px; /* Adjust the width as needed */
  padding: 4px;
}

.overlay-text {
  font-size: 1.0rem;
  font-weight: bold !important;
}

.side-text {
  font-size: 0.8rem;
}
</style>
