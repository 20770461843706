<template>
  <head>
    <!-- <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-icons.css"> -->
    <!-- <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/cleo-svg.css"> -->
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/soft-ui-dashboard.min.css?v=1.0.2">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700">
    <link rel="stylesheet" href="./assets/css/theme.css">
  </head>
  <!-- <navbar btn-background="bg-gradient-success" /> -->
  <div class="container position-sticky z-index-sticky top-0">
        <div class="row">
            <div class="col-12">
                <nav class="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
                  <div class="container-fluid">
                        <!-- <a class="navbar-brand font-weight-bolder ms-sm-3" href="javascript:;" rel="tooltip" title="Designed and Coded by Creative Tim" data-placement="bottom">Message To-Go</a> -->
                        <a class="navbar-brand ms-sm-3" href="../../home">
                          <!-- <img src="@/assets/img/m2g-logo-only.png" alt="Logo" height="30"> -->
                          <!-- <img src="../assets/img/m2g-logo-only.png" alt="Logo" height="30"> -->
                          <img src="@/assets/img/SK_logo.svg" alt="Your SVG Image" style="position: relative; width: 30%; height: 20%; margin-top: -10px; margin-bottom: -10px; margin-right: 10px; margin-left: -10px;">
                          <!-- <span class="font-weight-bolder"> Message To-Go</span>                           -->
                        </a>
                        <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon mt-2">
                                <span class="navbar-toggler-bar bar1"></span>
                                <span class="navbar-toggler-bar bar2"></span>
                                <span class="navbar-toggler-bar bar3"></span>
                            </span>
                        </button>
                        <div id="navigation" class="collapse navbar-collapse pt-3 pb-2 py-lg-0">
                            <ul class="navbar-nav navbar-nav-hover ms-lg-12 ps-lg-5 w-100">
                                <li class="nav-item">
                                  <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="../../home#channels" aria-expanded="false">
                                    <span class="font-weight-bolder" style="font-size: larger;">채널소개</span>
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="../../home#features" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">기능</span>
                                  </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="../../home#product" aria-expanded="false">    
                                        <span class="font-weight-bolder" style="font-size: larger;">요금제</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/contactus" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">문의하기</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" :to="`/home/news`" aria-expanded="false">
                                    <span class="font-weight-bolder" style="font-size: larger;">NEWS/소식</span>
                                  </router-link>
                                </li>
                                <li class="nav-item ms-lg-auto my-auto ms-3 ms-lg-0">
                                  <router-link class="btn btn-sm  bg-gradient-primary  btn-round mb-0 me-1 mt-2 mt-md-0" :to="`/authentication/signin/basic`">
                                    회원가입/로그인
                                  </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
  <div
    class="page-header align-items-start min-vh-100"
    style="
      background-image: url('https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80');
    "
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto" :hidden="status!=='Login'">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                  class="bg-primary shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-2">
                  로그인
                </h4>
              </div>
            </div>
            <div class="card-body" >
                <div class="mb-3">
                  <material-input
                      id="userId"
                      v-model="userId"
                      label="아이디"
                      name="userId"
                  />
                </div>
                <div class="mb-3">
                  <material-input
                      id="password"
                      v-model="password"
                      color="primary"
                      type="password"
                      label="비밀번호"
                  />
                  <p class="text-danger text-sm mt-1 mb-1 ms-3" :hidden="!isPasswordError">
                    아이디 혹은 비밀번호가 맞지 않습니다.
                  </p>
                  <p class="text-danger text-sm mt-1 mb-1 ms-3" :hidden="!isPasswordAttemptsExceeded">
                    로그인 시도 횟수가 초과하여 잠금상태입니다. 잠시후 시도해주세요.
                  </p>
                </div>
                <div class="text-center">
                  <material-button-progress
                      class="my-4 mb-2"
                      variant="gradient"
                      color="primary"
                      full-width
                      :is-loading="isLoadingSignIn"
                      @click="signIn"
                  >로그인</material-button-progress >
                  <material-button
                      variant="gradient"
                      color="fail"
                      full-width
                      :hidden="!isPasswordError"
                      @click="passwordReset"
                  >비밀번호 재설정</material-button>
                </div>
              <div class="mt-4 text-sm text-center">
                계정이 없으신가요?
<!--                <router-link-->
<!--                  :to="`/authentication/signup/auth`"-->
<!--                  class="text-primary text-gradient font-weight-bold"-->
<!--                >회원가입</router-link-->
<!--                >-->
                <a
                  href="javascript:void(0)"
                  class="text-primary text-gradient font-weight-bold"
                  @click="handleSignupClick"
                >
                  회원가입
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-auto" :hidden="status!=='confirm'">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mb-2 mx-3 z-index-2">
              <div
                  class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center">
                  본인인증
                </h4>
                <p class="text-white text-sm mb-1 ms-3">
                  등록된 이메일로 인증번호가 발송되었습니다. <br>
                  인증번호를 입력해주세요. <br>
                  이메일 발송시간은 1분정도 소요될 수 있습니다.
                </p>
              </div>
            </div>
            <div class="card-body" >
              <div class="mb-3">
                <material-input
                    id="confirmCode"
                    v-model="confirmCode"
                    label="인증번호"
                />
                <p class="text-sm mt-1 mb-1 ms-3" :hidden="!isResendConfirmCode">
                  인증번호가 재전송 되었습니다.
                </p>
                <p class="text-danger mt-1 mb-1 ms-3" :hidden="!isConfirmationError">
                  인증에 오류가 있습니다. 관리자에게 문의하세요.
                </p>
                <p class="text-danger text-sm mt-1 mb-1 ms-3" :hidden="!isLimitExceededException">
                  인증 한도를 초과했습니다. 잠시후에 시도해주세요.
                </p>
              </div>
              <div class="text-center">
                <material-button-progress
                    class="mb-2"
                    variant="gradient"
                    color="success"
                    full-width
                    :is-loading="isLoadingCertify"
                    @click="codeConfirm"
                >확인</material-button-progress>
              </div>
              <div>
                <material-button
                    variant="gradient"
                    color="fail"
                    full-width
                    @click="resendConfirmCode"
                >인증번호 재전송</material-button>
              </div>
              <p class="mt-4 text-sm text-center">
                계정이 없으신가요?
                <router-link
                  :to="`/authentication/signin/basic`"
                    class="text-success text-gradient font-weight-bold"
                >회원가입</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-auto" :hidden="status!=='reset'">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mb-2 mx-3 z-index-2">
              <div
                  class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center">
                  비밀번호 재설정
                </h4>
                <p class="text-white text-sm mb-1 ms-3">
                  계정을 입력해주세요. <br>
                  등록된 이메일로 인증코드가 발송됩니다. <br>
                  이메일 발송시간은 1분정도 소요될 수 있습니다.
                </p>
              </div>
            </div>
            <div class="card-body" >
              <div class="mb-3">
                <material-input
                    id="userId"
                    v-model="userId"
                    label="아이디"
                />
              </div>
              <div class="text-center">
                <p class="text-danger text-sm mt-1 mb-1 ms-3" :hidden="!isLimitExceededException">
                  인증 한도를 초과했습니다. 잠시후에 시도해주세요.
                </p>
                <material-button
                    class="mb-2"
                    variant="gradient"
                    color="success"
                    full-width
                    @click="sendResetPasswordConfirmCode"
                >인증번호 전송</material-button>
              </div>
              <p class="mt-4 text-sm text-center">
                계정이 없으신가요?
                <router-link
                  :to="`/authentication/signup/auth`"
                    class="text-success text-gradient font-weight-bold"
                >회원가입</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-auto" :hidden="status!=='resetConfirm'">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mb-2 mx-3 z-index-2">
              <div
                  class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center">
                  비밀번호 재설정
                </h4>
                <p class="text-white text-sm mb-1 ms-3">
                  등록된 이메일로 인증코드가 발송되었습니다. <br>
                  이메일 발송시간은 1분정도 소요될 수 있습니다. <br>
                  인증코드와 비밀번호를 입력해주세요.
                </p>
              </div>
            </div>
            <div class="card-body" >
              <div class="mb-3">
                <material-input
                    id="confirm"
                    v-model="confirmCode"
                    label="인증번호"
                />
              </div>
              <div class="mb-3">
                <material-input
                    id="password"
                    v-model="password"
                    type="password"
                    label="신규 비밀번호"
                    :success="isPasswordValid"
                    @input="validatePassword"
                />
              </div>
              <div class="mb-3">
                <material-input
                    id="passwordConfirm"
                    v-model="confirmPassword"
                    type="password"
                    label="비밀번호 재확인"
                    :success="isPasswordConfirmValid"
                    @input="validatePassword"
                />
              </div>
              <div class="text-center">
                <p class="text-sm mt-1 mb-1 ms-3" :hidden="!isRessetConfirmCodeError">
                  인증번호가 맞지 않습니다.
                </p>
                <p class="text-sm mt-1 mb-1 ms-3" :hidden="!isResendConfirmCode">
                  인증번호가 재전송 되었습니다.
                </p>
                <p class="text-danger text-sm mt-1 mb-1 ms-3" :hidden="!isLimitExceededException">
                  인증 한도를 초과했습니다. 잠시후에 시도해주세요.
                </p>
                <material-button
                    class="mb-2"
                    variant="gradient"
                    color="success"
                    full-width
                    :disabled="!(isPasswordValid&&isPasswordConfirmValid)"
                    @click="forgotPasswordSummit"
                >확인</material-button>
              </div>
              <div>
                <material-button
                    variant="gradient"
                    color="fail"
                    full-width
                    @click="reSendResetPasswordConfirmCode"
                >인증번호 재전송</material-button>
              </div>
              <p class="mt-4 text-sm text-center">
                계정이 없으신가요?
                <router-link
                    :to="{ name: 'Signup Basic' }"
                    class="text-success text-gradient font-weight-bold"
                >회원가입</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-10 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              ©
          {{ new Date().getFullYear() }}, made
          <!-- <i class="fa fa-heart"></i>  -->
          by
          <a
            href="https://www.sk.com"
            class="font-weight-bold"
            target="_blank"
            >SK 주식회사 C&C</a
          >
            </div>
            <span style="font-size: 0.8rem; margin-right: 0.5rem;">
          SK(주) | 대표이사: 장용호, 최태원 | 경기도 성남시 분당구 성남대로 343번길 9 | 사업자등록번호 783-85-00169 | 통신판매업신고 2015-경기성남-1388 <br> COPYRIGHT SK HOLDINGS CO., LTD. ALL RIGHTS RESERVED.
          </span>
          </div>
          <div class="col-12 col-md-2">
            <ul
                      class="nav nav-footer"
                    >
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 이용 약관_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >이용약관</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 수준 약정서(SLA)_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >서비스수준(SLA)</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/home/privacy"
                          class="nav-link pe-0 text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 1rem;"
                          >개인정보처리방침</a
                        >
                      </li>
                    </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";

// amplify
import {Hub, I18n, Auth} from 'aws-amplify'
import {
  Authenticator,
  AuthenticatorSignUpFormFields,
  AmplifyCheckBox,
  AmplifyTextField,
  useAuthenticator,
} from '@aws-amplify/ui-vue'
import '@aws-amplify/ui-vue/styles.css'
import '/src/assets/css/styles.css'
import {useRouter} from "vue-router";
import {onUnmounted} from "vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import { toRefs } from 'vue';
import ko from '../../../locales/ko.json';
import { translations } from '@aws-amplify/ui-vue';
import ApiCall from "@/Interface/ApiCall";
import MaterialButtonProgress from "@/components/MaterialButtonProgress.vue";

I18n.putVocabularies(translations);

I18n.putVocabularies({
  ko,
})
I18n.setLanguage('ko');

export default {
  name: "SigninBasic",
  components: {
    MaterialInput,
    // MaterialSwitch,
    MaterialButton,
    MaterialButtonProgress,
    // MaterialCheckbox,
    // Navbar,
    // Authenticator,
    // AuthenticatorSignUpFormFields,
    // AmplifyCheckBox,
    // AmplifyTextField,
  },
  computed: {
    I18n() {
      return I18n
    }
  },
  created() {
    // cognito endpoint change.
    if (ApiCall.vdiDomainCheck()) {
      Auth.configure({
        endpoint: process.env.VUE_APP_COGNITO_URL_PROXY
      });
    }

    Auth.currentAuthenticatedUser().then(user => {
      this.userName = user.attributes.name // Set the user ID as the dynamicMessage
      const router = this.$router
      router.push('/dashboard')
    }).catch(error => {
      console.error('Error fetching user information:', error)
    })
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    showNotificationModal() {
      const bootstrap = this.$store.state.bootstrap;
      this.notificationModal = new bootstrap.Modal(
        document.getElementById("notificationModal")
      );
      this.notificationModal.show();
    },
    moveToSignUp() {
      this.$router.push('/authentication/signup/auth')
    },
    getQueryString() {
      return window.location.search;
    },
    async signIn() {
      this.isLoadingSignIn = true;
      try {
        await Auth.signIn(this.userId, this.password)
      } catch (error) {
        switch (error.name) {
          case 'UserNotConfirmedException':
            console.error('UserNotConfirmedException', error)
              this.status='confirm'
            break;
          case 'NotAuthorizedException':
            console.error('NotAuthorizedException', error.message)
            if (error.message === 'Password attempts exceeded') {
              this.isPasswordAttemptsExceeded = true
              this.isPasswordError = false
            } else {
              this.isPasswordAttemptsExceeded = false
              this.isPasswordError = true
            }
            break;
          default:
            console.error('error signing in', error)
        }
      }
      this.isLoadingSignIn = false;
    },
    async codeConfirm() {
      this.isLoadingCertify = true;
      try {
        await Auth.confirmSignUp(this.userId, this.confirmCode);
        await Auth.signIn(this.userId, this.password);
      } catch (e) {
        console.error('인증오류 발생. 관리자에세 문의하세요.:', e)
        alert('인증오류 발생. 관리자에세 문의하세요.: ' + e)
        this.isConfirmationError = true
      }
      this.isLoadingCertify = false;
    },
    async resendConfirmCode() {
      try {
        await Auth.resendSignUp(this.userId)
        this.allFlagFalse()
        this.isResendConfirmCode = true
      } catch (e) {
        switch (e.name) {
          case 'LimitExceededException':
            this.isLimitExceededException = true
            break;
          default:
            console.error('Re send signup Confirm Error :', e)
        }
      }
    },
    async passwordReset() {
      this.allFlagFalse()
      this.status = 'reset'
    },
    async sendResetPasswordConfirmCode() {
      try {
        await Auth.forgotPassword(this.userId)
        this.allFlagFalse()
        this.status='resetConfirm'
      } catch (e) {
        switch (e.name) {
          case 'LimitExceededException':
            this.isLimitExceededException = true
            break;
          default:
            console.error('비밀번호 초기화중 오류가 발생했습니다.', e)
        }
      }
    },
    async reSendResetPasswordConfirmCode() {
      try {
        await Auth.forgotPassword(this.userId)
        this.allFlagFalse()
        this.status='resetConfirm'
        this.isResendConfirmCode=true;
      } catch (e) {
        switch (e.name) {
          case 'LimitExceededException':
            this.isLimitExceededException = true
            break;
          default:
            console.error('비밀번호 초기화 중 오류가 발생했습니다.',e)
        }
      }
    },
    validatePassword() {
      const minLength = 8;
      const containsLowercase = /[a-z]/;
      const containsNumber = /\d/;

      const isValidLength = this.password.length >= minLength;
      const hasLowercase = containsLowercase.test(this.password);
      const hasNumber = containsNumber.test(this.password);
      this.isPasswordConfirmValid = this.password === this.confirmPassword;
      this.isPasswordValid = isValidLength && hasLowercase && hasNumber;
    },
    async forgotPasswordSummit() {
      try {
        await Auth.forgotPasswordSubmit(this.userId, this.confirmCode, this.password)
        this.allFlagFalse()
        this.status='Login'
      } catch (e) {
        switch (e.name) {
          case 'CodeMismatchException':
            console.error('인증번호 오류')
            this.allFlagFalse()
            this.isRessetConfirmCodeError = true
            break;
          default:
            console.error('비밀번호 초기화 오류', e)
        }
      }
    },
    allFlagFalse() {
      this.isPasswordError = false
      this.isPasswordAttemptsExceeded = false
      this.isResendConfirmCode = false
      this.isConfirmationError = false
      this.isRessetConfirmCode = false
      this.isResendResetConfirmCode = false
      this.isRessetConfirmCodeError = false
      this.isEnterpriseChecked = false
      this.isPasswordValid = false
      this.isPasswordConfirmValid = false
      this.isLimitExceededException = false
    },
    handleSignupClick() {
      alert("회원가입은 관리자에게 문의해주세요.")
    }
  },
  setup() {
    const router = useRouter()

    const formFields = {
      signUp: {
        phone_number: {
          dialCode: '+82'
        },
      }
    }

    const { validationErrors } = toRefs(useAuthenticator());

    const services = {
      async validateCustomSignUp(formData) {
        if (!formData.acknowledgement) {
          return {
            acknowledgement: '이용약관에 동의하여야 합니다.',
          };
        }
      },
    };

    const listener = (data) => {
      switch (data.payload.event) {
        case 'signIn':
          // console.log('sign in.')
          // eslint-disable-next-line no-case-declarations
          router.push('/dashboard')
          break
        case 'signOut':
          // console.log('sign out.')
          break
      }
    }

    Hub.listen('auth', listener)

    onUnmounted(() => {
      Hub.remove('auth', listener)
    })

    return {
      services,
      validationErrors,
      formFields,
    }
  },
  data() {
    return {
      isPasswordError: false,
      isPasswordAttemptsExceeded: false,
      isResendConfirmCode: false,
      isConfirmationError: false,
      isRessetConfirmCode: false,
      isResendResetConfirmCode: false,
      isRessetConfirmCodeError: false,
      isEnterpriseChecked: false,
      isPasswordValid: false,
      isPasswordConfirmValid: false,
      isLimitExceededException: false,
      status: 'Login',
      userId: '',
      password: '',
      confirmPassword: '',
      confirmCode: '',
      notificationModal: null,
      isLoadingSignIn: false,
      isLoadingCertify: false,
    }
  },
};
</script>

<style>
.my-authenticator {
  position: relative;
  margin-top: 150px;
  margin-bottom: 150px;
}
</style>
