<template>
  <div id="user-list" class="card mt-4 me-2">
    <div class="card-header">
      <h6 class="mb-0">사용자 조회</h6>
    </div>
    <div class="card-body pt-0">
      <div class="mt-0 row bottom">
        <div class="col-8 d-flex flex-column">
          <label class="form-label mt-0 ms-0">부서선택</label>
          <select
            id="tenant-name"
            v-model="selectedTenant"
            class="form-control"
            name="tenant-name"
          >
          </select>
        </div>
        <div class="col-4 d-flex justify-content-end align-items-end mb-2">
          <material-button color="primary" size="sm" variant="default"
                           style="width: 120px; margin-right: 5px;"
                           @click="openSubTenantModal">
           부서추가
          </material-button>
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;" @click="changeData">
            조회
          </material-button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-end">
        </div>
      </div>
    </div>
  </div>
  <div id="updateTenantModal" class="modal fade"  tabindex="-1" aria-labelledby="updateTenantModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="updateTenantModalLabel" class="modal-title" >부서 변경</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <label class="form-label mt-0 ms-0">부서선택</label>
          <select
            id="tenant-name-modal"
            v-model="selectedTenantModal"
            class="form-control"
            name="tenant-name-modal"
          >
          </select>
        </div>
        <div class="modal-footer">
          <material-button color="primary" size="sm" variant="default" @click="updateTenant">변경</material-button>
          <material-button color="primary" size="sm" variant="default" data-bs-dismiss="modal">닫기</material-button>
        </div>
      </div>
    </div>
  </div>
  <div id="createSubTenantModal" class="modal fade"  tabindex="-1" aria-labelledby="createSubTenantModal"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" >부서 생성</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <material-input
            id="subTenantName"
            v-model="newSubTenantName"
            label="부서 명"
          >
          </material-input>
        </div>
        <div class="modal-footer">
          <material-button color="primary" size="sm" variant="default" @click="createSubTenant">생성</material-button>
          <material-button color="primary" size="sm" variant="default" data-bs-dismiss="modal">닫기</material-button>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-4 card mt-2 me-2">
    <div class="pb-0 card-header">
      <div class="row">
        <div class="col-6">
          <div v-if="tableData.length < 0"><h6>조회 결과</h6></div>
          <div v-if="tableData.length >= 0"><h6>조회 결과 {{ tableData.length }}건</h6></div>
        </div>
        <div class="col-6 text-end">
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;" :disabled="isSelectedUser" @click="changeTenant">
            부서변경
          </material-button>
          <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;" :disabled="isSelectedUser" @click="checkDeleteUser">
            삭제
          </material-button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <EasyDataTable
          v-model:items-selected="selectedUser"
          table-class-name="customize-table"
          empty-message="조회된 내역이 없습니다."
          rows-per-page-message="페이지당 표시 행 수"
          rows-of-page-separator-message="중"
          :headers="tableColumns"
          :items="tableData"
          :loading="isLoading"
      >
        <template #loading>
          <img
              src="@/assets/img/Spinner-1s-200px.gif"
              style="width: 100px; height: 100px;"
          />
        </template>
      </EasyDataTable>
    </div>
  </div>
</template>
<script>
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import ApiCall from "@/Interface/ApiCall";
import { Auth } from 'aws-amplify'
import MaterialInput from "@/components/MaterialInput.vue";
import {formatDate} from "@fullcalendar/core";

import MaterialTable from "@/components/MaterialTable.vue";
import MaterialPagination from "@/components/MaterialPagination.vue";

export default {
  name: "UserList",
  components: {
    MaterialInput,
    MaterialButton,
  },
  data() {
    return {
      // tableColumns: ['발송종류','발송일자', '수신번호', '발송내용','상태','고객식별ID','캠페인ID',''],
      tableData: [],
      userId: '',
      userName: '',
      selectedTenant: null,
      selectedTenantModal: '',
      subTenantList: [],
      subTenantListForSearch: [],
      selectedUser: [],
      result_count: -1,
      isLoading: false,
      isSubTenantLoading: false,
      updateTenantModal: null,
      createSubTenantModal: null,
      newSubTenantName: '',
      tableColumns : [
        { text: "부서명", value: "tenant_name", sortable: true},
        { text: "아이디", value: "user_id", sortable: true},
        { text: "이름", value: "user_name", sortable: true},
        { text: "전화번호", value: "user_phone_number", sortable: true},
        { text: "이메일", value: "email", sortable: true},
        { text: "생성일", value: "create_timestamp", sortable: true},
      ],
      selectInstanceList: null,
      selectInstanceChange: null,
    }
  },
  computed: {
    isSelectedUser() {
      return this.selectedUser === null || this.selectedUser.length === 0;
    }
  },
  watch: {
    subTenantList(newList) {
      if (newList.length > 0) {
        this.selectedTenantModal = newList[0].value;
      }
    },
    subTenantListForSearch(newList) {
      if (newList.length > 0) {
        this.selectedTenant = newList[0].value;
      }
    }
  },
  beforeMount() {
  },
  mounted() {
    this.triggerGetTenant();
  },
  methods: {
    async triggerGetTenant() {
      await this.getTenantList();
      this.initChoicesList();
    },
    initChoicesList() {
      if (this.selectInstanceList) {
        this.selectInstanceList.destroy();
      }
      this.selectInstanceList = new Choices('#tenant-name', {
        shouldSort: false,
        choices: this.subTenantListForSearch,
      });
    },
    initChoicesChange() {
      if (this.selectInstanceChange) {
        this.selectInstanceChange.destroy();
      }
      this.selectInstanceChange = new Choices('#tenant-name-modal', {
        shouldSort: false,
        choices: this.subTenantListForSearch,
      });
    },
    async getTenantList() {
      await ApiCall.call('user/auth/subtenant/list', 'POST').then(async (res) => {
        this.subTenantListForSearch = []
        this.subTenantList = []

        console.log('subtenant list :', res.data)
        const optionData = res.data.map(item => ({
          label: item['tenant_name'],
          value: item['tenant_id']
        }));
        this.subTenantList = optionData;
        const all = [{ value: "all", label: "전체" }]

        // 자기 자신의 tenant
        const user = await Auth.currentAuthenticatedUser()
        const idToken = user.signInUserSession.idToken;
        const tenantId = idToken.payload['cognito:groups'][0];
        const tenantName = user.attributes['custom:tenant_name'];
        const myTenant = [{value:tenantId, label:tenantName}]
        this.subTenantListForSearch = this.subTenantListForSearch.concat(all)
        this.subTenantListForSearch = this.subTenantListForSearch.concat(myTenant)
        this.subTenantListForSearch = this.subTenantListForSearch.concat(optionData);
        this.subTenantList = myTenant.concat(this.subTenantList)


        console.log('tenant list :' + this.subTenantListForSearch);
      })
    },
    async changeData() {
      this.isLoading = true
      console.log("seleted tenant :",JSON.stringify(this.selectedTenant))

      const labeledTenantList = this.subTenantList.map(item => ({
        tenant_name : item['label'],
        tenant_id : item['value']
      }));

      const payload = {
        selected_sub_tenant: this.selectedTenant,
        sub_tenant_list: labeledTenantList
      }
      await ApiCall.call('user/auth/subtenant/userlist', 'POST', payload).then(async (res) => {
        console.log(res)
        if (res.status === 200) {
          this.tableData = res.data;
          this.isLoading = false;
        } else {
          alert(res.data.message)
          this.tableData = [];
          this.isLoading = false;
        }
      });
    },
    changeTenant() {
      const bootstrap = this.$store.state.bootstrap;
      // 모달 열기
      this.updateTenantModal = new bootstrap.Modal(
        document.getElementById("updateTenantModal")
      );
      this.updateTenantModal.show();

      this.initChoicesChange();
    },
    async updateTenant() {
      // console.log('selected tenant :', this.selectedTenantModal.tenant_id, this.selectedTenantModal.tenant_name)
      console.log("selected tenant :",this.selectedTenantModal)
      const foundTenant = this.subTenantList.find(tenant => tenant.value === this.selectedTenantModal);

      const payload = {
        user_list: this.selectedUser,
        target_tenant_id: foundTenant.value,
        target_tenant_name: foundTenant.label,
      }

      console.log('payload:', payload);

      await ApiCall.call('user/auth/tenant', 'POST', payload).then(async (res) => {
        console.log(res)
        await this.changeData();

        alert(res.data.message)
        // if (res.data.status === 200) { //   alert(res.data.message)
        // } else {
        //   alert(res.data.message)
        // }
        this.updateTenantModal.hide();

      })

      this.selectedUser = [];
    },
    async openSubTenantModal() {
      const bootstrap = this.$store.state.bootstrap;
      // 모달 열기
      this.createSubTenantModal = new bootstrap.Modal(
        document.getElementById("createSubTenantModal")
      );
      this.createSubTenantModal.show();

    },
    async createSubTenant() {
      console.log(this.newSubTenantName);

      const payload = {
        sub_tenant_name: this.newSubTenantName
      }

      await ApiCall.call('user/auth/subtenant', 'POST', payload).then(async (res) => {
        console.log(res)

        if (res.status === 200) {
          alert('생성 완료 되었습니다.')
          this.createSubTenantModal.hide();
          await this.triggerGetTenant();
        } else {
          alert('부서 생성이 실패 했습니다.' + res.data.message)
        }

      }).catch((error) => {
        console.log(error)
      });
    },
    async checkDeleteUser() {
      const confirmationMessage = "선택한 사용자들을 삭제 하시겠습니까?";
      if (window.confirm(confirmationMessage)) {
        await this.deleteUser();
      }
    },
    async deleteUser() {
      const payload = {
        user_list: this.selectedUser,
      }

      await ApiCall.call('user', 'DELETE', payload).then((res) => {
        console.log(res)

        alert('삭제 되었습니다.')

        // 완료 후 리프레쉬
        window.location.reload();

      }).catch((error) => {
        console.log(error)
      });
    }
  }
};
</script>
<style>
.spinner-icon {
  font-size: 40px;
}
.customize-table{
  --easy-table-border: 0px;
}
</style>
