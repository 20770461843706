<template>
  <li class="nav-item active">
    <div class="nav-link" @click="signOut">
      <span class="sidenav-mini-icon text-white"> {{ miniIcon }} </span>
      <span class="sidenav-normal me-3 ms-3 ps-1 text-white text-white">
        {{ text }}
      </span>
    </div>
  </li>
</template>
<script>

import { Auth } from 'aws-amplify'
import { useRouter } from 'vue-router'
export default {
  name: "SidenavItemCustom",
  props: {
    miniIcon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  setup() {
    const router = useRouter()

    async function signOut() {
      console.log('sign out')
      try {
        await Auth.signOut()
        console.log('User signed out successfully')
        router.push('/authentication/signin/basic')
      } catch (error) {
        console.error('Error signing out user', error)
      }
    }

    return {
      signOut,
    }
  },
};
</script>
