<template>
  <div class="conbined-textarea">
    <textarea
      v-bind="$attrs"
      :id="id"
      ref="contentTextarea"
      class="form-control content-textarea ps-2"
      :rows="currentRows"
      :name="name"
      :placeholder="placeholder"
      :isRequired="isRequired"
      :disabled="disabled"
      :value="modelValue"
      style="width: 250px"
      v-on="inputListeners"
      @input="$emit('input', $event.target.value)"
    ></textarea>
  </div>
</template>

<script>
import setMaterialInput from "@/assets/js/material-input.js";

export default {
  name: "MaterialTextarea",
  props: {
    variant: {
      type: String,
      default: "outline",
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Your text here...",
    },
    isRequired: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    title: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 0,
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      currentRows: 2,
      defaultRows: 2,
    }
  },
  computed: {
    inputListeners() {
      return {
        ...this.$attrs,
        input: (event) => {
          let truncatedValue = event.target.value;
          if (this.maxLength > 0) {
            const byteLength = this.calculateByteLength(truncatedValue);
            if (byteLength > this.maxLength) {
              truncatedValue = this.sliceToByteLength(truncatedValue, this.maxLength);
              event.target.value = truncatedValue;
            }
          }
          this.$emit("update:modelValue", truncatedValue);
        },
      };
    },
  },
  watch: {
    modelValue(newValue) {
      this.adjustRows();
    },
  },
  mounted() {
    this.adjustRows(); // Initial adjustment
  },
  methods: {
    calculateByteLength(content) {
      const iconv = require('iconv-lite');
      const bytes = iconv.encode(content, 'euc-kr');
      return bytes.length;
    },
    sliceToByteLength(value, byteLimit) {
      let truncatedValue = '';
      let byteLength = 0;
      for (const char of value) {
        byteLength += this.calculateByteLength(char);
        if (byteLength > byteLimit) {
          break;
        }
        truncatedValue += char;
      }
      return truncatedValue;
    },
    handleInput(event) {
      this.$emit('input', event.target.value);
      this.adjustRows();
    },
    adjustRows() {
      const content = this.modelValue;
      const byteLimit = 57; // Set your desired byte limit for line increase with Korean characters
      const maxRows = 17; // Set your maximum row limit here
      const textarea = this.$refs.contentTextarea; // Get a reference to the content textarea element

      if (textarea) {
        // Calculate the byte length of the content considering UTF-8 encoding
        const contentBytes = new TextEncoder().encode(content).length;

        // Calculate the number of line breaks in the content
        const lineBreaks = (content.match(/\n/g) || []).length;

        // Calculate the number of rows based on byte length and line breaks
        const estimatedRows =
          Math.ceil(contentBytes / byteLimit) +
          lineBreaks + 1 // Add 1 to account for the initial row

        // Set a minimum number of rows to prevent the textarea from becoming too small
        const minRows = 1;

        // Enforce the maximum row limit and minimum row limit
        this.currentRows = Math.min(Math.max(estimatedRows, minRows), maxRows);
      }
    }
  },
};
</script>

<style scoped>
.combined-textarea textarea {
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  padding: 8px;
  position: relative;
}

.content-textarea {
  border: 1px solid #ccc;
  border-radius: 10px; /* Round bottom corners only */
  margin-top: -1px; /* Align closely with the title textarea */
  background-color: #ffffff !important;
  resize: none;
  font-size: 0.875rem;
}

</style>
