<template>
  <div v-if="isLoading" class="position-absolute top-0 bottom-0 d-flex justify-content-center align-items-center">
    <div class="spinner-container">
      <i class="fas fa-spinner fa-spin spinner-icon text-info"></i>
    </div>
  </div>
  <div class="py-3 container-fluid">
    <div class="row">
      <div class="col-lg-5">        
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">알림톡</h6>
            </div>
          </div>
          <div class="p-3 card-body">
            <form ref="form" @submit.prevent="submitForm">
              <div class="row align-items-center">
                  <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">발신번호: </span>
                  </div>
                  <div class="col-sm-9">
                    <select
                      id="from-phone-number"
                      v-model="formData.from_phone_num"
                      class="form-control"
                    >
                    </select>
                  </div>
              </div>
              <div class="row align-items-center mt-2">
                  <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">예약발송:</span>
                  </div>
                  <div class="col-sm-9">
                      <material-input 
                          id="senddtm_datetime"
                          v-model="formData.send_request_dtm" 
                          size="sm" 
                          type="datetime-local"
                          step="60"
                          :min="minDatetime"
                      >
                      </material-input>
                  </div>
              </div>
              <div class="row align-items-center mt-2">
                  <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">전송방식:</span>
                  </div>
                  <div v-if="isBusinessVerified == '2'" class="col-sm-9 d-flex align-items-center">
                      <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">수동입력</label>
                      <material-radio 
                          id="manual"
                          v-model.sync="selectedSendMethod"
                          name="send_method"
                          style="margin-left: -20px"
                          :value="'manual'"
                          :checked="selectedSendMethod === 'manual'"
                          @change="onChangeSendMethod('manual')"
                      >
                      </material-radio>
                      <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">엑셀업로드</label>
                      <material-radio 
                          id="excel"
                          v-model.sync="selectedSendMethod"
                          name="send_method"
                          style="margin-left: -20px"
                          :value="'excel'"
                          :checked="selectedSendMethod === 'excel'"
                          @change="onChangeSendMethod('excel')"
                      >
                      </material-radio>
                      <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">주소록전송</label>
                      <material-radio 
                          id="address"
                          v-model.sync="selectedSendMethod"
                          name="send_method"
                          style="margin-left: -20px"
                          :value="'address'"
                          :checked="selectedSendMethod === 'address'"
                          @change="onChangeSendMethod('address')"
                      >
                      </material-radio>
                  </div>
              </div>
              <div v-if="selectedSendMethod == 'manual'" id="send_from_manual" class="row align-items-center mt-2">
                  <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">수동입력:</span>
                  </div>
                  <div class="col-sm-9">
                    <span style="font-weight: bold; font-size: 0.8rem;">전화번호</span>
                  <div id="to_phone_table" style="height: 250px; overflow-y: auto;">
                      <div class="table-responsive">
                      <table id="to-phone-list" class="table table-flush">
                          <tbody>
                            <tr v-for="(phone, index) in checkedPhones" :key="index">
                              <div v-if="index <= 4 || isBusinessVerified == '2'" class="d-flex align-items-start" style="padding: 0;">
                                <material-checkbox id="to_phone_number_checkbox" :checked="phone.checked" @click.stop @change="toggleCheckbox($event, index)" />
                                <material-input
                                  id="to_phone_number"
                                  v-model="phone.phoneNumber"
                                  size="sm"
                                  placeholder="수신번호"
                                  style="width: 150px;"
                                  @focus="handleFocus(index)">
                                </material-input>
                              </div>
                            </tr>
                          </tbody>
                      </table>
                      </div>
                  </div>
                  </div>
              </div>
              <div v-if="selectedSendMethod == 'excel'" id="send_from_excel" class="row align-items-center mt-2">
                <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">엑셀선택:</span><br>
                      <label class="btn-sm" style="display: inline-block; position: relative; cursor: pointer;">
                        <i class="material-icons icon-hover icon-large" style="cursor: pointer; margin-top: 0px; margin-left: -15px;"
                        @click="openFileDialog">upload</i>
                        <span style="cursor: pointer; display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 20px;" @click="openFileDialog">UPLOAD</span>
                        <input ref="fileInput" type="file" style="display: none" @change="handleFileUpload" />
                      </label><br><br>
                      <span style="font-weight: bold; font-size: 0.8rem;">샘플파일:</span><br>
                      <label class="btn-sm" style="display: inline-block; position: relative; cursor: pointer;">
                        <i class="material-icons icon-hover icon-large" style="cursor: pointer; margin-top: 0px; margin-left: -15px;"
                        @click="downloadExcelSample">download</i>
                        <span style="cursor: pointer; display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 20px;" @click="downloadExcelSample">DOWNLOAD</span>
                      </label>                   
                  </div>
                  <div id="address_name" class="col-sm-9" style="height: 300px; overflow-y: auto;">
                    <span style="font-weight: bold; font-size: 0.8rem;">주소록명</span><br>
                              <td style="padding: 5px;">
                              <div class="d-flex align-items-start">
                                <material-input id="address_name" v-model="formData.customer_group_alias" size="sm" style="margin-left: -3px;" :disabled="!fileSelected"></material-input>
                              </div>
                              </td>
                      <div class="table-responsive">
                      <table id="excel_table" class="table table-flush">
                          <div class="col-sm-5">
                              
                              <span style="font-weight: bold; font-size: 0.8rem;">전화번호</span><br>
                              <material-button
                              id="toPhoneNumberIndex"
                              color="success"
                              size="sm"
                              variant="outline"
                              class="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style="width: 180px"
                              :disabled = "!fileSelected"
                              >
                              {{ selectedPhoneNumberField || "전화번호헤더 선택" }}
                              </material-button>
                              <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                  <li v-for="field in headerFields" :key="field">
                                      <a class="dropdown-item border-radius-md" href="javascript:;"
                                          @click="handlePhoneSelection(field)">{{ field }}</a>
                                  </li>
                              </ul>
                              <br>
                              <span style="font-weight: bold; font-size: 0.8rem;">변수</span>
                              <div class="d-flex" style="width: 270px;">
                              <material-button
                              id="paramIndex1"
                              color="success"
                              size="sm"
                              variant="outline"
                              class="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style="width: 90px"
                              :disabled = "!phoneSelected"
                              >
                              {{ selectedParamField1 || "변수1" }}
                              </material-button>
                              <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                  <li v-for="field in headerFields" :key="field">
                                      <a class="dropdown-item border-radius-md" href="javascript:;"
                                          @click="handleParmSelection1(field)">{{ field }}</a>
                                  </li>
                              </ul>
                              <material-button
                              id="paramIndex2"
                              color="success"
                              size="sm"
                              variant="outline"
                              class="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style="width: 90px"
                              :disabled = "!param1Selected"
                              >
                              {{ selectedParamField2 || "변수2" }}
                              </material-button>
                              <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                  <li v-for="field in headerFields" :key="field">
                                      <a class="dropdown-item border-radius-md" href="javascript:;"
                                          @click="handleParmSelection2(field)">{{ field }}</a>
                                  </li>
                              </ul>
                              <material-button
                              id="paramIndex3"
                              color="success"
                              size="sm"
                              variant="outline"
                              class="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style="width: 90px"
                              :disabled = "!param2Selected"
                              >
                              {{ selectedParamField3 || "변수3" }}
                              </material-button>
                              <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                  <li v-for="field in headerFields" :key="field">
                                      <a class="dropdown-item border-radius-md" href="javascript:;"
                                          @click="handleParmSelection3(field)">{{ field }}</a>
                                  </li>
                              </ul>
                            </div>
                            <div class="d-flex" style="width: 270px;">
                              <material-button
                              id="paramIndex4"
                              color="success"
                              size="sm"
                              variant="outline"
                              class="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style="width: 90px"
                              :disabled = "!param3Selected"
                              >
                              {{ selectedParamField4 || "변수4" }}
                              </material-button>
                              <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                  <li v-for="field in headerFields" :key="field">
                                      <a class="dropdown-item border-radius-md" href="javascript:;"
                                          @click="handleParmSelection4(field)">{{ field }}</a>
                                  </li>
                              </ul>
                              <material-button
                              id="paramIndex5"
                              color="success"
                              size="sm"
                              variant="outline"
                              class="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style="width: 90px"
                              :disabled = "!param4Selected"
                              >
                              {{ selectedParamField5 || "변수5" }}
                              </material-button>
                              <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                  <li v-for="field in headerFields" :key="field">
                                      <a class="dropdown-item border-radius-md" href="javascript:;"
                                          @click="handleParmSelection5(field)">{{ field }}</a>
                                  </li>
                              </ul>
                              <material-button
                              id="paramIndex6"
                              color="success"
                              size="sm"
                              variant="outline"
                              class="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style="width: 90px"
                              :disabled = "!param5Selected"
                              >
                              {{ selectedParamField6 || "변수6" }}
                              </material-button>
                              <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                  <li v-for="field in headerFields" :key="field">
                                      <a class="dropdown-item border-radius-md" href="javascript:;"
                                          @click="handleParmSelection6(field)">{{ field }}</a>
                                  </li>
                              </ul>
                              </div>
                          </div>
                      </table>
                      </div>
                  </div>
              </div>
              <div v-if="selectedSendMethod == 'address'" id="send_from_customer_group" class="row align-items-center mt-2">
                  <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">주소록선택:</span><br>
                      <div style="display: flex;">
                      <material-button
                              id="toAddress"
                              color="success"
                              size="sm"
                              variant="outline"
                              class="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style="flex-grow: 1;"                              
                            >
                              {{ formData.customer_group_id || "주소록" }}
                            </material-button>
                            <ul class="dropdown-menu" aria-labelledby="customerGroupIds">
                              <li v-for="optionCustomerGroupId in optionCustomerGroupIds" :key="optionCustomerGroupId">
                                <a
                                  class="dropdown-item"
                                  href="javascript:;"
                                  @click="selectCustomerGroupId(optionCustomerGroupId)"
                                >
                                  {{ optionCustomerGroupId }}
                                </a>
                              </li>
                            </ul>
                            </div>
                  </div>
                  <div class="col-sm-9">
                    <span style="font-weight: bold; font-size: 0.8rem; margin-right: 0.5rem;">주소록명</span><span style="font-weight: bold; font-size: 0.7rem;">(최대 100건까지 조회됩니다.)</span><br>
                              <td style="padding: 5px;">
                              <div>
                                <material-input id="address_name" v-model="formData.customer_group_id" size="sm" style="margin-left: -3px;" readonly></material-input>
                                <span style="font-weight: bold; font-size: 0.7rem;">총 주소록 건수: {{contact_list_count}}</span>
                              </div>
                              </td>
                    <!-- <span style="font-weight: bold; font-size: 0.8rem;">전화번호</span> -->
                    <div id="address_table" style="height: 200px; overflow-y: auto;">
                        <div class="table-responsive">
                        <material-table
                          title="연락처"
                          :headers=tableColumns
                          :lists=contact_list
                          :font-size=fontSize
                        />
                        </div>
                    </div>
                  </div>
              </div>
              <div class="row align-items-center mt-2">
                <div class="col-sm-3 pr-0">
                  <span style="font-weight: bold; font-size: 0.8rem;">식별정보:</span>
                </div>
                <div class="col-sm-9">
                  <div class="mt-2">
                    <material-input 
                      id="primary_customer_reference_id"
                      v-model="formData.primary_customer_reference_id" 
                      type="text" 
                      size="sm"
                      label="고객식별ID"
                      label-size="sm"                  
                      variant="outline" 
                      maxlength="36"
                    >
                    </material-input>
                  </div>
                  <div class="mt-2">
                    <material-input 
                      id="secondary_customer_reference_id"
                      v-model="formData.secondary_customer_reference_id" 
                      type="text" 
                      size="sm"
                      label="캠페인ID"
                      label-size="sm"                  
                      variant="outline" 
                      maxlength="36"
                    >
                    </material-input>
                  </div>
                  
                </div>
              </div>
              <div class="row align-items-center" style="margin-top: 10px">
                  <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">결과알림:</span>
                  </div>
                  <div class="col-sm-9 d-flex">
                      <label for="send_alert" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">미수신</label>
                      <material-radio 
                          id="alert_n"
                          v-model.sync="selectedAlertMethod"
                          name="send_alert"
                          style="margin-left: -20px"
                          :value="'alert_n'"
                          :checked="selectedAlertMethod === 'alert_n'"
                          :disabled="formData.send_request_dtm == ''"
                          @change="onChangeAlertMethod('alert_n')"
                      >
                      </material-radio>
                      <label for="send_alert" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">수신</label>
                      <material-radio 
                          id="alert_y"
                          v-model.sync="selectedAlertMethod"
                          name="send_alert"
                          style="margin-left: -20px"
                          :value="'alert_y'"
                          :checked="selectedAlertMethod === 'alert_y'"
                          :disabled="formData.send_request_dtm == ''"
                          @change="onChangeAlertMethod('alert_y')"
                      >
                      </material-radio>
                      <div class="mt-0" style="margin-left: 10px;">
                        <material-input 
                        id="alert_phone_number"
                        v-model="formData.alert_phone_number" 
                        type="number" 
                        size="sm"
                        label="알림수신자번호"
                        label-size="sm"                  
                        variant="outline" 
                        maxlength="36"
                        :disabled=!isAlert
                      >
                      </material-input>
                      </div>
                  </div>
              </div>
              <div class="row align-items-center" style="margin-top: 7px">
                  <div class="col-sm-3 pr-0">
                      <span style="font-weight: bold; font-size: 0.8rem;">대체발송:</span>
                  </div>
                  <div class="col-sm-9 d-flex">
                    <label for="send_on_fail" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">LMS</label>
                    <material-checkbox 
                          id="checkboxNextMethodOnFail"
                          v-model="isNextMethodOnFailChecked"
                          name="checkboxNextMethodOnFail"
                          :checked="isNextMethodOnFailChecked"
                      >
                    </material-checkbox>
                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-3">        
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">템플릿</h6>
            </div>
          </div>
          <div class="p-3 card-body">
            <form ref="form" @submit.prevent="submitForm">
              <div class="row align-items-center">
                  <div class="col-sm-12">
                    <select
                      id="kakao-template-select"
                      v-model="selectedTemplate"
                      class="form-control"
                    >
                    </select>
                  <div v-for="key in kakao_param_list" :key="key" class="row align-items-center mt-1">
                    <div class="col-md-12">
                    <label style="font-weight: bold; font-size: 0.7rem; margin-bottom: 0px;">{{ key }} </label>
                    </div>
                    <div class="col-md-12">
                      <material-button
                        v-if="selectedSendMethod == 'excel'"
                        :id="'params-' + key"
                        color="success"
                        size="sm"
                        variant="outline"
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style="width: 100%"
                        :disabled="!param1Selected"
                      >
                        {{ selectedIndices.length ? selectedIndices.map(item => item.name).join(', ') : '변수' }}
                      </material-button>
                      <ul class="dropdown-menu" aria-labelledby="params">
                        <li v-for="paramIndex in params" :key="paramIndex" :value="paramIndex">
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            @click="selectIndex(paramIndex, key)"
                          >
                            {{ headerFields[paramIndex] }}
                          </a>
                        </li>
                      </ul>
                      <material-button
                        v-if="selectedSendMethod == 'address'"
                        :id="'address_params-' + key"
                        color="success"
                        size="sm"
                        variant="outline"
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style="width: 100%; height: 30px;"
                        :disabled="!addressSelected"
                      >
                        {{ selectedIndices.length ? selectedIndices.map(item => item.name).join(', ') : '변수' }}
                      </material-button>
                      <ul class="dropdown-menu" aria-labelledby="address_params">
                        <li v-for="(paramIndex, index) in address_params" :key="paramIndex" :value="paramIndex">
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            @click="selectAddressIndex(index, paramIndex, key)"
                          >
                            {{ paramIndex }}
                          </a>
                        </li>
                      </ul>
                      </div>
                      <div class="col-md-12">
                      <material-textarea 
                        id="params" 
                        v-model=kakao_param_input[key] 
                        name="params" 
                        class="text-xs" 
                        style="width: 100%; height: 60px; resize: none;" 
                        placeholder="직접 또는 변수순서 입력             예:{0},{1},..,{n}"  
                        maxlength="50"
                        @blur="handleTextareaBlur(key)">
                      </material-textarea>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
          <div class="d-flex justify-content-between">
            <h6 class="mb-0">내용</h6>
          </div>
          </div>
          <div class="p-3 card-body" style="position: relative;">
            <div class="row d-flex justify-content-center">
              <div class="image-container max-width-300" style="position: relative; display: inline-block;">
                <img src="@/assets/img/smartphone2.png" class="img-fluid border-radius-lg max-width-300">
                <div class="text-start" style="position: absolute; top: 18%; left: 52%; transform: translateX(-50%);">
                  <custom-textarea
                    id="content"
                    v-model:contentsValue="formatted_typed_content"
                    v-model:textTitleValue="formData.template_title"
                    v-model:textSubTitleValue="formData.template_subtitle"
                    placeholder="템플릿 내용을 입력하세요."
                    :disabled="true"
                    :emphasize-type="formData.template_emphasize_type"
                    :image-url="formData.template_image_url"
                    :buttons="formData.kakao_button"
                  >
                  </custom-textarea>
                </div>
                <p style="font-weight: bold; font-size: 0.8rem; text-align: left;">{{ calculateByteLength(typed_content) }}/2000 bytes</p>
                <div style="position: absolute; top: 5.5%; left: 55%; transform: translate(-50%, -50%);">
                  <span style="font-size: 0.9rem;">{{formData.profile_id}}</span>
                </div>
                <div style="position: absolute; top: 13%; left: 39%; transform: translate(-50%, -50%);">
                  <span style="font-size: 0.8rem;">{{formData.profile_id}}</span>
                </div>
              </div>
            </div>
            <div v-if="typed_content && calculateByteLength(typed_content) >= 2000">
                  <p class="text-danger" style="margin-top: -125px; font-weight: bold; font-size: 0.8rem; text-align: center;">입력할 수 있는 바이트 수가 초과되어<br>내용이 잘릴 수 있습니다.</p>
            </div>            
          </div>
          <div class="row position-absolute bottom-1 end-2">
            <div class="col-12 text-end">
              <material-button type="submit" color="primary" size="sm" variant="default" style="width: 120px" @click="submitForm">
                전송
              </material-button>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import CustomTextarea from "@/components/CustomTextarea.vue";
import ApiCall from "@/Interface/ApiCall";
import { Auth } from 'aws-amplify'
import * as XLSX from 'xlsx'
import MaterialTable from "@/components/MaterialTable.vue";
import { reactive } from 'vue'
import * as Choices from "choices.js";

export default {
  components: {
    MaterialButton,
    MaterialInput,
    MaterialCheckbox,
    MaterialRadio,
    CustomTextarea,
    MaterialTextarea,
    MaterialTable,
  },
  data() {
    return {
      formData: {
        from_phone_num: '',
        from_kakao_channel: '',
        teamplate_id: '',
        send_request_dtm: '',
        to_phone_number: [''],
        customer_group_id: '',
        content: '',
        customer_group_alias: '',
        excel_file_name: '',
        excelfile: '',
        primary_customer_reference_id: '',
        secondary_customer_reference_id: '',
        send_status: '',
        kakao_send_type: '',
        kakao_button: [],
        next_method_on_fail: '',
        alert_phone_number: '',
        template_title: '',
        template_subtitle: '',
        template_emphasize_type: 'NONE',
        template_image_name: '',
        template_image_url: '',
      },
      typed_content: '',
      to_phone_num_index: '',
      headerFields: [],
      params: [],
      address_params: [],
      selectedPhoneNumberField: '전화번호 선택',
      selectedParamField1: '변수1',
      selectedParamField2: '변수2',
      selectedParamField3: '변수3',
      selectedParamField4: '변수4',
      selectedParamField5: '변수5',
      selectedParamField6: '변수6',
      selectedSendMethod: 'manual',
      selectedAlertMethod: 'alert_n',
      checkedPhones: [{ checked: false, phoneNumber: '' }],
      optionFromPhoneNumbers: [],
      optionCustomerGroupIds: [],
      sendNumberGroupData: [],

      //이미지 업로드
      uploadImages: [
        { files: [], data: null, imageID: null },
        { files: [], data: null, imageID: null },
        { files: [], data: null, imageID: null }
      ],
      imageReturnID: [],

      contact_list: [], 
      fontSize: 'xs',
      tableData: [],
      tableColumns: ['전화번호', '변수',''],

      contact_list_count: 0,

      isLoading: true,
      isAlert: false,
      addressSelected: false,
      fileSelected: false,
      phoneSelected: false,
      param1Selected: false,
      param2Selected: false,
      param3Selected: false,
      param4Selected: false,
      param5Selected: false,
      param6Selected: false,
      isNextMethodOnFailChecked: false,
      template_alias: '',
      kakao_templates: [],
      kakao_template_options: [],
      kakao_param_list: [],
      kakao_param_input: {},
      previous_kakao_param_input: {},
      current_content: '',
      selectedIndices: [],
      optionFromKakaoChannel: [],
      selectInstance: null,
      selectInstanceTemplate: null,

      selectedTemplate: '',

      // 불법스팸 막기
      isBusinessVerified: '',
    };
  },
  computed: {
    minDatetime() {
      // Get the current datetime in Seoul time and round up to the nearest 10 minute interval
      let now = new Date();
      now.setSeconds(0);

      // Set the time zone offset to the offset for Seoul time (UTC+9)
      let timezoneOffset = 9 * 60; // in minutes
      now.setMinutes(now.getMinutes() + timezoneOffset);

      // Round up to the nearest 10 minute interval
      let roundedMinutes = Math.ceil(now.getMinutes() / 10) * 10;
      now.setMinutes(roundedMinutes);

      let datetimeString = now.toISOString().slice(0, -8);

      return datetimeString;
    },
    formatted_typed_content() {
      return this.typed_content.replace(/\\n/g, '\u000A');
    }
  },
  watch: {
    'checkedPhones': {
    handler(newVal, oldVal) {
      if (this.isBusinessVerified == '2'){
        for (let i = 0; i < newVal.length; i++) {
          // Replace hyphens and dots with an empty string
          newVal[i].phoneNumber = newVal[i].phoneNumber.replace(/[-. ]/g, '');

          // Find the first non-digit character
          const nonDigitOrAlphabetIndex = newVal[i].phoneNumber.search(/[^0-9a-zA-Z]/);

          if (nonDigitOrAlphabetIndex !== -1) {
            // Split the string at the first non-digit character
            const splitPhones = [
              newVal[i].phoneNumber.slice(0, nonDigitOrAlphabetIndex),
              newVal[i].phoneNumber.slice(nonDigitOrAlphabetIndex + 1)
            ].map(phone => phone.trim());

            // Update the current phone number with the part before the non-digit character
            this.checkedPhones[i].phoneNumber = splitPhones[0];

            // Append the rest of the string to the checkedPhones array
            const additionalPhones = splitPhones.slice(1).map(phone => ({ phoneNumber: phone, checked: newVal[0].checked }));
            this.checkedPhones.splice(i + 1, 0, ...additionalPhones);

            // Focus the next input box after the one that was changed
            this.$nextTick(() => {
              const nextInputRef = this.$refs[`input-${i + 1}`];
              if (nextInputRef && nextInputRef.$el) {
                nextInputRef.$el.focus();
              }
            });

            break; // Stop the loop after processing the first non-digit character
          }
        }
      }
    },
    deep: true
  },
    optionFromPhoneNumbers(newList) {
      if (newList.length > 0) {
        this.formData.from_phone_num = newList[0].value;
      }
    },
    selectedTemplate(option) {
      // template list에서 item.value (template_id) 와 일치하는것 로드
      const template = this.kakao_templates.filter(item => item.template_id === option)[0];
      this.typed_content = '';
      this.current_content = '';
      this.kakao_param_list = '';
      this.formData.from_kakao_channel = '';
      this.formData.template_id = '';
      this.formData.kakao_send_type = '';
      this.formData.kakao_button = '';
      if (template) {
        this.typed_content = template.template_content;
        this.current_content = this.typed_content;
      }
      if (template && template.param_list) {
        this.kakao_param_list = template.param_list;
      }
      if (template && template.from_kakao_channel) {
        this.formData.from_kakao_channel = template.from_kakao_channel;
      }
      if (template && template.external_reference_id) {
        this.formData.template_id = template.external_reference_id;
        // this.formData.template_id = 'abcd';
      }
      if (template && template.send_type) {
        this.formData.kakao_send_type = template.send_type;
      }
      if (template && template.button) {
        this.formData.kakao_button = template.button;
      }
      if (template && template.profile_id) {
        this.formData.profile_id = template.profile_id;
      }
      if (template && template.emphasize_type) {
        this.formData.template_emphasize_type = template.emphasize_type;
      } else {
        this.formData.template_emphasize_type = 'NONE';
      }
      if (template && template.image_name) {
        this.formData.template_image_name = template.image_name;
      } else {
        this.formData.template_image_name = ''
      }
      if (template && template.image_url) {
        this.formData.template_image_url = template.image_url;
      } else {
        this.formData.template_image_url = '';
      }
      if (template && template.title) {
        this.formData.template_title = template.title;
      } else {
        this.formData.template_title = '';
      }
      if (template && template.subtitle) {
        this.formData.template_subtitle = template.subtitle;
      } else {
        this.formData.template_subtitle = '';
      }
    }
  },
  mounted() {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      const idToken = user.signInUserSession.idToken;
      const tenantId = idToken.payload['cognito:groups'][0];
      const isBusinessVerified = user.attributes['custom:isBusinessVerified']
      this.tenantId = tenantId;
      this.isBusinessVerified = isBusinessVerified;

      const payload = {
        status: "active",
      };

      ApiCall.call('phone/from/numbers', 'POST', payload)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            let defaultOptionData = [{ value: "", label: "발신번호를 선택해주세요." }];
            const optionData = defaultOptionData.concat(response.data.map(item => {
              // Check if from_phone_number_alias is not null
              const label = item['from_phone_number_alias']
                ? '(' + item['from_phone_number_alias'] + ') ' + item['from_phone_number']
                : item['from_phone_number'];

              return {
                label: label,
                value: item['from_phone_number']
              };
            }));
            this.optionFromPhoneNumbers = optionData;
            console.log("optionsFromPhoneNumbers :", this.optionFromPhoneNumbers);
            this.initChoicesList();
            // console.log(this.optionFromPhoneNumbers);
          } else {
            console.error('Error: Invalid response data format');
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });


      const getGroupPayload = {
        status: "active",
      };

      ApiCall.call('group/list', 'POST', getGroupPayload)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.optionCustomerGroupIds = response.data;
            // console.log(this.optionCustomerGroupIds);
          } else {
            console.error('Error: Invalid response data format');
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });  

      const getTemplatePayload = {
        send_type: "kakao",
        template_status: "active",
      };

      ApiCall.call('template/list', 'POST', getTemplatePayload)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.kakao_templates = response.data;

            let defaultOptionData = [{ value: "", label: "템플릿을 선택해주세요." }];
            const optionData = defaultOptionData.concat(response.data.map(item => ({
              label: item['template_name'],
              value: item['template_id']
            })));
            this.kakao_template_options = optionData;
            this.initChoicesTemplate();
          } else {
            console.error('Error: Invalid response data format');
            alert("카카오톡 발신프로필 또는 알림톡 템플릿이 등록되어 있지 않습니다.\n발신프로필(검색용ID)은 [내 정보]->카카오톡 프로필 정보에서 '발신프로필 등록(@ID)'를 통해 등록 가능합니다.\n알림톡 템플릿은 프로필 등록 후 [내 정보]->카카오템플릿관리 화면에서 등록 하실 수 있습니다.");
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });   

      })();

    this.checkedPhones[0].checked = true;
    this.checkedPhones.push({ checked: false, phoneNumber: "" });
    this.isLoading = false;
  },
  methods: {
    initChoicesList() {
      if (this.selectInstance) {
        this.selectInstance.destroy();
      }
      this.selectInstance = new Choices('#from-phone-number', {
        shouldSort: false,
        choices: this.optionFromPhoneNumbers,
      });
    },
    initChoicesTemplate() {
      if (this.selectInstanceTemplate) {
        this.selectInstanceTemplate.destroy();
      }
      this.selectInstanceTemplate = new Choices('#kakao-template-select', {
        shouldSort: false,
        choices: this.kakao_template_options,
      });
    },
    calculateByteLength(content) {
      const iconv = require('iconv-lite');
      const bytes = iconv.encode(content, 'euc-kr');
      return bytes.length;
    },
    resetData(){
      this.isLoading = false;
      this.formData.from_phone_num= '';
      this.formData.from_kakao_channel= '';
      this.formData.teamplate_id='';
      this.formData.send_request_dtm= '';
      this.formData.to_phone_number= [''];
      this.formData.customer_group_id= '';
      this.formData.content= '';
      this.formData.customer_group_alias= '';
      this.excel_file_name = '',
      this.formData.excelfile= '';
      this.formData.primary_customer_reference_id= '';
      this.formData.secondary_customer_reference_id= '';
      this.formData.send_status= '';
      this.typed_content= '';
      this.to_phone_num_index= '';
      this.headerFields= [''];
      this.params= [''];
      this.selectedPhoneNumberField= '전화번호 선택';
      this.selectedParamField1= '변수1';
      this.selectedParamField2= '변수2';
      this.selectedParamField3= '변수3';
      this.selectedParamField4= '변수4';
      this.selectedParamField5= '변수5';
      this.selectedParamField6= '변수6';
      this.selectedSendMethod= 'manual';
      this.selectedAlertMethod= 'alert_n';
      this.contact_list = [], 
      this.tableData = [],
      this.checkedPhones = [{ checked: false, phoneNumber: '' }];
      this.checkedPhones[0].checked = true;
      this.checkedPhones.push({ checked: false, phoneNumber: '' });

      this.kakao_param_list = [];
      this.kakao_param_input = {};
      this.previous_kakao_param_input = {};
      this.current_content = '';
      this.formData.kakao_send_type = '';
      this.formData.kakao_button = '';
      this.contact_list_count = 0;
      this.isAlert = false;
      this.isNextMethodOnFailChecked = false;
      this.next_method_on_fail = '';
      this.formData.alert_phone_number = '';
      this.addressSelected = false;
      this.fileSelected = false;
      this.phoneSelected = false;
      this.param1Selected = false;
      this.param2Selected = false;
      this.param3Selected = false;
      this.param4Selected = false;
      this.param5Selected = false;
      this.param6Selected = false;

      this.formData.template_image_name = '';
      this.formData.template_image_url = '';
      this.formData.template_emphasize_type = '';
      this.formData.template_title = '';

      this.initChoicesList();
    },
    resetDataPartial(){
      this.isLoading = false;
      this.formData.to_phone_number= [''];
      this.formData.customer_group_id= '';
      this.formData.content= '';
      this.formData.customer_group_alias= '';
      this.excel_file_name = '',
      this.formData.excelfile= '';
      this.formData.send_status= '';
      this.typed_content= '';
      this.to_phone_num_index= '';
      this.headerFields= [''];
      this.params= [''];
      this.selectedPhoneNumberField= '전화번호 선택';
      this.selectedParamField1= '변수1';
      this.selectedParamField2= '변수2';
      this.selectedParamField3= '변수3';
      this.selectedParamField4= '변수4';
      this.selectedParamField5= '변수5';
      this.selectedParamField6= '변수6';
      this.selectedSendMethod= 'manual';
      this.contact_list = [], 
      this.tableData = [],
      this.checkedPhones = [{ checked: false, phoneNumber: '' }];
      this.checkedPhones[0].checked = true;
      this.checkedPhones.push({ checked: false, phoneNumber: '' });

      this.kakao_param_list = [];
      this.kakao_param_input = {};
      this.previous_kakao_param_input = {};
      this.current_content = '';
      this.formData.kakao_send_type = '';
      this.formData.kakao_button = '';
      this.contact_list_count = 0;

      this.addressSelected = false;
      this.fileSelected = false;
      this.phoneSelected = false;
      this.param1Selected = false;
      this.param2Selected = false;
      this.param3Selected = false;
      this.param4Selected = false;
      this.param5Selected = false;
      this.param6Selected = false;
    },
    getCurrentDateTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = '00';

      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    },
    selectFromPhone(option) {
      this.formData.from_phone_num = option;
    },
    selectFromKakaoChannel(option) {
      this.formData.from_kakao_channel = option;
    },
    handleTextareaBlur(key) {
      // console.log(`Textarea ${key} lost focus.`);
      console.log(this.kakao_param_input[key]);
      // console.log(key);
      const regex = new RegExp(`#{${key}}`, 'g');  // Create a regular expression to match all occurrences of the key string
      if (this.kakao_param_input[key] === '') {
        // If the textarea is empty, reset its value to the original value from kakao_param_list        
        this.kakao_param_input = {};
        this.previous_kakao_param_input = {};
        this.typed_content = this.current_content;
      } else {
        // If the textarea has a new value, replace the corresponding string in typed_content
        const new_value = this.kakao_param_input[key]|| `#{${key}}`;
        const previous_value = this.previous_kakao_param_input[key];
        if (previous_value !== undefined && previous_value !== null && previous_value !== new_value) {
          this.typed_content = this.typed_content.replace(previous_value, new_value);
        } else {
          this.typed_content = this.typed_content.replace(regex, new_value);
        }
        // Update the previous_kakao_param_input with the new value
        this.previous_kakao_param_input[key] = new_value;
      }
      // Add any additional logic here
    },
    selectIndex(paramIndex, key) {
      const selected = [];
      for (const index of this.params) {
        if (index === paramIndex) {
          selected.push({ index, name: this.headerFields[index] });
          // console.log(this.headerFields[index]);
          // console.log(key);
          this.kakao_param_input[key] = '{'+this.headerFields[index]+'}';
          // console.log(this.kakao_param_input[paramIndex]);
          const regex = new RegExp(`#{${key}}`, 'g');
          const new_value = this.kakao_param_input[key]|| `#{${key}}`;
          const previous_value = this.previous_kakao_param_input[key];
          if (previous_value !== undefined && previous_value !== null && previous_value !== new_value) {
            this.typed_content = this.typed_content.replace(previous_value, new_value);
          } else {
            this.typed_content = this.typed_content.replace(regex, new_value);
          }
          // Update the previous_kakao_param_input with the new value
          this.previous_kakao_param_input[key] = new_value;
          // this.handleTextareaBlur(paramIndex);
        }
      }
      //this.selectedIndices = selected;
    },
    selectAddressIndex(index, paramIndex, key) {
      this.kakao_param_input[key] = '{'+index+'}';
      const regex = new RegExp(`#{${key}}`, 'g');
      const new_value = this.kakao_param_input[key]|| `#{${key}}`;
      const previous_value = this.previous_kakao_param_input[key];
      if (previous_value !== undefined && previous_value !== null && previous_value !== new_value) {
        this.typed_content = this.typed_content.replace(previous_value, new_value);
      } else {
        this.typed_content = this.typed_content.replace(regex, new_value);
      }
      // Update the previous_kakao_param_input with the new value
      this.previous_kakao_param_input[key] = new_value;
    },
    selectCustomerGroupId(option) {
      this.isLoading = true;
      this.formData.customer_group_id = option;

      const getcontactlistPayload = {
        customer_group_id: this.formData.customer_group_id,
        limit: "100",
      };

      ApiCall.call('group/contact/list', 'POST', getcontactlistPayload).then(res => {
        this.contact_list_count = res.data.record_count;
        const contact_list = res.data.data.map(item => {
          return {
            // primary: item.phone_number,
            primary: item.phone_number.substring(0, item.phone_number.length - 4) + '****',
            values: [ 
              item.param_list,            
            ],
            secondary: '',
            secondary_color: '',
            info: '',
            image: '',
            image_color: '',
            icon: '',
          };
        });
        this.tableData = res.data.data;
        // this.contact_list = contact_list;
        this.contact_list = contact_list.map(item => ({
          ...item,
          values: item.values.map(value => String(value).replace(/"/g, '')),
        }));
        const newArray = this.contact_list[0].values[0].split(",");
        this.address_params = newArray;
      });
      this.addressSelected = true;
      this.isLoading = false;
    },
    downloadExcelSample(){
      const filePath = '/엑셀샘플.xlsx';
      window.location.href = filePath;
    },
    handleFileUpload(event) {
          const file = event.target.files[0]
          // console.log('file:', file)


          if (file) {
              const reader = new FileReader()
              // console.log('reader:', reader)
              reader.onload = () => {
                  const fileData = reader.result
                  const workbook = XLSX.read(fileData, { type: 'binary' })
                  const sheetName = workbook.SheetNames[0]
                  const sheet = workbook.Sheets[sheetName]
                  const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 })
                  this.headerFields = rows[0]
                  this.formData.excelfile = file.name
                  this.formData.customer_group_alias = file.name.split('.').slice(0, -1).join('.');
                  this.excel_file = file;
              };
              reader.readAsBinaryString(file)
              
              this.selectedPhoneNumberField = ''
              this.to_phone_num_index = ''
              this.selectedParamField1 = ''
              this.selectedParamField2 = ''
              this.selectedParamField3 = ''
              this.selectedParamField4 = ''
              this.selectedParamField5 = ''
              this.selectedParamField6 = ''
              this.params.length = 0;
              this.fileSelected = true;
              this.phoneSelected = false;
              this.param1Selected = false;
              this.param2Selected = false;
              this.param3Selected = false;
              this.param4Selected = false;
              this.param5Selected = false;
              this.param6Selected = false;
          }
      },
      handlePhoneSelection(option) {
          this.selectedPhoneNumberField = option
          this.to_phone_num_index = this.headerFields.indexOf(option)
          console.log("to_phone_num_index :", this.to_phone_num_index)
          this.phoneSelected = true;
      },
      handleParmSelection1(option) {
          this.typed_content = this.typed_content.replace("{"+this.selectedParamField1+"}", "");
          this.selectedParamField1 = option
          this.params[0] = this.headerFields.indexOf(option)
          this.param1Selected = true;
          // this.typed_content += "{"+this.selectedParamField1+"}"
          
      },
      handleParmSelection2(option) {
          this.typed_content = this.typed_content.replace("{"+this.selectedParamField2+"}", "");
          this.selectedParamField2 = option
          this.params[1] = this.headerFields.indexOf(option)
          this.param2Selected = true;
          // this.typed_content += "{"+this.selectedParamField2+"}"
          
      },
      handleParmSelection3(option) {
        this.typed_content = this.typed_content.replace("{"+this.selectedParamField3+"}", "");
          this.selectedParamField3 = option
          this.params[2] = this.headerFields.indexOf(option)
          this.param3Selected = true;
          // this.typed_content += "{"+this.selectedParamField3+"}"
          
      },
      handleParmSelection4(option) {
        this.typed_content = this.typed_content.replace("{"+this.selectedParamField4+"}", "");
          this.selectedParamField4 = option
          this.params[3] = this.headerFields.indexOf(option)
          this.param4Selected = true;
          // this.typed_content += "{"+this.selectedParamField4+"}"
          
      },
      handleParmSelection5(option) {
        this.typed_content = this.typed_content.replace("{"+this.selectedParamField5+"}", "");
          this.selectedParamField5 = option
          this.params[4] = this.headerFields.indexOf(option)
          this.param5Selected = true;
          // this.typed_content += "{"+this.selectedParamField5+"}"
          
      },
      handleParmSelection6(option) {
        this.typed_content = this.typed_content.replace("{"+this.selectedParamField6+"}", "");
          this.selectedParamField6 = option
          this.params[5] = this.headerFields.indexOf(option)
          this.param6Selected = true;
          // this.typed_content += "{"+this.selectedParamField6+"}"
          
      },
    onChangeSendMethod(option) {
      this.resetDataPartial();
      this.selectedSendMethod = option;
    },
    onChangeAlertMethod(option) {      
      this.selectedAlertMethod = option;  
      if(option == 'alert_n'){
        this.formData.alert_phone_number = '';
        this.isAlert = false;
      }else{
        this.isAlert = true;
      }    
    },
    handleFocus(index) {
      if (index === 0) {
        const firstCommaIndex = this.checkedPhones[0].phoneNumber.indexOf(',');
        if (firstCommaIndex !== -1) {
          this.checkedPhones[0].phoneNumber = this.checkedPhones[0].phoneNumber.substring(0, firstCommaIndex);
        }
      }
    },
    toggleCheckbox(event, idx) {
      if (this.checkedPhones[idx].checked){
        this.checkedPhones[idx].checked = false;
        if(this.checkedPhones[idx].phoneNumber == '')
          this.checkedPhones.splice(idx, 1);
      } else {
        this.checkedPhones[idx].checked = true;
        if (idx == this.checkedPhones.length-1)
          this.checkedPhones.push({ checked: false, phoneNumber: "" });
      }
      this.$nextTick(() => {
          const table = document.getElementById("to_phone_table");
          table.scrollTo(0, table.scrollHeight);
          });
    },
    submitForm(){
      if(this.formData.from_phone_num.length <= 0){
        alert("발신번호를 선택해주세요.");
        return;
      }
      if(this.formData.from_kakao_channel.length <= 0){
        alert("템플릿을 선택해주세요.");
        return;
      }
      if(this.typed_content.length <= 0){
        alert("전송 메시지를 입력해주세요.");
        return;
      }
      // if(Object.keys(this.kakao_param_input).length !== this.kakao_param_list.length){
      //   alert("템플릿 변수를 모두 입력해주세요.");
      //   return;
      // }
      if(this.selectedSendMethod == 'manual'){
        if (!this.checkedPhones.every(phone => {
          return !phone.checked || (phone.phoneNumber !== '' && phone.phoneNumber !== null);
        })) {
          alert('체크한 수신번호를 모두 입력해주세요.');
          return;
        }
        if (!this.checkedPhones.every(phone => {
          if (phone.checked && phone.phoneNumber !== '') {
            if (phone.phoneNumber.length < 8 || phone.phoneNumber.length > 12){
              return false;
            }

            return !isNaN(parseInt(phone.phoneNumber));
          }
          return true;
        })) {
          alert('유효한 전화번호를 입력해주세요.');
          return;
        }
      }
      if(this.selectedSendMethod == 'excel'){
        if (!this.excel_file || this.excel_file.length <= 0) {
          alert("전화번호가 있는 엑셀을 선택해주세요.");
          return;
        }
        if(this.to_phone_num_index.length <= 0){
          alert("전화번호가 있는 컬럼을 선택해주세요.");
          return;
        }        
      }
      if(this.selectedSendMethod == 'address'){
        if (this.formData.customer_group_id.length <= 0) {
          alert("주소록을 선택해주세요.");
          return;
        }      
      }
      if(this.isAlert){
        if (this.formData.alert_phone_number.length <= 0) {
          alert("알림수신번호를 입력해주세요.");
          return;
        }   
        if (!this.formData.alert_phone_number.startsWith('01')){
          alert('유효한 알림수신번호를 입력해주세요.');
          return false;
        }
        if (this.formData.alert_phone_number.length != 10 && this.formData.alert_phone_number.length != 11 && this.formData.alert_phone_number.length != 12){
          alert('유효한 알림수신번호를 입력해주세요.');
          return false;
        }
      }
      if(this.formData.send_request_dtm){
        const currentTime = new Date();
        const requestTime = new Date(this.formData.send_request_dtm);
        const thirtyMinutesFromNow = new Date(currentTime.getTime() + (21 * 60000));
        const minutes = requestTime.getMinutes();
        if (minutes % 1 !== 0) {
          alert("예약전송은 1분 단위로 전송 가능합니다.");
          return;
        }
        // console.log(requestTime);
        // console.log(thirtyMinutesFromNow);
        if (requestTime < thirtyMinutesFromNow) {
          alert("예약전송은 최소 30분 이후부터 가능합니다.");
          return;
        }        
        this.submitFormData();
      } else {
        this.submitFormData();
      }
    },
    showConfirmation(count) {
      var confirmationMessage = ""
      if (this.formData.send_status == 'schedule-request'){
        confirmationMessage = "총" + count + "건의 메시지가 예약 됩니다. 예약 하시겠습니까?";
      } else {
        confirmationMessage = "총" + count + "건의 메시지는 즉시전송 됩니다. 전송 하시겠습니까?";
      }
      if (window.confirm(confirmationMessage)) {
        return true;
      } else {
        this.isLoading = false;
        return false;
      }
    },
    async submitFormData() {
      this.isLoading = true;

      this.formData.to_phone_number = this.checkedPhones
        .filter((phone) => phone.checked && phone.phoneNumber !== '')
        .map((phone) => phone.phoneNumber);
      this.formData.content = this.typed_content;
      this.formData.content = this.formData.content.replace(new RegExp("{"+this.selectedParamField1+"}", "g"), "{0}");
      this.formData.content = this.formData.content.replace(new RegExp("{"+this.selectedParamField2+"}", "g"), "{1}");
      this.formData.content = this.formData.content.replace(new RegExp("{"+this.selectedParamField3+"}", "g"), "{2}");
      this.formData.content = this.formData.content.replace(new RegExp("{"+this.selectedParamField4+"}", "g"), "{3}");
      this.formData.content = this.formData.content.replace(new RegExp("{"+this.selectedParamField5+"}", "g"), "{4}");
      this.formData.content = this.formData.content.replace(new RegExp("{"+this.selectedParamField6+"}", "g"), "{5}");

      
      // alert(JSON.stringify(this.formData));
      // alert(JSON.stringify(this));
      const sendRequestDtm = '';
      //send SMS
      if (!this.formData.send_request_dtm) {
        this.sendRequestDtm = this.getCurrentDateTime();
        this.formData.send_status = "request"
      } else {
        this.formData.send_status = "schedule-request"
        const date = new Date(this.formData.send_request_dtm);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = '00';

        this.sendRequestDtm = `${year}${month}${day}${hours}${minutes}${seconds}`;
      }

      const toPhoneNumbersWithParams = this.formData.to_phone_number.map((phone) => {
        return {
          phone: phone,
          params: this.params
        };
      });

      if(this.isNextMethodOnFailChecked){
        this.formData.next_method_on_fail = "lms"
      } else {
        this.formData.next_method_on_fail = ""
      }

      //파라미터 입력 안했을 경우 #{ㅇㅇㅇ} 내용 replace
      this.formData.content = this.formData.content.replace(/#\{[^}]*\}/g, '');

      const payload = {
          contents: this.formData.content,
          send_request_dtm: this.sendRequestDtm,
          from_phone_number: this.formData.from_phone_num, 
          from_kakao_channel: this.formData.from_kakao_channel,
          template_id: this.formData.template_id,
          from_country_code: "82",         
          to_country_code: "82",
          next_method_on_fail: this.formData.next_method_on_fail,
          secondary_customer_reference_id: this.formData.secondary_customer_reference_id,
          primary_customer_reference_id: this.formData.primary_customer_reference_id,
          send_status: this.formData.send_status,
          result_alarm_phone_number: this.formData.alert_phone_number,
          send_type: this.formData.kakao_send_type,
          button: this.formData.kakao_button,
          emphasize_type: this.formData.template_emphasize_type,
          img_url: this.formData.template_image_url,
          title: this.formData.template_title,
      };
      console.log(payload);
      if (this.selectedSendMethod == 'manual'){
        if (this.showConfirmation(toPhoneNumbersWithParams.length)){
          try {
            payload.to_phone_numbers = toPhoneNumbersWithParams;
            console.log(payload);
            const response = await ApiCall.call('message/kakao', 'POST', payload);
            // const response = await ApiCall.call('안보내기', 'POST', payload);
            console.log(response.data);
            alert("알림톡 전송 성공");
          } catch (error) {
            console.error(error);
            alert("알림톡 전송 실패");
          }
          this.resetData();
        }
      } else if (this.selectedSendMethod == 'excel') {
        const payloadFile = {
          customer_group_id: this.formData.customer_group_alias,
          phone_number_index: this.to_phone_num_index,
          params: this.params,
        };
        // console.log(payloadFile);
        // console.log(payload);
        try {
          const fileResponse = await ApiCall.file('upload/excel', 'POST', payloadFile, null, this.excel_file, null, false);
          console.log(fileResponse.data);
          if (this.showConfirmation(fileResponse.data.total_count) && fileResponse.data.customer_group_id) {
            payload.customer_group_id = fileResponse.data.customer_group_id;
            console.log(payload);
            const response = await ApiCall.call('message/group/kakao', 'POST', payload);
            // const response = await ApiCall.call('안보내기', 'POST', payload);
            console.log(response.data);
            alert("알림톡 전송 성공");
            this.resetData();
          }
        }
        catch (error) {
          console.error(error);
          alert("알림톡 전송 실패");
          this.resetData();
        }
      } else if (this.selectedSendMethod == 'address') {
        if (this.showConfirmation(this.contact_list_count)){
          payload.customer_group_id = this.formData.customer_group_id;
          console.log(payload);
          try {
            const response = await ApiCall.call('message/group/kakao', 'POST', payload);
            // const response = await ApiCall.call('안보내기', 'POST', payload);
            console.log(response.data);
            alert("알림톡 전송 성공");
          } catch (error) {
            console.error(error);
            alert("알림톡 전송 실패");
          }
          this.resetData();
        }
      }
      this.isLoading = false;  
    },
  },
};

</script>

<style>
#to_phone_table {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
#excel_table {
  border: 0px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
#address_table {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
.material-textarea {
  white-space: pre-wrap;
}
.spinner-container {
  z-index: 9999; /* Set a high z-index value */
}
</style>
