<template>
  <div class="radio-group">
    <button
      v-for="(option, index) in options"
      :key="index"
      class="custom-button"
    >
      {{ option }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'CustomViewKeyword',
  props: {
    options: {
      type: Array,
      required: true
    }
  },
};
</script>

<style>
.radio-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

button.custom-button {
  margin: 5px;
  flex: 1 1 calc(33.333% - 10px); /* 각 버튼이 전체 너비의 1/3을 차지하도록 계산, 마진 고려 */
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 5px 20px;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: none;
  font-size: .75rem;
  text-align: center;
}

button.custom-button.selected {
  background-color: dimgray;
  color: #fff;
}
</style>
