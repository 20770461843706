<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h6 class="font-weight-bolder mb-4">👥 전송대상</h6>
      <div class="row">
        <div>
          <label class="typo_label">📑 마케팅 DB를 선택해주세요.</label>
          <multiselect
            v-model="selectedDb"
            deselect-label="Can't remove this value"
            track-by="tenant_name"
            label="tenant_name"
            placeholder="Select one"
            :options="dbs"
            :searchable="false"
            :allow-empty="false"
          >
            <template #singleLabel="{ option }" >
              <strong>{{ option.tenant_name }}</strong>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="row">
        <div>
          <label class="typo__label">세그먼트 목록</label>
          <multiselect
            v-model="selectedSegments"
            :options="segments"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            label="segment_name"
            track-by="segment_name"
            :preselect-first="false"
            :taggable="true"
          >
<!--            <template #selection="{ values, isOpen }" >-->
<!--                    <span-->
<!--                      class="multiselect__single"-->
<!--                      v-if="values.length"-->
<!--                      v-show="!isOpen"-->
<!--                    >-->
<!--                      {{ values.length }} options selected-->
<!--                    </span>-->
<!--            </template>-->
          </multiselect>
          <div v-for="(value, index) in formattedSelectedSegments" :key="value">
            <label class="typo__label">{{ value.segment_name }}</label>
            <multiselect v-if="!startsWithIntegers(value.segment_elements[0].detail_name)"
                         v-model="selectedSegmentsDetails[index]"
                         :options="value.segment_elements"
                         :multiple="true"
                         :close-on-select="false"
                         :clear-on-select="false"
                         :preserve-search="true"
                         placeholder="Pick some"
                         label="detail_name"
                         track-by="detail_name"
                         :preselect-first="false"
                         :taggable="true"
            >
<!--              <template #selection="{ values, isOpen }" >-->
<!--                <span-->
<!--                  class="multiselect__single"-->
<!--                  v-if="values.length"-->
<!--                  v-show="!isOpen"-->
<!--                >-->
<!--                  {{ values.length }} options selected-->
<!--                </span>-->
<!--              </template>-->
            </multiselect>
            <div v-if="startsWithIntegers(value.segment_elements[0].detail_name)">
              <div class="row">
                <material-input-between
                  :segment-name="value.segment_name"
                  @changed="handleBetween(index, $event)"
                >
                </material-input-between>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <h6 class="font-weight-normal">📑 예상 발송 건수 : {{ expected_number }} 건</h6>
        </div>
        <div class="col-4"></div>
        <div class="col-4 text-end">
          <div class="row">
            <div class="col-6">
              <material-button-progress
                type="button"
                color="dark"
                variant="gradient"
                class="ms-auto mb-0 js-btn-next"
                @click="resetSegmentSelect"
              >
                초기화
              </material-button-progress>
            </div>
            <div class="col-6">
              <material-button-progress
                type="button"
                color="dark"
                variant="gradient"
                class="ms-auto mb-0 js-btn-next ms-10"
                :is-loading="isGetCountLoading"
                @click="patchSegmentCount"
              >
                대상지정
              </material-button-progress>
            </div>
          </div>
        </div>
      </div>
      <h6 class="font-weight-bolder mt-5 mb-4">💬 전송정보</h6>
      <div class="row mt-3">
        <div class="col-4">
          <p class="text-md font-weight-light">광고를 발송할 시간을 입력해주세요.</p>
        </div>
        <div class="col-6">
          <material-input
            id="senddtm_datetime"
            v-model="send_request_dtm"
            size="default"
            type="datetime-local"
            step="600"
            :min="minDatetime"
          >
          </material-input>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <p class="text-md font-weight-light">캠페인명을 입력해주세요.</p>
        </div>
        <div class="col-6">
          <material-input-vmodel
            id="secondary_customer_reference_id"
            v-model="secondary_customer_reference_id"
          >
          </material-input-vmodel>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="$parent.prevStep"
        >뒤로</material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="handleSetTarget"
          >설정완료</material-button
        >
      </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import Multiselect from "vue-multiselect";
import CustomSelectContents from "@/components/CustomSelectContents.vue";
import ApiCall from "@/Interface/ApiCall";
import MaterialInputBetween from "@/components/MaterialInputBetween.vue";
import MaterialButtonProgress from "@/components/MaterialButtonProgress.vue";
import MaterialInputVmodel from "@/components/MaterialInputVmodel.vue";

export default {
  name: "TargetSelect",
  components: {
    MaterialButton,
    Multiselect,
    MaterialInputBetween,
    MaterialButtonProgress,
    MaterialInput,
    MaterialInputVmodel,
  },
  props: {
    campaignInfo: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:campaignInfo'],
  data() {
    return {
      dbs: [],
      selectedDb: '',
      segments: [],
      selectedSegments: [],
      formattedSelectedSegments: [],
      selectedSegmentsDetails: [],
      isGetCountLoading: false,
      intSegmentsDetails: [],
      selectedDbObject: {},
      expected_number: 0,
      send_request_dtm: '',
      secondary_customer_reference_id: '',
      isSetSegment: false,
    }
  },
  watch: {
    selectedDb(newList) {
      if (newList !== '') {
        // this.selectedDbObject = this.findObjectByValue(this.dbs, 'tenant_id', newList);
        this.$emit(
          'update:campaignInfo',
          {
            ...this.campaignInfo,
            from_phone_number: newList.from_phone_number,
            approve_tenant_id: newList.tenant_id,
            approve_tenant_name: newList.tenant_name,
          }
        );
        this.getSegment();
      }
    },
    selectedSegments(newList) {
      console.log("selected segments : " + JSON.stringify(newList))

      this.formattedSelectedSegments = newList.map(item => {
        return {
          segment_name: item.segment_name,
          segment_elements: item.segment_elements.map(detail_name => {
            return { segment_name: item.segment_name, detail_name };
          })
        };
      });

      console.log("formatted selected segments : " + JSON.stringify(this.formattedSelectedSegments))
    },
    send_request_dtm(newValue) {
      const date = new Date(newValue);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      const sendRequestDtm = `${year}${month}${day}${hours}${minutes}${seconds}`;
      this.$emit('update:campaignInfo', { ...this.campaignInfo, send_request_dtm: sendRequestDtm, original_send_request_dtm: newValue});
    },
    secondary_customer_reference_id(newValue) {
      this.$emit('update:campaignInfo', { ...this.campaignInfo, secondary_customer_reference_id: newValue});
    },
    expected_number(newValue) {
      this.$emit('update:campaignInfo', { ...this.campaignInfo, target_count: newValue});
    }
  },
  mounted() {
    this.getDbs();
  },
  methods: {
    getDbs() {
      ApiCall.call('marketing/target/list', 'POST').then(response =>{
        // console.log("response :"+ JSON.stringify(response));
        if (response.status === 200) {
          this.dbs = response.data
        } else {
          this.dbs = []
        }
        // this.initChoicesList();
      }).catch(error => {
        console.error('Error: Unable to fetch data from API', error);
      });
    },
    getSegment() {
      this.isGetCountLoading = true;
      const payload = {
        tenant_id: this.selectedDb.tenant_id,
      }
      ApiCall.call('marketing/segment/list', 'POST', payload).then(response =>{
        // console.log("response :"+ JSON.stringify(response));
        this.segments = response.data




        console.log("options : " + JSON.stringify(this.segmentOptions));
      }).catch(error => {
        console.error('Error: Unable to fetch data from API', error);
      });
      this.isGetCountLoading = false;
    },
    startsWithIntegers(detailName) {
      return detailName.startsWith("integer");
    },
    handleBetween(index, newList) {
      console.log("index : " + JSON.stringify(index))
      console.log("between : " + JSON.stringify(newList))

      const value = newList.value
      this.selectedSegmentsDetails[index] = value.map(obj => ({
        segment_type: "BETWEEN",
        ...obj
      }));
      console.log("after between : " + JSON.stringify(this.selectedSegmentsDetails))
    },
    async patchSegmentCount() {
      this.isGetCountLoading = true;
      console.log("selectedSegmentsDetails : " + JSON.stringify(this.selectedSegmentsDetails))
      console.log("intSegmentsDetails : " + JSON.stringify(this.intSegmentsDetails))

      const transformedObject = {};
      this.selectedSegmentsDetails.forEach(segmentArray => {
        segmentArray.forEach(item => {
          if (!transformedObject[item.segment_name]) {
            if (Object.prototype.hasOwnProperty.call(item, 'segment_type')) {
              if (item.segment_type === "BETWEEN") {
                transformedObject[item.segment_name] = ["BETWEEN"];
              } else {
                // 정의되지 않은 연산자 처리, 우선 OR로
                transformedObject[item.segment_name] = ["OR"];
              }
            } else {
              transformedObject[item.segment_name] = ["OR"];
            }
          }
          transformedObject[item.segment_name].push(item.detail_name);
        });
      });

      console.log("targetSegment :" + JSON.stringify(transformedObject));

      this.$emit('update:campaignInfo', {
        ...this.campaignInfo,
        target_segment: transformedObject,
      })

      const payload = {
        approve_tenant_id: this.selectedDb.tenant_id,
        target_segment: transformedObject,
        total_count: this.selectedDbObject.total_count,
      }

      await ApiCall.call('marketing/segment/count', 'POST', payload).then(response => {
        console.log(JSON.stringify(response))
        if (response.status === 200) {
          this.expected_number = parseInt(response.data.expected_number);
          this.isSetSegment = true;
        } else {
          alert("대상지정에 실패하였습니다. 초기화후 재시도해주세요.")
        }
      }).catch(error => {
        console.error('Error: Unable to fetch data from API', error);
      });
      this.isGetCountLoading = false;
    },
    minDatetime() {
      // Get the current datetime in Seoul time and round up to the nearest 10 minute interval
      let now = new Date();
      now.setSeconds(0);

      // Set the time zone offset to the offset for Seoul time (UTC+9)
      let timezoneOffset = 9 * 60; // in minutes
      now.setMinutes(now.getMinutes() + timezoneOffset);

      // Round up to the nearest 10 minute interval
      let roundedMinutes = Math.ceil(now.getMinutes() / 10) * 10;
      now.setMinutes(roundedMinutes);

      let datetimeString = now.toISOString().slice(0, -8);

      return datetimeString;
    },
    resetSegmentSelect() {
      this.selectedDb = '';
      this.selectedSegments = [];
      this.selectedSegmentsDetails = [];

      this.isSetSegment = false;
    },
    handleSetTarget() {
      if ( this.campaignInfo.send_request_dtm !== '' &&
        this.campaignInfo.secondary_customer_reference_id !== '') {
        this.$emit(
          'update:campaignInfo',
          {
            ...this.campaignInfo,
            confirm_step: 5,
          }
        );
        this.$parent.nextStep();
      } else if (!this.isSetSegment) {
        alert("대상지정을 해주세요.");
      } else {
        alert("입력정보를 모두 입력해주세요.");
      }
    },
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
