<template>
  <div v-if="isLoading" class="position-absolute top-0 bottom-0 d-flex justify-content-center align-items-center">
    <div class="spinner-container">
      <i class="fas fa-spinner fa-spin spinner-icon text-info"></i>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div id="kakaoTemplateCard" class="card mt-0">
      <div class="card-header">
        <h6 class="mb-0">친구톡</h6>
      </div>
      <div class="card-body pt-0">
        <div class="row align-items-start mt-2">
          <div class="col-8">
            <div class="row align-items-center">
              <div class="col-3">
                <span style="font-weight: bold; font-size: 0.8rem;">친구톡 타입</span>
              </div>
              <div class="col-9 mt-2">
                <select
                  id="friendTalkTypeSelect"
                  v-model="selectedFriendTalkType"
                  class="form-control"
                >
                </select>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-sm-3 pr-0">
                <span style="font-weight: bold; font-size: 0.8rem;">발신번호 </span>
              </div>
              <div class="col-sm-9">
                <select
                  id="from-phone-number"
                  v-model="friendTalkDetails.from_phone_num"
                  class="form-control"
                >
                </select>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-3">
                <span style="font-weight: bold; font-size: 0.8rem;">발신프로필</span>
              </div>
              <div class="col-9">
                <select
                  id="profileSelect"
                  v-model="selectedProfile"
                  class="form-control"
                >
                </select>
              </div>
            </div>
<!--            <div class="row align-items-center mt-2">-->
<!--              <div class="col-sm-3 pr-0">-->
<!--                <span style="font-weight: bold; font-size: 0.8rem;">예약발송</span>-->
<!--              </div>-->
<!--              <div class="col-sm-9">-->
<!--                <material-input-->
<!--                  id="senddtm_datetime"-->
<!--                  v-model="friendTalkDetails.send_request_dtm"-->
<!--                  type="datetime-local"-->
<!--                  step="60"-->
<!--                  :min="minDatetime"-->
<!--                >-->
<!--                </material-input>-->
<!--              </div>-->
<!--            </div>-->
            <div class="row align-items-center mt-2">
              <div class="col-sm-3 pr-0">
                <span style="font-weight: bold; font-size: 0.8rem;">전송방식</span>
              </div>
              <div v-if="isBusinessVerified === '2'" class="col-sm-9 d-flex align-items-center">
                <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">수동입력</label>
                <material-radio
                  id="manual"
                  v-model.sync="selectedSendMethod"
                  name="send_method"
                  style="margin-left: -20px"
                  :value="'manual'"
                  :checked="selectedSendMethod === 'manual'"
                  @change="onChangeSendMethod('manual')"
                >
                </material-radio>
                <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">엑셀업로드</label>
                <material-radio
                  id="excel"
                  v-model.sync="selectedSendMethod"
                  name="send_method"
                  style="margin-left: -20px"
                  :value="'excel'"
                  :checked="selectedSendMethod === 'excel'"
                  @change="onChangeSendMethod('excel')"
                >
                </material-radio>
                <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">주소록전송</label>
                <material-radio
                  id="address"
                  v-model.sync="selectedSendMethod"
                  name="send_method"
                  style="margin-left: -20px"
                  :value="'address'"
                  :checked="selectedSendMethod === 'address'"
                  @change="onChangeSendMethod('address')"
                >
                </material-radio>
              </div>
            </div>
            <div v-if="selectedSendMethod === 'manual'" id="send_from_manual" class="row align-items-center mt-2">
              <div class="col-sm-3 pr-0">
                <span style="font-weight: bold; font-size: 0.8rem;">수동입력</span>
              </div>
              <div class="col-sm-9">
                <span style="font-weight: bold; font-size: 0.8rem;">전화번호</span>
                <div id="to_phone_table" style="height: 150px; overflow-y: auto;">
                  <div class="table-responsive">
                    <table id="to-phone-list" class="table table-flush">
                      <tbody>
                      <tr v-for="(phone, index) in checkedPhones" :key="index">
                        <div
                          v-if="index <= 4 || isBusinessVerified === '2'"
                          class="d-flex align-items-start"
                          style="padding: 0;"
                        >
                          <material-checkbox
                            id="to_phone_number_checkbox"
                            :checked="phone.checked"
                            @click.stop
                            @change="toggleCheckbox($event, index)" />
                          <material-input
                            id="to_phone_number"
                            v-model="phone.phoneNumber"
                            size="sm"
                            placeholder="수신번호"
                            style="width: 150px;"
                            @focus="handleFocus(index)">
                          </material-input>
                        </div>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="selectedSendMethod === 'excel'" id="send_from_excel" class="row align-items-center mt-2">
              <div class="col-sm-3 pr-0">
                <span style="font-weight: bold; font-size: 0.8rem;">엑셀선택</span><br>
                <label class="btn-sm" style="display: inline-block; position: relative; cursor: pointer;">
                  <i class="material-icons icon-hover icon-large" style="cursor: pointer; margin-top: 0px; margin-left: -15px;"
                     @click="openFileDialog">upload</i>
                  <span style="cursor: pointer; display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 20px;" @click="openFileDialog">UPLOAD</span>
                  <input ref="fileInput" type="file" style="display: none" @change="handleExcelFileUpload" />
                </label><br><br>
                <span style="font-weight: bold; font-size: 0.8rem;">샘플파일</span><br>
                <label class="btn-sm" style="display: inline-block; position: relative; cursor: pointer;">
                  <i class="material-icons icon-hover icon-large" style="cursor: pointer; margin-top: 0px; margin-left: -15px;"
                     @click="downloadExcelSample">download</i>
                  <span style="cursor: pointer; display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 20px;" @click="downloadExcelSample">DOWNLOAD</span>
                </label>
              </div>
              <div id="address_name" class="col-sm-9" style="height: 300px; overflow-y: auto;">
                <span style="font-weight: bold; font-size: 0.8rem;">주소록명</span><br>
                <td style="padding: 5px;">
                  <div class="d-flex align-items-start">
                    <material-input
                      id="address_name"
                      v-model="friendTalkDetails.customer_group_alias"
                      size="sm"
                      style="margin-left: -3px;"
                      :disabled="!fileSelected">
                    </material-input>
                  </div>
                </td>
                <div class="table-responsive">
                  <table id="excel_table" class="table table-flush">
                    <div class="col-sm-5">
                      <span style="font-weight: bold; font-size: 0.8rem;">전화번호</span><br>
                      <material-button-progress
                        id="toPhoneNumberIndex"
                        color="success"
                        size="sm"
                        variant="outline"
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style="width: 180px"
                        :disabled = "!fileSelected"
                      >
                        {{ selectedPhoneNumberField || "전화번호헤더 선택" }}
                      </material-button-progress>
                      <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                        <li v-for="field in headerFields" :key="field">
                          <a class="dropdown-item border-radius-md" href="javascript:;"
                             @click="handlePhoneSelection(field)">{{ field }}</a>
                        </li>
                      </ul>
                      <br>
                      <div v-if="!selectedFriendTalkType.endsWith('LIST')">
                        <span style="font-weight: bold; font-size: 0.8rem;">변수</span>
                        <div class="d-flex" style="width: 270px;">
                          <material-button-progress
                            id="paramIndex1"
                            color="success"
                            size="sm"
                            variant="outline"
                            class="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style="width: 90px"
                            :disabled = "!phoneSelected"
                          >
                            {{ selectedParamField1 || "변수1" }}
                          </material-button-progress>
                          <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                            <li v-for="field in headerFields" :key="field">
                              <a class="dropdown-item border-radius-md" href="javascript:;"
                                 @click="handleParamSelection1(field)">{{ field }}</a>
                            </li>
                          </ul>
                          <material-button-progress
                            id="paramIndex2"
                            color="success"
                            size="sm"
                            variant="outline"
                            class="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style="width: 90px"
                            :disabled = "!param1Selected"
                          >
                            {{ selectedParamField2 || "변수2" }}
                          </material-button-progress>
                          <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                            <li v-for="field in headerFields" :key="field">
                              <a class="dropdown-item border-radius-md" href="javascript:;"
                                 @click="handleParamSelection2(field)">{{ field }}</a>
                            </li>
                          </ul>
                          <material-button-progress
                            id="paramIndex3"
                            color="success"
                            size="sm"
                            variant="outline"
                            class="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style="width: 90px"
                            :disabled = "!param2Selected"
                          >
                            {{ selectedParamField3 || "변수3" }}
                          </material-button-progress>
                          <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                            <li v-for="field in headerFields" :key="field">
                              <a class="dropdown-item border-radius-md" href="javascript:;"
                                 @click="handleParamSelection3(field)">{{ field }}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="d-flex" style="width: 270px;">
                          <material-button-progress
                            id="paramIndex4"
                            color="success"
                            size="sm"
                            variant="outline"
                            class="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style="width: 90px"
                            :disabled = "!param3Selected"
                          >
                            {{ selectedParamField4 || "변수4" }}
                          </material-button-progress>
                          <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                            <li v-for="field in headerFields" :key="field">
                              <a class="dropdown-item border-radius-md" href="javascript:;"
                                 @click="handleParamSelection4(field)">{{ field }}</a>
                            </li>
                          </ul>
                          <material-button-progress
                            id="paramIndex5"
                            color="success"
                            size="sm"
                            variant="outline"
                            class="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style="width: 90px"
                            :disabled = "!param4Selected"
                          >
                            {{ selectedParamField5 || "변수5" }}
                          </material-button-progress>
                          <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                            <li v-for="field in headerFields" :key="field">
                              <a class="dropdown-item border-radius-md" href="javascript:;"
                                 @click="handleParamSelection5(field)">{{ field }}</a>
                            </li>
                          </ul>
                          <material-button-progress
                            id="paramIndex6"
                            color="success"
                            size="sm"
                            variant="outline"
                            class="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style="width: 90px"
                            :disabled = "!param5Selected"
                          >
                            {{ selectedParamField6 || "변수6" }}
                          </material-button-progress>
                          <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                            <li v-for="field in headerFields" :key="field">
                              <a class="dropdown-item border-radius-md" href="javascript:;"
                                 @click="handleParamSelection6(field)">{{ field }}</a>
                            </li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </div>
            <div v-if="selectedSendMethod === 'address'"
                 id="send_from_customer_group" class="row align-items-center mt-2">
              <div class="col-sm-3 pr-0">
                <span style="font-weight: bold; font-size: 0.8rem;">주소록선택</span><br>
                <div style="display: flex;">
                  <material-button-progress
                    id="toAddress"
                    color="success"
                    size="sm"
                    variant="outline"
                    class="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style="flex-grow: 1;"
                  >
                    {{ friendTalkDetails.customer_group_id || "주소록" }}
                  </material-button-progress>
                  <ul class="dropdown-menu" aria-labelledby="customerGroupIds">
                    <li v-for="optionCustomerGroupId in optionCustomerGroupIds" :key="optionCustomerGroupId">
                      <a
                        class="dropdown-item"
                        href="javascript:;"
                        @click="selectCustomerGroupId(optionCustomerGroupId)"
                      >
                        {{ optionCustomerGroupId }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-9">
                <span style="font-weight: bold; font-size: 0.8rem; margin-right: 0.5rem;">주소록명</span><span style="font-weight: bold; font-size: 0.7rem;">(최대 100건까지 조회됩니다.)</span><br>
                <td style="padding: 5px;">
                  <div>
                    <material-input id="address_name" v-model="friendTalkDetails.customer_group_id" size="sm" style="margin-left: -3px;" readonly></material-input>
                    <span style="font-weight: bold; font-size: 0.7rem;">총 주소록 건수 {{contact_list_count}}</span>
                  </div>
                </td>
                <!-- <span style="font-weight: bold; font-size: 0.8rem;">전화번호</span> -->
                <div id="address_table" style="height: 200px; overflow-y: auto;">
                  <div class="table-responsive">
                    <material-table
                      title="연락처"
                      :headers=tableColumns
                      :lists=contact_list
                      :font-size=fontSize
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center mt-2">
              <div class="col-sm-3 pr-0">
                <span style="font-weight: bold; font-size: 0.8rem;">식별정보</span>
              </div>
              <div class="col-sm-9">
                <div class="mt-2">
                  <material-input
                    id="primary_customer_reference_id"
                    v-model="friendTalkDetails.primary_customer_reference_id"
                    type="text"
                    label="고객식별ID"
                    label-size="sm"
                    variant="outline"
                    maxlength="36"
                  >
                  </material-input>
                </div>
                <div class="mt-2">
                  <material-input
                    id="secondary_customer_reference_id"
                    v-model="friendTalkDetails.secondary_customer_reference_id"
                    type="text"
                    label="캠페인ID"
                    label-size="sm"
                    variant="outline"
                    maxlength="36"
                  >
                  </material-input>
                </div>
              </div>
            </div>
            <div
              v-if="selectedFriendTalkType.startsWith('IMAGE_CAROUSEL_LIST')"
              class="row align-items-center mt-2">
              <div class="col-3">
                <span style="font-weight: bold; font-size: 0.8rem;">더보기 링크</span>
              </div>
              <div class="col-9">
                <material-input-vmodel
                  id="friendTalkCarouselLink"
                  v-model="friendTalkDetails.carousel_link"
                  placeholder="캐러셀의 더보기 클릭 시 이동링크(http 포함)"
                >
                </material-input-vmodel>
              </div>
            </div>
            <div class="ms-3">
              <div v-if="!selectedFriendTalkType.startsWith('IMAGE_LIST')" class="row align-items-center">
                <div class="col-3">
                  <span style="font-weight: bold; font-size: 0.8rem;">버튼추가</span>
                </div>
                <div class="col-9">
                  <select
                    id="buttonTypeSelect"
                    v-model="selectedButtonType"
                    class="form-control"
                  >
                  </select>
                </div>
              </div>
              <div v-if="!isNotFirstPageOfItemList" class="row align-items-center mt-4">
                <div class="col-3">
                  <span style="font-weight: bold; font-size: 0.8rem;">제목</span>
                </div>
                <div class="col-9">
                  <material-input-vmodel
                    id="friendTalkTitle"
                    v-model="friendTalkDetails.pages[currentPage].title"
                  >
                  </material-input-vmodel>
                </div>
              </div>
              <div v-if="selectedFriendTalkType.startsWith('IMAGE')" class="row align-items-end mt-2">
                <div class="col-3">
                  <span style="font-weight: bold; font-size: 0.8rem;">이미지 클릭 링크</span>
                </div>
                <div class="col-9">
                  <material-input-vmodel
                    id="templateCode"
                    v-model="friendTalkDetails.pages[currentPage].imageURL"
                    placeholder="이미지를 클릭할 시 이동될 URL을 입력해주세요.(http 포함)"
                  >
                  </material-input-vmodel>
                </div>
              </div>
              <div v-if="selectedFriendTalkType.startsWith('IMAGE')" class="row align-items-end mt-2">
                <div class="col-3">
                  <span style="font-weight: bold; font-size: 0.8rem;">이미지 추가</span>
                </div>
                <div class="col-7">
                  <material-input-vmodel
                    id="templateCode"
                    v-model="friendTalkDetails.pages[currentPage].imageFileName"
                    :disabled=true
                  >
                  </material-input-vmodel>
                </div>
                <div class="col-2">
                  <div class="d-flex justify-content-end">
                    <input
                      ref="imageFileInput"
                      type="file"
                      style="display: none"
                      @change="handleFileInputChange($event, index)"
                    />
                    <material-button-progress
                      style="padding: 10px"
                      :is-loading="isImageUploadLoading"
                      @click="openFileDialogForImage"
                    >
                      업로드
                    </material-button-progress>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-3">
                  <span style="font-weight: bold; font-size: 0.8rem;">내용</span>
                </div>
                <div class="col-9 mt-2">
                  <material-textarea
                    id="friendTalkContent"
                    v-model="friendTalkDetails.pages[currentPage].typed_contents"
                    placeholder="내용을 입력하세요.
번호별로 다른 값을 보낼경우 변수를 설정해주세요.
변수명은 특수문자를 넣지마세요.
변수명이 여러개일때 각각 다른이름을 사용해주세요.
주소록 전송시에는 변수 순서대로 {순서} 로 입력해주세요.
예){고객이름}님 환영합니다. or {0}님 환영합니다."
                    :rows="friendTalkDetails.pages[currentPage].contentsRow"
                  >
                  </material-textarea>
                </div>
              </div>
              <div
                v-for="(button, index) in friendTalkDetails.pages[currentPage].insertedButtons"
                :key="index"
                class="row align-items-center mb-2 mt-2"
              >
                <div class="col-11">
                  <kakao-template-button-input
                    v-model:buttonName="button.name"
                    v-model:links="button.links"
                    :type="button.linkType"
                  >
                  </kakao-template-button-input>
                </div>
                <div class="col-1">
                  <i class="material-icons-round opacity-10" style="cursor: pointer;"  @click="deleteButton(index)">delete</i>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-4"></div>
                <div class="col-4 d-flex justify-content-center">
                  <material-pagination
                    :total-pages="totalPages"
                    :current-page="currentPage + 1"
                    size="sm"
                    :show-prev-next="false"
                    @page-changed="onPageChange"
                  >
<!--                    <material-pagination-item prev/>-->
                    <material-pagination-item add/>
                  </material-pagination>
                </div>
                <div class="col-4 d-flex justify-content-end">
                  <material-button-progress
                    v-if="selectedFriendTalkType.endsWith('LIST')"
                    class="me-1"
                    style="padding: 10px"
                    :is-loading="isImageUploadLoading"
                    @click="removeList"
                  >
                    삭제
                  </material-button-progress>
                  <material-button-progress
                    v-if="selectedFriendTalkType.endsWith('LIST')"
                    style="padding: 10px"
                    :is-loading="isImageUploadLoading"
                    @click="addList"
                  >
                   추가
                  </material-button-progress>

                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row d-flex justify-content-center">
              <div class="image-container max-width-300" style="position: relative; display: inline-block;">
                <img src="@/assets/img/smartphone2.png" class="img-fluid border-radius-lg max-width-300">
                <div class="text-start" style="position: absolute; top: 18%; left: 52%; transform: translateX(-50%);">
                  <friend-talk-textarea
                    v-if="!selectedFriendTalkType.startsWith('IMAGE_LIST')"
                    id="friendTalkContentPreview"
                    v-model:contentsValue="friendTalkDetails.pages[currentPage].typed_contents"
                    v-model:textTitleValue="friendTalkDetails.pages[currentPage].title"
                    v-model:textSubTitleValue="friendTalkDetails.pages[currentPage].subTitle"
                    placeholder="템플릿 내용을 입력하세요."
                    :disabled=true
                    :friends-talk-type="selectedFriendTalkType"
                    :image-url="friendTalkDetails.pages[currentPage].imageFileUrl"
                    :buttons="friendTalkDetails.pages[currentPage].insertedButtons"
                  >
                  </friend-talk-textarea>
                  <friend-talk-textarea-item-list
                    v-if="selectedFriendTalkType.startsWith('IMAGE_LIST')"
                    id="friendTalkImageListContentPreview"
                    v-model:contentsValue="friendTalkDetails.pages[0].typed_contents"
                    v-model:textTitleValue="friendTalkDetails.pages[0].title"
                    v-model:textSubTitleValue="friendTalkDetails.pages[0].subTitle"
                    :disabled=true
                    :friends-talk-type="selectedFriendTalkType"
                    :pages="friendTalkDetails.pages"
                  >
                  </friend-talk-textarea-item-list>
                </div>
                <div style="position: absolute; top: 5.5%; left: 55%; transform: translate(-50%, -50%);">
                  <span style="font-size: 0.9rem;">{{friendTalkDetails.pages.profileName}}</span>
                </div>
                <div style="position: absolute; top: 14%; left: 40%; transform: translate(-50%, -50%);">
                  <span style="font-size: 0.8rem;">{{friendTalkDetails.pages.profileName}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="col-12">
<!--          <div class="d-flex justify-content-end">-->
<!--            <material-button-progress-->
<!--              :is-loading="isSendLoading"-->
<!--              @click="printDetail"-->
<!--            >테스트</material-button-progress>-->
<!--          </div>-->
          <div class="d-flex justify-content-end">
            <material-button-progress
              :is-loading="isSendLoading"
              @click="sendKakaoFriendTalk"
            >발송</material-button-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import { DataTable } from "simple-datatables";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialButtonProgress from "@/components/MaterialButtonProgress.vue";
import * as Choices from "choices.js";
import XLSX from 'xlsx/dist/xlsx.full.min.js';
import ApiCall from "@/Interface/ApiCall";
import MaterialTable from "@/components/MaterialTable.vue";
import MaterialInputVmodel from "@/components/MaterialInputVmodel.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import KakaoTemplateButtonInput from "@/components/KakaoTemplateButtonInput.vue";
import FriendTalkTextarea from "@/components/FriendTalkTextarea.vue";
import MaterialPagination from "@/components/MaterialPagination.vue";
import MaterialPaginationItem from "@/components/MaterialPaginationItem.vue";
import { Auth } from "aws-amplify";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import FriendTalkTextareaItemList from "@/components/FriendTalkTextareaItemList.vue";

export default {
  name: "KakaoFriendForm",
  components: {
    FriendTalkTextarea,
    FriendTalkTextareaItemList,
    MaterialTextarea,
    MaterialInputVmodel,
    MaterialButtonProgress,
    KakaoTemplateButtonInput,
    MaterialPagination,
    MaterialPaginationItem,
    MaterialInput,
    MaterialRadio,
    MaterialCheckbox,
    MaterialTable,
  },
  data() {
    return {
      selectedProfile: '',
      selectedProfileId: '',
      selectedButtonType: '',
      selectedFriendTalkType: 'TEXT',
      friendTalkTypeSelectInstance: null,
      currentButtonName: '',
      friendTalkDetails: {
        'carousel_link': '',
        'send_request_dtm': '',
        'from_phone_num': '',
        'to_phone_numbers': [''],
        'customer_group_id': '',
        'customer_group_alias': '',
        'primary_customer_reference_id': '',
        'secondary_customer_reference_id': '',
        'type':'TEXT',
        'pages':[{
          //'TEXT', 'IMAGE_NORMAL', 'IMAGE_WIDE', 'IMAGE_LIST', 'IMAGE_CAROUSEL_LIST'
          'typed_contents':'',
          'contents':'',
          'contentsRow':10,
          'profileName':'',
          'imageFileName': '',
          'imageFileUrl': '',
          'imageURL': '',
          'title': '',
          'subTitle': '',
          'insertedButtons': [],
        },],
      },
      profileSelectInstance: null,
      selectInstance: null,
      buttonTypeSelectInstance: null,
      kakaoProfiles: [],
      templateCategorys: [],
      friendTalkType: [
        {
          'label': '텍스트',
          'value': 'TEXT',
        },
        {
          'label': '기본 이미지',
          'value': 'IMAGE_NORMAL',
        },
        {
          'label': '와이드 이미지',
          'value': 'IMAGE_WIDE',
        },
        {
          'label': '리스트',
          'value': 'IMAGE_LIST',
        },
        {
          'label': '캐러셀',
          'value': 'IMAGE_CAROUSEL_LIST',
        },
      ],
      buttonTypes: [
        {
          'label': '선택',
          'value': '',
        },
        {
          'label': '웹링크',
          'value': 'WL',
        },
        {
          'label': '앱링크',
          'value': 'AL',
        },
        {
          'label': '봇키워드',
          'value': 'BK',
        },
        {
          'label': '메시지전달',
          'value': 'MD',
        },
        {
          'label': '상담톡전환',
          'value': 'BC',
        },
        {
          'label': '봇전환',
          'value': 'BT',
        },
      ],
      selectedEmphasizeType: '',
      imageFile: null,
      imageFileUrl : '',
      isImageUploadLoading: false,
      isSendLoading: false,
      currentPage:0,
      confirmedFriendTalkType: '',
      // 불법스팸 막기
      isBusinessVerified: '',
      checkedPhones: [{ checked: false, phoneNumber: '' }],
      excel_file_name: '',
      excelfile: '',
      isLoading: true,
      contact_list_count: 0,
      optionCustomerGroupIds: [],
      selectedPhoneNumberField: '전화번호 선택',
      selectedParamField1: '변수1',
      selectedParamField2: '변수2',
      selectedParamField3: '변수3',
      selectedParamField4: '변수4',
      selectedParamField5: '변수5',
      selectedParamField6: '변수6',
      selectedSendMethod: 'manual',
      selectedAlertMethod: 'alert_n',

      params: [],
      fileSelected: false,
      phoneSelected: false,
      param1Selected: false,
      param2Selected: false,
      param3Selected: false,
      param4Selected: false,
      param5Selected: false,
      param6Selected: false,

      headerFields: [],

      tableColumns: ['전화번호', '변수',''],
      contact_list: [],
      fontSize: 'xs',
      to_phone_num_index: '',
      sendRequestDtm: '',
    };
  },
  computed: {
    minDatetime() {
      // Get the current datetime in Seoul time and round up to the nearest 10 minute interval
      let now = new Date();
      now.setSeconds(0);

      // Set the time zone offset to the offset for Seoul time (UTC+9)
      let timezoneOffset = 9 * 60; // in minutes
      now.setMinutes(now.getMinutes() + timezoneOffset);

      // Round up to the nearest 10 minute interval
      let roundedMinutes = Math.ceil(now.getMinutes() / 10) * 10;
      now.setMinutes(roundedMinutes);

      let datetimeString = now.toISOString().slice(0, -8);

      return datetimeString;
    },
    kakaoProfileOptions() {
      const initialData = { 'label': '선택', 'value': '0' };
      const categoryArray = this.kakaoProfiles.map(item => ({
        'label': item.sk,
        'value': item.sender_key,
      }));
      return [initialData, ...categoryArray];
    },
    totalPages() {
      return this.friendTalkDetails.pages.length
    },
    kakao_send_type() {
      switch(this.selectedFriendTalkType) {
        case "TEXT":
          return 'ft';
        case "IMAGE_NORMAL":
          return 'fi';
        case "IMAGE_WIDE":
          return 'fw';
        case "IMAGE_LIST":
          return 'fl';
        case "IMAGE_CAROUSEL_LIST":
          return 'fc';
        default:
          return '';
      }
    },
    isNotFirstPageOfItemList() {
      if (this.selectedFriendTalkType === 'IMAGE_LIST') {
        if (this.currentPage === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  watch: {
    'checkedPhones': {
      handler(newVal, oldVal) {
        if (this.isBusinessVerified === '2') {
          for (let i = 0; i < newVal.length; i++) {
            // Replace hyphens and dots with an empty string
            newVal[i].phoneNumber = newVal[i].phoneNumber.replace(/[-. ]/g, '');

            // Find the first non-digit character
            const nonDigitOrAlphabetIndex = newVal[i].phoneNumber.search(/[^0-9a-zA-Z]/);

            if (nonDigitOrAlphabetIndex !== -1) {
              // Split the string at the first non-digit character
              const splitPhones = [
                newVal[i].phoneNumber.slice(0, nonDigitOrAlphabetIndex),
                newVal[i].phoneNumber.slice(nonDigitOrAlphabetIndex + 1)
              ].map(phone => phone.trim());

              // Update the current phone number with the part before the non-digit character
              this.checkedPhones[i].phoneNumber = splitPhones[0];

              // Append the rest of the string to the checkedPhones array
              const additionalPhones = splitPhones.slice(1).map(phone => ({ phoneNumber: phone, checked: newVal[0].checked }));
              this.checkedPhones.splice(i + 1, 0, ...additionalPhones);

              // Focus the next input box after the one that was changed
              this.$nextTick(() => {
                const nextInputRef = this.$refs[`input-${i + 1}`];
                if (nextInputRef && nextInputRef.$el) {
                  nextInputRef.$el.focus();
                }
              });

              break; // Stop the loop after processing the first non-digit character
            }
          }
        }
      },
      deep: true
    },
    selectedProfile(newList) {
      if (newList !== '') {
        const object = this.findObjectByValue(this.kakaoProfileOptions, 'value', newList)
        this.selectedProfileId = object.label
      }
    },
    selectedButtonType(newList) {
      console.log("friendTalkDetail :", JSON.stringify(this.friendTalkDetails));
      if (newList !== '') {
        if (this.friendTalkDetails.pages[this.currentPage].insertedButtons.length < 5) {
          console.log("Button add :" + newList)
          this.friendTalkDetails.pages[this.currentPage].insertedButtons.push(
            {
              linkType: newList,
              name: '',
              links: {},
            }
          )

          this.initButtonChoicesList();
        } else {
          alert("템플릿 버튼추가는 최대 5개까지 가능합니다.")
        }
      }
    },
    selectedFriendTalkType(newItem, oldItem) {
      this.resetFriendTalkDetails(newItem);
    },
  },
  mounted() {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      const idToken = user.signInUserSession.idToken;
      const tenantId = idToken.payload['cognito:groups'][0];
      const isBusinessVerified = user.attributes['custom:isBusinessVerified']
      this.tenantId = tenantId;
      this.isBusinessVerified = isBusinessVerified;

      const payload = {
        status: "active",
      };

      ApiCall.call('phone/from/numbers', 'POST', payload)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            let defaultOptionData = [{ value: "", label: "발신번호를 선택해주세요." }];
            const optionData = defaultOptionData.concat(response.data.map(item => {
              // Check if from_phone_number_alias is not null
              const label = item['from_phone_number_alias']
                ? '(' + item['from_phone_number_alias'] + ') ' + item['from_phone_number']
                : item['from_phone_number'];

              return {
                label: label,
                value: item['from_phone_number']
              };
            }));
            this.optionFromPhoneNumbers = optionData;
            console.log("optionsFromPhoneNumbers :", this.optionFromPhoneNumbers);
            this.initFromPhoneNumberChoicesList();
            // console.log(this.optionFromPhoneNumbers);
          } else {
            console.error('Error: Invalid response data format');
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });

      const getGroupPayload = {
        status: "active",
      };

      ApiCall.call('group/list', 'POST', getGroupPayload)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.optionCustomerGroupIds = response.data;
            // console.log(this.optionCustomerGroupIds);
          } else {
            console.error('Error: Invalid response data format');
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });

      this.getKakaoProfiles();
      this.initButtonChoicesList();
      this.initFriendTalkTypeChoicesList();

      this.checkedPhones[0].checked = true;
      this.checkedPhones.push({ checked: false, phoneNumber: "" });
      this.isLoading = false;
    })();
  },
  methods: {
    initFromPhoneNumberChoicesList() {
      if (this.selectInstance) {
        this.selectInstance.destroy();
      }
      this.selectInstance = new Choices('#from-phone-number', {
        shouldSort: false,
        choices: this.optionFromPhoneNumbers,
      });
    },
    initChoicesList() {
      this.selectedProfile = '';
      this.selectedProfileId = '';
      if (this.profileSelectInstance) {
        this.profileSelectInstance.destroy();
      }
      this.profileSelectInstance = new Choices('#profileSelect', {
        shouldSort: false,
        choices: this.kakaoProfileOptions,
      });
    },
    initFriendTalkTypeChoicesList() {
      this.selectedFriendTalkType='TEXT';
      if (this.friendTalkTypeSelectInstance) {
        this.friendTalkTypeSelectInstance.destroy();
      }
      this.friendTalkTypeSelectInstance = new Choices('#friendTalkTypeSelect', {
        shouldSort: false,
        choices: this.friendTalkType,
      });
    },
    initButtonChoicesList() {
      this.selectedButtonType='';
      if (this.buttonTypeSelectInstance) {
        this.buttonTypeSelectInstance.destroy();
      }
      this.buttonTypeSelectInstance = new Choices('#buttonTypeSelect', {
        shouldSort: false,
        choices: this.buttonTypes,
      });
    },
    getKakaoProfiles() {
      ApiCall.call('user/kakaoprofile/profileinfo', 'GET').then(response =>{
        this.kakaoProfiles = response.data.list
        console.log("response :"+response.data.message);
        this.initChoicesList();
      }).catch(error => {
        console.error('Error: Unable to fetch data from API', error);
      });
    },
    findObjectByValue(arr, keyName, searchValue) {
      for (const obj of arr) {
        if (obj[keyName] === searchValue) {
          return obj;
        }
      }
      return null;
    },
    async sendKakaoFriendTalk() {
      this.isSendLoading = true;
      // if (!this.validatefriendTalkDetail()) {
      //   this.isSendLoading = false;
      // }

      if (!this.requireValidation(this.selectedSendMethod)) {
        this.isSendLoading = false;
        return;
      }

      this.friendTalkDetails.to_phone_number = this.checkedPhones
        .filter((phone) => phone.checked && phone.phoneNumber !== '')
        .map((phone) => phone.phoneNumber);


      let apiResource = '';

      // to-do
      // contents 파람처리
      this.friendTalkDetails.pages[this.currentPage].contents = this.friendTalkDetails.pages[this.currentPage].typed_contents;
      this.friendTalkDetails.pages[this.currentPage].contents = this.friendTalkDetails.pages[this.currentPage].contents.replace(new RegExp("{"+this.selectedParamField1+"}", "g"), "{0}");
      this.friendTalkDetails.pages[this.currentPage].contents = this.friendTalkDetails.pages[this.currentPage].contents.replace(new RegExp("{"+this.selectedParamField2+"}", "g"), "{1}");
      this.friendTalkDetails.pages[this.currentPage].contents = this.friendTalkDetails.pages[this.currentPage].contents.replace(new RegExp("{"+this.selectedParamField3+"}", "g"), "{2}");
      this.friendTalkDetails.pages[this.currentPage].contents = this.friendTalkDetails.pages[this.currentPage].contents.replace(new RegExp("{"+this.selectedParamField4+"}", "g"), "{3}");
      this.friendTalkDetails.pages[this.currentPage].contents = this.friendTalkDetails.pages[this.currentPage].contents.replace(new RegExp("{"+this.selectedParamField5+"}", "g"), "{4}");
      this.friendTalkDetails.pages[this.currentPage].contents = this.friendTalkDetails.pages[this.currentPage].contents.replace(new RegExp("{"+this.selectedParamField6+"}", "g"), "{5}");


      // if (!this.formData.send_request_dtm) {
      //   this.sendRequestDtm = this.getCurrentDateTime();
      //   this.formData.send_status = "request"
      // } else {
      //   this.formData.send_status = "schedule-request"
      //   const date = new Date(this.formData.send_request_dtm);
      //   const year = date.getFullYear();
      //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
      //   const day = date.getDate().toString().padStart(2, '0');
      //   const hours = date.getHours().toString().padStart(2, '0');
      //   const minutes = date.getMinutes().toString().padStart(2, '0');
      //   const seconds = '00';

      //   this.sendRequestDtm = `${year}${month}${day}${hours}${minutes}${seconds}`;
      // }

      let payload = {
        'contents': '',
        'send_request_dtm': this.sendRequestDtm,
        'from_phone_number': this.friendTalkDetails.from_phone_num,
        'from_country_code': "82",
        'to_country_code': "82",
        'from_kakao_channel': this.selectedProfile,
        'send_status': "request",
        'primary_customer_reference': this.friendTalkDetails.primary_customer_reference_id,
        'secondary_customer_reference_id': this.friendTalkDetails.secondary_customer_reference_id,
      };


      if (this.selectedFriendTalkType === "TEXT") {
        payload.send_type = "ft";
        payload.contents = this.friendTalkDetails.pages[this.currentPage].contents;
        payload.title = this.friendTalkDetails.pages[this.currentPage].title;

        if (this.friendTalkDetails.pages[this.currentPage].insertedButtons.length > 0) {
          payload.button = this.makeButtonObjectList(this.friendTalkDetails.pages[this.currentPage].insertedButtons);
        }
      } else if (this.selectedFriendTalkType === "IMAGE_NORMAL") {
        payload.send_type = "fi"
        payload.contents = this.friendTalkDetails.pages[this.currentPage].contents;

        if (!this.friendTalkDetails.pages[this.currentPage].imageURL) {
          alert("기본 이미지 타입선택 시 이미지업로드가 필수입니다.")
        }

        payload.title = this.friendTalkDetails.pages[this.currentPage].title;
        payload.image = {
          img_url: this.friendTalkDetails.pages[this.currentPage].imageFileUrl,
          imglink: this.friendTalkDetails.pages[this.currentPage].imageURL,
        }

        if (this.friendTalkDetails.pages[this.currentPage].insertedButtons.length > 0) {
          payload.button = this.makeButtonObjectList(this.friendTalkDetails.pages[this.currentPage].insertedButtons);
        }
      } else if (this.selectedFriendTalkType === "IMAGE_WIDE") {
        payload.send_type = "fw"

        payload.contents = this.friendTalkDetails.pages[this.currentPage].contents;
        if (!this.friendTalkDetails.pages[this.currentPage].imageURL) {
          alert("와이드 이미지 타입선택 시 이미지업로드가 필수입니다.")
        }

        payload.title = this.friendTalkDetails.pages[this.currentPage].title;
        payload.image = {
          img_url: this.friendTalkDetails.pages[this.currentPage].imageFileUrl,
          imglink: this.friendTalkDetails.pages[this.currentPage].imageURL,
        }

        if (this.friendTalkDetails.pages[this.currentPage].insertedButtons.length > 0) {
          payload.button = this.makeButtonObjectList(this.friendTalkDetails.pages[this.currentPage].insertedButtons);
        }

      } else if (this.selectedFriendTalkType === "IMAGE_LIST") {
        payload.send_type = "fl"
        payload.header = this.friendTalkDetails.pages[0].title
        payload.item = { list : this.transformItemList(this.friendTalkDetails)}

      } else if (this.selectedFriendTalkType === "IMAGE_CAROUSEL_LIST") {
        payload.send_type = "fc"
        payload.carousel = {
          tail : {
            url_mobile: this.friendTalkDetails.carousel_link
          },
          list : this.transformCarousel(this.friendTalkDetails)
        }
      }

      await this.callApiWithType(apiResource, payload);

    },
    async callApiWithType(apiResource, payload) {
      if (this.selectedSendMethod === "manual") {
        apiResource = 'message/kakao/friend';

        const toPhoneNumbersWithParams = this.friendTalkDetails.to_phone_number.map((phone) => {
          return {
            phone: phone,
            params: this.params
          };
        });

        payload.to_phone_numbers = toPhoneNumbersWithParams;
      } else if (this.selectedSendMethod === "excel") {
        apiResource = 'message/group/kakao/friend';

        const payloadFile = {
          customer_group_id: this.friendTalkDetails.customer_group_alias,
          phone_number_index: this.to_phone_num_index,
          params: this.params,
        };

        try {
          const fileResponse = await ApiCall.file('upload/excel', 'POST', payloadFile, null, this.excel_file, null, false);
          console.log(fileResponse.data);
          if (this.showConfirmation(fileResponse.data.total_count) && fileResponse.data.customer_group_id) {
            payload.customer_group_id = fileResponse.data.customer_group_id;
            console.log(payload);
          }
        } catch (error) {
          console.error(error);
          alert("알림톡 전송 실패");
          this.reset();
        }
      } else if (this.selectedSendMethod === "address") {
        apiResource = 'message/group/kakao/friend';
        payload.customer_group_id = this.friendTalkDetails.customer_group_id;
      }

      console.log("payload :" + JSON.stringify(payload));

      await ApiCall.call(apiResource, 'POST', payload).then(response => {
        // console.log("regist template : " + response.data.message)
        if (response.status === 200) {
          this.isSendLoading = false;
          alert("친구톡 전송 성공");
          this.reset();
        } else {
          alert("친구톡 전송 실패" + response.data.message);
          this.isSendLoading = false;
        }
      });

    },
    requireValidation(sendType) {
      // 공통체크
      if ( !this.friendTalkDetails.from_phone_num) {
        alert("발신번호를 선택해주세요.")
        return false;
      } else if (!this.selectedProfile) {
        alert("발신프로필을 선택해주세요.")
        return false;
      }

      switch (sendType) {
        case 'manual':
          if (!this.checkedPhones.every(phone => {
            return !phone.checked || (phone.phoneNumber !== '' && phone.phoneNumber !== null);
          })) {
            alert('체크한 수신번호를 모두 입력해주세요.');
            return;
          }
          if (!this.checkedPhones.every(phone => {
            if (phone.checked && phone.phoneNumber !== '') {
              if (phone.phoneNumber.length < 8 || phone.phoneNumber.length > 12){
                return false;
              }

              return !isNaN(parseInt(phone.phoneNumber));
            }
            return true;
          })) {
            alert('유효한 전화번호를 입력해주세요.');
            return;
          }
          break;
        case 'excel':
          if (!this.excel_file || this.excel_file.length <= 0) {
            alert("전화번호가 있는 엑셀을 선택해주세요.");
            return;
          }
          if(this.to_phone_num_index.length <= 0){
            alert("전화번호가 있는 컬럼을 선택해주세요.");
            return;
          }
          break;
        case 'address':
          if (this.friendTalkDetails.customer_group_id.length <= 0) {
            alert("주소록을 선택해주세요.");
            return;
          }
          break;
      }

      if(this.friendTalkDetails.send_request_dtm){
        const currentTime = new Date();
        const requestTime = new Date(this.friendTalkDetails.send_request_dtm);
        const thirtyMinutesFromNow = new Date(currentTime.getTime() + (21 * 60000));
        const minutes = requestTime.getMinutes();
        if (minutes % 1 !== 0) {
          alert("예약전송은 1분 단위로 전송 가능합니다.");
          return;
        }
        // console.log(requestTime);
        // console.log(thirtyMinutesFromNow);
        if (requestTime < thirtyMinutesFromNow) {
          alert("예약전송은 최소 30분 이후부터 가능합니다.");
          return;
        }
      }

      return true;
    },
    reset() {
      this.$nextTick(() => {
        this.resetFriendTalkDetails();
        this.resetDataPartial();
        this.selectedSendMethod = 'manual';
        this.initFromPhoneNumberChoicesList();
        this.initButtonChoicesList();
        this.initFriendTalkTypeChoicesList();
        this.initChoicesList();

        this.sendRequestDtm = '';
      });
    },
    async handleFileInputChange(event, index) {
      this.isImageUploadLoading = true;
      console.log("file input changed");
      if (event.target.files.length > 0) {
        try {
          this.friendTalkDetails.pages[this.currentPage].imageFile = event.target.files[0]; // Use 'this' to access the data property
          this.friendTalkDetails.pages[this.currentPage].imageFileUrl = URL.createObjectURL(this.friendTalkDetails.pages[this.currentPage].imageFile)
          this.friendTalkDetails.pages[this.currentPage].imageFileName = event.target.files[0].name;

          // 친구톡 종류별로 이미지업로드 분리
          let imageResponse;
          let payload;
          const formData = new FormData();
          switch(this.selectedFriendTalkType) {
            //'TEXT', 'IMAGE_NORMAL', 'IMAGE_WIDE', 'IMAGE_LIST', 'IMAGE_CAROUSEL_LIST'
            case "TEXT":
              alert("텍스트형식에서는 이미지업로드를 할 수 없습니다.")
              break;
            case "IMAGE_NORMAL":
              payload = {
                'imageType' : 'I',
                'title' : this.friendTalkDetails.pages[this.currentPage].imageFileName,
                'link' : this.friendTalkDetails.pages[this.currentPage].imageURL,
                'senderKey': this.selectedProfile
              };
              imageResponse = await ApiCall.call('upload/image/kakao/friend', 'POST', payload, null, this.friendTalkDetails.pages[this.currentPage].imageFile);
              break;
            case "IMAGE_WIDE":
              payload = {
                'imageType' : 'W',
                'title' : this.friendTalkDetails.pages[this.currentPage].imageFileName,
                'link' : this.friendTalkDetails.pages[this.currentPage].imageURL,
                'senderKey': this.selectedProfile
              };
              imageResponse = await ApiCall.call('upload/image/kakao/friend', 'POST', payload, null, this.friendTalkDetails.pages[this.currentPage].imageFile);
              break;
            case "IMAGE_LIST":
              formData.append('imageList[0].image', this.friendTalkDetails.pages[this.currentPage].imageFile);
              formData.append('imageList[0].title', this.friendTalkDetails.pages[this.currentPage].imageFileName);
              formData.append('imageList[0].link', this.friendTalkDetails.pages[this.currentPage].imageURL);
              formData.append('senderKey', this.selectedProfile)
              imageResponse = await ApiCall.call('upload/image/kakao/friend/item/list', 'POST', null, null, null, null, false, formData);
              break;
            case "IMAGE_CAROUSEL_LIST":
              formData.append('imageList[0].image', this.friendTalkDetails.pages[this.currentPage].imageFile);
              formData.append('imageList[0].title', this.friendTalkDetails.pages[this.currentPage].imageFileName);
              formData.append('imageList[0].link', this.friendTalkDetails.pages[this.currentPage].imageURL);
              formData.append('senderKey', this.selectedProfile)
              imageResponse = await ApiCall.call('upload/image/kakao/friend/carousel', 'POST', null, null, null, null, false, formData);
              break;
          }

          if (imageResponse.status === 200) {
            switch(this.selectedFriendTalkType) {
              case "IMAGE_LIST":
              case "IMAGE_CAROUSEL_LIST":
                console.log('전송성공 :' + imageResponse.data);
                this.friendTalkDetails.pages[this.currentPage].imageFileName = imageResponse.data.data.success[0].formField;
                this.friendTalkDetails.pages[this.currentPage].imageFileUrl = imageResponse.data.data.success[0].url;
                break;
              case "IMAGE_NORMAL":
              case "IMAGE_WIDE":
              default:
                console.log('전송성공 :' + imageResponse.data);
                this.friendTalkDetails.pages[this.currentPage].imageFileName = imageResponse.data.file_name;
                this.friendTalkDetails.pages[this.currentPage].imageFileUrl = imageResponse.data.image;
            }
          } else {
            console.log('전송실패 :' + JSON.stringify(imageResponse.data));
            alert('전송실패, 사유 :' + imageResponse.data.message);
          }

          this.isImageUploadLoading = false;
        } catch (error) {
          console.log('전송실패 :' + JSON.stringify(error));
          alert("이미지 업로드 실패: " + error);
          this.isImageUploadLoading = false;
          return;
        }
      }
    },
    openFileDialogForImage() {
      this.$refs.imageFileInput.click();
    },
    validatefriendTalkDetail() {
      if (!this.friendTalkDetail ||
        !this.friendTalkDetail.code ||
        !this.friendTalkDetail.name ||
        !this.friendTalkDetail.contents ||
        !this.friendTalkDetail.profileName) {
        alert('템플릿정보를 모두 입력해주세요.')
        return false; // Validation failed
      }

      if (this.friendTalkDetail.templateEmphasizeType === 'IMAGE' &&
        (!this.friendTalkDetail.imageFileName || !this.friendTalkDetail.imageFileUrl)) {
        alert('이미지형 강조 선택 시 이미지 등록이 필요합니다.')
        return false; // Validation failed
      }

      if (this.friendTalkDetail.templateEmphasizeType === 'TEXT' &&
        (!this.friendTalkDetail.title || !this.friendTalkDetail.subTitle)) {
        alert('강조표기형 강조 선택 시 강조타이틀과 보조표기항목을 입력해주세요.')
        return false; // Validation failed
      }

      if (this.isImageUploadLoading) {
        alert('이미지 업로드 완료 후 등록해주세요.')
        return false;
      }

      return true; // Validation passed
    },
    deleteButton(index) {
      this.friendTalkDetails.pages[this.currentPage].insertedButtons.splice(index, 1);
    },
    printDetail() {
      console.log("selectedProfle :", this.selectedProfile)
      console.log("friendTalkDetails :", JSON.stringify(this.friendTalkDetails));
    },
    submitForm(){
      console.log("submit called")
    },
    onPageChange(pageNumber) {
      this.currentPage = pageNumber - 1;

      if (this.selectedFriendTalkType === 'IMAGE_LIST' && this.currentPage === 0) {
        this.$nextTick(() => {
          this.initButtonChoicesList();
        });
      }
    },
    addList() {
      switch(this.selectedFriendTalkType) {
        case "TEXT":
        case "IMAGE_NORMAL":
        case "IMAGE_WIDE":
          alert("선택하신 친구톡 타입은 페이지 추가가 되지 않습니다.");
          break;
        case "IMAGE_LIST":
          if (this.totalPages < 4) {
            this.addPageToDetails();
          } else {
            alert("와이드 이미지 리스트형은 4개 항목까지 가능합니다.")
          }
          break;
        case "IMAGE_CAROUSEL_LIST":
          if (this.totalPages < 10) {
            this.addPageToDetails();
          } else {
            alert("캐러셀형은 10개 항목까지 가능합니다.")
          }
          break;
      }
    },
    removeList() {
      switch(this.selectedFriendTalkType) {
        case "TEXT":
        case "IMAGE_NORMAL":
        case "IMAGE_WIDE":
          alert("선택하신 친구톡 타입은 페이지 삭제가 되지 않습니다.");
          break;
        case "IMAGE_LIST":
          if (this.totalPages > 3) {
            this.removePageFromDetals();
          } else {
            alert("와이드 이미지 리스트형은 최소 3개 항목이 필요합니다.")
          }
          break;
        case "IMAGE_CAROUSEL_LIST":
          if (this.totalPages > 2) {
            this.removePageFromDetals();
          } else {
            alert("캐러셀형은 최소 2개 항목이 필요합니다.")
          }
          break;
      }
    },
    addPageToDetails() {
      this.friendTalkDetails.pages.push(Array.from({ length: 1 }, () => ({
        'typed_contents':'',
        'contents':'',
        'contentsRow':10,
        'profileName':'',
        'imageFileName': '',
        'imageFileUrl': '',
        'imageURL': '',
        'title': '',
        'subTitle': '',
        'insertedButtons': [],
      })))
    },
    removePageFromDetals() {
      this.friendTalkDetails.pages.splice(this.currentPage, 1);
    },
    onChangeSendMethod(option) {
      this.resetDataPartial();
      this.selectedSendMethod = option;
    },
    resetFriendTalkDetails(newItem) {
      this.friendTalkDetails.to_phone_numbers= [''];
      this.friendTalkDetails.customer_group_id= '';
      this.friendTalkDetails.customer_group_alias= '';

      switch (newItem) {
        //'TEXT', 'IMAGE_NORMAL', 'IMAGE_WIDE', 'IMAGE_LIST', 'IMAGE_CAROUSEL_LIST'
        case "IMAGE_LIST":
          console.log("IMAGE_LIST")
          this.friendTalkDetails.pages = Array.from({ length: 3 }, () => ({
            'typed_contents':'',
            'contents':'',
            'contentsRow':10,
            'profileName':'',
            'imageFileName': '',
            'imageFileUrl': '',
            'imageURL': '',
            'title': '',
            'subTitle': '',
            'insertedButtons': [],
          }))
          this.currentPage = 0;
          break;
        case "IMAGE_CAROUSEL_LIST":
          console.log("IMAGE_CAROUSEL")
          this.friendTalkDetails.pages = Array.from({ length: 2 }, () => ({
            'typed_contents':'',
            'contents':'',
            'contentsRow':10,
            'profileName':'',
            'imageFileName': '',
            'imageFileUrl': '',
            'imageURL': '',
            'title': '',
            'subTitle': '',
            'insertedButtons': [],
          }))
          this.currentPage = 0;
          break;
        default:
          this.friendTalkDetails.pages = Array.from({ length: 1 }, () => ({
            'typed_contents':'',
            'contents':'',
            'contentsRow':10,
            'profileName':'',
            'imageFileName': '',
            'imageFileUrl': '',
            'imageURL': '',
            'title': '',
            'subTitle': '',
            'insertedButtons': [],
          }))
          this.currentPage = 0;
      }
    },
    resetDataPartial(){
      this.isSendLoading = false;
      this.resetFriendTalkDetails(this.selectedFriendTalkType);
      this.excel_file_name = '';
      this.excelfile= '';
      this.to_phone_num_index= '';
      this.headerFields= [''];
      this.params= [];
      this.selectedPhoneNumberField= '전화번호 선택';
      this.selectedParamField1= '변수1';
      this.selectedParamField2= '변수2';
      this.selectedParamField3= '변수3';
      this.selectedParamField4= '변수4';
      this.selectedParamField5= '변수5';
      this.selectedParamField6= '변수6';
      this.selectedSendMethod= 'manual';
      this.contact_list = [];
      this.tableData = [];
      this.checkedPhones = [{ checked: false, phoneNumber: '' }];
      this.checkedPhones[0].checked = true;
      this.checkedPhones.push({ checked: false, phoneNumber: '' });

      this.kakao_param_list = [];
      this.kakao_param_input = {};
      this.previous_kakao_param_input = {};
      this.contact_list_count = 0;

      this.addressSelected = false;
      this.fileSelected = false;
      this.phoneSelected = false;
      this.param1Selected = false;
      this.param2Selected = false;
      this.param3Selected = false;
      this.param4Selected = false;
      this.param5Selected = false;
      this.param6Selected = false;
    },
    handleFocus(index) {
      if (index === 0) {
        const firstCommaIndex = this.checkedPhones[0].phoneNumber.indexOf(',');
        if (firstCommaIndex !== -1) {
          this.checkedPhones[0].phoneNumber = this.checkedPhones[0].phoneNumber.substring(0, firstCommaIndex);
        }
      }
    },
    handleExcelFileUpload(event) {
      const file = event.target.files[0]
      // console.log('file:', file)


      if (file) {
        const reader = new FileReader()
        // console.log('reader:', reader)
        reader.onload = () => {
          const fileData = reader.result
          const workbook = XLSX.read(fileData, { type: 'binary' })
          const sheetName = workbook.SheetNames[0]
          const sheet = workbook.Sheets[sheetName]
          const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 })
          this.headerFields = rows[0]
          this.excelfile = file.name
          this.friendTalkDetails.customer_group_alias = file.name.split('.').slice(0, -1).join('.');
          this.excel_file = file;
        };
        reader.readAsBinaryString(file)

        this.selectedPhoneNumberField = ''
        this.to_phone_num_index = ''
        this.selectedParamField1 = ''
        this.selectedParamField2 = ''
        this.selectedParamField3 = ''
        this.selectedParamField4 = ''
        this.selectedParamField5 = ''
        this.selectedParamField6 = ''
        this.params.length = 0;
        this.fileSelected = true;
        this.phoneSelected = false;
        this.param1Selected = false;
        this.param2Selected = false;
        this.param3Selected = false;
        this.param4Selected = false;
        this.param5Selected = false;
        this.param6Selected = false;
      }
    },
    downloadExcelSample(){
      const filePath = '/엑셀샘플.xlsx';
      window.location.href = filePath;
    },
    selectCustomerGroupId(option) {
      this.isLoading = true;
      this.friendTalkDetails.customer_group_id = option;

      const getcontactlistPayload = {
        customer_group_id: this.friendTalkDetails.customer_group_id,
        limit: "100",
      };

      ApiCall.call('group/contact/list', 'POST', getcontactlistPayload).then(res => {
        this.contact_list_count = res.data.record_count;
        const contact_list = res.data.data.map(item => {
          return {
            // primary: item.phone_number,
            primary: item.phone_number.substring(0, item.phone_number.length - 4) + '****',
            values: [
              item.param_list,
            ],
            secondary: '',
            secondary_color: '',
            info: '',
            image: '',
            image_color: '',
            icon: '',
          };
        });
        this.tableData = res.data.data;
        // this.contact_list = contact_list;
        this.contact_list = contact_list.map(item => ({
          ...item,
          values: item.values.map(value => String(value).replace(/"/g, '')),
        }));
        const newArray = this.contact_list[0].values[0].split(",");
        this.address_params = newArray;
      });
      this.addressSelected = true;
      this.isLoading = false;
    },
    toggleCheckbox(event, idx) {
      if (this.checkedPhones[idx].checked){
        this.checkedPhones[idx].checked = false;
        if(this.checkedPhones[idx].phoneNumber === '')
          this.checkedPhones.splice(idx, 1);
      } else {
        this.checkedPhones[idx].checked = true;
        if (idx === this.checkedPhones.length-1)
          this.checkedPhones.push({ checked: false, phoneNumber: "" });
      }
      this.$nextTick(() => {
        const table = document.getElementById("to_phone_table");
        table.scrollTo(0, table.scrollHeight);
      });
    },
    handlePhoneSelection(option) {
      this.selectedPhoneNumberField = option
      this.to_phone_num_index = this.headerFields.indexOf(option)
      console.log("to_phone_num_index :", this.to_phone_num_index)
      this.phoneSelected = true;
    },
    handleParamSelection1(option) {
      this.friendTalkDetails.pages[this.currentPage].typed_contents = this.friendTalkDetails.pages[this.currentPage].typed_contents.replace("{"+this.selectedParamField1+"}", "");
      this.selectedParamField1 = option
      this.params[0] = this.headerFields.indexOf(option)
      this.param1Selected = true;
      this.friendTalkDetails.pages[this.currentPage].typed_contents += "{"+this.selectedParamField1+"}"
    },
    handleParamSelection2(option) {
      this.friendTalkDetails.pages[this.currentPage].typed_contents = this.friendTalkDetails.pages[this.currentPage].typed_contents.replace("{"+this.selectedParamField2+"}", "");
      this.selectedParamField2 = option
      this.params[1] = this.headerFields.indexOf(option)
      this.param2Selected = true;
      this.friendTalkDetails.pages[this.currentPage].typed_contents += "{"+this.selectedParamField2+"}"
    },
    handleParamSelection3(option) {
      this.friendTalkDetails.pages[this.currentPage].typed_contents = this.friendTalkDetails.pages[this.currentPage].typed_contents.replace("{"+this.selectedParamField3+"}", "");
      this.selectedParamField3 = option
      this.params[2] = this.headerFields.indexOf(option)
      this.param3Selected = true;
      this.friendTalkDetails.pages[this.currentPage].typed_contents += "{"+this.selectedParamField3+"}"
    },
    handleParamSelection4(option) {
      this.friendTalkDetails.pages[this.currentPage].typed_contents = this.friendTalkDetails.pages[this.currentPage].typed_contents.replace("{"+this.selectedParamField4+"}", "");
      this.selectedParamField4 = option
      this.params[3] = this.headerFields.indexOf(option)
      this.param4Selected = true;
      this.friendTalkDetails.pages[this.currentPage].typed_contents += "{"+this.selectedParamField4+"}"
    },
    handleParamSelection5(option) {
      this.friendTalkDetails.pages[this.currentPage].typed_contents = this.friendTalkDetails.pages[this.currentPage].typed_contents.replace("{"+this.selectedParamField5+"}", "");
      this.selectedParamField5 = option
      this.params[4] = this.headerFields.indexOf(option)
      this.param5Selected = true;
      this.friendTalkDetails.pages[this.currentPage].typed_contents += "{"+this.selectedParamField5+"}"
    },
    handleParamSelection6(option) {
      this.friendTalkDetails.pages[this.currentPage].typed_contents = this.friendTalkDetails.pages[this.currentPage].typed_contents.replace("{"+this.selectedParamField6+"}", "");
      this.selectedParamField6 = option
      this.params[5] = this.headerFields.indexOf(option)
      this.param6Selected = true;
      this.friendTalkDetails.pages[this.currentPage].typed_contents  += "{"+this.selectedParamField6+"}"
    },
    showConfirmation(count) {
      var confirmationMessage = ""
      if (this.formData.send_status == 'schedule-request'){
        confirmationMessage = "총" + count + "건의 메시지가 예약 됩니다. 예약 하시겠습니까?";
      } else {
        confirmationMessage = "총" + count + "건의 메시지는 즉시전송 됩니다. 전송 하시겠습니까?";
      }
      if (window.confirm(confirmationMessage)) {
        return true;
      } else {
        this.isLoading = false;
        return false;
      }
    },
    transformItemList(object) {
      const list = [];

      object.pages.forEach(page => {
        const newItem = {
          title : page.typed_contents,
          img_url : page.imageFileUrl,
          url_mobile : page.imageURL,
        };

        list.push(newItem);
      });

      return list;
    },
    transformCarousel(object) {
      const list = [];

      object.pages.forEach(page => {
        const newItem = {
          header : page.title,
          message : page.typed_contents,
          attachment : {
            image : {
              img_url : page.imageFileUrl,
              img_link : page.imageURL,
            },
          },
        };
        if (page.insertedButtons.length > 0) {
          newItem.attachment.button = this.makeButtonObjectList(page.insertedButtons);
        }
        list.push(newItem);
      });

      return list;
    },
    makeButtonObjectList(insertedButtons) {
      const list = [];

      insertedButtons.forEach(button => {
        switch (button.linkType) {
          case 'WL':
            list.push({
              name: button.name,
              type: button.linkType,
              url_pc: button.links.linkPc,
              url_mobile: button.links.linkMo,
            });
            break;
          case 'AL':
            list.push({
              name: button.name,
              type: button.linkType,
              scheme_android: button.links.linkAnd,
              scheme_ios: button.links.linkIos,
            });
            break;
          case 'BK':
          case 'MD':
          case 'BC':
          case 'BT':
            list.push({
              name: button.name,
              type: button.linkType,
            });
            break;
        }
      })

      return list;
    },
    getCurrentDateTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = '00';

      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    },
  },
};
</script>
