<template>
  <div class="nav-wrapper position-relative end-0">
    <ul :class="['nav', `${name}`, 'nav-pills', 'nav-fill', 'p-1']" role="tablist" style="background-color: #dee2e6;">
      <li v-for="(item, index) in selectItems" :key="index" class="nav-item">
        <a
          :class="[`${name}`, 'nav-link', 'mb-0', 'px-0', 'py-1', { active: currentTab === item.type }, `active-${boxHeight}`]"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          :aria-selected="currentTab === item.type ? 'true' : 'false'"
          @click="selectTab(item.type)"
        >
          <div class="row">
            <span>{{ item.name }}</span>
            <span v-if="item.name !== ''" style="font-size: 0.75rem; color: grey">{{ item.description }}</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills-custom.js";

export default {
  name: "CampaignNavPill",
  props: {
    boxHeight: {
      type: Number,
      required: true,
    },
    selectItems: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      currentTab: this.selectItems.length > 0 ? this.selectItems[0].type : null,
    };
  },
  mounted() {
    setNavPills(this.name);
  },
  methods: {
    selectTab(tab) {
      console.log("clicked : " + tab);
      this.currentTab = tab;
      console.log("currentTab : " + this.currentTab);
      this.$emit("select-form", tab);
    },
  },  
};
</script>
