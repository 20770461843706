<template>
  <div class="avatar" :class="getSize(size)">
    <img
      :src="selectedImage"
      :alt="alt"
      :class="[getClasses(shadow, circular, borderRadius)]"
    />
  </div>
</template>

<script>
const images = {
  0: require('@/assets/img/avatar/0.jpeg'),
  1: require('@/assets/img/avatar/1.jpeg'),
  2: require('@/assets/img/avatar/2.jpeg'),
  3: require('@/assets/img/avatar/3.jpeg'),
  4: require('@/assets/img/avatar/4.jpeg'),
  5: require('@/assets/img/avatar/5.jpeg'),
  6: require('@/assets/img/avatar/6.jpeg'),
  7: require('@/assets/img/avatar/7.jpeg'),
  8: require('@/assets/img/avatar/8.jpeg'),
  9: require('@/assets/img/avatar/9.jpeg'),
  10: require('@/assets/img/avatar/10.jpeg'),
  11: require('@/assets/img/avatar/11.jpeg'),
  12: require('@/assets/img/avatar/12.jpeg'),
  13: require('@/assets/img/avatar/13.jpeg'),
  14: require('@/assets/img/avatar/14.jpeg'),
}
export default {
  name: "MaterialAvatar",
  props: {
    avatar: {
      type: String,
      default: Math.floor(Math.random() * 14).toString()
    },
    alt: {
      type: String,
      default: "",
    },
    size:  {
      type: String,
      default: "",
    },
    shadow: {
      type: String,
      default: "",
    },
    borderRadius:  {
      type: String,
      default: "",
    },
    circular:  {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const img = '../assets/img/avatar/0.jpeg'
    return {
      img,
    }
  },
  data() {
    return {
      selectedImage: '',
      internalAvatar: this.avatar,
    }
  },
  watch: {
    avatar(newVal) {
      this.internalAvatar = newVal;
      this.chooseImage();
    },
  },
  mounted() {
    this.chooseImage()
  },
  methods: {
    getSize: (size) => (size ? `avatar-${size}` : null),
    getClasses: (shadow, circular, borderRadius) => {
      let shadowValue, circularValue, borderRadiusValue;

      if (shadow) {
        shadowValue = shadow === "regular" ? "shadow" : `shadow-${shadow}`;
      } else {
        shadowValue = null;
      }

      circularValue = circular ? "rounded-circle" : null;

      borderRadiusValue = borderRadius ? `border-radius-${borderRadius}` : null;

      return `${shadowValue} ${circularValue} ${borderRadiusValue}`;
    },
    chooseImage() {
      this.selectedImage = images[this.avatar]
    }
  },
};
</script>
