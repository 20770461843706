<template>
  <div class="row radio-group">
    <div class="col-4">
      <span style="font-weight: bold; font-size: 0.9rem;">{{options.name}}</span>
    </div>
    <div class="col-8">
      <button
        v-for="(option, index) in options.list"
        :key="index"
        class="custom-button"
        :class="{'selected': selectedOption === option }"
      >
        {{ option }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSelectTargetSegment',
  props: {
    options: {
      type: Object,
      required: true
    }
  },
emits: ['selected'],
  data() {
    return {
      selectedOption: this.options.length > 0 ? this.options[0] : null
    };
  },
  methods: {
  }
};
</script>

<style>
.radio-group {
  display: flex;
  flex-direction: row;
}

button.custom-button {
  margin-right: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 2px 10px;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: none;
  font-size: .75rem;
}

button.custom-button.selected {
  background-color: dimgray;
  color: #fff;
}
</style>
