<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">채널 별 발송 현황</h6>
        <!-- <button
          type="button"
          class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title=""
          data-bs-original-title="See traffic channels"
        >
          <i class="fas fa-info" aria-hidden="true"></i>
        </button> -->
      </div>
    </div>
    <div class="p-3 pb-0 mt-4 card-body">
      <div class="row">
        <div class="col-7 text-start">
          <pie-chart
            :chart="{
              labels: ['SMS', 'LMS', 'MMS', 'Kakao'],
              datasets: {
                label: 'Projects',
                data: [sms_success_count, lms_success_count, mms_success_count, kakao_success_count],
              },
            }"
          />
        </div>
        <div class="my-auto col-5">
          <span class="badge badge-md badge-dot me-4 d-block text-start">
              <i class="bg-secondary"></i>
              <span class="text-xs text-dark">SMS</span>
            </span>
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-info"></i>
            <span class="text-xs text-dark">LMS</span>
          </span>
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-primary"></i>
            <span class="text-xs text-dark">MMS</span>
          </span>
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-dark"></i>
            <span class="text-xs text-dark">Kakao</span>
          </span>
          
        </div>
      </div>
    </div>
    <div class="p-3 pt-0 pb-0 card-footer d-flex align-items-center">
      <div class="w-100">
        <p class="text-sm">
          이번 달 전체 채널의 발송 <b>{{(sms_success_count+lms_success_count+mms_success_count+kakao_success_count).toLocaleString() }}건</b> 중 {{best_type}}가 <b>{{best_count.toLocaleString()}}건</b> 으로 가장 많이 전송하였다.
        </p>
      </div>
      <!-- <div class="w-40 text-end">
        <a class="mb-0 btn bg-light text-end" href="javascript:;">더 보기</a>
      </div> -->
    </div>
  </div>
</template>

<script>
import PieChart from "@/examples/Charts/PieChart.vue";
import ApiCall from "@/Interface/ApiCall";
import { Auth } from 'aws-amplify'
export default {
  name: "ChannelsChartCard",
  components: {
    PieChart,
  },
  data() {
    return {
      sms_success_count : 0,
      lms_success_count : 0,
      mms_success_count : 0,
      kakao_success_count : 0,
      best_count: 0, // best_count 변수 추가
      best_type: '', // best_type 변수 추가
    };
  },
  async mounted() {  
    this.setSuccessFailCount();
  },
  methods: { 
    getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    async setSuccessFailCount() {
      const date = new Date();
      const user = await Auth.currentAuthenticatedUser()
      const idToken = user.signInUserSession.idToken;
      const tenantId = idToken.payload['cognito:groups'][0];
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');      
      const year_month = `${year}${month}`;
      const payload = {
          year_month: year_month,
          // send_type: ["sms", "lms", "mms", "at", "ai","email"],
          tenant_id: tenantId,
        };
      ApiCall.call('marketing/campaign/dashboard/sendtype', 'POST', payload)
        .then(response => {
            const this_month = response.data['this_month']
            // Store the counts in the Vue component's data
            this.sms_success_count = this_month['sms'];
            this.lms_success_count = this_month['lms'];
            this.mms_success_count = this_month['mms'];
            this.kakao_success_count = this_month['kakao'];

                // 각 타입별 카운트를 객체로 구성
            const counts = {
              SMS: this.sms_success_count,
              LMS: this.lms_success_count,
              MMS: this.mms_success_count,
              Kakao: this.kakao_success_count,
            };

            // 가장 큰 카운트 값을 찾아 best_count에 할당하고, 해당 타입을 best_type에 저장
            this.best_count = 0;
            this.best_type = '';
            for (const [type, count] of Object.entries(counts)) {
              if (count > this.best_count) {
                this.best_count = count;
                this.best_type = type;
              }
            }

        })
        .catch(error => {
            console.error('Error: Unable to fetch data from API', error);
        });
    },
  },
};
</script>
