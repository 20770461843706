<template>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <title>SK(주) C&C 대량 단체문자 발송 - Message To-Go | SMS, LMS, 알림톡 API 기업 문자 서비스</title>
    <link rel="icon" href="https://to-go.io/sk_logo.jpg" type="image/jpg">
<!--    <script src="https://kit.fontawesome.com/42d5adcbca.js" crossorigin="anonymous"></script>-->
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-icons.css">
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-svg.css">
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/soft-ui-dashboard.min.css?v=1.0.2">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700">
    <link rel="stylesheet" href="../assets/css/theme.css">
    <link rel="stylesheet" href="../assets/css/loopple/loopple.css">
  </head>

  <body class="g-sidenav-show">
    <div class="container position-sticky z-index-sticky top-0">
            <div class="row">
              <div class="col-12">
                  <nav class="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
                      <div class="container-fluid">
                          <!-- <a class="navbar-brand font-weight-bolder ms-sm-3" href="javascript:;" rel="tooltip" title="Designed and Coded by Creative Tim" data-placement="bottom">Message To-Go</a> -->
                          <a class="navbar-brand ms-sm-3" href="/home">
                            <!-- <img src="../assets/img/m2g-logo-only.png" alt="Logo" height="30"> -->
                            <img src="../assets/img/SK_logo.svg" alt="Your SVG Image" style="position: relative; width: 30%; height: 20%; margin-top: -10px; margin-bottom: -10px; margin-right: 10px; margin-left: -10px;">
                            <!-- <span class="font-weight-bolder"> Message To-Go</span>    -->
                          </a>
                          <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                              <span class="navbar-toggler-icon mt-2">
                                  <span class="navbar-toggler-bar bar1"></span>
                                  <span class="navbar-toggler-bar bar2"></span>
                                  <span class="navbar-toggler-bar bar3"></span>
                              </span>
                          </button>
                          <div id="navigation" class="collapse navbar-collapse pt-3 pb-2 py-lg-0">
                              <ul class="navbar-nav navbar-nav-hover ms-lg-12 ps-lg-5 w-100">
                                  <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="/home#channels" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">채널소개</span>
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="/home#features" aria-expanded="false">
                                        <span class="font-weight-bolder" style="font-size: larger;">기능</span>
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="/home#product" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">요금제</span>
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/contactus" aria-expanded="false">
                                        <span class="font-weight-bolder" style="font-size: larger;">문의하기</span>
                                      </router-link>
                                  </li>
                                  <li class="nav-item ms-lg-auto my-auto ms-3 ms-lg-0">
                                    <router-link class="btn btn-sm  bg-gradient-primary  btn-round mb-0 me-1 mt-2 mt-md-0" to="/authentication/signin/basic">
                                      회원가입/로그인
                                    </router-link>
                                  </li>
                                </ul>
                          </div>
                      </div>
                  </nav>
              </div>
          </div>
      </div>
      <section class="py-5 position-relative">
          <div class="container">
              <div class="row mt-5">
                  <div class="col-lg-10 mx-auto">
                      <div class="card">
                          <div class="row">
                              <div class="col-lg-5 d-flex">                                
                                  <div class="bg-gradient-dark my-lg-3 ms-lg-3 border-radius-md">
                                      <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/shapes/waves-white.svg" alt="pattern-lines" class="position-absolute start-0 top-0 h-100 opacity-6">
                                      <div class="card-body p-5 position-relative">
                                          <img src="../assets/img/logo-m2g.png" class="img-fluid mb-4">
                                          <h3 class="text-white text-center">Contact Us</h3>
                                          <p class="text-white opacity-8 mb-4 text-center">질문 또는 의견 있으시면 언제든 연락주세요!<br>저희 팀이 빠른 시간내에 답변 드리겠습니다.</p>
                                          <div class="d-flex p-2">
                                              <div>
                                                  <i class="fas fa-envelope text-white" aria-hidden="true"></i>
                                              </div>
                                              <div class="ps-3">
                                                  <a href="mailto:event@message.to-go.io" class="text-white"><span class="text-sm opacity-8">message.to-go@sk.com</span></a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-7">
                                  <form id="contact-form" method="post" autocomplete="off">
                                      <div class="card-body p-5 position-relative">
                                        <div class="row mt-8">
                                          <h3 class="text-black text-center">감사합니다.</h3>
                                          <h4 class="text-black text-center">빠른시간안에 회신드리겠습니다.</h4>
                                        </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  </body>
</template>

<script>
import { useStore } from 'vuex';
import axios from 'axios';
import ApiCall from "@/Interface/ApiCall";
export default {
  name: 'Pricing',
  beforeMount() {
    const store = useStore();
    store.commit('toggleEveryDisplay', false);
  },
  beforeUnmount() {
    const store = useStore();
    store.commit('toggleEveryDisplay', true);
  },
  mounted(){
  },
  methods: {
    getQueryString() {
      return window.location.search;
    }
  },
};

</script>

<style scoped></style>
