<template>
    <div class="card h-100 mt-4 me-2">
        <div id="send-history" class="card mt-0">
            <!-- Card header -->
            <div class="card-header">
                <h6>주소록 관리</h6>
            </div>
            <div class="card-body pt-0">
                <div class="row align-items-center">
                    <div class="col-sm-6">
                        <material-button id="choices-groupNumber" color="success" size="sm" variant="outline"
                            class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                            style="width: 450px" :disabled="isLoading" @change="selectedGroup = $event.target.value">
                            {{ selectedGroup || "주소록" }}
                        </material-button>
                        <ul class="dropdown-menu" aria-labelledby="choices-groupNumber">
                            <li v-for="group in receiveGroupList" :key="group">
                                <a class="dropdown-item" href="javascript:;" @click="selectedGroup = group">
                                    {{ group }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-6 text-end">
                        <material-button color="success" size="sm" variant="default" class="me-3" style="width: 120px;"
                            @click="searchGroupItem()">
                            조회
                        </material-button>
                        <material-button color="success" size="sm" variant="default" class="me-3" style="width: 120px;"
                            @click="createGroup()">
                            등록
                        </material-button>
                        <material-button color="primary" size="sm" variant="default" style="width: 120px;"
                            @click="deleteGroup()">
                            삭제
                        </material-button>
                    </div>
                    <!-- <div class="col-6" style="max-width: 180px;">
                <material-input 
                    id="from_phone_number_alias"
                    type="text" 
                    size="sm" 
                    label="발신번호 명"
                    labelSize="sm"
                    v-model="from_phone_number_alias"                  
                    variant="outline" 
                >
                </material-input>
              </div> -->
                </div>
                <div class="row mt-3">
                </div>
            </div>
        </div>
        <!-- 주소록 등록 Modal -->
        <div id="createGroupModal" class="modal fade" tabindex="-1" aria-labelledby="createGroupModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title" id="addressBookModalLabel">{{ modalTitle }}</h5> -->
                        <h5 id="createGroupModalLabel" class="modal-title">주소록 등록</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!-- Modal body content goes here -->
                        <div class="d-flex">
                            <div class="flex-fill">
                                <label class="form-control ms-0">엑셀 업로드</label>
                                <label class="btn-lg">
                                    <input ref="fileInput" type="file" style="display: none;"
                                        @change="handleExcelUpload" />
                                    <span style="cursor: pointer;">
                                        <i class="material-icons icon-hover icon-large"
                                        >attach_file</i>
                                        <span v-if="uploadedFilename" style="margin-left: 10px;">{{ uploadedFilename
                                            }}</span>
                                    </span>
                                </label>
                            </div>
                            <div class="flex-fill">
                                <label class="form-control ms-0">샘플 다운로드</label>
                                <label class="btn-lg">
                                    <span style="cursor: pointer;">
                                        <i class="material-icons icon-hover icon-large"
                                        @click="downloadExcelSample">attach_file</i>
                                        <span v-if="uploadedFilename" style="margin-left: 10px;">{{ 
                                            }}</span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <span style="font-weight: bold; font-size: 0.8rem;">전화번호를 선택하세요</span><br>
                            <material-button id="toPhoneNumberIndex" color="success" size="sm" variant="outline"
                                class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                style="width: 180px">
                                {{ selectedPhoneNumberField || "전화번호헤더 선택" }}
                            </material-button>
                            <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                <li v-for="field in headerFields" :key="field">
                                    <a class="dropdown-item border-radius-md" href="javascript:;"
                                        @click="handlePhoneSelection(field)">{{ field }}</a>
                                </li>
                            </ul>
                            <material-input id="addresstext" v-model="groupName" class="mt-1" type="text"
                                placeholder="주소록명을 입력하세요" />
                        </div>
                        <div class="col" style="margin-top: 10px;">
                            <span style="font-weight: bold; font-size: 0.8rem;">별칭</span>
                            <div class="d-flex" style="width: 450px;">
                                <material-button id="aliasindex" color="success" size="sm" variant="outline"
                                    class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                    style="width: 150px">
                                    {{ selectedAliasField || "별칭" }}
                                </material-button>
                                <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                    <li v-for="field in headerFields" :key="field">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="handleAliasSelection(field)">{{ field }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col" style="margin-top: 10px;">
                            <span style="font-weight: bold; font-size: 0.8rem;">변수</span>
                            <div class="d-flex" style="width: 450px;">
                                <material-button id="paramIndex1" color="success" size="sm" variant="outline"
                                    class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                    style="width: 150px">
                                    {{ selectedParamField1 || "변수1" }}
                                </material-button>
                                <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                    <li v-for="field in headerFields" :key="field">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="handleParmSelection1(field)">{{ field }}</a>
                                    </li>
                                </ul>
                                <material-button id="paramIndex2" color="success" size="sm" variant="outline"
                                    class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                    style="width: 150px">
                                    {{ selectedParamField2 || "변수2" }}
                                </material-button>
                                <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                    <li v-for="field in headerFields" :key="field">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="handleParmSelection2(field)">{{ field }}</a>
                                    </li>
                                </ul>
                                <material-button id="paramIndex3" color="success" size="sm" variant="outline"
                                    class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                    style="width: 150px">
                                    {{ selectedParamField3 || "변수3" }}
                                </material-button>
                                <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                    <li v-for="field in headerFields" :key="field">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="handleParmSelection3(field)">{{ field }}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="d-flex mt-1" style="width: 450px;">
                                <material-button id="paramIndex4" color="success" size="sm" variant="outline"
                                    class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                    style="width: 150px">
                                    {{ selectedParamField4 || "변수4" }}
                                </material-button>
                                <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                    <li v-for="field in headerFields" :key="field">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="handleParmSelection4(field)">{{ field }}</a>
                                    </li>
                                </ul>
                                <material-button id="paramIndex5" color="success" size="sm" variant="outline"
                                    class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                    style="width: 150px">
                                    {{ selectedParamField5 || "변수5" }}
                                </material-button>
                                <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                    <li v-for="field in headerFields" :key="field">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="handleParmSelection5(field)">{{ field }}</a>
                                    </li>
                                </ul>
                                <material-button id="paramIndex6" color="success" size="sm" variant="outline"
                                    class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                    style="width: 150px">
                                    {{ selectedParamField6 || "변수6" }}
                                </material-button>
                                <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                    <li v-for="field in headerFields" :key="field">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="handleParmSelection6(field)">{{ field }}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="d-flex mt-1" style="width: 450px;">
                                <material-button id="paramIndex7" color="success" size="sm" variant="outline"
                                    class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                    style="width: 150px">
                                    {{ selectedParamField7 || "변수7" }}
                                </material-button>
                                <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                    <li v-for="field in headerFields" :key="field">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="handleParmSelection7(field)">{{ field }}</a>
                                    </li>
                                </ul>
                                <material-button id="paramIndex8" color="success" size="sm" variant="outline"
                                    class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                    style="width: 150px">
                                    {{ selectedParamField8 || "변수8" }}
                                </material-button>
                                <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                    <li v-for="field in headerFields" :key="field">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="handleParmSelection8(field)">{{ field }}</a>
                                    </li>
                                </ul>
                                <material-button id="paramIndex9" color="success" size="sm" variant="outline"
                                    class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                    style="width: 150px">
                                    {{ selectedParamField9 || "변수9" }}
                                </material-button>
                                <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                                    <li v-for="field in headerFields" :key="field">
                                        <a class="dropdown-item border-radius-md" href="javascript:;"
                                            @click="handleParmSelection9(field)">{{ field }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal" :is-loading="isLoading"
                            @click="excelSubmit()">주소록 등록</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                        <material-button-progress color="primary" size="sm" variant="default"
                            style="width: 120px; margin-right: 5px;" :is-loading="isLoading" @click="excelSubmit">
                            등록
                        </material-button-progress>
                        <material-button color="primary" size="sm" variant="default"
                            style="width: 120px; margin-right: 5px;" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeRequest">닫기</material-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 주소록 삭제 Modal -->
        <div id="deleteGroupModal" class="modal fade" tabindex="-1" aria-labelledby="deleteGroupModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title" id="createGroupModalLabel">{{ modalTitle }}</h5> -->
                        <h5 id="deleteGroupModalLabel" class="modal-title">주소록 삭제</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-sm-9">
                            <material-button id="choices-groupNumber" color="success" size="sm" variant="outline"
                                class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                style="width: 180px" :disabled="isLoading"
                                @change="selectedGroup = $event.target.value">
                                {{ selectedGroup || "주소록" }}
                            </material-button>
                            <ul class="dropdown-menu" aria-labelledby="choices-groupNumber">
                                <li v-for="group in receiveGroupList" :key="group">
                                    <a class="dropdown-item" href="javascript:;" @click="selectedGroup = group">
                                        {{ group }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!-- Modal body content goes here -->
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="resetData()">Close</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                            @click="excelDelete()">주소록삭제</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card mt-2 me-2">
            <!-- Card header -->
            <div class="card-header">
                <div class="col-12 d-flex justify-content-between align-items-center">
                    <div v-if="result_count < 0">
                        <h6>전화번호 목록</h6>
                    </div>
                    <div v-if="result_count >= 0">
                        <h6>전화번호 목록 {{ result_count }}건</h6>
                    </div>
                    <material-button :disabled="!isDownload" size="sm" variant="default" style="width: 120px;"
                        @click="downloadFile">다운로드</material-button>
                </div>
            </div>
            <!--                        <div v-if="isLoading" class="d-flex justify-content-center align-items-center">-->
            <!--                            <div class="spinner-container">-->
            <!--                                <i class="fas fa-spinner fa-spin spinner-icon text-info"></i>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <div class="card-body">
                <EasyDataTable table-class-name="customize-table" empty-message="조회된 내역이 없습니다."
                    rows-per-page-message="페이지당 표시 행 수" rows-of-page-separator-message="중" :headers="tableColumns"
                    :items="tableItem" :loading="isLoading">
                    <template #loading>
                        <img src="@/assets/img/Spinner-1s-200px.gif" style="width: 100px; height: 100px;" />
                    </template>
                </EasyDataTable>
            </div>
            <!-- <div class="table-responsive" ref="myTable">
                            <table id="datatable-basic" class="table table-flush">
                                <thead class="thead-light">
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                                            v-for="(header, index) in headers" :key="index">
                                            {{ header }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
                                        <td v-for="(header, headerIndex) in headers" :key="headerIndex">
                                            {{ row[header] }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->
        </div>
    </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import { DataTable } from "simple-datatables";
import MaterialButton from "@/components/MaterialButton.vue";
import Choices from "choices.js";
import axios from 'axios';
import XLSX from 'xlsx/dist/xlsx.full.min.js';
import ApiCall from "@/Interface/ApiCall";
import MaterialTable from "@/components/MaterialTable.vue";
import MaterialPagination from "@/components/MaterialPagination.vue";
import MaterialButtonProgress from "@/components/MaterialButtonProgress.vue";

export default {
    name: "GroupHistory",
    components: {
        MaterialButton,
        MaterialInput,
        MaterialButtonProgress,
        // MaterialTable,
        // MaterialPagination,
    },
    data() {
        return {
            // 발신인 정보
            //주소록 수신인
            // tableColumns: ['별칭','전화번호','변수'],
            tableColumns: [
                { text: "별칭", value: "alias", sortable: true },
                { text: "전화번호", value: "phone_number", sortable: true },
                { text: "변수", value: "param_list", sortable: true },
            ],
            receiveGroupList: [''], //Group명 IF통해서 저장하는 배열
            selectedGroup: null,
            //대량발송 수신인
            selectedExcelFile: null,
            groupName: '',
            groupItem: [],
            tableItem: [],
            itemsPerPage: 5000,
            currentPage: 1, // Add this line
            uploadedFilename: null,
            isDownload: false,
            objectKey: '',
            isLoading: true,
            params: [],
            to_phone_num_index: '',
            alias_index: '',
            headerFields: [],
            selectedPhoneNumberField: '전화번호 선택',
            selectedParamField1: '변수1',
            selectedParamField2: '변수2',
            selectedParamField3: '변수3',
            selectedParamField4: '변수4',
            selectedParamField5: '변수5',
            selectedParamField6: '변수6',
            selectedParamField7: '변수7',
            selectedParamField8: '변수8',
            selectedParamField9: '변수9',
            selectedAliasField: '별칭',
            result_count: -1,
        };
    },
    computed: {
        paginatedRows() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.rows.slice(startIndex, endIndex);
        },
        // 계산된 속성 작성
        modalTitle() {
            return "";
        },
        // headers() {
        //     let headerSet = new Set();
        //     this.groupItem.forEach(item => {
        //         Object.keys(item).forEach(key => {
        //             if (key !== "param_list") {
        //                 headerSet.add(key);
        //             } else {
        //                 item[key].forEach((subItem, index) => {
        //                     headerSet.add(`${key}[${index}]`);
        //                 })
        //             }
        //         });
        //     });
        //     return ['별칭', '전화번호', ...Array.from(headerSet), ''];
        // },
        rows() {
            return this.groupItem.map(item => {
                let row = {};
                Object.keys(item).forEach(key => {
                    if (key == "alias") {
                        row["primary"] = item[key];
                    }
                    else if (key == "phone_number") {
                        row["values"] = [item[key]];
                    }
                    else if (key == "param_list") {
                        row["values"] = (row["values"] || []).concat(item[key]);
                    }
                });
                return row;
            });
        },
    },
    mounted() {
        // 마운트 시 실행할 로직 작성
        this.fetchreceiveGroup();
        // const dataTableBasic = new DataTable("#datatable-basic", {
        //     searchable: false,
        //     fixedHeight: true,
        // });
        // this.$nextTick(() => {
        //     this.$refs.myTable.DataTable();
        // })

    },
    methods: {
        test() {
            this.tableColumns = [
                { text: "별칭2", value: "alias", sortable: true },
                { text: "전화번호2", value: "phone_number", sortable: true },
                { text: "변수2", value: "param_list", sortable: true },
                { text: "변수3", value: "param_list", sortable: true },
            ]
        },
        onPageChange(pageNumber) {
            this.currentPage = pageNumber;
        },
        onRowClicked({ list, index }) {
            this.selectedList = list;
            this.selectedRow = index;
            // console.log(JSON.stringify(this.selectedList));
        },
        downloadExcelSample(){
            const filePath = '/엑셀샘플.xlsx';
            console.log("엑셀샘플다운");
            window.location.href = filePath;
        },
        // 메서드 작성
        searchGroupItem() {

            if (!this.selectedGroup) {
                alert("선택된 주소록이 없습니다.");
                return;
            }
            this.isLoading = true;

            // console.log("selectedGroup", this.selectedGroup);
            const payload = {
                customer_group_id: this.selectedGroup,
                limit: "10000",
            };
            //this.receiveGroupList = [''];
            ApiCall.call("group/contact/list", "POST", payload)
                .then((res) => {
                    this.result_count = res.data.data.length
                    this.groupItem = res.data.data;
                    // data flatten

                    this.tableItem = this.groupItem.map((obj) => {
                        if (obj.alias == obj.phone_number)
                            obj.alias = obj.phone_number.substring(0, obj.phone_number.length - 4) + '****';
                        const convertedObj = {
                            alias: obj.alias,
                            // phone_number: obj.phone_number
                            phone_number: obj.phone_number.substring(0, obj.phone_number.length - 4) + '****',
                        }

                        obj.param_list.forEach((param, index) => {
                            convertedObj['param' + (index + 1)] = param;
                        })

                        return convertedObj;
                    });

                    const paramListLength = this.groupItem.length > 0 ? this.groupItem[0].param_list.length : 0;
                    const paramColumns = Array.from({ length: paramListLength }, (_, index) => `변수${index + 1}`);
                    if (paramListLength > 0) {
                        // this.tableColumns = ['별칭', '전화번호', ...paramColumns, ''];
                        this.tableColumns = [
                            { text: "별칭", value: "alias", sortable: true },
                            { text: "전화번호", value: "phone_number", sortable: true },
                        ]

                        paramColumns.forEach((element, index) => {
                            this.tableColumns.push({ text: element, value: 'param' + (index + 1), sortable: true })
                        });

                    } else {
                        this.tableColumns = [
                            { text: "별칭", value: "alias", sortable: true },
                            { text: "전화번호", value: "phone_number", sortable: true },
                        ]
                    }

                    // console.log('get Group item groupItem', this.groupItem);
                    this.isDownload = true;
                    this.objectKey = res.data.objectKey;
                    this.isLoading = false;
                })
                .catch((e) => {
                    // console.log(e);
                    this.isLoading = false;
                });

        },
        async fetchreceiveGroup() {
            const data = {
            };
            //this.receiveGroupList = [''];
            const response = ApiCall.call('group/list', 'POST', data).then(res => {

                // console.log('receive Group 결과', res);
                this.receiveGroupList = res.data;
                this.isLoading = false;
            })
                .catch((e) => {
                    // console.log(e);
                });
        },
        async closeRequest() {
            this.resetData();
        },
        createGroup() {
            const bootstrap = this.$store.state.bootstrap;
            // 모달 열기
            const modal = new bootstrap.Modal(
                document.getElementById("createGroupModal")
            );
            modal.show();
        },
        deleteGroup() {
            const bootstrap = this.$store.state.bootstrap;

            // 모달 열기
            const modal = new bootstrap.Modal(
                document.getElementById("deleteGroupModal")
            );
            this.fetchreceiveGroup();
            modal.show();

        },
        handleExcelUpload(event) {
            const file = event.target.files[0]
            console.log('file:', file)


            if (file) {
                const reader = new FileReader()
                console.log('reader:', reader)
                reader.onload = () => {
                    const fileData = reader.result
                    const workbook = XLSX.read(fileData, { type: 'binary' })
                    const sheetName = workbook.SheetNames[0]
                    const sheet = workbook.Sheets[sheetName]
                    const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 })
                    this.headerFields = rows[0]
                    this.uploadedFilename = file.name
                    this.groupName = file.name.split('.').slice(0, -1).join('.');
                    this.selectedExcelFile = file;
                };
                reader.readAsBinaryString(file)
            }
        },
        handlePhoneSelection(option) {
            this.selectedPhoneNumberField = option
            this.to_phone_num_index = this.headerFields.indexOf(option)
        },
        handleAliasSelection(option) {
            this.selectedAliasField = option
            this.alias_index = this.headerFields.indexOf(option)
        },
        handleParmSelection1(option) {
            this.selectedParamField1 = option
            this.params[0] = this.headerFields.indexOf(option)
        },
        handleParmSelection2(option) {
            this.selectedParamField2 = option
            this.params[1] = this.headerFields.indexOf(option)
        },
        handleParmSelection3(option) {
            this.selectedParamField3 = option
            this.params[2] = this.headerFields.indexOf(option)
        },
        handleParmSelection4(option) {
            this.selectedParamField4 = option
            this.params[3] = this.headerFields.indexOf(option)
        },
        handleParmSelection5(option) {
            this.selectedParamField5 = option
            this.params[4] = this.headerFields.indexOf(option)
        },
        handleParmSelection6(option) {
            this.selectedParamField6 = option
            this.params[5] = this.headerFields.indexOf(option)
        },
        handleParmSelection7(option) {
            this.selectedParamField7 = option
            this.params[6] = this.headerFields.indexOf(option)
        },
        handleParmSelection8(option) {
            this.selectedParamField8 = option
            this.params[7] = this.headerFields.indexOf(option)
        },
        handleParmSelection9(option) {
            this.selectedParamField9 = option
            this.params[8] = this.headerFields.indexOf(option)
        },
        async excelSubmit() {
            this.isLoading = true;

            if (this.to_phone_num_index.length <= 0) {
                alert("전화번호가 있는 컬럼을 선택해주세요.");
                this.isLoading = false;
                return;
            }

            const payloadFile = {
                customer_group_id: this.groupName,
                phone_number_index: this.to_phone_num_index,
                alias_index: this.alias_index,
                params: this.params,
            };
            // console.log(payloadFile);

            try {
                const fileResponse = await ApiCall.file('upload/excel', 'POST', payloadFile, null, this.selectedExcelFile);

                // console.log(fileResponse);
                this.isLoading = false;
                alert("주소록 등록완료");
                this.resetData();

            } catch (error) {
                console.error(error);
                this.isLoading = false;
                alert("주소록 등록 실패");
                this.resetData();
            }
        },
        async excelDelete() { //그룹삭제함수
            this.isLoading = true;
            const payload = {
                customer_group_id: this.selectedGroup,
            };
            try {
                const fileResponse = await ApiCall.call('group/delete', 'POST', payload);

                // console.log(fileResponse);
                this.isLoading = false;
                alert("주소록 삭제완료");
                this.resetData();

            } catch (error) {
                console.error(error);
                this.isLoading = false;
                alert("주소록 삭제실패");
            }
        },
        resetData() {
            // location.reload(true);
            // 발신인 정보
            //주소록 수신인
            this.tableColumns = [
                { text: "별칭", value: "alias", sortable: true },
                { text: "전화번호", value: "phone_number", sortable: true },
                { text: "변수", value: "param_list", sortable: true },
            ]
            this.receiveGroupList = ['']; //Group명 IF통해서 저장하는 배열
            this.selectedGroup = null;
            //대량발송 수신인
            this.selectedExcelFile = null;
            this.groupName = '';
            this.groupItem = [];
            this.tableItem = [];
            this.currentPage = 1; // Add this line
            this.uploadedFilename = null;
            this.isDownload = false;
            this.objectKey = '';
            this.params = [];
            this.to_phone_num_index = '';
            this.alias_index = '';
            this.headerFields = [];
            this.selectedPhoneNumberField = '전화번호 선택';
            this.selectedParamField1 = '변수1';
            this.selectedParamField2 = '변수2';
            this.selectedParamField3 = '변수3';
            this.selectedParamField4 = '변수4';
            this.selectedParamField5 = '변수5';
            this.selectedParamField6 = '변수6';
            this.selectedParamField7 = '변수7';
            this.selectedParamField8 = '변수8';
            this.selectedParamField9 = '변수9';
            this.selectedAliasField = '별칭';
            this.result_count = -1;
            this.isLoading = true;
            this.fetchreceiveGroup();

        },
        downloadFile() {
            //   console.log('download file : ', this.objectKey)

            ApiCall.file('download', 'POST', null, null, null, null, this.objectKey).then(response => {
                // console.log(response)
                const data = response.data;
                const fileName = response.filename;

                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link);
                // console.log(`File saved: ${fileName}`);
            }).catch(error => {
                // console.error('Download failed:', error)
            })
        },
    },
};
</script>

<style>
.my-input .form-control {
    height: 500px;
    resize: none;

}
</style>
