<template>
  <div id="sidenav-collapse-main" class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100">
    <ul class="navbar-nav">
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse-custom-user-id v-if="userName &&  isLogin" class="M2GTour-home" collapse-ref="userIdRef" :nav-text="userName"
          :class="getRoute() === 'user' ? 'active' : ''">
          <template #icon>
<!--            <img src="../../assets/img/avatar/1.jpeg" class="avatar" />-->
            <material-avatar class="avatar"
                :avatar="sideNavAvatar"
                size="sm"
                shadow="sm"
                circular
              />
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Profile' }" mini-icon="MP" text="내 정보" />
              <!-- <sidenav-item :to="{ name: 'Settings' }" mini-icon="S" text="Settings" /> -->
              <sidenav-item-custom :to="{ name: 'Signin Cover' }" mini-icon="L" text="로그아웃" />
            </ul>
          </template>
        </sidenav-collapse-custom-user-id>
      </li>
      <hr class="horizontal light mt-2 mb-2" />
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse v-if="isLogin" collapse-ref="dashboardsExamples"
          nav-text="Dashboards" :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">dashboard</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Home' }" mini-icon="M" text="메시지" />
              <sidenav-item  v-if="false" :to="{ name: 'Analytics' }" mini-icon="A" text="분석" />
              <sidenav-item v-if="isOperator" :to="{ name: 'Campaign Dashboard' }" mini-icon="C" text="캠페인" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <!-- Message To-Go Start ========================================================================-->
      <li class="mt-3 nav-item">
        <h6 v-if="isLogin" class="text-xs ps-4 text-uppercase font-weight-bolder text-white" :class="isRTL ? 'me-4' : 'ms-2'">
          메시지
        </h6>
      </li>
      <li class="nav-item M2GTour1" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse v-if="isLogin" :expanded="isSendMessageExpand" collapse-ref="messageSend" nav-text="메시지전송" :class="getRoute() === 'message' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">sms</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item v-if="isBusinessVerified" class="M2GTour-sendSms" :to="{ name: 'SendSms' }" mini-icon="S" text="문자"/>
              <sidenav-item v-if="!isBusinessVerified" class="M2GTour-sendSms" :to="{ name: 'SendTestSms' }" mini-icon="S" text="테스트문자"/>
              <sidenav-item v-if="isBusinessVerified" :to="{ name: 'SendKakao' }" mini-icon="K" text="카카오"/>
              <sidenav-item v-if="isOperator" :to="{ name: 'SendFax' }" mini-icon="F" text="팩스"/>
              <sidenav-item v-if="!isAdmin" class="M2GTour-sendHistory" :to="{ name: 'SendHistory' }" mini-icon="H" text="전송이력"/>
              <sidenav-item v-if="isAdmin" class="M2GTour-sendHistory" :to="{ name: 'SendHistoryAdmin' }" mini-icon="H" text="전송이력"/>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item M2GTour2" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse v-if="isLogin" :expanded="isManageMessageExpand" collapse-ref="manage" nav-text="메시지관리" :class="getRoute() === 'manage' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">import_contacts</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item :to="{ name: 'GroupHistory' }" mini-icon="A" text="주소록관리" />
              <sidenav-item :to="{ name: 'SendNumber' }" mini-icon="P" text="발신번호관리" />
              <sidenav-item :to="{ name: 'KakaoTemplate' }" mini-icon="T" text="카카오템플릿관리" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if=isOperator class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse v-if="isLogin" collapse-ref="campaign" nav-text="광고진행" :class="getRoute() === 'marketing' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">campaign</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item :to="{ name: 'Register Campaign' }" mini-icon="A" text="등록" />
              <sidenav-item :to="{ name: 'Campaign History' }" mini-icon="P" text="조회" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="template" nav-text="템플릿관리" :class="getRoute() === 'template' ? 'active' : ''" v-if="isLogin">
          <template #icon>
            <i class="material-icons-round opacity-10">mms</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item :to="{ name: 'Edit Product1' }" mini-icon="S" text="SMS템플릿" />
              <sidenav-item :to="{ name: 'Edit Product2' }" mini-icon="L" text="LMS템플릿" />
              <sidenav-item :to="{ name: 'Edit Product3' }" mini-icon="M" text="MMS템플릿" />
              <sidenav-item :to="{ name: 'Edit Product4' }" mini-icon="K" text="카카오템플릿" />
            </ul>
          </template>
        </sidenav-collapse>
     </li> -->
  <li v-if="isAdmin" class="mt-3 nav-item">
    <h6 v-if="isLogin" class="text-xs ps-4 text-uppercase font-weight-bolder text-white" :class="isRTL ? 'me-4' : 'ms-2'">
      관리자
    </h6>
  </li>
  <li v-if="isAdmin" class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
    <sidenav-collapse v-if="isLogin" collapse-ref="admin" nav-text="사용자관리" :class="getRoute() === 'admin' ? 'active' : ''">
      <template #icon>
        <i class="material-icons-round opacity-10">manage_accounts</i>
      </template>
      <template #list>
        <ul class="nav pe-0">
          <sidenav-item v-if="isBusinessVerified" :to="{ name: 'RegisterParty' }" mini-icon="S" text="사용자 등록"/>
          <sidenav-item :to="{ name: 'EditParty' }" mini-icon="E" text="사용자 조회" />
        </ul>
      </template>
    </sidenav-collapse>
  </li>
  <li v-if="isLogin" class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
    <sidenav-collapse v-if="isLogin" collapse-ref="manageBilling" nav-text="청구관리" :class="getRoute() === 'billing' ? 'active' : ''">
      <template #icon>
        <i class="material-icons-round opacity-10">account_balance</i>
      </template>
      <template #list>
        <ul class="nav pe-0">
          <sidenav-item v-if="!isOperator" :to="{ name: 'Invoice' }" mini-icon="I" text="청구서"/>
          <sidenav-item v-if="isOperator" :to="{ name: 'InvoiceOperator' }" mini-icon="I" text="청구서(Operator)"/>
        </ul>
      </template>
    </sidenav-collapse>
  </li>
      <li v-if="isOperator" class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse v-if="isLogin" collapse-ref="campaignManage" nav-text="광고관리" :class="getRoute() === 'billing' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">construction</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-if="isOperator" :to="{ name: 'Approve Campaign' }" mini-icon="I" text="광고관리"/>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
  <li v-if="isOperator" class="mt-3 nav-item">
    <h6 class="text-xs ps-4 text-uppercase font-weight-bolder text-white" :class="isRTL ? 'me-4' : 'ms-2'">
      운영자
    </h6>
  </li>
  <li v-if="isOperator" class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
    <sidenav-collapse collapse-ref="manageApproval" nav-text="승인관리" :class="getRoute() === 'operator' ? 'active' : ''">
      <template #icon>
        <i class="material-icons-round opacity-10">manage_accounts</i>
      </template>
      <template #list>
        <ul class="nav pe-0">
          <sidenav-item :to="{ name: 'ApproveSendNumber' }" mini-icon="P" text="발신번호 승인" />
          <!-- <sidenav-item :to="{ name: 'ApproveTemplate' }" mini-icon="S" text="템플릿 승인" />        -->
        </ul>
      </template>
    </sidenav-collapse>
  </li>
  <!-- Message To-Go End ========================================================================-->
  <!-- <li class="mt-3 nav-item">
    <h6 class="text-xs ps-4 text-uppercase font-weight-bolder text-white" :class="isRTL ? 'me-4' : 'ms-2'">
      PAGES
    </h6>
  </li>
  <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
    <sidenav-collapse collapse-ref="pagesExamples" nav-text="Pages" :class="getRoute() === 'pages' ? 'active' : ''">
      <template #icon>
        <i class="material-icons-round opacity-10">image</i>
      </template>
      <template #list>
        <ul class="nav pe-0">
          <sidenav-collapse-item refer="profileExample" mini-icon="P" text="Profile">
            <template #nav-child-item>
              <sidenav-item :to="{ name: 'Profile Overview' }" mini-icon="P" text="Profile Overview" />
              <sidenav-item :to="{ name: 'All Projects' }" mini-icon="A" text="All Projects" />
            </template>
          </sidenav-collapse-item>


          <sidenav-collapse-item refer="accountExample" mini-icon="A" text="Account">
            <template #nav-child-item>
              <sidenav-item :to="{ name: 'Settings' }" mini-icon="S" text="Settings" />
              <sidenav-item :to="{ name: 'Billing' }" mini-icon="B" text="Billing" />
              <sidenav-item :to="{ name: 'Invoice' }" mini-icon="I" text="Invoice" />
            </template>
          </sidenav-collapse-item>

          <sidenav-collapse-item refer="projectsExample" mini-icon="P" text="Projects">
            <template #nav-child-item>
              <sidenav-item :to="{ name: 'Timeline' }" mini-icon="T" text="Timeline" />
            </template>
          </sidenav-collapse-item>

          <sidenav-item :to="{ name: 'Pricing Page' }" mini-icon="P" text="Pricing Page" />
          <sidenav-item :to="{ name: 'RTL' }" mini-icon="R" text="RTL" />
          <sidenav-item :to="{ name: 'Widgets' }" mini-icon="W" text="Widgets" />
          <sidenav-item :to="{ name: 'Charts' }" mini-icon="C" text="Charts" />
          <sidenav-item :to="{ name: 'Notifications' }" mini-icon="N" text="Notifications" />
        </ul>
      </template>
    </sidenav-collapse>
  </li>

  <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
    <sidenav-collapse collapse-ref="applicationsExamples" nav-text="Applications"
      :class="getRoute() === 'applications' ? 'active' : ''">
      <template #icon>
        <i class="material-icons-round opacity-10">apps</i>
      </template>
      <template #list>
        <ul class="nav pe-0">

          <sidenav-item :to="{ name: 'Kanban' }" mini-icon="K" text="Kanban" />
          <sidenav-item :to="{ name: 'Wizard' }" mini-icon="W" text="Wizard" />
          <sidenav-item :to="{ name: 'Data Tables' }" mini-icon="D" text="Data Tables" />
          <sidenav-item :to="{ name: 'Calendar' }" mini-icon="C" text="Calendar" />
        </ul>
      </template>
    </sidenav-collapse>
  </li>

  <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
    <sidenav-collapse collapse-ref="ecommerceExamples" nav-text="Ecommerce"
      :class="getRoute() === 'ecommerce' ? 'active' : ''">
      <template #icon>
        <i class="material-icons-round opacity-10">shopping_basket</i>
      </template>
      <template #list>
        <ul class="nav pe-0">

          <sidenav-collapse-item refer="productsExample" mini-icon="P" text="Products">
            <template #nav-child-item>
              <sidenav-item :to="{ name: 'New Product' }" mini-icon="N" text="New Product" />
              <sidenav-item :to="{ name: 'Edit Product' }" mini-icon="E" text="Edit Product" />
              <sidenav-item :to="{ name: 'Product Page' }" mini-icon="P" text="Product page" />
            </template>
          </sidenav-collapse-item>

          <sidenav-collapse-item refer="ordersExample" mini-icon="O" text="Orders">
            <template #nav-child-item>
              <sidenav-item :to="{ name: 'Order List' }" mini-icon="O" text="Order List" />
              <sidenav-item :to="{ name: 'Order Details' }" mini-icon="O" text="Order Details" />
            </template>
          </sidenav-collapse-item>
        </ul>
      </template>
    </sidenav-collapse>
  </li>

  <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
    <sidenav-collapse collapse-ref="authExamples" nav-text="Authentication"
      :class="getRoute() === 'authentication' ? 'active' : ''">
      <template #icon>
        <i class="material-icons-round opacity-10">content_paste</i>
      </template>
      <template #list>
        <ul class="nav pe-0">
          <sidenav-collapse-item refer="signinExample" mini-icon="S" text="Sign In">
            <template #nav-child-item>
              <sidenav-item :to="{ name: 'Signin Basic' }" mini-icon="B" text="Basic" />
              <sidenav-item :to="{ name: 'Signin Cover' }" mini-icon="C" text="Cover" />
              <sidenav-item :to="{ name: 'Signin Illustration' }" mini-icon="I" text="Illustration" />
            </template>
          </sidenav-collapse-item>

          <sidenav-collapse-item refer="signupExample" mini-icon="S" text="Sign Up">
            <template #nav-child-item>
              <sidenav-item :to="{ name: 'Signup Cover' }" mini-icon="C" text="Cover" />
            </template>
          </sidenav-collapse-item>

          <sidenav-collapse-item refer="resetExample" mini-icon="R" text="Reset Password">
            <template #nav-child-item>
              <sidenav-item :to="{ name: 'Reset Cover' }" mini-icon="C" text="Cover" />
            </template>
          </sidenav-collapse-item>
        </ul>
      </template>
    </sidenav-collapse>
  </li>
  <li class="mt-3 nav-item">
    <hr class="horizontal light mt-0" />
    <h6 class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder text-white" :class="isRTL ? 'me-4' : 'ms-2'">
      DOCS
    </h6>
  </li>
  <sidenav-collapse nav-text="Intro" :collapse="false" url="#" :aria-controls="''" collapse-ref="/home/intro">
    <template #icon>
      <i class="material-icons-round opacity-10">storefront</i>
    </template>
  </sidenav-collapse>
  <sidenav-collapse nav-text="Pricing" :collapse="false" url="#" :aria-controls="''" collapse-ref="/home/pricing">
    <template #icon>
      <i class="material-icons-round opacity-10">attach_money</i>
    </template>
  </sidenav-collapse>
  <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
    <sidenav-collapse collapse-ref="basicExamples" nav-text="Basic" icon="dashboard"
      :class="getRoute() === 'basic' ? 'active' : ''">
      <template #icon>
        <i class="material-icons-round opacity-10">upcoming</i>
      </template>
      <template #list>
        <ul class="nav pe-0">
          <sidenav-collapse-item refer="gettingStartedExample" mini-icon="G" text="Getting Started">
            <template #nav-child-item>
              <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
                <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/quick-start/material-dashboard/"
                  target="_blank">
                  <span class="text-xs sidenav-mini-icon">Q</span>
                  <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Quick Start</span>
                </a>
              </li>
              <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
                <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/license/material-dashboard/"
                  target="_blank">
                  <span class="text-xs sidenav-mini-icon">L</span>
                  <span class="sidenav-normal me-3 ms-3 ps-1 text-white">License</span>
                </a>
              </li>
              <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
                <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/overview/material-dashboard/"
                  target="_blank">
                  <span class="text-xs sidenav-mini-icon">C</span>
                  <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Contents</span>
                </a>
              </li>
              <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
                <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/build-tools/material-dashboard/"
                  target="_blank">
                  <span class="text-xs sidenav-mini-icon">B</span>
                  <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Build Tools</span>
                </a>
              </li>
            </template>
          </sidenav-collapse-item>

          <sidenav-collapse-item refer="foundationExample" mini-icon="F" text="Foundation">
            <template #nav-child-item>
              <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
                <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/colors/material-dashboard/"
                  target="_blank">
                  <span class="text-xs sidenav-mini-icon">C</span>
                  <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Colors</span>
                </a>
              </li>
              <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
                <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/grid/material-dashboard/"
                  target="_blank">
                  <span class="text-xs sidenav-mini-icon">G</span>
                  <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Grid</span>
                </a>
              </li>
              <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
                <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/typography/material-dashboard/"
                  target="_blank">
                  <span class="text-xs sidenav-mini-icon">T</span>
                  <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Typography</span>
                </a>
              </li>
              <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
                <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/icons/material-dashboard/"
                  target="_blank">
                  <span class="text-xs sidenav-mini-icon">I</span>
                  <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Icons</span>
                </a>
              </li>
            </template>
          </sidenav-collapse-item>
        </ul>
      </template>
    </sidenav-collapse>
  </li>
  <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
    <sidenav-collapse collapse-ref="componentsExamples" nav-text="Components"
      :class="getRoute() === 'components' ? 'active' : ''">
      <template #icon>
        <i class="material-icons-round opacity-10">view_in_ar</i>
      </template>
      <template #list>
        <ul class="nav pe-0">
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/alerts/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">A</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Alerts</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/badge/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">B</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Badge</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/buttons/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">B</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Buttons</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/cards/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">C</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Card</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/carousel/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">C</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Carousel</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/collapse/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">C</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Collapse</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/dropdowns/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">D</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Dropdowns</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/forms/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">F</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Forms</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/modal/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">M</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Modal</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/navs/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">N</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Navs</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/navbar/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">N</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Navbar</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/pagination/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">P</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Pagination</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/popovers/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">P</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Popovers</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/progress/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">P</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Progress</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/spinners/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">S</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Spinners</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/tables/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">T</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Tables</span>
            </a>
          </li>
          <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
            <a class="nav-link" href="https://www.creative-tim.com/learning-lab/vue/tooltips/material-dashboard/"
              target="_blank">
              <span class="sidenav-mini-icon text-white">T</span>
              <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Tooltips</span>
            </a>
          </li>
        </ul>
      </template>
    </sidenav-collapse>
  </li>
  <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
    <sidenav-collapse nav-text="Changelog" :collapse="false" url="#" :aria-controls="''"
      collapse-ref="https://github.com/creativetimofficial/ct-vue-material-dashboard-2-pro/blob/main/CHANGELOG.md">
      <template #icon>
        <i class="material-icons-round opacity-10">receipt_long</i>
      </template>
    </sidenav-collapse>
  </li> -->
  </ul>
</div>
  <VTour :steps="steps" auto-start/>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavItemCustom from "./SidenavItemCustom.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import SidenavCollapseCustomUserId from "./SidenavCollapseCustomUserId.vue";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import { mapState } from "vuex";
import { ref } from 'vue';

import { Auth } from 'aws-amplify'
import { useRouter } from 'vue-router'

import ApiCall from "@/Interface/ApiCall";

export default {
  components: {
    SidenavItem,
    SidenavItemCustom,
    SidenavCollapseCustomUserId,
    SidenavCollapse,
    MaterialAvatar,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      userName: '',
      isOperator: false,
      isAdmin: false,
      isBusinessVerified: false,
      isLogin: false,
      userInfo: null,
      sideNavAvatar: '',
      isSendMessageExpand: false,
      isManageMessageExpand: false,
      // for tutorial
      steps: [
        {
          target: '.M2GTour-home',
          content: '메세지 투고의 튜토리얼을 시작합니다.',
          onNext: () => {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve) =>{
              await this.$router.push('/manage/sendnumber')
              this.isManageMessageExpand = true;
              resolve();
            });
          },
        },
        {
          target: '.M2GTour2',
          content: '메시지관리의 발신번호등록에서 발신번호를 등록 후 메시지 전송을 할 수 있습니다.',
          onNext: () => {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve) =>{
              await this.$router.push('/message/sendsms')
              this.isManageMessageExpand = false;
              this.isSendMessageExpand = true;
              resolve();
            });
          },
        },
        {
          target: '.M2GTour-sendSms',
          content: '메세지전송 메뉴에서 메세지를 전송할 수 있습니다.',
          onNext: () => {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve) =>{
              await this.$router.push('/message/SendHistory')
              resolve();
            });
          },
        },
        {
          target: '.M2GTour-sendHistory',
          content: '전송이력에서 전송된 내역을 확인할 수 있습니다.',
          onNext: () => {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve) =>{
              await this.$router.push('/message/sendtestsms')
              resolve();
            });
          },
        },
        {
          target: '.M2GTour-sendSms',
          content: '테스트 SMS 전송 메뉴에서 발신번호 등록전 자신의 번호로 테스트 문자를 전송해보세요.',
        },
      ],
    };
  },
  async mounted() {
    try {
      this.userInfo = await Auth.currentAuthenticatedUser()
      this.isLogin = true;
      const idToken = this.userInfo.signInUserSession.idToken
      // console.log("idToken payload :", idToken.payload)
      const tenantId = idToken.payload['cognito:groups'][0]
      const isAdmin = idToken.payload['custom:isAdmin']
      const isBusinessVerified = idToken.payload['custom:isBusinessVerified']
      // console.log(tenantId)
      this.userName = this.userInfo.attributes.name // Set the user ID as the dynamicMessage
      if (tenantId === '0'){
        this.isOperator = true;
      }
      if (isAdmin === '1') {
        this.isAdmin = true;
        if(this.userName){
          if(this.userName === 'PARKSEUNGHYUN'){
            this.isOperator = true;
          }
        }
      }
      if (isBusinessVerified === '1' || isBusinessVerified === '2' || typeof isBusinessVerified === undefined) {
        this.isBusinessVerified = true;
        // console.log('isBusinessVerified :', this.isBusinessVerified)
      }
    } catch (error) {
      console.error('Error fetching user information:', error)
      // const currentURL = window.location.href;
      const currentPath = window.location.pathname;
      
      // alert(currentPath);
      if (!currentPath.startsWith('/home') && !currentPath.startsWith('/authentication/signup') && currentPath !='/') {
        this.$router.push({ name: 'Signin Basic' });
      }
    }
    await this.getAvatarInfo()
  },
  computed: {
    ...mapState(["isRTL"])
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    async getAvatarInfo() {
      const payload = {
        user_id : this.userInfo.username,
      };
      let dynamoUserInfo = null;
      await ApiCall.call('user', 'POST', payload).then((res) =>{
        dynamoUserInfo = res.data[0];
      })
      const avatar = dynamoUserInfo['avatar'];
      this.sideNavAvatar = avatar;
      // console.log('sideNavAvatar : ', this.sideNavAvatar)
    }
  },
};
</script>
