<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3"
    :class="`${
      isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'
    } ${sidebarType}`"
    @mouseover="handleMouseOver"
    @mouseout="handleMouseOut"
  >
    <div class="sidenav-header">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/dashboard">
        <img
          v-if="isPinned"
          :src="
            sidebarType === 'bg-white' ||
            (sidebarType === 'bg-transparent' && !isDarkMode)
              ? logoDarkMin
              : logoMin
          "
          class="navbar-brand-img h-200"
          alt="main_logo"
          height="24"
        />
        <img
          v-else
          :src="
            sidebarType === 'bg-white' ||
            (sidebarType === 'bg-transparent' && !isDarkMode)
              ? logoDark
              : logo
          "
          class="navbar-brand-img h-200"
          alt="minimized_logo"
        />
        <span class="ms-1 font-weight-bold text-white"></span>
      </router-link>
    </div>
    <hr class="horizontal light mt-0 mb-2" />
    <sidenav-list />
  </aside>
</template>

<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-m2g-long.png";
import logoDark from "@/assets/img/logo-m2g-long-dark.png";
import logoMin from "@/assets/img/m2g-icon-only.png";
import logoTemp from "@/assets/img/m2g-icon-only.png";
import logoDarkMin from "@/assets/img/m2g-icon-only.png";
import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoDark,
      logoMin,
      logoDarkMin,
      logoTemp: '',
    };
  },
  computed: {
    ...mapState(["isRTL", "sidebarType", "isDarkMode", "isPinned"]),
  },
  methods: {
    handleMouseOver() {
      this.isMouseover = true;
      if (this.isPinned) {
        this.logoTemp = this.logoMin;
        this.logoMin = this.logo;
        document.querySelector('.navbar-brand-img').classList.add('logo-large');
      }
    },
    handleMouseOut() {
      this.isMouseover = false;
      if (this.isPinned) {
        this.logoMin = this.logoTemp;
        document.querySelector('.navbar-brand-img').classList.remove('logo-large');
      }
    },
  },
};
</script>

<style>
.logo-large {
  height: 40px;
}
</style>
