<template>
  <ul class="pagination" :class="getClasses(color, size)">
    <li class="page-item" :class="{ disabled: currentPage === 1 }">
      <a class="page-link" href="#" aria-label="Previous" @click.prevent="$emit('page-changed', currentPage - 1)">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: currentPage === page }">
      <a class="page-link" href="#" @click.prevent="$emit('page-changed', page)">{{ page }}</a>
    </li>
    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
      <a class="page-link" href="#" aria-label="Next" @click.prevent="$emit('page-changed', currentPage + 1)">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "MaterialPagination",
  props: {
    color: {
      type: String,
      default: "success",
    },
    size: {
      type: String,
      default: "md",
    },
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
      default: 1,
    },
    showPrevNext: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getClasses: (color, size) => {
      let colorValue, sizeValue;

      colorValue = color ? `pagination-${color}` : null;
      sizeValue = size ? `pagination-${size}` : null;

      return `${colorValue} ${sizeValue}`;
    },
  },
};
</script>
