<template>
  <div class="py-4 container-fluid">
    <div class="mt-0 row">
      <div id="send-history" class="card mt-4">
        <!-- Card header -->
        <div class="card-header">
          <h6 class="mb-0">발신번호 승인</h6>
        </div>
        <div class="card-body pt-0">
          <div class="row align-items-center mt-2">
            <div class="col-sm-3" style="max-width: 180px;">
              <material-input id="input_tenant_id" v-model="input_tenant_id" type="text" size="sm" label="Tenant_Id"
                label-size="sm" variant="outline">
              </material-input>
            </div>
            <div class="col-sm-6" style="max-width: 180px;">
              <material-input id="from_phone_number" v-model="from_phone_number" type="text" size="sm" label="발신번호"
                label-size="sm" variant="outline">
              </material-input>
            </div>
            <div class="col-sm-9" style="max-width: 180px;">
              <select id="from_phone_number_status" v-model="from_phone_number_status" class="form-control">
              </select>
            </div>
            <!-- <div class="col-6" style="max-width: 180px;">
              <material-input 
                  id="from_phone_number_alias"
                  type="text" 
                  size="sm" 
                  label="발신번호 명"
                  labelSize="sm"
                  v-model="from_phone_number_alias"                  
                  variant="outline" 
              >
              </material-input>
            </div> -->
          </div>
          <div class="row mt-3">
            <div class="col-12 text-end">
              <material-button color="primary" size="sm" variant="default" style="width: 120px" @click="changeData">
                조회
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="mb-4 card">
        <div class="pb-0 card-header">
          <h6>조회 결과</h6>
        </div>
        <EasyDataTable table-class-name="customize-table" empty-message="조회된 내역이 없습니다."
          rows-per-page-message="페이지당 표시 행 수" rows-of-page-separator-message="중" :headers="tableColumns"
          :items="fromPhoneNumberList" :loading="isLoading" @click-row="onRowClicked">
          <template #loading>
            <img src="@/assets/img/Spinner-1s-200px.gif" style="width: 100px; height: 100px;" />
          </template>
          <template #item-primary="{ primary, image, image_color, secondary, secondary_color }">
            <div class="row">
              <div class="col-auto flex-wrap">
                <material-button v-if="image" :color="image_color" variant="outline"
                  class="btn-icon-only btn-rounded btn-sm d-flex align-items-center justify-content-center">
                  <i :class="image" aria-hidden="true"></i>
                </material-button>
              </div>
              <div class="col-auto flex-wrap">
                <div class="d-flex flex-column justify-content-center">
                  <span style="display: inline-block; max-width: 100%;" :title="primary">{{ primary }}</span>
                  <span style="display: inline-block; max-width: 100%;" :title="secondary">
                    <span :class="secondary_color">{{ secondary }}</span>
                  </span>
                </div>
              </div>
            </div>
          </template>
          <template #item-sent_content="{ sent_content }">
            <div class="row" style="max-width: 290px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"
              :title="sent_content">
              {{ sent_content }}
            </div>
          </template>
          <template #item-info="{ info }">
            <div class="justify-content-center">
              <material-button v-if="info" size="xsm" color="secondary" variant="outline"
                class="btn-icon-only btn-rounded btn-sm d-flex align-items-center justify-content-center"
                data-bs-toggle="tooltip" data-bs-placement="bottom" :title="info">
                <i class="fas fa-info" aria-hidden="true"></i>
              </material-button>
            </div>
          </template>
        </EasyDataTable>
        <!-- <div class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center" v-if="isLoading">
          <div class="spinner-container">
            <i class="fas fa-spinner fa-spin spinner-icon text-info"></i>
          </div>
        </div>
        <material-table
          title="조회 결과"
          :headers=tableColumns
          :lists=paginatedFromPhoneNumberList
          @row-clicked=onRowClicked
        />
        <material-pagination
        :total-items="fromPhoneNumberList.length"
        :items-per-page="itemsPerPage"
        v-model="currentPage"
        :total-pages="Math.ceil(fromPhoneNumberList.length / itemsPerPage)"
        :current-page="currentPage"
        class="mt-4"
        @page-changed="onPageChange"
        /> -->
      </div>
    </div>
  </div>
  <div id="reviewDocument" class="modal fade" tabindex="-1" aria-labelledby="reviewDocumentLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <h5 class="modal-title" id="createGroupModalLabel">{{ modalTitle }}</h5> -->
          <h5 id="reviewDocumentLabel" class="modal-title">서류 인증</h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col">
              <label class="form-control ms-0">등록할 발신번호*</label>
            </div>
            <div class="col">
              <material-input id="address_name" v-model="request_from_phone_number" size="md" style="margin-left: -3px;"
                readonly></material-input>
            </div>
          </div>
          <div class="row">
            <div v-for="(file, index) in request_file_list" :key="index">
              <div class="row align-items-center">
                <div class="col">
                  <p>{{ file.file_name }} <br>({{ file.file_type_name }})</p>
                </div>
                <div v-if="userEmail === 'shpark82@sk.com'" class="col-auto">
                  <material-button class="text-end me-3"
                    @click="downloadFile(file.file_reference_id)">다운로드</material-button>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal body content goes here -->
        </div>
        <material-textarea id="reject_reason" v-model=comment_text name="reject_reason" class="text-xs"
          style="height: 60px; resize: none;" placeholder="반려 사유입력">
        </material-textarea>
        <div class="modal-footer">
          <button :disabled="false" type="button" class="btn btn-success" data-bs-dismiss="modal"
            @click="approveRequest()">승인</button>
          <button :disabled="false" type="button" class="btn btn-success" data-bs-dismiss="modal"
            @click="changeBusinessVerified('1')">인증(1)</button>
          <button :disabled="false" type="button" class="btn btn-success" data-bs-dismiss="modal"
            @click="changeBusinessVerified('2')">인증(2)</button>
          <button :disabled="false" type="button" class="btn btn-primary" data-bs-dismiss="modal"
            @click="rejectRequest()">반려</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
</div>

</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import ApiCall from "@/Interface/ApiCall";
import MaterialInput from "@/components/MaterialInput.vue";

import MaterialTable from "@/components/MaterialTable.vue";
import MaterialPagination from "@/components/MaterialPagination.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import * as Choices from "choices.js";
import { Auth } from 'aws-amplify'

export default {
  name: "DataTables",
  components: {
    MaterialButton,
    // MaterialTable,
    // MaterialPagination,
    MaterialInput,
    MaterialTextarea,
  },
  setup() {
  },
  data() {
    return {
      formData: {
        from_phone_number_status: ['request', 'reject', 'active'],
      },
      // tableColumns: ['Tenant', '부모 Tenant', 'Tenant명', '발송번호', '번호유형', '인증방법', '상태', '요청일자', '승인일자', '사유', ''],
      tableColumns: [
        { text: "Tenant", value: "tenant_id", sortable: true },
        { text: "부모 Tenant", value: "parent_tenant", sortable: true },
        { text: "Tenant명", value: "tenant_name", sortable: true },
        { text: "발송번호", value: "from_phone_number", sortable: true },
        { text: "번호유형", value: "type_korean", sortable: true },
        { text: "인증방법", value: "auth_type_korean", sortable: true },
        { text: "상태", value: "phone_status", sortable: true },
        { text: "요청일자", value: "create_timestamp", sortable: true },
        { text: "승인일자", value: "authorize_timestamp", sortable: true },
        { text: "사유", value: "comment_text", sortable: true },
      ],
      tableData: [],

      // currentTimestamp: Date.now(),
      fromPhoneNumberList: [], // Add this line
      from_phone_number: '',
      input_tenant_id: '',
      selectedRow: null,
      selectedList: null,
      itemsPerPage: 20,
      currentPage: 1, // Add this line

      isLoading: false,

      request_pk: '',
      request_sk: '',
      from_phone_number_type: '',
      from_phone_number_status: 'request',
      request_from_phone_number: '',
      request_file_list: [],
      comment_text: '',
      authorize_type: '',
      tenant_id: '',
      create_id: '',
      selectInstance: null,
      tenantOptions: [],
      tenantList: [],
      tenantListOrigin: [],

      userEmail: '',
    }
  },
  computed: {
    paginatedFromPhoneNumberList() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.fromPhoneNumberList.slice(startIndex, endIndex);
    },
  },
  watch: {
    from_phone_number_status(newValue) {
      console.log('from_phone_number_status changed to:', newValue);
    }
  },
  async mounted() {
    this.initChoicesList();
    this.getAllTenantList();
    try {
      this.userInfo = await Auth.currentAuthenticatedUser()
      this.isLogin = true;
      const idToken = this.userInfo.signInUserSession.idToken
      // console.log("idToken payload :", idToken.payload)
      const tenantId = idToken.payload['cognito:groups'][0]
      const isAdmin = idToken.payload['custom:isAdmin']
      const isBusinessVerified = idToken.payload['custom:isBusinessVerified']
      // console.log(tenantId)
      this.userEmail = this.userInfo.attributes.email
    } catch (error) {
      console.error('Error fetching user information:', error)
      // const currentURL = window.location.href;
      const currentPath = window.location.pathname;
      
      // alert(currentPath);
      if (!currentPath.startsWith('/home') && !currentPath.startsWith('/authentication/signup') && currentPath !='/') {
        this.$router.push({ name: 'Signin Basic' });
      }
    }
  },
  methods: {
    async getAllTenantList() {
      await ApiCall.call('user/auth/tenant/all', 'POST').then(async (res) => {
        this.tenantListOrigin = res.data.data
        this.tenantOptions = []
        this.tenantList = []

        const optionData = res.data.data.map(item => ({
          label: item['tenant_id'] + ' | ' + item['tenant_name'],
          value: item['tenant_id']
        }));
        this.tenantList = res.data.data.map(item => (
          item['tenant_id']
        ));

        // 전체 선택
        const all = [{ value: "all", label: "전체" }]
        this.tenantOptions = this.tenantOptions.concat(all)
        this.tenantOptions = this.tenantOptions.concat(optionData);
        // console.log('tenantOptions :', this.tenantOptions)

        // console.log('tenant list options:' + this.tenantOptions);
      })
    },
    initChoicesList() {
      if (this.selectInstance) {
        this.selectInstance.destroy();
      }
      // Convert your array of strings to the expected format
      const choicesArray = this.formData.from_phone_number_status.map(item => {
        return { value: item, label: item };
      });
      this.selectInstance = new Choices('#from_phone_number_status', {
        shouldSort: false,
        choices: choicesArray,
      });
      this.selectInstance.passedElement.element.addEventListener('change', (event) => {
        this.from_phone_number_status = event.target.value;
      });
      console.log(this.selectInstance);
    },
    async approveRequest() {
      const payload = {
        from_phone_number: this.request_from_phone_number,
        authorize_type: this.authorize_type,
        from_phone_number_type: this.from_phone_number_type,
        from_phone_number_alias: this.from_phone_number_alias,
        from_phone_number_status: 'active',
        file_list: this.request_file_list,
        comment_text: this.comment_text,
        tenant_id: this.tenant_id,
      };
      try {
        console.log(payload);
        const response = await ApiCall.call('phone/from/register', 'POST', payload);
        // const response = await ApiCall.call('안보내기', 'POST', payload);
        console.log(response.data);
        alert("발신번호가 승인되었습니다.");

        // 발신번호 승인 -> cognito에서 해당 유저의 isBusinessVerify:1 로 설정
        // const verify_payload = {
        //   create_id: this.create_id,
        // }
        // const verify_response = await ApiCall.call('user/businessverify', 'POST', verify_payload);
        // console.log('verify response :', verify_response.data);

        this.changeData();
      } catch (error) {
        console.error(error);
        alert("발신번호 승인 실패");
      }
    },
    async rejectRequest() {
      const payload = {
        from_phone_number: this.request_from_phone_number,
        authorize_type: this.authorize_type,
        from_phone_number_type: this.from_phone_number_type,
        from_phone_number_alias: this.from_phone_number_alias,
        from_phone_number_status: 'reject',
        file_list: this.request_file_list,
        comment_text: this.comment_text,
        tenant_id: this.tenant_id,
      };
      try {
        console.log(payload);
        const response = await ApiCall.call('phone/from/register', 'POST', payload);
        // const response = await ApiCall.call('안보내기', 'POST', payload);
        console.log(response.data);
        alert("발신번호가 반려되었습니다.");

        this.changeData();
      } catch (error) {
        console.error(error);
        alert("발신번호 반려 실패");
      }
    },
    async changeBusinessVerified(target_verify) {
      // 발신번호 승인 -> cognito에서 해당 유저의 isBusinessVerify:1 로 설정
      const verify_payload = {
        create_id: this.create_id,
        target_tenant_id: this.tenant_id,
        business_verify: target_verify,
      }
      const verify_response = await ApiCall.call('user/businessverify', 'POST', verify_payload);

      if (verify_response.status === 200) {
        console.log("인증처리가 완료 되었습니다.")
      } else {
        console.log("인증오류 " + verify_response.data.message)
      }

    },
    downloadFile(object) {
      console.log('download file object: ', object);

      // file object key, reference key 통일 필요. 현재는 object key 로 apiCall 에서 사용.
      const pattern = /s3:\/\/[^/]+\/([^/]+\/[^/]+\/[^/]+)/;
      const match = object.match(pattern);
      let objectKey = ''
      objectKey = match[1];

      console.log('download file objectKey: ', objectKey);

      ApiCall.file('download', 'POST', null, null, null, null, objectKey).then(response => {
        console.log(response)
        const data = response.data;
        const fileName = response.filename;

        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link);
        console.log(`File saved: ${fileName}`);
      }).catch(error => {
        console.error('Download failed:', error)
      })
    },
    reviewDocument() {
      const bootstrap = this.$store.state.bootstrap;

      // 모달 열기
      const modal = new bootstrap.Modal(
        document.getElementById("reviewDocument")
      );
      modal.show();
    },
    onPageChange(pageNumber) {
      this.currentPage = pageNumber;
    },
    onRowClicked(item) {
      this.selectedList = item;
      // this.selectedRow = index;
      console.log(JSON.stringify(this.selectedList.pk));
      console.log(JSON.stringify(this.selectedList.sk));
      console.log(JSON.stringify(this.selectedList.file_list[0].file_reference_id));
      this.request_pk = this.selectedList.pk;
      this.request_sk = this.selectedList.sk;
      this.from_phone_number_type = this.selectedList.from_phone_number_type;
      this.request_from_phone_number = this.selectedList.from_phone_number;
      this.from_phone_number_alias = this.selectedList.from_phone_number_alias;
      this.request_file_list = this.selectedList.file_list;
      this.from_phone_number_status = this.selectedList.from_phone_number_status;
      this.authorize_type = this.selectedList.authorize_type;
      this.tenant_id = this.selectedList.tenant_id;
      this.create_id = this.selectedList.create_id;
      console.log(this.request_file_list);
      console.log('created id :', this.create_id)
      this.reviewDocument();
    },
    getFormattedDate(daysBefore = 0) {
      const currentTimestamp = Date.now();
      const date = new Date(currentTimestamp - daysBefore * 24 * 60 * 60 * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    changeDateToString(dateString) {
      const dateObj = new Date(dateString);
      const year = dateObj.getFullYear();
      const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      const day = ("0" + dateObj.getDate()).slice(-2);
      const hour = ("0" + dateObj.getHours()).slice(-2);
      const minute = ("0" + dateObj.getMinutes()).slice(-2);
      const second = ("0" + dateObj.getSeconds()).slice(-2);
      return `${year}${month}${day}${hour}${minute}${second}`;
    },
    changeData() {
      this.isLoading = true;
      this.fromPhoneNumberList = []

      this.currentPage = 1;

      const payload = {
        // tenant_id : '41',
        from_phone_number: this.from_phone_number,
        from_phone_number_status: this.from_phone_number_status,
        operator_yn: 'Y',
      }
      if (this.input_tenant_id !== '') {
        payload.tenant_id = this.input_tenant_id;
      }
      console.log(payload)
      ApiCall.call('phone/from/list', 'POST', payload).then(res => {
        // console.log(res.data);
        if (!res.data || !res.data.length) {
          // Handle case where no items were found
          console.log('No items found');
          this.isLoading = false;
          return;
        }
        const fromPhoneNumberList = res.data.map(item => {
          let iconClass = '';
          let primary_image = '';
          let primary_image_color = '';
          let secondary = '';
          let secondary_color = '';
          let type_korean = '';
          let auth_type_korean = '';
          let phone_status = '';
          //success(green), info(blue), warning(orange), danger(red), primary(red), secondary(grey), dark(black)
          if (item.from_phone_number_type === 'company') {
            type_korean = '법인번호';
          } else if (item.from_phone_number_type === 'individual') {
            type_korean = '개인번호';
          }
          if (item.authorize_type === 'document') {
            auth_type_korean = '서류인증';
          } else if (item.authorize_type === 'authentication') {
            auth_type_korean = '본인인증';
          }
          if (item.from_phone_number_status === 'active') {
            phone_status = '활성화';
          } else if (item.from_phone_number_status === 'reject') {
            phone_status = '반려';
          } else if (item.from_phone_number_status === 'inactive') {
            phone_status = '비활성화';
          } else if (item.from_phone_number_status === 'deleted') {
            phone_status = '삭제';
          } else if (item.from_phone_number_status === 'request') {
            phone_status = '요청중';
          } else if (item.from_phone_number_status === 'review') {
            phone_status = '검토중';
          }

          // let tenant_id = item.pk.slice(0, item.pk.indexOf('#'));
          
          // let parentId = null; // 찾고자 하는 parent_id를 저장할 변수 초기화

          // console.log("찾을 tenant_id: ", tenant_id);
          // console.log("this.tenantListOrigin= ",this.tenantListOrigin);

          // for (let i = 0; i < this.tenantListOrigin.length; i++) {
          //     if (this.tenantListOrigin[i].tenant_id === tenant_id) { // 특정 tenant_id 값과 일치하는 경우
          //         // parent_id가 null이 아니며 parent_tenant 객체가 존재하는지 확인
          //         if (this.tenantListOrigin[i].parent_id !== null && this.tenantListOrigin[i].parent_tenant) {
          //             parentId = this.tenantListOrigin[i].parent_tenant.tenant_id;
          //              // 일치하는 첫 번째 객체를 찾으면 반복문 종료
          //         }
          //         break;
          //     }
          // }

          // if (parentId !== null) {
          //     console.log("찾은 parent_id: ", parentId);
          // } else {
          //     console.log("parent_id를 찾을 수 없습니다.");
          // }
          
          // let parent_tenant = this.tenantListOrigin['parent_tenant'] ? this.tenantListOrigin['tenant_id'] : '없음';
         

          return {
            primary: item.pk.slice(0, item.pk.indexOf('#')),
           
            parent_tenant: item.parent_id ? item.parent_id : '없음',
            tenant_name: item.tenant_name,
            from_phone_number: item.from_phone_number,
            type_korean: type_korean,
            auth_type_korean: auth_type_korean,
            phone_status: phone_status,
            create_timestamp: item.create_timestamp,
            authorize_timestamp: item.authorize_timestamp,
            comment_text: item.comment_text,
            secondary: '',
            secondary_color: secondary_color,
            info: '',
            image: primary_image,
            image_color: primary_image_color,
            icon: '',
            pk: item.pk,
            sk: item.sk,
            file_list: item.file_list,
            // parent_tenant_id: parent_tenant,
            // tenant_id: item.pk.slice(0, item.pk.indexOf('#')),
            tenant_id: item.tenant_id,
            // from_phone_number: item.from_phone_number,
            from_phone_number_alias: item.from_phone_number_alias,
            from_phone_number_type: item.from_phone_number_type,
            from_phone_number_status: item.from_phone_number_status,
            authorize_type: item.authorize_type,
            create_id: item.create_id,
          };

          function formatDate(dateString) {
            const year = dateString.substring(0, 4);
            const month = dateString.substring(4, 6);
            const day = dateString.substring(6, 8);
            const hour = dateString.substring(8, 10);
            const minute = dateString.substring(10, 12);
            const second = dateString.substring(12, 14);

            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
          }
        }).filter(item => {
          return item.sk.slice(0, item.sk.indexOf('#')) == 0;
        });

        console.log('fromPhoneNumberList :', fromPhoneNumberList)

        // console.log(res.data);
        // console.log(sendHistoryList)
        this.tableData = res.data.data;
        this.fromPhoneNumberList = fromPhoneNumberList;

        // Update current page if it exceeds the number of pages available
        const maxPage = Math.ceil(this.fromPhoneNumberList.length / this.itemsPerPage);
        if (this.currentPage > maxPage) {
          this.currentPage = maxPage;
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
<style>
.spinner-icon {
  font-size: 40px;
}
</style>
