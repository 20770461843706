<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div v-if="campaignInfo.send_type === 'mms'">
        <h6 class="font-weight-bolder mb-4">🎨 이미지</h6>
        <div class="row mt-4">
          <p class="text-sm font-weight-bold">가장 마음에 드는 광고 이미지를 선택해주세요.</p>
          <custom-select-image
            :options="campaignInfo.image_info"
            @selected="handleSelectImage"
          ></custom-select-image>
        </div>
      </div>
      <div class="row mt-3">
        <h6 class="font-weight-bolder mt-5 mb-4">💬 텍스트</h6>
        <custom-select-contents
          :options="campaignInfo.contents"
          @selected="handleSelectContents"
        ></custom-select-contents>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="$parent.prevStep"
        >뒤로</material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="handleCompleteSelection"
          >선택완료</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";

import CustomSelectContents from "@/components/CustomSelectContents.vue";
import CustomSelectImage from "@/components/CustomSelectImage.vue";

export default {
  name: "MarketingSelect",
  components: {
    CustomSelectImage,
    MaterialButton,
    CustomSelectContents,
  },
  props: {
    campaignInfo: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:campaignInfo'],
  data() {
    return {
      texts: [],
      imageFiles: [],
      isImageSelect: false,
      isTextSelect: false,
    }
  },
  methods: {
    handleSelectContents(option) {
      console.log("TargetSelect :" + JSON.stringify(option))
      this.$emit('update:campaignInfo', { ...this.campaignInfo, sent_content: option.content, subject: option.title })
      this.isTextSelect = true;
    },
    async handleSelectImage(option) {
      console.log("TargetSelect :" + JSON.stringify(option))
      // url to file

      this.$emit('update:campaignInfo', {
        ...this.campaignInfo,
        final_image_url: option.url,
        final_image_file: option.file,
      })
      this.isImageSelect = true;
    },
    handleCompleteSelection() {
      if (this.campaignInfo.send_type === 'mms') {
        if (this.isTextSelect && this.isImageSelect) {
          this.$emit('update:campaignInfo', {
            ...this.campaignInfo,
            confirm_step: 4,
          })
          this.$parent.nextStep();
        } else {
          alert("이미지와 텍스트 모두 선택해주세요.")
        }
      } else {
        if (this.isTextSelect) {
          this.$emit('update:campaignInfo', {
            ...this.campaignInfo,
            confirm_step: 4,
          })
          this.$parent.nextStep();
        } else {
          alert("텍스트를 선택해주세요.")
        }
      }
    },
  },
};
</script>
