<!--
=========================================================
* Vue Material Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-material-dashboard-2-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
  <sidenav
    v-if="showSidenav"
    :user-info="userInfo"
    :custom_class="color"
    :class="[isRTL ? 'fixed-end' : 'fixed-start']"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
  >
    <!-- nav -->
    <navbar
      v-if="showNavbar"
      :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''"
      :min-nav="navbarMinimize"
    />
    <router-view />
    <app-footer v-show="showFooter" />
    <configurator v-if="showSidenav"
      :toggle="toggleConfigurator"
      :class="[showConfigurator ? 'show' : '', hideConfiguratorButton ? 'd-none' : '']"
    />
    <chat v-if="showSidenav" :toggle="toggleChat" :class="[showChat ? 'show' : '', hideChatButton ? 'd-none' : '']"/>
  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Chat from "@/examples/Chat.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState } from "vuex";
import { Auth } from 'aws-amplify'
import ApiCall from "@/Interface/ApiCall";

export default {
  name: "App",
  components: {
    Sidenav,
    Chat,
    Configurator,
    Navbar,
    AppFooter
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showChat",
      "hideChatButton",
      "showConfigurator",
      "hideConfiguratorButton"
    ])
  },
  beforeMount() {
    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  },
  async data() {
    if (ApiCall.vdiDomainCheck()) {
      Auth.configure({
        endpoint: process.env.VUE_APP_COGNITO_URL_PROXY
      });
    }
    const user = await Auth.currentAuthenticatedUser()

    let isLogin;
    if (user) {
      isLogin = true;
    } else {
      isLogin = false;
    }

    const idToken = user.signInUserSession.idToken
    const tenantId = idToken.payload['cognito:groups'][0]
    // console.log(tenantId)
    let isOperator;
    if (tenantId === '0'){
      isOperator = true;
    } else {
      isOperator = false;
    }
    this.userName = user.attributes.name // Set the user ID as the dynamicMessage

    const user_id = user.username;
    const user_name = user.attributes.name;
    const email = user.attributes.email;
    const phone_number = user.attributes.phone_number;
    const tenant_name = user.attributes['custom:tenant_name'];
    const is_admin = user.attributes['custom:isAdmin'];
    // additional user information loading from dynamoDB
    const payload = {
      user_id : user_id,
    };
    let dynamoUserInfo = null;
    await ApiCall.call('user', 'POST', payload).then((res) =>{
      dynamoUserInfo = res.data[0];
    })
    const avatar = dynamoUserInfo['avatar'];

    // console.log('dynamoUserInfo : ', dynamoUserInfo)



    return {
      userInfo: {
        isOperator: isOperator,
        isLogin: isLogin,
        user_id : user_id,
        user_name : user_name,
        email : email,
        phone_number : phone_number,
        tenant_id : tenantId,
        tenant_name : tenant_name,
        is_admin : is_admin,
        avatar : avatar,
      }
    }
  },
  methods: {
    ...mapMutations(["toggleChat", "navbarMinimize", "toggleConfigurator"])
  }
};
</script>
<style>
.dataTable-pagination-list .active a {
  background-image: linear-gradient(
    195deg,
    #66bb6a 0%,
    #43a047 100%
  ) !important;
}
</style>
