<template>
  <div class="py-4 container-fluid">
    <div class="mt-0 row">
      <div id="send-history" class="card mt-4">
        <!-- Card header -->
        <div class="card-header">
          <h6 class="mb-0">템플릿 승인</h6>
        </div>
        <div class="card-body pt-0">
          <div class="row align-items-center mt-2">
            <div class="col-6" style="max-width: 180px;">
              <material-input 
                  id="from_phone_number"
                  v-model="from_phone_number" 
                  type="text" 
                  size="sm"
                  label="발신번호"
                  label-size="sm"                  
                  variant="outline" 
              >
              </material-input>
            </div>
            <!-- <div class="col-6" style="max-width: 180px;">
              <material-input 
                  id="from_phone_number_alias"
                  type="text" 
                  size="sm" 
                  label="발신번호 명"
                  labelSize="sm"
                  v-model="from_phone_number_alias"                  
                  variant="outline" 
              >
              </material-input>
            </div> -->
          </div>
          <div class="row mt-3">
            <div class="col-12 text-end">
              <material-button color="primary" size="sm" variant="default" style="width: 120px" @click="changeData">
                조회
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="mb-4 card">
        <div class="pb-0 card-header">
          <h6>조회 결과</h6>
        </div>
        <div v-if="isLoading" class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
          <div class="spinner-container">
            <i class="fas fa-spinner fa-spin spinner-icon text-info"></i>
          </div>
        </div>
        <material-table
          title="조회 결과"
          :headers=tableColumns
          :lists=paginatedFromPhoneNumberList
          @row-clicked=onRowClicked
        />
        <material-pagination
        v-model="currentPage"
        :total-items="fromPhoneNumberList.length"
        :items-per-page="itemsPerPage"
        :total-pages="Math.ceil(fromPhoneNumberList.length / itemsPerPage)"
        :current-page="currentPage"
        class="mt-4"
        @page-changed="onPageChange"
        />
      </div>
    </div>
  </div>
  <div id="reviewDocument" class="modal fade" tabindex="-1" aria-labelledby="reviewDocumentLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <!-- <h5 class="modal-title" id="createGroupModalLabel">{{ modalTitle }}</h5> -->
                            <h5 id="reviewDocumentLabel" class="modal-title">서류 인증</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col">
                                    <label class="form-control ms-0">등록할 발신번호*</label>
                                </div>
                                <div class="col">
                                  <material-input id="address_name" v-model="request_from_phone_number" size="md" style="margin-left: -3px;" readonly></material-input>
                                </div>
                            </div>
                            <div class="row">
                              <div v-for="(file, index) in request_file_list" :key="index">
                                <div class="row align-items-center">
                                  <div class="col">
                                    <p>{{ file.file_name }} <br>({{ file.file_type_name }})</p>
                                  </div>
                                  <div class="col-auto">
                                    <material-button class="text-end me-3" @click="downloadFile(file.file_reference_id)">다운로드</material-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Modal body content goes here -->
                        </div>
                        <material-textarea 
                          id="reject_reason" 
                          v-model=reject_reason 
                          name="reject_reason" 
                          class="text-xs" 
                          style="height: 60px; resize: none;" 
                          placeholder="반려 사유입력">
                        </material-textarea>
                        <div class="modal-footer">
                            <button :disabled="false" type="button" class="btn btn-success" data-bs-dismiss="modal"
                                @click="approveRequest()">승인</button>
                            <button :disabled="false" type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                @click="rejectRequest()">반려</button>    
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
</template>
<script>
import MaterialButton from "@/components/MaterialButton.vue";
import ApiCall from "@/Interface/ApiCall";
import MaterialInput from "@/components/MaterialInput.vue";

import MaterialTable from "@/components/MaterialTable.vue";
import MaterialPagination from "@/components/MaterialPagination.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";


export default {
  name: "DataTables",
  components: {
    MaterialButton,
    MaterialTable,
    MaterialPagination,
    MaterialInput,
    MaterialTextarea,
  },
  setup() {
  },
  data() {
    return {
      tableColumns: ['발송번호','번호유형', '인증방법', '상태', '등록일자',''],
      tableData: [],

      // currentTimestamp: Date.now(),
      fromPhoneNumberList: [], // Add this line
      from_phone_number: '',
      selectedRow: null,
      selectedList: null,
      itemsPerPage: 20,
      currentPage: 1, // Add this line

      isLoading: false,

      request_pk: '',
      request_sk: '',
      request_from_phone_number: '',
      request_file_list: [],
      reject_reason: '',
    }
  },
  computed: {
      paginatedFromPhoneNumberList() {
          const startIndex = (this.currentPage - 1) * this.itemsPerPage;
          const endIndex = startIndex + this.itemsPerPage;
          return this.fromPhoneNumberList.slice(startIndex, endIndex);
      },
  },
  methods: {
    approveRequest(){
      alert("발신번호가 승인되었습니다.");
    },
    rejectRequest(){
      alert("발신번호가 반려되었습니다.");
    },
    downloadFile(object) {
          console.log('download file : ', object);
          let tenantId = object.slice(0, object.indexOf('/'));
          let objectKeyWithoutTenantId = object.slice(object.indexOf('/') + 1);
          let fileName = objectKeyWithoutTenantId.slice(objectKeyWithoutTenantId.indexOf('/') + 1);
          console.log(fileName); // Output: customer_group/명비즈팜4#명비즈팜4.xlsx

          console.log(tenantId); // Output: 0
          console.log(objectKeyWithoutTenantId); // Output: customer_group/명비즈팜4#명비즈팜4.xlsx

          const param = {
            // tenantId
            tenantId: tenantId,
            objectKeyWithoutTenantId: objectKeyWithoutTenantId,
          }
          const header = {
            'accept': '*/*',
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }

          ApiCall.call('download', 'POST', null, param, null, header, true).then(response => {
            console.log(response.data)

            const data = response.data;

            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()

            console.log(`File saved: ${fileName}`);
          }).catch(error => {
            console.error('Download failed:', error)
          })
        },
    reviewDocument() {
        const bootstrap = this.$store.state.bootstrap;

        // 모달 열기
        const modal = new bootstrap.Modal(
            document.getElementById("reviewDocument")
        );
        modal.show();
    },
    onPageChange(pageNumber) {
      this.currentPage = pageNumber;
    },
    onRowClicked({ list, index }) {
      this.selectedList = list;
      this.selectedRow = index;
      console.log(JSON.stringify(this.selectedList.pk));
      console.log(JSON.stringify(this.selectedList.sk));
      console.log(JSON.stringify(this.selectedList.file_list[0].file_reference_id));
      this.request_pk = this.selectedList.pk;
      this.request_sk = this.selectedList.sk;
      this.request_from_phone_number = this.selectedList.primary;
      this.request_file_list = this.selectedList.file_list;
      this.reviewDocument();
    },
    getFormattedDate(daysBefore = 0) {
      const currentTimestamp = Date.now();
      const date = new Date(currentTimestamp - daysBefore * 24 * 60 * 60 * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    changeDateToString(dateString) {
      const dateObj = new Date(dateString);
      const year = dateObj.getFullYear();
      const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      const day = ("0" + dateObj.getDate()).slice(-2);
      const hour = ("0" + dateObj.getHours()).slice(-2);
      const minute = ("0" + dateObj.getMinutes()).slice(-2);
      const second = ("0" + dateObj.getSeconds()).slice(-2);
      return `${year}${month}${day}${hour}${minute}${second}`;
    },
    changeData() {
      this.isLoading = true;       

      this.currentPage = 1;

      const payload = {
        from_phone_number : this.from_phone_number,
        from_phone_number_status : 'request',
      }  
      ApiCall.call('phone/from/list', 'POST', payload).then(res => {
        console.log(res.data);
        if (!res.data || !res.data.length) {
          // Handle case where no items were found
          console.log('No items found');
          this.isLoading = false;
          return;
        }
        const fromPhoneNumberList = res.data.map(item => {
          let iconClass = '';
          let primary_image = '';
          let primary_image_color = '';
          let secondary = '';
          let secondary_color = '';
          let type_korean = '';
          let auth_type_korean = '';
          let phone_status = '';
          //success(green), info(blue), warning(orange), danger(red), primary(red), secondary(grey), dark(black)
          if (item.from_phone_number_type === 'company') {
              type_korean = '법인번호';
          } else if (item.from_phone_number_type === 'individual') {
              type_korean = '개인번호';
          }
          if (item.authorize_type === 'document') {
              auth_type_korean = '서류인증';
          } else if (item.authorize_type === 'authentication') {
              auth_type_korean = '본인인증';
          }
          if (item.from_phone_number_status === 'active') {
              phone_status = '활성화';
          } else if (item.from_phone_number_status === 'reject') {
              phone_status = '반려';
          } else if (item.from_phone_number_status === 'inactive') {
              phone_status = '비활성화';
          } else if (item.from_phone_number_status === 'deleted') {
              phone_status = '삭제';
          } else if (item.from_phone_number_status === 'request') {
              phone_status = '요청중';
          } else if (item.from_phone_number_status === 'review') {
              phone_status = '검토중';
          }

          return {
            primary: item.from_phone_number,
            values: [
              type_korean,
              auth_type_korean,
              phone_status,
              item.authorize_timestamp,
            ],
            secondary: '',
            secondary_color: secondary_color,
            info: '',
            image: primary_image,
            image_color: primary_image_color,
            icon: '',
            pk: item.pk,
            sk: item.sk,
            file_list: item.file_list,
          };

          function formatDate(dateString) {
            const year = dateString.substring(0, 4);
            const month = dateString.substring(4, 6);
            const day = dateString.substring(6, 8);
            const hour = dateString.substring(8, 10);
            const minute = dateString.substring(10, 12);
            const second = dateString.substring(12, 14);

            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
          }
        });

        // console.log(res.data);
        // console.log(sendHistoryList)
        this.tableData = res.data.data;
        this.fromPhoneNumberList = fromPhoneNumberList;

        // Update current page if it exceeds the number of pages available
        const maxPage = Math.ceil(this.fromPhoneNumberList.length / this.itemsPerPage);
        if (this.currentPage > maxPage) {
          this.currentPage = maxPage;
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
<style>
.spinner-icon {
  font-size: 40px;
}
</style>
