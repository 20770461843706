<template>
  <div class="py-4 container-fluid">
    <div id="kakaoTemplateCard" class="card mt-0">
      <div class="card-header">
        <h6 class="mb-0">카카오 템플릿 등록</h6>
      </div>
      <div class="card-body pt-0">
        <div class="row align-items-start mt-2">
          <div class="col-8">
            <div class="row align-items-center">
              <div class="col-3">
                <span style="font-weight: bold; font-size: 0.8rem;">발신프로필</span>
              </div>
              <div class="col-9">
                <select
                  id="profileSelect"
                  v-model="selectedProfile"
                  class="form-control"
                >
                </select>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-3">
                <span style="font-weight: bold; font-size: 0.8rem;">템플릿 코드</span>
              </div>
              <div class="col-9">
                <material-input-vmodel
                  id="templateCode"
                  v-model="templateDetail.code"
                  variant="static"
                  placeholder="예시) m2g-0001 *한글불가"
                  :max-length=30
                >
                </material-input-vmodel>
              </div>
            </div>
            <div class="row align-items-end">
              <div class="col-3">
                <span style="font-weight: bold; font-size: 0.8rem;">템플릿 명</span>
              </div>
              <div class="col-9">
                <material-input-vmodel
                  id="templateCode"
                  v-model="templateDetail.name"
                  variant="static"
                  :max-length=200
                >
                </material-input-vmodel>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-3">
                <span style="font-weight: bold; font-size: 0.8rem;">카테고리</span>
              </div>
              <div class="col-4">
                <select
                  id="categorySelect1"
                  v-model="selectedCategory1"
                  class="form-control"
                >
                </select>
              </div>
              <div class="col-5">
                <select
                  id="categorySelect2"
                  v-model="selectedCategory2"
                  class="form-control"
                >
                </select>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-3">
                <span style="font-weight: bold; font-size: 0.8rem;">강조 유형</span>
              </div>
              <div class="col-9">
                <select
                  id="emphasizeTypeSelect"
                  v-model="templateDetail.templateEmphasizeType"
                  class="form-control"
                >
                </select>
              </div>
            </div>
            <div v-if="templateDetail.templateEmphasizeType === 'TEXT'">
              <div class="row align-items-center">
                <div class="col-3">
                  <span style="font-weight: bold; font-size: 0.8rem;">강조 타이틀</span>
                </div>
                <div class="col-8">
                  <material-input-vmodel
                    id="templateCode"
                    v-model="templateDetail.title"
                    variant="static"
                    :max-length=23
                  >
                  </material-input-vmodel>
                </div>
                <div class="col-1 px-0">
                  <span v-if="templateDetail.title" style="font-size: 0.5rem;">{{templateDetail.title.length}}/23자</span>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-3">
                  <span style="font-weight: bold; font-size: 0.8rem;">강조 보조표기</span>
                </div>
                <div class="col-8">
                  <material-input-vmodel
                    id="templateCode"
                    v-model="templateDetail.subTitle"
                    variant="static"
                    :max-length=18
                  >
                  </material-input-vmodel>
                </div>
                <div class="col-1 px-0">
                  <span v-if="templateDetail.subTitle" style="font-size: 0.5rem;">{{templateDetail.subTitle.length}}/18자</span>
                </div>
              </div>
            </div>
            <div v-if="templateDetail.templateEmphasizeType === 'IMAGE'" class="row align-items-end">
              <div class="col-3">
                <span style="font-weight: bold; font-size: 0.8rem;">이미지 추가</span>
              </div>
              <div class="col-6">
                <material-input-vmodel
                  id="templateCode"
                  v-model="templateDetail.imageFileName"
                  :disabled=true
                >
                </material-input-vmodel>
              </div>
              <div class="col-3">
                <div class="d-flex justify-content-end">
                  <input
                    ref="fileInput"
                    type="file"
                    style="display: none"
                    @change="handleFileInputChange($event, index)"
                  />
                  <material-button-progress
                    style="padding: 10px"
                    :is-loading="isImageUploadLoading"
                    @click="openFileDialog"
                  >
                    업로드
                  </material-button-progress>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-3">
                <span style="font-weight: bold; font-size: 0.8rem;">템플릿 내용</span>
              </div>
              <div class="col-9 mt-2">
                <material-textarea
                  id="content"
                  v-model="templateDetail.content"
                  placeholder="템플릿 내용을 입력하세요.

번호별로 다른 값을 보낼경우 변수를 설정해주세요.
변수명은 특수문자를 넣지마세요.
변수명이 여러개일때 각각 다른이름을 사용해주세요.
예)#{고객이름}님 환영합니다."
                  :rows="templateDetail.contentRow"
                >
                </material-textarea>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-3">
                <span style="font-weight: bold; font-size: 0.8rem;">버튼추가</span>
              </div>
              <div class="col-9 mt-2">
                <select
                  id="buttonTypeSelect"
                  v-model="selectedButtonType"
                  class="form-control"
                >
                </select>
              </div>
            </div>
            <div
              v-for="(button, index) in insertedButtons"
              :key="index"
              class="row align-items-center mb-2"
            >
              <div class="col-11">
                <kakao-template-button-input
                  v-model:buttonName="button.name"
                  v-model:links="button.links"
                  :type="button.linkType"
                >
                </kakao-template-button-input>
              </div>
              <div class="col-1">
                <i class="material-icons-round opacity-10" style="cursor: pointer;"  @click="deleteButton(index)">delete</i>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row d-flex justify-content-center">
              <div class="image-container max-width-300" style="position: relative; display: inline-block;">
                <img src="@/assets/img/smartphone2.png" class="img-fluid border-radius-lg max-width-300">
                <div class="text-start" style="position: absolute; top: 18%; left: 52%; transform: translateX(-50%);">
                  <custom-textarea
                    id="content"
                    v-model:contentsValue="templateDetail.content"
                    v-model:textTitleValue="templateDetail.title"
                    v-model:textSubTitleValue="templateDetail.subTitle"
                    placeholder="템플릿 내용을 입력하세요."
                    :disabled=true
                    :emphasize-type="templateDetail.templateEmphasizeType"
                    :image-url="imageFileUrl"
                    :buttons="insertedButtons"
                  >
                  </custom-textarea>
                </div>
                <div style="position: absolute; top: 5.5%; left: 55%; transform: translate(-50%, -50%);">
                  <span style="font-size: 0.9rem;">{{templateDetail.profileName}}</span>
                </div>
                <div style="position: absolute; top: 14%; left: 40%; transform: translate(-50%, -50%);">
                  <span style="font-size: 0.8rem;">{{templateDetail.profileName}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="col-12">
          <div class="d-flex justify-content-end">
<!--            <material-button-progress-->
<!--              @click="printValue"-->
<!--            >테스트</material-button-progress>-->
            <material-button-progress
              :is-loading="isRegisterLoading"
              @click="registTemplate"
            >등록</material-button-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import { DataTable } from "simple-datatables";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialButtonProgress from "@/components/MaterialButtonProgress.vue";
import * as Choices from "choices.js";
import axios from 'axios';
import XLSX from 'xlsx/dist/xlsx.full.min.js';
import ApiCall from "@/Interface/ApiCall";
import MaterialTable from "@/components/MaterialTable.vue";
import MaterialPagination from "@/components/MaterialPagination.vue";
import MaterialInputVmodel from "@/components/MaterialInputVmodel.vue";
import CustomTextarea from "@/components/CustomTextarea.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import KakaoTemplateButtonInput from "@/components/KakaoTemplateButtonInput.vue";

export default {
  name: "RegisterKakaoTemplate",
  components: {
    // MaterialButton,
    CustomTextarea,
    MaterialTextarea,
    MaterialInputVmodel,
    MaterialButtonProgress,
    KakaoTemplateButtonInput,
    // MaterialTextArea,
    // MaterialButton,
  },
  data() {
    return {
      selectedProfile: '',
      selectedProfileId: '',
      selectedCategory1: '',
      selectedCategory2: '',
      selectedCategoryCode: '',
      selectedButtonType: '',
      currentButtonName: '',
      templateDetail: {
        'code':'',
        'name':'',
        'content':'',
        'contentRow':10,
        'profileName':'',
        'templateEmphasizeType':'NONE',
        'imageFileName': '',
        'imageFileUrl': '',
        'title': '',
        'subTitle': '',
      },
      profileSelectInstance: null,
      categorySelectInstance1: null,
      categorySelectInstance2: null,
      emphasizeSelectInstance: null,
      buttonTypeSelectInstance: null,
      kakaoProfiles: [],
      templateCategorys: [],
      emphasizeTypes: [
        {
          'label': '선택안함',
          'value': 'NONE',
        },
        {
          'label': '강조표기형',
          'value': 'TEXT',
        },
        {
          'label': '이미지형',
          'value': 'IMAGE',
        },
      ],
      buttonTypes: [
        {
          'label': '선택',
          'value': '',
        },
        {
          'label': '배송조회',
          'value': 'DS',
        },
        {
          'label': '웹링크',
          'value': 'WL',
        },
        {
          'label': '앱링크',
          'value': 'AL',
        },
        {
          'label': '봇키워드',
          'value': 'BK',
        },
        {
          'label': '메시지전달',
          'value': 'MD',
        },
        {
          'label': '상담톡전환',
          'value': 'BC',
        },
        {
          'label': '봇전환',
          'value': 'BT',
        },
        {
          'label': '이미지 보안전송 플러그인',
          'value': 'P1',
        },
        {
          'label': '개인정보이용 플러그인',
          'value': 'P2',
        },
      ],
      insertedButtons: [],
      selectedEmphasizeType: '',
      imageFile: null,
      imageFileUrl : '',
      isImageUploadLoading: false,
      isRegisterLoading: false,
    };
  },
  computed: {
    kakaoProfileOptions() {
      const initialData = { 'label': '선택', 'value': '0' };
      const categoryArray = this.kakaoProfiles.map(item => ({
        'label': item.sk,
        'value': item.sender_key,
      }));
      return [initialData, ...categoryArray];
    },
    firstDepthCategory() {
      const initialData = { 'label': '선택', 'value': '0' };
      const categoryArray = Object.keys(this.templateCategorys).map(key => ({
        'label': key,
        'value': key
      }));
      return [initialData, ...categoryArray];
    },
    secondDepthCategory() {
      const initialData = { 'label': '선택', 'value': '0' };
      const categoryArray = Object.keys(this.templateCategorys[this.selectedCategory1]).map(key => ({
        'label': key,
        'value': this.templateCategorys[this.selectedCategory1][key],
      }));
      return [initialData, ...categoryArray];
    },
  },
  watch: {
    selectedProfile(newList) {
      // console.log("kakaoProfileOptions :" + JSON.stringify(this.kakaoProfileOptions));
      if (newList !== '') {
        const object = this.findObjectByValue(this.kakaoProfileOptions, 'value', newList)
        this.selectedProfileId = object.label

        this.templateDetail.profileName = this.selectedProfileId

        // console.log("selected : " + newList)
        // console.log("selected id: " + this.selectedProfileId)
      }
    },
    selectedCategory1(newList) {
      if (newList !== '') {
        this.initCategoryChoicesList2();
      }
    },
    selectedCategory2(newList) {
      if (newList !== '') {
        this.selectedCategoryCode = this.selectedCategory2
        console.log("selectedCategoryCode:" + this.selectedCategoryCode)
      }
    },
    selectedButtonType(newList) {
      if (newList !== '') {
        if (this.insertedButtons.length < 5) {
          console.log("Button add :" + newList)
          this.insertedButtons.push(
            {
              linkType: newList,
              name: '',
              links: {},
            }
          )

          this.initButtonChoicesList();
        } else {
          alert("템플릿 버튼추가는 최대 5개까지 가능합니다.")
        }
      }
    },
    // 'templateDetail.title': function(newTitle, oldTitle) {
    //   console.log(`'templateDetail.title' changed from "${oldTitle}" to "${newTitle}"`);
    //   const maxLengthBytes = 23;
    //   if (newTitle && newTitle.length > maxLengthBytes) {
    //     this.templateDetail.title = oldTitle;
    //   }
    // },

  },
  mounted() {
    this.getKakaoProfiles();
    this.getTemplateCategory();
    this.initEmphasizeTypeChoicesList();
    this.initButtonChoicesList();
  },
  methods: {
    initChoicesList() {
      if (this.profileSelectInstance) {
        this.profileSelectInstance.destroy();
      }
      this.profileSelectInstance = new Choices('#profileSelect', {
        shouldSort: false,
        choices: this.kakaoProfileOptions,
      });
    },
    initEmphasizeTypeChoicesList() {
      if (this.emphasizeSelectInstance) {
        this.emphasizeSelectInstance.destroy();
      }
      this.emphasizeSelectInstance = new Choices('#emphasizeTypeSelect', {
        shouldSort: false,
        choices: this.emphasizeTypes,
      });
    },
    initCategoryChoicesList1() {
      if (this.categorySelectInstance1) {
        this.categorySelectInstance1.destroy();
      }
      this.categorySelectInstance1 = new Choices('#categorySelect1', {
        shouldSort: false,
        choices: this.firstDepthCategory,
      });
    },
    initCategoryChoicesList2() {
      if (this.categorySelectInstance2) {
        this.categorySelectInstance2.destroy();
      }
      this.categorySelectInstance2 = new Choices('#categorySelect2', {
        shouldSort: false,
        choices: this.secondDepthCategory,
      });
    },
    initButtonChoicesList() {
      this.selectedButtonType='';
      if (this.buttonTypeSelectInstance) {
        this.buttonTypeSelectInstance.destroy();
      }
      this.buttonTypeSelectInstance = new Choices('#buttonTypeSelect', {
        shouldSort: false,
        choices: this.buttonTypes,
      });
    },
    getKakaoProfiles() {
      ApiCall.call('user/kakaoprofile/profileinfo', 'GET').then(response =>{
        this.kakaoProfiles = response.data.list
        console.log("response :"+response.data.message);
        this.initChoicesList();
      }).catch(error => {
        console.error('Error: Unable to fetch data from API', error);
      });
    },
    getTemplateCategory() {
      ApiCall.call('user/kakaotemplate/category', 'POST').then(response =>{
        console.log("response :"+response.data.message);
        this.templateCategorys = response.data.body.reduce((acc, item) =>{
          if (!acc[item.groupName]) {
            acc[item.groupName] = {};
          }
          acc[item.groupName][item.name] = item.code;

          return acc;
        }, {});

        console.log("templateCategorys :"+this.templateCategorys)

        this.initCategoryChoicesList1();
      }).catch(error => {
        console.error('Error: Unable to fetch data from API', error);
        // alert('Error: Unable to fetch data from API', error)
      });
    },
    findObjectByValue(arr, keyName, searchValue) {
      for (const obj of arr) {
        if (obj[keyName] === searchValue) {
          return obj;
        }
      }
      return null; // Return null if the value is not found in any object
    },
    registTemplate() {
      this.isRegisterLoading = true;
      if (!this.validateTemplateDetail()) {
        this.isRegisterLoading = false;
        return;
      }
      const payload = {
        'senderKey': this.selectedProfile,
        'profileId': this.selectedProfileId,
        'templateName': this.templateDetail.name,
        'templateCode': this.templateDetail.code,
        'templateMessageType': 'BA',
        'templateContent': this.templateDetail.content,
        'categoryCode': this.selectedCategoryCode,
        'templateEmphasizeType': this.templateDetail.templateEmphasizeType,
        'button': this.insertedButtons,
      }

      switch (this.templateDetail.templateEmphasizeType) {
        case "IMAGE":
          // payload['templateImageName'] = this.templateDetail.imageFileName;
          payload['templateImageName'] = this.templateDetail.imageFileName;
          // payload['templateImageUrl'] = this.templateDetail.imageFileUrl;
          payload['templateImageUrl'] = this.templateDetail.imageFileUrl;
          break;
        case "TEXT":
          payload['templateTitle'] = this.templateDetail.title;
          payload['templateSubtitle'] = this.templateDetail.subTitle;
          break;
        default:
          console.log("Default type.")
      }

      // console.log("payload :" + JSON.stringify(payload));
      ApiCall.call('user/kakaotemplate/create', 'POST', payload).then(response =>{
        // console.log("regist template : " + response.data.message)
        if (response.status === 200) {
          alert(response.data.message + "(템플릿 승인까지 약 카카오사 2~3영업일 소요)");
          this.isRegisterLoading=false;
          this.reset();
        } else {
          alert('템플릿 등록이 실패 했습니다.' + response.data.message);
          this.isRegisterLoading=false;
        }
      });
    },
    reset() {
      this.initChoicesList();
      this.initCategoryChoicesList2();
      this.initCategoryChoicesList1();
      this.initEmphasizeTypeChoicesList();
      this.selectedProfile = '';
      this.selectedProfileId = '';
      this.selectedCategory1 = '';
      this.selectedCategory2 = '';
      this.selectedCategoryCode = '';
      this.templateDetail = {
        'code':'',
        'name':'',
        'content':'',
        'contentRow':10,
        'profileName':'',
        'templateEmphasizeType':'NONE',
        'imageFileName': '',
        'imageFileUrl': '',
        'title': '',
        'subTitle': '',
      };
      this.insertedButtons = [];
    },
    async handleFileInputChange(event, index) {
      this.isImageUploadLoading = true;
      console.log("file input changed");
      if (event.target.files.length > 0) {
        try {
          this.imageFile = event.target.files[0]; // Use 'this' to access the data property
          this.imageFileUrl = URL.createObjectURL(this.imageFile)
          this.templateDetail.imageFileName = event.target.files[0].name;

          const imageResponse = await ApiCall.call('user/kakaotemplate/image', 'POST', null, null, this.imageFile);
          if (imageResponse.status === 200) {
            console.log('전송성공 :' + imageResponse.data);
            this.templateDetail.imageFileName = imageResponse.data.file_name;
            this.templateDetail.imageFileUrl = imageResponse.data.image;
          } else {
            console.log('전송실패 :' + imageResponse.data);
            alert('전송실패, 사유 :' + imageResponse.data.message);
          }

          this.isImageUploadLoading = false;
        } catch (error) {
          console.log('전송실패 :' + error);
          alert("이미지 업로드 실패: " + error);
          this.isImageUploadLoading = false;
          return;
        }
      }
    },
    handleImageUploaded(imageFile) {
      this.templateDetail.imageFileName = imageFile;
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    validateTemplateDetail() {
      if (!this.templateDetail ||
        !this.templateDetail.code ||
        !this.templateDetail.name ||
        !this.templateDetail.content ||
        !this.templateDetail.profileName) {
        alert('템플릿정보를 모두 입력해주세요.')
        return false; // Validation failed
      }

      if (this.templateDetail.templateEmphasizeType === 'IMAGE' &&
        (!this.templateDetail.imageFileName || !this.templateDetail.imageFileUrl)) {
        alert('이미지형 강조 선택 시 이미지 등록이 필요합니다.')
        return false; // Validation failed
      }

      if (this.templateDetail.templateEmphasizeType === 'TEXT' &&
        (!this.templateDetail.title || !this.templateDetail.subTitle)) {
        alert('강조표기형 강조 선택 시 강조타이틀과 보조표기항목을 입력해주세요.')
        return false; // Validation failed
      }

      if (this.isImageUploadLoading) {
        alert('이미지 업로드 완료 후 등록해주세요.')
        return false;
      }

      return true; // Validation passed
    },
    deleteButton(index) {
      this.insertedButtons.splice(index, 1);
    },
    printValue() {
      console.log("insertedButtons : " + JSON.stringify(this.insertedButtons));
    }
  },
};
</script>

<style scoped>
.my-input .form-control {
    height: 500px;
    resize: none;
    
}

</style>
