<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="col-11">
        <div v-if="campaignInfo.send_type === 'mms'">
          <h6 class="font-weight-bolder mb-4">🎨 이미지</h6>
          <div class="row mt-3">
            <p class="text-sm mb-1 font-weight-bold">강조하고 싶은 키워드를 선택해 주세요.</p>
            <p class="text-xs">선택한 키워드는 그래픽 생성에 활용됩니다.</p>
            <custom-select-keyword
              v-if="campaignInfo.keywords.length > 0"
              :options="campaignInfo.keywords"
              @selected="handleSelectKeyword"
            ></custom-select-keyword>
          </div>
          <div class="row mt-4">
            <p class="text-sm mb-1 font-weight-bold">그래픽스타일을 선택해 주세요.</p>
            <p class="text-xs">입체적인 3D 또는 플랫한 2D 중 선택할 수 있습니다.</p>
            <div class="col-6">
              <campaign-nav-pill
                id="graphic-style-select"
                :select-items="graphicStyles"
                :box-height=0
                name="graphic-style"
                @selectForm="updateGraphicStyle($event)"
              >
              </campaign-nav-pill>
            </div>
          </div>
          <div class="row mt-4">
            <p class="text-xs">다양한 그래픽 효과를 적용할 수 있습니다.</p>
            <campaign-nav-pill
              id="graphic-effect-select"
              :select-items="graphicEffects"
              :box-height=53
              name="graphic-effect"
              @selectForm="updateGraphicEffect($event)"
            >
            </campaign-nav-pill>
          </div>
          <div class="row mt-4">
            <p class="text-sm font-weight-bold">컬러 톤을 선택해 주세요.</p>
            <campaign-nav-pill
              id="graphic-color-tone-select"
              :select-items="graphicColorTones"
              :box-height=0
              name="graphic-color-tone"
              @selectForm="updateGraphicColorTone($event)"
            >
            </campaign-nav-pill>
          </div>
        </div>
        <h6 class="font-weight-bolder mt-5 mb-4">💬 텍스트</h6>
        <div class="row mt-4">
          <p class="text-sm font-weight-bold">광고 보이스 톤을 선택해 주세요.</p>
          <campaign-nav-pill
            id="text-voice-tone-select"
            :select-items="textVoiceTones"
            :box-height=0
            name="text-voice-tone"
            @selectForm="updateTextVoiceTone($event)"
          >
          </campaign-nav-pill>
        </div>
        <div class="row mt-4">
          <p class="text-sm font-weight-bold">텍스트 길이를 선택해주세요.</p>
          <campaign-nav-pill
            id="text-length-select"
            :select-items="textLengths"
            :box-height=0
            name="text-length"
            @selectForm="updateTextLength($event)"
          >
          </campaign-nav-pill>
        </div>
        <div class="row mt-4">
          <p class="text-sm font-weight-bold">광고 내용의 형태를 선택해주세요.</p>
          <campaign-nav-pill
            id="marketing-content-type-select"
            :select-items="marketingContentTypes"
            :box-height=53
            name="marketing-content-type"
            @selectForm="updateMarketingContentType($event)"
          >
          </campaign-nav-pill>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button-progress
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="$parent.prevStep"
          >뒤로</material-button-progress
        >
        <material-button-progress
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          :is-loading="isCreateLoading"
          @click="handleCreateInfo"
        >생성하기</material-button-progress
        >
      </div>
    </div>
    <progress-bar :is-loading="isCreateLoading" :percent="progress"></progress-bar>
  </div>
</template>

<script>
import MaterialButtonProgress from "@/components/MaterialButtonProgress.vue";
import MaterialInput from "@/components/MaterialInputVmodel.vue";
import { getEditorDefaults } from "@pqina/pintura";
import CustomSelectKeyword from "@/components/CustomSelectKeyword.vue";
import MaterialInputVmodel from "@/components/MaterialInputVmodel.vue";
import CampaignNavPill from "@/views/pages/marketing/components/CampaignNavPill.vue";
import ApiCall from "@/Interface/ApiCall";
import AlbCall from "@/Interface/AlbCall";
import ProgressBar from "@/views/pages/marketing/components/ProgressBar.vue";

export default {
  name: "CreateInfo",
  components: {
    CustomSelectKeyword,
    MaterialButtonProgress,
    CampaignNavPill,
    ProgressBar,
  },
  props: {
    campaignInfo: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:campaignInfo'],
  data() {
    return {
      isCreateLoading: false,
      progress: 0,
      duration: 25000,
      interval: null,
      graphicStyles: [
        {
          type: "3D",
          name: "3D",
          description: "",
        },
        {
          type: "2D",
          name: "2D",
          description: "",
        },
      ],
      graphicEffects: [
        {
          type: "기본",
          name: "기본",
          description: "",
        },
        {
          type: "라인드로잉",
          name: "라인드로잉",
          description: "선으로 그린 효과",
        },
        {
          type: "포토리얼리스틱",
          name: "포토리얼리스틱",
          description: "실제같은 느낌",
        },
        {
          type: "페이퍼크래프트",
          name: "페이퍼크래프트",
          description: "종이의 질감",
        },
        {
          type: "카툰",
          name: "카툰",
          description: "만화 그림체",
        },
      ],
      graphicColorTones: [
        {
          type: "네이비",
          name: "네이비",
          description: "",
        },
        {
          type: "블루",
          name: "블루",
          description: "",
        },
        {
          type: "그린",
          name: "그린",
          description: "",
        },
        {
          type: "옐로우",
          name: "옐로우",
          description: "",
        },
        {
          type: "레드",
          name: "레드",
          description: "",
        },
        {
          type: "화이트",
          name: "화이트",
          description: "",
        },
        {
          type: "블랙",
          name: "블랙",
          description: "",
        },
      ],
      textVoiceTones: [
        {
          type: "신뢰있고 정중하게",
          name: "신뢰있고 정중하게",
          description: "",
        },
        {
          type: "친근하고 다정하게",
          name: "친근하고 다정하게",
          description: "",
        },
        {
          type: "유머러스하게",
          name: "유머러스하게",
          description: "",
        },
        {
          type: "귀엽고 발랄하게",
          name: "귀엽고 발랄하게",
          description: "",
        },
      ],
      textLengths: [
        {
          type: "짧게",
          name: "짧게",
          description: "",
        },
        {
          type: "보통",
          name: "보통",
          description: "",
        },
        {
          type: "길게",
          name: "길게",
          description: "",
        },
      ],
      marketingContentTypes: [
        {
          type: "리스트형",
          name: "리스트형",
          description: "간단 명료하게",
        },
        {
          type: "문장형",
          name: "문장형",
          description: "이야기를 들려주듯",
        },
      ],
    }
  },
  watch: {
    isCreateLoading(newVal) {
      if (newVal) {
        this.startProgress();
      } else {
        this.resetProgress();
      }
    },
  },
  methods: {
    handleSelectKeyword(option) {
      this.$emit('update:campaignInfo', { ...this.campaignInfo, sent_emphasize_keyword: option })
    },
    updateGraphicStyle(selectedType) {
      this.$emit('update:campaignInfo', { ...this.campaignInfo, graphic_style: selectedType});
    },
    updateGraphicEffect(selectedType) {
      this.$emit('update:campaignInfo', { ...this.campaignInfo, graphic_effect: selectedType});
    },
    updateGraphicColorTone(selectedType) {
      this.$emit('update:campaignInfo', { ...this.campaignInfo, color_tone: selectedType});
    },
    updateTextVoiceTone(selectedType) {
      this.$emit('update:campaignInfo', { ...this.campaignInfo, marketing_text_tone: selectedType});
    },
    updateTextLength(selectedType) {
      this.$emit('update:campaignInfo', { ...this.campaignInfo, text_length: selectedType});
    },
    updateMarketingContentType(selectedType) {
      this.$emit('update:campaignInfo', { ...this.campaignInfo, marketing_content_type: selectedType});
    },
    async handleCreateInfo() {
      this.isCreateLoading = true;
      const payload = {
        campaign_object: this.campaignInfo.campaign_object,
        service_type: this.campaignInfo.service_type,
        campaign_purpose: this.campaignInfo.campaign_purpose,
        campaign_description: this.campaignInfo.campaign_description,
        landing_url: this.campaignInfo.landing_url,
        contact_address: this.campaignInfo.contact_address,
        contact_phone_number: this.campaignInfo.contact_phone_number,
        dimension: this.campaignInfo.graphic_style,
        colorTone: this.campaignInfo.color_tone,
        graphic: this.campaignInfo.graphic_effect,
        voiceTone: this.campaignInfo.marketing_text_tone,
        textLength: this.campaignInfo.text_length,
        adContentsType: this.campaignInfo.marketing_content_type,
        keyword: this.campaignInfo.sent_emphasize_keyword,
      };

      if (this.campaignInfo.send_type === 'mms') {
        payload.ai_template_type = 'both'
      } else {
        payload.ai_template_type = 'text'
      }

      await AlbCall.call('marketing/campaign/image', 'POST', payload).then(response => {
        if (response.status === 200) {
          console.log("response : " + JSON.stringify(response))

          const headlinesAndBodyCopyArray = response.data.data.map(({ headline, body_copy }) => ({ title: headline, content: body_copy }));

          console.log("contents: " + JSON.stringify(headlinesAndBodyCopyArray))

          let fileObjects = [];
          let fileUrls = [];
          let imageInfo = [];
          if (this.campaignInfo.send_type === 'mms') {
            // imagesArray 에 들어있는건 base64 인코딩된 file binary.
            const imagesArray = response.data.data.map(obj => obj.images);
            imagesArray.forEach(base64Data =>{
              const byteCharacters = atob(base64Data);
              const byteNumbers = new Array(byteCharacters.length);

              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: 'image/jpg' });

              const fileName = 'image.jpg';
              const fileObject = new File([blob], fileName, { type: 'image/jpg' });

              const fileUrl = URL.createObjectURL(fileObject)

              fileObjects.push(fileObject);
              fileUrls.push(fileUrl);

              imageInfo.push({file:fileObject, url:fileUrl})
            });
          }

          this.$emit('update:campaignInfo', {
            ...this.campaignInfo,
            contents: headlinesAndBodyCopyArray,
            image_files: fileObjects,
            image_file_urls: fileUrls,
            image_info: imageInfo,
            confirm_step: 2
          });
          // 이미지 받자 마자, 파일로 말아서 upload
          this.isCreateLoading = false;
          this.$parent.nextStep();
        } else {
          alert("광고 생성 오류: " + response.data);
          this.isCreateLoading = false;
        }
      });
    },
    startProgress() {
      console.log("progress : " + this.progress)
      this.progress = 0;
      const step = 1;
      const numSteps = this.duration / step;
      let currentStep = 0;

      this.interval = setInterval(() => {
        currentStep++;
        this.progress = (currentStep / numSteps) * 100;
        if (currentStep >= numSteps) {
          clearInterval(this.interval);
        }
      }, step);
    },
    resetProgress() {
      clearInterval(this.interval);
      this.progress = 0;
    },
    beforeUnmount() {
      clearInterval(this.interval);
    },
  }
};
</script>
