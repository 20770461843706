<template>
      <div id="send-history" class="card mt-4 me-2">
<!--        <div class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center" v-if="isLoading">-->
<!--          <div class="spinner-container">-->
<!--            <i class="fas fa-spinner fa-spin spinner-icon text-info"></i>-->
<!--          </div>-->
<!--        </div>-->
        <!-- Card header -->
        <div id="contentModal" class="modal fade" tabindex="-1" aria-labelledby="contentModalLabel" aria-hidden="true">
          <div class="modal-dialog" style="max-width: 500px; width: 90%;">
            <div class="modal-content">
              <div class="modal-header">
                <h6 id="contentModalLabel" class="modal-title">{{modalTitle}}</h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body" style="white-space: pre-wrap; word-wrap: break-word; overflow-wrap: break-word;">
                {{ modalContent }}
              </div>
            </div>
          </div>
        </div>
        <div class="card-header">
          <h6 class="mb-0">조회 조건</h6>
        </div>
        <div class="card-body pt-0">
          <div class="mt-0 row">
            <span style="font-weight: bold; font-size: 0.8rem;">발송 유형:</span>
          </div>
          <div class="col-sm-12 d-flex align-items-center">
                      <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">SMS </label>
                      <material-checkbox 
                          id="checkboxSMS"
                          v-model="isSMSChecked"
                          name="checkboxSMS"
                          :checked="isSMSChecked"
                      >
                      </material-checkbox>
                      <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">LMS </label>
                      <material-checkbox 
                          id="checkboxLMS"
                          v-model="isLMSChecked"
                          name="checkboxLMS"
                          :checked="isLMSChecked"
                      >
                      </material-checkbox>
                      <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">MMS </label>
                      <material-checkbox 
                          id="checkboxMMS"
                          v-model="isMMSChecked"
                          name="checkboxMMS"
                          :checked="isMMSChecked"
                      >
                      </material-checkbox>
                      <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">카카오 </label>
                      <material-checkbox 
                          id="checkboxAt"
                          v-model="isKakaoChecked"
                          name="checkboxAt"
                          :checked="isKakaoChecked"
                      >
                      </material-checkbox>
                      <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">앱푸시 </label>
                      <material-checkbox 
                          id="checkboxAppPush"
                          v-model="isAppPushChecked"
                          name="checkboxAppPush"
                          :checked="isAppPushChecked"
                      >
                      </material-checkbox>
                      <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">이메일 </label>
                      <material-checkbox 
                          id="checkboxEmail"
                          v-model="isEmailChecked"
                          name="checkboxEmail"
                          :checked="isEmailChecked"
                      >
                      </material-checkbox>
                      <label for="send_method" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">팩스 </label>
                      <material-checkbox 
                          id="checkboxFax"
                          v-model="isFaxChecked"
                          name="checkboxFax"
                          :checked="isFaxChecked"
                      >
                      </material-checkbox>
                  </div>
                  <div class="mt-0 row">
            <span style="font-weight: bold; font-size: 0.8rem;">전송 상태:</span>
          </div>
          <div class="col-sm-12 d-flex align-items-center">
                      <label for="send_status" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">요청완료 </label>
                      <material-checkbox 
                          id="checkboxRequest"
                          v-model="isRequestChecked"
                          name="checkboxRequest"
                          :checked="isRequestChecked"
                          
                      >
                      </material-checkbox>
                      <label for="send_status" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px; margin-left: 26px;">전송중 </label>
                      <material-checkbox 
                          id="checkboxInTransit"
                          v-model="isInTransitChecked"
                          name="checkboxInTransit"
                          :checked="isInTransitChecked"
                      >
                      </material-checkbox>
                      <label for="send_status" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px; margin-left: 26px;">전송성공 </label>
                      <material-checkbox 
                          id="checkboxSuccess"
                          v-model="isSuccessChecked"
                          name="checkboxSuccess"
                          :checked="isSuccessChecked"
                      >
                      </material-checkbox>
                      <label for="send_status" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px; margin-left: 26px;">전송실패 </label>
                      <material-checkbox 
                          id="checkboxFail"
                          v-model="isFailChecked"
                          name="checkboxFail"
                          :checked="isFailChecked"
                      >
                      </material-checkbox>
                      <label for="send_status" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px; margin-left: 26px;">수신완료 </label>
                      <material-checkbox 
                          id="checkboxReceive"
                          v-model="isReceiveChecked"
                          name="checkboxReceive"
                          :checked="isReceiveChecked"
                      >
                      </material-checkbox>
<!--                    </div>-->
<!--                      <div class="col-sm-12 d-flex align-items-center">-->
                      <label for="send_status" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">예약완료 </label>
                      <material-checkbox 
                          id="checkboxScheduleRequest"
                          v-model="isScheduleRequestChecked"
                          name="checkboxScheduleRequest"
                          :checked="isScheduleRequestChecked"
                      >
                      </material-checkbox>
                      <label for="send_status" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">예약전송중 </label>
                      <material-checkbox 
                          id="checkboxScheduleInTransit"
                          v-model="isScheduleInTransitChecked"
                          name="checkboxScheduleInTransit"
                          :checked="isScheduleInTransitChecked"
                      >
                      </material-checkbox>
                      <label for="send_status" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">예약전송성공 </label>
                      <material-checkbox 
                          id="checkboxScheduleSuccess"
                          v-model="isScheduleSuccessChecked"
                          name="checkboxScheduleSuccess"
                          :checked="isScheduleSuccessChecked"
                      >
                      </material-checkbox>
                      <label for="send_status" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">예약전송실패 </label>
                      <material-checkbox 
                          id="checkboxScheduleFail"
                          v-model="isScheduleFailChecked"
                          name="checkboxScheduleFail"
                          :checked="isScheduleFailChecked"
                      >
                      </material-checkbox>
                      <label for="send_status" style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px; margin-right: 5px;">예약전송취소 </label>
                      <material-checkbox
                          id="checkboxScheduleCancel"
                          v-model="isScheduleCancelChecked"
                          name="checkboxScheduleCancel"
                          :checked="isScheduleCancelChecked"
                      >
                      </material-checkbox>
                  </div>
          <div class="mt-0 row">
            <div class="col-6">
              <span style="font-weight: bold; font-size: 0.8rem;">조회기간:</span>
              <div class="col-sm-12 d-flex align-items-center mt-2">
                <div class="col-6" style="margin-right: 5px; max-width: 180px;">
                  <material-input
                      id="fromDate"
                      v-model="fromDate"
                      size="sm"
                      type="datetime-local"
                      :max="maxDatetime"
                  >
                  </material-input>
                </div>
                ~
                <div class="col-6" style="margin-left: 5px; max-width: 180px;">
                  <material-input
                      id="toDate"
                      v-model="toDate"
                      size="sm"
                      type="datetime-local"
                      :max="maxDatetime"
                  >
                  </material-input>
                </div>
              </div>

            </div>
            <div class="col-6">
              <span style="font-weight: bold; font-size: 0.8rem;">조회상세:</span>

              <div class="row align-items-center mt-2">
                <div class="col-4" style="max-width: 180px;">
                  <material-input
                      id="toPhoneNumer"
                      v-model="customerId"
                      type="text"
                      size="sm"
                      label="전화번호"
                      label-size="sm"
                      variant="outline"
                  >
                  </material-input>
                </div>
                <div class="col-4" style="max-width: 180px;">
                  <material-input
                      id="primary_customer_reference_id"
                      v-model="primary_customer_reference_id"
                      type="text"
                      size="sm"
                      label="고객식별ID"
                      label-size="sm"
                      variant="outline"
                  >
                  </material-input>
                </div>
                <div class="col-4" style="max-width: 180px;">
                  <material-input
                      id="secondary_customer_reference_id"
                      v-model="secondary_customer_reference_id"
                      type="text"
                      size="sm"
                      label="캠페인ID"
                      label-size="sm"
                      variant="outline"
                  >
                  </material-input>
                </div>
                <div v-if="currentTenantId === '-1'" class="col-4" style="max-width: 180px;">
                  <material-input
                      id="request_tenant_id"
                      v-model="selectedTenant"
                      type="text"
                      size="sm"
                      label="Tenant ID"
                      label-size="sm"
                      variant="outline"
                  >
                  </material-input>
                </div>
              </div>
            </div>
          </div>
          <div v-if="hasSubTenant && currentTenantId != '0'" class="mt-2 row">
            <span style="font-weight: bold; font-size: 0.8rem;">부서 선택</span>
            <select
              id="tenant-name"
              v-model="selectedTenant"
              class="form-control"
              name="tenant-name"
            >
              <option v-for="tenant in subTenantListForSearch" :key="tenant.tenant_id" :value="tenant.tenant_id">
                {{ tenant.tenant_name }}
              </option>
            </select>
          </div>
            <!-- <div class="row align-items-center mt-3">
            <div class="col-6" style="max-width: 180px;">
              <material-button
                  color="success"
                  size="sm"
                  variant="outline"
                  class="dropdown-toggle"
                  type="button"
                  id="send_status"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style="width: 180px"
                >
                  {{ send_status || "전송상태" }}
                </material-button>
                <ul class="dropdown-menu" aria-labelledby="send_status">
                  <li v-for="optionSendStatus in optionSendStatuses" :key="optionSendStatus">
                    <a
                      class="dropdown-item"
                      href="javascript:;"
                      @click="selectSendStatus(optionSendStatus)"
                    >
                      {{ optionSendStatus }}
                    </a>
                  </li>
                </ul>
            </div>
          </div>           -->
          <div class="row mt-3">
            <div class="col-12 text-end">
              <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;" @click="changeData">
                조회
              </material-button>
              <material-button color="primary" size="sm" variant="default" style="width: 120px" @click="showConfirmation">
                예약취소
              </material-button>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4 card mt-2 me-2">
        <div class="pb-0 card-header">
          <div class="row mb-2">
            <div class="col-6">
              <div v-if="result_count < 0"><h6>조회 결과</h6></div>
              <div v-if="result_count >= 0"><h6>조회 결과 {{ result_count }}건</h6></div>
            </div>
            <div v-if="sendHistoryList != '' && currentTenantId != '0'" class="col-6 text-end">
              <material-button color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;" @click="exportCSV">
                내보내기
              </material-button>
            </div>
          </div>
        </div>
        <div>
          <EasyDataTable
              table-class-name="customize-table"
              empty-message="조회된 내역이 없습니다."
              rows-per-page-message="페이지당 표시 행 수"
              rows-of-page-separator-message="중"
              :headers="tableColumns"
              :items="sendHistoryList"
              :loading="isLoading"
          >
            <template #loading>
              <img
                  src="@/assets/img/Spinner-1s-200px.gif"
                  style="width: 100px; height: 100px;"
              />
            </template>
            <template #item-primary="{ primary, image, image_color, secondary, secondary_color, status }">
              <div class="row">
                <div class="col-auto flex-wrap">
                  <material-button
                      v-if="image"
                      :color="image_color"
                      variant="outline"
                      class="btn-icon-only btn-rounded btn-sm d-flex align-items-center justify-content-center"
                      @click="openModal('전송상태',status)"
                  >
                    <i :class="image" aria-hidden="true"></i>
                  </material-button>
                </div>
                <div class="col-auto flex-wrap">
                  <div class="d-flex flex-column justify-content-center">
                    <span style="display: inline-block; max-width: 100%;" :title="primary">{{ primary }}</span>
                    <span style="display: inline-block; max-width: 100%;" :title="secondary">
                    <span :class="secondary_color">{{ secondary }}</span>
                  </span>
                  </div>
                </div>
              </div>
            </template>
         
              <template #item-sent_content="{ sent_content }">
<!--              <p>{{ sent_content }}</p>-->
                <div class="row" style="max-width: 290px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" :title="sent_content" @click="openModal('발송내용', sent_content)">
                  {{sent_content}}
                </div>              
              </template>
            <template #item-info="{ info }">
              <div class="justify-content-center" >
                <material-button
                    v-if="info"
                    size="xsm"
                    color="secondary"
                    variant="outline"
                    class="btn-icon-only btn-rounded btn-sm d-flex align-items-center justify-content-center"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    :title="info"
                    @click="openModal('실패사유',info)"
                >
                  <i class="fas fa-info" aria-hidden="true"></i>
                </material-button>
              </div>
            </template>
          </EasyDataTable>
        </div>
<!--        <material-table-->
<!--          title="조회 결과"-->
<!--          :headers=tableColumns-->
<!--          :lists=paginatedSendHistoryList-->
<!--          :values-max-width="'300px'"-->
<!--          @row-clicked=onRowClicked-->
<!--        />-->
<!--        <material-pagination-->
<!--        :total-items="sendHistoryList.length"-->
<!--        :items-per-page="itemsPerPage"-->
<!--        v-model="currentPage"-->
<!--        :total-pages="Math.ceil(sendHistoryList.length / itemsPerPage)"-->
<!--        :current-page="currentPage"-->
<!--        class="mt-4"-->
<!--        @page-changed="onPageChange"-->
<!--        />-->
      </div>
</template>
<!-- :lists="[
{
  title: 'Nike v22 Running',
  order: '12.821',
  values: ['$130.992', '$9.500', '13'],
  info: 'Refund rate is lower with 97% than other products',
  image:
    'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/blue-shoe.jpg',
  icon: 'bold-down text-success',
},
{
  title: 'Black Chair',
  order: '2.421',
  values: ['$40.600', '$9.430', '54'],
  image:
    'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/black-chair.jpg',
  icon: 'bold-up text-danger',
},
]" -->
<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import ApiCall from "@/Interface/ApiCall";
import { Auth } from 'aws-amplify'
import MaterialInput from "@/components/MaterialInput.vue";
import {formatDate} from "@fullcalendar/core";
import Calendar from "../../examples/Calendar.vue";

import MaterialTable from "@/components/MaterialTable.vue";
import MaterialPagination from "@/components/MaterialPagination.vue";
import * as Choices from "choices.js";
import Papa from "papaparse";

export default {
  name: "SendHistoryAdmin",
  components: {
    MaterialButton,
    // MaterialTable,
    // MaterialPagination,
    MaterialInput,
    MaterialCheckbox,
  },
  setup() {
  },
  data() {
    return {
      modalTitle: '',
      modalContent: '',
      // tableColumns: ['발송종류','발송일자', '수신번호', '발송내용','상태','고객식별ID','캠페인ID',''],
      tableData: [],
      fromDate: new Date(new Date().getTime() + (8 * 60 * 60 * 1000)).toISOString().slice(0, 16),
      toDate: new Date(new Date().getTime() + (10 * 60 * 60 * 1000)).toISOString().slice(0, 16),
      maxDate: '',
      primary_customer_reference_id: '',
      secondary_customer_reference_id: '',
      customerId: '',
      isSMSChecked: true,
      isLMSChecked: false,
      isMMSChecked: false,
      isKakaoChecked: false,
      isEmailChecked: false,
      isAppPushChecked: false,
      isFaxChecked: false,
      //전송상태
      isRequestChecked: true,
      isInTransitChecked: true,
      isSuccessChecked: true,
      isFailChecked: true,
      isReceiveChecked: true,
      isScheduleRequestChecked: true,
      isScheduleInTransitChecked: true,
      isScheduleSuccessChecked: true,
      isScheduleFailChecked: true,
      isScheduleCancelChecked: true,
      // currentTimestamp: Date.now(),
      sendHistoryList: [], // Add this line
      selectedRow: null,
      selectedList: null,
      maxItems: 10000,
      itemsPerPage: 1000,
      currentPage: 1, // Add this line
      page_number: 1,
      currentTenantId: '',
      result_count: -1,
      isLoading: false,
      selectedTenant: '',
      subTenantListForSearch: [],
      subTenantList: [],
      hasSubTenant: false,
      tableColumns : [
        { text: "발송부서", value: "tenant_name", sortable: true},
        { text: "발송종류", value: "primary", sortable: true},
        { text: "발송일자", value: "send_request_dtm", sortable: true},
        { text: "발신번호", value: "from_phone_number", sortable: true},
        { text: "수신번호", value: "phone_number_masking", sortable: true},
        { text: "발송내용", value: "sent_content", width: 300},
        { text: "상태", value: "status", sortable: true},
        { text: "고객식별ID", value: "primary_customer_reference_id", sortable: true},
        { text: "캠페인ID", value: "secondary_customer_reference_id", sortable: true},
        { text: "", value: "info", width: 10, sortable: true},
      ],
    }
  },
  computed: {
    paginatedSendHistoryList() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.sendHistoryList.slice(startIndex, endIndex);
    },
    maxDatetime() {
      // Get the current datetime in Seoul time and round up to the nearest 10 minute interval
      let now = new Date();
      now.setSeconds(0);

      // Set the time zone offset to the offset for Seoul time (UTC+9)
      let timezoneOffset = 9 * 60; // in minutes
      now.setMinutes(now.getMinutes() + timezoneOffset);

      // Round up to the nearest 10 minute interval
      let roundedMinutes = Math.ceil(now.getMinutes() / 10) * 10;
      now.setMinutes(roundedMinutes);

      // Add 7 days to the current date
      now.setDate(now.getDate() + 7);

      let datetimeString = now.toISOString().slice(0, -8);

      return datetimeString;
    }
  },
  async mounted() {
    // Perform the search using the route parameters
    if(this.$route.params.title)
      this.performSearch();

    await this.triggerGetTenant();
  },
  methods: {
    openModal(title, content) {
      this.modalTitle = title;
      this.modalContent = content.replace("<내용잘림>", "");
      const bootstrap = this.$store.state.bootstrap;
        // 모달 열기
        const modal = new bootstrap.Modal(
            document.getElementById("contentModal")
        );
        modal.show()
    },
    exportCSV() {
      // First, decode URIs in the dictionary list
      const decodedList = this.sendHistoryList;

      // Convert the decoded list to CSV with PapaParse
      const csv = Papa.unparse(decodedList, {
        quotes: true,  // Enable quotes around values
      });

      // Create and trigger the download link with UTF-8 BOM
      const csvBlob = new Blob(["\ufeff", csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(csvBlob);
      link.setAttribute('download', 'history.csv');
      document.body.appendChild(link);
      link.click();
    },
    async triggerGetTenant() {
      await this.getTenantList();

      if (document.getElementById("tenant-name")) {
        var tenant_name = document.getElementById("tenant-name");
        new Choices(tenant_name);
      }
    },
    async getTenantList() {
      await ApiCall.call('user/auth/subtenant/list', 'POST').then(async (res) => {

        // console.log("res.data :", res.data)
        if (res.data.length > 0) {
          this.subTenantList = res.data;
          this.hasSubTenant = true;
        }
        // this.subTenantList = this.subTenantList.concat([{tenant_id: "0", tenant_name:"전체"}])
        this.subTenantListForSearch = [{ tenant_id: "all", tenant_name: "전체" }]

        // 자기 자신의 tenant
        const user = await Auth.currentAuthenticatedUser()
        const idToken = user.signInUserSession.idToken;
        const tenantId = idToken.payload['cognito:groups'][0];
        this.currentTenantId = tenantId;
        const tenantName = user.attributes['custom:tenant_name'];
        const myTenant = [{tenant_id:tenantId, tenant_name:tenantName}]
        this.subTenantListForSearch = this.subTenantListForSearch.concat(myTenant)
        this.subTenantList = this.subTenantList.concat(myTenant)

        this.subTenantListForSearch = this.subTenantListForSearch.concat(res.data);

        this.selectedTenant = this.subTenantListForSearch[0].tenant_id
        // console.log('tenant list :' + JSON.stringify(this.subTenantListForSearch));
      })
    },
    performSearch() {
      // Retrieve the route parameters
      const title = this.$route.params.title;
      const start = this.$route.params.start;
      const request_tenant_id = this.$route.params.request_tenant_id;
      if (this.$route.params.primary_customer_reference_id){
        this.primary_customer_reference_id = this.$route.params.primary_customer_reference_id;
      }
      console.log("this.primary_customer_reference_id : ",this.primary_customer_reference_id);
      

      this.selectedTenant = request_tenant_id;

      const year = start.substring(0, 4);
      const month = start.substring(4, 6);
      const day = start.substring(6, 8);

      const date1 = `${year}-${month}-${day}T00:00`;
      const date2 = `${year}-${month}-${day}T23:59`;

      this.fromDate = date1;
      this.toDate = date2;
      this.isLMSChecked = true;
      this.isMMSChecked = true;
      this.isSMSChecked = true;
      this.isAppPushChecked = false;
      this.isKakaoChecked = true;
      this.isEmailChecked = false;
      this.isFaxChecked = false;


      this.isRequestChecked = false;
      this.isInTransitChecked = false;
      this.isSuccessChecked = false;
      this.isFailChecked = false;
      this.isReceiveChecked = false;
      this.isScheduleRequestChecked = false;
      this.isScheduleInTransitChecked = false;
      this.isScheduleSuccessChecked = false;
      this.isScheduleFailChecked = false;
      this.isScheduleCancelChecked = false;

      if(title == 'success'){
        this.isSuccessChecked = true;
        this.isReceiveChecked = true;
        this.isScheduleSuccessChecked = true;
      } else if (title == 'fail'){
        this.isFailChecked = true;
        this.isScheduleFailChecked = true;
      } else if (title == 'schedule-request'){
        this.isScheduleRequestChecked = true;
        this.isScheduleSuccessChecked = true;
        this.isScheduleInTransitChecked = true;
        this.isScheduleFailChecked = true;
      } else if (title == 'schedule-cancel'){
        this.isScheduleCancelChecked = true;
      } else {
        this.isRequestChecked = true;
        this.isInTransitChecked = true;
        this.isSuccessChecked = true;
        this.isFailChecked = true;
        this.isReceiveChecked = true;
        this.isScheduleRequestChecked = true;
        this.isScheduleInTransitChecked = true;
        this.isScheduleSuccessChecked = true;
        this.isScheduleFailChecked = true;
        this.isScheduleCancelChecked = true;
      }
      this.changeData();

      // Perform the search logic based on the title and start date
      // ...
    },
    onPageChange(pageNumber) {
      this.currentPage = pageNumber;
    },
    onRowClicked({ list, index }) {
      this.selectedList = list;
      this.selectedRow = index;
      // console.log(JSON.stringify(this.selectedList));
    },
    getFormattedDate(daysBefore = 0) {
      const currentTimestamp = Date.now();
      const date = new Date(currentTimestamp - daysBefore * 24 * 60 * 60 * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    changeDateToString(dateString) {
      const dateObj = new Date(dateString);
      const year = dateObj.getFullYear();
      const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      const day = ("0" + dateObj.getDate()).slice(-2);
      const hour = ("0" + dateObj.getHours()).slice(-2);
      const minute = ("0" + dateObj.getMinutes()).slice(-2);
      const second = ("0" + dateObj.getSeconds()).slice(-2);
      return `${year}${month}${day}${hour}${minute}${second}`;
    },
    showConfirmation() {
      const confirmationMessage = "예약중인 모든 메시지를 일괄 취소 하시겠습니까?\n(30분 이내 전송 될 메시지는 취소가 안됩니다.)";
      if (window.confirm(confirmationMessage)) {
        this.cancelSchedule();
      }
    },
    async cancelSchedule() {
      try {
          const response = await ApiCall.call('schedule/delete', 'POST');
          // const response = await ApiCall.call('안보내기', 'POST', payload);
          alert("예약취소 완료");
      } catch (error) {
          console.error(error);
          alert("예약취소 완료");
      }
    },
    changeData() {
      if (new Date(this.fromDate) > new Date(this.toDate)) {
        alert("시작일자가 종료일자보다 클 수 없습니다.")
        return;
      }
      if (!this.isLMSChecked & !this.isMMSChecked & !this.isSMSChecked & !this.isAppPushChecked & !this.isKakaoChecked & !this.isEmailChecked & !this.isFaxChecked) {
        alert("발송 유형을 한개 이상 체크해주세요.")
        return;
      }
      this.isLoading = true;

      this.page_number = 1;
      const sendType = [];
      this.currentPage = 1;
      if (this.isLMSChecked) sendType.push('lms')
      if (this.isMMSChecked) sendType.push('mms')
      if (this.isSMSChecked) sendType.push('sms')
      if (this.isAppPushChecked) sendType.push('apppush')
      if (this.isKakaoChecked) sendType.push('kakao')
      if (this.isEmailChecked) sendType.push('email')
      if (this.isFaxChecked) sendType.push('fax')

      const sendStatus = [];
      this.currentPage = 1;
      if (this.isRequestChecked) sendStatus.push('request')
      if (this.isInTransitChecked) sendStatus.push('in_transit')
      if (this.isSuccessChecked) sendStatus.push('success')
      if (this.isFailChecked) sendStatus.push('fail')
      if (this.isReceiveChecked) sendStatus.push('receive')
      if (this.isScheduleRequestChecked) sendStatus.push('schedule-request')
      if (this.isScheduleInTransitChecked) sendStatus.push('schedule-in_transit')
      if (this.isScheduleSuccessChecked) sendStatus.push('schedule-success')
      if (this.isScheduleFailChecked) sendStatus.push('schedule-fail')
      if (this.isScheduleCancelChecked) sendStatus.push('schedule-cancel')

      // const queryParam = {
      //   "customer_id" : '01059674864',
      // }
      //
      // console.log('fromDate :', this.changeDateToString(this.fromDate))
      // console.log('toDate :', this.changeDateToString(this.toDate))
      // console.log('sendType :', sendType)
      // console.log('sendStatus :', sendStatus)
      // console.log('primary_customer_reference_id :', this.primary_customer_reference_id)
      // console.log('secondary_customer_reference_id :', this.secondary_customer_reference_id)
      // console.log('customerId :', this.customerId)

      let payload = {
        from_date : this.changeDateToString(this.fromDate),
        to_date : this.changeDateToString(this.toDate),
        send_type : sendType,
        send_status : sendStatus,
        primary_customer_reference_id : this.primary_customer_reference_id,
        secondary_customer_reference_id : this.secondary_customer_reference_id,
        customer_id : this.customerId,
        items_per_page : this.itemsPerPage,
        page_number : this.page_number,
      }

      if (this.selectedTenant === 'all') {
        // 전체 조회
        // payload['tenant_id_list'] = this.subTenantList.map(item => item.tenant_id);
        payload['tenant_id_list'] = [this.selectedTenant];
      } else {
        payload['tenant_id_list'] = [this.selectedTenant];
      }

      ApiCall.call('message/history', 'POST', payload).then(res => {
        // if(res.data.length >= this.maxItems){
        //   alert('조회결과가 5000건이 넘습니다. 조회조건을 조정해 주세요.')
        // }
        this.result_count = res.data.data.length
        const sendHistoryList = res.data.data.map(item => {
          let iconClass = '';
          let primary_image = '';
          let primary_image_color = '';
          let send_status = '';
          let secondary_color = '';
          let secondary = '';
          let tenant_name = '';
          if (item.parent_tenant_name != ''){
            tenant_name = '('+item.parent_tenant_name+') ' + item.tenant_name;
          } else {
            tenant_name = item.tenant_name;
          }
          if (item.kakao_send_type == 'ai' || item.kakao_send_type == 'at'){
            secondary = '알림톡';
          } else if (item.kakao_send_type == 'ft') {
            secondary = '친구톡';
          } else if (item.kakao_send_type == 'fi' ){
            secondary = '친구톡-이미지';
          } else if (item.kakao_send_type == 'fl' ){
            secondary = '친구톡-리스트';
          } else if (item.kakao_send_type == 'fw' ){
            secondary = '친구톡-와이드 이미지';
          } else if (item.kakao_send_type == 'fc'){
            secondary = '친구톡-캐러셀';
          } else if (item.send_type_detail == 'slack'){
            secondary = 'SLACK';
          } else if (item.send_type_detail == 'teams'){
            secondary = 'TEAMS';
          } else if (item.send_type_detail == 'apppush'){
            secondary = 'APP PUSH';
          } else {
            secondary = '';
          }

          //success(green), info(blue), warning(orange), danger(red), primary(red), secondary(grey), dark(black)
          if (item.send_status === 'request') {
            iconClass = 'bold-up text-secondary';
            primary_image = "fas fa-arrow-right";
            primary_image_color = "dark";
            send_status = '요청완료';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'schedule-request') {
            iconClass = 'bold-up text-secondary';
            primary_image = "fas fa-calendar-alt";
            primary_image_color = "secondary";
            send_status = '예약완료';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'in_transit') {
            iconClass = 'bold-right text-info';
            primary_image = "fas fa-sync fa-spin";
            primary_image_color = "info";
            send_status = '전송중';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'schedule-in_transit') {
            iconClass = 'bold-right text-info';
            primary_image = "fas fa-sync fa-spin";
            primary_image_color = "info";
            send_status = '예약전송중';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'success') {
            iconClass = 'bold-down text-success';
            primary_image = "fas fa-check";
            primary_image_color = "success";
            send_status = '전송성공';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'schedule-success') {
            iconClass = 'bold-down text-success';
            primary_image = "fas fa-check";
            primary_image_color = "success";
            send_status = '예약전송성공';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'fail') {
            iconClass = 'bold-down text-danger';
            primary_image = "fas fa-exclamation";
            primary_image_color = "primary";
            send_status = '전송실패';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'schedule-fail') {
            iconClass = 'bold-down text-danger';
            primary_image = "fas fa-exclamation";
            primary_image_color = "primary";
            send_status = '예약전송실패';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'schedule-cancel') {
            iconClass = 'bold-down text-secondary';
            primary_image = "fas fa-trash-alt";
            primary_image_color = "secondary";
            send_status = '예약전송취소';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'receive') {
            iconClass = 'bold-down text-success';
            primary_image = "fas fa-check";
            primary_image_color = "success";
            send_status = '수신완료';
            secondary_color = "text-secondary";
          } else {
            iconClass = 'bold-down text-warning';
            primary_image = "fas exclamation-triangle";
            primary_image_color = "primary";
            send_status = '시스템오류';
            secondary_color = "text-secondary";
          }
          let send_status_korean = send_status;

          return {
            primary: item.send_type,
            // values: [
            //   formatDate(item.send_request_dtm),
            //   item.to_phone_number,
            //   item.sent_content,
            //   send_status_korean,
            //   item.primary_customer_reference_id,
            //   item.secondary_customer_reference_id,
            // ],
            send_request_dtm : formatDate(item.send_request_dtm),
            phone_number : item.to_phone_number,
            phone_number_masking : item.to_phone_number.substring(0, item.to_phone_number.length - 4) + '****',
            from_phone_number : item.from_phone_number,
            sent_content : item.sent_content,
            status : send_status_korean,
            primary_customer_reference_id : item.primary_customer_reference_id,
            secondary_customer_reference_id : item.secondary_customer_reference_id,
            secondary: secondary,
            secondary_color: secondary_color,
            info: item.fail_reason,
            image: primary_image,
            image_color: primary_image_color,
            icon: '',
            tenant_name: tenant_name,
          };

          function formatDate(dateString) {
            const year = dateString.substring(0, 4);
            const month = dateString.substring(4, 6);
            const day = dateString.substring(6, 8);
            const hour = dateString.substring(8, 10);
            const minute = dateString.substring(10, 12);
            const second = dateString.substring(12, 14);

            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
          }
        });

        // console.log(res)
        // this.tableData = res.data;
        this.sendHistoryList = sendHistoryList;

        // console.log(this.sendHistoryList)

        // Update current page if it exceeds the number of pages available
        // const maxPage = Math.ceil(this.sendHistoryList.length / this.itemsPerPage);
        // if (this.currentPage > maxPage) {
        //   this.currentPage = maxPage;
        // }
        this.isLoading = false;
        if (res.data.data.length == this.itemsPerPage){
          //  console.log('getting next page');
           this.appendData();
        }
      })
      .catch(error => {
        // Handle error response
        console.error('Error:', error);
        // You can display an error message to the user or perform any other necessary actions
        alert('Timeout이 발생했습니다. 조회조건을 수정하고 다시 조회하세요.');

        // Reset any relevant state variables if needed

        this.isLoading = false;
      });
    },
    appendData(){
      this.isLoading = true;
      this.page_number += 1;
      const sendType = [];
      this.currentPage = 1;
      if (this.isLMSChecked) sendType.push('lms')
      if (this.isMMSChecked) sendType.push('mms')
      if (this.isSMSChecked) sendType.push('sms')
      if (this.isAppPushChecked) sendType.push('apppush')
      if (this.isKakaoChecked) sendType.push('kakao')
      if (this.isEmailChecked) sendType.push('email')
      if (this.isFaxChecked) sendType.push('fax')

      const sendStatus = [];
      this.currentPage = 1;
      if (this.isRequestChecked) sendStatus.push('request')
      if (this.isInTransitChecked) sendStatus.push('in_transit')
      if (this.isSuccessChecked) sendStatus.push('success')
      if (this.isFailChecked) sendStatus.push('fail')
      if (this.isReceiveChecked) sendStatus.push('receive')
      if (this.isScheduleRequestChecked) sendStatus.push('schedule-request')
      if (this.isScheduleInTransitChecked) sendStatus.push('schedule-in_transit')
      if (this.isScheduleSuccessChecked) sendStatus.push('schedule-success')
      if (this.isScheduleFailChecked) sendStatus.push('schedule-fail')
      if (this.isScheduleCancelChecked) sendStatus.push('schedule-cancel')

      const payload = {
        from_date : this.changeDateToString(this.fromDate),
        to_date : this.changeDateToString(this.toDate),
        send_type : sendType,
        send_status : sendStatus,
        primary_customer_reference_id : this.primary_customer_reference_id,
        secondary_customer_reference_id : this.secondary_customer_reference_id,
        customer_id : this.customerId,
        items_per_page : this.itemsPerPage,
        page_number : this.page_number,
      }

      if (this.selectedTenant === 'all') {
        // 전체 조회
        // payload['tenant_id_list'] = this.subTenantList.map(item => item.tenant_id);
        payload['tenant_id_list'] = [this.selectedTenant];
      } else {
        payload['tenant_id_list'] = [this.selectedTenant];
      }
      ApiCall.call('message/history', 'POST', payload).then(res => {
        this.result_count += res.data.data.length
        
        const sendHistoryList = res.data.data.map(item => {
          let iconClass = '';
          let primary_image = '';
          let primary_image_color = '';
          let send_status = '';
          let secondary_color = '';
          let secondary = '';
          let tenant_name = '';
          if (item.parent_tenant_name != ''){
            tenant_name = '('+item.parent_tenant_name+') ' + item.tenant_name;
          } else {
            tenant_name = item.tenant_name;
          }
          if (item.kakao_send_type == 'ai' || item.kakao_send_type == 'at'){
            secondary = '알림톡';
          } else if (item.kakao_send_type == 'ft') {
            secondary = '친구톡';
          } else if (item.kakao_send_type == 'fi' || item.kakao_send_type == 'fl' || item.kakao_send_type == 'fw' || item.kakao_send_type == 'fc'){
            secondary = '친구톡-이미지';
          } else {
            secondary = '';
          }

          //success(green), info(blue), warning(orange), danger(red), primary(red), secondary(grey), dark(black)
          if (item.send_status === 'request') {
            iconClass = 'bold-up text-secondary';
            primary_image = "fas fa-arrow-right";
            primary_image_color = "dark";
            send_status = '요청완료';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'schedule-request') {
            iconClass = 'bold-up text-secondary';
            primary_image = "fas fa-calendar-alt";
            primary_image_color = "secondary";
            send_status = '예약완료';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'in_transit') {
            iconClass = 'bold-right text-info';
            primary_image = "fas fa-sync fa-spin";
            primary_image_color = "info";
            send_status = '전송중';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'schedule-in_transit') {
            iconClass = 'bold-right text-info';
            primary_image = "fas fa-sync fa-spin";
            primary_image_color = "info";
            send_status = '예약전송중';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'success') {
            iconClass = 'bold-down text-success';
            primary_image = "fas fa-check";
            primary_image_color = "success";
            send_status = '전송성공';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'schedule-success') {
            iconClass = 'bold-down text-success';
            primary_image = "fas fa-check";
            primary_image_color = "success";
            send_status = '예약전송성공';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'fail') {
            iconClass = 'bold-down text-danger';
            primary_image = "fas fa-exclamation";
            primary_image_color = "primary";
            send_status = '전송실패';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'schedule-fail') {
            iconClass = 'bold-down text-danger';
            primary_image = "fas fa-exclamation";
            primary_image_color = "primary";
            send_status = '예약전송실패';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'schedule-cancel') {
            iconClass = 'bold-down text-secondary';
            primary_image = "fas fa-trash-alt";
            primary_image_color = "secondary";
            send_status = '예약전송취소';
            secondary_color = "text-secondary";
          } else if (item.send_status === 'receive') {
            iconClass = 'bold-down text-danger';
            primary_image = "fas fa-exclamation";
            primary_image_color = "primary";
            send_status = '수신완료';
            secondary_color = "text-secondary";
          } else {
            iconClass = 'bold-down text-warning';
            primary_image = "fas exclamation-triangle";
            primary_image_color = "primary";
            send_status = '시스템오류';
            secondary_color = "text-secondary";
          }
          let send_status_korean = send_status;

          return {
            primary: item.send_type,
            // values: [
            //   formatDate(item.send_request_dtm),
            //   item.to_phone_number,
            //   item.sent_content,
            //   send_status_korean,
            //   item.primary_customer_reference_id,
            //   item.secondary_customer_reference_id,
            // ],
            send_request_dtm : formatDate(item.send_request_dtm),
            phone_number : item.to_phone_number,
            phone_number_masking : item.to_phone_number.substring(0, item.to_phone_number.length - 4) + '****',
            from_phone_number : item.from_phone_number,
            sent_content : item.sent_content,
            status : send_status_korean,
            primary_customer_reference_id : item.primary_customer_reference_id,
            secondary_customer_reference_id : item.secondary_customer_reference_id,
            secondary: secondary,
            secondary_color: secondary_color,
            info: item.fail_reason,
            image: primary_image,
            image_color: primary_image_color,
            icon: '',
            tenant_name: tenant_name,
          };

          function formatDate(dateString) {
            const year = dateString.substring(0, 4);
            const month = dateString.substring(4, 6);
            const day = dateString.substring(6, 8);
            const hour = dateString.substring(8, 10);
            const minute = dateString.substring(10, 12);
            const second = dateString.substring(12, 14);

            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
          }
        });

        // console.log(res);
        // console.log(sendHistoryList)
        // this.tableData = this.tableData.concat(res.data);
        this.sendHistoryList = this.sendHistoryList.concat(sendHistoryList);

        // console.log(this.sendHistoryList);

        // Update current page if it exceeds the number of pages available
        // const maxPage = Math.ceil(this.sendHistoryList.length / this.itemsPerPage);
        // if (this.currentPage > maxPage) {
        //   this.currentPage = maxPage;
        // }
        this.isLoading = false;
        if(this.result_count >= this.maxItems){
          alert('조회결과가 '+this.maxItems+'건이 넘습니다. 조회조건을 조정해 주세요.');
          return;
        }
        if (res.data.data.length == this.itemsPerPage){
          //  console.log('getting more page');
           this.appendData();
        }
      })
      .catch(error => {
        // Handle error response
        console.error('Error:', error);
        // You can display an error message to the user or perform any other necessary actions
        alert('Timeout이 발생했습니다. 조회조건을 수정하고 다시 조회하세요.');

        // Reset any relevant state variables if needed

        this.isLoading = false;
      });
    }
  },
};
</script>
<style>
.spinner-icon {
  font-size: 40px;
}
.customize-table{
  --easy-table-border: 0px;
}
</style>
