<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">매체별 사용 금액</h6>
        <!-- <button
          type="button"
          class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title=""
          data-bs-original-title="See which ads perform better"
        >
          <i class="fas fa-info" aria-hidden="true"></i>
        </button> -->
      </div>
      <!-- <div class="d-flex align-items-center">
        <span class="badge badge-md badge-dot me-4">
          <i class="bg-danger"></i>
          <span class="text-xs text-dark">카카오톡 링크 구매 건수</span>
        </span>
        <span class="badge badge-md badge-dot me-4">
          <i class="bg-dark"></i>
          <span class="text-xs text-dark">SMS 링크 구매 건수</span>
        </span>
      </div> -->
    </div>
    <div class="p-3 card-body">
      <default-line-chart
        v-if =  "chartData.datasets.length > 0"
        id="chart-line3"
        title="Traffic channels"
        :chart="chartData"
      />
    </div>
  </div>
</template>

<script>
import DefaultLineChart from "@/examples/Charts/DefaultLineChart.vue";
import ApiCall from "@/Interface/ApiCall";
import { Auth } from 'aws-amplify'
export default {
  name: "RevenueChartCard",
  components: {
    DefaultLineChart,
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
    };
  },
  async mounted() {  
    this.getPerTenantData();
  },
  methods: { 
    getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    async getPerTenantData() {
      this.isloading = true
      const date = new Date();
      const user = await Auth.currentAuthenticatedUser()
      const idToken = user.signInUserSession.idToken;
      const tenantId = idToken.payload['cognito:groups'][0];
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');      
      const year_month = `${year}${month}`;
      const payload = {
          // year_month: year_month,
          // send_type: ["sms", "lms", "mms", "at", "ai","email"],
          tenant_id: tenantId,
          year_month : year_month,
        };
      ApiCall.call('marketing/campaign/dashboard/approvetenantid', 'POST', payload)
        .then(response => {
          const perTenantData = response.data['per_tenant_id'];
          
          let chartData = {
            labels: [], // 차트의 X축 (연월일)
            datasets: [] // 각 테넌트별 데이터셋
          };

          let tenantNames = new Set(); // 중복 없는 테넌트명 저장
          let sortedMonths = Object.keys(perTenantData).sort(); // 날짜(년월일) 기준으로 정렬

          sortedMonths.forEach(month => {
            chartData.labels.push(`${month.substring(4, 6)}월 ${month.substring(6, 8)}일`); // 'YYYYMMDD' -> 'YYYY년 MM월 DD일'
            Object.keys(perTenantData[month]).forEach(tenant => {
              tenantNames.add(tenant); // 테넌트 이름 추가
            });
          });
          
          // 각 테넌트명별로 데이터셋 생성
          tenantNames.forEach(tenantName => {
            let dataset = {
              label: tenantName, // 테넌트 이름
              data: [], // 데이터
              fill: false, // 차트 채우기 없음
              borderColor: getRandomColor(), // 테넌트별로 랜덤한 색상
              tension: 0.1 // 차트 선의 부드러움
            };

            sortedMonths.forEach(month => {
              let count = perTenantData[month][tenantName] || 0; // 해당 날짜에 데이터가 없으면 0
              dataset.data.push(count);
            });

            chartData.datasets.push(dataset);
          });
          function getRandomColor() {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
              color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
          }
          this.chartData = chartData; 
          console.log(chartData)
        })
        .catch(error => {
            console.error('Error: Unable to fetch data from API', error);
        });

        // this.isloading = false;
    },
  },
};
</script>
