<template>
  <div
      class="input-group"
      :class="`input-group-${variant} ${getStatus(error, success)}`"
  >
    <label :class="variant === 'static' ? '' : 'form-label'">{{ label }}</label>
    <input
        :id="id"
        v-model="inputValue"
        :type="type"
        class="form-control"
        :class="getClasses(size)"
        :name="name"
        :placeholder="placeholder"
        :isRequired="isRequired"
        :disabled="disabled"
        :value="inputValue"
        :autocomplete="autoComplete"
        :maxlength="maxLength"
    />
    <i
      v-if="button"
      class="material-icons icon-sm copy-button"
      :style="{ color: buttonColor }"
      @click="copyToClipBoard"
      @mouseover="setHandCursor"
      @mouseleave="setDefaultCursor"
    >
      {{buttonIcon}}
    </i>
  </div>
</template>

<style>
.input-group {
  position: relative;
}

.copy-button {
  position: absolute;
  right: 0;
  top: 75%;
  transform: translateY(-50%);
}
</style>


<script>
import setMaterialInput from "@/assets/js/material-input.js";

export default {
  name: "MaterialInput",
  props: {
    variant: {
      type: String,
      default: "outline",
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    autoComplete: {
      type: String,
      default: 'new-password',
    },
    button: {
      type: Boolean,
      default: false,
    },
    buttonIcon: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-prop-types
    callback: {
      default: ''
    },
    buttonColor: {
      type: String,
      default: 'grey',
    },
    maxLength: {
      type: Number,
      default: null,
    }
  },
  // emits: ['copy-clipboard'],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  mounted() {
    setMaterialInput();
  },
  methods: {
    copyToClipBoard() {
      console.log("copy value to clipboard : ", this.modelValue)
      navigator.clipboard.writeText(this.modelValue);
    },
    getClasses: (size) => {
      let sizeValue;

      sizeValue = size ? `form-control-${size}` : null;

      return sizeValue;
    },
    getStatus: (error, success) => {
      let isValidValue;

      if (success) {
        isValidValue = "is-valid";
      } else if (error) {
        isValidValue = "is-invalid";
      } else {
        isValidValue = null;
      }

      return isValidValue;
    },
    setHandCursor() {
      this.$el.style.cursor = 'pointer';
    },
    // Method to set the default cursor
    setDefaultCursor() {
      this.$el.style.cursor = 'default';
    },
  },
};
</script>
