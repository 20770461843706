<template>
  <div class="col-5">
    <div class="mt-3">
      <span style="font-weight: bold; font-size: 1.1rem;">📣 광고 내용</span>
    </div>
    <div class="mt-3">
      <span style="font-weight: bold; font-size: 0.9rem;">제목</span>
      <material-textarea
        id="desc-title"
        :rows="2"
        variant="static"
        placeholder=""
        :disabled="true"
        :model-value="campaignInfo.subject"
        style="resize: none;"
      ></material-textarea>
    </div>
    <div class="mt-2">
      <span style="font-weight: bold; font-size: 0.9rem;">내용</span>
      <material-textarea
        id="desc-title"
        :rows="10"
        variant="static"
        placeholder=""
        :disabled="true"
        :model-value="campaignInfo.sent_content"
        style="resize: none;"
      ></material-textarea>
    </div>
    <div class="mt-3">
      <span style="font-weight: bold; font-size: 1.1rem;">👥 광고 대상</span>
    </div>
    <div class="row mt-2">
      <div class="col-4">
        <span style="font-weight: bold; font-size: 0.9rem;">매체명</span>
      </div>
      <div class="col-8">
        <material-input
          id="desc_approve_tenant_name"
          :model-value="campaignInfo.approve_tenant_name"
          :disabled="true"
        >
        </material-input>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4">
        <span style="font-weight: bold; font-size: 0.9rem;">예상건수</span>
      </div>
      <div class="col-8">
        <material-input
          id="desc_target_count"
          :model-value="campaignInfo.target_count.toString()"
          :disabled="true"
        >
        </material-input>
      </div>
    </div>
    <div class="mt-3">
      <span style="font-weight: bold; font-size: 0.9rem;">조건</span>
    </div>
    <div v-for="data in segments" :key="data" class="mt-2">
      <custom-select-target-segment
        v-if="segments.length > 0"
        :options="data"
      ></custom-select-target-segment>
    </div>
    <div class="mt-3">
      <span style="font-weight: bold; font-size: 1.1rem;">⚙️ 전송 설정</span>
    </div>
    <div class="mt-2 row">
      <div class="col-4">
        <span style="font-weight: bold; font-size: 0.9rem;">예약시간</span>
      </div>
      <div class="col-8">
        <material-input
          id="reservated_time"
          size="default"
          type="datetime-local"
          :model-value="parseInputDate"
          :disabled="true"
        >
        </material-input>
      </div>
    </div>
    <div class="mt-2 row">
      <div class="col-4">
        <span style="font-weight: bold; font-size: 0.9rem;">전송타입</span>
      </div>
      <div class="col-8">
        <material-input
          id="desc_send_type"
          :model-value="campaignInfo.send_type"
          :disabled="true"
        >
        </material-input>
      </div>
    </div>
  </div>
  <div class="col-7">
    <div class="row d-flex justify-content-center">
      <div class="image-container max-width-400" style="position: relative; display: inline-block;">
        <img src="@/assets/img/smartphone.png" class="img-fluid border-radius-lg max-width-400">
        <div class="text-start" style="position: absolute; top: 10%; left: 50%; transform: translateX(-50%);">
          <div class="combined-textarea text-center">
            <div class="textarea-container">
            <textarea
              v-if="campaignInfo.subject"
              :id="`${id}-textTitle`"
              ref="textTitleTextarea"
              class="form-control title-textarea"
              :rows="calculateRowsTitle(campaignInfo.subject, 3)"
              :disabled="disabled"
              :value="campaignInfo.subject"
              readonly
            >
            </textarea>
            </div>
            <div class="image-container mt-3">
              <img v-if="campaignInfo.final_image_url" class="image-area" :src="campaignInfo.final_image_url" alt="Uploaded Image">
            </div>
            <div class="textarea-content mt-3">
            <textarea
              v-if="campaignInfo.sent_content"
              :id="`${id}-contents`"
              ref="contentTextarea"
              class="form-control content-textarea"
              :rows="calculateRowsContent(campaignInfo.sent_content, contentMaxRow)"
              :name="name"
              :placeholder="placeholder"
              :isRequired="isRequired"
              :disabled="disabled"
              :value="campaignInfo.sent_content"
              readonly
            ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setMaterialInput from "@/assets/js/material-input.js";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import CustomSelectTargetSegment from "@/components/CustomSelectTargetSegment.vue";

export default {
  name: "CampaignForm",
  components: {
    CustomSelectTargetSegment,
    MaterialTextarea,
    MaterialInput,
  },
  props: {
    variant: {
      type: String,
      default: "outline",
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    isRequired: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 5,
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    campaignInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentContentsRows: 1,
      currentTitleRows: 1,
    }
  },
  computed: {
    segments() {
      return Object.keys(this.campaignInfo.target_segment).map(key => {
        return {
          name: key,
          list: this.campaignInfo.target_segment[key].slice(1)
        };
      });
    },
    parseInputDate() {
      const year = this.campaignInfo.send_request_dtm.substr(0, 4);
      const month = this.campaignInfo.send_request_dtm.substr(4, 2);
      const day = this.campaignInfo.send_request_dtm.substr(6, 2);
      const hours = this.campaignInfo.send_request_dtm.substr(8, 2);
      const minutes = this.campaignInfo.send_request_dtm.substr(10, 2);
      const seconds = this.campaignInfo.send_request_dtm.substr(12, 2);

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    },
    contentMaxRow() {
      if (this.campaignInfo.send_type === 'mms') {
        return 10;
      } else {
        return 20;
      }
    }
  },
  watch: {
    contentsValue(newValue) {
      this.adjustContentsRows();
    },
    textTitleValue(newValue) {
      this.adjustTitleRows();
    }
  },
  mounted() {
    this.adjustContentsRows(); // Initial adjustment
    this.adjustTitleRows();
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value);
      this.adjustContentsRows();
    },
    adjustContentsRows() {
      const content = this.campaignInfo.sent_content;
      const byteLimit = 57; // Set your desired byte limit for line increase with Korean characters
      const maxRows = 20; // Set your maximum row limit here
      const textarea = this.$refs.contentTextarea; // Get a reference to the content textarea element

      if (textarea) {
        // Calculate the byte length of the content considering UTF-8 encoding
        const contentBytes = new TextEncoder().encode(content).length;

        // Calculate the number of line breaks in the content
        const lineBreaks = (content.match(/\n/g) || []).length;

        // Calculate the number of rows based on byte length and line breaks
        const estimatedRows =
          Math.ceil(contentBytes / byteLimit) +
          lineBreaks + 1 // Add 1 to account for the initial row

        // Set a minimum number of rows to prevent the textarea from becoming too small
        const minRows = 1;

        // Enforce the maximum row limit and minimum row limit
        this.currentContentsRows = Math.min(Math.max(estimatedRows, minRows), maxRows);
      }
    },
    adjustTitleRows() {
      const content = this.campaignInfo.subject;
      const byteLimit = 20; // Set your desired byte limit for line increase with Korean characters
      const maxRows = 3; // Set your maximum row limit here
      const textarea = this.$refs.textTitleTextarea; // Get a reference to the content textarea element

      if (textarea) {
        // Calculate the byte length of the content considering UTF-8 encoding
        const contentBytes = new TextEncoder().encode(content).length;

        // Calculate the number of line breaks in the content
        const lineBreaks = (content.match(/\n/g) || []).length;

        // Calculate the number of rows based on byte length and line breaks
        const estimatedRows =
          Math.ceil(contentBytes / byteLimit) +
          lineBreaks // Add 1 to account for the initial row

        // Set a minimum number of rows to prevent the textarea from becoming too small
        const minRows = 1;

        // Enforce the maximum row limit and minimum row limit
        this.currentTitleRows = Math.min(Math.max(estimatedRows, minRows), maxRows);
      }
    },
    calculateRowsTitle(value, maxRow) {
      const lineBreaks = (value.match(/\n/g) || []).length; // Count line breaks
      const textLength = value.length; // Count text length
      const charsPerRow = 22; // Assuming 40 characters per row (adjust as needed)
      const defaultRows = 1; // Set a default number of rows
      const rowsFromText = Math.ceil(textLength / charsPerRow); // Calculate rows based on text length
      const calculatedRows = Math.min(Math.max(lineBreaks + 1, rowsFromText, defaultRows), maxRow); // Ensure the calculated rows are within the range
      return calculatedRows;
    },
    calculateRowsContent(value, maxRow) {
      const lineBreaks = (value.match(/\n/g) || []).length; // Count line breaks
      const textLength = value.length; // Count text length
      const charsPerRow = 27; // Assuming 40 characters per row (adjust as needed)
      const defaultRows = 5; // Set a default number of rows
      const rowsFromText = Math.ceil(textLength / charsPerRow); // Calculate rows based on text length
      const calculatedRows = Math.min(Math.max(lineBreaks + 1, rowsFromText, defaultRows), maxRow); // Ensure the calculated rows are within the range
      return calculatedRows;
    },
  },
};
</script>

<style scoped>
.textarea-container {
  display: flex;
  align-items: stretch; /* Ensure all items have the same height */
}

.title-textarea{
  border-radius: 10px; /* Rounded corners */
  overflow-y: hidden;
  font-size: 1.15rem;
  font-weight: bold;
  resize: none;
  background-color: lightgray;
  padding: 15px;
  width: 300px
}
.content-textarea {
  border-radius: 10px; /* Rounded corners */
  overflow-y: hidden; /* Hide vertical scrollbar */
  font-size: 1.0rem;
  resize: none;
  background-color: lightgray;
  padding: 15px;
  width: 300px
}

.image-container {
}

.image-area {
  width: 300px;
  height: auto;
  border-radius: 10px;
}
</style>
