<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <default-statistics-card
        title="총 발송고객 건수"
        :count=this_month_total_count.toLocaleString()
        :percentage="count_percentage"
        :menu="currentMonthRange"
        :dropdown="[
          {
            label: 'Last 7 days',
            route: 'https://creative-tim.com/',
          },
          {
            label: 'Last week',
            route: '/pages/widgets',
          },
          {
            label: 'Last 30 days',
            route: '/',
          },
        ]"
      />
      <default-statistics-card
        title="총 사용 금액"
        :count=this_month_total_amount.toLocaleString()
        :percentage="cost_percentage"
        :menu="currentMonthRange"
        :dropdown="[
          {
            label: 'Last 7 days',
            route: 'javascript:;',
          },
          {
            label: 'Last week',
            route: 'javascript:;',
          },
          {
            label: 'Last 30 days',
            route: 'javascript:;',
          },
        ]"
      />
      <default-statistics-card
        title="총 광고 건수"
        :count=total_amount.toLocaleString()
        :percentage="total_percentage"
        :menu="currentMonthRange"
        :dropdown="[
          {
            label: 'Last 7 days',
            route: 'javascript:;',
          },
          {
            label: 'Last week',
            route: 'javascript:;',
          },
          {
            label: 'Last 30 days',
            route: 'javascript:;',
          },
        ]"
      />
    </div>
    <div class="mt-4 row">
      <div class="col-lg-4 col-sm-6">
        <channels-chart-card />
      </div>
      <div class="mt-4 col-lg-8 col-sm-6 mt-sm-0">
        <revenue-chart-card />
      </div>
    </div>
    <div class="mt-4 row">
      <div class="mt-4 col-lg-12 mt-lg-0">
        <div class="card">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">세그먼트 목록</h6>
            </div>
          </div>
          <div class="row mt-3">
            <custom-view-keyword
              v-if="segmentData.keywords.length > 0"
              :options="segmentData.keywords"
            ></custom-view-keyword>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-12">
        <div class="card h-100">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">세그먼트 통계 </h6>
            </div>
          </div>
          <div class="p-3 card-body">
            <horizontal-bar-chart
              v-if =  "chartSementData.datasets.length > 0"
              :chart="chartSementData"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="mt-4 row">
      <div class="col-12">
        <orders-list-card
          title="Top Selling Products"
          :headers="['Product', 'Value', 'Ads Spent', 'Refunds']"
          :lists="products"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import ChannelsChartCard from "./components/ChannelsChartCard.vue";
import RevenueChartCard from "./components/RevenueChartCard.vue";
import HorizontalBarChart from "@/examples/Charts/HorizontalBarChart.vue";
import DefaultStatisticsCard from "./components/DefaultStatisticsCard.vue";
import OrdersListCard from "@/examples/Cards/OrdersListCard.vue";
import CustomViewKeyword from "@/components/CustomViewKeyword.vue";

// images
import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";
import AU from "@/assets/img/icons/flags/AU.png";
import setTooltip from "@/assets/js/tooltip.js";
import ApiCall from "@/Interface/ApiCall";
import { Auth } from 'aws-amplify'

export default {
  name: "Campaign Dashboard",
  components: {
    CustomViewKeyword,
    ChannelsChartCard,
    RevenueChartCard,
    HorizontalBarChart,
    DefaultStatisticsCard,
    // OrdersListCard,
  },
  data() {
    return {
      currentMonthRange : '',
      segmentData: {
        keywords: []
      },
      total_amount : 0,
      this_month_total_count : 0,
      this_month_total_amount : 0,
      chartSementData: {
        labels: [],
        datasets: [],
      },
      count_percentage : {
          color: '',
          value: '',
          label: '',
      },
      cost_percentage : {
          color: '',
          value: '',
          label: '',
      },
      total_percentage : {
          color: '',
          value: '',
          label: '',
      },
      count_growth_rate : 0,
      cost_growth_rate : 0,
      total_growth_rate : 0,
      count_growth_color : 'success',
      cost_growth_color : 'success',
      total_growth_color : 'success',
    };
  },
  mounted() {
    setTooltip(this.$store.state.bootstrap);
    this.setSuccessFailCount();
    this.currentMonthRange = this.getMonthStartAndEnd();
  },
  methods: { 
    getMonthStartAndEnd() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();

      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);

      const firstDayFormatted = `${firstDay.getFullYear()}-${String(firstDay.getMonth() + 1).padStart(2, '0')}-${String(firstDay.getDate()).padStart(2, '0')}`;
      const lastDayFormatted = `${lastDay.getFullYear()}-${String(lastDay.getMonth() + 1).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')}`;

      return `${firstDayFormatted} - ${lastDayFormatted}`;
    },
    getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}${month}`;
      return formattedDate;
    },
    async setSuccessFailCount() {
      const date = new Date();
      const user = await Auth.currentAuthenticatedUser()
      const idToken = user.signInUserSession.idToken;
      const tenantId = idToken.payload['cognito:groups'][0];
      const yearMonth = this.getCurrentDate();
      const payload = {
          tenant_id: tenantId,
          year_month : yearMonth
        };

      ApiCall.call('marketing/campaign/dashboard', 'POST', payload)
        .then(response => {
          this.count_growth_rate = String(response.data['count_growth_rate'])
          this.cost_growth_rate = String(response.data['cost_growth_rate'])
          this.total_growth_rate = String(response.data['year_cost_growth_rate'])
          this.total_amount = response.data['this_month_media_count'] + "건"
          this.this_month_total_count = response.data['this_month']['this_month_total_count'].toLocaleString() +"건"
          this.this_month_total_amount = response.data['this_month']['this_month_total_cost'].toLocaleString() +"원"

          const segmentData = response.data['target_segment'];

          // 차트 데이터 구조 생성
          let chartSementData = {
            labels: Object.keys(segmentData), // 키를 labels 배열로 사용
            datasets: [
              {
                label: 'Segment Count', // 데이터셋의 라벨
                data: Object.values(segmentData), // 각 키의 값들을 data 배열로 사용
              }
            ]
          };

          this.chartSementData = chartSementData
          // segmentInfo 객체를 업데이트합니다. 여기서는 예시로 segmentKeywords를 직접 할당합니다.
          // 실제로는 segmentInfo 객체의 구조에 맞게 업데이트해야 합니다.
          // this.segmentInfo = {
          //   ...this.segmentInfo, // 기존 segmentInfo의 다른 속성들을 유지
          //   keywords: Object.keys(segmentData) // segmentData의 키들로 keywords 배열을 업데이트
          // };
          // this.$emit('update:segmentInfo', {
          //   ...this.segmentInfo,
          this.segmentData = { 
            keywords: Object.keys(segmentData)
          };

          console.log(this.segmentInfo)
          
          
          let label = ''
          // count_growth_rate 값에 따른 색상 클래스 동적 할당
          if (this.count_growth_rate && this.count_growth_rate.startsWith('-')) {
            this.count_growth_color = 'danger';
            label = '지난 달 대비'
          } else {
            this.count_growth_color = 'success';
            label = '지난 달 대비'
          }
          if (this.count_growth_rate == "N/A"){
            this.count_growth_color = 'grey';
            label = ''
          }
          let count_percentage = {
              color: this.count_growth_color,
              value: this.count_growth_rate,
              label: label,
          }
          this.count_percentage = {}

          // count_growth_rate 값에 따른 색상 클래스 동적 할당
          if (this.cost_growth_rate && this.cost_growth_rate.startsWith('-')) {
            this.cost_growth_color = 'danger';
            label = '지난 달 대비'
          } else {
            this.cost_growth_color = 'success';
            label = '지난 달 대비'
          }
          if (this.cost_growth_rate == "N/A"){
            this.cost_growth_color = 'grey';
            label = ''
          }
          let cost_percentage = {
              color: this.cost_growth_color,
              value: this.cost_growth_rate,
              label: label,
          }
          this.cost_percentage = {}

          // count_growth_rate 값에 따른 색상 클래스 동적 할당
          if (this.total_growth_rate && this.total_growth_rate.startsWith('-')) {
            this.total_growth_color = 'danger';
            label = '지난 달 대비'
          } else {
            this.total_growth_color = 'success';
            label = '지난 달 대비'
          }
          if (this.total_growth_rate == "N/A"){
            this.total_growth_color = 'grey';
            label = ''
          }
          
          let total_percentage = {
              color: this.total_growth_color,
              value: this.total_growth_rate,
              label: label,
          }
          this.total_percentage = {}
          // total_percentage
        })
        .catch(error => {
            console.error('Error: Unable to fetch data from API', error);
        });
    },
  },
};
</script>
