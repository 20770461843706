<template>
  <div class="card h-100 mt-4">
      <div class="card-header pb-0 p-3">
          <div class="row">
              <div class="col-6 d-flex align-items-center">
                  <h6 class="mb-0">LMS</h6>
              </div>
          </div>
          <form ref="form" @submit.prevent="submitForm">
          <div class="row">
              <div class="mt-7 col-sm-1 col-1 d-flex align-items-center justify-content-center">
                  <i class="material-icons icon-hover icon-large" style="cursor: pointer; margin-top: -7px;"
                      @click="openFileDialog">attach_file</i>
                  <input ref="fileInput" type="file" style="display: none" @change="handleFileUpload" />
              </div>
              <div class="btn-group dropup mt-7 col-sm-6 col-6">
                  <button type="button" class="btn bg-gradient-primary dropdown-toggle" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      {{ selectedPhoneNumberField }}
                  </button>
                  <ul class="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                      <li v-for="field in headerFields" :key="field">
                          <a class="dropdown-item border-radius-md" href="javascript:;"
                              @click="handlePhoneSelection(field)">{{ field }}</a>
                      </li>
                  </ul>
              </div>
              <div class="btn-group dropup mt-7 col-sm-5 col-5">
                  <button type="button" class="btn bg-gradient-primary dropdown-toggle" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      {{ selectedParamField1 }}
                  </button>
                  <ul class="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                      <li v-for="field in headerFields" :key="field">
                          <a class="dropdown-item border-radius-md" href="javascript:;"
                              @click="handleParmSelection1(field)">{{ field }}</a>
                      </li>
                  </ul>
                  <button type="button" class="btn bg-gradient-primary dropdown-toggle" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      {{ selectedParamField2 }}
                  </button>
                  <ul class="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                      <li v-for="field in headerFields" :key="field">
                          <a class="dropdown-item border-radius-md" href="javascript:;"
                              @click="handleParmSelection2(field)">{{ field }}</a>
                      </li>
                  </ul>
                  <button type="button" class="btn bg-gradient-primary dropdown-toggle" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      {{ selectedParamField3 }}
                  </button>
                  <ul class="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                      <li v-for="field in headerFields" :key="field">
                          <a class="dropdown-item border-radius-md" href="javascript:;"
                              @click="handleParmSelection3(field)">{{ field }}</a>
                      </li>
                  </ul>
              </div>
              <div class="row">
              <div class="col-12 d-flex align-items-center">
                  <material-input id="text1" name="text1" placeholder="Type here.." @input="updateValue"></material-input>
                  <material-input id="text2" v-model="formData.text2" name="text2" placeholder="Type here.."></material-input>

                  <material-input
                  id="input1"
                  v-model="formData.input1"                    
                  name="input1"
                  placeholder="Enter your username"
                  variant="outline"
                  size="lg"
                  :success="input1Success"
                  :error="input1Error"
                  :is-required="false"
                  ></material-input>

                  <material-input
                  id="input2"
                  v-model="formData.input2"
                  name="input2"                    
                  label="Username"
                  variant="outline"
                  :success="input2Success"
                  :error="input2Error"
                  :is-required="true"
                  @input="onInput2Change"
                  ></material-input>

                  <material-input
                      id="input3"
                      v-model="formData.input3"
                      name="input3"
                      placeholder="Enter your email"
                      :disabled="isdisableInput3Disabled"
                  ></material-input>

                  <material-input
                  id="input4"
                  v-model="formData.input4"
                  name="input4"                    
                  label="input4"
                  variant="outline"
                  :is-required="true"
                  ></material-input>
              </div>
          </div>
          </div>
          <div class="row">
              <div class="col-6 text-end">
                  <material-button type="submit" color="primary" size="sm" variant="outline">
                      Send File</material-button>
              </div>
          </div>
          </form>
      </div>

  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import * as XLSX from 'xlsx'

export default {
  name: "InvoiceCard",
  components: {
      MaterialButton,
      MaterialInput
  },
  data() {
      return {
          formData: {
              // excelfile: '',
              // selectedPhoneNumberField: '',
              // selectedParamField1: '',
              // selectedParamField2: '',
              // selectedParamField3: '',
              // selectedParamField4: '',
              // selectedParamField5: '',
              // selectedParamField6: '',
              // selectedParamField7: '',
              // selectedParamField8: '',
              // selectedParamField9: '',
              // selectedParamField10: '',
              text1: '',
              text2: '',
              input1: '',
              input2: '',
              input3: '',
          },
          input2Success: false,
          input2Error: false,
          formDisabled: false,
          selectedPhoneNumberField: '전화번호 선택',
          selectedParamField1: '변수1 선택',
          selectedParamField2: '변수2 선택',
          selectedParamField3: '변수3 선택',
          isManualPhoneNumberVisible: true,
          headerFields: [],
      }
  },
  computed: {
  isdisableInput3Disabled() {
    return !this.input2Success;
  },
  },
  methods: {
      onInput2Change() {
          this.validateInput2();
          if (!this.formData.input2) {
              this.formData.input3 = "";
          }
      },
      validateInput2() {
          if (this.formData.input2.length > 4) {
          this.input2Success = true;
          this.input2Error = false;
          } else {
          this.input2Success = false;
          this.input2Error = true;
          }
      },
      updateValue(event) {
          this.formData.text1 = event.target.value;
      },
      submitForm() {
          if (this.$refs.form.checkValidity()) {
          // Process form data
          console.log("Form submitted:", this.formData);
          alert(JSON.stringify(this.formData))
          } else {
          console.log("Form is not valid");
          alert("required not in")
          }
      },
      openFileDialog() {
          this.$refs.fileInput.click()
      },
      handleFileUpload(event) {
          const file = event.target.files[0]
          console.log('file:', file)


          if (file) {
              const reader = new FileReader()
              console.log('reader:', reader)
              reader.onload = () => {
                  const fileData = reader.result
                  const workbook = XLSX.read(fileData, { type: 'binary' })
                  const sheetName = workbook.SheetNames[0]
                  const sheet = workbook.Sheets[sheetName]
                  const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 })
                  this.headerFields = rows[0]
                  this.formData.excelfile = file.name
              };
              reader.readAsBinaryString(file)
          }
      },
      handlePhoneSelection(option) {
          this.selectedPhoneNumberField = option
          this.formData.selectedPhoneNumberField = this.headerFields.indexOf(option)
      },
      handleParmSelection1(option) {
          this.selectedParamField1 = option
          this.formData.selectedParamField1 = this.headerFields.indexOf(option)
      },
      handleParmSelection2(option) {
          this.selectedParamField2 = option
          this.formData.selectedParamField2 = this.headerFields.indexOf(option)
      },
      handleParmSelection3(option) {
          this.selectedParamField3 = option
          this.formData.selectedParamField3 = this.headerFields.indexOf(option)
      }
  }
}
</script>
<style>
.icon-large {
  font-size: 204px;
}

.icon-hover {
  transition: transform 0.2s ease-in-out;
}

.icon-hover:hover {
  transform: scale(1.4);
}</style>