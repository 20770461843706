<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <div v-if="isRegisterSuccess">
      <material-alert icon="ni ni-like-2 ni-lg" dismissible>
        사용자 등록 완료
      </material-alert>
    </div>
    <div v-if="isRegisterFail">
      <material-alert color="danger" icon="ni ni-ungroup ni-lg" dismissible>
        {{ registerFailMessage }}
      </material-alert>
    </div>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-12">
          <material-input-vmodel
              id="userId"
              v-model="userId"
              label="ID"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <material-input-vmodel
              id=""
              v-model="password"
              type="password"
              label="비밀번호"
              :autocomplete=false
              v-bind="passwordStateAttribute"
              @input="validatePassword"
          />
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <material-input-vmodel
              id="confirmPass"
              v-model="confirmPassword"
              type="password"
              label="비밀번호 확인"
              :autocomplete=false
              v-bind="passwordConfirmStateAttribute"
              @input="validatePassword"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-12">
          <material-input-vmodel
              id="userName"
              v-model="userName"
              label="성함"
              :autocomplete=false
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-12 mt-3 mt-sm-0">
          <material-input-vmodel
            id="email"
            v-model="email"
            type="email"
            label="Email"
            v-bind="emailStateAttribute"
            @input="validateEmail"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-12 mt-3 mt-sm-0">
          <material-input-vmodel
              id="phoneNumber"
              v-model="phoneNumber"
              label="휴대전화"
          />
        </div>
      </div>
<!--      <div class="row mt-3 text-end">-->
<!--        <material-input-vmodel-->
<!--          id="subTenant"-->
<!--          v-model="subTenantName"-->
<!--          :disabled="!isSubTenant"-->
<!--          label="부서 명"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="row text-end">-->
<!--        <div class="col-12 col-sm-12 mt-3 mt-sm-0">-->
<!--          <material-checkbox-->
<!--            id="isSubTenant"-->
<!--            v-model="isSubTenant">-->
<!--            부서 추가-->
<!--          </material-checkbox>-->
<!--        </div>-->
<!--      </div>-->
      <h5 class="mt-5">비밀번호 생성 규칙</h5>
      <p class="text-muted mb-2">
        보안을 위하여 아래 비밀번호 규칙에 따라 생성해주세요.
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">8자리 이상 길이</span>
        </li>
        <li>
          <span class="text-sm">최소 1개의 숫자 포함</span>
        </li>
        <li>
          <span class="text-sm">최소 1개의 영문 소문자 포함</span>
        </li>
      </ul>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          :disabled="!(isPasswordValid&&isEmailValid&&isPasswordConfirmValid)"
          @click="registerParty"
          >Register</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInputVmodel from "@/components/MaterialInputVmodel.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import { Auth } from 'aws-amplify'
import {useRouter} from "vue-router";
import router from "@/router";
import MaterialAlert from "@/components/MaterialAlert.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import ApiCall from "@/Interface/ApiCall";

export default {
  name: "UserInfo",
  components: {
    // MaterialCheckbox,
    MaterialAlert,
    MaterialInputVmodel,
    MaterialButton,
    // PasswordInput,
  },
  data() {
    return {
      userId: '',
      password: '',
      confirmPassword: '',
      userName: '',
      email: '',
      phoneNumber: '',
      isPasswordValid: false,
      isPasswordConfirmValid: false,
      isEmailValid: false,
      emailStateAttribute: {
      },
      passwordStateAttribute: {
      },
      passwordConfirmStateAttribute: {
      },
      isRegisterSuccess: false,
      isRegisterFail: false,
      registerFailMessage: '',
      subTenantName: '',
      isSubTenant: false,
    };
  },
  created() {
    if (ApiCall.vdiDomainCheck()) {
      Auth.configure({
        endpoint: process.env.VUE_APP_COGNITO_URL_PROXY
      });
    }
  },
  methods: {
    validatePhoneNumber() {
      let sanitizedNumber = ''
      // Remove special characters
      if (this.phoneNumber.startsWith('01')) {
        sanitizedNumber = this.phoneNumber.replace(/[^\d]/g, '');
        this.phoneNumber = '+82' + sanitizedNumber.substring(1);
      } else if (this.phoneNumber.startsWith('+82')) {
        sanitizedNumber = '+' + this.phoneNumber.replace(/[^\d]/g, '');
      } else {
        throw new Error("User phone number validation error.");
      }
    },
    validatePassword() {
      const minLength = 8;
      const containsLowercase = /[a-z]/;
      const containsNumber = /\d/;

      const isValidLength = this.password.length >= minLength;
      const hasLowercase = containsLowercase.test(this.password);
      const hasNumber = containsNumber.test(this.password);
      this.isPasswordConfirmValid = this.password === this.confirmPassword;
      this.isPasswordValid = isValidLength && hasLowercase && hasNumber;


      if (this.isPasswordValid) {
        this.passwordStateAttribute = { success: '' }
      } else {
        this.passwordStateAttribute = { error: '' }
      }
      if (this.isPasswordConfirmValid) {
        this.passwordConfirmStateAttribute = { success: '' }
      } else {
        this.passwordConfirmStateAttribute = { error: '' }
      }
    },
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.isEmailValid = re.test(this.email.toLowerCase())

      if (this.isEmailValid) {
        console.log('email valid success.')
        this.emailStateAttribute = { success: '' }
      } else {
        console.log('email valid error.')
        this.emailStateAttribute = { error: '' }
      }
    },
    async registerParty() {
      this.validatePhoneNumber()

      console.log('id :', this.userId)
      console.log('password :', this.password)
      console.log('confirmPassword :', this.confirmPassword)
      console.log('name :', this.userName)
      console.log('email :', this.email)
      console.log('all condition : ', this.isPasswordConfirmValid&&this.isPasswordValid&&this.isEmailValid)

      let currentUser

      try {
        currentUser = await Auth.currentAuthenticatedUser()
      } catch (error) {
        console.error('Error fetching user information:', error)
      }

      const username = this.userId
      const name = this.userName
      const password = this.password
      const email = this.email
      const phone_number = this.phoneNumber
      console.log("call registerParty")
      const signupMethod = this.isSubTenant ? 'subTenant' : 'registerParty'

      try {
        console.log("user group id :", currentUser)
        const groups = currentUser.signInUserSession.idToken.payload['cognito:groups'] || []
        const tenant_name = this.isSubTenant ? this.subTenantName : currentUser.attributes['custom:tenant_name']
        const isBusinessVerified = currentUser.attributes['custom:isBusinessVerified']
        console.log("user groups : ", groups)

        await Auth.signUp({
          username,
          password,
          attributes: {
            email,
            phone_number,
            name,
            'custom:tenant_id': groups[0],
            'custom:tenant_name': tenant_name,
            'custom:isAdmin': '0',
            'custom:creator': currentUser.username,
            'custom:signup_method': signupMethod,
            'custom:isBusinessVerified': isBusinessVerified,
          },
          autoSignIn: {
            enabled: false,
          },
        }).then(res => {
          // console.log('cognito signup result :' + res)

          // admin confirm 요청
          const payload = {
            user_id : username,
          };
          ApiCall.call('user/confirmsignup', 'POST', payload).then(res => {
            console.log(res)
          });

          this.isRegisterSuccess = true
          this.isRegisterFail = false
          this.userId = ''
          this.name = ''
          this.password = ''
          this.confirmPassword = ''
          this.email = ''
          this.phoneNumber = ''
        })


      } catch (e) {
        console.error('error signup : ', e)
        switch (e.name) {
          case 'UserLambdaValidationException':
            this.emailStateAttribute = { error: '' }
            this.registerFailMessage = '가입가능한 이메일이 아닙니다.'
            break;
          case 'UsernameExistsException':
            this.userIdStateAttribute = { error: '' }
            this.registerFailMessage = '이미 사용중인 아이디 입니다.'
            break;
          case 'InvalidParameterException':
            this.userIdStateAttribute = { error: '' }
            this.registerFailMessage = '입력값에 허용되지 않는 문자가 있습니다.'
            break;
          case 'ForbiddenException':
            this.userIdStateAttribute = { error: '' }
            this.registerFailMessage = '방화벽에 문제가 있습니다. 관리자에게 문의해 주세요.'
            break;
          case 'InvalidEmailRoleAccessPolicyException':
            this.userIdStateAttribute = { error: '' }
            this.registerFailMessage = '권한이 허용되지 않은 Email 입니다.'
            break;
          case 'InvalidPasswordException':
            this.userIdStateAttribute = { error: '' }
            this.registerFailMessage = '허용되지 않는 비밀번호 입니다.'
            break;
          case 'InvalidSmsRoleTrustRelationshipException':
          case 'InvalidSmsRoleAccessPolicyException':
            this.userIdStateAttribute = { error: '' }
            this.registerFailMessage = '허용되지 않은 SMS 권한입니다.'
            break;
          case 'NotAuthorizedException':
            this.userIdStateAttribute = { error: '' }
            this.registerFailMessage = '권한이 없는 요청입니다.'
            break;
          case 'ResourceNotFoundException':
            this.userIdStateAttribute = { error: '' }
            this.registerFailMessage = '사용할 수 없는 Resource입니다.'
            break;
          case 'TooManyRequestsException':
            this.userIdStateAttribute = { error: '' }
            this.registerFailMessage = '너무 많은 요청이 발생하였습니다. 잠시 후 시도해주세요.'
            break;
          case 'InternalErrorException':
          case 'InvalidLambdaResponseException':
          case 'UnexpectedLambdaException':
          default:
            this.registerFailMessage = '사용자 등록중 알수 없는 오류가 발생하였습니다. 관리자에게 문의해주세요.'
        }
        this.isRegisterFail = true
        this.isRegisterSuccess = false
      }
    },
  },
};
</script>
