<template>
    <div class="container-fluid mt-4">
        <div class="row align-items-center">
            <div class="col-lg-4 col-sm-8">
                <SendNumberNavPill @selectForm="selectedForm = $event" />
            </div>
        </div>
        <div class="row mb-5">
            <RegisterSendNumber v-if="selectedForm === 'register'" />
            <SearchSendNumber v-if="selectedForm === 'search'" />    
        </div>
    </div>
</template>

<script>
import SendNumberNavPill from "./components/SendNumberNavPill.vue";
import RegisterSendNumber from "./components/RegisterSendNumber.vue";
import SearchSendNumber from "./components/SearchSendNumber.vue";


export default {
    name: "SendNumber",
    components: {
        RegisterSendNumber,
        SearchSendNumber,
        SendNumberNavPill,        
    },
    data() {
        return {
            selectedForm: "register", // default selected form
        };
    },
};
</script>