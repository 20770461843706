<template>
  <div class="container-fluid mt-4">
    <div class="row mb-5">
      <div class="col-lg-12 mt-lg-0 mt-4">
        <profile-item />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileItem from "./components/ProfileItem.vue";

export default {
  name: "Profile",
  components: {
    ProfileItem,
  },
};
</script>
