<template>
  <head>
    <!-- <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-icons.css"> -->
    <!-- <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/cleo-svg.css"> -->
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/soft-ui-dashboard.min.css?v=1.0.2">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700">
    <link rel="stylesheet" href="./assets/css/theme.css">
  </head>
  <!-- <navbar btn-background="bg-gradient-success" /> -->
  <div class="container position-sticky z-index-sticky top-0">
        <div class="row">
            <div class="col-12">
                <nav class="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
                  <div class="container-fluid">
                        <!-- <a class="navbar-brand font-weight-bolder ms-sm-3" href="javascript:;" rel="tooltip" title="Designed and Coded by Creative Tim" data-placement="bottom">Message To-Go</a> -->
                        <a class="navbar-brand ms-sm-3" href="../../home">
                          <!-- <img src="@/assets/img/m2g-logo-only.png" alt="Logo" height="30"> -->
                          <!-- <img src="../assets/img/m2g-logo-only.png" alt="Logo" height="30"> -->
                          <img src="@/assets/img/SK_logo.svg" alt="Your SVG Image" style="position: relative; width: 30%; height: 20%; margin-top: -10px; margin-bottom: -10px; margin-right: 10px; margin-left: -10px;">
                          <!-- <span class="font-weight-bolder"> Message To-Go</span>                           -->
                        </a>
                        <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon mt-2">
                                <span class="navbar-toggler-bar bar1"></span>
                                <span class="navbar-toggler-bar bar2"></span>
                                <span class="navbar-toggler-bar bar3"></span>
                            </span>
                        </button>
                        <div id="navigation" class="collapse navbar-collapse pt-3 pb-2 py-lg-0">
                            <ul class="navbar-nav navbar-nav-hover ms-lg-12 ps-lg-5 w-100">
                                <li class="nav-item">
                                  <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="../../home#channels" aria-expanded="false">
                                    <span class="font-weight-bolder" style="font-size: larger;">채널소개</span>
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="../../home#features" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">기능</span>
                                  </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="../../home#product" aria-expanded="false">    
                                        <span class="font-weight-bolder" style="font-size: larger;">요금제</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/contactus" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">문의하기</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" :to="`/home/news`" aria-expanded="false">
                                    <span class="font-weight-bolder" style="font-size: larger;">NEWS/소식</span>
                                  </router-link>
                                </li>
                                <li class="nav-item ms-lg-auto my-auto ms-3 ms-lg-0">
                                  <router-link class="btn btn-sm  bg-gradient-primary  btn-round mb-0 me-1 mt-2 mt-md-0" :to="`/authentication/signin/basic`" style="font-size: medium;">
                                    회원가입/로그인
                                  </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
  <div
    class="page-header align-items-start min-vh-100"
    style="
      background-image: url('https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80');
    "
  >
    <span class="mask opacity-6"></span>
    <div class="container my-auto">
      <div class="row mt-lg-n12 mt-md-n12 mt-n11 justify-content-center">
        <div class="col-xl-8 col-lg-5 col-md-7 mx-auto">
          <div
            v-if="isNiceAuthenticated"
            class="card mt-12">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                  class="bg-gradient-primary shadow-success border-radius-lg py-3 pe-1 text-center py-4"
              >
                <h4 class="font-weight-bolder text-white mt-1">
                  회원가입
                </h4>
                <p class="mb-1 text-white text-sm">
                  회원가입을 위해 아래 항목을 입력해주세요.
                </p>
                <p class="mb-1 text-white text-sm">
                   - 비밀번호 생성규칙 : 8자 이상, 1개이상의 숫자와 알파벳 포함
                </p>
              </div>
            </div>
            <div class="card-body pb-3 px-4">
<!--              <form role="form">-->
                <div class="mb-3">
                  <material-input
                    id="userId"
                    v-model="userId"
                    v-bind="userIdStateAttribute"
                    label="아이디"
                    @input="validateUserId"
                  />
                </div>
                <div class="row mb-3">
                  <div class="col-6">
                    <material-input
                        id="pass"
                        v-model="password"
                        type="password"
                        label="비밀번호"
                        v-bind="passwordStateAttribute"
                        @input="validatePassword"
                    />
                  </div>
                  <div class="col-6">
                    <material-input
                        id="confirmPass"
                        v-model="confirmPassword"
                        type="password"
                        label="비밀번호 재확인"
                        v-bind="passwordConfirmStateAttribute"
                        @input="validatePassword"
                    />
                  </div>
                </div>
                <div class="mb-3">
                  <material-input
                    id="name"
                    v-model="userName"
                    v-bind="nameStateAttribute"
                    disabled="true"
                  />
                </div>
                <div class="mb-3">
                  <material-input
                      id="email"
                      v-model="email"
                      type="email"
                      label="Email"
                      v-bind="emailStateAttribute"
                      @input="validateEmail"
                  />
                </div>
                <div class="row mb-3">
                  <div class="col-12">
                    <material-input
                      id="phone"
                      v-model="phoneNumber"
                      v-bind="phoneNumberStateAttribute"
                      disabled="true"
                    />
                  </div>
                </div>
                <div class="mb-1">
                  <material-input id="phone" v-model="tenantName" label="기업명" :disabled="!isEnterpriseChecked"/>
                </div>
                <div v-if="inputTenantName" class="row">
                  <div class="text-sm text-danger">
                    사업자만 사용이 가능하며, 개인고객은 사용이 불가합니다.
                  </div>
                  <div class="text-sm text-warning">
                    이미 가입된 기업회원의 추가 사용자 가입의 경우 관리자에게 사용자 추가요청을 해야합니다.<br>
                    신규가입으로 생성 시 별도 기업으로 관리됩니다.
                  </div>
                </div>
                <div class="text-center mb-1" style="display: flex; flex-direction: row;">
                  <material-checkbox
                      id="flexCheckDefault"
                      v-model="isTermsAgree"
                      class="font-weight-light"
                      style="margin-right: 15px;"
                  >
                    <router-link
                        :to="{ name: 'Terms' }"
                        target="_blank"
                        class="text-success font-weight-bold"
                    >이용약관</router-link>
                    동의
                  </material-checkbox>
                  <material-checkbox
                      id="flexCheckDefault"
                      v-model="isInformationUse"
                      class="font-weight-light"
                  >
                    <router-link
                        :to="{ name: 'InformationUse' }"
                        target="_blank"
                        class="text-success font-weight-bold"
                    >개인정보 수집 및 이용</router-link>
                    동의
                  </material-checkbox>
                </div>
                <div class="text-center mb-1" style="display: flex; flex-direction: row;">
                 <material-checkbox
                   id="flexCheckDefault"
                   v-model="isOver14Age"
                   class="font-weight-light"
                   style="margin-right: 15px;"
                 >
                   만 14세 이상입니다
                 </material-checkbox>
                </div>
                <div class="text-center">
                  <material-button-progress
                      color="primary"
                      variant="gradient"
                      full-width
                      class="mt-0 mb-0"
                      :disabled="!(isPasswordValid&&isEmailValid&&isPasswordConfirmValid&&isTermsAgree&&isInformationUse&&userId&&tenantName&&phoneNumber&&userName&&isOver14Age)"
                      :is-loading="isSignUpLoading"
                      @click="signUp"
                  >회원가입</material-button-progress>
                </div>
<!--              </form>-->
            </div>
            <div class="card-footer text-center pt-0 px-sm-4 px-1">
              <p class="mb-4 mx-auto">
                이미 가입하셨나요?
                <router-link
                    :to="{ name: 'Signin Basic' }"
                    class="text-primary text-gradient font-weight-bold"
                >로그인</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-10 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              ©
          {{ new Date().getFullYear() }}, made
          <!-- <i class="fa fa-heart"></i>  -->
          by
          <a
            href="https://www.sk.com"
            class="font-weight-bold"
            target="_blank"
            >SK 주식회사 C&C</a
          >
            </div>
            <span style="font-size: 0.8rem; margin-right: 0.5rem;">
          SK(주) | 대표이사: 장용호, 최태원 | 경기도 성남시 분당구 성남대로 343번길 9 | 사업자등록번호 783-85-00169 | 통신판매업신고 2015-경기성남-1388 <br> COPYRIGHT SK HOLDINGS CO., LTD. ALL RIGHTS RESERVED.
          </span>
          </div>
          <div class="col-12 col-md-2">
            <ul
                      class="nav nav-footer"
                    >
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 이용 약관_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >이용약관</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 수준 약정서(SLA)_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >서비스수준(SLA)</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/home/privacy"
                          class="nav-link pe-0 text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 1rem;"
                          >개인정보처리방침</a
                        >
                      </li>
                    </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";

// amplify
import {Hub, I18n, Auth} from 'aws-amplify'
import {
  Authenticator,
  AuthenticatorSignUpFormFields,
  AmplifyCheckBox,
  AmplifyTextField,
  useAuthenticator,
} from '@aws-amplify/ui-vue'
import '@aws-amplify/ui-vue/styles.css'
import '/src/assets/css/styles.css'
import {useRouter} from "vue-router";
import {onUnmounted, onBeforeCreate} from "vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import { toRefs } from 'vue';
import ko from '../../../locales/ko.json';
import { translations } from '@aws-amplify/ui-vue';
import {isValidDate} from "@fullcalendar/core";
import ApiCall from "@/Interface/ApiCall";
import CryptoJS from 'crypto-js';
import base64js from 'base64-js';
import Base64 from 'crypto-js/enc-base64';
import { SecretKey } from 'crypto-js';
import { HmacSHA256, enc } from 'crypto-js';
import Cookies from "js-cookie";
import MaterialButtonProgress from "@/components/MaterialButtonProgress.vue";

export default {
  name: "SignupBasic",
  components: {
    MaterialCheckbox,
    MaterialInput,
    // MaterialButton,
    MaterialButtonProgress,
  },
  setup() {
    const router = useRouter()

  },
  created() {
    if (ApiCall.vdiDomainCheck()) {
      Auth.configure({
        endpoint: process.env.VUE_APP_COGNITO_URL_PROXY
      });
    }

    Auth.currentAuthenticatedUser().then(user => {
      this.userName = user.attributes.name // Set the user ID as the dynamicMessage
      const router = this.$router
      router.push('/dashboard')
    }).catch(error => {
      // console.log('Error fetching user information:', error)
    })
  },
  beforeMount() {
    // console.log("nice return url:", process.env.VUE_APP_NICE_RETURN_URL)
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    window.addEventListener('message', this.handleChildMessage);
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    window.removeEventListener('message', this.handleChildMessage);
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    getQueryString() {
      return window.location.search;
    },
    parseQueryString(queryString) {
      // Initialize URLSearchParams with the queryString
      const searchParams = new URLSearchParams(queryString);

      // Initialize an empty object to store the parsed query
      const parsedQuery = {};

      // Iterate through the searchParams and convert it to an object
      for (const [key, value] of searchParams.entries()) {
        parsedQuery[key] = value;
      }

      // Set the parsed query in the data
      return parsedQuery;
    },
    handleChildMessage(event) {
      // Check if the message is coming from the child window
      if (event.origin !== window.location.origin) return;

      const queryString = event.data; // Retrieve the query string parameter
      // Do something with the query string parameter
      // console.log("queryString from child window :", queryString);
      const params = Object.fromEntries(new URLSearchParams(queryString));

      // console.log("queryString object :", params)

      if ('resultcode' in params) {
        //인증 후 진입
        if (params.resultcode === '0000') {
          // 인증처리 정상 진행
          this.userName = params.name;
          this.phoneNumber = params.mobileno;

          // 기존 입력했던 값들 처리
          const receiveData = JSON.parse(params.receivedata);
          this.userId = receiveData.userId;
          this.password = receiveData.password;
          this.confirmPassword = receiveData.confirmPassword;
          this.tenantName = receiveData.tenantName;
          this.email = receiveData.email;

          this.validatePassword();
          this.validatePhoneNumber();
          this.validateEmail();
          this.validateUserId();

          this.isNiceAuthenticated = true;
        } else {
          //인증오류
        }
      }
    },
    validatePhoneNumber() {
      let sanitizedNumber = ''
      // Remove special characters
      if (this.phoneNumber.startsWith('01')) {
        sanitizedNumber = this.phoneNumber.replace(/[^\d]/g, '');
        this.phoneNumber = '+82' + sanitizedNumber.substring(1);
      } else if (this.phoneNumber.startsWith('+82')) {
        sanitizedNumber = '+' + this.phoneNumber.replace(/[^\d]/g, '');
      } else {
        throw new Error("User phone number validation error.");
      }
    },
    validatePassword() {
      const minLength = 8;
      const containsLowercase = /[a-z]/;
      const containsNumber = /\d/;

      const isValidLength = this.password.length >= minLength;
      const hasLowercase = containsLowercase.test(this.password);
      const hasNumber = containsNumber.test(this.password);
      this.isPasswordConfirmValid = this.password === this.confirmPassword;
      this.isPasswordValid = isValidLength && hasLowercase && hasNumber;

      if (this.isPasswordValid) {
        this.passwordStateAttribute = { success: '' }
      } else {
        this.passwordStateAttribute = { error: '' }
      }
      if (this.isPasswordConfirmValid) {
        this.passwordConfirmStateAttribute = { success: '' }
      } else {
        this.passwordConfirmStateAttribute = { error: '' }
      }
    },
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.isEmailValid = re.test(this.email.toLowerCase())

      if (this.isEmailValid) {
        // console.log('email valid success.')
        this.emailStateAttribute = { success: '' }
      } else {
        // console.log('email valid error.')
        this.emailStateAttribute = { error: '' }
      }
    },
    validateUserId() {
      if (this.userId) {
        this.userIdStateAttribute = { success: ''}
      } else {
        this.userIdStateAttribute = { error: ''}
      }
    },
    async signUp() {
      this.isSignUpLoading = true;
      // console.log('id :', this.userId)
      // console.log('password :', this.password)
      // console.log('confirmPassword :', this.confirmPassword)
      // console.log('name :', this.userName)
      // console.log('email :', this.email)
      // console.log('isEnterpriseChecked : ', this.isEnterpriseChecked)
      // console.log('all condition : ', this.isPasswordConfirmValid&&this.isPasswordValid&&this.isEmailValid)

      this.validatePhoneNumber()
      // console.log('phone_number :', this.phoneNumber)

      // let currentUser
      //
      // try {
      //   currentUser = await Auth.currentAuthenticatedUser()
      // } catch (error) {
      //   console.error('Error fetching user information:', error)
      // }

      const username = this.userId
      const name = this.userName
      const password = this.password
      const email = this.email
      const phone_number = this.phoneNumber
      const tenant_name = this.tenantName
      // console.log("call registerParty")
      try {
        await Auth.signUp({
          username,
          password,
          attributes: {
            email,
            phone_number,
            name,
            'custom:tenant_name': tenant_name,
            'custom:signup_method': 'selfSignUp',
            'custom:isBusinessVerified': '0',
          },
          autoSignIn: {
            enabled: false,
          },
        }).then(res => {
          console.log(res)

          // UTM 정보 저장
          const savedUtm = Cookies.get('m2g-utm');

          let utmInformation = {}
          if (savedUtm !== undefined) {
            utmInformation = JSON.parse(savedUtm);
          }

          const seoulTimezone = 'Asia/Seoul';
          const currentDateTime = new Date().toLocaleString('en-US', { timeZone: seoulTimezone });

          // Add UTM value
          utmInformation['userId'] = this.userId
          utmInformation['userName'] = this.userName
          utmInformation['email'] = this.email
          utmInformation['phoneNumber'] = this.phoneNumber
          utmInformation['tenantName'] = this.tenantName
          utmInformation['date'] = currentDateTime

          ApiCall.callNoAuth('analytics/utm', 'POST', utmInformation).then((utm_res) => {
            console.log(utm_res)

            this.isRegisterSuccess = true
            this.userId = ''
            this.userName = ''
            this.password = ''
            this.confirmPassword = ''
            this.email = ''
            this.phoneNumber = ''
            this.isSignUpLoading = false;

            this.$router.push('/authentication/signup/done')
          });
        })
      } catch (e) {
        console.error('error signup : ', e)
        switch (e.name) {
          case 'UserLambdaValidationException':
            this.emailStateAttribute = { error: '' }
            alert('가입가능한 이메일이 아닙니다.')
            break;
          case 'UsernameExistsException':
            this.userIdStateAttribute = { error: '' }
            alert('이미 사용중인 아이디입니다.')
            break;
          case 'InvalidParameterException':
            this.userIdStateAttribute = { error: '' }
            alert('입력값에 허용되지 않는 문자가 있습니다.')
            break;
          case 'ForbiddenException':
            this.userIdStateAttribute = { error: '' }
            alert('방화벽에 문제가 있습니다. 관리자에게 문의해 주세요.')
            break;
          case 'InvalidEmailRoleAccessPolicyException':
            this.userIdStateAttribute = { error: '' }
            alert('권한이 허용되지 않은 Email 입니다.')
            break;
          case 'InvalidPasswordException':
            this.userIdStateAttribute = { error: '' }
            alert('허용되지 않는 비밀번호 입니다.')
            break;
          case 'InvalidSmsRoleTrustRelationshipException':
          case 'InvalidSmsRoleAccessPolicyException':
            this.userIdStateAttribute = { error: '' }
            alert('허용되지 않은 SMS 권한입니다.')
            break;
          case 'NotAuthorizedException':
            this.userIdStateAttribute = { error: '' }
            alert('권한이 없는 요청입니다.')
            break;
          case 'ResourceNotFoundException':
            this.userIdStateAttribute = { error: '' }
            alert('사용할 수 없는 Resource입니다.')
            break;
          case 'TooManyRequestsException':
            this.userIdStateAttribute = { error: '' }
            alert('너무 많은 요청이 발생하였습니다. 잠시 후 시도해주세요.')
            break;
          case 'InternalErrorException':
          case 'InvalidLambdaResponseException':
          case 'UnexpectedLambdaException':
          default:
            console.error('가입중 알수 없는 오류가 발생했습니다.', e)
            alert('가입중 알수 없는 오류가 발생했습니다. 콘솔창 에러를 확인 후 관리자에게 문의 부탁드립니다.')
        }
        this.isRegisterFail = true
        this.isSignUpLoading = false;
      }
    },
    async issuNiceCryptoKey() {
      const signUpInfo = {
        'userId': this.userId,
        'password': this.password,
        'confirmPassword': this.confirmPassword,
        'tenantName': this.tenantName,
        'email': this.email,
      }

      const res = await ApiCall.callNoAuth('user/nice/token', 'POST', signUpInfo);
      // console.log("enc data", res);

      // document.form.submit();
      document.form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
      document.form.token_version_id.value = res.token_version_id;
      document.form.enc_data.value = res.enc_data;
      document.form.integrity_value.value = res.integrity_value;
      document.form.target='niceForm';

      const left = screen.width / 2 - 500 / 2;
      const top = screen.height / 2 - 800 / 2;
      const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;

      let niceAuthWindow = window.open(
        'about:blank',
        'niceForm',
        option
      )
      document.form.submit();

      // niceAuthWindow.addEventListener('hashchange', function(){
      //   var response =
      // });
    },
    // hmac256(secretKey, message) {
    //   try {
    //     const hmac256 = HmacSHA256(message, secretKey);
    //     return hmac256.toString(enc.Base64);
    //   } catch (error) {
    //     throw new Error('Failed to generate HMACSHA256 encrypt');
    //   }
    // }
  },
  data() {
    return {
      userId: '',
      password: '',
      confirmPassword: '',
      userName: '',
      tenantName: '',
      email: '',
      phoneNumber: '',
      isEnterpriseChecked: true,
      isValid: false,
      isPasswordValid: false,
      isPasswordConfirmValid: false,
      isEmailValid: false,
      isTermsAgree: false,
      isOver14Age: false,
      isInformationUse: false,
      isUserNameValid: false,
      isPhoneNumberValid: false,
      emailStateAttribute: {
      },
      passwordStateAttribute: {
      },
      passwordConfirmStateAttribute: {
      },
      nameStateAttribute: {
      },
      phoneNumberStateAttribute: {
      },
      userIdStateAttribute: {
      },
      niceReqDtim: '',
      niceReqNo: '',
      niceKey: '',
      niceIv: '',
      niceHmacKey: '',
      niceReqData: {
        'requestno': '',
        'returnurl': '',
        'sitecode': '',
        // 'authtype': '',
        // 'mobilceco': '',
        // 'businessno': '',
        'methodtype': '',
        // 'popupyn': '',
        'receivedata': '',
      },
      // http://localhost:8080/authentication/signup/basic?token_version_id=202307012033558A-NCH2CB568-96457-HD1AC8FCE6&enc_data=3WJnAPnbNWy2fbwRKSxDeONg0WJvgAimmnFs3hla8HuvQ%2B4e1pc4HLchpyDcfOhbduazEL7xuhNkB5znaBUPPaM5s9tk9H9872%2BejNmLeyiWwDfzwuODsLiXL1jtZiF7FMOYXh1%2BV2XzO3yQDqL2s9DcKyYmxiM87eEeTJsIa/ivZwB2%2BGX5eHl7Qx4v6A%2B7pKW5RYOewIc5qaYebHMNBE8bxHOunRFfqlOp21N5Gt56F5oLjHoIYtI0WkQTWAlLWJxEdzzsAk/sVGRN8JlpPydaRb3ZBarEsT2g3BfE82%2BS8s6R0MyzVUixJgZNjUk%2BvMz8i6VX8U43hofkW8S/WHUm5%2B7Op%2BDf4VRhJRwBOpjzT7K3uCnxZiqgRCPhpWd/WGpA9lN/1PPL%2BVZVGp00Dud4chXfBDbJ3dsdoFhSlSzk1ztyM2HymlWQUDTh6ts31VyDHCtS9h5FvTQmTsplK0Vbtv32SYo2fJ3z7H7Sf4z85ueAaTVCeAVNxDdhU3QnmVSWs6845fj5eUCz1S/Ek5VYGfpQzDwhjIlCHceVCR8FIdQZ/dW5Rd/gkoJ1FRvA&integrity_value=NONXH0YF0IkmteM/DPi75U/rtPIZhwgjTYL/D5Ov/dQ=
      niceReturnData: {
        token_version_id: '',
        enc_data: '',
        integrity_value: '',
      },
      niceDecData: {},
      isNiceAuthenticated: false,
      isSignUpLoading: false,
    }
  },
  computed: {
    inputTenantName() {
      if (this.tenantName.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
</style>
