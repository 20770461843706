<template>
  <div class="row">
    <div class="col-5">
      <material-input
        id="start-between"
        v-model="startValue"
        type="number"
      >
        시작
      </material-input>
    </div>
    <div class="col-5">
      <material-input
        id="end-between"
        v-model="endValue"
        type="number"
      >
        끝
      </material-input>
    </div>
    <div class="col-2 px-0 text-end">
      <material-button
        class="px-2"
        @click="handleSet"
      >
        선택
      </material-button>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import setMaterialInput from "@/assets/js/material-input.js";
import MaterialButton from "@/components/MaterialButtonProgress.vue";

export default {
  name: "MaterialInputBetween",
  components: {
    MaterialButton,
    MaterialInput,
  },
  props: {
    segmentName : {
      type: String,
      required: true,
    },
  },
  emits: ['changed'],
  data() {
    return {
      startValue : '',
      endValue: '',
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    handleSet(index) {
      const inputValue = [
        { segment_name: this.segmentName, detail_name: this.startValue, },
        { segment_name: this.segmentName, detail_name : this.endValue, },
      ]
      console.log("inputValue from component : " + JSON.stringify(inputValue))
      this.$emit('changed', { index, value : inputValue});
    }
  },
};
</script>
