<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div v-if="campaignInfo.send_type === 'mms'">
        <div class="row">
          <div class="col-6">
            <h6 class="font-weight-bolder mb-4 mt-2">🎨 이미지</h6>
          </div>
          <div class="col-6 text-end">
            <material-button
              v-if="!editImage"
              variant="outline"
              size="sm"
              color="secondary"
              @click="editImage = true"
            > 수정하기
            </material-button>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <p class="text-sm font-weight-bold">이미지를 수정할 수 있습니다.</p>
          </div>
        </div>
        <div v-if="!editImage" class="image-preview">
          <img :src="result.imagePreview || src" alt="" style="height: 400px"/>
        </div>
        <div v-if="editImage" class="row">
          <div style="height: 600px">
            <PinturaEditor
              v-bind="editorDefaults"
              :src="src"
              :image-state="result.imageState"
              :stickers="stickers"
              @pintura:load="handleLoad($event)"
              @pintura:process="handleProcess($event)"
            ></PinturaEditor>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-6">
          <h6 class="font-weight-bolder mt-2">💬 텍스트</h6>
        </div>
        <div class="col-6 text-end">
          <material-button
            v-if="!editText"
            variant="outline"
            size="sm"
            color="secondary"
            @click="editText = true"
          > 수정하기
          </material-button>
          <material-button
            v-if="editText"
            variant="outline"
            size="sm"
            color="secondary"
            @click="handleEditTextFalse"
          > 수정완료
          </material-button>
        </div>
      </div>
      <div class="row mt-2">
        <p class="text-sm font-weight-bold">생성한 텍스트를 원하는대로 수정할 수 있습니다.</p>
      </div>
      <div class="row">
        <div class="col-12">
          <material-input-vmodel
            id="sent-title-edit"
            :disabled="!editText"
            :model-value="campaignInfo.subject"
            @input="$emit('update:campaignInfo', { ...campaignInfo, subject: $event.target.value })"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <material-text-area
            id="sent-content-edit"
            :rows="20"
            :disabled="!editText"
            :model-value="campaignInfo.sent_content"
            @input="$emit('update:campaignInfo', { ...campaignInfo, sent_content: $event.target.value })"
          />
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="$parent.prevStep"
          >뒤로</material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="handleModifyInfoDone"
          >수정완료</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialTextArea from "@/components/MaterialTextareaCustom.vue";
import MaterialInputVmodel from "@/components/MaterialInputVmodel.vue";

import { PinturaEditor } from '@pqina/vue-pintura';
import { getEditorDefaults } from '@pqina/pintura';
import {
  // editor
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  locale_en_gb,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_filter,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
  plugin_sticker,
  plugin_sticker_locale_en_gb,
} from "@pqina/pintura";
import "@pqina/pintura/pintura.css";

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

export default {
  name: "ModifyInfo",
  components: {
    MaterialButton,
    MaterialTextArea,
    PinturaEditor,
    MaterialInputVmodel,
  },
  props: {
    campaignInfo: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:campaignInfo'],
  data() {
    return {
      editorDefaults: getEditorDefaults(),
      props: {
        utils: ["crop", "finetune", "filter", "annotate", "stickers"],
        imageReader: createDefaultImageReader(),
        imageWriter: createDefaultImageWriter(),
        shapePreprocessor: createDefaultShapePreprocessor(),
        ...plugin_finetune_defaults,
        ...plugin_filter_defaults,
        ...markup_editor_defaults,
        ...plugin_sticker,
        locale: {
          ...locale_en_gb,
          ...plugin_crop_locale_en_gb,
          ...plugin_finetune_locale_en_gb,
          ...plugin_filter_locale_en_gb,
          ...plugin_annotate_locale_en_gb,
          ...markup_editor_locale_en_gb,
          ...plugin_sticker_locale_en_gb,
        },
      },
      stickers: ['🎉', '😄', '👍', '👎', '🍕'],
      src: this.campaignInfo.final_image_url,
      // src: '/image.jpeg',
      result: {
        imageState: undefined,
        imagePreview: undefined,
        imagePreviewFile: undefined,
      },
      editImage: false,
      editText: false,
    }
  },
  watch: {
    campaignInfo(newValue) {
      this.src = newValue.final_image_url;
    },
  },
  methods: {
    handleLoad(event) {
      console.log("load", event.detail);
      console.log("load", event.detail);
    },
    handleProcess(event) {
      console.log("process", event.detail);
      const { imageState, dest } = event.detail;
      this.result = {
        imagePreview: URL.createObjectURL(dest),
        imagePreviewFile: dest,
        imageState: imageState,
      };
      this.editImage = false;
    },
    handleEditTextFalse() {
      this.editText = false
    },
    handleModifyInfoDone() {
      if (this.result.imagePreview != null) {
        this.$emit('update:campaignInfo', { ...this.campaignInfo, final_image_url: this.result.imagePreview, final_image_file: this.result.imagePreviewFile });
      }
      this.$parent.nextStep();
    }
  },
};
</script>

<style>
/* bright / dark mode */
.pintura-editor {
  --color-background: 255, 255, 255;
  --color-foreground: 10, 10, 10;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

@media (prefers-color-scheme: light) {
  html {
    color: #fff;
    background: #111;
  }

  .pintura-editor {
    --color-background: 10, 10, 10;
    --color-foreground: 255, 255, 255;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  }
}

.image-preview {
  border: 1px solid #d2d6da;
  border-radius: 10px; /* Adjust the radius as per your preference */
  overflow: hidden; /* Ensures the image corners are also rounded */
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
</style>
