import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/dashboards/Default.vue";
import CampaignDashboad from "../views/dashboards/CampaignDashboard.vue";
import Analytics from "../views/dashboards/Sales.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Profile from "../views/pages/profile/Profile.vue";
import Projects from "../views/pages/profile/Projects.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct1 from "../views/ecommerce/products/EditProduct.vue";
import EditProduct2 from "../views/ecommerce/products/EditProduct.vue";
import EditProduct3 from "../views/ecommerce/products/EditProduct.vue";
import EditProduct4 from "../views/ecommerce/products/EditProduct.vue";
import ProductPage from "../views/ecommerce/products/ProductPage.vue";
import OrderDetails from "../views/ecommerce/orders/OrderDetails";
import OrderList from "../views/ecommerce/orders/OrderList";
import NewUser from "../views/pages/users/NewUser.vue";
import SendFax from "../views/message/SendFax.vue";
import Settings from "../views/pages/account/Settings.vue";
import Billing from "../views/pages/account/Billing.vue";
import Invoice from "../views/pages/account/Invoice.vue";
import InvoiceList from "@/views/pages/account/InvoiceList.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import Certify from "../views/auth/signup/Certify.vue";

// Message To-Go Start
import SendSms from "../views/message/SendSms.vue";
import SendTestSms from "../views/message/SendTestSms.vue";
import SendKakao from "../views/message/SendKakao.vue";
import ApproveSendNumber from "../views/operator/ApproveSendNumber.vue";
import ApproveTemplate from "../views/operator/ApproveTemplate.vue";

// import GroupHistory from "../views/message/GroupHistory.vue";

import GroupHistory from "../views/manage/GroupHistory.vue";
import SendNumber from "../views/manage/SendNumber.vue";
import SendNumberCertify from "../views/manage/components/SendNumberCertify.vue";



import SendHistory from "../views/applications/SendHistory.vue";
import CampaignHistory from "../views/applications/CampaignHistory.vue";
import TableSample from "@/views/applications/TableSample.vue";
import Product from "../pages/Product.vue";
import HomePage from "../pages/HomePage.vue";
import News from "../pages/News.vue";
import Survey from "../pages/Survey.vue";
import ContactUs from "../pages/ContactUs.vue";
import Event from "../pages/Event.vue";
import Terms from "../pages/Terms.vue";
import Privacy from "../pages/Privacy.vue";
import InformationUse from "../pages/InformationUse.vue";
import Empty from "../views/pages/empty.vue"
import CustomTable from "@/components/CustomTable.vue";
import UserList from "@/views/pages/users/UserList.vue";
// Message To-Go End

// amplify auth
import { Auth } from 'aws-amplify'
import SendHistoryAdmin from "@/views/applications/SendHistoryAdmin.vue";
import PersonalAuthentication from "@/views/auth/signup/PersonalAuthentication.vue";
import SignupDone from "@/views/auth/signup/SignupDone.vue";
import DeleteUserDone from "@/views/auth/signup/DeleteUserDone.vue";
import ContactUsDone from "@/pages/ContactUsDone.vue";
import UtmList from "@/views/operator/UtmList.vue";
import InvoiceListOperator from "@/views/pages/account/InvoiceListOperator.vue";
import KakaoTemplate from "@/views/manage/KakaoTemplate.vue";
import RegisterCampaign from "@/views/pages/marketing/RegisterCampaign.vue";
import ApproveCampaign from "@/views/pages/marketing/ApproveCampaign.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/home",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "/dashboard",
    redirect: "/dashboards/dashboard-default",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboards/dashboard-default",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboards/dashboard-campaign",
    name: "Campaign Dashboard",
    component: CampaignDashboad,
    meta: {
      requiresAuth: true,
    },
  },
// Message To-Go Start
  {
      path: "/home",
      name: "HomePage",
      component: HomePage,
      meta: {
        requiresAuth: false,
      },
  },
  {
    path: "/home/product",
    name: "Product",
    component: Product,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/home/terms",
    name: "Terms",
    component: Terms,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/home/privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/message/sendfax",
    name: "SendFax",
    component: SendFax,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home/informationuse",
    name: "InformationUse",
    component: InformationUse,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/home/contactus",
    name: "ContactUs",
    component: ContactUs,
    meta: {
      requiresAuth: false,
    },
  },
  // {
  //   path: "/home/event",
  //   name: "Event",
  //   component: Event,
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },
  {
    path: "/home/contactus/done",
    name: "ContactUsDone",
    component: ContactUsDone,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/home/survey",
    name: "Survey",
    component: Survey,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/home/news",
    name: "News",
    component: News,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/message/sendsms",
    name: "SendSms",
    component: SendSms,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/message/sendtestsms",
    name: "SendTestSms",
    component: SendTestSms,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/message/sendkakao",
    name: "SendKakao",
    component: SendKakao,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/message/sendhistory',
    name: 'SendHistory',
    component: SendHistory,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/message/campaignhistory',
    name: 'CampaignHistory',
    component: CampaignHistory,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/message/sendhistory/:title/:start',
    name: "SendHistoryWithParams",
    component: SendHistory,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/message/sendhistoryadmin',
    name: 'SendHistoryAdmin',
    component: SendHistoryAdmin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/message/sendhistoryadmin/:title/:start',
    name: "SendHistoryAdminWithParams",
    component: SendHistoryAdmin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/message/sendhistoryadmin/:title/:start/:primary_customer_reference_id',
    name: "SendHistoryAdminWithParamsCampaign",
    component: SendHistoryAdmin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/history/TableSample",
    name: "TableSample",
    component: TableSample,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/marketing/register",
    name: "Register Campaign",
    component: RegisterCampaign,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/marketing/history",
    name: "Campaign History",
    component: CampaignHistory,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manage/grouphistory",
    name: "GroupHistory",
    component: GroupHistory,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manage/kakaotemplate",
    name: "KakaoTemplate",
    component: KakaoTemplate,
    meta: {
      requiresAuth: true,
    },
  },
    {
    path: "/manage/sendnumber",
    name: "SendNumber",
    component: SendNumber,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manage/approvecampaign",
    name: "Approve Campaign",
    component: ApproveCampaign,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/history/customTable",
    name: "CustomTable",
    component: CustomTable,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/empty",
    name: "Empty",
    component: Empty,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/operator/approvesendnumber",
    name: "ApproveSendNumber",
    component: ApproveSendNumber,
    meta: {
      requiresAuth: true,
      requiresOperator: true,
    },
  },
  {
    path: "/operator/approvetemplate",
    name: "ApproveTemplate",
    component: ApproveTemplate,
    meta: {
      requiresAuth: true,
      requiresOperator: true,
    },
  },
// Message To-Go End
  {
    path: "/dashboards/sales",
    name: "Analytics",
    component: Analytics,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pages/rtl-page",
    name: "RTL",
    component: RTL,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/template/edit-product",
    name: "Edit Product1",
    component: EditProduct1,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/template/edit-product",
    name: "Edit Product2",
    component: EditProduct2,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/template/edit-product",
    name: "Edit Product3",
    component: EditProduct3,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/template/edit-product",
    name: "Edit Product4",
    component: EditProduct4,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/register-party",
    name: "RegisterParty",
    component: NewUser,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/edit-party",
    name: "EditParty",
    component: UserList,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/users/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/billing",
    name: "Billing",
    component: Billing,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/invoice",
    name: "Invoice",
    component: InvoiceList,
    meta: {
      requiresAuth: true,
      // requiresAdmin: true,
    },
  },
  {
    path: "/billing/invoiceoperator",
    name: "InvoiceOperator",
    component: InvoiceListOperator,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic,
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover,
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration,
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover,
  },
  {
    path: "/authentication/signup/auth",
    name: "Signup Authentication",
    component: PersonalAuthentication,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/authentication/signup/basic",
    name: "Signup Basic",
    component: SignupBasic,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/authentication/signup/done",
    name: "Signup Done",
    component: SignupDone,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/authentication/deleteuser/done",
    name: "DeleteUser Done",
    component: DeleteUserDone,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/authentication/signup/certify",
    name: "Signup Certify",
    component: Certify,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/manage/certify",
    name: "Signup SendNumberCertify",
    component: SendNumberCertify,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/manage/utm",
    name: "UTM List",
    component: UtmList,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    try {
      if (to.meta.requiresOperator || to.meta.requiresAdmin) {
        await Auth.currentAuthenticatedUser()
        const user = await Auth.currentAuthenticatedUser()
        const idToken = user.signInUserSession.idToken
        const tenantId = idToken.payload['cognito:groups'][0]
        const isAdmin = idToken.payload['custom:isAdmin']
        if (!to.meta.requiresOperator) {
          if (isAdmin === '1') {
            next()
          } else {
            alert('권한이 없습니다.')
            next({ path: '/authentication/signin/basic' })
          }
        } else if (!to.meta.requiresAdmin) {
          if (tenantId === '0') {
            next()
          } else {
            alert('권한이 없습니다.')
            next({ path: '/authentication/signin/basic' })
          }
        } else {
          if (tenantId === '0' && isAdmin === '1') {
            next()
          } else {
            alert('권한이 없습니다.')
            next({ path: '/authentication/signin/basic' })
          }
        }
      } else {
        next()
      }
      
    } catch (error) {
      console.log('No auth. move to login')
      next({ path: '/authentication/signin/basic' })
    }
  } else {
    next()
  }
})

export default router;
