<template>
    <div class="nav-wrapper position-relative end-0">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
            <li class="nav-item">
                <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" href="javascript:;" role="tab"
                    aria-selected="true" @click="selectTab('register')">발신번호등록</a>
            </li>
            <li class="nav-item">
                <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false"
                    @click="selectTab('search')">발신번호조회</a>
            </li>
        </ul>
    </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
    name: "SendNumberNavPill",
    data() {
        return {
            currentTab: "manual",
        };
    },
    mounted() {
        setNavPills();
    },
    methods: {
        selectTab(tab) {
            this.currentTab = tab;
            this.$emit("select-form", tab);
        },
    },
};
</script>
