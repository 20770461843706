<template>
  <div v-if="type ==='DS'" class="row align-items-center">
    <div class="col-2 ps-3 pe-1">
      <span style="font-weight: bold; font-size: 0.8rem;">배송조회</span>
    </div>
    <div class="col-3 px-0">
      <material-input-vmodel
        id="name"
        v-model="inputButtonName"
        v-bind="$attrs"
        :value="inputButtonName"
        label="버튼명"
        :disabled="disabled"
        v-on="buttonNameInputListeners"
        @input="$emit('update:buttonName', $event.target.value)"
      >
      </material-input-vmodel>
    </div>
    <div class="col-6 pe-0">
      <material-input-vmodel
        id="link"
        label="링크"
        :disabled="disabled || type==='DS'"
      >
      </material-input-vmodel>
    </div>
    <div class="col-1">
      <i class="material-icons-round opacity-10" style="cursor: pointer;" @click="openDSModal">help</i>
    </div>
  </div>
  <div v-if="type ==='WL'" class="row align-items-center">
    <div class="col-2 ps-3 pe-1">
      <span style="font-weight: bold; font-size: 0.8rem;">웹링크</span>
    </div>
    <div class="col-3 px-0">
      <material-input-vmodel
        id="name"
        v-model="inputButtonName"
        v-bind="$attrs"
        :value="inputButtonName"
        label="버튼명"
        :disabled="disabled"
        v-on="buttonNameInputListeners"
        @input="$emit('update:buttonName', $event.target.value)"
      >
      </material-input-vmodel>
    </div>
    <div class="col-6 pe-0">
      <material-input-vmodel
        id="link"
        v-model="mobileLink"
        label="Mobile(필수)"
        :disabled="disabled"
        @input="updateWebLinks"
      >
      </material-input-vmodel>
      <material-input-vmodel
        id="link"
        v-model="pcLink"
        label="PC"
        :disabled="disabled"
        @input="updateWebLinks"
      >
      </material-input-vmodel>
    </div>
    <div class="col-1">
    </div>
  </div>
  <div v-if="type ==='AL'" class="row align-items-center">
    <div class="col-2 ps-3 pe-1">
      <span style="font-weight: bold; font-size: 0.8rem;">앱링크</span>
    </div>
    <div class="col-3 px-0">
      <material-input-vmodel
        id="name"
        v-model="inputButtonName"
        v-bind="$attrs"
        :value="inputButtonName"
        label="버튼명"
        :disabled="disabled"
        v-on="buttonNameInputListeners"
        @input="$emit('update:buttonName', $event.target.value)"
      >
      </material-input-vmodel>
    </div>
    <div class="col-6 pe-0">
      <material-input-vmodel
        id="link"
        v-model="androidLink"
        label="Android"
        :disabled="disabled"
        @input="updateAppLinks"
      >
      </material-input-vmodel>
      <material-input-vmodel
        id="link"
        v-model="iosLink"
        label="IOS"
        :disabled="disabled"
        @input="updateAppLinks"
      >
      </material-input-vmodel>
    </div>
    <div class="col-1">
    </div>
  </div>
  <div v-if="type ==='BK'" class="row align-items-center">
    <div class="col-2 ps-3 pe-1">
      <span style="font-weight: bold; font-size: 0.8rem;">봇키워드</span>
    </div>
    <div class="col-3 px-0">
      <material-input-vmodel
        id="name"
        v-model="inputButtonName"
        v-bind="$attrs"
        :value="inputButtonName"
        label="버튼명"
        :disabled="disabled"
        v-on="buttonNameInputListeners"
        @input="$emit('update:buttonName', $event.target.value)"
      >
      </material-input-vmodel>
    </div>
    <div class="col-6 pe-0">
      <material-input-vmodel
        id="link"
        label="링크"
        :disabled="disabled || type==='BK'"
      >
      </material-input-vmodel>
    </div>
    <div class="col-1">
    </div>
  </div>
  <div v-if="type ==='MD'" class="row align-items-center">
    <div class="col-2 ps-3 pe-1">
      <span style="font-weight: bold; font-size: 0.8rem;">메시지전달</span>
    </div>
    <div class="col-3 px-0">
      <material-input-vmodel
        id="name"
        v-model="inputButtonName"
        v-bind="$attrs"
        :value="inputButtonName"
        label="버튼명"
        :disabled="disabled"
        v-on="buttonNameInputListeners"
        @input="$emit('update:buttonName', $event.target.value)"
      >
      </material-input-vmodel>
    </div>
    <div class="col-6 pe-0">
      <material-input-vmodel
        id="link"
        label="링크"
        :disabled="disabled || type==='MD'"
      >
      </material-input-vmodel>
    </div>
    <div class="col-1">
    </div>
  </div>
  <div v-if="type ==='BC'" class="row align-items-center">
    <div class="col-2 ps-3 pe-1">
      <span style="font-weight: bold; font-size: 0.8rem;">상담톡전환</span>
    </div>
    <div class="col-3 px-0">
      <material-input-vmodel
        id="name"
        v-model="inputButtonName"
        v-bind="$attrs"
        :value="inputButtonName"
        label="버튼명"
        :disabled="disabled"
        v-on="buttonNameInputListeners"
        @input="$emit('update:buttonName', $event.target.value)"
      >
      </material-input-vmodel>
    </div>
    <div class="col-6 pe-0">
      <material-input-vmodel
        id="link"
        label="링크"
        :disabled="disabled || type==='BC'"
      >
      </material-input-vmodel>
    </div>
    <div class="col-1">
      <i class="material-icons-round opacity-10" style="cursor: pointer;"  @click="openBCModal">help</i>
    </div>
  </div>
  <div v-if="type ==='BT'" class="row align-items-center">
    <div class="col-2 ps-3 pe-1">
      <span style="font-weight: bold; font-size: 0.8rem;">봇전환</span>
    </div>
    <div class="col-3 px-0">
      <material-input-vmodel
        id="name"
        v-model="inputButtonName"
        v-bind="$attrs"
        :value="inputButtonName"
        label="버튼명"
        :disabled="disabled"
        v-on="buttonNameInputListeners"
        @input="$emit('update:buttonName', $event.target.value)"
      >
      </material-input-vmodel>
    </div>
    <div class="col-6 pe-0">
      <material-input-vmodel
        id="link"
        label="링크"
        :disabled="disabled || type==='BT'"
      >
      </material-input-vmodel>
    </div>
    <div class="col-1">
      <i class="material-icons-round opacity-10" style="cursor: pointer;"  @click="openBTModal">help</i>
    </div>
  </div>
  <div v-if="type ==='P1'" class="row align-items-center">
    <div class="col-2 ps-3 pe-1">
      <span style="font-weight: bold; font-size: 0.8rem;">이미지보안전송 플러그인</span>
    </div>
    <div class="col-3 px-0">
      <material-input-vmodel
        id="name"
        v-model="inputButtonName"
        v-bind="$attrs"
        :value="inputButtonName"
        label="버튼명"
        :disabled="disabled"
        v-on="buttonNameInputListeners"
        @input="$emit('update:buttonName', $event.target.value)"
      >
      </material-input-vmodel>
    </div>
    <div class="col-6 pe-0">
      <material-input-vmodel
        id="link"
        v-model="pluginId"
        label="플러그인 ID"
        :disabled="disabled"
        @input="updatePluginID"
      >
      </material-input-vmodel>
    </div>
    <div class="col-1">
      <i class="material-icons-round opacity-10" style="cursor: pointer;"  @click="openPluginModal">help</i>
    </div>
  </div>
  <div v-if="type ==='P2'" class="row align-items-center">
    <div class="col-2 ps-3 pe-1">
      <span style="font-weight: bold; font-size: 0.8rem;">개인정보이용 플러그인</span>
    </div>
    <div class="col-3 px-0">
      <material-input-vmodel
        id="name"
        v-model="inputButtonName"
        v-bind="$attrs"
        :value="inputButtonName"
        label="버튼명"
        :disabled="disabled"
        v-on="buttonNameInputListeners"
        @input="$emit('update:buttonName', $event.target.value)"
      >
      </material-input-vmodel>
    </div>
    <div class="col-6 pe-0">
      <material-input-vmodel
        id="link"
        v-model="pluginId"
        label="플러그인 ID"
        :disabled="disabled"
        @input="updatePluginID"
      >
      </material-input-vmodel>
    </div>
    <div class="col-1">
      <i class="material-icons-round opacity-10" style="cursor: pointer;"  @click="openPluginModal">help</i>
    </div>
  </div>
  <div id="DSModal" class="modal fade"  tabindex="-1" aria-labelledby="createGroupModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="DSModalTitle" class="modal-title" >배송조회 버튼 도움말</h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <h6 id="DSModalBody" class="modal-body" >템플릿 내용 내 택배사명, 송장번호가 포함되어야합니다.
              각 택배사에서 제공하는 배송조회 페이지로 이동합니다.</h6>
          </div>
            <span
              v-for="(company, index) in companies"
              :key="index"
              style="font-weight: bold; cursor: pointer;"
              @click="selectCompany(company)"
            >
              {{ company.name }}&nbsp;
            </span>
          <hr>
          <div v-if="selectedCompany">
            <h6> 택배사명: {{ selectedCompany.name_desc }}</h6><br>
            <h6> 송장번호 패턴: {{ selectedCompany.pattern_desc }}</h6><br>
            <h6> 예시: {{ selectedCompany.ex_desc }}</h6>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
          >닫기</button>
        </div>
      </div>
    </div>
  </div>
  <div id="BCModal" class="modal fade"  tabindex="-1" aria-labelledby="createGroupModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="DSModalTitle" class="modal-title" >상담톡 버튼 도움말</h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <h6 id="DSModalBody" class="modal-body" >상담톡을 이용하는 카카오톡 채널만 이용가능합니다.</h6>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
          >닫기</button>
        </div>
      </div>
    </div>
  </div>
  <div id="BTModal" class="modal fade"  tabindex="-1" aria-labelledby="createGroupModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="BTModalTitle" class="modal-title" >봇전환 버튼 도움말</h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <h6 id="BTModalBody" class="modal-body" >카카오 i오픈빌더를 이용하는 카카오톡 채널만 이용가능</h6>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
          >닫기</button>
        </div>
      </div>
    </div>
  </div>
  <div id="PluginModal" class="modal fade"  tabindex="-1" aria-labelledby="createGroupModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="P1ModalTitle" class="modal-title" >이미지보안전송 플러그인 버튼 도움말</h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <h6 id="P1ModalBody" class="modal-body" >
              카카오 플러그인 개발이 선행되어야 합니다.
              <a class="custom-link" href="https://business.kakao.com/info/talkbizplugin/">플러그인 개발 가이드 바로가기</a> 개발 완료 후 발신프로필 콜백 URL을 등록합니다.(내정보> 카카오 프로필 정보> URL등록)
            </h6>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
          >닫기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInputVmodel from "@/components/MaterialInputVmodel.vue";
export default {
  name: "KakaoTemplateButtonInput",
  components: {
    MaterialInputVmodel,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    buttonName: {
      type: String,
      default: '',
    },
    links: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:buttonName", 'update:links'],
  data() {
    return {
      DSModal: null,
      BCModal: null,
      BTModal: null,
      selectedCompany: '',
      mobileLink: '',
      pcLink: '',
      androidLink: '',
      iosLink: '',
      pluginId: '',
      companies: [
        {
          name: '우체국택배',
          name_desc: '우체국',
          pattern_desc: "숫자 13자리 또는 숫자 6자리 + 숫자 7자리 (구분자 '-' 또는 '_')",
          ex_desc: '1234567890123, 123456-1234567, 123456_1234567',
        },
        {
          name: '로젠택배',
          name_desc: '로젠 or 로잰',
          pattern_desc: "숫자 11자리 또는 숫자 3자리 + 숫자 4자리 + 숫자 4자리 (구분자 '-' 또는 '_'))",
          ex_desc: '12345678901, 123-1234-1234, 123_1234_1234',
        },
        {
          name: '일양로지스',
          name_desc: '일양로지스택배 or 일양택배 or 일양로지스',
          pattern_desc: '숫자 9~11자리',
          ex_desc: '123456789, 1234567890, 12345678901',
        },
        {
          name: 'FedEx',
          name_desc: '페덱스 or fedex or FedEx',
          pattern_desc: '숫자 12자리',
          ex_desc: '123456789012',
        },
        {
          name: '한진택배',
          name_desc: '한진택배',
          pattern_desc: '숫자 10자리 또는 숫자 12자리',
          ex_desc: '1234567890, 123456789012',
        },
        {
          name: '경동택배',
          name_desc: '경동택배',
          pattern_desc: "숫자 9~16자리 또는 숫자 4자리 + 숫자 3자리 + 숫자 6자리 (구분자 '-')",
          ex_desc: '123456789, 1234567890123456, 1234-123-123456',
        },
        {
          name: '합동택배',
          name_desc: '합동택배',
          pattern_desc: '숫자 9~16자리',
          ex_desc: '123456789, 1234567890123456',
        },
        {
          name: '롯데택배',
          name_desc: '롯데택배 or 롯데로지스틱스 or 현대택배 or 현대로지스틱스',
          pattern_desc: "숫자 12자리 또는 숫자 4자리 + 숫자 4자리 + 숫자 4자리 (구분자 '-')",
          ex_desc: '123456789012, 1234-1234-1234',
        },
        {
          name: '한덱스택배',
          name_desc: '한덱스',
          pattern_desc: '숫자 10자리 또는 숫자 14자리',
          ex_desc: '1234567890, 12345678901234',
        },
        {
          name: '농협택배',
          name_desc: '농협택배',
          pattern_desc: '숫자 12자리',
          ex_desc: '123456789012',
        },
        {
          name: '호남택배',
          name_desc: '호남택배',
          pattern_desc: '숫자 10자리',
          ex_desc: '1234567890',
        },
        {
          name: '천일택배',
          name_desc: '천일택배',
          pattern_desc: '숫자 11자리',
          ex_desc: '12345678901',
        },
        {
          name: '건영택배',
          name_desc: '건영택배',
          pattern_desc: '숫자 10자리',
          ex_desc: '1234567890',
        },
        {
          name: '대신택배',
          name_desc: '대신택배',
          pattern_desc: '숫자 13자리',
          ex_desc: '1234567890123',
        },
        {
          name: 'CU편의점택배',
          name_desc: 'CU편의점택배',
          pattern_desc: "숫자 10자리 또는 숫자 12자리 또는 숫자 4자리 + 숫자 4자리 + 숫자 4자리 (구분자 '-' 또는 '_')",
          ex_desc: '1234567890, 123456789012, 1234-1234-1234, 1234_1234_1234',
        },
        {
          name: 'CVSnet편의점택배',
          name_desc: 'GSPostbox택배 or GS편의점택배 or CVSnet편의점택배',
          pattern_desc: "숫자 10자리 또는 숫자 12자리 또는 숫자 4자리 + 숫자 4자리 + 숫자 4자리 (구분자 '-' 또는 '_')",
          ex_desc: '1234567890, 123456789012, 1234-1234-1234, 1234_1234_1234',
        },
        {
          name: 'TNT Express',
          name_desc: 'TNTExpress or TNT택배 or TNT Express',
          pattern_desc: '숫자 8~9자리',
          ex_desc: '12345678, 123456789',
        },
        {
          name: 'USPS',
          name_desc: 'USPS',
          pattern_desc: '숫자 10자리 또는 숫자 22자리 또는 대문자 알파벳 2자리 + 숫자 9자리 + 대문자 알파벳 2자리 (구분자 없음)',
          ex_desc: '1234567890, 1234567890123456789012, AB123456789AB',
        },
        {
          name: 'EMS',
          name_desc: 'EMS',
          pattern_desc: '대문자 알파벳 2자리 + 숫자 9자리 + 대문자 알파벳 2자리 (구분자 없음)',
          ex_desc: 'AB1234567890AB',
        },
        {
          name: 'DHL',
          name_desc: 'DHL',
          pattern_desc: '숫자 10자리',
          ex_desc: '1234567890',
        },
        {
          name: '굿투럭',
          name_desc: '굿투럭',
          pattern_desc: '숫자 4자리+숫자 4자리+숫자 4자리 (구분자 ‘-’ )',
          ex_desc: '1234-1234-1234',
        },
      ]
    }
  },
  computed: {
    buttonNameInputListeners() {
      return {
        ...this.$attrs,
        input: (event) => this.$emit("update:buttonName", event.target.value),
      };
    },
    inputButtonName: {
      get() {
        return this.buttonName;
      },
      set(value) {
        this.$emit('update:buttonName', value);
      },
    },
  },
  methods: {
    openDSModal() {
      const bootstrap = this.$store.state.bootstrap;

      this.DSModal = new bootstrap.Modal(
        document.getElementById("DSModal")
      );

      this.DSModal.show();
    },
    openBCModal() {
      const bootstrap = this.$store.state.bootstrap;

      this.BCModal = new bootstrap.Modal(
        document.getElementById("BCModal")
      );

      this.BCModal.show();
    },
    openBTModal() {
      const bootstrap = this.$store.state.bootstrap;

      this.BTModal = new bootstrap.Modal(
        document.getElementById("BTModal")
      );

      this.BTModal.show();
    },
    openPluginModal() {
      const bootstrap = this.$store.state.bootstrap;

      this.P1Modal = new bootstrap.Modal(
        document.getElementById("PluginModal")
      );

      this.P1Modal.show();
    },
    selectCompany(company) {
      this.selectedCompany = company;
    },
    updateWebLinks() {
      this.$emit('update:links', {
        linkMo: this.mobileLink,
        linkPc: this.pcLink,
      });
    },
    updateAppLinks() {
      this.$emit('update:links', {
        linkAnd: this.androidLink,
        linkIos: this.iosLink,
      });
    },
    updatePluginID() {
      this.$emit('update:links', {
        pluginId: this.pluginId,
      });
    },
  }
};
</script>

<style scoped>
.custom-link {
  color: blue; /* Change to the desired color */
  font-weight: bold; /* Add emphasis with font weight */
  text-decoration: underline; /* Underline the link */
}
</style>
