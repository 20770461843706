<template>
    <div class="px-0 pt-0 pb-2 card-body">
      <div class="p-0 table-responsive">
        <table class="table mb-0 align-items-center">
          <thead>
            <tr>
              <th
                v-for="(header, index) of headers"
                :key="index"
                :class="index >= 1 ? 'text-center ps-2' : ''"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                @click="sortTable(index)"
              >
                {{ header }}
                <i
                  v-if="header !== '' && header !== null"
                  class="fas"
                  :class="{
                    'fa-sort-up': currentSortColumn === index && currentSortDirection === 1,
                    'fa-sort-down': currentSortColumn === index && currentSortDirection === -1,
                    'fa-sort': currentSortColumn !== index,
                    'stronger-color': currentSortColumn === index
                  }"
                ></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(list, index) in sortedLists" :key="index" :class="{ 'selected': index === selectedRow }" @click="$emit('row-clicked', { list, index }), selectedRow = index">
              <td :style="{ 'max-width': valuesMaxWidth, 'white-space': 'nowrap', 'text-overflow': 'ellipsis', 'overflow': 'hidden' }">
                <div class="px-3 py-1 d-flex">
                  <material-button
                    v-if="list.image"
                    :color="list.image_color"
                    variant="outline"
                    class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                  >
                    <i :class="list.image" aria-hidden="true"></i>
                  </material-button>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0" :class="fontSizeClass" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                      <span style="display: inline-block; max-width: 100%;" :title="list.primary">{{ list.primary }}</span>
                    </h6>
                    <p class="mb-0 font-weight-bold text-secondary" :class="fontSizeClass" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                      <span style="display: inline-block; max-width: 100%;" :title="list.secondary">
                        <span :class="list.secondary_color">{{ list.secondary }}</span>
                      </span>
                    </p>
                  </div>
                </div>
              </td>
              <td v-for="(value, index) in list.values" :key="index" class="align-middle" :class="fontSizeClass"  :style="{ 'max-width': valuesMaxWidth, 'white-space': 'nowrap', 'text-overflow': 'ellipsis', 'overflow': 'hidden' }">
                  <p class="text-center mb-0 font-weight-bold" :class="fontSizeClass" :title="value">{{ value }}</p>
              </td>
              <td class="align-middle text-center">
                <div class="text-center px-3 py-1 d-flex justify-content-center align-items-center">
                  <!-- <p class="mb-0 text-sm font-weight-bold">{{ list.info }}</p> -->
                  <p v-if="showInfo" class="text-center mb-0 font-weight-bold" :class="fontSizeClass">{{ list.info }}</p>
                  <i class="mt-1 text-sm ms-1" :class="`ni ni-${list.icon}`"></i>
                  <material-button
                    v-if="list.info"
                    size="sm"
                    color="secondary"
                    variant="outline"
                    class="btn-icon-only btn-rounded mb-0 ms-2 btn-sm d-flex align-items-center justify-content-center ms-3"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    :title="list.info"
                    @click="showInfo = !showInfo"
                  >
                    <i class="fas fa-info" aria-hidden="true"></i>
                  </material-button>
                </div>
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
export default {
  name: "SendHistoryListCard",
  components: {
    MaterialButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    lists: {
      type: Array,
      default: () => [],
      values: Array,
      primary: String,
      secondary: String,
      info: String,
      image: String,
      icon: String,
    },
    fontSize: {
      type: String,
      required: false,
      default: 'sm' // default font size is 14px
    },
    primaryMaxWidth : {
      type: String,
      required: false,
      default: 'none' 
    },
    valuesMaxWidth: {
      type: String,
      required: false,
      default: 'none' 
    }
  },
  data() {
    return {
      selectedRow: -1, // -1 means no row is selected
      sortedLists: [],
      currentSortColumn: null,
      currentSortDirection: 1,
      showInfo: false,
      // ... other properties ...
    };
  },
  computed: {
    fontSizeClass() {
      return `text-${this.fontSize}`
    }
  },
  watch: {
    lists: {
      immediate: true,
      handler() {
        this.sortedLists = [...this.lists]; // Update sortedLists when lists prop changes
      },
    },
  },
  methods: {
    selectRow(index) {
      this.selectedRow = index;
      console.error(`Row ${index} clicked!`);
    },
    sortTable(columnIndex) {
  // Check if the clicked column is the same as the current sort column
  if (this.currentSortColumn === columnIndex) {
    // If it's the same column, toggle the sort direction
    this.currentSortDirection = -this.currentSortDirection;
  } else {
    // If it's a new column, set the current sort column and reset the sort direction to ascending
    this.currentSortColumn = columnIndex;
    this.currentSortDirection = 1;
  }

  if (columnIndex === 0) { // sort by primary when columnIndex is 0
    console.log(this.lists);
    this.sortedLists = [...this.lists].sort((a, b) => {
      const aValue = Number(a.primary);
      const bValue = Number(b.primary);
      console.log(a.primary);
      if (!isNaN(aValue) && !isNaN(bValue)) {
        // Sort numbers
        return (aValue - bValue) * this.currentSortDirection;
      } else {
        // Sort strings
        const aString = String(a.primary);
        const bString = String(b.primary);
        const result = aString.localeCompare(bString);
        return result * this.currentSortDirection;
      }
    });
  } else { // otherwise, sort by value
    this.sortedLists = [...this.lists].sort((a, b) => {
      const aValue = Number(a.values[columnIndex-1]); // Get the fourth column value of object a
      const bValue = Number(b.values[columnIndex-1]); // Get the fourth column value of object b

      if (!isNaN(aValue) && !isNaN(bValue)) {
        // Sort numbers
        return (aValue - bValue) * this.currentSortDirection;
      } else {
        // Sort strings
        const aString = String(a.values[columnIndex-1]);
        const bString = String(b.values[columnIndex-1]);
        const result = aString.localeCompare(bString);
        return result * this.currentSortDirection;
      }
    });
  }
},

  },
};
</script>
<style>
.selected-row {
  background-color: #f5f5f5;
}
.stronger-color {
  color: #333; /* Change this to a stronger color of your choice */
}
</style>