<template>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="keywords" content="통합 메세징 솔루션, 심플하고 빠른 기업 메시징 솔루션" />
    <meta property="og:locale" content="ko_KR" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="SK(주) C&C 대량 단체문자 발송 - Message To-Go | SMS, LMS, 알림톡 API 기업 문자 서비스" />
    <meta property="og:description" content="메시지투고 문의하기" />
    <meta property="og:url" content="https://message.to-go.io/home/contactus" />
    <meta property="og:site_name" content="SK(주) C&C 대량문자발송 솔루션: SMS LMS MMS 알림톡 친구톡 이메일 팩스" />
    <title>SK(주) C&C 대량 단체문자 발송 - Message To-Go | SMS, LMS, 알림톡 API 기업 문자 서비스</title>
    <link rel="icon" href="https://to-go.io/sk_logo.jpg" type="image/jpg">
<!--    <script src="https://kit.fontawesome.com/42d5adcbca.js" crossorigin="anonymous"></script>-->
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-icons.css">
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-svg.css">
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/soft-ui-dashboard.min.css?v=1.0.2">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700">
    <link rel="stylesheet" href="../assets/css/theme.css">
    <link rel="stylesheet" href="../assets/css/loopple/loopple.css">
  </head>

  <body class="g-sidenav-show">
    <div class="container position-sticky z-index-sticky top-0">
            <div class="row">
              <div class="col-12">
                  <nav class="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
                      <div class="container-fluid">
                          <!-- <a class="navbar-brand font-weight-bolder ms-sm-3" href="javascript:;" rel="tooltip" title="Designed and Coded by Creative Tim" data-placement="bottom">Message To-Go</a> -->
                          <a class="navbar-brand ms-sm-3" href="/home">
                            <!-- <img src="../assets/img/m2g-logo-only.png" alt="Logo" height="30"> -->
                            <img src="../assets/img/SK_logo.svg" alt="Your SVG Image" style="position: relative; width: 30%; height: 20%; margin-top: -10px; margin-bottom: -10px; margin-right: 10px; margin-left: -10px;">
                            <!-- <span class="font-weight-bolder"> Message To-Go</span>    -->
                          </a>
                          <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                              <span class="navbar-toggler-icon mt-2">
                                  <span class="navbar-toggler-bar bar1"></span>
                                  <span class="navbar-toggler-bar bar2"></span>
                                  <span class="navbar-toggler-bar bar3"></span>
                              </span>
                          </button>
                          <div id="navigation" class="collapse navbar-collapse pt-3 pb-2 py-lg-0">
                              <ul class="navbar-nav navbar-nav-hover ms-lg-12 ps-lg-5 w-100">
                                  <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="/home#channels" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">채널소개</span>
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="/home#features" aria-expanded="false">
                                        <span class="font-weight-bolder" style="font-size: larger;">기능</span>
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="/home#product" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">요금제</span>
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/contactus" aria-expanded="false">
                                        <span class="font-weight-bolder" style="font-size: larger;">문의하기</span>
                                      </router-link>
                                  </li>
                                  <li class="nav-item">
                                    <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" :to="`/home/news`" aria-expanded="false">
                                      <span class="font-weight-bolder" style="font-size: larger;">NEWS/소식</span>
                                    </router-link>
                                  </li>
                                  <li class="nav-item ms-lg-auto my-auto ms-3 ms-lg-0">
                                    <router-link class="btn btn-sm  bg-gradient-primary  btn-round mb-0 me-1 mt-2 mt-md-0" to="/authentication/signin/basic">
                                      회원가입/로그인
                                    </router-link>
                                  </li>
                                </ul>
                          </div>
                      </div>
                  </nav>
              </div>
          </div>
      </div>
      <section class="py-5 position-relative">
          <div class="container">
              <div class="row mt-5">
                  <div class="col-lg-10 mx-auto">
                      <div class="card">
                          <div class="row">
                              <div class="col-lg-5 d-flex">                                
                                  <div class="bg-gradient-dark my-lg-3 ms-lg-3 border-radius-md">
                                      <div class="card-body p-5 position-relative">
                                          <img src="../assets/img/logo-m2g.png" class="img-fluid mb-4">
                                          <h3 class="text-white text-center">Contact Us</h3>
                                          <p class="text-white opacity-8 mb-4 text-center">질문 또는 의견 있으시면 언제든 연락주세요!<br>저희 팀이 빠른 시간내에 답변 드리겠습니다.</p>
                                          <div class="d-flex p-2">
                                              <div>
                                                  <i class="fas fa-envelope text-white" aria-hidden="true"></i>
                                              </div>
                                              <div class="ps-3">
                                                  <a href="mailto:event@message.to-go.io" class="text-white"><span class="text-sm opacity-8">message.to-go@sk.com</span></a>
                                              </div>
                                          </div>
                                      </div>
<!--                                    <div class="card-footer z-index-2">-->
<!--                                      <router-link :to="`/home/event`" target="_blank">-->
<!--                                        <img-->
<!--                                          class="col-12 border-radius-lg"-->
<!--                                          src="../views/auth/signup/image/promotion.png"-->
<!--                                        >-->
<!--                                      </router-link>-->
<!--                                    </div>-->
                                  </div>
                              </div>
                              <div class="col-lg-7">
                                  <form id="contact-form" method="post" autocomplete="off">
                                      <div class="card-body position-relative p-3 ps-0">
                                          <div class="row mt-4">
                                              <div class="col-md-6">
                                                  <label>이름</label>
                                                  <div class="input-group mb-4">
                                                      <input class="form-control" placeholder="홍길동" aria-label="Name..." type="text" name="name" required>
                                                  </div>
                                              </div>
                                              <div class="col-md-6 ps-2">
                                                  <label>회사명</label>
                                                  <div class="input-group mb-4">
                                                      <input type="company" class="form-control" placeholder="eg. Message To-Go" name="company">
                                                  </div>
                                              </div>
                                          </div>
                                        <div class="row mt-4">
                                          <div class="col-md-6">
                                            <label>조직명</label>
                                            <div class="input-group mb-4">
                                              <input class="form-control" placeholder="홍보팀" aria-label="Name..." type="text" name="department" required>
                                            </div>
                                          </div>
                                          <div class="col-md-6 ps-2">
                                            <label>전화번호</label>
                                            <div class="input-group mb-4">
                                              <input class="form-control" placeholder="eg. 010-1234-5678" name="phone"  required>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="mb-4">
                                                  <label>이메일</label>
                                                  <div class="input-group mb-4">
                                                      <input type="email" class="form-control" placeholder="eg. message.to-go@sk.com" name="email" required>
                                                  </div>
                                              </div>
                                          <label>무엇에 관련 문의/의견입니까?</label>
                                          <div class="d-flex">
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="inquire_reason1" class="form-check-input" type="radio" name="inquire_reason" value="product" required>
                                                      <label class="form-check-label" for="inquire_reason1">
                                                          제품/서비스
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="inquire_reason2" class="form-check-input" type="radio" name="inquire_reason" value="purchase">
                                                      <label class="form-check-label" for="inquire_reason2">
                                                         도입문의
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="inquire_reason3" class="form-check-input" type="radio" name="inquire_reason" value="tech_support">
                                                      <label class="form-check-label" for="inquire_reason3">
                                                          기술지원
                                                      </label>
                                                  </div>
                                              </div>
                                              <div>
                                                  <div class="form-check me-3">
                                                      <input id="inquire_reason4" class="form-check-input" type="radio" name="inquire_reason" value="other">
                                                      <label class="form-check-label" for="inquire_reason4">
                                                          기타
                                                      </label>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="form-group mt-4">
                                              <label>문의사항</label>
                                              <textarea id="inquire_text" class="form-control" name="inquire_reason4" rows="3" required></textarea>
                                          </div>
                                          <div class="col-md-6">
                                              <div class="form-check form-switch mb-4">
                                                  <input id="termsCheck" class="form-check-input" type="checkbox" name="agree" required>
                                                  <label class="form-check-label" for="termsCheck"><a href="/home/informationuse" class="text-dark"><u>개인정보 수집 및 이용</u></a> 동의</label>
                                              </div>
                                          </div>
                                          <div class="text-end">
                                              <!-- <button type="button" class="btn bg-gradient-dark mb-0" @click="submitForm">Send message</button> -->
                                              <material-button-progress color="primary" size="sm" variant="default" style="width: 120px; margin-right: 5px;"
                                                :is-loading="isCreateLoading" @click="submitForm">
                                                제출하기
                                              </material-button-progress>
                                          </div>
                                          
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  </body>
</template>

<script>
import { useStore } from 'vuex';
import axios from 'axios';
import ApiCall from "@/Interface/ApiCall";
import Cookies from "js-cookie";
import MaterialButtonProgress from "@/components/MaterialButtonProgress.vue";

export default {
  name: 'Pricing',
  components: {
    // MaterialButton,
    // MaterialTable,
    // MaterialPagination,
    // MaterialInput,
    // MaterialTextarea,
    // MaterialCheckbox,
    // MaterialInputVmodel,
    MaterialButtonProgress,
    // CampaignForm,
  },
  data() {
    return{
      isCreateLoading: false,
    }
  },
  beforeMount() {
    const store = useStore();
    store.commit('toggleEveryDisplay', false);
  },
  beforeUnmount() {
    const store = useStore();
    store.commit('toggleEveryDisplay', true);
  },
  mounted(){
    this.saveDataToCookie();
  },
  methods: {
    saveDataToCookie() {
      const parsedQueryString = this.parseQueryString(this.getQueryString())

      if (Object.keys(parsedQueryString).length > 0) {
        const jsonQueryString = JSON.stringify(parsedQueryString)
        Cookies.set('m2g-utm', jsonQueryString)
      }
    },
    getQueryString() {
      return window.location.search;
    },
    parseQueryString(queryString) {
      // Initialize URLSearchParams with the queryString
      const searchParams = new URLSearchParams(queryString);

      // Initialize an empty object to store the parsed query
      const parsedQuery = {};

      // Iterate through the searchParams and convert it to an object
      for (const [key, value] of searchParams.entries()) {
        parsedQuery[key] = value;
      }

      // Set the parsed query in the data
      return parsedQuery;
    },
    async submitForm() {

      this.isCreateLoading = true;
      const form = document.querySelector('#contact-form');

      const formData = new FormData(form);

      // Convert the form data to an object
      const formObject = {};
      var checkbox = document.getElementById("termsCheck");
      if (!checkbox.checked) {
        // Checkbox is checked
        alert("개인정보처리방침 동의가 필요합니다.");
        this.isCreateLoading = false;
        return;
        
      }
      
      for (const [key, value] of formData.entries()) {
        formObject[key] = value;
        if (value.length === 0) {
          alert("모든 항목을 입력해주세요.");
          this.isCreateLoading = false;
          return; // This will exit the function and prevent the API call
        }
      }

      const seoulTimezone = 'Asia/Seoul';
      const currentDateTime = new Date().toLocaleString('en-US', { timeZone: seoulTimezone });

      formObject["pk"] = 'inquiry';
      formObject["sk"] = currentDateTime

      const savedUtm = Cookies.get('m2g-utm');

      let formObjectFinal = {}
      if (savedUtm !== undefined) {
        const parsedUtm = JSON.parse(savedUtm);
        formObjectFinal = { ...formObject, ...parsedUtm, ...{date:currentDateTime}};
      } else {
        formObjectFinal = { ...formObject, ...{date:currentDateTime}};
      }

      // Add UTM value

      await ApiCall.callNoAuth('survey', 'POST', formObject).then((res) => {
        console.log(res)

        ApiCall.callNoAuth('analytics/utm', 'POST', formObjectFinal).then((utm_res) => {
          console.log(utm_res)
          this.$router.push('/home/contactus/done')
        });
        // if (res.status === 200) {
        // } else {
        //   alert(res.message)
        //   alert('문의 등록이 실패했습니다.');
        // }
      });

      this.isCreateLoading = false;
    },
  },
};

</script>

<style scoped></style>
