<template>
  <head>
    <!-- <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-icons.css"> -->
    <!-- <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/cleo-svg.css"> -->
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/soft-ui-dashboard.min.css?v=1.0.2">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700">
    <link rel="stylesheet" href="./assets/css/theme.css">
  </head>
  <!-- <navbar btn-background="bg-gradient-success" /> -->
  <div class="container position-sticky z-index-sticky top-0">
        <div class="row">
            <div class="col-12">
                <nav class="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
                  <div class="container-fluid">
                        <!-- <a class="navbar-brand font-weight-bolder ms-sm-3" href="javascript:;" rel="tooltip" title="Designed and Coded by Creative Tim" data-placement="bottom">Message To-Go</a> -->
                        <a class="navbar-brand ms-sm-3" href="../../home">
                          <img src="@/assets/img/m2g-logo-only.png" alt="Logo" height="30">
                          <span class="font-weight-bolder"> 메시지투고</span>
                        </a>
                        <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon mt-2">
                                <span class="navbar-toggler-bar bar1"></span>
                                <span class="navbar-toggler-bar bar2"></span>
                                <span class="navbar-toggler-bar bar3"></span>
                            </span>
                        </button>
                        <div id="navigation" class="collapse navbar-collapse pt-3 pb-2 py-lg-0">
                            <ul class="navbar-nav navbar-nav-hover ms-lg-12 ps-lg-5 w-100">
                                <li class="nav-item">
                                  <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="../../home#channels" aria-expanded="false">
                                    <span class="font-weight-bolder">채널소개</span>
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="../../home#features" aria-expanded="false">
                                      <span class="font-weight-bolder">기능</span>
                                  </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" href="../../home#product" aria-expanded="false">    
                                        <span class="font-weight-bolder">요금제</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/contactus" aria-expanded="false">
                                      <span class="font-weight-bolder">문의하기</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" :to="`/home/news`" aria-expanded="false">
                                    <span class="font-weight-bolder" style="font-size: larger;">NEWS/소식</span>
                                  </router-link>
                                </li>
                                <li class="nav-item ms-lg-auto my-auto ms-3 ms-lg-0">
                                  <router-link class="btn btn-sm  bg-gradient-primary  btn-round mb-0 me-1 mt-2 mt-md-0" :to="`/authentication/signin/basic`">
                                    회원가입/로그인
                                  </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
  <div
    class="page-header align-items-start min-vh-100"
    style="
      background-image: url('https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80');
    "
  >
    <span class="mask opacity-6"></span>
    <div class="container my-auto">
      <div class="row mt-lg-n12 mt-md-n12 mt-n11 justify-content-center">
        <div class="col-xl-8 col-lg-5 col-md-7 mx-auto">
          <div class="card mt-12">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                  class="bg-gradient-primary shadow-success border-radius-lg py-3 pe-1 text-center py-4"
              >
                <h4 class="font-weight-bolder text-white mt-1">
                  회원가입
                </h4>
              </div>
            </div>
            <div class="card-body pb-3 px-4">
              <div class="row text-center mb-2">
                <h5 class="font-weight-bolder text-black mt-1">
                  감사합니다. 회원가입이 완료 되었습니다.<br>
                  M2G에 가입을 환영합니다.<br>
                  로그인 후 쉽고 편하게 메세지를 보내보세요.
                </h5>
              </div>
              <div class="row">
                  <router-link
                    class="btn btn-sm  bg-gradient-primary border-radius-lgtext-center py-4"
                    :to="`/authentication/signin/basic`"
                  >
                    <h6 class="font-weight-bolder text-white mt-1">
                    로그인으로 이동
                    </h6>
                  </router-link>
                  <form name="form">
                    <input id="m" type="hidden" name="m" value="service" />
                    <input id="token_version_id" type="hidden" name="token_version_id" value="" />
                    <input id="enc_data" type="hidden" name="enc_data" value="" />
                    <input id="integrity_value" type="hidden" name="integrity_value" value="" />
                  </form>
              </div>
            </div>
            <div class="card-footer text-center pt-0 px-sm-4 px-1">
<!--              <p class="mx-auto">-->
<!--                이미 가입하셨나요?-->
<!--                <router-link-->
<!--                    :to="{ name: 'Signin Basic' }"-->
<!--                    class="text-success text-gradient font-weight-bold"-->
<!--                >로그인</router-link>-->
<!--              </p>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-10 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              © {{ new Date().getFullYear() }}, made with
              <i class="fa fa-heart" aria-hidden="true"></i> by
              <a
                href="https://www.sk.com"
                class="font-weight-bold text-white"
                target="_blank"
                >SK</a
              >
            </div>
            <span style="font-size: 0.8rem; margin-right: 0.5rem;">
          SK(주) | 대표이사: 장용호, 최태원 | 경기도 성남시 분당구 성남대로 343번길 9 | 사업자등록번호 783-85-00169 | 통신판매업신고 2015-경기성남-1388 <br> COPYRIGHT SK HOLDINGS CO., LTD. ALL RIGHTS RESERVED.
          </span>
          </div>
          <div class="col-12 col-md-2">
            <ul
                      class="nav nav-footer"
                    >
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 이용 약관_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >이용약관</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 수준 약정서(SLA)_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >서비스수준(SLA)</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/home/privacy"
                          class="nav-link pe-0 text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 1rem;"
                          >개인정보처리방침</a
                        >
                      </li>
                    </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";

// amplify
import {Hub, I18n, Auth} from 'aws-amplify'
import {
  Authenticator,
  AuthenticatorSignUpFormFields,
  AmplifyCheckBox,
  AmplifyTextField,
  useAuthenticator,
} from '@aws-amplify/ui-vue'
import '@aws-amplify/ui-vue/styles.css'
import '/src/assets/css/styles.css'
import {useRouter} from "vue-router";
import {onUnmounted, onBeforeCreate} from "vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import { toRefs } from 'vue';
import ko from '../../../locales/ko.json';
import { translations } from '@aws-amplify/ui-vue';
import {isValidDate} from "@fullcalendar/core";
import ApiCall from "@/Interface/ApiCall";
import CryptoJS from 'crypto-js';
import base64js from 'base64-js';
import Base64 from 'crypto-js/enc-base64';
import { SecretKey } from 'crypto-js';
import { HmacSHA256, enc } from 'crypto-js';

export default {
  name: "SignupDone",
  components: {
  },
  setup() {
    const router = useRouter()

  },
  computed: {
  },
  created() {
    if (ApiCall.vdiDomainCheck()) {
      Auth.configure({
        endpoint: process.env.VUE_APP_COGNITO_URL_PROXY
      });
    }

    Auth.currentAuthenticatedUser().then(user => {
      this.userName = user.attributes.name // Set the user ID as the dynamicMessage
      const router = this.$router
      router.push('/dashboard')
    }).catch(error => {
      // console.log('Error fetching user information:', error)
    })
  },
  beforeMount() {
    // console.log("nice return url:", process.env.VUE_APP_NICE_RETURN_URL)
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    window.addEventListener('message', this.handleChildMessage);
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    window.removeEventListener('message', this.handleChildMessage);
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    getQueryString() {
      return window.location.search;
    },
    handleChildMessage(event) {
      // Check if the message is coming from the child window
      if (event.origin !== window.location.origin) return;

      const queryString = event.data; // Retrieve the query string parameter
      // Do something with the query string parameter
      // console.log("queryString from child window :", queryString);
      const params = Object.fromEntries(new URLSearchParams(queryString));

      // console.log("queryString object :", params)
    },
    async issuNiceCryptoKey() {
      const signUpInfo = {
        'userId': '',
        'password': '',
        'confirmPassword': '',
        'tenantName': '',
        'email': '',
      }

      const res = await ApiCall.callNoAuth('user/nice/token', 'POST', signUpInfo);
      // console.log("enc data", res);

      // document.form.submit();
      document.form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
      document.form.token_version_id.value = res.token_version_id;
      document.form.enc_data.value = res.enc_data;
      document.form.integrity_value.value = res.integrity_value;
      document.form.target='niceForm';

      const left = screen.width / 2 - 500 / 2;
      const top = screen.height / 2 - 800 / 2;
      const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;

      let niceAuthWindow = window.open(
        'about:blank',
        'niceForm',
        option
      )
      document.form.submit();

      await this.$router.push('/authentication/signup/basic')
    },
  },
  data() {
    return {
    }
  },
};
</script>

<style>
</style>
