<template>
  <div class="card widget-calendar">
    <div class="p-3 pb-0 card-header">
      <h6 class="mb-0">{{ title }}</h6>
      <div class="d-flex">
        <div class="mb-0 text-sm p font-weight-bold widget-calendar-day">
          {{ day }}
        </div>
        <span>,&nbsp;</span>
        <div class="mb-1 text-sm p font-weight-bold widget-calendar-year">
          {{ year }}
        </div>
      </div>
    </div>
    <div class="p-3 card-footer">
      <i class="fas fa-chevron-left calendar-icon calendar-button" @click="handlePreviousMonthClick"></i>
      {{ getFormattedYearMonth() }}
      <i class="fas fa-chevron-right calendar-icon calendar-button" @click="handleNextMonthClick"></i>
    </div>
    <div class="p-3 card-body">
      <div :id="id" data-toggle="widget-calendar"></div>
    </div>
  </div>
</template>

<script>
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
let calendar;
export default {
  name: "Calendar",
  props: {
    id: String,
    title: String,
    day: String,
    year: String,
    initialView: String,
    initialDate: {
      type: String,
      required: true, // or false, depending on your requirements
    },
    events: Array,
    selectable: Boolean,
    editable: Boolean,
  },
  data() {
    return {
      // Move initialDate to data
      currentInitialDate: this.initialDate,
    };
  },
  watch: {
    events: {
      immediate: true,
      handler(newEvents) {
        if (calendar) {
          calendar.getEvents().forEach((event) => event.remove());
          newEvents.forEach((event) => calendar.addEvent(event));
        }
      },
    },
    currentInitialDate: {
      immediate: true,
      handler(newInitialDate) {
        if (calendar) {
          calendar.gotoDate(newInitialDate);
        }
      },
    },
  },
  mounted() {
    calendar = new Calendar(document.getElementById(this.id), {
      contentHeight: "auto",
      plugins: [dayGridPlugin],
      initialView: this.initialView,
      selectable: this.selectable,
      editable: this.editable,
      events: this.events,
      initialDate: this.currentInitialDate, // Use the data property
      headerToolbar: false,
      eventClick: this.handleEventClick,
      views: {
        month: {
          titleFormat: {
            month: "long",
            year: "numeric",
          },
        },
        agendaWeek: {
          titleFormat: {
            month: "long",
            year: "numeric",
            day: "numeric",
          },
        },
        agendaDay: {
          titleFormat: {
            month: "short",
            year: "numeric",
            day: "numeric",
          },
        },
      },
    });

    calendar.render();
  },
  beforeUnmount() {
    if (calendar) {
      calendar.destroy();
    }
  },
  methods: {
    getFormattedYearMonth() {
      const currentDate = new Date(this.currentInitialDate);
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      return `${year}-${month}`;
    },
    handleEventClick(info) {
      const eventData = {
        title: info.event.title,
        start: info.event.start,
      };
      this.$emit("event-clicked", eventData);
    },
    handlePreviousMonthClick() {
      // console.log(this.currentInitialDate);
      const dateParts = this.currentInitialDate.split('-');
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed
      const day = parseInt(dateParts[2], 10);
      const currentDate = new Date(year, month, 1); // Set day to 1 to ensure the first day of the month
      currentDate.setMonth(currentDate.getMonth() - 2); // Set to the previous month

      //뒤로 가면 calendar가 이상하게 만들어지는데 앞으로 가면 정상으로 만들어짐, 2개윌 뒤로갔다 앞으로 1번..
      // Get the formatted date in the format you need
      const formattedYear = currentDate.getFullYear();
      const formattedMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
      const formattedDay = String(currentDate.getDate()).padStart(2, '0');
      const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;
      this.currentInitialDate = formattedDate; // Update the data property
      calendar.gotoDate(this.currentInitialDate);
      currentDate.setMonth(currentDate.getMonth() + 1); // Set to the previous month
      // Get the formatted date in the format you need
      const formattedYear2 = currentDate.getFullYear();
      const formattedMonth2 = String(currentDate.getMonth() + 1).padStart(2, '0');
      const formattedDay2 = String(currentDate.getDate()).padStart(2, '0');
      const formattedDate2 = `${formattedYear2}-${formattedMonth2}-${formattedDay2}`;
      this.currentInitialDate = formattedDate2; // Update the data property

      const yearMonth = `${formattedYear2}${formattedMonth2}`;

      const eventData = {
        title: "Previous Month",
        start: yearMonth,
        initialDate: this.currentInitialDate,
      };
      // console.log(eventData);
      calendar.gotoDate(this.currentInitialDate);
      this.$emit("event-clicked", eventData);
    },
    handleNextMonthClick() {
      // console.log(this.currentInitialDate);
      const dateParts = this.currentInitialDate.split('-');
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed
      const day = parseInt(dateParts[2], 10);
      const currentDate = new Date(year, month, 1); // Set day to 1 to ensure the first day of the month
      currentDate.setMonth(currentDate.getMonth() + 1); // Set to the previous month


      // Get the formatted date in the format you need
      const formattedYear = currentDate.getFullYear();
      const formattedMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
      const formattedDay = String(currentDate.getDate()).padStart(2, '0');
      const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;
      this.currentInitialDate = formattedDate; // Update the data property

      const yearMonth = `${formattedYear}${formattedMonth}`;

      const eventData = {
        title: "Next Month",
        start: yearMonth,
        initialDate: formattedDate,
      };
      // console.log(eventData);
      calendar.gotoDate(this.currentInitialDate);
      this.$emit("event-clicked", eventData);
    },
  },
};
</script>

<style>
.fc .fc-button-primary {
  background: #4caf50 !important;
  border-color: #4caf50 !important;
}
.fc .fc-button-primary:hover {
  background: #4caf50 !important;
  border-color: #4caf50 !important;
}
.card-footer {
  display: flex;
  justify-content: space-between;
}
.calendar-button {
  font-size: 1.5em;
  transition: color 0.3s ease;
  cursor: pointer;
}

.calendar-button:hover {
  color: #007bff;
}
</style>
