<template>
  <div class="nav-wrapper position-relative end-0">
    <ul class="nav nav-pills nav-fill p-1" role="tablist" style="background-color: #dee2e6;">
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="true"
          @click="selectTab('kakao_notice')"
          >알림톡</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link mb-0 px-0 py-1"
          data-bs-toggle="tab"
          href="javascript:;"
          role="tab"
          aria-selected="false"
          @click="selectTab('kakao_friend')"
          >친구톡</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "KakaoNavPill",
  data() {
    return {
      currentTab: "kakao_notice",
    };
  },
  mounted() {
    setNavPills();
  },
  methods: {
    selectTab(tab) {
      this.currentTab = tab;
      this.$emit("select-form", tab);
    },
  },  
};
</script>
