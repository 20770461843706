<template>
  <div v-if="isLoading" class="position-absolute top-0 bottom-0 d-flex justify-content-center align-items-center">
    <div class="spinner-container">
      <i class="fas fa-spinner fa-spin spinner-icon text-info"></i>
    </div>
  </div>
  <div class="py-3 container-fluid">
    <div class="row">
      <div class="card h-100">
        <div class="p-3 pb-0 card-header">
          <div class="d-flex justify-content-between">
            <h6 class="mb-0">FAX</h6>
          </div>
        </div>
        <div class="p-3 card-body">
          <div class="row align-items-start mt-2">
            <div class="col-6">
              <form ref="form" @submit.prevent="submitForm">
                <div class="row align-items-center">
                  <div class="col-sm-3 pr-0">
                    <span style="font-weight: bold; font-size: 0.8rem;">발신번호: </span>
                  </div>
                  <div class="col-sm-9">
                    <select id="from-phone-number" v-model="formData.from_phone_num" class="form-control">
                    </select>
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <div class="col-sm-3 pr-0">
                    <span style="font-weight: bold; font-size: 0.8rem;">예약발송:</span>
                  </div>
                  <div class="col-sm-9">
                    <material-input id="senddtm_datetime" v-model="formData.send_request_dtm" size="sm"
                      type="datetime-local" step="600" :min="minDatetime">
                    </material-input>
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <div class="col-sm-3 pr-0">
                    <span style="font-weight: bold; font-size: 0.8rem;">전송방식:</span>
                  </div>
                  <div v-if="isBusinessVerified == '2'" class="col-sm-9 d-flex align-items-center">
                    <label for="send_method"
                      style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">수동입력</label>
                    <material-radio id="manual" v-model.sync="selectedSendMethod" name="send_method" style="margin-left: -20px"
                      :value="'manual'" :checked="selectedSendMethod === 'manual'"
                      @change="onChangeSendMethod('manual')">
                    </material-radio>
                    <label for="send_method"
                      style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">엑셀업로드</label>
                    <material-radio id="excel" v-model.sync="selectedSendMethod" name="send_method" style="margin-left: -20px"
                      :value="'excel'" :checked="selectedSendMethod === 'excel'"
                      @change="onChangeSendMethod('excel')">
                    </material-radio>
                    <label for="send_method"
                      style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">주소록전송</label>
                    <material-radio id="address" v-model.sync="selectedSendMethod" name="send_method" style="margin-left: -20px"
                      :value="'address'" :checked="selectedSendMethod === 'address'"
                      @change="onChangeSendMethod('address')">
                    </material-radio>
                  </div>
                </div>
                <div v-if="selectedSendMethod == 'manual'" id="send_from_manual"
                  class="row align-items-center mt-2">
                  <div class="col-sm-3 pr-0">
                    <span style="font-weight: bold; font-size: 0.8rem;">수신번호:</span>
                  </div>
                  <div class="col-sm-9">
                    <div id="to_phone_table" style="height: 200px; overflow-y: auto;">
                      <div class="table-responsive">
                        <table id="to-phone-list" class="table table-flush">
                          <tbody>
                            <tr v-for="(phone, index) in checkedPhones" :key="index">
                              <div v-if="index <= 4 || isBusinessVerified == '2'" class="d-flex align-items-start"
                                style="padding: 0;">
                                <material-checkbox id="to_phone_number_checkbox" :checked="phone.checked" @click.stop
                                  @change="toggleCheckbox($event, index)" />
                                <material-input id="to_phone_number" v-model="phone.phoneNumber" size="sm"
                                  placeholder="수신번호" style="width: 150px;" @focus="handleFocus(index)">
                                </material-input>
                              </div>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="selectedSendMethod == 'excel'" id="send_from_excel" class="row align-items-center mt-2">
                  <div class="col-sm-3 pr-0">
                    <span style="font-weight: bold; font-size: 0.8rem;">엑셀선택:</span><br>
                    <label class="btn-sm" style="display: inline-block; position: relative; cursor: pointer;">
                      <i class="material-icons icon-hover icon-large" style="cursor: pointer; margin-top: 0px; margin-left: -15px;"
                        @click="openFileDialog">upload</i>
                      <span style="cursor: pointer; display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 20px;"
                        @click="openFileDialog">UPLOAD</span>
                      <input ref="fileInput" type="file" style="display: none" @change="handleFileUpload" />
                    </label><br><br>
                    <span style="font-weight: bold; font-size: 0.8rem;">샘플파일:</span><br>
                    <label class="btn-sm" style="display: inline-block; position: relative; cursor: pointer;">
                      <i class="material-icons icon-hover icon-large" style="cursor: pointer; margin-top: 0px; margin-left: -15px;"
                        @click="downloadExcelSample">download</i>
                      <span style="cursor: pointer; display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 20px;"
                        @click="downloadExcelSample">DOWNLOAD</span>
                    </label>
                  </div>
                  <div id="address_name" class="col-sm-9" style="height: 300px; overflow-y: auto;">
                    <span style="font-weight: bold; font-size: 0.8rem;">주소록명</span><br>
                    <td style="padding: 5px;">
                      <div class="d-flex align-items-start">
                        <material-input id="address_name" v-model="formData.customer_group_alias" size="sm"
                          style="margin-left: -3px;" :disabled="!fileSelected"></material-input>
                      </div>
                    </td>
                    <div class="table-responsive">
                      <table id="excel_table" class="table table-flush">
                        <div class="col-sm-5">

                          <span style="font-weight: bold; font-size: 0.8rem;">전화번호</span><br>
                          <material-button id="toPhoneNumberIndex" color="success" size="sm" variant="outline"
                            class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                            style="width: 180px" :disabled="!fileSelected">
                            {{ selectedPhoneNumberField || "전화번호헤더 선택" }}
                          </material-button>
                          <ul class="dropdown-menu px-2 py-3 bg-white" aria-labelledby="dropdownMenuButton">
                            <li v-for="field in headerFields" :key="field">
                              <a class="dropdown-item border-radius-md" href="javascript:;"
                                @click="handlePhoneSelection(field)">{{ field }}</a>
                            </li>
                          </ul>

                        </div>
                      </table>
                    </div>
                  </div>
                </div>
                <div v-if="selectedSendMethod == 'address'" id="send_from_customer_group"
                  class="row align-items-center mt-2">
                  <div class="col-sm-3 pr-0">
                    <span style="font-weight: bold; font-size: 0.8rem;">주소록선택:</span><br>
                    <div style="display: flex;">
                      <material-button id="toAddress" color="success" size="sm" variant="outline" class="dropdown-toggle"
                        type="button" data-bs-toggle="dropdown" aria-expanded="false" style="flex-grow: 1;">
                        {{ formData.customer_group_id || "주소록" }}
                      </material-button>
                      <ul class="dropdown-menu" aria-labelledby="customerGroupIds">
                        <li v-for="optionCustomerGroupId in optionCustomerGroupIds" :key="optionCustomerGroupId">
                          <a class="dropdown-item" href="javascript:;"
                            @click="selectCustomerGroupId(optionCustomerGroupId)">
                            {{ optionCustomerGroupId }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-sm-9">
                    <span style="font-weight: bold; font-size: 0.8rem; margin-right: 0.5rem;">주소록명</span><span
                      style="font-weight: bold; font-size: 0.7rem;">(최대 100건까지 조회됩니다.)</span><br>
                    <td style="padding: 5px;">
                      <div>
                        <material-input id="address_name" v-model="formData.customer_group_id" size="sm"
                          style="margin-left: -3px;" readonly></material-input>
                        <span style="font-weight: bold; font-size: 0.7rem;">총 주소록 건수: {{ contact_list_count }}</span>
                      </div>
                    </td>
                    <!-- <span style="font-weight: bold; font-size: 0.8rem;">전화번호</span> -->
                    <div id="address_table" style="height: 200px; overflow-y: auto;">
                      <div class="table-responsive">
                        <material-table title="연락처" :headers=tableColumns :lists=contact_list :font-size=fontSize />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <div class="col-sm-3 pr-0">
                    <span style="font-weight: bold; font-size: 0.8rem;">식별정보:</span>
                  </div>
                  <div class="col-sm-9">
                    <div class="mt-2">
                      <material-input id="primary_customer_reference_id" v-model="formData.primary_customer_reference_id" type="text" size="sm"
                        label="고객식별ID" label-size="sm" variant="outline"
                        maxlength="36">
                      </material-input>
                    </div>
                    <div class="mt-2">
                      <material-input id="secondary_customer_reference_id" v-model="formData.secondary_customer_reference_id" type="text" size="sm"
                        label="캠페인ID" label-size="sm" variant="outline"
                        maxlength="36">
                      </material-input>
                    </div>
                  </div>
                </div>
                <div id="upload_image" class="row align-items-center mt-2">
                  <div class="col-sm-3 pr-0">
                    <span style="font-weight: bold; font-size: 0.8rem;">파일첨부:</span>
                  </div>
                  <div class="col-sm-9">
                    <div class="d-flex flex-wrap">
                      <div v-for="(uploadImage, index) in uploadImages" :key="index" class="col-1.5">
                        <label class="btn-lg">
                          <i class="material-icons icon-hover icon-large" style="cursor: pointer; margin-top: 0px;"
                            @click="openFileDialog">add_photo_alternate</i>
                          <span style="cursor: pointer; display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;"
                            @click="openFileDialog">사진추가</span>
                          <input ref="fileInput" type="file" style="display: none"
                            @change="handleImageUpload($event, index)" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="row align-items-center" style="margin-top: -8px">
                  <div class="col-sm-3 pr-0">
                    <span style="font-weight: bold; font-size: 0.8rem;">예약결과알림:</span>
                  </div>
                  <div class="col-sm-9 d-flex">
                    <label for="send_alert"
                      style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">미수신</label>
                    <material-radio name="send_alert" id="alert_n" style="margin-left: -20px" :value="'alert_n'"
                      v-model.sync="selectedAlertMethod" @change="onChangeAlertMethod('alert_n')"
                      :checked="selectedAlertMethod === 'alert_n'" :disabled="this.formData.send_request_dtm == ''">
                    </material-radio>
                    <label for="send_alert"
                      style="display: inline-block; white-space: nowrap; font-weight: bold; font-size: 0.7rem; margin-top: 10px;">수신</label>
                    <material-radio name="send_alert" id="alert_y" style="margin-left: -20px" :value="'alert_y'"
                      v-model.sync="selectedAlertMethod" @change="onChangeAlertMethod('alert_y')"
                      :checked="selectedAlertMethod === 'alert_y'" :disabled="this.formData.send_request_dtm == ''">
                    </material-radio>
                    <div class="mt-0" style="margin-left: 10px;">
                      <material-input id="alert_phone_number" type="number" size="sm" label="알림수신 전화번호" labelSize="sm"
                        v-model="formData.alert_phone_number" variant="outline" maxlength="36" :disabled=!isAlert>
                      </material-input>
                    </div>
                  </div>

                </div> -->
                <div class="col-12 text-end">
                  <material-button type="submit" color="primary" size="sm" variant="default" style="width: 120px">
                    전송
                  </material-button>
                </div>
              </form>
            </div>
            <div class="col-6">
              <div id="image-card-body" class="p-3 card-body" style="position: relative;">
                <h6 class="mb-0">첨부파일</h6>
                <div class="row">
                  <div v-for="(uploadImage, index) in uploadImages" :key="index" class="col-4">
                    <div style="position: relative;">
                      <img :src="uploadImage.data" style="max-width: 100%; max-height: 180px;">
                      <i v-if="uploadImage.data" class="material-icons icon-hover icon-large"
                        style="position: absolute; top: 0; left: 0; cursor: pointer; margin-top: 5px; margin-left: 5px; color: red;"
                        @click="removeImage(index)">cancel</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import ApiCall from "@/Interface/ApiCall";
import { Auth } from 'aws-amplify'
import * as XLSX from 'xlsx'
import MaterialTable from "@/components/MaterialTable.vue";
import * as Choices from "choices.js";

export default {
  components: {
    MaterialButton,
    MaterialInput,
    MaterialCheckbox,
    MaterialRadio,
    MaterialTable,
  },
  data() {
    return {
      formData: {
        from_phone_num: '',
        send_request_dtm: '',
        to_phone_number: [''],
        customer_group_id: '',
        customer_group_alias: '',
        excel_file_name: '',
        excelfile: '',
        primary_customer_reference_id: '',
        secondary_customer_reference_id: '',
        send_status: '',
        subject: '',
        alert_phone_number: '',
      },
      to_phone_num_index: '',
      headerFields: [],
      params: [],
      selectedPhoneNumberField: '전화번호 선택',

      selectedSendMethod: 'manual',
      selectedAlertMethod: 'alert_n',
      checkedPhones: [{ checked: false, phoneNumber: '' }],
      optionFromPhoneNumbers: [],
      optionCustomerGroupIds: [],
      sendNumberGroupData: [],

      //이미지 업로드
      uploadImages: [
        { files: [], data: null, imageID: null },
        { files: [], data: null, imageID: null },
        { files: [], data: null, imageID: null }
      ],
      // imageReturnID: [],

      contact_list: [],
      fontSize: 'xs',
      tableData: [],
      tableColumns: ['전화번호', '변수', ''],

      contact_list_count: 0,

      isLoading: true,
      isAlert: false,
      addressSelected: false,
      fileSelected: false,
      phoneSelected: false,

      isValidateMessageContent: false,


    };
  },
  computed: {
    contentTextareaReadonly() {
      return !this.isContentEditable;
    },
    minDatetime() {
      // Get the current datetime in Seoul time and round up to the nearest 10 minute interval
      let now = new Date();
      now.setSeconds(0);

      // Set the time zone offset to the offset for Seoul time (UTC+9)
      let timezoneOffset = 9 * 60; // in minutes
      now.setMinutes(now.getMinutes() + timezoneOffset);

      // Round up to the nearest 10 minute interval
      let roundedMinutes = Math.ceil(now.getMinutes() / 10) * 10;
      now.setMinutes(roundedMinutes);

      let datetimeString = now.toISOString().slice(0, -8);

      return datetimeString;
    }
  },
  watch: {
    'checkedPhones': {
      handler(newVal, oldVal) {
        if (this.isBusinessVerified == '2') {
          for (let i = 0; i < newVal.length; i++) {
            // Replace hyphens and dots with an empty string
            newVal[i].phoneNumber = newVal[i].phoneNumber.replace(/[-. ]/g, '');

            // Find the first non-digit character
            const nonDigitOrAlphabetIndex = newVal[i].phoneNumber.search(/[^0-9a-zA-Z]/);

            if (nonDigitOrAlphabetIndex !== -1) {
              // Split the string at the first non-digit character
              const splitPhones = [
                newVal[i].phoneNumber.slice(0, nonDigitOrAlphabetIndex),
                newVal[i].phoneNumber.slice(nonDigitOrAlphabetIndex + 1)
              ].map(phone => phone.trim());

              // Update the current phone number with the part before the non-digit character
              this.checkedPhones[i].phoneNumber = splitPhones[0];

              // Append the rest of the string to the checkedPhones array
              const additionalPhones = splitPhones.slice(1).map(phone => ({ phoneNumber: phone, checked: newVal[0].checked }));
              this.checkedPhones.splice(i + 1, 0, ...additionalPhones);

              // Focus the next input box after the one that was changed
              this.$nextTick(() => {
                const nextInputRef = this.$refs[`input-${i + 1}`];
                if (nextInputRef && nextInputRef.$el) {
                  nextInputRef.$el.focus();
                }
              });

              break; // Stop the loop after processing the first non-digit character
            }
          }
        }
      },
      deep: true
    },
    optionFromPhoneNumbers(newList) {
      if (newList.length > 0) {
        this.formData.from_phone_num = newList[0].value;
      }
    },
  },
  mounted() {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      const idToken = user.signInUserSession.idToken;
      const tenantId = idToken.payload['cognito:groups'][0];
      const isBusinessVerified = user.attributes['custom:isBusinessVerified']
      this.tenantId = tenantId;
      this.isBusinessVerified = isBusinessVerified;

      const payload = {
        status: "active",
      };

      ApiCall.call('phone/from/numbers', 'POST', payload)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            let defaultOptionData = [{ value: "", label: "발신번호를 선택해주세요." }];
            const optionData = defaultOptionData.concat(response.data.map(item => {
              // Check if from_phone_number_alias is not null
              const label = item['from_phone_number_alias']
                ? '(' + item['from_phone_number_alias'] + ') ' + item['from_phone_number']
                : item['from_phone_number'];

              return {
                label: label,
                value: item['from_phone_number']
              };
            }));
            this.optionFromPhoneNumbers = optionData;
            // console.log("optionsFromPhoneNumbers :", this.optionFromPhoneNumbers);
            this.initChoicesList();
            // console.log(this.optionFromPhoneNumbers);
          } else {
            console.error('Error: Invalid response data format');
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });


      const getGroupPayload = {
        status: "active",
      };

      ApiCall.call('group/list', 'POST', getGroupPayload)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.optionCustomerGroupIds = response.data;
            // console.log(this.optionCustomerGroupIds);
          } else {
            console.error('Error: Invalid response data format');
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });

    })();

    this.checkedPhones[0].checked = true;
    this.checkedPhones.push({ checked: false, phoneNumber: "" });
    this.isLoading = false;
  },
  methods: {
    initChoicesList() {
      if (this.selectInstance) {
        this.selectInstance.destroy();
      }
      this.selectInstance = new Choices('#from-phone-number', {
        shouldSort: false,
        choices: this.optionFromPhoneNumbers,
      });
    },

    resetData() {
      this.isLoading = false;
      this.formData.subject = '';
      this.formData.from_phone_num = '';
      this.formData.send_request_dtm = '';
      this.formData.to_phone_number = [''];
      this.formData.customer_group_id = '';
      this.formData.customer_group_alias = '';
      this.excel_file_name = '',
        this.formData.excelfile = '';
      this.formData.primary_customer_reference_id = '';
      this.formData.secondary_customer_reference_id = '';
      this.formData.send_status = '';
      this.formData.alert_phone_number = '';
      this.to_phone_num_index = '';
      this.headerFields = [''];
      this.params = [''];
      this.selectedPhoneNumberField = '전화번호 선택';

      this.selectedSendMethod = 'manual';
      this.is080Selected = false;
      this.selectedAlertMethod = 'alert_n';
      this.checkedPhones = [{ checked: false, phoneNumber: '' }];
      this.uploadImages = [
        { files: [], data: null, imageID: null },
        { files: [], data: null, imageID: null },
        { files: [], data: null, imageID: null }
      ];
      // this.imageReturnID = [];
      this.contact_list = [],
        this.tableData = [],
        this.checkedPhones = [{ checked: false, phoneNumber: '' }];
      this.checkedPhones[0].checked = true;
      this.checkedPhones.push({ checked: false, phoneNumber: '' });
      this.contact_list_count = 0;
      this.isAlert = false;
      this.addressSelected = false;
      this.fileSelected = false;
      this.phoneSelected = false;


      this.initChoicesList();

      this.isContentEditable = true;
    },
    resetDataPartial() {
      this.isLoading = false;
      this.formData.to_phone_number = [''];
      this.formData.customer_group_id = '';
      this.formData.customer_group_alias = '';
      this.excel_file_name = '',
        this.formData.excelfile = '';
      this.formData.send_status = '';
      this.to_phone_num_index = '';
      this.headerFields = [''];
      this.params = [''];
      this.selectedPhoneNumberField = '전화번호 선택';

      this.selectedSendMethod = 'manual';
      this.contact_list = [],
        this.tableData = [],
        this.checkedPhones = [{ checked: false, phoneNumber: '' }];
      this.checkedPhones[0].checked = true;
      this.checkedPhones.push({ checked: false, phoneNumber: '' });
      this.contact_list_count = 0;
      this.addressSelected = false;
      this.fileSelected = false;
      this.phoneSelected = false;

    },
    getCurrentDateTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    },
    selectFromPhone(option) {
      this.formData.from_phone_num = option;
    },
    selectCustomerGroupId(option) {
      this.isLoading = true;
      this.formData.customer_group_id = option;

      const getcontactlistPayload = {
        customer_group_id: this.formData.customer_group_id,
        limit: "100",
      };

      ApiCall.call('group/contact/list', 'POST', getcontactlistPayload).then(res => {
        this.contact_list_count = res.data.record_count;
        const contact_list = res.data.data.map(item => {
          return {
            // primary: item.phone_number,
            primary: item.phone_number.substring(0, item.phone_number.length - 4) + '****',
            values: [
              item.param_list,
            ],
            secondary: '',
            secondary_color: '',
            info: '',
            image: '',
            image_color: '',
            icon: '',
          };
        });
        this.tableData = res.data.data;
        // this.contact_list = contact_list;
        this.contact_list = contact_list.map(item => ({
          ...item,
          values: item.values.map(value => String(value).replace(/"/g, '')),
        }));
        this.isLoading = false;
      });
    },
    downloadExcelSample() {
      const filePath = '/엑셀샘플.xlsx';
      window.location.href = filePath;
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      // console.log('file:', file)


      if (file) {
        const reader = new FileReader()
        // console.log('reader:', reader)
        reader.onload = () => {
          const fileData = reader.result
          const workbook = XLSX.read(fileData, { type: 'binary' })
          const sheetName = workbook.SheetNames[0]
          const sheet = workbook.Sheets[sheetName]
          const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 })
          this.headerFields = rows[0]
          this.formData.excelfile = file.name
          this.formData.customer_group_alias = file.name.split('.').slice(0, -1).join('.');
          this.excel_file = file;
        };
        reader.readAsBinaryString(file)

        this.selectedPhoneNumberField = ''
        this.to_phone_num_index = ''

        this.params.length = 0;
        this.fileSelected = true;
        this.phoneSelected = false;

      }
    },
    handlePhoneSelection(option) {
      this.selectedPhoneNumberField = option
      this.to_phone_num_index = this.headerFields.indexOf(option)
      this.phoneSelected = true;
    },

    onChangeSendMethod(option) {
      this.resetDataPartial();
      this.selectedSendMethod = option;
    },
    onChangeAlertMethod(option) {
      this.selectedAlertMethod = option;
      if (option == 'alert_n') {
        this.formData.alert_phone_number = '';
        this.isAlert = false;
      } else {
        this.isAlert = true;
      }
    },
    handleFocus(index) {
      if (index === 0) {
        const firstCommaIndex = this.checkedPhones[0].phoneNumber.indexOf(',');
        if (firstCommaIndex !== -1) {
          this.checkedPhones[0].phoneNumber = this.checkedPhones[0].phoneNumber.substring(0, firstCommaIndex);
        }
      }
    },
    toggleCheckbox(event, idx) {
      if (this.checkedPhones[idx].checked) {
        this.checkedPhones[idx].checked = false;
        if (this.checkedPhones[idx].phoneNumber == '')
          this.checkedPhones.splice(idx, 1);
      } else {
        this.checkedPhones[idx].checked = true;
        if (idx == this.checkedPhones.length - 1)
          this.checkedPhones.push({ checked: false, phoneNumber: "" });
      }
      this.$nextTick(() => {
        const table = document.getElementById("to_phone_table");
        table.scrollTo(0, table.scrollHeight);
      });
    },
    async handleImageUpload(event, index) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        const mimeType = file.type; // 파일의 MIME 타입
        const fileType = mimeType.split('/')[0]; // 파일 유형 추출

        const formData = {
          mime_type: mimeType,
          file_type: fileType,
          upload_type: "file",
        };
        // console.log("formData1=", formData);

        if (fileType === 'image') {
          // 이미지 파일 처리
          const reader = new FileReader();
          reader.onload = async (e) => {
            const img = document.createElement('img');
            img.src = e.target.result;

            await new Promise((resolve) => {
              img.onload = () => resolve();
            });

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            let newWidth = img.width;
            let newHeight = img.height;

            const maxWidth = 1440;
            const maxHeight = 1440;
            if (img.width > maxWidth || img.height > maxHeight) {
              const aspectRatio = img.width / img.height;
              if (aspectRatio > 1) {
                newWidth = maxWidth;
                newHeight = newWidth / aspectRatio;
              } else {
                newHeight = maxHeight;
                newWidth = newHeight * aspectRatio;
              }
            }

            canvas.width = newWidth;
            canvas.height = newHeight;
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            const quality = 1;
            canvas.toBlob(async (blob) => {
              const resizedImageFile = new File([blob], file.name, { type: mimeType });
              this.uploadImages[index].data = canvas.toDataURL('image/jpeg', quality);
              const preview = document.querySelector('#image-card-body');
              if (preview) {
                preview.src = this.uploadImages[index].data;
              }
              await this.uploadFile(formData, index, file);

            }, mimeType, quality);
          };
          reader.readAsDataURL(file);
        } else {
          // 비이미지 파일 처리
          const defaultImageUrl = require('@/assets/img/file_image.png'); // 미리보기로 사용할 이미지 URL
          this.uploadImages[index].data = defaultImageUrl; // 특정 이미지 미리보기 설정
          const preview = document.querySelector('#image-card-body');
          if (preview) {
            preview.src = this.uploadImages[index].data;
          }
          await this.uploadFile(formData, index, file);
        }

        // eslint-disable-next-line require-atomic-updates
        event.target.value = null; // 입력 필드 초기화
      }
    },

    async uploadFile(formData, index, file) {
      try {
        const fileResponse = await ApiCall.file('upload/fax', 'POST', formData, null, file, null, false);
        // console.log("업로드 성공:", fileResponse.data);
        this.uploadImages[index].imageID = fileResponse.data.item_list[0].s3_uri; // file_key 저장
        // console.log(" this.uploadImages=", this.uploadImages);
      } catch (error) {
        throw new Error("업로드 중 오류 발생");
      }
    }
    ,
    removeImage(index) {
      this.uploadImages[index] = { files: [], data: null, imageID: null };
    },
    async submitForm() {
      if (this.formData.from_phone_num.length <= 0) {
        alert("발신번호를 선택해주세요.");
        return;
      }

      if (this.selectedSendMethod == 'manual') {
        if (!this.checkedPhones.every(phone => {
          return !phone.checked || (phone.phoneNumber !== '' && phone.phoneNumber !== null);
        })) {
          alert('체크한 수신번호를 모두 입력해주세요.');
          return;
        }
        if (!this.checkedPhones.every(phone => {
          if (phone.checked && phone.phoneNumber !== '') {
            // if (!phone.phoneNumber.startsWith('01')) {
            //   return false;
            // }
            if (phone.phoneNumber.length != 10 && phone.phoneNumber.length != 11 && phone.phoneNumber.length != 12) {
              return false;
            }

            return !isNaN(parseInt(phone.phoneNumber));
          }
          return true;
        })) {
          alert('유효한 전화번호를 입력해주세요.');
          return;
        }
      }
      if (this.selectedSendMethod == 'excel') {
        if (!this.excel_file || this.excel_file.length <= 0) {
          alert("전화번호가 있는 엑셀을 선택해주세요.");
          return;
        }
        if (this.to_phone_num_index.length <= 0) {
          alert("전화번호가 있는 컬럼을 선택해주세요.");
          return;
        }
      }
      if (this.selectedSendMethod == 'address') {
        if (this.formData.customer_group_id.length <= 0) {
          alert("주소록을 선택해주세요.");
          return;
        }
      }
      if (this.uploadImages[0].imageID === null) {
        alert("첫번째 FAX 첨부를(이미지,파일 등) 업로드 해주세요.");
        return;
      }
      if (this.uploadImages[1].imageID === null & this.uploadImages[2].imageID !== null) {
        alert("순서대로 선택해주세요.");
        return;
      }
      if (this.isAlert) {
        if (this.formData.alert_phone_number.length <= 0) {
          alert("결과알림 수신전화번호를 입력해주세요.");
          return;
        }
        if (!this.formData.alert_phone_number.startsWith('01')) {
          alert('유효한 결과알림 수신전화번호를 입력해주세요.');
          return false;
        }
        if (this.formData.alert_phone_number.length != 10 && this.formData.alert_phone_number.length != 11 && this.formData.alert_phone_number.length != 12) {
          alert('유효한 결과알림 수신전화번호를 입력해주세요.');
          return false;
        }
      }
      if (this.formData.send_request_dtm) {
        const currentTime = new Date();
        const requestTime = new Date(this.formData.send_request_dtm);
        const thirtyMinutesFromNow = new Date(currentTime.getTime() + (21 * 60000));
        const minutes = requestTime.getMinutes();
        if (minutes % 1 !== 0) {
          alert("예약전송은 1분 단위로 전송 가능합니다.");
          return;
        }
        if (requestTime < thirtyMinutesFromNow) {
          alert("예약전송은 최소 30분 이후부터 가능합니다.");
          return;
        }
        this.submitFormData();
      } else {
        this.submitFormData();
      }
    },

    showConfirmation(count) {
      var confirmationMessage = ""
      if (this.formData.send_status == 'schedule-request'){
        confirmationMessage = "총" + count + "건의 메시지가 예약 됩니다. 예약 하시겠습니까?";
      } else {
        confirmationMessage = "총" + count + "건의 메시지는 즉시전송 됩니다. 전송 하시겠습니까?";
      }
      if (window.confirm(confirmationMessage)) {
        return true;
      } else {
        this.isLoading = false;
        return false;
      }
    },
    async submitFormData() {
      this.isLoading = true;

      this.formData.to_phone_number = this.checkedPhones
        .filter((phone) => phone.checked && phone.phoneNumber !== '')
        .map((phone) => phone.phoneNumber);

      const sendRequestDtm = '';
      //send SMS
      if (!this.formData.send_request_dtm) {
        this.sendRequestDtm = this.getCurrentDateTime();
        this.formData.send_status = "request"
      } else {
        this.formData.send_status = "schedule-request"
        const date = new Date(this.formData.send_request_dtm);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        this.sendRequestDtm = `${year}${month}${day}${hours}${minutes}${seconds}`;
      }

      const toPhoneNumbersWithParams = this.formData.to_phone_number.map((phone) => {
        return {
          phone: phone,
   
        };
      });
      const payload = {
        // contents: this.formData.content,
        send_status: this.formData.send_status,
        from_country_code: "82",
        to_country_code: "82",
        result_url: "", 
        contents: "", 
        next_method_on_fail: "", 
        send_request_dtm: this.sendRequestDtm, 
        from_phone_number: this.formData.from_phone_num, 
        secondary_customer_reference_id: this.formData.secondary_customer_reference_id,
        primary_customer_reference_id: this.formData.primary_customer_reference_id,
        send_type: "fax",
        image_url1: this.uploadImages[0] ? this.uploadImages[0].imageID : null,
        image_url2: this.uploadImages[1] ? this.uploadImages[1].imageID : null,
        image_url3: this.uploadImages[2] ? this.uploadImages[2].imageID : null,
      };
      if (this.selectedSendMethod == 'manual') {
        if (this.showConfirmation(toPhoneNumbersWithParams.length)) {
          try {
            payload.to_phone_numbers = toPhoneNumbersWithParams;
            // console.log("payload=", payload);
            const response = await ApiCall.call('message', 'POST', payload);
            // const response = await ApiCall.call('안보내기', 'POST', payload);
            // console.log(response);
            alert(response.data.resultMessage);
          } catch (error) {
            console.error(error);
            alert("FAX 전송 실패");
          }
          this.resetData();
        }
      } else if (this.selectedSendMethod == 'excel') {
        const payloadFile = {
          customer_group_id: this.formData.customer_group_alias,
          phone_number_index: this.to_phone_num_index,

          link_url: this.linkUrl,
          link_type: this.linkType,
          link_file_name: this.linkFileName,
        };
        // console.log(payloadFile);
        try {
          const fileResponse = await ApiCall.file('upload/excel', 'POST', payloadFile, null, this.excel_file, null, false);
          // console.log(fileResponse.data);
          if (this.showConfirmation(fileResponse.data.total_count) && fileResponse.data.customer_group_id) {
            payload.customer_group_id = fileResponse.data.customer_group_id;
            // console.log(payload);
            const response = await ApiCall.call('message/group/mms', 'POST', payload);
            // const response = await ApiCall.call('안보내기', 'POST', payload);
            // console.log(response.data);
            alert("MMS 전송 성공");
            this.resetData();
          }
        }
        catch (error) {
          console.error(error);
          alert("MMS 전송 실패");
          this.resetData();
        }
        this.resetData();
      } else if (this.selectedSendMethod == 'address') {
        if (this.showConfirmation(this.contact_list_count)) {
          payload.customer_group_id = this.formData.customer_group_id;
          // console.log(payload);
          try {
            const response = await ApiCall.call('message/group/mms', 'POST', payload);
            // const response = await ApiCall.call('안보내기', 'POST', payload);
            // console.log(response.data);
            alert("MMS 전송 성공");
          } catch (error) {
            console.error(error);
            alert("MMS 전송 실패");
          }
          this.resetData();
        }
      }
      this.isLoading = false;
    },
  },
};

</script>

<style>
#to_phone_table {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

#excel_table {
  border: 0px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

#address_table {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.spinner-container {
  z-index: 9999;
  /* Set a high z-index value */
}
</style>
